import {
    GET_QRS_GENERATED_BY_FACILITY_PAGE_RESPONSE,
    GET_QRS_TEMPLATES_RESPONSE,
    SUBMIT_QR_GENERATION_RESPONSE,
    EDIT_QR_GENERATION_RESPONSE,
    GET_QRS_GENERATED_BY_ID_RESPONSE,
    GET_LOTS_IN_GLOBAL_EVENTS_RESPONSE,
    GET_RELATED_IMAGES_BY_LOT_RESPONSE

} from "../actions/qrGenerationActions";

const initialState = {
    massiveLoad: {}
};

const qrGenerationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_QRS_GENERATED_BY_FACILITY_PAGE_RESPONSE:
        case GET_QRS_TEMPLATES_RESPONSE:
        case GET_QRS_GENERATED_BY_ID_RESPONSE:
        case GET_LOTS_IN_GLOBAL_EVENTS_RESPONSE:
            return {
                ...state,
                [`${action.eventType}`]: action.response
            };
        case EDIT_QR_GENERATION_RESPONSE:      
            return {
                    ...state,
                    events: action.response.results,
                };    
        case GET_RELATED_IMAGES_BY_LOT_RESPONSE:
            return {
                ...state,
                'relatedImages':action.response
            }
        default:
            return state
    };
}
export default qrGenerationReducer;
