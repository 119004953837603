import api from '../../api/index';
import {
    GET_LOTS_WITH_ANIMALS_BY_FACILITYID
} from '../actions/globalEventActions';

import actions from '../actions';
import mapper from './mappers/globalEventMapper';

const globalEvents = ({dispatch}) => next => action => {
    next(action);
    switch (action.type) {

        case GET_LOTS_WITH_ANIMALS_BY_FACILITYID:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getLotsWithAnimalsByFacilityId(jwt, action.establishmentId, action.viewOnlyActiveLots)
                    .then(res => {
                        dispatch(actions.globalEvent.getLotsWithAnimalsByFacilityIdResponse(
                            mapper.response.getLotsWithAnimalsByFacilityId(res, action.viewOnlyActiveLots)))
                        if (action.callback) action.callback();
                    })
                    .catch(err => {
                        dispatch(actions.globalEvent.getLotsWithAnimalsByFacilityIdError(err))
                    });
                }));
                break;
              
    }
};

export default globalEvents;