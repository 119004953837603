import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';
import {ROLES} from "../../constants";
import actions from '../../redux/actions/index';

import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import setting from '../../util/settings';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import DatePicker from "react-date-picker";
import cowTransparent from "../../shared/img/cow-transparent.gif";

import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import '../../scss/component/reports.scss';

import CVBasicModal from "../../shared/components/cv/CVBasicModal";
import { BlockPicker } from 'react-color';


const Reports = (props) => {    
    const {  t, reduxState, allCompanies, establishments, establishmentLots, roles } = props;    
    const [companies, setCompanies] = useState([]);
    const [facilities, setFacilities] = useState([]);
    const [selectedCompany, selectCompany] = useState([]);
    const [selectedType, selectType] = useState([]);
    const [selectedUrl, selectUrl] = useState([]);
    const [wait, setWait] = useState(false);
    const [types, selectTypes] = useState([
        {id:'STOCK_VARIATION',name:'Variación de Stock'},
        {id:'STOCK_VARIATION_PER_ANIMAL',name:'Variación de Stock por Animal'},
    ]);

    const [dateFrom, onChangeDateFrom] = useState(null);
    const [dateTo, onChangeDateTo] = useState(null);
    const [returnValueTo, setReturnValueTo] = useState([]);

    const [blocksTypes, selectBlocksTypes] = useState({
        'STOCK_VARIATION_PER_ANIMAL': [
            {id:1,name:'Gráfico de variación total',selected:false},
            {id:2,name:'Tabla de variación por nombre de establecimiento',selected:false},
            //{id:3,name:'Tabla de Variación por tipo de establecimiento',selected:false},
            {id:4,name:'Tabla de Variación por categoría de animales',selected:false},
            {id:5,name:'Tabla de Detalle de Bajas por caravanas',selected:false},
            {id:6,name:'Tabla de Detalle de Stock Final por caravanas',selected:false},            
        ],
        'STOCK_VARIATION': [
            {id:1,name:'Gráfico de variación total',selected:false},
            {id:2,name:'Tabla de variación por nombre de establecimiento',selected:false},
            //{id:3,name:'Tabla de Variación por tipo de establecimiento',selected:false},
            {id:4,name:'Tabla de Variación por categoría de animales',selected:false},            
        ],

    });

    const [blocks, selectBlocks] = useState([]);
    const [defaultCheckedBlock,setDefaultCheckedBlock] = useState(false);

    const [showFacilities, setShowFacilities]  = useState(false);
    const [showBlocks, setShowBlocks]  = useState(false);
    const [showDates, setShowDates]  = useState(false);
    const [showSubmit, setShowSubmit]  = useState(false);
    const [showLinks, setShowLinks]  = useState(false);
    const [showCompanies, setShowCompanies]  = useState(false);
    //const [bottomDiv, setBottomDiv] = useState([]);
    const [tkWithoutContractNumber,setTkWithoutContractNumber] = useState(0);

    const bottomDiv = useRef(null)
    
    
    useEffect(() => {        
        if(props.roles.some(rol => (rol.name) === ROLES.TITULAR )) {
            props.getAllCompanies();                                
        }
    }, []);

    useEffect(() => {
        if(props.roles.some(rol => (rol.name) === ROLES.SURROGATE )) props.getAssignedCompanies(props.userId);        
    }, []);

    useEffect(() => {                
        console.log("debug06",allCompanies);
        if(allCompanies && allCompanies.length > 0) {            
            //setAllTemplates(templates);    
            setCompanies(allCompanies);            
            selectCompany('');
            setFacilities([]);
            //selectEstablishment('');            
            //setAllEstablishment([]);            
        }
    }, [allCompanies]);

    useEffect(() => {                
        
        let facilitiesTemp = []
        for (let facility of establishments) {
            facility.selected=false;
            facilitiesTemp.push(facility);
        }   
        setFacilities(facilitiesTemp);
        
        
    }, [establishments]);
    
    
    const handleBlur = (event) => {
        //console.log("event",event.target.value);
    }
    
    const changeType = (event) => {        
        let type = event.target.value;        
        selectBlocks(blocksTypes[type]);
        selectType(type);
        setShowCompanies(true);
        setShowFacilities(false);
        setShowBlocks(false);
        setShowSubmit(false);
        setShowLinks(false);
        setShowDates(false);
        selectCompany('');
        setFacilities([]);
    }

    const changeCompany = (event) => {        
        let company = event.target.value;
        selectCompany(company);
                
        props.getEstablishmentsByCompanyId(company._id);                
        setShowFacilities(true);
        setShowBlocks(false);
        setShowSubmit(false);
        setShowLinks(false);
        setShowDates(false);

        //selectEstablishment('');
        //selectLot('');

        //validateEditButtom(company,'','');
        //setDisableSaveButton(false);
        //setShowCertificates(false);
    };

    const handleChangeBlock = (event) => {
        let blocksTemp = []
        for (let block of blocks) {
            if (block.id==event.target.name) {
                block.selected=event.target.checked;                
            }
            blocksTemp.push(block);
        }
        selectBlocks(blocksTemp);

        setShowDates(true);
        setShowSubmit(true);
        //setShowLinks(false);
        console.log(blocks);
    }

    const selectAllBlocks = () => {
        let blocksTemp = blocks.map(function(block){
            block.selected=true;
            return block;
        });
        selectBlocks(blocksTemp)
        setShowDates(true);
        setShowSubmit(true);
        console.log(blocks);
        
    }

    const unselectAllBlocks = () => {
        let blocksTemp = blocks.map(function(block){
            block.selected=false;
            return block;
        });
        selectBlocks(blocksTemp)        
        //setShowDates(true);
        //setShowSubmit(true);
        console.log(blocks);
        
    }

    const selectAllFacilities = () => {        
        let facilitiesTemp = []
        for (let facility of facilities) {
            facility.selected=true;
            facilitiesTemp.push(facility);
        }        

        setFacilities(facilitiesTemp);
        setShowBlocks(true);
        console.log(facilities);
        
    }
    
    const handleChangeFacility = (event) => {
        let facilitiesTemp = [];
        for (let facility of facilities) {
            if (facility._id==event.target.name) {
                facility.selected=event.target.checked;
            }
            facilitiesTemp.push(facility);
        }
        setFacilities(facilitiesTemp);        
        if (showBlocks==false) {
            unselectAllBlocks();    
        }            
        setShowBlocks(true);
        //setShowSubmit(false);
        //setShowLinks(false);
        //setShowDates(false);
        console.log(facilities);

      };

    const getReport = () => {
        
        let today = new Date(new Date().toISOString().slice(0, 10));
        

        if (dateFrom==null) {
            console.log("debug");
            ToastsStore.error("Debe seleccionar una fecha de Inicio", 5000);
            return;
        }
        if (dateTo==null) {
            ToastsStore.error("Debe seleccionar una fecha de Finalización", 5000);
            return;
        }

        if (dateFrom>=dateTo) {
            ToastsStore.error("La fecha de inicio debe ser anterior a la de finalización", 5000);
            return;
        }

        if (dateFrom>=today) {
            ToastsStore.error("La fecha de inicio debe ser anterior a la fecha actual", 5000);
            return;
        }

        

        
        let blocksArgs = [];
        for (let blockTemp of blocks) {
            if (blockTemp.selected) {
                blocksArgs.push(blockTemp.id.toString());
            }
        }

        if (blocksArgs.length==0) {
            ToastsStore.error("Debe seleccionar al menos una sección.", 5000);
            return;
        }

        let facilitiesArgs = [];
        for (let facilityTemp of facilities) {
            if (facilityTemp.selected) {
                facilitiesArgs.push(facilityTemp._id);
            }
        }

        if (facilitiesArgs.length==0) {
            ToastsStore.error("Debe seleccionar al menos un establecimiento.", 5000);
            return;
        }

        let locale = 0;
        
        try {
            var d = new Date();
            var n = d.getTimezoneOffset();
            locale = n
        } catch (e) {
            
        }

        let body = { 
            
            blocks: blocksArgs,
            companies: [selectedCompany._id],
            facilities: facilitiesArgs,
            reportType: selectedType,
            userId: props.userId,
            dateFrom: dateFrom,
            dateTo: dateTo,
            locateTimezoneOffset: locale

        }
        
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //'Origin':'http://localhost:3001'
             },            
            body: JSON.stringify(body)
        };
                
        setWait(true);
        selectUrl([]);
        setShowLinks(false);
        setTimeout(function(){bottomDiv.current.scrollIntoView();},200);
        ToastsStore.success("Este proceso puede durar unos segundos...", 5000);                        
        fetch(setting.baseUrlReports+"/report/stock/variation/bind", requestOptions)
            .then(response => response.json())
            .then(data => {
                selectUrl(data.documents);
                console.log('tkWithoutContract',data.status.tkWithoutContract);
                setShowLinks(true);
                setWait(false);
                if (data.status.tkWithoutContract>0) {
                    toggleInfoModal(true);
                    setTkWithoutContractNumber(data.status.tkWithoutContract);
                } else {
                    ToastsStore.success("Reporte/s Generado/s ! Haga click en Descargar.", 5000);
                }
                
                bottomDiv.current.scrollIntoView();                
            }).catch(error => {
                setTimeout(
                    function() {
                        ToastsStore.error("El reporte no se ha podido generar.", 5000);
                    }
                    ,2000);                
                setShowLinks(false);
                setWait(false);
            });
    }

    const [infoModalIsOpen, toggleInfoModal] = useState(false);
    const infoModal = () => (
        <CVBasicModal
            title={'Info'}
            body={renderInfoBody}
            isOpen={infoModalIsOpen}
            toggleModal={() => toggleInfoModal(!infoModalIsOpen)}
            hideCancelButton={true}
            //cancel={() => toggleQrModal(false)}
            accept={()=>toggleInfoModal(false)}
            //acceptText={t('animals.modals.qr.download')}
        />
    );
    const renderInfoBody = () => (
        <div>
            <span>
            El reporte se generó correctamente.<br/>
            Pero existen {tkWithoutContractNumber} animal/es nuevos que serán estampados<br/>
            en el próximo bloque de la Blockchain de Carnes Validadas. <br/><br/>
            Si desea obtener un nuevo reporte<br/>
            con todos los animales confirmados<br/>
            espere 15 minutos y genere un nuevo reporte.
            </span>
        </div>
    );
    
    
    return (
        <form className="form" onSubmit={(evt)=>{evt.preventDefault()}}>            
        <Container style={{marginTop:"-15px"}}>
        <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>                                                                                                        
            
            <Row>
                <Col md={12} xl={12} lg={12} xs={12}><h4 style={{fontSize:"14px"}}>Reportes</h4><hr style={{marginTop:"2px", marginBottom:"4px"}}/></Col>
            </Row>            
            <br/>
            <br/>
            <Row>
                    <Col md={8} xl={8} lg={8} xs={8}>
                        <InputLabel id="type-select-label" style={{marginBottom:"0px", marginTop:"5px", fontSize:"14px"}}>Tipo</InputLabel><br/>
                        <FormControl>                                                    
                            <Select                            
                            id="type-select"
                            value={selectedType}
                            onChange={changeType}                            
                            displayEmpty style={{fontSize:"12px"}}
                            >
                            <MenuItem style={{fontSize:"12px"}} value='' disabled>Seleccione una Tipo de Reporte</MenuItem>                        
                            { types.map((type) => (
                            <MenuItem style={{fontSize:"12px"}} value={type.id}>{type.name}</MenuItem>                        
                            ))
                            }
                            </Select>
                        </FormControl>
                    </Col>
            </Row>
            <br/>
            {(showCompanies) &&
            <Row>
                    <Col md={8} xl={8} lg={8} xs={8}>
                        <InputLabel id="company-select-label" style={{marginBottom:"0px", marginTop:"5px", fontSize:"14px"}}>Empresa</InputLabel><br/>
                        <FormControl>                                                    
                            <Select
                            
                            id="company-select"
                            onChange={changeCompany}
                            value={selectedCompany}
                            displayEmpty style={{fontSize:"12px"}}
                            >
                            <MenuItem style={{fontSize:"12px"}} value='' disabled>Seleccione una Empresa</MenuItem>                        
                            { companies.map((company) => (
                            <MenuItem style={{fontSize:"12px"}} value={company}>{company.name}</MenuItem>                        
                            ))
                            }
                            </Select>
                            
                        </FormControl>
                    </Col>                        
            </Row>
            }
            <br/>            
            {(showFacilities) && 
            <Row>
                <Col md={8} xl={8} lg={8} xs={8}>
                    <InputLabel id="company-select-label" style={{marginBottom:"10px", marginTop:"5px", fontSize:"14px"}}>Establecimientos</InputLabel>
                    <Button style={{fontSize:"12px", marginLeft:"10px", paddingBottom:" 1px", paddingTop:" 1px",paddingLeft:"5px", paddingRight:"5px"}} onClick={()=>{selectAllFacilities()}} variant="contained"  color="primary" >
                        Seleccionar Todos
                    </Button>
                    <br/>
                    <FormControl component="fieldset">
                    { facilities.map((establishment) => (                        
                        <label>
                            <Checkbox className={'checkboxReport'}
                                onChange={handleChangeFacility}
                                name={establishment._id}
                                checked={establishment.selected}
                            />
                            <span>{establishment.name}</span>
                        </label>
                    ))
                    }
                    {(facilities.length==0) &&
                        <label>...La empresa seleccionada no posee Establecimientos</label>
                    }           
                                                        
                    </FormControl>
                </Col>
            </Row>
            }
            <br/> 
            {(showBlocks) &&            
            <Row>
                <Col md={8} xl={8} lg={8} xs={8}>
                    <InputLabel id="company-select-label" style={{marginBottom:"10px", marginTop:"5px", fontSize:"14px"}}>Sección</InputLabel> 
                    <Button style={{fontSize:"12px", marginLeft:"10px", paddingBottom:" 1px", paddingTop:" 1px",paddingLeft:"5px", paddingRight:"5px"}} onClick={()=>{selectAllBlocks()}} variant="contained"  color="primary" >
                        Seleccionar Todos
                    </Button>
                    <br/>
                    <FormControl component="fieldset">
                    { blocks.map((block) => (                        
                        <label>
                            <Checkbox className={'checkboxReport'}                           
                                onChange={handleChangeBlock}
                                checked={block.selected}
                                name={block.id}
                            />
                            <span>{block.name}</span>
                        </label>
                    ))
                    }                                               
                    </FormControl>
                </Col>
            </Row>
            }
            <br/>
            {(showDates) &&
            <Row>
                <Col md={8} xl={3} lg={3} xs={3}>
                    <InputLabel id="company-select-label" style={{marginBottom:"0px", marginTop:"5px", fontSize:"14px"}}>Desde</InputLabel><br/>
                    <FormControl component="fieldset">
                    <DatePicker
                        onChange={onChangeDateFrom}
                        value={dateFrom}
                        //className={"cv_date_picker"}
                        calendarClassName={"cv_date_picker_calendar"}
                        required={true}                        
                        //disabled={disabled}
                    />                   
                    </FormControl>
                </Col>
                <Col md={8} xl={3} lg={3} xs={3}>
                    <InputLabel id="company-select-label" style={{marginBottom:"0px", marginTop:"5px", fontSize:"14px"}}>Hasta</InputLabel><br/>
                    
                    <FormControl component="fieldset">                    
                    <DatePicker
                        onChange={onChangeDateTo}
                        refs = "debug"
                        onKeyUp={(event)=> console.log(event.value)}
                        ///onBlur={handleBlur}
                        value={dateTo}
                        
                        //className={"cv_date_picker"}
                        calendarClassName={"cv_date_picker_calendar"}
                        required={true}
                        //returnValue={setReturnValueTo}
                        //disabled={disabled}
                    />
                    </FormControl>                    
                    
                </Col>
            </Row>
            }
            
            
            {(showSubmit) &&            
            <Row>
            <Col md={8} xl={2} lg={2} xs={2}>                                                
            <Button disabled={wait} style={{marginTop:"18px", fontSize:"12px", padding:"8px"}} type="submit" onClick={()=>{getReport()}} variant="contained"  color="primary" >
                Generar Reportes
            </Button> 
                                                          
                    {(wait) &&
                        <div><img className="cow__button_loader" style={{width:"100px",marginTop:"10px"}} alt="cow" src={cowTransparent}/></div>
                    }
            </Col>
            </Row>
            }
            <br/>
            {(showLinks) &&
            <Row>
            <Col md={8} xl={8} lg={8} xs={8}>                                                
            <InputLabel id="company-select-label" style={{marginBottom:"0px", marginTop:"5px", fontSize:"14px"}}>Reportes Generados</InputLabel><br/>                    
                    { selectedUrl.map((url) => (                        
                        <Row>
                        <label style={{marginLeft:"20px"}}>                            
                            <span><a target="_BLANK" style={{padding:"2px", borderRadius:"2px", marginBotton:"5px"}} class="btn btn-primary btn-sm" href={setting.baseUrlReports+"/"+url.path}>Descargar {url.type}</a></span>
                        </label>
                        </Row>
                    ))
                    } 
            </Col>
            </Row>            
            }            
            {(false) &&
            <Row style={{marginTop:"10px"}}>
                <Col style={{height:"72vh"}} md={12} xl={12} lg={12} xs={12}>
                <iframe allowfullscreen="1" style={{height:"100% !important;"}} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" src={setting.baseUrlReports+"/report/stock/variation/"+props.userId+"/"+props.token} id="widget2" data-title="Tutorial - Alta de Animales" width="100%" height="100%" frameborder="0"></iframe>
                </Col>                                                                              
            </Row>
            }
            <div ref={bottomDiv}></div>
            {infoModal()}
        </Container> 
        </form>       
        
    )
};

const mapDispatchToProps = (dispatch) => ({    
    getAllCompanies: () => dispatch(actions.report.getAllUserCompanies()),    
    selectCompany: (company) => dispatch(actions.report.selectCompany(company)),    
    getAssignedCompanies: (userId) => dispatch(actions.report.getAssignedCompanies(userId)),    
    getEstablishmentsByCompanyId: (companyId) => dispatch(actions.report.getUserEstablishmentsByCompany(companyId)),
    getUserEstablishmentById: (currentEstablishment, callback) =>
        dispatch(actions.report.getEstablishmentById(currentEstablishment, callback))    
});

const mapStateToProps = (state) => ({   
    userId: state.user.id,    
    token: state.session.jwt,
    roles: state.user.roles,

    allCompanies: state.report.allCompanies,
    selectedCompany: state.report.selectedCompany,
    establishments: state.report.allEstablishmentsByCompany,    
    reduxState: state,        
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Reports));