import React from 'react'
import setting from '../../util/settings';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';
import {ROLES} from "../../constants";
import actions from '../../redux/actions/index';
const Asekuro = (props) => {  


return(
    <Container style={{marginTop:"-15px",height:'100%'}}>
     <iframe allowfullscreen="1" style={{height:"100% !important;"}}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
      src={setting.baseUrlAsekuro+props.userId} id="widget2" data-title="Asekuro" width="100%" height="700px" frameborder="0"></iframe>
    </Container>
)
}

const mapStateToProps = (state) => ({   
    userId: state.user.id,    
    reduxState: state,        
});

export default withTranslation('common')(connect(mapStateToProps)(Asekuro));