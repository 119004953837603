// **********************************************************************
//                         GLOBAL EVENt ACTIONS
// **********************************************************************
export const GET_LOTS_WITH_ANIMALS_BY_FACILITYID = 'GET_LOTS_WITH_ANIMALS_BY_FACILITYID'
export const GET_LOTS_WITH_ANIMALS_BY_FACILITYID_RESPONSE = 'GET_LOTS_WITH_ANIMALS_BY_FACILITYID_RESPONSE'
export const GET_LOTS_WITH_ANIMALS_BY_FACILITYID_ERROR = 'GET_LOTS_WITH_ANIMALS_BY_FACILITYID_ERROR'

export const RESET_SELECTED_EVENT = 'RESET_SELECTED_EVENT'

const globalEventActions = {
    
    getLotsWithAnimalsByFacilityId(establishmentId, viewOnlyActiveLots) {
        return {
            type: GET_LOTS_WITH_ANIMALS_BY_FACILITYID,
            establishmentId,
            viewOnlyActiveLots
        }
    },
    getLotsWithAnimalsByFacilityIdResponse(response) {
        return {
            type: GET_LOTS_WITH_ANIMALS_BY_FACILITYID_RESPONSE,
            response
        }
    },
    getLotsWithAnimalsByFacilityIdError(message) {
        return {
            type: GET_LOTS_WITH_ANIMALS_BY_FACILITYID_ERROR,
            message
        }
    },
    resetSelectedEvent() {
        return {
            type: RESET_SELECTED_EVENT,
        }
    },
};
export default globalEventActions;