/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import CVPagination from '../../../shared/components/pagination/CVPagination';
import { withTranslation } from 'react-i18next';
import Search from "./components/Search";
import Filter from "./components/Filter";
import ColumnFilter from "./components/ColumnFilter";
import PropTypes from 'prop-types';
import DataTable from '../../../shared/components/table/DataTable';
import AlertIcon from 'mdi-react/AlertIcon';
import cowTransparent from "../../../shared/img/cow-transparent.gif";
//import CVSelect from "./components/MultiSelect"

const styles = {
    filterContainer: {
        flex: 2,
        marginRight:'15px',
        marginTop: '40px',
        backgroundColor: 'rgb(250,251,254)',
        borderRadius: 10,
        flexDirection: 'column'
    },
    tableContainer: {
        flex: 10,
        flex: 10,
        flexDirection:'row', 
        overflowX:'auto'
    }
};

const CVTableEventsPreview = (props) =>  {
    const [data, setData] = useState(null);
    const [heads, setHeads] = useState();
    const [numberRowsOK, setNumberRowsOK] = useState(0)
    const [numberRowsError, setNumberRowsError] = useState(0)


    const [fullData, setFullData] = useState([])
    const [totalRows, setTotalRows] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [animalsToShow, setAnimalsToShow] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(props.currentPage)
    const [itemToShow, setItemToShow] = useState(0)
    

    useEffect(() => {       
        if (data !== undefined && data !== null) {
            setFullData(data);
            setTotalRows(data.length)
            setTotalPages(Math.ceil( data.length/10))
            let animalsToShow = data.slice( ((currentPage-1) *pageSize),(currentPage*pageSize));
            setAnimalsToShow(animalsToShow)            
            setItemToShow(animalsToShow.length)
        }
    }, [data]);

    useEffect(() => {
        if (props.data) {
            let dataTemp = [];
            let rowNumber=2;
            let numberOK = 0;
            let numberError = 0;
            for (let row of props.data) {
                if (row.status=='error') {
                    let rowTemp = {...row,rowNumber:rowNumber}                    
                    dataTemp.push(rowTemp);
                    numberError++;
                } else {
                    numberOK++;
                }
                rowNumber++;
            }
            if(dataTemp.length>0) setData(dataTemp);        
            
            setNumberRowsOK(numberOK);
            setNumberRowsError(numberError);
            

        }
    }, [props.data]);


    useEffect(() => {
                
        let headsAux = [];
       
        headsAux.push(
        {
            key: 'rowNumber',
            name: 'Nro. de Fila',
            percentage: 7.6,
            minWidth: 40,
            visible: true,
            formatter: renderValueDefault
        })

        props.heads.forEach(head => {            
            headsAux.push({...head, visible: true})
        });
        
        setHeads(headsAux);
    }, [props.heads]);

    const setColumnVisibility = (columnId, visible) => {
        let headsAux = [...heads];
        headsAux.forEach(head => {
            if(head.key === columnId) head.visible = visible
        });
        setHeads(headsAux);
    };

    const onPageChange = (page) => {        
        //props.getPage(page, 10);
        let animalsToShow = data.slice( ((page-1) *pageSize),(page*pageSize))
        setAnimalsToShow(animalsToShow)            
        setItemToShow(animalsToShow.length)
        setCurrentPage(page);
    };

    //Not implemented yet.
    const onSorting = (sortColumn, sortDirection) => {
        return data;
    };

    const thereIsNoData = () =>  data.length === 0; //data === null || data === undefined ||

    const onFilter = (values) => {
        props.onFilter(values)
    };

    const processHeads = (heads) => {
        
        let filterHeads = heads.filter((head) => head.visible);

        let totalFilterPercentage = filterHeads.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.percentage
        }, 0);

        const calculateNewHeadPercentage = (oldPercentage, newTotalPercentage) => ((oldPercentage * 100)/newTotalPercentage);
        const tableWidth = window.innerWidth * (props.widthPercentage ? props.widthPercentage/100 : 0.65);

        filterHeads.forEach(head => {
            const headWidth = calculateNewHeadPercentage(head.percentage, totalFilterPercentage)/100 * tableWidth;
            head.width = headWidth < head.minWidth ? head.minWidth : headWidth
        });
        
        return  filterHeads;
    };

    const setAllColumnVisibility = (visibility) => {        
        let columnsVisibility = {};
        if(heads) {
            heads.forEach(head => {
                columnsVisibility[`${head.key}`] = visibility
            });
        }
        return columnsVisibility;
    };

    const { total } = props;

    const { CVSelect, SubtitleComponent, hideFilters, hidePagination } = props;

    const renderValueDefault = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value}
            </div>
        )
    };

    return (

        <Col md={12} lg={12}>
            <Card>
                <CardBody className="cv__table">
                    {   props.title ?
                        <div>
                            <div className="card__title">
                                <div className="cv-table-title">
                                    <h5 className="bold-text">{props.title}
                                    <br/>
                                    <span style={{fontWeight:"normal"}}>
                                        <br/>Animales reconocidos correctamente: <strong style={{color:'#0006ab'}}><b>{numberRowsOK}</b></strong>
                                        <br/>Animales <b>no</b> cargados (con errores): <strong style={{color:'red'}}><b>{numberRowsError}</b></strong>
                                        {  (numberRowsError>0) &&
                                            <div style={{textTransform:'none'}}>
                                            <br/>
                                            <b>... por favor corregí en tu excel los errores señalados y cargalo nuevamente.</b>                                            
                                            <br/><br/>Descripción de errores por Nro. de Fila (Excel)::
                                            </div>
                                        }
                                    </span>
                                    </h5>                                                                         
                                    <div style={{display: 'flex'}}>                                    
                                        {props.showSearchInput && <Search search={props.onSearch}/>}
                                        {
                                            props.showColumnFilter && heads &&
                                            <ColumnFilter columns={heads} setColumnVisibility={setColumnVisibility}
                                                          initialValues={setAllColumnVisibility(true)}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                SubtitleComponent ?
                                    <div className="subtitle__wrapper">
                                      <div className="subtitle__left">
                                        {CVSelect? <div><CVSelect /></div>: null}
                                      </div>
                                      <div className="subtitle__right">
                                        <SubtitleComponent/>
                                      </div>
                                    </div>
                                    : null
                            }
                        </div>:
                        null
                    }

                    {
                        props.pending ?
                            <div className="loading">
                                <img alt="cow" src={cowTransparent}/>
                            </div>:
                            data === null || numberRowsError==0 ?  <div></div>: thereIsNoData() ?
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <AlertIcon/>
                                    <h5 style={{marginLeft: '10px'}}>{props.noDataLabel}</h5>
                                </div>:
                                <div>
                                    <div style={{display: 'flex', flexDirection:'row', alignItems: 'flex-start'}}>
                                        {
                                            !hideFilters &&
                                            <div style={styles.filterContainer}>
                                                <Filter filters={props.filters} onSubmit={onFilter}/>
                                            </div>
                                        }
                                        
                                        <div style={styles.tableContainer}>
                                            <DataTable
                                                heads={processHeads(heads)}
                                                rows={animalsToShow}
                                            />
                                        </div>
                                    </div>
                                    {/*{
                                    !hidePagination &&
                                    <Pagination
                                        itemsCount={total}
                                        itemsToShow={data.length}
                                        pageOfItems={currentPage}
                                        onChangePage={onPageChange}
                                        appendClassName={"center"}
                                    />
                                }*/}
                                    {
                                        !hidePagination &&
                                        <CVPagination
                                            totalItems={fullData.length}
                                            totalPages={totalPages}
                                            currentPage={currentPage ? currentPage : 1}
                                            itemsToShow={itemToShow}
                                            pageSize={pageSize}
                                            onChangePage={onPageChange}
                                            appendClassName={"center"}
                                        />
                                    }
                                </div>
                    }
                </CardBody>
            </Card>
        </Col>
    );
};

CVTableEventsPreview.propTypes = {
    data: PropTypes.array,
    //  Required for pagination
    getPage: PropTypes.func,
    total: PropTypes.number, // Total items
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    pageSize: PropTypes.number,
    heads: PropTypes.array.isRequired,
    title: PropTypes.string,
    SubtitleComponent: PropTypes.func,
    CVSelect: PropTypes.func,
    hideFilters: PropTypes.bool,
    hidePagination: PropTypes.bool,
    showSearchInput: PropTypes.bool,
    onSearch: PropTypes.func,
    showColumnFilter: PropTypes.bool,
    onFilter: PropTypes.func,
    filters: PropTypes.array,
    widthPercentage: PropTypes.number,
    noDataLabel: PropTypes.string.isRequired,
    pending: PropTypes.bool
};

export default withTranslation('common')(CVTableEventsPreview)
