import {
    ADD_USER_COMPANY_RESPONSE,
    ADD_ESTABLISHMENT_TO_COMPANY_RESPONSE,
    EDIT_USER_AVATAR_RESPONSE,
    EDIT_USER_DATA_RESPONSE,
    GET_USER_ACCOUNTS_RESPONSE,
    GET_SURROGATE_USER_ACCOUNTS_RESPONSE,
    GET_SURROGATE_USER_ESTABLISHMENTS_RESPONSE,
    GET_USER_APPID_RESPONSE,
    GET_USER_PROFILE_RESPONSE,
    GET_USER_RESPONSE,
    GET_USER_ROLES_RESPONSE,
    SELECT_ESTABLISHMENT,
    SELECT_ESTABLISHMENT_LOT_MODE,
    SELECT_ESTABLISHMENT_LOT_TO_EDIT,
    SELECT_COMPANY,
    SELECT_LOT,
    ADD_ROLE_TO_USER_RESPONSE,
    ADD_ROLE_TO_USER_WITHOUT_LICENSE_RESPONSE,
    GET_USER_COMPANY_RESPONSE,
    EDIT_USER_COMPANY_RESPONSE,
    GET_USER_ESTABLISHMENTS_RESPONSE,
    GET_ALL_USER_ESTABLISHMENTS_RESPONSE,
    GET_USER_ESTABLISHMENT_BY_ID_RESPONSE,
    GET_ALL_USER_COMPANIES_RESPONSE,
    GET_ASSIGNED_COMPANIES_RESPONSE,
    RESET_ACCOUNT_DETAILS,
    RESET_ESTABLISHMENT_DETAILS,
    GET_USER_ESTABLISHMENTS_BY_COMPANY_RESPONSE,
    RESET_SELECTED_ESTABLISHMENT,
    RESET_SELECTED_LOT,
    GET_USER_ESTABLISHMENTS_BY_COMPANY,
    GET_USER_ESTABLISHMENTS_BY_COMPANIES_PAGINATED_RESPONSE,
    RESET_SELECTED_COMPANY,
    GET_PROFESSIONAL_USER_BY_DNI_RESPONSE,
    RESET_PROFESSIONAL_USER_SEARCH,
    GET_PROFESSIONALS_BY_ESTABLISHMENT_RESPONSE,
    ADD_PROFESSIONAL_TO_ESTABLISHMENT_RESPONSE,
    GET_ASSIGNED_ESTABLISHMENTS_RESPONSE,
    GET_SURROGATE_USER_BY_DNI_RESPONSE,
    RESET_SURROGATE_USER_SEARCH,
    GET_SURROGATES_BY_COMPANY_RESPONSE,
    GET_HAS_SLAUGHTERHOUSE_OR_COMMERCIAL_RESPONSE,
    ADD_SURROGATE_TO_COMPANY_RESPONSE,
    DELETE_USER_FROM_COMPANY_RESPONSE,
    DELETE_PROFESSIONAL_FROM_ESTABLISHMENT_RESPONSE,
    SEND_EMAIL_TO_UNREGISTERED_RESPONSE,
    ADD_ROLE_TO_USER_WITHOUT_LICENSE,
    ADD_ROLE_TO_USER_WITHOUT_LICENSE_ERROR,
    GET_COMPANY_SCOPE_ANIMALS_COUNT_RESPONSE,
    GET_COUNT_FACILITIES_BY_TYPE_RESPONSE
} from "../actions/userActions";

import {
    REGISTER_RESPONSE, FULL_REGISTER_RESPONSE
} from '../actions/sessionActions';

import { LOG_OUT } from "../actions/sessionActions";

const initialState = {
    id: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    appId: {
        id: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        name: undefined,
        apiKey: undefined
    },
    email: undefined,
    
    password: undefined,
    country: undefined,
    profile: {},
    roles: [],
    accounts: {
        results: [],
        page: 1,
        totalPages: undefined,
        total: 0,
        pageSize: undefined,
    },
    establishments: {
        results: [],
        page: 1,
        totalPages: undefined,
        total: 0,
        pageSize: undefined
    },
    selectedEstablishment: undefined,
    selectedEstablishmentLotMode: undefined,
    selectedEstablishmentLotToEdit: {
        name: undefined,
        details: undefined,
        _id: undefined
    },
    selectedCompany: undefined,
    selectedLot: undefined,
    selectedLots: [],
    accountDetails: undefined,
    allEstablishments: [],
    allEstablishmentsByCompany: [],
    establishmentDetails: undefined,
    allCompanies: [],
    professionalUserSearch: undefined,
    establishmentProfessionals: [],
    surrogateUserSearch: undefined,
    companySurrogates: [],
    inviteSent: '',
    animalsCountSelectedCompanyScope: {count:-1,max:0, context: {company:{legalName:'',tin:''},activeTier:{name:''},nextTier:{name:''}}},
    countFacilitiesByType: undefined
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_RESPONSE:
            return { ...state, ...action.user };
        case REGISTER_RESPONSE:
            return {
                ...state,
                ...action.res
            };
        case FULL_REGISTER_RESPONSE:
            return { ...state, ...action.res.user };
        case GET_USER_ROLES_RESPONSE:
            return { ...state, roles: action.roles };
        case GET_USER_APPID_RESPONSE:
            return { ...state, appId: action.appId};
        case GET_USER_PROFILE_RESPONSE:
            return { ...state, profile: action.profile};
        case EDIT_USER_DATA_RESPONSE:
            return {...state, ...action.response};
        case EDIT_USER_AVATAR_RESPONSE:
            return {...state, profile: {...state.profile, avatar: action.response.photo}};
        case GET_USER_ESTABLISHMENTS_RESPONSE:
            return {...state, establishments: action.response};
        case SELECT_ESTABLISHMENT:
            return {...state, selectedEstablishment: action.establishment};
        case SELECT_ESTABLISHMENT_LOT_MODE:
                return {...state, selectedEstablishmentLotMode: action.lotMode};
        case SELECT_ESTABLISHMENT_LOT_TO_EDIT:
            return {...state, selectedEstablishmentLotToEdit: action};
        case SELECT_COMPANY:
            return {...state, selectedCompany: action.company};
        case SELECT_LOT:
                return {...state, selectedLots: action.lot};
        case GET_USER_ACCOUNTS_RESPONSE:
            return {...state, accounts: action.response};
        case GET_SURROGATE_USER_ACCOUNTS_RESPONSE:
            return {...state, accounts: action.response};
        case GET_USER_ESTABLISHMENTS_BY_COMPANIES_PAGINATED_RESPONSE:
            return {...state, establishments: action.response};
        case GET_SURROGATE_USER_ESTABLISHMENTS_RESPONSE:
            return {...state, establishments: action.response};
        case ADD_USER_COMPANY_RESPONSE:
            return {...state, selectedCompany: action.response};
        case ADD_ESTABLISHMENT_TO_COMPANY_RESPONSE:
            return {...state};
        case ADD_ROLE_TO_USER_RESPONSE:
            return {...state, roles: action.response.roles};
        case ADD_ROLE_TO_USER_WITHOUT_LICENSE_RESPONSE:
                return {...state, roles: action.response.roles};          
        case GET_COMPANY_SCOPE_ANIMALS_COUNT_RESPONSE:            
            return {...state, animalsCountSelectedCompanyScope: action.response}
        case GET_USER_COMPANY_RESPONSE:
        case EDIT_USER_COMPANY_RESPONSE:
            return {...state, accountDetails: action.response};
        case GET_ALL_USER_ESTABLISHMENTS_RESPONSE:
            return {...state, allEstablishments: action.response};
        case GET_USER_ESTABLISHMENT_BY_ID_RESPONSE:
            return {...state, establishmentDetails: action.response};
        case GET_ALL_USER_COMPANIES_RESPONSE:
            return {...state, allCompanies: action.response};
        case GET_ASSIGNED_COMPANIES_RESPONSE:
            return { ...state, allCompanies: action.response };
        case RESET_ACCOUNT_DETAILS:
            return {...state, accountDetails: undefined};
        case RESET_ESTABLISHMENT_DETAILS:
            return {...state, establishmentDetails: undefined};
        case GET_USER_ESTABLISHMENTS_BY_COMPANY_RESPONSE:
            return {...state, allEstablishmentsByCompany: action.response};
        case RESET_SELECTED_ESTABLISHMENT:
            return {...state, selectedEstablishment: undefined};
        case RESET_SELECTED_LOT:
                return {...state, selectedLots: []};
        case RESET_SELECTED_COMPANY:
            return {...state, selectedCompany: undefined};
        case GET_PROFESSIONAL_USER_BY_DNI_RESPONSE:
            return {...state, professionalUserSearch: action.response};
        case RESET_PROFESSIONAL_USER_SEARCH:
            return {...state, professionalUserSearch: undefined};
        case GET_PROFESSIONALS_BY_ESTABLISHMENT_RESPONSE:
        case ADD_PROFESSIONAL_TO_ESTABLISHMENT_RESPONSE:
        case DELETE_PROFESSIONAL_FROM_ESTABLISHMENT_RESPONSE:
            return {...state, establishmentProfessionals: action.response};
        case GET_ASSIGNED_ESTABLISHMENTS_RESPONSE:
            return {...state, allEstablishmentsByCompany: action.response};
        case GET_SURROGATE_USER_BY_DNI_RESPONSE:
                return {...state, surrogateUserSearch: action.response};
        case RESET_SURROGATE_USER_SEARCH:
            return {...state, surrogateUserSearch: undefined};
        case GET_SURROGATES_BY_COMPANY_RESPONSE:
        case GET_HAS_SLAUGHTERHOUSE_OR_COMMERCIAL_RESPONSE:
            return {...state, hasSlaughterhouseOrCommercial: action.response};
        case ADD_SURROGATE_TO_COMPANY_RESPONSE:
        case DELETE_USER_FROM_COMPANY_RESPONSE:
            return { ...state, companySurrogates: action.response};
        case SEND_EMAIL_TO_UNREGISTERED_RESPONSE:
            return {...state, inviteSent: action.response}
        case GET_COUNT_FACILITIES_BY_TYPE_RESPONSE:
            return {...state, countFacilitiesByType: action.response}
            
        case LOG_OUT:
            return initialState;
        default:
            return state
    }
};
export default userReducer;