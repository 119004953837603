import React, { Component, Fragment } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import { history } from './store';
import { I18nextProvider } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from './Router';
import store, { persistor } from './store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import ScrollToTop from './ScrollToTop';
import i18n from '../../translations/config/i18n';

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <I18nextProvider i18n={i18n}>
              <ScrollToTop>
                <Fragment>
                  <Router />
                </Fragment>
              </ScrollToTop>
            </I18nextProvider>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default hot(module)(App);
