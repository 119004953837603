import React from 'react';
import { Redirect, Route } from "react-router-dom";

const CVRouteByRole = ({component: Component, authorizedRoles, userRoles, ...rest}) => (
    <Route {...rest} render={props => {
        if(hasAccess(userRoles, authorizedRoles)) return <Component/>;
        return <Redirect to={{pathname: '/404', state: {from: props.location}}}/>
    }}/>
);

const hasAccess = (userRoles, authorizedRoles) => {
    return userRoles.some((userRole) => {
        return authorizedRoles.some((authorizedRol) => (authorizedRol === userRole.name))
    })
};

export default CVRouteByRole;