import React, {PureComponent} from 'react';
import {withTranslation} from 'react-i18next';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import MailRuIcon from 'mdi-react/MailRuIcon';
import CalendarIcon from 'mdi-react/CalendarIcon';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';
import renderSelectField from '../../../../shared/components/form/Select';
import {formValidators, forceLowerCase} from '../../../../util/index';
import renderCheckBoxField from "../../../../shared/components/form/CheckBox";
import { connect } from "react-redux";
import generalDataActions from '../../../../redux/actions/generalDataActions';
import settings from '../../../../util/settings';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

const minLength8 = formValidators.minLength(8);

class RegisterForm extends PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
        };
        this.showPassword = this.showPassword.bind(this);
    }

    componentDidMount() {
        this.props.getAvailableCountries();
    }

    showPassword(e) {
        e.preventDefault();
        this.setState(prevState => ({showPassword: !prevState.showPassword}));
    }

    showErrors = (invalid) => {
        if(invalid) ToastsStore.error(this.props.t('common.general_invalid_form'), 10000)
    }

    changeHandler = ({ target }) => {
        target.value = target.value.toUpperCase()
    }

    validateEqual = value => this.props.password === value ? undefined : 'passwords_not_match';

    renderField = ({input, placeholder, type, meta: {touched, error, warning}}) => (
      <div style={{width: '100%'}}>
        <input style={{backgroundColor: 'white'}} {...input} placeholder={placeholder} type={type} autoComplete="new-password"/>
        <div className="input-error-container">
          {touched && ((error &&
            <span className="error-message">{this.props.t(`errors.${error}`)}</span>) || (warning &&
            <span className="error-message">{warning}</span>))}
        </div>
      </div>
    );

    render() {
        const {handleSubmit, t} = this.props;
        const {showPassword} = this.state;
        return (
            <form className="form" onSubmit={handleSubmit}>
              <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
                <div className="form__form-group">
                    <span className="form__form-group-label">{t('register.email')}</span>
                    <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                            <MailRuIcon/>
                        </div>
                        <Field
                            name="email"
                            component={this.renderField}
                            validate={[formValidators.validateRequired, formValidators.validateEmail]}
                            type="email"
                            normalize={(value) => value.toLowerCase()}
                            placeholder={t('register.email')}
                        />
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">{t('register.password')}</span>
                    <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                            <KeyVariantIcon/>
                        </div>
                        <Field
                            name="password"
                            component={this.renderField}
                            validate={[formValidators.validateRequired, minLength8]}
                            type={showPassword ? 'text' : 'password'}
                            placeholder={t('register.password')}
                        />
                        <button
                            type="button"
                            className={`form__form-group-button${showPassword ? ' active' : ''}`}
                            onClick={e => this.showPassword(e)}
                        ><EyeIcon/>
                        </button>
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">{t('register.repeat_password')}</span>
                    <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                            <KeyVariantIcon/>
                        </div>
                        <Field
                            name="confirm_password"
                            component={this.renderField}
                            validate={[formValidators.validateRequired, this.validateEqual, minLength8]}
                            type={showPassword ? 'text' : 'password'}
                            placeholder={t('register.repeat_password')}
                        />
                        <button
                            type="button"
                            className={`form__form-group-button${showPassword ? ' active' : ''}`}
                            onClick={e => this.showPassword(e)}
                        ><EyeIcon/>
                        </button>
                    </div>
                </div>
                <div className="form__form-group">
                    <span className="form__form-group-label">{t('register.country')}</span>
                    <div className="form__form-group-field">
                        <Field
                            name="country"
                            component={renderSelectField}
                            validate={formValidators.validateRequired}
                            type="text"
                            options={
                                this.props.availableCountries.map(country => ({
                                    value: country.value,
                                    label: t(`countries.${country.value}`)
                                }))
                            }
                            placeholder={t('register.selectCountry')}
                        />
                    </div>
                </div>
                <div className="form__form-group">
                    <div className="form__form-group-field">
                        <Field
                            name="termsAndConditions"
                            component={renderCheckBoxField}
                            validate={formValidators.validateRequired}
                        />
                        <div
                          style={{
                            color: '#646777',
                            position: 'absolute',
                            float: 'left',
                            left: '25px'}}
                        >
                          {t('register.terms_and_conditions_label')}
                          <a href={settings.termsUrl} target={"_blank"}>{t('register.terms_and_conditions')}</a>
                        </div>
                    </div>
                </div>
                <div style={{marginBottom:"10px",padding:"0px"}} className="error-container">
                    {
                        this.props.registerError &&
                        <span className="error-message">{t(`errors.${this.props.registerErrorMessage}`)}</span>
                    }
                </div>
                
                <div className="account__btns">
                    <Button className="btn btn-primary account__btn" type="submit">{t('register.register')}</Button>
                </div>
            </form>
        );
    }
}

const selector = formValueSelector('register_form');

const mapDispatchToProps = (dispatch) => ({
    getAvailableCountries: () => dispatch(generalDataActions.getAvailableCountries())
});

const mapStateToProps = (state) => ({
    availableCountries: state.generalData.availableCountries,
    password: selector(state, 'password')
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(reduxForm({
    form: 'register_form', // a unique identifier for this form
})(RegisterForm)));