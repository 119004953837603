import React from 'react'
import { Card, CardBody } from 'reactstrap';

export class ErrorNoEstablishment extends React.Component { 
    render(){
        return (   
            <Card>
                <CardBody className="cv__table">
                    <div className="card__title">
                        <div className="cv-table-title">
                            <h5 className="bold-text">
                                No se puede mostrar Tabla de Eventos porque no existen establecimientos en esta empresa
                            </h5>
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export class ErrorSlaughterNotValid extends React.Component { 
    render(){
        return (   
            <Card>
                <CardBody className="cv__table">
                    <div className="card__title">
                        <div className="cv-table-title">
                            <h5 className="bold-text">
                                Esta sección no está disponible para este tipo de establecimiento<br/><br/>
                                <p>Para un evento de Faena se debe seleccionar previamente un establecimiento frigorífico</p> 
                                <p>En el caso de "Faena en Terceros" el establecimiento selecionado debe ser comercializador</p>
                            </h5>
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }
}