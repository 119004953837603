import {        
    EDIT_CERTIFICATE_RESPONSE,
    GET_FACILITY_CERTIFICATES_RESPONSE,
    GET_FACILITY_CERTIFICATES_ERROR,
    GET_CERTIFICATE_BY_ID_RESPONSE,
    GET_CERTIFICATE_BY_ID_ERROR,
    NEW_CERTIFICATE_RESPONSE,
} from "../actions/certificateActions";
import {RESET_SELECTED_ESTABLISHMENT} from "../actions/userActions";

const initialState = {
    certificates: {
        results: [],        
    },
    certificateDetails: {},
    certificate_types: {        
          //sanitary: [{id:"1", toDateRequired:true, fromDateRequired: true, dateLabel: 'validity'},{id:"2", fromDateRequired:true, dateLabel: 'vaccination'},{id:"3",fromDateRequired:true, toDateRequired:true, dateLabel: 'validity'},{id:"4", fromDateRequired: true, dateLabel:'vaccination'},{id:"10000", detailRequired:true, dateLabel: 'validity'}],                     
          sanitary: [{id:"1", toDateRequired:true, fromDateRequired: true, dateLabel: 'validity'},{id:"3",fromDateRequired:true, toDateRequired:true, dateLabel: 'validity'},{id:"10000", detailRequired:true, dateLabel: 'validity'}],                     
          productive: [{id:"5", toDateRequired:true, fromDateRequired: true, dateLabel: 'validity'},{id:"6", toDateRequired:true, fromDateRequired: true, dateLabel: 'validity'},{id:"7", dateLabel: 'validity'},{id:"8", dateLabel: 'validity'},{id:"10001", detailRequired:true, dateLabel: 'validity'}],                     
          management: [{id:"9", toDateRequired:true, fromDateRequired: true, dateLabel: 'validity'},{id:"10", toDateRequired:true, fromDateRequired: true, dateLabel: 'validity'},{id:"10002", detailRequired:true, dateLabel: 'validity'}],                  
      }        
};

const certificateReducer = (state = initialState, action) => {            
    switch (action.type) {               
        case NEW_CERTIFICATE_RESPONSE:                         
            return {
                ...state,
                certificateDetails: action.response,
            }
        case EDIT_CERTIFICATE_RESPONSE:
            return {
                ...state,
                certificateDetails: initialState.certificateDetails,
            };
        case GET_FACILITY_CERTIFICATES_RESPONSE:                
                return {
                    ...state,
                    certificates: action.response.results,
                };        
        case GET_FACILITY_CERTIFICATES_ERROR:                
                return {
                    ...state,
                    certificates: initialState.certificates.results,
                };        
        case GET_CERTIFICATE_BY_ID_RESPONSE:                                
                return {
                    ...state,
                    certificateDetails: action.response.results,
                };        
        case GET_CERTIFICATE_BY_ID_ERROR:                                
                return {
                    ...state,
                    certificateDetails: initialState.certificateDetails,
                };        
        default:
            return state
    }
};
export default certificateReducer;