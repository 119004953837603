/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import CVPagination from '../../../shared/components/pagination/CVPagination';
import { withTranslation } from 'react-i18next';
import Search from "./components/Search";
import Filter from "./components/Filter";
import ColumnFilter from "./components/ColumnFilter";
import PropTypes from 'prop-types';
import DataTable from '../../../shared/components/table/DataTable';
import AlertIcon from 'mdi-react/AlertIcon';
import cowTransparent from "../../../shared/img/cow-transparent.gif";
//import CVSelect from "./components/MultiSelect"

// TODO este archivo es identico a CVTable salvo dentro de  "onPageChange". Se creo por un problema en la paginación de eventos.
// Eventualmente deberíamos ver si tiene sentido llevar todo a un mismo archivo.


const styles = {
    filterContainer: {
        flex: 2,
        marginRight:'15px',
        marginTop: '40px',
        backgroundColor: 'rgb(250,251,254)',
        borderRadius: 10,
        flexDirection: 'row'
    },
    tableContainer: {
        flex: 10,
        flexDirection:'row',
        overflowX:'auto'
    }
};

const CVTableEvents = (props) =>  {
    const [data, setData] = useState();
    const [heads, setHeads] = useState();

    useEffect(() => {
        if(props.data) setData(props.data);
    }, [props.data]);


    useEffect(() => {
        let headsAux = [];
        props.heads.forEach(head => {
            headsAux.push({...head, visible: true})
        });
        setHeads(headsAux);
    }, [props.heads]);

    const setColumnVisibility = (columnId, visible) => {
        let headsAux = [...heads];
        headsAux.forEach(head => {
            if(head.key === columnId) head.visible = visible
        });
        setHeads(headsAux);
    };

    const onPageChange = (page) => {
        props.getPage(props.establishmentId,page, props.pageSize);
    };

    //Not implemented yet.
    const onSorting = (sortColumn, sortDirection) => {
        return data;
    };

    const thereIsNoData = () => data === null || data === undefined || data.length === 0;

    const onFilter = (values) => {
        props.onFilter(values)
    };

    const processHeads = (heads) => {
        let filterHeads = heads.filter((head) => head.visible);

        let totalFilterPercentage = filterHeads.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.percentage
        }, 0);

        const calculateNewHeadPercentage = (oldPercentage, newTotalPercentage) => ((oldPercentage * 100)/newTotalPercentage);
        const tableWidth = window.innerWidth * (props.widthPercentage ? props.widthPercentage/100 : 0.65);

        filterHeads.forEach(head => {
            const headWidth = calculateNewHeadPercentage(head.percentage, totalFilterPercentage)/100 * tableWidth;
            head.width = headWidth < head.minWidth ? head.minWidth : headWidth
        });
        return  filterHeads;
    };

    const setAllColumnVisibility = (visibility) => {
        let columnsVisibility = {};
        if(heads) {
            heads.forEach(head => {
                columnsVisibility[`${head.key}`] = visibility
            });
        }
        return columnsVisibility;
    };

    const { total, currentPage } = props;

    const { CVSelect, SubtitleComponent, hideFilters, hidePagination } = props;

    return (

        <Col md={12} lg={12}>
            <Card>
                <CardBody className="cv__table">
                    {   props.title ?
                        <div>
                            <div className="card__title">
                                <div className="cv-table-title">
                                    <h5 className="bold-text">{props.title}</h5>
                                    <div style={{display: 'flex'}}>
                                        {props.showSearchInput && <Search search={props.onSearch}/>}
                                        {
                                            props.showColumnFilter && heads &&
                                            <ColumnFilter columns={heads} setColumnVisibility={setColumnVisibility}
                                                          initialValues={setAllColumnVisibility(true)}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                SubtitleComponent ?
                                    <div className="subtitle__wrapper">
                                      <div className="subtitle__left">
                                        {CVSelect? <div><CVSelect /></div>: null}
                                      </div>
                                      <div className="subtitle__right">
                                        <SubtitleComponent/>
                                      </div>
                                    </div>
                                    : null
                            }
                        </div>:
                        null
                    }


                    {   
                        props.recognizedAnimals &&
                            
                            <div className="card">
                                        <div style={{display:'flex', flexDirection:'row',justifyContent: 'left', 
                                        alignItems: 'center', fontSize:'12px', textTransform:'uppercase'}}>                                            
                                            Animales reconocidos correctamente:&nbsp;<strong style={{color:'#0006ab'}}><b>{props.recognizedAnimals.correct}</b></strong>
                                        </div>

                                        <div style={{display:'flex', flexDirection:'row',justifyContent: 'left', 
                                        alignItems: 'center', fontSize:'12px', textTransform:'uppercase'}}>
                                            Animales&nbsp;<b>no</b>&nbsp;cargados (con errores):&nbsp;<strong style={{color:'red'}}><b>{props.recognizedAnimals.incorrect}</b></strong>
                                        </div>
                                        {  (props.recognizedAnimals.incorrect>0) &&
                                            <div style={{textTransform:'none'}}>
                                            <br/>
                                            <b>... por favor corregí en tu excel los errores señalados y cargalo nuevamente.</b>                                            
                                            <br/><br/>Descripción de errores por Nro. de Fila (Excel)::
                                            </div>
                                        }
                            </div>
                    }

                    {
                        props.pending ?
                            <div className="loading">
                                <img alt="cow" src={cowTransparent}/>
                            </div>:
                            thereIsNoData() ?
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <AlertIcon/>
                                    <h5 style={{marginLeft: '10px'}}>{props.noDataLabel}</h5>
                                </div>:
                                <div>
                                    <div style={{display: 'flex', flexDirection:'row', alignItems: 'flex-start'}}>
                                        {
                                            !hideFilters &&
                                            <div style={styles.filterContainer}>
                                                <Filter filters={props.filters} onSubmit={onFilter}/>
                                            </div>
                                        }
 
                                        <div style={styles.tableContainer}> 
                                            <DataTable
                                                heads={processHeads(heads)}
                                                rows={data}
                                            />
                                        </div>
                                    </div>
                                    {/*{
                                    !hidePagination &&
                                    <Pagination
                                        itemsCount={total}
                                        itemsToShow={data.length}
                                        pageOfItems={currentPage}
                                        onChangePage={onPageChange}
                                        appendClassName={"center"}
                                    />
                                }*/}
                                    {
                                        !hidePagination &&
                                        <CVPagination
                                            totalItems={total ? total : 0}
                                            totalPages={props.totalPages}
                                            currentPage={currentPage ? currentPage : 1}
                                            itemsToShow={data.length}
                                            pageSize={props.pageSize}
                                            onChangePage={onPageChange}
                                            appendClassName={"center"}
                                            establishmentId={props.establishmentId}
                                        />
                                    }
                                </div>
                    }
                </CardBody>
            </Card>
        </Col>
    );
};

CVTableEvents.propTypes = {
    data: PropTypes.array,
    //  Required for pagination
    getPage: PropTypes.func,
    total: PropTypes.number, // Total items
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    pageSize: PropTypes.number,
    heads: PropTypes.array.isRequired,
    title: PropTypes.string,
    SubtitleComponent: PropTypes.func,
    CVSelect: PropTypes.func,
    hideFilters: PropTypes.bool,
    hidePagination: PropTypes.bool,
    showSearchInput: PropTypes.bool,
    onSearch: PropTypes.func,
    showColumnFilter: PropTypes.bool,
    onFilter: PropTypes.func,
    filters: PropTypes.array,
    widthPercentage: PropTypes.number,
    noDataLabel: PropTypes.string.isRequired,
    pending: PropTypes.bool
};

export default withTranslation('common')(CVTableEvents)
