export const TIMEOUT = 'TIMEOUT';
export const GENERIC_ERROR = 'GENERIC_ERROR';

export const sessionErrors = {
    USER_NOT_FOUND_IN_THE_SYSTEM: "USER_NOT_FOUND_IN_THE_SYSTEM",
    USER_ALREADY_REGISTER: "USER_ALREADY_REGISTER",
    WRONG_CREDENTIALS: "WRONG_CREDENTIALS",
    EMAIL_NOT_VERIFIED: "EMAIL_NOT_VERIFIED",
    USER_ALREADY_REGISTER_EMAIL_NOT_VERIFIED: "USER_ALREADY_REGISTER_EMAIL_NOT_VERIFIED",
};

export const animalErrors = {
    EXCEL_HAS_NO_VALID_ROWS: 'EXCEL_HAS_NO_VALID_ROWS'
};

export const eventErrors = {
    EXCEL_HAS_NO_VALID_ROWS: 'EXCEL_HAS_NO_VALID_ROWS'
};