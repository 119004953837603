import React from 'react';
import DatePicker from "react-date-picker";
import i18n from '../../../translations/config/i18n';

const CVDatePicker = (props) => {

    const { value, onChange, disabled, showFutureDates } = props;

    return (
        <DatePicker
            onChange={onChange}
            value={value ? new Date(value) : undefined}
            className={"cv_date_picker"}
            calendarClassName={"cv_date_picker_calendar"}
            disabled={disabled}
            calendarIcon={ disabled ? null:undefined}
            clearIcon={disabled ? null:undefined}
            maxDate={showFutureDates? null:new Date}
        />
    )
};

export const renderDateInputField = ({input, disabled, showFutureDates, meta: {touched, error, warning}}) => (
    <div style={{width: '100%'}}>
        <CVDatePicker
            {...input}
            disabled={disabled}
            showFutureDates={showFutureDates}
        />
        <div className="input-error-container">
            {touched && ((error && <span className="error-message">{i18n.t(`common:errors.${error}`)}</span>) ||
                (warning && <span className="error-message">{warning}</span>))}
        </div>
    </div>
);