import React from 'react';
import Layout from '../../../Layout/index';
import Account from './Account';
import Notifications from './Notifications';
import Animals from './Animals';
import Wallet from './Wallet';
import Users from './Users';
import Roles from './Roles';
import GeneralData from './GeneralData';
import Events from './Events';
import UserAccounts from './UserAccounts'
import UserEstablishments from './UserEstablishments'
import BI from './BI'
import Templates from './Templates'
import QrGeneration from './QrGeneration'
import Certificates from './Certificates'
import Reports from './Reports'
import Asekuro from './Asekuro'
import Karbono from './Karbono'
import GDP from './GDP'

import DefaultDashboard from '../../../Dashboards/index';
import AdminDashboard from '../../../Dashboards/Admin/index';
import TitularDashboard from '../../../Dashboards/Titular/index';
import VeterinaryDashboard from '../../../Dashboards/Veterinary/index';
import AgriculturalEngineerDashboard from '../../../Dashboards/AgriculturalEngineer/index';

import CVRouteByRole from '../CVRoutes/CVRouteByRole';
import { ROLES } from '../../../../constants';
import {connect} from 'react-redux';

const Wrapper = (props) => (
    <div>
        <Layout/>
        <div className="container__wrap">
            <CVRouteByRole exact path="/" component={DefaultDashboard} authorizedRoles={[ROLES.ADMIN, ROLES.TITULAR, ROLES.AGRICULTURAL_ENGINEER, ROLES.VETERINARY, ROLES.SURROGATE, ROLES.WITH_OUT_ROLE]}
                           userRoles={props.userRoles}/>
            <CVRouteByRole path="/dashboards_agricultural_engineer" component={AgriculturalEngineerDashboard} authorizedRoles={[ROLES.AGRICULTURAL_ENGINEER]}
                           userRoles={props.userRoles}/>
            <CVRouteByRole path="/account" component={Account} authorizedRoles={[ROLES.WITH_OUT_ROLE, ROLES.ADMIN, ROLES.TITULAR, ROLES.AGRICULTURAL_ENGINEER, ROLES.VETERINARY, ROLES.SURROGATE]}
                           userRoles={props.userRoles}/>
            <CVRouteByRole path="/animals" component={Animals} authorizedRoles={[ROLES.ADMIN, ROLES.TITULAR, ROLES.VETERINARY, ROLES.AGRICULTURAL_ENGINEER, ROLES.SURROGATE]}
                           userRoles={props.userRoles}/>
            <CVRouteByRole path="/events" component={Events} authorizedRoles={[ROLES.WITH_OUT_ROLE, ROLES.ADMIN, ROLES.VETERINARY, ROLES.SURROGATE, ROLES.TITULAR, ROLES.AGRICULTURAL_ENGINEER]} userRoles={props.userRoles}/>
            <CVRouteByRole path="/manage_accounts" component={UserAccounts} authorizedRoles={[ROLES.ADMIN, ROLES.TITULAR, ROLES.SURROGATE]}
                           userRoles={props.userRoles}/>
            <CVRouteByRole path="/manage_establishments" component={UserEstablishments} authorizedRoles={[ROLES.ADMIN, ROLES.SURROGATE, ROLES.TITULAR]}
                           userRoles={props.userRoles}/>
            <CVRouteByRole path="/bi" component={BI} authorizedRoles={[ROLES.ADMIN, ROLES.TITULAR, ROLES.SURROGATE, ROLES.VETERINARY, ROLES.AGRICULTURAL_ENGINEER]}
                           userRoles={props.userRoles}/>
            <CVRouteByRole path="/templates" component={Templates} authorizedRoles={[ROLES.ADMIN, ROLES.TITULAR, ROLES.SURROGATE]}
                           userRoles={props.userRoles}/>
            <CVRouteByRole path="/qrGeneration" component={QrGeneration} authorizedRoles={[ROLES.ADMIN, ROLES.TITULAR, ROLES.SURROGATE]}
                           userRoles={props.userRoles}/> 
            <CVRouteByRole path="/certificates" component={Certificates} authorizedRoles={[ROLES.ADMIN, ROLES.TITULAR, ROLES.SURROGATE]}
                           userRoles={props.userRoles}/>
            <CVRouteByRole path="/reports" component={Reports} authorizedRoles={[ROLES.ADMIN, ROLES.TITULAR, ROLES.SURROGATE]}
                           userRoles={props.userRoles}/>      
            <CVRouteByRole path="/asekuro" component={Asekuro} authorizedRoles={[ROLES.ADMIN, ROLES.TITULAR, ROLES.SURROGATE]}
                           userRoles={props.userRoles}/>      
            <CVRouteByRole path="/karbono" component={Karbono} authorizedRoles={[ROLES.ADMIN, ROLES.TITULAR, ROLES.SURROGATE]}
                           userRoles={props.userRoles}/>
            <CVRouteByRole path="/GDP" component={GDP} authorizedRoles={[ROLES.ADMIN, ROLES.TITULAR, ROLES.SURROGATE]}
                           userRoles={props.userRoles}/>   
            
            {/* todo uncomment this when 'notifications' is supported*/}
            {/*<CVRouteByRole path="/notifications" component={Notifications} authorizedRoles={[ROLES.ADMIN, ROLES.TITULAR]} userRoles={props.userRoles}/>*/}
            {/* todo uncomment this when 'admin dashboard' is supported*/}
            {/*<CVRouteByRole path="/dashboard_admin" component={AdminDashboard} authorizedRoles={[ROLES.ADMIN]}*/}
            {/*               userRoles={props.userRoles}/>*/}
            {/* todo uncomment this when 'titular dashboard' is supported*/}
            {/*<CVRouteByRole path="/dashboard_titular" component={TitularDashboard} authorizedRoles={[ROLES.TITULAR]}
                           userRoles={props.userRoles}/>*/}
            {/*<CVRouteByRole path="/dashboard_veterinary" component={VeterinaryDashboard} authorizedRoles={[ROLES.VETERINARY]}
                           userRoles={props.userRoles}/>*/}
            {/* todo uncomment this when 'wallet' is supported*/}
            {/*<CVRouteByRole path="/wallet" component={Wallet} authorizedRoles={[ROLES.ADMIN, ROLES.TITULAR]} userRoles={props.userRoles}/>*/}
            {/* todo uncomment this when 'users' is supported*/}
            {/*<CVRouteByRole path="/users" component={Users} authorizedRoles={[ROLES.ADMIN]} userRoles={props.userRoles}/>*/}
            {/* todo uncomment this when 'roles' is supported*/}
            {/*<CVRouteByRole path="/roles" component={Roles} authorizedRoles={[ROLES.ADMIN]} userRoles={props.userRoles}/>*/}
            {/* todo uncomment this when 'general data' is supported*/}
            {/*<CVRouteByRole path="/generalData" component={GeneralData} authorizedRoles={[ROLES.ADMIN]}
                           userRoles={props.userRoles}/>*/}
        </div>
    </div>
);

const mapStateToProps = (state) => ({
    userRoles: state.user.roles
});

export default connect(mapStateToProps)(Wrapper)

