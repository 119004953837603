import setting from '../../util/settings';

import {    
    SELECT_ESTABLISHMENT_MICROSITE,    
    SELECT_COMPANY_MICROSITE,    
    GET_USER_ESTABLISHMENT_BY_ID_MICROSITE_RESPONSE,
    GET_ALL_USER_COMPANIES_MICROSITE_RESPONSE,
    GET_ASSIGNED_COMPANIES_MICROSITE_RESPONSE,
    GET_USER_ESTABLISHMENTS_BY_COMPANY_MICROSITE_RESPONSE,    
    GET_USER_ESTABLISHMENTS_BY_COMPANY_MICROSITE,    
} from "../actions/templateActions";

import {
    REGISTER_RESPONSE, FULL_REGISTER_RESPONSE
} from '../actions/sessionActions';

import { LOG_OUT } from "../actions/sessionActions";

const initialState = {    
    selectedEstablishment: undefined,    
    selectedCompany: undefined,    
    allEstablishmentsByCompany: [],
    establishmentDetails: {animalLots:{lots:[]}},
    allCompanies: [],    
    templates: [
        {type:1, name:'QR IDENTECO por Establecimiento', scope:2, url: setting.baseUrlForAnimalQRTemplate+"/edit",tooltipEditTemplate: "Para elegir qué mostrar en el reporte 'QR Identeco' del establecimiento seleccionado apretá sobre Editar Plantilla."},        
        {type:2, name:'QR IDENTECO TRUPO por Establecimiento', scope:2, url:setting.baseUrlForAnimalQRIdentecoTrupoTemplate+"/edit", tooltipEditTemplate: "Para editar el Identeco Trupo del establecimiento seleccionado apretá sobre Editar Plantilla."},
        {type:4, name:'MIKROSITO por Establecimiento', scope:2, url:setting.baseUrlForAnimalMicrosite+"/microsite/edit", tooltipEditTemplate: "Para editar el Mikrosito del establecimiento seleccionado apretá sobre Editar Plantilla."},
        {type:5, name:'PRODUKTO TRUPO', scope:2, url:setting.baseUrlForAnimalMicrosite+"/trupoProdukto/list", tooltipEditTemplate: "Para editar el Produckto Trupo del establecimiento seleccionado apretá sobre Editar Plantilla."},
        
        //scope1: producto de Empresa  
        //scope2: Establecimiento
        //scope3: Lotes (No sé uso) 
    ],
};

const templateReducer = (state = initialState, action) => {
    switch (action.type) {        
        case SELECT_ESTABLISHMENT_MICROSITE:
            return {...state, selectedEstablishment: action.establishment};        
        case SELECT_COMPANY_MICROSITE:
            return {...state, selectedCompany: action.company};        
        case GET_USER_ESTABLISHMENT_BY_ID_MICROSITE_RESPONSE:
            return {...state, establishmentDetails: action.response};
        case GET_ALL_USER_COMPANIES_MICROSITE_RESPONSE:
            return {...state, allCompanies: action.response};
        case GET_ASSIGNED_COMPANIES_MICROSITE_RESPONSE:
            return { ...state, allCompanies: action.response };        
        case GET_USER_ESTABLISHMENTS_BY_COMPANY_MICROSITE_RESPONSE:
            return {...state, allEstablishmentsByCompany: action.response};        
        default:
            return state
    }
};
export default templateReducer;