export const ROLES = {
    ADMIN: 'ADMIN',
    WITH_OUT_ROLE: 'WITH_OUT_ROLE',
    TITULAR: 'titular',
    VETERINARY: 'veterinary',
    AGRICULTURAL_ENGINEER: 'agriculturalEngineer',
    SURROGATE: 'surrogate'
};

export const SIDEBAR_LINKS = {
    establishments: 'ESTABLISHMENTS',
    establishmentLots: 'ESTABLISHMENTLOTS',
    animals: 'ANIMALS',
    events: 'EVENTS',
    weight: 'WEIGHT',
    productive_system_change: "PRODUCTIVE_SYSTEM_CHANGE",
    dentition: 'DENTITION',
    reidentification: 'REIDENTIFICATION',
    castration: 'CASTRATION',
    recategorization: 'RECATEGORIZATION',
    slaughter: 'SLAUGHTER',
    third_party_slaughter: 'THIRD_PARTY_SLAUGHTER',
    deletion: 'DELETION',
    lot: 'LOT',
    transfer: 'TRANSFER',
    notifications: 'NOTIFICATIONS',
    users: 'USERS',
    roles: 'ROLES',
    permissions: 'PERMISSIONS',
    accounts: 'ACCOUNTS',
    generalData: 'GENERAL_DATA',
    selectRole: 'SELECT_ROLE',
    bi: 'BI',
    microsite: 'MICROSITE',
    qrGeneration: 'QR_GENERATION',
    certificates: 'CERTIFICATES',
    help: 'HELP',
    reports: 'REPORTS',
    asekuro:'ASEKURO',
    karbono:'KARBONO',
    tools: 'TOOLS',
    gdp: 'GDP'
};

export const ERRORS = {
    required: 'REQUIRED',
    invalidEmail: 'INVALID_EMAIL',
    invalidRENSPA: 'INVALID_RENSPA',
    shortPassword: 'SHORT_PASSWORD',
    invalidCowWeight: 'INVALID_COW_WEIGHT',
    invalidLatCoordinate: 'INVALID_LAT_COORDINATE',
    invalidLonCoordinate: 'INVALID_LON_COORDINATE',
    invalidDate: "INVALID_DATE",
    invalidMonthAge: "INVALID_MONTH_AGE",
    invalidCaravanId: "INVALID_CARAVAN_ID",
    invalidURL: 'INVALID_URL',
};

export const EVENT_TYPES = {
    weight: 'weightEvent',
    dentition: 'dentitionEvent',
    castration: 'castrationEvent',
    reidentification: 'reidentificationEvent',
    change_of_productive_system: 'productiveSystemChangeEvent',
    change_of_category: 'recategorizationEvent',
    fain: 'slaughterEvent',
    vaccination: 'vaccinationEvent',
    recategorization: 'recategorizationEvent',
    third_party_slaughter: 'thirdPartySlaughterEvent',
    transfer: 'transferEvent',
    deletion: 'deletionEvent',
    lot: 'lotEvent',
    globalTransfer:'globalTransferEvent'
};

export const ESTABLISHMENT_TYPES = {
    commercial: 'commercial',
    slaughterhouse: 'slaughterhouse',
    productive: 'productive',
    importer: 'importer'
};

export const NOT_ASSIGNED = 'none'
export const LOT_MODE_DISABLED = 'lotModeDisabled'