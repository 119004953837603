import React, { useEffect, useState } from 'react';
import { withTranslation } from "react-i18next";
import {Link,Tooltip} from "@material-ui/core";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { withStyles, makeStyles } from '@material-ui/core/styles';
//import InfoIcon from "mdi-react/InfoCircleOutlineIcon"

import ClickAwayListener from '@material-ui/core/ClickAwayListener';


const Helpi = (props) => {

    const {title,size,iconColor,fontColor,isHtml,fontSize, style}=props
    const [open, setOpen] = React.useState(false);
    const [platformToolTipsEnable, setPlatformToolTipsEnable] = useState(true);
    const [textFontColor,setTextFontColor] = useState("white");
    const [textFontSize,setTextFontSize] = useState("14px");


    const LightTooltip = withStyles((theme) => ({
        tooltip: {
          //backgroundColor: 'rgba(147, 147, 147, 0.87)',
          //color: 'rgba(255, 255, 255, 0.87)',
          fontSize: textFontSize,
          boxShadow: theme.shadows[1],
        },
      }))(Tooltip);




    const handleTooltipClose = () => {
        setOpen(false);
        };

    const handleTooltipOpen = () => {
    setOpen(true);
    };

    useEffect(()=>{
        setTextFontColor(fontColor)
    },[fontColor])

    useEffect(()=>{
        if (fontSize!==undefined) {
            setTextFontSize(fontSize)
        }
    },[fontSize])



    return (
        <a style={style}>
            { platformToolTipsEnable && title!=="" &&
                <ClickAwayListener onClickAway={handleTooltipClose}>
                            <LightTooltip
                                onClose={handleTooltipClose}
                                open={open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                interactive={true}
                                title={
                                    isHtml ?
                                    <React.Fragment>
                                        <div
                                        dangerouslySetInnerHTML={{
                                        __html: title //DOMPurify.sanitize(title)
                                        }}/>
                                    </React.Fragment>
                                    :
                                    title
                        }
                                arrow>
                                    <a>
                                        <HelpOutlineIcon
                                        style={{ fontSize: parseInt(size),
                                            color: iconColor}}
                                        onClick={handleTooltipOpen}/>
                                    </a>
                                </LightTooltip>
                </ClickAwayListener>
            }
        </a>

)

};

//export default withTranslation('common')(Helpi);
export default Helpi;
