import React, {useEffect, useState} from 'react';
import {Col, Card, CardBody, Button, ButtonToolbar} from 'reactstrap';
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Field, reduxForm} from 'redux-form';
import {connect} from "react-redux";
import actions from "../../../../redux/actions";
import MaskedInput from "react-text-mask";
import {CheckBoxField} from '../../../../shared/components/form/CheckBox';
import i18n from '../../../../translations/config/i18n';
import {useAlert} from "react-alert";
import CVButton from "../../../../shared/components/cv/CVButton";
import {formValidators, formMasks, subtract_x_years_to_date} from '../../../../util/index';
import { withRouter } from 'react-router-dom';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import {renderDateInputField} from "../../../../shared/components/cv/CVDatePicker";
import caravanIdLogo from '../../../../shared/img/icon/caravanId.png';
import caravanIdRLogo from '../../../../shared/img/icon/caravanIdR.png';
import caravanEIdLogo from '../../../../shared/img/icon/caravanEId.png';
import caravanIncompleteLogo from '../../../../shared/img/icon/incomplete.png';

const renderField = ({input, disabled, placeholder, type, meta: {touched, error, warning}, mask}) => (
  <div style={{width: '100%'}}>
    {!mask && <input style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type}/>}
    {mask && <MaskedInput style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type} mask={mask}/>}
    <div className="input-error-container">
      {touched && ((error && <span className="error-message">{i18n.t(`common:errors.${error}`)}</span>) || (warning &&
        <span className="error-message">{warning}</span>))}
    </div>
  </div>
);

const validateDateRequired = formValidators.validateDateBetweenRequired(
  subtract_x_years_to_date(new Date(), 100), // 100 years ago date
  new Date(), // today date
);

const CastrationEventForm = (props) => {
  CastrationEventForm.propTypes = {
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  const {t, reset, handleSubmit, onSubmit, editable,
    hideTitle, } = props;

  // const [productiveSystemSelected, setProductiveSystemSelected] = useState({});

  const alert = useAlert();

  const [earIdValidatorMode, setEarIdValidatorMode] = useState(0);

    useEffect(() => {        
        initEarIdValidator(props.initialValues.caravan_id);        
    }, [props.initialValues]);

    const getValidatorsEarId = () => {                
        if (earIdValidatorMode==0) {            
            return [formValidators.validateRequired, formValidators.validateCaravanEIdRequired];
        } else if (earIdValidatorMode==1) {                        
            return [formValidators.validateRequired, formValidators.validateCaravanEIdRequired];
        } else if (earIdValidatorMode==2) {
            return [formValidators.validateRequired, formValidators.validateCaravanIdRequired];
        } else if (earIdValidatorMode==3) {
            return [formValidators.validateRequired, formValidators.validateCaravanIdRRequired];
        }                        
    }

    const initEarIdValidator = (earId) => {
        if (earId!=undefined) {
            let isnum = /^\d+$/.test(earId[1]);
            if (earId[0]=='0') {                        
                setEarIdValidatorMode(1);
            } else if (isnum) {
                setEarIdValidatorMode(3);
            } else {
                setEarIdValidatorMode(2);
            }
        }
    }

    const updateEarId = (e) => {        
        //e.preventDefault();
        
        if (e.currentTarget.value.length==0) {            
            setEarIdValidatorMode(0);
        } else if (e.currentTarget.value[0]=='0') {                        
            setEarIdValidatorMode(1);
        } else if (e.currentTarget.value[0]!='0') {
            setEarIdValidatorMode(2);
        }
        
        if (e.currentTarget.value.length>1 && e.currentTarget.value[0]!='0') {            
            let isnum = /^\d+$/.test(e.currentTarget.value[1]);
            if (isnum) {
                setEarIdValidatorMode(3);
            }
        }
        
        if (e.currentTarget.value.length>2) return;
        setTimeout(function(){
            if (e.currentTarget.value.length>1) return;
            var elem = document.getElementsByName("caravan_id")[0];
            let caretPos = 1;
            if(elem != null) {
                if(elem.createTextRange) {
                    var range = elem.createTextRange();
                    range.move('character', caretPos);
                    range.select();
                }
                else {
                    if(elem.selectionStart) {
                        elem.focus();
                        elem.setSelectionRange(caretPos, caretPos);
                    }
                    else
                        elem.focus();
                }
            }            
        },200);
        
        
    }

  const showErrors = (invalid) => {
    if(invalid) ToastsStore.error(t('common.general_invalid_form'), 10000)
  }

  const submitForm = (values) => {
    values.facilityId = props.selectedEstablishment._id;
    onSubmit({...values});
  };

  return (
    <Col md={12} lg={12}>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
      <Card>
        <CardBody>
          <div className="card__title">
            {!hideTitle && <h5 className="bold-text">{t('cv-forms.events.castration_event.title')}</h5>}
          </div>
          <form className="form" onSubmit={handleSubmit(submitForm)}>
            <div className="edit-buttons" >
                          <ButtonToolbar className="form__button-toolbar" >
                                  { !editable && <Button 
                                      color="primary" 
                                      outline={true} 
                                      type="button" 
                                      onClick={() => props.history.goBack()}>
                                      {t('common.back')}</Button>
                                  }
                          </ButtonToolbar>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('cv-forms.events.change_productive_system.caravan_id')}</span>
              <div className="form__form-group-field">
              {
                  (earIdValidatorMode==0) &&
                  <img style={{width:"30px",height:"30px"}} src={caravanIncompleteLogo}/>
              }
              {
                  (earIdValidatorMode==1) &&
                  <img style={{width:"30px",height:"30px"}} src={caravanEIdLogo}/>
              }
              {
                  (earIdValidatorMode==2) &&
                  <img style={{width:"30px",height:"30px"}} src={caravanIdLogo}/>
              }
              {
                  (earIdValidatorMode==3) &&
                  <img style={{width:"30px",height:"30px"}} src={caravanIdRLogo}/>
              }                                
              &nbsp;
                <Field
                  name="caravan_id"
                  component={renderField}
                  validate={getValidatorsEarId()}                                    
                  type="text"
                  placeholder={t('cv-forms.events.change_productive_system.caravan_id')}
                  mask={(earIdValidatorMode==1)?formMasks.caravanEIdMask:(earIdValidatorMode==2)?formMasks.caravanIdMask:(earIdValidatorMode==3)?formMasks.caravanIdMaskR:null}
                  onChange={updateEarId}
                  normalize={(value) => value.toUpperCase()}
                  disabled={!editable}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('cv-forms.events.change_productive_system.date')}</span>
              <div className="form__form-group-field">
                <Field
                  name="date"
                  component={renderDateInputField}
                  validate={[validateDateRequired]}
                  disabled={!editable}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('cv-forms.events.common_fields.comments')}</span>
              <div className="form__form-group-field">
                <Field
                  name="comments"
                  component={renderField}
                  disabled={!editable}
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              {
                editable &&
                <CVButton
                  color="primary"
                  type="submit"
                  onClick={()=> showErrors(props.invalid)}
                  pending={props.pending}>
                  {t('common.accept')}
                </CVButton>
              }
              {
                editable &&
                <Button type="button" onClick={() => props.history.push('/animals/table')}>
                  {t('common.cancel')}
                </Button>
              }

            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>)
};

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues || {},
  selectedEstablishment: state.user.selectedEstablishment,
});

const mapDispatchToProps = (dispatch) => ({
  getAvailableProductiveSystems: () => dispatch(actions.constant.getAvailableProductiveSystems())
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'castration-event-form',
  touchOnChange: true // this is set because if not the 'touched' props on DateInputField was always false
})(withTranslation('common')(withRouter(CastrationEventForm))));