import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';

const TitularDashboard = (props) => {
    const { t } = props;

    return (
        <Container className="dashboard">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">{t(`cv-dashboards.titular.title`)}</h3>
                </Col>
            </Row>
        </Container>
    )
};

export default withTranslation('common')(TitularDashboard)
