import React, {useState} from 'react';
import {Field, reduxForm, change, untouch, formValueSelector} from "redux-form";
import renderSelectField from "../../../../shared/components/form/Select";
import AddressInput from "../../../../shared/components/cv/AddressInput";
import {Button, ButtonToolbar} from "reactstrap";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {CheckBoxField} from '../../../../shared/components/form/CheckBox';
import i18n from '../../../../translations/config/i18n';
import CVButton from "../../../../shared/components/cv/CVButton";
import {formMasks, formValidators} from "../../../../util";
import {ERRORS} from "../../../../constants";
import { renderCVPhotoInputField } from '../../../../shared/components/cv/CVFileUpload';
import {SRLWrapper} from "simple-react-lightbox";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

const optionsLightbox = {
    settings: {},
    caption: {
        showCaption: false,
    },
    buttons: {
        showAutoplayButton: false,
        showNextButton: false,
        showPrevButton: false,
        showThumbnailsButton: false,
        showDownloadButton: false,
        showFullscreenButton: false,
    },
    thumbnails: {
        showThumbnails: false,
    },
    progressBar:{},    
  }



const renderField = ({input, disabled, placeholder, type, meta: {touched, error, warning}, mask}) => (
    <div style={{width: '100%'}}>
        {!mask && <input style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type}/>}
        {mask && <MaskedInput style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type} mask={mask}/>}
        <div className="input-error-container">
            {touched && ((error && <span className="error-message">{i18n.t(`common:errors.${error}`)}</span>) || (warning &&
                <span className="error-message">{warning}</span>))}
        </div>
    </div>
);


const CompanyForm = (props) => {

    const { handleSubmit, t, availableEntityTypes, editable, buttonText, showImages, initialValues } = props;

    CompanyForm.prototype = {
        t: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        buttonText: PropTypes.string.isRequired,
        editable: PropTypes.boolean
    };

    const renderImage = (imageName) => {
        // An image was selected and preview is needed
        if(typeof (props.formImages[`${imageName}`]) === 'object' && !Array.isArray(props.formImages[`${imageName}`]) && props.formImages[`${imageName}`]!=null) {
            return (
            <div>
                <SRLWrapper options={optionsLightbox}>                                                        
                <img style={{cursor:"pointer"}} src={URL.createObjectURL(props.formImages[`${imageName}`].files[0])}/>
                </SRLWrapper>
                
                {
                    (editable) && (
                    <a onClick={()=>{deleteFile(`${imageName}`)}}><button>Eliminar</button></a>
                    )
                }
                
            </div>
            );
        }
        // An image (or images) was previously upload and need to be shown
        else if((typeof (initialValues[`${imageName}`]) === 'string' && initialValues[`${imageName}`] != '') ||
            (Array.isArray(initialValues[`${imageName}`]) && initialValues[`${imageName}`].length > 0)) {
            return (
            <div>
                <SRLWrapper options={optionsLightbox}>                                                        
                <img style={{cursor:"pointer"}} src={initialValues[`${imageName}`]}/>
                </SRLWrapper>
                
                {
                    (editable) && (
                    <a onClick={()=>{deleteFile(`${imageName}`)}}><button>Eliminar</button></a>
                    )
                }                
            </div>                                       
            );
        }
        // No image was selected and no image was upload
        else {
            return <label>{t('cv-forms.productive_establishment.no_image')}</label>;
        }
    };

    const deleteFile = (field) => {        
        props.dispatch(change('company_form',field,null))                                                
        delete props.initialValues[`${field}`];        
        //props.formImages[`${field}`]=null;
        delete initialValues[`${field}`];
    }

    

    const [sameAddressAsPersonal, setSameAddressAsPersonal] = useState(false);

    const resetAddressFields = (addressName) => {
        // reset all address fields
        props.changeFieldValue(`${addressName}.streetName`, '');
        props.changeFieldValue(`${addressName}.streetNumber`, '');
        props.changeFieldValue(`${addressName}.apartment`, '');
        props.changeFieldValue(`${addressName}.postalCode`, '');
        props.changeFieldValue(`${addressName}.city`, '');
        props.changeFieldValue(`${addressName}.state`, '');
        props.changeFieldValue(`${addressName}.country`, {
            value: 'ARGENTINA',
            label: t(`countries.ARGENTINA`)
        });

        // set all fields as untouched
        props.setFieldAsUntouched(`${addressName}.streetName`);
        props.setFieldAsUntouched(`${addressName}.streetNumber`);
        props.setFieldAsUntouched(`${addressName}.apartment`);
        props.setFieldAsUntouched(`${addressName}.postalCode`);
        props.setFieldAsUntouched(`${addressName}.city`);
        props.setFieldAsUntouched(`${addressName}.state`);
        props.setFieldAsUntouched(`${addressName}.country`);
    };

    const copyLegalAddressToCommercialOne = () => {

        // If the legal address was already copy to commercial, reset the commercial one.
        if(sameAddressAsPersonal) {
            setSameAddressAsPersonal(false);
            resetAddressFields('companyCommercialAddress');
        }
        //  If the legal address was not copy to commercial yet, copy it.
        else {
            setSameAddressAsPersonal(true);
            props.changeFieldValue('companyCommercialAddress.streetName', props.personalAddress.streetName);
            props.changeFieldValue('companyCommercialAddress.streetNumber', props.personalAddress.streetNumber);
            props.changeFieldValue('companyCommercialAddress.apartment', props.personalAddress.apartment);
            props.changeFieldValue('companyCommercialAddress.postalCode', props.personalAddress.postalCode);
            props.changeFieldValue('companyCommercialAddress.city', props.personalAddress.city);
            props.changeFieldValue('companyCommercialAddress.state', props.personalAddress.state);
            props.changeFieldValue('companyCommercialAddress.country', props.personalAddress.country);
        }
    };

    const showErrors = (invalid) => {
        if(invalid) ToastsStore.error(t('common.general_invalid_form'), 10000)
    }

    return (
        <form className="form" onSubmit={handleSubmit}>
            <div className="edit-buttons" >
                <ButtonToolbar className="form__button-toolbar" >
                        { !editable && <Button 
                            color="primary" 
                            outline
                            type="button" 
                            onClick={() => props.setEditable(!editable)}>
                            {t('common.edit')}</Button>
                        }
                        { editable && <CVButton
                            outline 
                            color="success"
                            type="submit"
                            onClick={() => showErrors(props.invalid)}
                            pending={props.pending}
                             >{t('common.save')}</CVButton>
                        }
                        { editable && <Button 
                            type="button"
                            outline
                            color="danger"
                            size="sm"
                            onClick={() => {
                                if(props.isNew){
                                    props.history.push('/manage_accounts/table');
                                }else{
                                props.setEditable(!editable)
                                }
                               }
                             }>
                            {t('common.cancel')}</Button>
                        }
                        { !editable && <Button 
                            color="primary" 
                            outline={true} 
                            type="button" 
                            onClick={() => props.history.goBack()}>
                            {t('common.back')}</Button>
                        }
                </ButtonToolbar>
            </div>
            <div className="form__form-group">
              <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
                <span className="form__form-group-label">{t('cv-forms.company.cuit_or_cuil')}</span>
                <div className="form__form-group-field">
                    <Field
                        name="cuitOrCuil"
                        component={renderField}
                        type="text"
                        validate={formValidators.validateRequired}
                        disabled={!editable}
                        placeholder={t('cv-forms.company.cuit_or_cuil')}
                        mask={formMasks.cuilOrCuitMask}
                    />
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.company.business_name')}</span>
                <div className="form__form-group-field">
                    <Field
                        name="businessName"
                        component={renderField}
                        type="text"
                        validate={formValidators.validateRequired}
                        disabled={!editable}
                        placeholder={t('cv-forms.company.business_name')}
                    />
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.company.person_type')}</span>
                <div className="form__form-group-field">
                    <Field
                        name="personType"
                        component={renderSelectField}
                        type="text"
                        placeholder={t('cv-forms.company.person_type')}
                        disabled={!editable}
                        options={[
                            ...availableEntityTypes.map(entityType => ({
                                value: entityType, label: t(`cv-forms.company.person_types.${entityType}`)
                            }))
                        ]}
                    />
                </div>
            </div>
            <div className="form__form-group">
            <span className="form__form-group-label">{t('cv-forms.company.email')}</span>
            <div className="form__form-group-field">
              <Field
                name="email"
                component={renderField}
                validate={formValidators.validateEmail}
                type="email"
                disabled={!editable}
                placeholder={t('cv-forms.company.email')}
              />
            </div>
          </div>
          <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.company.linkedin')}</span>
                <div className="form__form-group-field">
                    <Field
                        name="linkedin"
                        component={renderField}
                        type="text"
                        disabled={!editable}
                        placeholder={t('cv-forms.company.linkedin')}
                    />
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.company.webSite')}</span>
                <div className="form__form-group-field">
                    <Field
                        name="webSite"
                        component={renderField}
                        type="text"
                        disabled={!editable}
                        placeholder={t('cv-forms.company.webSite')}
                    />
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.company.logotype')}</span>
                <div className="form__form-group-field with_image">
                    {
                        (!showImages || editable) &&
                        <Field
                            name="logotypePhoto"
                            component={renderCVPhotoInputField}
                            disabled={!editable}
                            hideFileName={true}
                        />
                    }
                    {renderImage('logotypePhoto')}
                </div>
                </div>
            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                 <span className="form__form-group-label">{t('cv-forms.company.commercial_address')}</span>
                {
                    editable &&
                    <div>
                        <div className="form__form-group-field" style={{marginBottom: '20px'}}>
                            <CheckBoxField
                                onChange={copyLegalAddressToCommercialOne}
                                value={sameAddressAsPersonal}
                                name={'same_direction_as_legal_one'}
                                label={t('cv-forms.company.same_address_as_legal_one')}
                            />
                        </div>
                    </div>
                }
                <AddressInput addressName={'companyCommercialAddress'} renderInput={renderField} editable={editable}/>
                <div className="edit-buttons" >
                <ButtonToolbar className="form__button-toolbar" >
                        { !editable && <Button 
                            color="primary" 
                            outline
                            type="button" 
                            onClick={() => props.setEditable(!editable)}>
                            {t('common.edit')}</Button>
                        }
                        { editable && <CVButton
                            outline 
                            color="success"
                            type="submit"
                            onClick={() => showErrors(props.invalid)}
                            pending={props.pending}
                             >{t('common.save')}</CVButton>
                        }
                        { editable && <Button 
                            type="button"
                            outline
                            color="danger"
                            size="sm"
                            onClick={() => props.setEditable(!editable)}>
                            {t('common.cancel')}</Button>
                        }
                        { !editable && <Button 
                            color="primary" 
                            outline={true} 
                            type="button" 
                            onClick={() => props.history.goBack()}>
                            {t('common.back')}</Button>
                        }
                </ButtonToolbar>
            </div>
            </div>
        </form>
    )
};

const selector = formValueSelector('company_form');

// This is hard-code because the default value of the select is not working properly.
const mapStateToProps = (state, props) => ({
    initialValues: props.initialValues || {
        companyCommercialAddress: {
            country: {
                value: 'ARGENTINA',
                label: 'Argentina'
            }
        }
    },
    personalAddress: state.user.profile.homeAddress,
    formImages: {
        logotypePhoto: selector(state, 'logotypePhoto')
    }
});

const mapDispatchToProps = (dispatch) => ({
    changeFieldValue: (field, value) => dispatch(change('company_form', field, value)),
    setFieldAsUntouched: (field) => dispatch(untouch('company_form', field))
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'company_form'
})(withTranslation('common')(CompanyForm)))