import React,{useState,useEffect} from 'react'
import setting from '../../util/settings';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';
import {ROLES} from "../../constants";
import actions from '../../redux/actions/index';
const Karbono = (props) => {  

const [iframeUrl,setIframeUrl]=useState('')

useEffect(()=>{
    setIframeUrl(setting.baseUrlKarbono+props.establishmentId)
},[props.establishmentId])


return(
    <Container style={{marginTop:"-15px",height:'700px !important'}}>
     <iframe allowfullscreen="1" style={{height:"800px !important;"}}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
      src={iframeUrl} id="widget2" data-title="Karbono" width="100%" height="800px" frameborder="0"></iframe>
    </Container>
)
}

const mapStateToProps = (state) => ({   
    userId: state.user.id,    
    reduxState: state,
    establishmentId: state.user.selectedEstablishment !== undefined ? state.user.selectedEstablishment._id : ''
});

export default withTranslation('common')(connect(mapStateToProps)(Karbono));