import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import CVTable from "../../Tables/CVTable/CVTable";
import { connect } from "react-redux";
import actions from "../../../redux/actions/index";
import { withRouter } from "react-router-dom";
import { useAlert } from "react-alert";
import CVModal from "../../UI/Modals/components/CVModal";
import { ROLES, LOT_MODE_DISABLED } from "../../../constants";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import CloseCircleIcon from "mdi-react/CloseCircleIcon";
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import ReceiptIcon from "mdi-react/ReceiptIcon";
import QrcodeIcon from "mdi-react/QrcodeIcon";
import FileDownloadIcon from "mdi-react/FileDownloadIcon";
import CVBasicModal from "../../../shared/components/cv/CVBasicModal";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import 'react-tippy/dist/tippy.css';
import AnimalLotsModal from "./AnimalLotsModal";
import CalendarPlusIcon from "mdi-react/CalendarPlusIcon";
import setting from '../../../util/settings.json'

const EstablishmentLotsTable = (props) => {
  const {
    history,
    t,
    establishmentLots,
    widthPercentage,
    currentEstablishment,
    selectedEstablishment,
    selectedCompany,
    defaultEstablishment,
    selectedEstablishmentLotMode,
    establishmentDetails,
    editUserEstablishmentById
  } = props;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInfoLotsModeModal, setShowInfoLotsModeModal] = useState(false);
  const [showEditAnimalLotsModal, setShowEditAnimalLotsModal] = useState(false);

  const [establishmentLotToDelete, setEstablishmentLotToDelete] = useState();
  const [activeLots, setActiveLots] = useState();
  const [lotModeEnabled, setLotModeEnabled] = useState(false);
  const [qrModalIsOpen, toggleQrModal] = useState(false);
  const [lotId, setLotId] = useState();
  const [qrIdentecoLink, setQrIdentecoLink] = useState();
  const [qrIdentecoName, setQrIdentecoName] = useState();
  
  const QRCode = require('qrcode.react');

    const alert = useAlert();

    useEffect(() => {
        getActiveLots();
        //if(props.userRoles.some((rol) => rol.name === ROLES.TITULAR)) props.getEstablishments(1, 10)
        //if(props.userRoles.some((rol) => rol.name === ROLES.SURROGATE)) props.getSurrogateEstablishments(1, 10)
    }, [establishmentLots]);

    useEffect(() => {
        if (
            (establishmentDetails && establishmentDetails.animalLots.mode !== LOT_MODE_DISABLED) 
            // ||
            // (selectedEstablishmentLotMode && selectedEstablishmentLotMode !== LOT_MODE_DISABLED)
            ) {
                setLotModeEnabled(true)
       } else { 
            setLotModeEnabled(false) 
            setShowInfoLotsModeModal(true) 
        }

        if ( props.selectedEstablishment) {
            props.getUserEstablishmentById(selectedEstablishment._id, ()=> {});
        } else if( defaultEstablishment) {
            props.getUserEstablishmentById(defaultEstablishment._id, ()=> {});
        }
        
    }, [ props.selectedEstablishment, (establishmentDetails && establishmentDetails.animalLots.mode)]);

    const renderValueDefault = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value}
            </div>
        )
    };

    const getEstablishmentId = () => {
        if(selectedEstablishment._id) return selectedEstablishment._id;
        return defaultEstablishment._id;
    }

    const getActiveLots = () => {
        let lots = []
        for(let lot of establishmentLots) {
            if (lot.isActive === true) lots.push(lot)
        }
        setActiveLots(lots)
    }

    const renderDate = ({value}) => {
        const date = new Date(value);
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {`${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`}
            </div>
        )
    };

    const isValidRole = () => {
        if(props.userRoles.some((rol) => rol.name === ROLES.SURROGATE)) return true
        if(props.userRoles.some((rol) => rol.name === ROLES.TITULAR)) return true
        return false
    };    

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 450,
          fontSize: theme.typography.pxToRem(12),
          border: '1px solid #dadde9'
        }       
    }))(Tooltip);

    const renderButtons = ({dependentValues}) => {
        
        //constants for tooltip
        const [open, setOpen] = React.useState(false);
        
        const handleTooltipClose = () => {
            setOpen(false);
        };
    
        const handleTooltipOpen = () => {
            setOpen(true);
        };

        return (
          <div style={{display: "flex", justifyContent: "center"}}>
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <MagnifyIcon
                className={"button"}
                style={{marginLeft: "10px"}}
                onClick={() => {
                  props.selectEstablishmentLotToEdit(getEstablishmentId(), dependentValues._id, dependentValues.name, dependentValues.details)
                  props.history.push(
                    "/manage_establishments/establishment_lot_details",
                  )
                }}
              />

              {(selectedEstablishment.type==="productive") ?
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
                    <QrcodeIcon className={"button"} style={{marginLeft: "10px"}} 
                            onClick={() => openQrModal(dependentValues._id, dependentValues.name, dependentValues.shortURL)}
                    />
                </div>
                : null }

              {(selectedEstablishment.type==="productive") ?
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
                    <ClickAwayListener onClickAway={handleTooltipClose}>                   
                    <HtmlTooltip
                        interactive 
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener  
                        onClose={handleTooltipClose}
                        open={open}        
                        title={
                        <React.Fragment>
                            <a style={{cursor:"pointer", color:"black", fontWeight:"weight"}} 
                                href={  `${setting.baseUrlForAnimalQRIdentecoTrupoTemplate}/pdf/${dependentValues._id}/${dependentValues.name}?lang=es`
                                }><b>Español</b>
                            </a>
                            <br></br>
                            <a style={{cursor:"pointer", color:"black", fontWeight:"weight"}} 
                                href={ `${setting.baseUrlForAnimalQRIdentecoTrupoTemplate}/pdf/${dependentValues._id}/${dependentValues.name}?lang=en`
                                }><b>English</b>
                            </a>                                                                    
                        </React.Fragment>
                        }>
                        <p><FileDownloadIcon className={"button"} style={{marginLeft: "10px"}} onClick={handleTooltipOpen} /></p>
                    </HtmlTooltip>
                    </ClickAwayListener>
                </div>
                : null }

              {((selectedEstablishment.type==="commercial" || selectedEstablishment.type==="slaughterhouse") && setting.isTrupoEnabled) ?
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                    <HtmlTooltip
                        interactive 
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener  
                        onClose={handleTooltipClose}
                        open={open}        
                        title={
                        <React.Fragment>
                            {selectedEstablishment.type==="commercial" ? 
                            <a style={{cursor:"pointer", color:"black;", fontWeight:"weight"}} 
                                onClick={() => props.history.push({
                                    pathname: "/events/third_party_slaughter/new_event",
                                    foreignKey: dependentValues._id,
                                    facility: selectedEstablishment._id,
                                    companyId: selectedCompany._id,
                                    globalEventName: "FaenaEnTerceros",
                                    globalEventI18next: "third_party_slaughter_event"
                                })
                                }><b>Faena en Terceros</b>
                            <br></br></a> : null} 
                            {selectedEstablishment.type==="slaughterhouse" ? 
                            <a style={{cursor:"pointer", color:"black;", fontWeight:"weight"}} 
                                onClick={()=> props.history.push({
                                    pathname: "/events/third_party_slaughter/new_event",
                                    foreignKey: dependentValues._id,
                                    facility: selectedEstablishment._id,
                                    companyId: selectedCompany._id,
                                    globalEventName: "Faena",
                                    globalEventI18next: "slaughter_event"
                                })
                                }><b>Faena</b>
                            <br></br></a> : null}                                                                       
                        </React.Fragment>
                        }>
                        <p><ReceiptIcon className={"button"} style={{marginLeft: "10px"}} onClick={handleTooltipOpen} /></p>
                    </HtmlTooltip>
                    </ClickAwayListener>
                </div>
                : null} 
                
            
                              
              {
                isValidRole() ? (
                <div>
                <CloseCircleIcon
                    className={"button"}
                    style={{marginLeft: "10px"}}
                    color={"red"}
                    onClick={() => {
                       setEstablishmentLotToDelete({
                            name: dependentValues.name,
                            _id: dependentValues._id
                        });
                        setShowDeleteModal(!showDeleteModal);
                    }}
                />
                </div>
                )
                : null
             }
          </div>
        );
    };

    const heads = [
        {
            key: 'name',
            name: 'Lote',
            percentage: 25,
            minWidth: 150,
            formatter: renderValueDefault
        },
        {
            key: 'createdAt',
            name: t('establishments.table.headers.creation_date'),
            percentage: 25,
            minWidth: 150,
            formatter: renderDate
        },
        {
            key: 'actions',
            name: t('establishments.table.headers.actions'),
            percentage: 25,
            minWidth: 150,
            formatter: renderButtons,
            getRowMetaData: (row) => row
        }
    ];

    const downloadQR = () => {
        let canvas = document.getElementById('generated_qr');
        let pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${qrIdentecoName.toString()}.png`;
        //downloadLink.download = `${qrIdentecoLink.toString()}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        toggleQrModal(false);
    };

    const qrModal = () => (
        <CVBasicModal
            title={t('animals.modals.qr.title')}
            body={renderQRBody}
            isOpen={qrModalIsOpen}
            toggleModal={() => toggleQrModal(!qrModalIsOpen)}
            cancel={() => toggleQrModal(false)}
            accept={downloadQR}
            acceptText={t('animals.modals.qr.download')}
        />
    );

    const renderQRBody = () => (
        <div>
            <QRCode value={qrIdentecoLink} size={500} includeMargin id={"generated_qr"}
                    imageSettings={{src: require(`../../../shared/img/carnes-validadas-logo-qr-v2.png`), height: 84, width: 120}}
                    level={'H'}
            />            
            <br/><a target='_BLANK' style={{fontSize:"16px",fontWeight:"bold"}} href={qrIdentecoLink}>VER</a>            
            <br/>
            <br/>
        </div>
    );

    const openQrModal = (lotId, lotName, shortURL) => {
        setLotId(lotId);
        setQrIdentecoName( lotName )
        if (shortURL) setQrIdentecoLink(shortURL)
            else setQrIdentecoLink(`${setting.baseUrlForAnimalQRIdentecoTrupoTemplate}/${lotId}`)
        toggleQrModal(!qrModalIsOpen)
    }

    const renderDeleteEstablishmentBody = () => (
        establishmentLotToDelete ?
            <div>
                {t('establishments.modals.delete_animal_lots.body', { name: establishmentLotToDelete.name })}
            </div>:
            null
    );
    
    const renderDeleteEstablishmentModal = () => (
        <CVModal
            title={t('establishments.modals.delete_animal_lots.title')}
            body={() => renderDeleteEstablishmentBody()}
            color="danger"
            header
            isOpen={showDeleteModal}
            toggle={() => setShowDeleteModal(!showDeleteModal)}
            acceptFunc={() => {
                props.deleteEstablishmentLotById(establishmentLotToDelete._id, () => {
                    setShowDeleteModal(false);
                    props.getUserEstablishmentById(currentEstablishment);
                    alert.success(t('establishments.alerts.establishment_lot_deleted', { name: establishmentLotToDelete.name }));
                })
            }}
        />
    );


    const renderInfoLotsModeModalBody = () => (
            <div>
                {t('establishments.modals.lot_mode.body', { name: selectedEstablishment ? selectedEstablishment.name : ''})}
            </div>

    );

    const renderInfoLotsModeModal = () => (
        <CVModal
            title={t('establishments.modals.lot_mode.title')}
            body={()=> renderInfoLotsModeModalBody()}
            color="primary"
            header
            isOpen={showInfoLotsModeModal}
            toggle={() => setShowInfoLotsModeModal(!showInfoLotsModeModal)}
            acceptText={t('establishments.modals.lot_mode.edit_lot_options')}
            acceptFunc={() => {
                setShowInfoLotsModeModal(false)
                setShowEditAnimalLotsModal(true)
                }
              
            }
        />
        
    )


    const editUserEstablishment = () => {
        
        const values= establishmentDetails

        values.animalLots = {
            mode: selectedEstablishmentLotMode,
            lots: establishmentDetails.animalLots.lots
        }
        
        props.resetSelectedLot();
        editUserEstablishmentById( 
            values.id,
            values.type,
            values,
            () => { setShowEditAnimalLotsModal(!showEditAnimalLotsModal) }, (errorMessage) => {
                
            });
    };


    const renderEditAnimalLotsModalBody = () => (
            <div>
                <AnimalLotsModal
                    establishmentId = {selectedEstablishment ? selectedEstablishment._id : '' } 
                />
            </div>
    );

    const renderEditAnimalLotsModal = () => (
        <CVModal
            style={{ width: '95%' }}
            title={t('establishments.modals.animal_lots.title')}
            body={renderEditAnimalLotsModalBody}
            color="primary"
            header
            isOpen={showEditAnimalLotsModal}
            toggle={() => setShowEditAnimalLotsModal(!showEditAnimalLotsModal)}
            acceptFunc={editUserEstablishment}
            details={establishmentDetails}
        />
    );

    const renderIconForAddingLots = () => (
        (selectedEstablishment || defaultEstablishment) &&
        <div>
            <PlusCircleOutlineIcon
                onClick={() => history.push('/manage_establishments/new_establishment_lot')}
            />

            <CalendarPlusIcon
                    className={"button"}
                    style={{marginLeft: "10px"}}
                    onClick={()=>  editUserEstablishment() }
            />
            
        </div>
       
    );


    return(

        <div>
           { 
               lotModeEnabled ?  
            
           <CVTable
                // getPage={props.getEstablishments}
                data={activeLots}
                heads={heads}
                title={`Lotes del establecimiento ${props.e}`}
                hideFilters
                hidePagination
                SubtitleComponent={renderIconForAddingLots }
                widthPercentage={widthPercentage}
                noDataLabel={t('establishments.lots.no_data_label')}
                pending={props.getUserEstablishmentsPending}
            />
            
            : 

            (!showEditAnimalLotsModal && renderInfoLotsModeModal())
            
            }

            {qrModal()}

            {renderEditAnimalLotsModal()}

            {renderDeleteEstablishmentModal()}

        </div>
    )

};

const mapStateToProps = (state) => ({
    defaultEstablishment: state.user.allEstablishmentsByCompany[0],
    selectedEstablishment: state.user.selectedEstablishment,
    selectedCompany: state.user.selectedCompany,
    currentEstablishment: state.user.selectedEstablishment?
        state.user.selectedEstablishment._id : null,
    establishments: state.user.establishments.results,
    currentPage: state.user.establishments.page,
    total: state.user.establishments.total,
    totalPages: state.user.establishments.totalPages,
    pageSize: state.user.establishments.pageSize,
    getUserEstablishmentsPending: state.ui.getUserEstablishmentsPending,
    getUserEstablishmentsError: state.ui.getUserEstablishmentsError,
    userRoles: state.user.roles,
    establishmentLots: state.user.establishmentDetails?
        state.user.establishmentDetails.animalLots? state.user.establishmentDetails.animalLots.lots: []
        : [],
    e: state.user.selectedEstablishment? state.user.selectedEstablishment.name: "",
    selectedEstablishmentLotMode: state.user.selectedEstablishmentLotMode,
    establishmentDetails: state.user.establishmentDetails,
    
});

const mapDispatchToProps = (dispatch) => ({
    getUserEstablishmentById: (currentEstablishment) => dispatch(actions.user.getEstablishmentById(currentEstablishment)),
    deleteEstablishmentLotById: (establishmentLotId, callback) =>
        dispatch(actions.user.deleteUserEstablishmentLotById(establishmentLotId, callback)),
    getEstablishments: (page, pageSize) => dispatch(actions.user.getEstablishments(page, pageSize)),
    selectEstablishmentLotToEdit: (establishmentId, lotId, name, details) =>
        dispatch(actions.user.selectEstablishmentLotToEdit(establishmentId, lotId, name, details)),
    editUserEstablishmentById: (establishmentId, establishmentType, data, successCallback, errorCallback) =>
        dispatch(actions.user.editEstablishmentById(establishmentId, establishmentType, data, successCallback, errorCallback)),
    resetSelectedLot: () => dispatch(actions.user.resetSelectedLot())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(EstablishmentLotsTable)));