/*
*   The event mapper is use for mapping either the body or the response on a event request. It try to decouple the
*   event middleware.
* */

const mapper =  {
    body: {

    },

    response: {
        getLotsWithAnimalsByFacilityId: (response, viewAllLots) => (
            mapLotsIdLotsName(response, viewAllLots)
        ),
    }
};


const mapLotsIdLotsName = (lots, viewOnlyActiveLots) => {
    let result = []
    let viewAllLots = !viewOnlyActiveLots
    lots.forEach(lot => {
        if(lot.isActive || viewAllLots) {
            result.push({
                _id: lot._id,
                name: lot.name,
                animalsCount: lot.animalsCount
            })
        }
    });
    return result;
};

export default mapper;