export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const REFRESH_REQUEST = 'REFRESH_REQUEST';
export const RETRIEVE_USERNAME = 'RETRIEVE_USERNAME';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_RESPONSE = 'REGISTER_RESPONSE';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const LOG_OUT = 'LOG_OUT';
export const FULL_REGISTER_REQUEST = 'FULL_REGISTER_REQUEST';
export const FULL_REGISTER_RESPONSE = 'FULL_REGISTER_RESPONSE';
export const FULL_REGISTER_ERROR = 'FULL_REGISTER_ERROR';
export const SET_TOKENS = 'SET_TOKENS';
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const CONFIRM_EMAIL_RESPONSE = 'CONFIRM_EMAIL_RESPONSE';
export const CONFIRM_EMAIL_ERROR = 'CONFIRM_EMAIL_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_RESPONSE = 'RESET_PASSWORD_RESPONSE';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const RECOVER_PASSWORD_RESPONSE = 'RECOVER_PASSWORD_RESPONSE';
export const RECOVER_PASSWORD_ERROR = 'RECOVER_PASSWORD_ERROR';
export const SEND_EMAIL_TIER = 'SEND_EMAIL_TIER';
export const SEND_EMAIL_TIER_RESPONSE = 'SEND_EMAIL_TIER_RESPONSE';
export const SEND_EMAIL_TIER_ERROR = 'SEND_EMAIL_TIER_ERROR';

const sessionActions = {
    loginRequest: (email, password, rememberUsername) => ({type: LOGIN_REQUEST, email, password, rememberUsername}),
    loginResponse: () => ({type: LOGIN_RESPONSE}),
    loginError: (message) => ({type: LOGIN_ERROR, message}),

    refreshRequest: (refreshToken) => ({type: REFRESH_REQUEST, refreshToken}),

    retrieveUserName: (callback) => ({type: RETRIEVE_USERNAME, callback}),

    setTokens: (tokens) => ({type: SET_TOKENS, tokens}),

    registerRequest: (email, password, country, callback) => ({type: REGISTER_REQUEST, email, password, country, callback}),
    registerResponse: (res) => ({type: REGISTER_RESPONSE, res}),
    registerError: (message) => ({type: REGISTER_ERROR, message}),

    fullRegisterRequest: (userId, info, successCallback, errorCallback) => (
        { type: FULL_REGISTER_REQUEST, userId, info, successCallback, errorCallback }
    ),

    fullRegisterResponse: (res) => ({type: FULL_REGISTER_RESPONSE, res}),
    fullRegisterError: (message) => ({type: FULL_REGISTER_ERROR, message}),

    confirmMailRequest: (token) => ({type: CONFIRM_EMAIL, token}),
    confirmMailResponse: (response) => ({type: CONFIRM_EMAIL_RESPONSE, response}),
    confirmMailError: (message) => ({type: CONFIRM_EMAIL_ERROR, message}),

    resetPasswordRequest: (userId, token, password) => ({type: RESET_PASSWORD, userId, token, password}),
    resetPasswordResponse: (response) => ({type: RESET_PASSWORD_RESPONSE, response}),
    resetPasswordError: (message) => ({type: RESET_PASSWORD_ERROR, message}),

    recoverPasswordRequest: (email) => ({type: RECOVER_PASSWORD, email}),
    recoverPasswordResponse: (response) => ({type: RECOVER_PASSWORD_RESPONSE, response}),
    recoverPasswordError: (message) => ({type: RECOVER_PASSWORD_ERROR, message}),
    
    sendMailTierRequest(data, successCallback, errorCallback) {
        return {
            type: SEND_EMAIL_TIER,
            data,
            successCallback,
            errorCallback
        }
    },
    
    sendMailTierResponse: (response) => ({type: SEND_EMAIL_TIER_RESPONSE, response}),
    sendMailTierError: (message) => ({type: SEND_EMAIL_TIER_ERROR, message}),

    logout: () => ({type: LOG_OUT})
};
export default sessionActions;