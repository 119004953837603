/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';



class SimpleVideoEmbed extends PureComponent {  
  constructor(props) {
    super(props);   
  }  

  render() {
    const { path } = this.props;    
    return (      
      <iframe allowfullscreen="1" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" src={path} id="widget2" data-title="Tutorial - Alta de Animales" width="100%" height="100%" frameborder="0"></iframe>
    );
  }
}

export default withTranslation('common')(SimpleVideoEmbed);
