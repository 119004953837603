import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import renderDropZoneField from '../../components/form/DropZone';
import FileExcelIcon from 'mdi-react/FileExcelIcon'

const FileUploadDefault = ({ handleSubmit, reset, t, acceptedFormats }) => (
    <form className="form form--justify" onSubmit={handleSubmit}>
        <Field
            name="files"
            acceptedFormats={acceptedFormats}
            dropZoneDefaultImage={FileExcelIcon}
            dropZonePlaceHolder={t('cv-inputs.file_upload.drop_zone_placeholder')}
            component={renderDropZoneField}
        />
        <ButtonToolbar className="form__button-toolbar center">
            <Button color="primary" type="submit">{t('common.upload')}</Button>
            <Button type="reset" onClick={reset}>{t('common.delete')}</Button>
        </ButtonToolbar>
    </form>
);

FileUploadDefault.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default reduxForm({
    form: 'file_upload_default', // a unique identifier for this form
})(withTranslation('common')(FileUploadDefault));
