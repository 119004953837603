import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import  CVBasicModal  from "./CVBasicModal";
import Helpi from "../../../../src/containers/Helpi"

const DEFAULT_FILE_FORMATS_ACCEPTED = ".jpg,.png,.pdf,.doc,.docx";
const MAX_MB = 10
const MAX_SIZE = MAX_MB * 1024 * 1024;


const renderInfoBody = () => (
        <div>
            <span>
            {`El archivo que estás queriendo subir supera el máximo permitido de ${MAX_MB}MB.
            Para mejorar el rendimiento al visualizarlo te recomendamos que el archivo no supere los ${MAX_MB/2}MB`}
            </span>
        </div>
);

const renderHelpiBody = () => (
    <div>
        <span>
        {`El tamaño máximo permitido es de ${MAX_MB}MB.
        Para mejorar el rendimiento al visualizarlo te recomendamos que el archivo no supere los ${MAX_MB/2}MB`}
        </span>
    </div>
);

const CVFileUpload = props => {
  const [tierModalIsOpen, toggleTierModal] = useState(false);

  CVFileUpload.propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string
      }),
      PropTypes.string
    ]),
    multiple: PropTypes.bool,
    acceptedFormats: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    hideFileName: PropTypes.bool
  };

  const {
    onChange,
    name,
    value,
    multiple,
    acceptedFormats,
    label,
    disabled,
    hideFileName
  } = props;


  const tierModal = () => (

          <CVBasicModal
              title={'Carga de imagen'}
              body={renderInfoBody}
              isOpen={tierModalIsOpen}
              backdrop={"static"}
              keyboard={false}
              toggleModal={() => toggleTierModal(!tierModalIsOpen)}
              hideCancelButton={true}
              accept={() => toggleTierModal(false)}

          />
      );


  return (
    <div className="form__form-group-file">
      <label htmlFor={name}>{label}</label>
      {!hideFileName && value.name && <span>{value.name}</span>}
      <input
       
        type="file"
        disabled={disabled}
        name={name}
        accept={
          acceptedFormats ? acceptedFormats : DEFAULT_FILE_FORMATS_ACCEPTED
        }
        multiple={multiple}
        id={name}
        onChange={e => {
          e.preventDefault();

          // convert files to an array
          const files = [...e.target.files];

          if (files[0].size > MAX_SIZE) {
            files.splice(0, 1);
            // setMaxSize(true);
            toggleTierModal(true)
          }
          // check to avoid error when 'cancel' button is press.
          onChange(
            files[0] ? { files: files, name: files[0].name } : undefined
          );
        }}

      />

      <Helpi
      title={renderHelpiBody()}
      size="20"
      iconColor="lightgrey"
      fontColor="white"
       style={{margin:"0px 0px 0px 5px"}}
      
      />

      { tierModal() }

    </div>
  );
};

const renderInputField = props => {
  const {
    input,
    meta: { touched, error, warning },
    multiple,
    labelSingle,
    labelMultiple,
    acceptedFormats,
    disabled,
    hideFileName
  } = props;
  const { t } = useTranslation("common");

  return (
    <div>
      <div className="form__form-group-input-wrap">
        <CVFileUpload
          {...input}
          label={multiple ? labelMultiple : labelSingle}
          multiple={multiple}
          acceptedFormats={acceptedFormats}
          disabled={disabled}
          hideFileName={hideFileName}
        />
      </div>
      <div className="input-error-container">
        {touched &&
          ((error && (
            <span className="error-message">{t(`errors.${error}`)}</span>
          )) ||
            (warning && <span className="error-message">{warning}</span>))}
      </div>
    </div>
  );
};

const renderCVFileInputField = props => {
  const { t } = useTranslation("common");

  return renderInputField({
    ...props,
    labelSingle: t("cv-inputs.file_upload.select_the_file"),
    labelMultiple: t("cv-inputs.file_upload.select_the_files"),
    acceptedFormats: props.acceptedFormats ? props.acceptedFormats : ".pdf"
  });
};

const renderCVPhotoInputField = props => {
  const { t } = useTranslation("common");

  return renderInputField({
    ...props,
    labelSingle: t("cv-inputs.photo_upload.select_the_photo"),
    labelMultiple: t("cv-inputs.photo_upload.select_the_photos"),
    acceptedFormats: props.acceptedFormats
      ? props.acceptedFormats
      : ".jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .png, .gif, .tiff, .tif, .bmp, .dib",
    maxSize: props.maxSize
  });
};

renderCVFileInputField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    hideFileName: PropTypes.bool
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  })
};

renderCVPhotoInputField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    hideFileName: PropTypes.bool
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  })
};

export { renderCVFileInputField, renderCVPhotoInputField };
