import React, {useState, useEffect, useCallback} from "react";
import PropTypes from 'prop-types';


const Countdown = (props)=> {
     Countdown.propTypes = {
         seconds: PropTypes.number.isRequired,
         restart: PropTypes.bool.isRequired,
         onFinish: PropTypes.func

     }
    const {seconds, restart, onFinish} = props
    const [counter, setCounter] = useState(seconds);

    const handleFinish = useCallback((handlerFunction)=> {
        handlerFunction()
    }, [onFinish])
    
    useEffect(() => {
    let instance = undefined
    if(counter > 0 )  instance = setTimeout(() => setCounter(counter - 1), 1000);
    if(counter === 1  && !restart && onFinish) handleFinish(onFinish) 
   
    return ()=> {
        if(counter === 0 && restart) setCounter(seconds)
        clearTimeout(instance)
    }

    }, [counter]);

    return (
        <div>{counter}</div>  
    );
}

export default React.memo(Countdown)