import React, {useState, useEffect} from 'react';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import {withTranslation} from "react-i18next";
import AlertIcon from "mdi-react/AlertIcon";
import {connect} from "react-redux";
import { ClipLoader } from "react-spinners";
import actions from '../../../redux/actions/index'
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

const ProfessionalList = (props) => {

    const { t, establishmentId, getProfessionalPending, professionals } = props;

    useEffect(() => {
        if(establishmentId) props.getProfessionalsByEstablishment(establishmentId);
    }, [establishmentId]);

    const noProfessionals = () => professionals.length === 0;

    const deleteProfessional = (userId, role) => {
        props.deleteProfessionalFromEstablishment(establishmentId, userId, role)
        ToastsStore.success('El Profesional / Operador ha sido removido.');
    };

    return (
        <div className="professional_list">
            <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
            {
                professionals.map(professional => (
                    <div className="professional_list_item">
                        <div className="professional_list_item-name">
                            <label>{professional.name} {professional.lastName}</label>
                        </div>
                        <div className="professional_list_item-role">
                            <label>{t(`common.roles.${professional.role}`)}</label>
                        </div>
                        <div className="professional_list_item-actions">
                            <CloseCircleIcon color={'red'}
                                             onClick={() => deleteProfessional(professional.userId, professional.role)}/>
                        </div>
                    </div>
                ))
            }
            {
                getProfessionalPending ?
                    <div className="loader">
                        <ClipLoader size={15} loading={true} color={"#123abc"}/>
                    </div>:
                    noProfessionals() &&
                    <div className="no_professionals">
                        <AlertIcon/>
                        <h5>{t('establishments.modals.add_professional_to_establishment.no_professional')}</h5>
                    </div>
            }
        </div>
    )
};

const mapStateToProps = (state) => ({
    getProfessionalPending: state.ui.getProfessionalsByEstablishmentPending,
    professionals: state.user.establishmentProfessionals
});

const mapDispatchToProps = (dispatch) => ({
    getProfessionalsByEstablishment: (establishmentId) => dispatch(actions.user.getProfessionalsByEstablishment(establishmentId)),
    deleteProfessionalFromEstablishment: (establishmentId, userId, roleId) =>
        dispatch(actions.user.deleteProfessionalFromEstablishment(establishmentId, userId, roleId))
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ProfessionalList))