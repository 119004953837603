export const GET_AVAILABLE_ROLES = "GET_AVAILABLE_ROLES";
export const GET_AVAILABLE_ROLES_RESPONSE = "GET_AVAILABLE_ROLES_RESPONSE";
export const GET_AVAILABLE_ROLES_ERROR = "GET_AVAILABLE_ROLES_ERROR";
export const GET_AVAILABLE_ENTITY_TYPES = "GET_AVAILABLE_ENTITY_TYPES";
export const GET_AVAILABLE_ENTITY_TYPES_RESPONSE = "GET_AVAILABLE_ENTITY_TYPES_RESPONSE";
export const GET_AVAILABLE_ENTITY_TYPES_ERROR = "GET_AVAILABLE_ENTITY_TYPES_ERROR";
export const GET_AVAILABLE_PRODUCTIVE_SYSTEMS = "GET_AVAILABLE_PRODUCTIVE_SYSTEMS";
export const GET_AVAILABLE_PRODUCTIVE_SYSTEMS_RESPONSE = "GET_AVAILABLE_PRODUCTIVE_SYSTEMS_RESPONSE";
export const GET_AVAILABLE_PRODUCTIVE_SYSTEMS_ERROR = "GET_AVAILABLE_PRODUCTIVE_SYSTEMS_ERROR";
export const GET_FACILITIES_PRODUCTIVE_TYPES = 'GET_FACILITIES_PRODUCTIVE_TYPES';
export const GET_FACILITIES_PRODUCTIVE_TYPES_RESPONSE = 'GET_FACILITIES_PRODUCTIVE_TYPES_RESPONSE';
export const GET_FACILITIES_PRODUCTIVE_TYPES_ERROR = 'GET_FACILITIES_PRODUCTIVE_TYPES_ERROR';
export const GET_AVAILABLE_PRODUCTIVE_SYSTEMS_FACILITY_TYPE = "GET_AVAILABLE_PRODUCTIVE_SYSTEMS_FACILITY_TYPE";
export const GET_AVAILABLE_PRODUCTIVE_SYSTEMS_FACILITY_TYPE_RESPONSE = "GET_AVAILABLE_PRODUCTIVE_SYSTEMS_FACILITY_TYPE_RESPONSE";
export const GET_AVAILABLE_PRODUCTIVE_SYSTEMS_FACILITY_TYPE_ERROR = "GET_AVAILABLE_PRODUCTIVE_SYSTEMS_FACILITY_TYPE_ERROR";
export const GET_AVAILABLE_ESTABLISHMENT_TYPES = "GET_AVAILABLE_ESTABLISHMENT_TYPES";
export const GET_AVAILABLE_ESTABLISHMENT_TYPES_RESPONSE = "GET_AVAILABLE_ESTABLISHMENT_TYPES_RESPONSE";
export const GET_AVAILABLE_ESTABLISHMENT_TYPES_ERROR = "GET_AVAILABLE_ESTABLISHMENT_TYPES_ERROR";
export const GET_AVAILABLE_GROUND_CONDITIONS = "GET_AVAILABLE_GROUND_CONDITIONS";
export const GET_AVAILABLE_GROUND_CONDITIONS_RESPONSE = "GET_AVAILABLE_GROUND_CONDITIONS_RESPONSE";
export const GET_AVAILABLE_GROUND_CONDITIONS_ERROR = "GET_AVAILABLE_GROUND_CONDITIONS_ERROR";
export const GET_AVAILABLE_RUCA_ACTIVITIES = "GET_AVAILABLE_RUCA_ACTIVITIES";
export const GET_AVAILABLE_RUCA_ACTIVITIES_RESPONSE = "GET_AVAILABLE_RUCA_ACTIVITIES_RESPONSE";
export const GET_AVAILABLE_RUCA_ACTIVITIES_ERROR = "GET_AVAILABLE_RUCA_ACTIVITIES_ERROR";
export const GET_DENTITION_VALUES = "GET_DENTITION_VALUES";
export const GET_DENTITION_VALUES_RESPONSE = "GET_DENTITION_VALUES_RESPONSE";
export const GET_DENTITION_VALUES_ERROR = "GET_DENTITION_VALUES_ERROR";
export const GET_DELETION_REASON_VALUES = "GET_DELETION_REASON_VALUES";
export const GET_DELETION_REASON_VALUES_RESPONSE = "GET_DELETION_REASON_VALUES_RESPONSE";
export const GET_DELETION_REASON_VALUES_ERROR = "GET_DELETION_REASON_VALUES_ERROR";
export const GET_AVAILABLE_STATES = "GET_AVAILABLE_STATES";
export const GET_AVAILABLE_STATES_RESPONSE = "GET_AVAILABLE_STATES_RESPONSE";
export const GET_AVAILABLE_STATES_ERROR = "GET_AVAILABLE_STATES_ERROR";

const constantActions = {
    getAvailableRoles() {
        return {
            type: GET_AVAILABLE_ROLES
        }
    },
    getAvailableRolesResponse(response) {
        return {
            type: GET_AVAILABLE_ROLES_RESPONSE,
            response
        }
    },
    getAvailableRolesError(message) {
        return {
            type: GET_AVAILABLE_ROLES_ERROR,
            message
        }
    },
    getAvailableEntityTypes() {
        return {
            type: GET_AVAILABLE_ENTITY_TYPES
        }
    },
    getAvailableEntityTypesResponse(response) {
        return {
            type: GET_AVAILABLE_ENTITY_TYPES_RESPONSE,
            response
        }
    },
    getAvailableEntityTypesError(message) {
        return {
            type: GET_AVAILABLE_ENTITY_TYPES_ERROR,
            message
        }
    },
    getAvailableProductiveSystems() {
        return {
            type: GET_AVAILABLE_PRODUCTIVE_SYSTEMS
        }
    },

    getFacilitiesProductiveTypes() {
        return {
            type: GET_FACILITIES_PRODUCTIVE_TYPES
        }
    },

    getFacilitiesProductiveTypesResponse(response) {
        return {
            type: GET_FACILITIES_PRODUCTIVE_TYPES_RESPONSE,
            response
        }
    },

    getFacilitiesProductiveTypesError(message) {
        return {
            type: GET_FACILITIES_PRODUCTIVE_TYPES_ERROR,
            message
        }
    },
    

    getAvailableProductiveSystemsResponse(response) {
        return {
            type: GET_AVAILABLE_PRODUCTIVE_SYSTEMS_RESPONSE,
            response
        }
    },
    getAvailableProductiveSystemsError(message) {
        return {
            type: GET_AVAILABLE_PRODUCTIVE_SYSTEMS_ERROR,
            message
        }
    },
    getAvailableProductiveSystemsFacilityType(data) {
        return {
            type: GET_AVAILABLE_PRODUCTIVE_SYSTEMS_FACILITY_TYPE,
            data
        }
    },
    getAvailableProductiveSystemsFacilityTypeResponse(response) {
        return {
            type: GET_AVAILABLE_PRODUCTIVE_SYSTEMS_FACILITY_TYPE_RESPONSE,
            response
        }
    },
    getAvailableProductiveSystemsFacilityTypeError(message) {
        return {
            type: GET_AVAILABLE_PRODUCTIVE_SYSTEMS_FACILITY_TYPE_ERROR,
            message
        }
    },
    getDentitionValues() {
        return{
            type: GET_DENTITION_VALUES
        }
    },
    getDentitionValuesResponse(response){
        return{
            type: GET_DENTITION_VALUES_RESPONSE,
            response
        }
    },
    getDentitionValuesError(message){
        return{
            type: GET_DENTITION_VALUES_ERROR,
            message
        }
    },
    getDeletionReasonValues() {
        return{
            type: GET_DELETION_REASON_VALUES
        }
    },
    getDeletionReasonValuesResponse(response){
        return{
            type: GET_DELETION_REASON_VALUES_RESPONSE,
            response
        }
    },
    getDeletionReasonValuesError(message){
        return{
            type: GET_DELETION_REASON_VALUES_ERROR,
            message
        }
    },
    getAvailableEstablishmentTypes(){
        return {
            type: GET_AVAILABLE_ESTABLISHMENT_TYPES
        }
    },
    getAvailableEstablishmentTypesResponse(response){
        return {
            type: GET_AVAILABLE_ESTABLISHMENT_TYPES_RESPONSE,
            response
        }
    },
    getAvailableEstablishmentTypesError(message){
        return {
            type: GET_AVAILABLE_ESTABLISHMENT_TYPES_ERROR,
            message
        }
    },
    getAvailableGroundConditions() {
        return {
            type: GET_AVAILABLE_GROUND_CONDITIONS
        }
    },
    getAvailableGroundConditionsResponse(response) {
        return {
            type: GET_AVAILABLE_GROUND_CONDITIONS_RESPONSE,
            response
        }
    },
    getAvailableGroundConditionsError(message) {
        return {
            type: GET_AVAILABLE_GROUND_CONDITIONS_ERROR,
            message
        }
    },
    getAvailableRucaActivities() {
        return {
            type: GET_AVAILABLE_RUCA_ACTIVITIES
        }
    },
    getAvailableRucaActivitiesResponse(response) {
        return {
            type: GET_AVAILABLE_RUCA_ACTIVITIES_RESPONSE,
            response
        }
    },
    getAvailableRucaActivitiesError(message) {
        return {
            type: GET_AVAILABLE_RUCA_ACTIVITIES_ERROR,
            message
        }
    },
    
    getAvailableStates() {
        return {
            type: GET_AVAILABLE_STATES
        }
    },
    getAvailableStatesResponse(response) {
        return {
            type: GET_AVAILABLE_STATES_RESPONSE,
            response
        }
    },
    getAvailableStatesError(message) {
        return {
            type: GET_AVAILABLE_STATES_ERROR,
            message
        }
    }
};

export default constantActions;