import React, {useEffect, useState} from 'react'
import MagnifyIcon from "mdi-react/MagnifyIcon";
import CVTable from "../../Tables/CVTable/CVTable";
import { withTranslation } from "react-i18next";
import eventActions from '../../../redux/actions/eventActions';
import { connect } from 'react-redux';
import {DetailsModal} from "../../../shared/components/cv/DetailsModal";
import cowTransparent from "../../../shared/img/cow-transparent.gif";
import {Button} from "reactstrap";
import { withRouter } from "react-router-dom";
import { useAlert } from "react-alert";

const EventMassiveLoadTable = (props) => {
    const { t, events, total, isLoading } = props;
    const alert = useAlert();

    const [detailsModalIsOpen, setDetailsModalOpen] = useState(false);
    const [details, setDetails] = useState();

    const openDetailsModal = (eventDetails) => {
        let temporaryDetails = [];
        for(let key in eventDetails){
            if(key !== 'id'){
                let temporaryDetail = {
                    label: t(`events.modals.details.labels.${key}`),
                    value: eventDetails[key]
                };
                if(key === 'name'){
                    temporaryDetail.value = t(`events.labels.${temporaryDetail.value}`);
                }
                else if(key === 'productive_system'){
                    temporaryDetail.value = t(`productive_system.${temporaryDetail.value}`)
                }
                temporaryDetails.push(temporaryDetail);
            }
        }
        setDetails(temporaryDetails);
        setDetailsModalOpen(true);
    };

    const renderValueDefault = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value}
            </div>
        )
    };

    const renderButtons = ({ dependentValues }) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <MagnifyIcon className={"button"} style={{marginLeft: "10px"}} onClick={() => openDetailsModal(dependentValues)}/>
            </div>
        )
    };

    const heads = [
        {
            key: 'name',
            name: t('events.table.headers.event'),
            percentage: 50,
            minWidth: 150,
            formatter: ({value}) => renderValueDefault({value: t(`events.labels.${value}`)})
        },
        {
            key: 'date',
            name: t('events.table.headers.date'),
            percentage: 25,
            minWidth: 210,
            formatter: renderValueDefault
        },
        {
            key: 'actions',
            name: t('events.table.headers.actions'),
            percentage: 25,
            minWidth: 135,
            formatter: renderButtons,
            getRowMetaData: (row) => row
        }
    ];

    const submit = () => {
        props.massiveEventsLoad(events, () => {
            alert.success(t('events.massive_load_table.massive_events_upload_success_message'))
        });
        props.history.push('/events/table')
    };

    const cancel = () => {
        props.history.push('/events/table')
    };

    return(
        <div className='event-massive-load'>
            {
                isLoading &&
                <div className="loading">
                    <img alt="cow" src={cowTransparent}/>
                    <span>{t('events.massive_load_table.we_are_processing_the_file')}</span>
                </div>
            }
            {
                !isLoading &&
                    <div>
                        <CVTable
                            getPage={() => {}}
                            data={events}
                            total={total}
                            currentPage={1}
                            heads={heads}
                            title={t('events.massive_load_table.title')}
                            hideFilters
                            hidePagination
                            noDataLabel={''}
                        />
                        <div className="button-container">
                            <Button className="btn btn-primary" onClick={submit}>{t('common.accept')}</Button>
                            <Button className="btn btn-secondary" onClick={cancel}>{t('common.cancel')}</Button>
                        </div>
                        <DetailsModal isOpen={detailsModalIsOpen} title={t('events.modals.details.title')}
                                      toggle={()=> setDetailsModalOpen(!detailsModalIsOpen)} data={details}/>
                    </div>
            }
        </div>

    );
};

const mapStateToProps = (state) => ({
    events: state.event.massiveLoad,
    total: state.event.massiveLoad.length,
    isLoading: state.ui.uploadFileForMassiveEventsLoadPending,
    thereIsAnError: state.ui.uploadFileForMassiveEventsLoadError
});

const mapDispatchToProps = (dispatch) => ({
    massiveEventsLoad: (events, callback) => dispatch(eventActions.massiveEventLoad(events, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(EventMassiveLoadTable)));