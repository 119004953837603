import setting from '../../util/settings';

import {
// =========== SELECT COMPANY / ESTABLISHMENT / LOT =================
    SELECT_COMPANY_THIRDPARTYSLAUGHTER,   
    SELECT_ESTABLISHMENT_THIRDPARTYSLAUGHTER,    
    SELECT_LOT_THIRDPARTYSLAUGHTER,
     
// =========== FORM DATA ============================================
    GET_ALL_USER_COMPANIES_THIRDPARTYSLAUGHTER_RESPONSE,
    GET_ASSIGNED_COMPANIES_THIRDPARTYSLAUGHTER_RESPONSE,
    GET_USER_ESTABLISHMENTS_BY_COMPANY_THIRDPARTYSLAUGHTER_RESPONSE,  
    GET_LOTS_BY_ESTABLISHMENT_THIRDPARTYSLAUGHTER_RESPONSE,
    GET_GLOBAL_EVENT_THIRDPARTYSLAUGHTER_RESPONSE,
    GET_GLOBAL_EVENT_BY_ID_THIRDPARTYSLAUGHTER_RESPONSE,
    GET_LOT_AVERAGE_WEIGHT_GLOBALEVENT_RESPONSE,

// =========== SUBMIT DATA ==========================================
    SUBMIT_THIRDPARTYSLAUGHTER_EVENT_RESPONSE,
    RESET_SELECTED_EVENT
    
} from "../actions/thirdPartySlaughterActions";

const initialState = {
    averageWeight: [],
    allCompanies: [],
    allEstablishments: [],
    allEstablishmentsLots: [],
    allGlobalEvents: [],
    allLots: [],
    globalEventDetails: [],
};

const thirdPartySlaughterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_COMPANY_THIRDPARTYSLAUGHTER:
            return {...state, selectedCompany: action.company};      
        case SELECT_ESTABLISHMENT_THIRDPARTYSLAUGHTER:
            return {...state, selectedEstablishment: action.establishment};        
        case SELECT_LOT_THIRDPARTYSLAUGHTER:
            return {...state, selectedLot: action.lot};
   
        case GET_ALL_USER_COMPANIES_THIRDPARTYSLAUGHTER_RESPONSE:
            return {...state, allCompanies: action.response};
        case GET_ASSIGNED_COMPANIES_THIRDPARTYSLAUGHTER_RESPONSE:
            return {...state, allCompanies: action.response };
        case GET_USER_ESTABLISHMENTS_BY_COMPANY_THIRDPARTYSLAUGHTER_RESPONSE:
            return {...state, allEstablishments: action.response};
        case GET_LOTS_BY_ESTABLISHMENT_THIRDPARTYSLAUGHTER_RESPONSE:
            return {...state, allLots: action.response};
        case GET_GLOBAL_EVENT_THIRDPARTYSLAUGHTER_RESPONSE:
            return {...state, allGlobalEvents: action.response };  
        case GET_GLOBAL_EVENT_BY_ID_THIRDPARTYSLAUGHTER_RESPONSE:
            return {...state, globalEventDetails: action.response }; 
        case GET_LOT_AVERAGE_WEIGHT_GLOBALEVENT_RESPONSE:
            return {...state, averageWeight: action.response };  

        case SUBMIT_THIRDPARTYSLAUGHTER_EVENT_RESPONSE:
            return {...state, events: action.response.results};
        case RESET_SELECTED_EVENT:
            return {
                ...state,
                averageWeight: [],
                allCompanies: [],
                allEstablishments: [],
                allEstablishmentLots: [],
                allLots: [],
                globalEventDetails: undefined
            }
        default:
            return state
    }
};
export default thirdPartySlaughterReducer;