import setting from '../../util/settings';

import {    
    SELECT_ESTABLISHMENT_REPORT,    
    SELECT_COMPANY_REPORT,    
    GET_USER_ESTABLISHMENT_BY_ID_REPORT_RESPONSE,
    GET_ALL_USER_COMPANIES_REPORT_RESPONSE,
    GET_ASSIGNED_COMPANIES_REPORT_RESPONSE,
    GET_USER_ESTABLISHMENTS_BY_COMPANY_REPORT_RESPONSE,    
    GET_USER_ESTABLISHMENTS_BY_COMPANY_REPORT,    
} from "../actions/reportActions";

import {
    REGISTER_RESPONSE, FULL_REGISTER_RESPONSE
} from '../actions/sessionActions';

import { LOG_OUT } from "../actions/sessionActions";

const initialState = {    
    selectedEstablishment: undefined,    
    selectedCompany: undefined,    
    allEstablishmentsByCompany: [],
    establishmentDetails: {animalLots:{lots:[]}},
    allCompanies: [],    
    templates: [
        //{type:1, name:'QR IDENTECO por Establecimiento', scope:2, url: setting.baseUrlForAnimalQRTemplate+"/edit",tooltipEditTemplate: "Para elegir qué mostrar en el reporte 'QR Identeco' del establecimiento seleccionado apretá sobre Editar Plantilla."},        
        //{type:4, name:'MIKROSITO por Establecimiento', scope:2, url:setting.baseUrlForAnimalREPORT+"/REPORT/edit", tooltipEditTemplate: "Para editar el Mikrosito del establecimiento seleccionado apretá sobre Editar Plantilla."},
    ],
};

const reportReducer = (state = initialState, action) => {
    switch (action.type) {        
        case SELECT_ESTABLISHMENT_REPORT:
            return {...state, selectedEstablishment: action.establishment};        
        case SELECT_COMPANY_REPORT:
            return {...state, selectedCompany: action.company};        
        case GET_USER_ESTABLISHMENT_BY_ID_REPORT_RESPONSE:
            return {...state, establishmentDetails: action.response};
        case GET_ALL_USER_COMPANIES_REPORT_RESPONSE:
            return {...state, allCompanies: action.response};
        case GET_ASSIGNED_COMPANIES_REPORT_RESPONSE:
            return { ...state, allCompanies: action.response };        
        case GET_USER_ESTABLISHMENTS_BY_COMPANY_REPORT_RESPONSE:
            return {...state, allEstablishmentsByCompany: action.response};        
        default:
            return state
    }
};
export default reportReducer;