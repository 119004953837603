import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';

const SidebarLinkExternalToBlank = ({
  title, icon: Icon, newLink, route, onClick,
}) => (
  <a
    href={route}
    onClick={onClick}
    activeClassName="sidebar__link-active"
    target="_blank"
  >
    <li className="sidebar__link">
      {Icon && <Icon className="sidebar__link-icon"/>}
      <p className="sidebar__link-title">
        {title}
        {newLink ? <Badge className="sidebar__link-badge"><span>New</span></Badge> : ''}
      </p>
    </li>
  </a>
);

SidebarLinkExternalToBlank.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarLinkExternalToBlank.defaultProps = {
  newLink: false,
  route: '/',
  onClick: () => {},
};

export default SidebarLinkExternalToBlank;
