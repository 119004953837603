import React, {useState, useEffect} from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import {Collapse} from 'reactstrap';
import {ButtonBase} from '@material-ui/core';
import {connect} from "react-redux";
import actions from '../../../redux/actions/index';
import {withTranslation} from "react-i18next";
import _ from 'lodash';
import {ROLES} from "../../../constants";

const TopbarEstablishment = (props) => {
    const [collapse, setCollapse] = useState(false);
    const [selectedEstablishment, setSelectedEstablishment] = useState();
    const [establishments, setEstablishments] = useState([]);

    useEffect(() => {
        // If the user is not titular get the user assigned establishment
        if(!props.roles.some(rol => rol.name === ROLES.TITULAR)) {
            props.getAssignedEstablishments(props.userId);
        }
    }, []);

    useEffect(() => {
        if(props.establishments) {
            setEstablishments(props.establishments);

            if(props.selectedEstablishment) {
                // checks if the selected establishments is present on the establishment array.
                const establishmentOnEstablishmentsArray = props.establishments.filter((establishment) => {
                    return establishment._id === props.selectedEstablishment._id
                });

                //  If the establishments array contains the selected establishment is because the company has not
                //  change, but the establishment may be change (if edited).
                if(establishmentOnEstablishmentsArray.length === 1) {
                    // Change it on the component state, for visual purposes.
                    setSelectedEstablishment(props.selectedEstablishment);
                    // Only selects the establishment again if the establishment selected has change (if edited).
                    if(!_.isEqual(establishmentOnEstablishmentsArray[0], props.selectedEstablishment)) {
                        props.selectEstablishment(establishmentOnEstablishmentsArray[0]);
                    }
                }
                else {
                    props.resetSelectedEstablishment();
                    // If there is at least one establishment select it
                    if(props.establishments.length > 0) {
                        // Change it on component state for visual purposes.
                        setSelectedEstablishment(props.establishments[0]);
                        // Change it on reducer, this will trigger all the related requests.
                        props.selectEstablishment(props.establishments[0]);
                    }
                }
            }
            else {
                // Change it on component state for visual purposes.
                setSelectedEstablishment(props.establishments[0]);
                // Change it on reducer, this will trigger all the related requests.
                props.selectEstablishment(props.establishments[0]);
            }
        }
    }, [props.establishments]);


    const toggle = () => {
        setCollapse(!collapse);
    };

    const _changeEstablishment = (establishment) => {
        props.selectEstablishment(establishment);
        setSelectedEstablishment(establishment);
        toggle();
    };

    // If no company is selected
    if(!props.selectedCompany) {
        //  And the user is titular don't show the establishment topbar.
        if(props.roles.some(rol => rol.name === ROLES.TITULAR)) return null;
    }

    if (establishments && establishments.length > 0) {
        return (
            <div className="topbar__profile">
                <button className="topbar__avatar" type="button" onClick={toggle}>
                    <span className={"topbar__link-icon lnr lnr-apartment"}/>
                    <p className="topbar__avatar-name">{selectedEstablishment.name}</p>
                    <DownIcon className="topbar__icon"/>
                </button>
                {
                    establishments.length > 1 ?
                        <div>
                            {collapse && <button className="topbar__back" type="button" onClick={toggle}/>}
                            <Collapse isOpen={collapse} className="topbar__menu-wrap">
                                <div className="topbar__menu left-align">
                                    {/* todo uncomment this when 'all' establishment is supported */}
                                    {/*<ButtonBase
                                        className="topbar__link d-flex justify-content-start"
                                        type="button"
                                        onClick={() => _changeEstablishment({all: true, name: t('common.all')})}
                                    >
                                        <span className={`topbar__link-icon lnr lnr-apartment`}/>
                                        <p className="topbar__link-title">{t('common.all')}</p>
                                    </ButtonBase>*/}
                                    {
                                        establishments.map((establishment) => (
                                            <ButtonBase
                                                className="topbar__link d-flex justify-content-start"
                                                type="button"
                                                onClick={() => _changeEstablishment(establishment)}
                                                key={establishment._id}
                                            >
                                                <span className={`topbar__link-icon lnr lnr-apartment`}/>
                                                <p className="topbar__link-title">{establishment.name}</p>
                                            </ButtonBase>
                                        ))
                                    }
                                </div>
                            </Collapse>
                        </div> :
                        null
                }
            </div>
        )
    }
    return null;
};

const mapStateToProps = (state) => ({
    establishments: state.user.allEstablishmentsByCompany,
    selectedCompany: state.user.selectedCompany,
    selectedEstablishment: state.user.selectedEstablishment,
    roles: state.user.roles,
    userId: state.user.id
});

const mapDispatchToProps = (dispatch) => ({
    selectEstablishment: (establishment) => dispatch(actions.user.selectEstablishment(establishment)),
    resetSelectedEstablishment: () => dispatch(actions.user.resetSelectedEstablishment()),
    getAssignedEstablishments: (userId) => dispatch(actions.user.getAssignedEstablishments(userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(TopbarEstablishment))
