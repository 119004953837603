import React, { useState } from 'react';
import { Container, Row } from 'reactstrap';
import { connect } from "react-redux";
import actions from '../../../../redux/actions';
import { useAlert } from "react-alert";
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import ThirdPartySlaughterEventForm from "../NewEventForms/ThirdPartySlaughterEventForm"

const ThirdPartySlaughterEventDetailsContainer = (props) => {
  const alert = useAlert();

  const addThirdPartySlaughterEvent = (values) => {
    props.submitThirdPartySlaughterEvent(values, props.facilityId, () => {
        alert.success(props.t('events.alerts.event_added'));
        props.history.push('/events/third_party_slaughter/table');
    }, (errorMessage) => {
        ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
    });
  };
  
  const [editable, setEditable] = useState(false);
  const event = props.globalEventDetails;

  const mapInitialValues = (event) => {
    if (event)
      return {
        companyId: event.companyId,
        facilityId: event.facility,
        lotId: event.foreignKey,
        eventDate: event.eventDate,

        globalEventName: event.globalEventName,
        entityType: event.entityType,
        fridgeName: event.frigo.name,
        fridgeRuca: event.frigo.ruca.ruca,
        rucaImage: event.frigo.ruca.rucaImage,
        rucaImage_checksumFile: event.frigo.ruca.rucaImage_checksumFile,
        plantNumber: event.frigo.plantNumber,
        logotypeImage: event.frigo.logotypeImage,
        logotypeImage_checksumFile: event.frigo.logotypeImage_checksumFile,
        numSenasa: event.frigo.numSenasa,
        latitude: event.frigo.latitud,
        longitude: event.frigo.longitud,
        plantNumber: event.frigo.plantNumber,
        slaughterRite: { value: event.slaughterRite, label: event.slaughterRite },
        lotAverageWeight: event.lotAverageWeight,

        file1: event.file1,
        file1_checksumFile: event.file1_checksumFile,
        file1_description: event.file1_description,
        file2: event.file2,
        file2_checksumFile: event.file2_checksumFile,
        file2_description: event.file2_description,
        file3: event.file3,
        file3_checksumFile: event.file3_checksumFile,
        file3_description: event.file3_description,
        file4: event.file4,
        file4_checksumFile: event.file4_checksumFile,
        file4_description: event.file4_description,
        file5: event.file5,
        file5_checksumFile: event.file5_checksumFile,
        file5_description: event.file5_description,

        viewOnlyActiveLots: false,
        isSlaughter: true
      };
    return {}
  };

  return (
    <Container>
      <Row>
        {
          event &&
          <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/> &&
          <ThirdPartySlaughterEventForm 
              onSubmit={addThirdPartySlaughterEvent}
              editable={editable}
              setEditable={setEditable}
              showImages={true}
              initialValues={mapInitialValues(event)}
              pending={props.addGlobalEventPending}
              />
        }
      </Row>
    </Container>
  )
};

const mapStateToProps = (state) => ({
    globalEventDetails: state.thirdPartySlaughter.globalEventDetails?
        state.thirdPartySlaughter.globalEventDetails.globalEvents : [],
    globalEventName: state.router.location.globalEventName,
    globalEventI18next: state.router.location.globalEventI18next,
    addGlobalEventPending: state.ui.addGlobalEventPending,
});

const mapDispatchToProps = (dispatch) => ({
  submitThirdPartySlaughterEvent: (values, facilityId, successCallback, errorCallback) =>
    dispatch(actions.thirdPartySlaughter.submitThirdPartySlaughterEvent(values, facilityId, successCallback, errorCallback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(ThirdPartySlaughterEventDetailsContainer)));
