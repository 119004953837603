
const mapper =  {
    body: {
        addQrGeneration: (values) =>({ 
            name:values.nameQr,
            templateId:values.templateId.value,
            scopeObjectId:values.facilityId,
            scope:"facility",
            type:"produktoTrupoQr",
            lotId:values.lotId === undefined ? null : values.lotId.value,
            selectedImages: values.selectedImages,
            referenceAnimalId: values.referenceAnimalId===undefined ? null: values.referenceAnimalId.value,
            slaughterDate: values.slaughterDate === undefined ? null: values.slaughterDate,    
            //comments:values.comments
        }),
        editQrGeneration: (values) =>({ 
            name:values.nameQr,
            templateId:values.templateId.value,
            scopeObjectId:values.facilityId,
            scope:"facility",
            type:"produktoTrupoQr",
            lotId: values.lotId === null ? null : values.lotId.value,
            selectedImages: values.selectedImages,
            referenceAnimalId: values.referenceAnimalId===null ? null: values.referenceAnimalId.value,
            slaughterDate: values.slaughterDate === undefined ? null: values.slaughterDate,  
           // comments:values.comments
        }),

    },

    response: {
        getQrsGeneratedById:(response) => ({ 
            qr: response,
        }),
        getQrsGeneratedByFacility:(response) => ({ 
            qrsGenerated: response.docs,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        getQrsTemplates:(response) => ({ 
            templates: response,
        }),
        getLotsInGlobalEvents:(response) => ({ 
            lots: response,
        }),
        getRelatedImagesByLot:(response) => ({ 
            relatedImages: response,
        }),
        
    },


};

export default mapper;