import QrGenerationForm from "./QrGenerationForm"
import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col} from "reactstrap";
import {withTranslation} from "react-i18next";
import actions from '../../redux/actions/index';
import { connect } from 'react-redux';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import qrGenerationActions from '../../redux/actions/qrGenerationActions';
import animalActions from '../../redux/actions/animalActions'
import { ESTABLISHMENT_TYPES } from "../../constants";

const QrGenerationDetailsContainer = (props) => {

  const [editable, setEditable] = useState(false);
  const [submittable, setSubmittable] = useState(true);
  
  if(!props.location || !props.location.state || !props.location.state.qrId ) return null;
  const qrId = props.location.state.qrId;

  const [readyDetailsTemplatesLots, setReadyDetailsTemplatesLots] = useState(false);
  const [readyAnimalsInfo, setReadyAnimalsInfo] = useState(false);
  
  const [initialDetailTemplatesLots, setInitialDetailTemplatesLots] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [qrDetails,setQrDetails]= useState(null)
  const [activeTemplates,setActiveTemplates]=useState(null)
  const [validLots, setValidLots] = useState(null);
  const [selectedLot, setSelectedLot] = useState(null);
  const [animalsInfo,setAnimalsInfo]=useState(null)
  
  
  useEffect(() => {

    props.getEstablishmentTemplates(props.facilityId)
    if (props.selectedEstablishment.type === ESTABLISHMENT_TYPES.slaughterhouse) {props.getLotsInGlobalEvents(props.facilityId,'Faena')}
    if (props.selectedEstablishment.type === ESTABLISHMENT_TYPES.commercial)     {props.getLotsInGlobalEvents(props.facilityId,'FaenaEnTerceros')}        
  }, []);
  
  useEffect(() => {
    if(qrId) {
        props.getQrGeneratedById(qrId);
    }
  }, [qrId]);

  useEffect(() => { 
    if(props.qrDetails!== undefined) { setQrDetails(props.qrDetails) } 
  }, [props.qrDetails]);
  
  useEffect(() => { 
    if (props.globalEventsLots!== undefined){  setValidLots (props.globalEventsLots) } 
  },[props.globalEventsLots]);
  
  useEffect(() => { 
      if (props.establishmentTemplates!== undefined){  setActiveTemplates (props.establishmentTemplates) } 
  }, [props.establishmentTemplates]);
 
  useEffect(() => { 
    if (selectedLot!==null){ 
      props.getAnimalsHistoryByLot(selectedLot,props.selectedEstablishment._id) 
    } 
  }, [selectedLot]);
  
  useEffect(() => { 
    if (props.animalsHistoryByLot!== undefined){ setAnimalsInfo(props.animalsHistoryByLot)  } 
  }, [props.animalsHistoryByLot]);

  
  // YA TENGO LOS LOTES Y LOS TEMPLATES Y LOS QRS
  // ===================================================================================
  useEffect(() => { 
    if (validLots!== null && activeTemplates!=null &&  qrDetails!== null ){ 
      setReadyDetailsTemplatesLots(true)  
    }
  }, [validLots,activeTemplates,qrDetails]);
    
  // YA TENGO (LOS LOTES Y LOS TEMPLATES Y LOS QRS) DEL ANTERIOR  Y LA INFO DEL ANIMAL
  // ===================================================================================
  useEffect(() => {
    if (animalsInfo!=null && initialDetailTemplatesLots!== null ){
      setReadyAnimalsInfo(true)  
    }
  }, [initialDetailTemplatesLots,animalsInfo]);    


    useEffect(()=>{
        
        if (readyDetailsTemplatesLots){
          
          let newInitialValues={nameQr: null,
                                templateId:null,
                                lotId:null,
                                slaughterDate:null,
                                selectedImages:null
                              }

            if ( qrDetails.name!== undefined ){
              newInitialValues.nameQr = qrDetails.name
            } 
        
            if ( qrDetails.templateId!== undefined && activeTemplates!==[]){
                let template_aux = activeTemplates.filter((template) => template._id=== qrDetails.templateId)
                if(template_aux.length===1){
                  newInitialValues.templateId={ value:qrDetails.templateId,
                                                label: template_aux[0].templateName+ " ("+template_aux[0].templateLang+")"}
                }
            }
            
            if( qrDetails.selectedImages!==undefined){
              newInitialValues.selectedImages=qrDetails.selectedImages
            }
                
            if ( qrDetails.lotId!== undefined && qrDetails.lotId!==null && validLots!== []){
              let lot_aux = validLots.filter((lot) => lot.foreignKey=== qrDetails.lotId)
              if(lot_aux.length===1){
                newInitialValues.lotId={value:qrDetails.lotId,label: lot_aux[0].lotName}
                newInitialValues.slaughterDate=lot_aux[0].eventDate.slice(0,10)    
              }
              setAnimalsInfo(null)
              setSelectedLot(qrDetails.lotId)    
            } else {
              setInitialValues({...newInitialValues,'referenceAnimalId':null})
            }
            setInitialDetailTemplatesLots(newInitialValues)
      }
    },[readyDetailsTemplatesLots])


    useEffect (()=>{ 
      if (readyAnimalsInfo ){
        let referenceAnimal = null    
        if (initialDetailTemplatesLots.lotId===null){
          setInitialValues({...initialDetailTemplatesLots,'referenceAnimalId':null})
        }else{
            if ( animalsInfo!==null && animalsInfo!==[]){
              let animal_aux = animalsInfo.filter((animal) => animal._id=== qrDetails.referenceAnimalId)
              if(animal_aux.length===1){
                referenceAnimal={value:qrDetails.referenceAnimalId,
                  label:  animal_aux[0].earId+ ': ' + parseFacilities(animal_aux[0].facilityHistory)}
                  
                  setInitialValues({...initialDetailTemplatesLots,'referenceAnimalId':referenceAnimal})
                }
              }
        }
        
      } 
    },[readyAnimalsInfo])

    const parseFacilities = (facilities) =>{
        let parsed=''
        facilities = [...facilities.reverse()]
        for (let f=0; f<facilities.length; f++){
            if (f==0){
                parsed = facilities[f].name
            } else {
                parsed = parsed +  ' ⟶ '  + facilities[f].name
            }
        }
        return parsed

    }
          


const editQr = (values) => {
  // console.log("DBG Edit Qr", values)
  setSubmittable(false);
  props.editQr(qrId, values, () => {
      props.history.push('/qrGeneration/table');
  }, (errorMessage) => {
      ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
      setSubmittable(true);
  });
};



  return (
    <Col md={12} lg={12}>
    <Card>
        <CardBody>
        <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
            <div className="card__title">
                <div style={{display: 'flex', flexDirection: 'row',justifyContent: 'space-between'}}>
                    <h5 className="bold-text">{props.t('qrGeneration.detailsTitle')}</h5>
                </div>
            </div>
            {/* only show the details when the animal info arrive */}
            {
                initialValues!==null  &&  
                <QrGenerationForm
                    onSubmit={editQr}
                    availableEstablishments={props.availableEstablishments}
                    hideTitle
                    initialValues={initialValues}
                    facilityId={props.facilityId}
                    editable={editable}
                    setEditable={setEditable}
                    showImages
                    submittable={submittable}
                    pending={props.editCowPending}
                    isNew={false}
                />
            }
        </CardBody>
    </Card>
</Col>


  )
};

const mapStateToProps = (state) => ({
  qrDetails: state.qrGeneration.QR=== undefined ? {} : state.qrGeneration.QR.qr,
  availableEstablishments: state.user.allEstablishments,
  facilityId: state.user.selectedEstablishment._id,
  editCowPending: state.ui.editCowPending,
  selectedEstablishment: state.user.selectedEstablishment,
  establishmentTemplates: state.qrGeneration.QR === undefined ? [] : state.qrGeneration.QR.templates,
  globalEventsLots: state.qrGeneration.QR === undefined ? [] : state.qrGeneration.QR.lots,
  animalsHistoryByLot: state.animal.animals.animalsHistoryByLot===undefined ? [] : state.animal.animals.animalsHistoryByLot,

});

const mapDispatchToProps = (dispatch) => ({
  getQrGeneratedById: (qrId) => dispatch(qrGenerationActions.getQrsGeneratedById(qrId,'QR')),
  editQr: (qrId, values, successCallback, errorCallback) =>    dispatch(actions.qrGeneration.editQrGeneration(qrId,values,successCallback, errorCallback)) ,
  getEstablishmentTemplates: (currentEstablishment) => dispatch(qrGenerationActions.getQrsTemplates(currentEstablishment,'QR')), 
  getLotsInGlobalEvents: (currentEstablishment,globalEventName) => dispatch(qrGenerationActions.getLotsInGlobalEvents(currentEstablishment,globalEventName,'QR')),
  getAnimalsHistoryByLot: (lotId,facilityId) => dispatch(animalActions.getAnimalsHistoryByLot(lotId,facilityId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(QrGenerationDetailsContainer))


