import React from 'react';
import { Route, Redirect } from "react-router-dom";

const CVAuthRoute = ({isAuthenticated, component: Component,  ...rest}) => (
    <Route {...rest} render={props => {
        if(isAuthenticated){
            return <Component {...props}/>
        }
        else return <Redirect to={{pathname: '/log_in', state: {from: props.location}}}/>
    }}/>
);

export default CVAuthRoute;