import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';
import actions from '../../redux/actions/index';
import {ROLES} from "../../constants";
import {Collapse} from "reactstrap";
import {ButtonBase} from "@material-ui/core";
import DownIcon from "mdi-react/ChevronDownIcon";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import setting from '../../util/settings';
import Tooltip from 'react-power-tooltip';
import { template } from '@babel/core';




const BI = (props) => {
    const {  t, BI, reduxState, allCompanies, establishments, establishmentLots, templates } = props;    
    const [editTemplateMode, setEditTemplateMode] = useState(false);
    const [selectedTemplate, selectTemplate] = useState([]);
    const [selectedCompany, selectCompany] = useState([]);
    const [companies, setCompanies] = useState([]);    
    const [allTemplates, setAllTemplates] = useState([]);    
    const [allEstablishmentLots, setAllEstablishmentLots] = useState([]);    
    const [allEstablishment, setAllEstablishment] = useState([]);    
    const [selectedEstablishment, selectEstablishment] = useState([]);
    const [selectedLot, selectLot] = useState([]);    
    const [visibilitySaveButton, setVisibilitySaveButton] = useState(false);
    const [templateUrl, setTemplateUrl] = useState([]);
    const [iframeTemplate, setIframeTemplate] = useState([]);
    const [iframeDisable, setIframeDisable] = useState(true);    
    const [visibilityEditButton, setVisibilityEditButton] = useState(false);
    const [disableSaveButton, setDisableSaveButton] = useState(false);
    
    const [tooltipEditTemplate, setTooltipEditTemplate] = useState(false);
    
    
    useEffect(() => {

        const handler = event => {
            try{
                const data = JSON.parse(event.data)                                    
                if (data.message=='OK') {
                    //props.history.push('/templates/microsite')            

                    /*if(allCompanies && allCompanies.length > 0) {            
                        setAllTemplates(templates);    
                        selectTemplate('');
                        setCompanies(allCompanies);
                        setAllEstablishmentLots(establishmentLots);
                        selectCompany('');
                        selectEstablishment('');
                        selectLot('');
                        setAllEstablishment([]);
                        setAllEstablishmentLots([]);            
                        setTemplateUrl(false);
                        setIframeDisable(true);
                    }*/

                }            
                if (data.message=='setSaveButton') {
                    //setVisibilitySaveButton(true);
                    setVisibilityEditButton(true);
                }
                if (data.message=='setDisableSaveButton') {                
                    setDisableSaveButton(true);
                }
                if (data.message=='unsetDisableSaveButton') {               
                    setDisableSaveButton(false);
                }            
                if (data.message=='setIframeEnable') {
                    setIframeDisable(false);
                }
            }catch(e){
                console.log('Event Error',e)
            }
        }
        
        window.addEventListener("message", handler)        

    }, [allCompanies]);

    
    useEffect(() => {
        if(props.roles.some(rol => (rol.name) === ROLES.TITULAR )) props.getAllCompanies();
    }, []);

    useEffect(() => {
        if(props.roles.some(rol => (rol.name) === ROLES.SURROGATE )) props.getAssignedCompanies(props.userId);
    }, []);
    
    useEffect(() => {        
        props.getHasSlaughterhouseOrCommercial(props.userId, props.roles[0].name);
    }, []);
    
    useEffect(() => {
        setAllEstablishmentLots(establishmentLots);
    }, [establishmentLots]);

    useEffect(() => {                
                
        if(allCompanies && allCompanies.length > 0) {  
            let templates_aux = templates
            if (!setting.isTrupoEnabled){
                  templates_aux = templates.filter( t => t.type!==5)
            }          
            setAllTemplates(templates_aux);    
            setCompanies(allCompanies);
            setAllEstablishmentLots(establishmentLots);
            selectCompany('');
            selectEstablishment('');
            selectLot('');
            setAllEstablishment([]);
            setAllEstablishmentLots([]);            
        }
    }, [allCompanies]);
   
    
    const changeTemplate = (event) => {        
        let template = event.target.value;
        selectTemplate(template);        
        
        setAllEstablishment([]);
        setAllEstablishmentLots([]);
        
        selectCompany('');
        selectEstablishment('');
        selectLot('');

        validateEditButtom('','','');
        setTooltipEditTemplate(template.tooltipEditTemplate);
        setDisableSaveButton(true);
        setIframeDisable(true)

    };

    const changeCompany = (event) => {        
        let company = event.target.value;
        selectCompany(company);
                
        props.getEstablishmentsByCompanyId(company._id);                
        setAllEstablishmentLots([]);

        selectEstablishment('');
        selectLot('');

        validateEditButtom(company,'','');
        setDisableSaveButton(false);
    };

    const changeEstablishment = (event) => {        
        let establishment = event.target.value;   
        selectEstablishment(establishment);        

        props.getUserEstablishmentById(establishment._id, ()=> {});
        
        selectLot('');

        validateEditButtom(selectedCompany,establishment,'');
        setDisableSaveButton(false);
    };

    const changeLot = (event) => {        
        let lot = event.target.value;
        selectLot(lot);                

        validateEditButtom(selectedCompany,selectedEstablishment,lot);
        setDisableSaveButton(false);
    };

    const validateEditButtom = (company,establishment,lot) => {
        let validate = false;
        if (selectedTemplate.scope==1) {
            if (company!='') {
                validate = true;
            }
            setTemplateUrl(selectedTemplate.url+"/"+company._id)                        
        }
        if (selectedTemplate.scope==2) {                        
            if (company!='' && establishment!='') {
                validate = true;
            }            
            setTemplateUrl(selectedTemplate.url+"/"+establishment._id)
        }
        if (selectedTemplate.scope==3) {
            if (company!='' && establishment!='' && lot!='') {
                validate = true;
                setTemplateUrl(selectedTemplate.url+"/"+lot._id)
            }
        }
        setEditTemplateMode(validate); 
        setVisibilityEditButton(false);        
        setIframeDisable(selectedTemplate.type!==5); //Produkto Trupo Habilitado desde un inicio
    }

    const setRef = (frame) => {
        console.log("frame",frame);
        setIframeTemplate(frame);        
    }
    
    const saveTemplate = (save) => {                
        iframeTemplate.contentWindow.postMessage(
        JSON.stringify({
            error: false,
            message: "saveTemplate"
        }),"*");        
    }

    const CancelTemplate = () => {                
        if(allCompanies && allCompanies.length > 0) {            
            setAllTemplates(templates);    
            selectTemplate('');
            setCompanies(allCompanies);
            setAllEstablishmentLots(establishmentLots);
            selectCompany('');
            selectEstablishment('');
            selectLot('');
            setAllEstablishment([]);
            setAllEstablishmentLots([]);            
            setTemplateUrl(false);
            setIframeDisable(true);
        }  
    }


    return (
        <Container style={{height:"80vh",marginTop:"-15px"}}>                                                                                                        
            
            <Row>
                <Col md={12} xl={12} lg={12} xs={12}><h4 style={{fontSize:"14px"}}>Edición de Plantillas Personalizadas</h4><hr style={{marginTop:"2px", marginBottom:"4px"}}/></Col>
            </Row>
            <Row>
                <Col md={8} xl={3} lg={3} xs={8}>
                    <InputLabel id="template-select-label" style={{marginBottom:"0px", marginTop:"5px", fontSize:"14px"}}>Plantilla</InputLabel><br/>
                    <FormControl>                        
                        
                        <Select
                        labelId="template-select-label"
                        id="template-select"
                        onChange={changeTemplate}
                        value={selectedTemplate}
                        displayEmpty style={{fontSize:"12px"}}
                        >
                        <MenuItem style={{fontSize:"12px"}} value='' disabled>Seleccione una Plantilla</MenuItem>                        
                        { allTemplates.map((template) => (
                        <MenuItem style={{fontSize:"12px"}} disabled={(template.type!=1 && template.type!=2 && template.type!=4 && template.type!=5) || (template.type===5 && props.hasSlaughterhouseOrCommercial===false)} value={template}>{template.name}</MenuItem>                                                
                        ))
                        }
                        </Select>
                    </FormControl>
                </Col>
                { (selectedTemplate.scope >= 1) && 
                    <Col md={8} xl={2} lg={2} xs={8}>
                        <InputLabel id="company-select-label" style={{marginBottom:"0px", marginTop:"5px", fontSize:"14px"}}>Empresa</InputLabel><br/>
                        <FormControl>                        
                            
                            <Select
                            labelId="company-select-label"
                            id="company-select"
                            onChange={changeCompany}
                            value={selectedCompany}
                            displayEmpty style={{fontSize:"12px"}}
                            >
                            <MenuItem style={{fontSize:"12px"}} value='' disabled>Seleccione una Empresa</MenuItem>                        
                            { companies.map((company) => (
                            <MenuItem style={{fontSize:"12px"}} value={company}>{company.name}</MenuItem>                        
                            ))
                            }
                            </Select>
                        </FormControl>
                    </Col>
                }
                { (selectedTemplate.scope >= 2) && 
                    <Col md={8} xl={3} lg={3} xs={8}>
                        <InputLabel id="establishment-select-label" style={{marginBottom:"0px", marginTop:"5px", fontSize:"14px"}}>Establecimiento</InputLabel><br/>
                        <FormControl>
                            
                            <Select
                            labelId="establishment-select-label"
                            id="establishment-select"
                            onChange={changeEstablishment}
                            value={selectedEstablishment}
                            displayEmpty style={{fontSize:"12px"}}
                            >
                            <MenuItem style={{fontSize:"12px"}} value='' disabled>Seleccione un Establecimiento</MenuItem>                        
                            { establishments.map((establishment) => {       // TODO Chequear estos criterios
                             
                                if ( selectedTemplate.type==5 ){ // 5 == PRODUKTO TRUPO
                                    if ( establishment.type!=='productive'){   
                                        return  <MenuItem style={{fontSize:"12px"}} value={establishment}>{establishment.name}</MenuItem>                       
                                    } else{
                                        return ""
                                    }
                                } else { 
                                    
                                if ( selectedTemplate.type==2  ){ // 2 == IDENTECO PRODUCTO
                                    if ( establishment.type=='productive'){   
                                        return  <MenuItem style={{fontSize:"12px"}} value={establishment}>{establishment.name}</MenuItem>                       
                                    } else{
                                        return ""
                                    }
                                } else { 

                                    if ( establishment.type==='slaughterhouse' || establishment.type==='importer' || establishment.type==='commercial' || establishment.type==='productive' || selectedTemplate.type==1){   //TODO harcodeo
                                            return  <MenuItem style={{fontSize:"12px"}} value={establishment}>{establishment.name}</MenuItem>                       
                                    } else{
                                        return ""
                                    }
                                }

                                }
                            })}
                            </Select>
                        </FormControl>
                    </Col>
                }
                { (selectedTemplate.scope >= 3) && 
                    <Col md={8} xl={2} lg={2} xs={8}>
                        <InputLabel id="lots-select-label" style={{marginBottom:"0px", marginTop:"5px", fontSize:"14px"}}>Lotes</InputLabel><br/>
                        <FormControl>                        
                            
                            <Select
                            labelId="lots-select-label"
                            id="lots-select"
                            onChange={changeLot}
                            value={selectedLot}
                            displayEmpty style={{fontSize:"12px"}}
                            >
                            <MenuItem style={{fontSize:"12px"}} value='' disabled>Seleccione un Lote</MenuItem>                        
                            { allEstablishmentLots.map((lot) => (
                            <MenuItem style={{fontSize:"12px"}} value={lot}>{lot.name}</MenuItem>                        
                            ))
                            }
                            </Select>
                        </FormControl>
                    </Col>
                }
                { (selectedTemplate.scope >= 1 && visibilityEditButton && iframeDisable) && 
                    <Col md={8} xl={2} lg={2} xs={8}>                                                
                        <Button style={{marginTop:"18px", fontSize:"12px", padding:"8px"}} variant="contained"  color="primary" onClick={()=> setIframeDisable(false)}>
                        Editar Plantilla                           
                        </Button>       
                        <Tooltip
                        show={true}
                        position="bottom center"
                        moveDown="5px"                        
                        moveLeft="20px"                        
                        fontSize="12px"
                        textBoxWidth="250px"
                        static>
                            <span>{tooltipEditTemplate}</span>
                        </Tooltip>              
                        
                    </Col>
                }
                { (!iframeDisable) && (selectedTemplate.type!==5) &&
                    <Col md={8} xl={3} lg={3} xs={8}>                                                                        
                        
                        <Button disabled={(disableSaveButton)} style={{marginTop:"18px", fontSize:"12px", padding:"8px"}} variant="contained"  color="secondary" onClick={()=> saveTemplate(true)}>
                        Guardar Cambios
                        </Button>                       
                        &nbsp;
                        <Button style={{marginTop:"18px", fontSize:"12px", padding:"8px"}} variant="contained"  color="warning" onClick={()=> CancelTemplate()}>
                        Cerrar
                        </Button>                        
                    </Col>
                }
                
                
            </Row>
            

                                                                          
            <Row style={{marginTop:"10px", pointerEvents: iframeDisable ? 'none' : 'auto', opacity: iframeDisable ? "0.2" : '1'}}>
                <Col style={{height:"72vh"}} md={12} xl={12} lg={12} xs={12}>
                    <iframe ref={setRef} allowfullscreen="1" style={{border:'3px dashed #616161',padding:"3px"}} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" src={(editTemplateMode) && templateUrl} id="widget2" data-title="Tutorial - Alta de Animales" width="100%" height="100%" frameborder="0"></iframe>
                </Col>                                                                              
            </Row>
        </Container>
    )
};

const mapDispatchToProps = (dispatch) => ({
    getAvailableBI: () => dispatch(actions.bi.getAvailableBI()),
    getAllCompanies: () => dispatch(actions.template.getAllUserCompanies()),    
    selectCompany: (company) => dispatch(actions.template.selectCompany(company)),
    selectEstablishment: (establishment) => dispatch(actions.template.selectEstablishment(establishment)),
    getAssignedCompanies: (userId) => dispatch(actions.template.getAssignedCompanies(userId)),    
    getHasSlaughterhouseOrCommercial: (userId, role) => dispatch(actions.user.getHasSlaughterhouseOrCommercial(userId, role)),
    getEstablishmentsByCompanyId: (companyId) => dispatch(actions.template.getUserEstablishmentsByCompany(companyId)),
    getUserEstablishmentById: (currentEstablishment, callback) =>
        dispatch(actions.template.getEstablishmentById(currentEstablishment, callback))
});

const mapStateToProps = (state) => ({
    allCompanies: state.template.allCompanies,
    selectedCompany: state.template.selectedCompany,
    establishments: state.template.allEstablishmentsByCompany,
    BI: state.bi.availableBI,    
    reduxState: state,
    roles: state.user.roles,
    userId: state.user.id,
    establishmentLots: state.template.establishmentDetails.animalLots.lots,
    templates: state.template.templates,
    hasSlaughterhouseOrCommercial: state.user.hasSlaughterhouseOrCommercial
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(BI));
