import React, {useState, useEffect} from 'react';
import {Col, Card, CardBody} from 'reactstrap';
import {withTranslation} from "react-i18next";
import ProductiveEstablishmentForm from "../establishments/ProductiveEstablishmentForm";
import SlaughterEstablishmentForm from '../establishments/SlaughterEstablishmentForm';
import {connect} from "react-redux";
import actions from '../../../../redux/actions/index';
import EstablishmentTypeSelection from "./EstablishmentTypeSelection";
import {ESTABLISHMENT_TYPES} from '../../../../constants/index';
import {useAlert} from "react-alert";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

const AddEstablishmentWizard = (props) => {
    const [page, setPage] = useState(1);
    const [establishmentTypeSelected, setEstablishmentTypeSelected] = useState();

    const {t} = props;

    const alert = useAlert();

    useEffect(() => {
        // Only get the productive systems and the ground conditions if the establishment type is 'productive'
        props.getAvailableProductiveSystems();
        props.getFacilitiesProductiveTypes();

        if(establishmentTypeSelected && (establishmentTypeSelected === ESTABLISHMENT_TYPES.productive)) {
            props.getAvailableGroundConditions();
        }
    }, [establishmentTypeSelected]);

    useEffect(() => {
        if(!props.selectedCompany) alert.error(t('add_new_establishment.company_is_need_to_add_an_establishment'), {timeout: 0});
    }, [props.selectedCompany]);

    useEffect(() => {
        console.log("DEBUG XX ",props.facilitiesTypes)
    }, [props.facilitiesTypes]);
    

    const nextPage = () => {
        setPage(page + 1);
    };

    const previousPage = () => {
        setPage(page - 1);
    };

    const submitEstablishmentForm = (values) => {
        props.submitEstablishmentForm(
            values,
            values.company_to_add_establishment.value,
            establishmentTypeSelected, () => {
            props.history.push('/manage_establishments/table');
            alert.success(t('add_new_establishment.establishment_added_successfully_message'));
        }, (errorMessage) => {
            ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
        });
    };

    const selectEstablishmentType = (establishmentType) => {
        setEstablishmentTypeSelected(establishmentType);
        nextPage();
    };

    const getEstablishmentFormByType = (establishmentType) => {
        switch (establishmentType) {
            case ESTABLISHMENT_TYPES.productive:
                return  <div>
                        <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
                        <ProductiveEstablishmentForm
                            previousPage={previousPage}
                            submitEstablishmentForm={submitEstablishmentForm}
                            availableProductiveSystems={props.availableProductiveSystems}
                            availableGroundConditions={props.availableGroundConditions}
                            facilitiesTypes={props.facilitiesTypes}
                            editable={true}
                            isNew={true}
                            history={props.history}
                            showBackButton={true}
                            submitButtonText={t('common.finalize')}
                            showCompanySelection
                            selectedCompany={props.selectedCompany}
                            pending={props.pending}
                        />
                        </div>;
            case ESTABLISHMENT_TYPES.slaughterhouse:
            case ESTABLISHMENT_TYPES.commercial:
            case ESTABLISHMENT_TYPES.importer:
                return <div>
                        <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
                        <SlaughterEstablishmentForm
                            previousPage={previousPage}
                            submitEstablishmentForm={submitEstablishmentForm}
                            editable={true}
                            isNew={true}
                            history={props.history}
                            showBackButton={true}
                            submitButtonText={t('common.finalize')}
                            showCompanySelection
                            selectedCompany={props.selectedCompany}
                            pending={props.pending}
                            establishmentType = {establishmentType}
                        />
                        </div>;
            default:
                return <div>No implementado todavía</div>;
        }
    };

    const getEstablishmentTitleByType = (establishmentType) => {
        switch (establishmentType) {
            case ESTABLISHMENT_TYPES.productive:
                return t('cv-forms.productive_establishment.title');
            case ESTABLISHMENT_TYPES.slaughterhouse:
                return t('cv-forms.slaughter_establishment.title');
            case ESTABLISHMENT_TYPES.commercial:
                return t('cv-forms.commercial.title');
            case ESTABLISHMENT_TYPES.importer:
                return t('cv-forms.importer.title');
            default:
                return 'No implementado todavía';
        }
    };

    const getStep = () => {
        switch (page) {
            case 1:
                return <EstablishmentTypeSelection selectEstablishmentType={selectEstablishmentType}/>;
            case 2:
                return (
                    <Col md={12} lg={12}>
                        <Card>
                            <CardBody>
                                <div className="card__title">
                                    <h5 className="bold-text">{getEstablishmentTitleByType(establishmentTypeSelected)}</h5>
                                </div>
                                {getEstablishmentFormByType(establishmentTypeSelected)}
                            </CardBody>
                        </Card>
                    </Col>
                );
            default:
                break;
        }
    };

    return (
        <Col md={12} lg={12}>
            <Card>
                <CardBody className="wizard">
                    <div className="wizard__steps">
                        <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}>
                            <p>{t('common.step')} 1</p></div>
                        <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}>
                            <p>{t('common.step')} 2</p></div>
                    </div>
                    <div className="wizard__form-wrapper">
                        {getStep()}
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

const mapStateToProps = (state) => ({
    availableProductiveSystems: state.constant.availableProductiveSystems,
    facilitiesTypes: state.constant.facilitiesTypes,
    availableGroundConditions: state.constant.availableGroundConditions,
    selectedCompany: state.user.selectedCompany,
    pending: state.ui.addEstablishmentToCompanyPending
});

const mapDispatchToProps = (dispatch) => ({
    submitEstablishmentForm: (info, companyId, establishmentType, successCallback, errorCallback) =>
        dispatch(actions.user.addEstablishmentToCompany(info, companyId, establishmentType, successCallback, errorCallback)),
    getAvailableProductiveSystems: () => dispatch(actions.constant.getAvailableProductiveSystems()),
    getFacilitiesProductiveTypes: () => dispatch(actions.constant.getFacilitiesProductiveTypes()),
    getAvailableGroundConditions: () => dispatch(actions.constant.getAvailableGroundConditions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(AddEstablishmentWizard))