import React, { useCallback } from "react"
import PropTypes from 'prop-types';
import { Spinner, Modal, ModalBody, Button} from "reactstrap"
import CountDown from "../../../../shared/components/CountDown"
import { withTranslation } from 'react-i18next';

 const OfflineModal = ({t,isOnline, isRetry, handleRetry, counterSeconds, restartCounter = false}) => {
   OfflineModal.propTypes = {
         isOnline: PropTypes.bool.isRequired,
         isRetry: PropTypes.bool.isRequired,
         handleRetry: PropTypes.func,
         counterSeconds: PropTypes.number.isRequired,
         restartCounter: PropTypes.bool.isRequired
     }

  const onRetry = useCallback(()=> {
    handleRetry()
  },[handleRetry])

  return  (<Modal  size="lg" isOpen = {!isOnline}>
            <ModalBody>
                {
                isRetry 
                ? 
                <p style={{"font-size": "0.9rem"}}>{t('conn.reconnecting')}  { <Spinner size="sm" type= "grow"/> }</p> 
                :  
                <p style={{"font-size": "0.9rem"}}>
                    <strong>
                        {t('conn.no_conn')} 
                    </strong>
                    <br/>
                    <p style={{"font-size": "0.9rem"}}>{t('conn.reconnecting')}  { <Spinner size="sm" type= "grow"/> }</p> 
                    { /*t('conn.retrying')} <span style={{display: "inline-block"}}> { 

                                                            // <CountDown 
                                                            //     onFinish ={()=>onRetry()} 
                                                            //     seconds = {counterSeconds} 
                                                            //     restart = {restartCounter} 
                                                            // />
                                                    } </span> {t('conn.seconds')*/}
                </p>
                }
                <br/>
              <Button  size="sm" color="primary" onClick={()=>onRetry()}>Reintentar</Button>
            </ModalBody>
    </Modal>
)
}


export default React.memo (withTranslation('common')(OfflineModal))