/*
*   The event mapper is use for mapping either the body or the response on a event request. It try to decouple the
*   event middleware.
* */

const parseProductiveSystemOptionsToArray = (productiveSystems) => {
    let result = [];
    if(productiveSystems.natural) result.push('natural');
    if(productiveSystems.silvoPastoral) result.push('silvoPastoral');
    if(productiveSystems.pastoral) result.push('pastoral');
    if(productiveSystems.creepFeeding) result.push('creepFeeding');
    if(productiveSystems.strategicFeeding) result.push('strategicFeeding');
    if(productiveSystems.feedlot) result.push('feedlot');

    return result;
};

const mapper =  {
    body: {
        addProductiveSystemChangeEvent: (values) => ({
            facilityId: values.establishment._id,
            eventDate: values.date,
            productionSystem: parseProductiveSystemOptionsToArray(values.productiveSystems)[0],
            feeding: values.selectedFoods,
            earId: values.caravan_id,
            comments: values.comments,
        }),
        addWeightEvent: (values) => ({
            facilityId: values.facilityId,
            eventDate: values.date,
            weight: values.weight,
            earId: values.caravan_id,
            comments: values.comments,
        }),
        addSlaughterEvent: (values) => ({
            facilityId: values.facilityId,
            eventDate: values.date,
            earId: values.caravan_id,
            comments: values.comments,
        }),
        addCastrationEvent: (values) => ({
            facilityId: values.facilityId,
            eventDate: values.date,
            earId: values.caravan_id,
            comments: values.comments,
        }),
        addVaccinationEvent: (values) => ({
            facilityId: values.facilityId,
            eventDate: values.date,
            comments: values.comments,
            earId: values.caravan_id
        }),
        addReidentificationEvent: (values) => ({
            facilityId: values.facilityId,
            eventDate: values.date,
            newEarId: values.newEarId,
            earId: values.caravan_id,
            comments: values.comments,
        }),
        addDentitionEvent: (values) => ({
            facilityId: values.facilityId,
            eventDate: values.date,
            value: values.value.value,
            earId: values.caravan_id,
            comments: values.comments,
        }),
        addDeletionEvent: (values) => ({
            facilityId: values.facilityId,
            eventDate: values.date,
            earId: values.caravan_id,
            comments: values.comments,
            deletionReason: values.deletionReason.value,
        }),
        addLotEvent: (values) => ({
            facilityId: values.facilityId,
            eventDate: values.date,
            earId: values.caravan_id,
            lot: values.value.value,
            newLot: values.new_lot,
            comments: values.comments,
        }),
        addThirdPartySlaughterEvent: (values, uploadedFilesAndUrls = {}) => {
            let result = {
                globalEventName: values.globalEventName,
                frigo: {
                    name: values.fridgeName,
                    ruca: {
                        ruca: values.fridgeRuca,
                        rucaImage: values.rucaImage,
                        activities: {
                            activityCode: '',
                            activity: ''
                        }
                    },
                    numSenasa: values.numSenasa,
                    plantNumber: values.plantNumber,
                    logotypeImage: values.logotypeImage,
                    latitud: values.latitude,
                    longitud: values.longitude
                },
                entityType: values.entityType,
                companyId: values.companyId,
                facility: values.facilityId,
                foreignKey: values.lotId,
                eventDate: values.eventDate,
   
                slaughterRite: parseSlaughterRiteValue(values.slaughterRite),
                lotAverageWeight: values.lotAverageWeight, 
                createBy: values.userId,
                fromDate: values.fromDate,
                toDate: values.toDate
            };

            if(uploadedFilesAndUrls.rucaImage) result.frigo.ruca.rucaImage = uploadedFilesAndUrls.rucaImage.fileUrl
            else if(!values.rucaImage) result.frigo.ruca.rucaImage  = undefined;

            if(uploadedFilesAndUrls.logotypeImage) result.frigo.logotypeImage = uploadedFilesAndUrls.logotypeImage.fileUrl
            else if(!values.logotypeImage) result.frigo.logotypeImage  = undefined;           

            if(uploadedFilesAndUrls.file1) {
                result['file1'] = uploadedFilesAndUrls.file1.fileUrl
                result['file1_checksumFile'] = uploadedFilesAndUrls.file1.md5Hash
                result['file1_description'] = values.file1_description
            } else if(values.file1) {
                    result['file1'] = values.file1;
                    result['file1_checksumFile'] = values.file1_checksumFile
                    result['file1_description'] = values.file1_description
                    } else result['file1'] = undefined;
            
            if(uploadedFilesAndUrls.file2) {
                result['file2'] = uploadedFilesAndUrls.file2.fileUrl
                result['file2_checksumFile'] = uploadedFilesAndUrls.file2.md5Hash
                result['file2_description'] = values.file2_description
            } else if(values.file2) {
                    result['file2'] = values.file2;
                    result['file2_checksumFile'] = values.file2_checksumFile
                    result['file2_description'] = values.file2_description
                    } else result['file2'] = undefined;
            
            if(uploadedFilesAndUrls.file3) {
                result['file3'] = uploadedFilesAndUrls.file3.fileUrl
                result['file3_checksumFile'] = uploadedFilesAndUrls.file3.md5Hash
                result['file3_description'] = values.file3_description
            } else if(values.file3) {
                    result['file3'] = values.file3;
                    result['file3_checksumFile'] = values.file3_checksumFile
                    result['file3_description'] = values.file3_description
                    } else result['file3'] = undefined;
            
            if(uploadedFilesAndUrls.file4) {
                result['file4'] = uploadedFilesAndUrls.file4.fileUrl
                result['file4_checksumFile'] = uploadedFilesAndUrls.file4.md5Hash
                result['file4_description'] = values.file4_description
            } else if(values.file4) {
                    result['file4'] = values.file4;
                    result['file4_checksumFile'] = values.file4_checksumFile
                    result['file4_description'] = values.file4_description
                    } else result['file4'] = undefined;
            
            if(uploadedFilesAndUrls.file5) {
                result['file5'] = uploadedFilesAndUrls.file5.fileUrl
                result['file5_checksumFile'] = uploadedFilesAndUrls.file5.md5Hash
                result['file5_description'] = values.file5_description
            } else if(values.file5) {
                    result['file5'] = values.file5;
                    result['file5_checksumFile'] = values.file5_checksumFile
                    result['file5_description'] = values.file5_description
                    } else result['file5'] = undefined;
            
            return result;
        },
    },

    response: {
        getAllGlobalEvent: (response) => ({
            events: response.docs,
        }),
        getEvents: (response) => ({
            events: response.docs,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        getEventsExcel:(response) => ({
            excelBuffer: response.excelBuffer,
        }),
        searchEvents: (response) => ({
            events: response.results,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        filterEvents: (response) => ({
            events: response.results,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        uploadFileForMassiveEventsLoad: (response) => {

            console.log("🚀 ~ ACTION file: eventMapper.js ~ line 164 ~ response", response)
        return    {
            
            _id: response._id,
            hash: response.hash,
            status: response.status,
            events: parseValidatedRows(response.validatedRows)
        }},
        getAllGlobalEvent: (response) => ({
            globalEvents: response

            // TODO armar funcion similar a parseValidatedRows
            // para retornar todos los datos planos
        }),
        getGlobalEventByIdThirdPartySlaughter: (response) => ({
            globalEventDetails: response

            // TODO armar funcion similar a parseValidatedRows
            // para retornar todos los datos planos
        }),
        getLotsByEstablishmentId: (response, viewAllLots) => (
            mapLotsIdLotsName(response.docs[0].animalLots.lots, viewAllLots)
        ),

    },

    data: {
        uploadGlobalEventFiles: (info) => {
            let files = [];
            if(info.rucaImage) { 
                if(info.rucaImage.files) {
                    files.push({
                        id: 'rucaImage',
                        file: info.rucaImage.files[0]
                    });
                }
            }
            if(info.logotypeImage) { 
                if(info.logotypeImage.files) {
                    files.push({
                        id: 'logotypeImage',
                        file: info.logotypeImage.files[0]
                    });
                }
            }
            if(info.file1) {
                if(info.file1.files) {
                    files.push({
                        id: 'file1',
                        file: info.file1.files[0]
                    });
                }
                }
            if(info.file2) {
                if(info.file2.files) {
                    files.push({
                        id: 'file2',
                        file: info.file2.files[0]
                    });
                }
            }
            if(info.file3) {
                if(info.file3.files) {
                    files.push({
                        id: 'file3',
                        file: info.file3.files[0]
                    });
                }
            }
            if(info.file4) {
                if(info.file4.files) {
                    files.push({
                        id: 'file4',
                        file: info.file4.files[0]
                    });
                }
            }
            if(info.file5) {
                if(info.file5.files) {
                    files.push({
                        id: 'file5',
                        file: info.file5.files[0]
                    });
                }
            }

            return files;
        },

        mapUploadTransferFiles: (files) => {
            let arrFiles = [];
            
            for (let f in files)  {
                const {doc, ...rest} = files[f]
                if(doc && doc.files.length)  arrFiles.push({ file: doc.files[0], id: f, ...rest })
            }
            
            return arrFiles;
        }
    }

};


const parseUploadFileName = (fileUpload) => {

    if (fileUpload) return fileUpload.name;
    return undefined;
};


const parseSlaughterRiteValue = (slaughterRite) => {
    if (slaughterRite) return slaughterRite.value;
    return undefined;
};

const parseValidatedRows = (validatedRows) => {
console.log("🚀 ~ ACTION file: eventMapper.js ~ line 274 ~ parseValidatedRows ~ validatedRows", validatedRows)
    
    let result = [];
    validatedRows.forEach(row => {
        result.push({
            ...row.data,
            status: row.status,
            errors: row.errors
        })
    });
    
    console.log("🚀 ~ ACTION file: eventMapper.js ~ line 277 ~ parseValidatedRows ~ result", result)
    
    return result;
};

const mapLotsIdLotsName = (lots, viewOnlyActiveLots) => {
    let result = []
    let viewAllLots = !viewOnlyActiveLots
    lots.forEach(lot => {
        if(lot.isActive || viewAllLots) {
            result.push({
                _id: lot._id,
                name: lot.name,
            })
        }
    });
    return result;
};

export default mapper;