import React, { PureComponent } from 'react';
import {
  Card, Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { withTranslation } from "react-i18next";
import AccountTable from '../../../ManageAccounts/components/AccountTable';
import "./ProfileTabs.css"

class ProfileTabs extends PureComponent {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: 0,
    };
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { activeTab } = this.state;
    const { t } = this.props;
    return (
      <Col md={12} lg={12} xl={8}>
        <Card>
          <div className="profile__card tabs tabs--bordered-bottom">
            <div className="tabs__wrap">
              <Nav tabs>
                <NavItem>
                  <NavLink
                      className={classnames({ active: activeTab === 0 })}
                      onClick={() => this.toggle(0)}
                  >
                    {t(`profile.companies_tab.companies`)}
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab} className={"ProfileTabContent"}>
                <TabPane tabId={0}>
                  <AccountTable widthPercentage={35}/>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </Card>
      </Col>
    );
  }
}

export default withTranslation('common')(ProfileTabs)