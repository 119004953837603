import {
    GET_LOTS_WITH_ANIMALS_BY_FACILITYID_RESPONSE,
    RESET_SELECTED_EVENT
} from "../actions/globalEventActions";


const initialState = {
    allLotsWhithAnimals: []
};

const eventReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_LOTS_WITH_ANIMALS_BY_FACILITYID_RESPONSE:
            return {
                ...state, 
                allLotsWhithAnimals: action.response
            };

        case RESET_SELECTED_EVENT:
            return {
                ...state,
                allLotsWhithAnimals: []
            }

        default:
            return state
    }
};
export default eventReducer;