import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import actions from '../../redux/actions/index';
import AnimalsTable from "./components/AnimalsTable";
import CVModal from "../UI/Modals/components/CVModal";
import { withTranslation } from 'react-i18next';
import FileUploadModal from "../../shared/components/cv/FileUploadModal";
import { Redirect } from "react-router-dom";
import {useAlert} from "react-alert";
import CVBasicModal from "../../shared/components/cv/CVBasicModal";
import setting from '../../util/settings';
import '../../scss/component/qrcode.scss';
import { propTypes } from 'qrcode.react';

const Animals = (props) => {

    const [animals, setAnimals] = useState();
    const [animalsForDownload, setAnimalsForDownload] = useState();
    const [detailsModalIsOpen, setDetailsModalOpen] = useState(false);
    const [fileUploadModalIsOpen, toggleFileUploadModal] = useState(false);
    const [redirectRoute, setRedirectRoute] = useState();
    const [selectedAnimal, selectAnimal] = useState();
    const [qrModalIsOpen, toggleQrModal] = useState(false);
    const [animalId, setAnimalId] = useState();
    const [animalEarId, setAnimalEarId] = useState();


    const { selectedLots, establishmentSelected, totalPages, pageSize, total, page, t } = props;
    const establishmentId = props.location.state ? props.location.state.establishmentId : undefined;
    const title = props.location.state ? t('animals.table.title') + ' - ' + props.location.state.establishmentName : t('animals.table.title');
    const alert = useAlert();
    const QRCode = require('qrcode.react');


    useEffect(() => {
        const lotIds = getLotidsFromProps();
        if (establishmentId) {
            props.getPageByEstablishment(establishmentId, lotIds, 1, 10)
        } else if(establishmentSelected) {
            props.getPageByEstablishment(establishmentSelected._id, lotIds, 1, 10)
        }
    }, [establishmentSelected, establishmentId, selectedLots]);

    useEffect(() => {
        if (establishmentSelected) {
            props.getUserEstablishmentById(establishmentSelected._id, ()=> {});
        }
    }, []);

    useEffect(() => {
        props.resetSelectedLot();
    }, [establishmentSelected, establishmentId]);

    useEffect(() => {
        setAnimals(props.animals);
    }, [props.animals]);

    useEffect(() => {
        setAnimalsForDownload(props.animalsForDownload);
    }, [props.animalsForDownload]);

    const getLotidsFromProps = () => {
        if(selectedLots != 0) {
            let lotIds = selectedLots.map(lot => ( lot.value ));
            if(lotIds.length === 0) return 0;
            return lotIds;
        }
        return '0';
    }

    const getAllLotidsFromProps = () => {
            let lotIds = selectedLots.map(lot => ( lot.value ));
            if(lotIds.length === 0) return 0;
            return lotIds;
    }

    const findAnimalById = (id) => new Promise((resolve, reject) => {
        animals.forEach(animal => {
            if(animal.id === id) resolve(animal)
        });
        reject();
    });

    const filterAnimal = (filters) => {
        props.filter(filters)
    };

    const renderDetailsBody = () => {
        return (
            selectedAnimal ?
                <div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.id`)}:</h4>
                        <h4 className={"value"}>{selectedAnimal.id}</h4>
                    </div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.caravan_id`)}:</h4>
                        <h4 className={"value"}>{selectedAnimal.caravan_id}</h4>
                    </div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.breed`)}:</h4>
                        <h4 className={"value"}>{t(`animals.table.breed.${selectedAnimal.breed}`)}</h4>
                    </div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.species`)}:</h4>
                        <h4 className={"value"}>{t(`animals.table.species.${selectedAnimal.species}`)}</h4>
                    </div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.sex`)}:</h4>
                        <h4 className={"value"}>{t(`animals.table.headers.sex.${selectedAnimal.sex}`)}</h4>
                    </div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.category`)}:</h4>
                        <h4 className={"value"}>{t(`animals_categories.cow.${selectedAnimal.category}`)}</h4>
                    </div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.birth_date`)}:</h4>
                        <h4 className={"value"}>{selectedAnimal.birth_date}</h4>
                    </div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.born_weight`)}:</h4>
                        <h4 className={"value"}>{selectedAnimal.born_weight}</h4>
                    </div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.age_in_months`)}:</h4>
                        <h4 className={"value"}>{selectedAnimal.age_in_months}</h4>
                    </div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.father_id`)}:</h4>
                        <h4 className={"value"}>{selectedAnimal.father_id}</h4>
                    </div>
                    <div className={'animal-details-row'}>
                        <h4 className={"bold-text label"}>{t(`animals.modals.details.keys.mother_id`)}:</h4>
                        <h4 className={"value"}>{selectedAnimal.mother_id}</h4>
                    </div>
                </div>
                :
                null
        )
    };

    const renderQRBody = () => (
        <div>
            <QRCode value={`${setting.baseUrlForAnimalQR}/${animalId}`} size={500} includeMargin id={"generated_qr"}
                    imageSettings={{src: require(`../../shared/img/carnes-validadas-logo-qr-v2.png`), height: 84, width: 120}}
                    level={'H'}
            />            
            <br/><a target='_BLANK' style={{fontSize:"16px",fontWeight:"bold"}} href={`${setting.baseUrlForAnimalQR}/${animalId}`}>VER</a>            
            <br/>
            <br/>
        </div>
    );

    const toggleDetailsModal = (id) => {
        setDetailsModalOpen(!detailsModalIsOpen);
        findAnimalById(id).then((animal) => selectAnimal(animal))
    };

    const detailsModal = () => (
        <CVModal
            title={t('animals.modals.details.title')}
            body={() => renderDetailsBody()}
            color="primary"
            header
            isOpen={detailsModalIsOpen}
            toggle={() => setDetailsModalOpen(!detailsModalIsOpen)}
            acceptFunc={() => setDetailsModalOpen(!detailsModalIsOpen)}
            hideButtons={true}
        />
    );

    const fileUploadModal = () => (
        <FileUploadModal title={t('animals.modals.massive_upload_modal.title')} isOpen={fileUploadModalIsOpen}
                         toggleModal={toggleFileUploadModal} acceptedFormats={".xls,.xlsx"}
                         onSubmit={(file) => {
                             props.uploadFileForBulkLoad(
                                file.files,
                                establishmentSelected._id,
                                props.history.push("/animals/massiveLoad"),
                                ()=>{ props.history.push("/animals/table");; alert.error(t('events.modals.massive_upload.search_file_error')) }
                            );

                         }}/>
    );

    const downloadQR = () => {
        let canvas = document.getElementById('generated_qr');
        let pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${animalEarId.toString()}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        toggleQrModal(false);
    };

    const qrModal = () => (
        <CVBasicModal
            title={t('animals.modals.qr.title')}
            body={renderQRBody}
            isOpen={qrModalIsOpen}
            toggleModal={() => toggleQrModal(!qrModalIsOpen)}
            cancel={() => toggleQrModal(false)}
            accept={downloadQR}
            acceptText={t('animals.modals.qr.download')}
        />
    );

    const getEstablishmentPage = (page, pageSize) => {
        const lotIds = getLotidsFromProps();
        if(establishmentSelected) {
            props.getPageByEstablishment(establishmentSelected._id, lotIds, page, pageSize)
        }
    };

    const getPageForDownload = (page, pageSize) => {
        if(establishmentSelected) {
            const lotIds = getLotidsFromProps();
            // TODO: Validar cantidad maxima posible
            props.getPageForDownload(establishmentSelected._id, lotIds, 1, 10000000)
        }
    };

    return (
        <div>
            <AnimalsTable
                title={title}
                animals={animals}
                animalsForDownload={animalsForDownload}
                total={total ? total : 0}
                currentPage={page ? page : 1}
                getPage={getEstablishmentPage}
                getPageForDownload={getPageForDownload}
                totalPages={totalPages}
                pageSize={pageSize}
                toggleDetailsModal={(id) => toggleDetailsModal(id)}
                toggleFileUploadModal={(value) => toggleFileUploadModal(value)}
                openQrModal={(animalId, animalEarId) => {
                    setAnimalId(animalId);
                    setAnimalEarId(animalEarId);
                    toggleQrModal(!qrModalIsOpen)
                }}
                redirectTo={(route) => setRedirectRoute(route)}
                filter={filterAnimal}
                pending={props.getAnimalPagePending}
                establishmentSelected={establishmentSelected}
                lotIds={getAllLotidsFromProps()}
            />
            {detailsModal()}
            {fileUploadModal()}
            {qrModal()}
            { redirectRoute && <Redirect to={redirectRoute}/> }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        animals: state.animal.animals.results,
        animalsForDownload: state.animal.animalsForDownload?
            state.animal.animalsForDownload.results : [],
        total: state.animal.animals.total,
        page: state.animal.animals.page,
        totalPages: state.animal.animals.totalPages,
        pageSize: state.animal.animals.pageSize,
        establishmentSelected: state.user.selectedEstablishment,
        getAnimalPagePending: state.ui.getAnimalPagePending,
        getAnimalPageError: state.ui.getAnimalPageError,
        selectedLot: state.user.selectedLot? state.user.selectedLot : '0',
        selectedLots: state.user.selectedLots? state.user.selectedLots : '0',
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPage: (page, pageSize) => dispatch(actions.animal.getPage(page, pageSize)),
        filter: (filters) => dispatch(actions.animal.filter(filters)),
        uploadFileForBulkLoad: (file, establishmentId, successCallback, errorCallback) =>
            dispatch(actions.animal.uploadFileForBulkLoad(file, establishmentId, successCallback, errorCallback)),
        getPageByEstablishment: (establishmentId, lotId, page, pageSize) =>
            dispatch(actions.animal.getPageByEstablishment(establishmentId, lotId, page, pageSize)),
        getPageForDownload: (establishmentId, lotId, page, pageSize) =>
            dispatch(actions.animal.getPageForDownload(establishmentId, lotId, page, pageSize)),
        resetSelectedLot: () => dispatch(actions.user.resetSelectedLot()),
        getUserEstablishmentById: (currentEstablishment, callback) =>
            dispatch(actions.user.getEstablishmentById(currentEstablishment, callback))
    }
};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Animals));
