import React, {useEffect, useState} from 'react';
import {Col, Row, Card, CardBody, Button, ButtonToolbar,Input, FormGroup, Container} from 'reactstrap';
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {change, Field, reduxForm, formValueSelector} from 'redux-form';
import renderSelectField from "../../shared/components/form/Select";
import {connect} from "react-redux";
import actions from "../../redux/actions";
import MaskedInput from "react-text-mask";
import i18n from '../../translations/config/i18n';
import CVButton from "../../shared/components/cv/CVButton";
import { formValidators, subtract_x_years_to_date, formMasks } from '../../util/index';
import { withRouter } from 'react-router-dom';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import qrGenerationActions from '../../redux/actions/qrGenerationActions';
import animalActions from '../../redux/actions/animalActions'
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';
import "../../scss/component/Select2.css"
import '../../scss/component/cvRadioBtn.scss';
import '../../scss/component/cvCowForm.scss';
import Helpi from '../Helpi'
import { ESTABLISHMENT_TYPES } from '../../constants';
import {SRLWrapper} from "simple-react-lightbox";
import { ImageRounded } from '@material-ui/icons';

const optionsLightbox = {
    settings: {},
    caption: {
        showCaption: false,
    },
    buttons: {
        showAutoplayButton: false,
        showNextButton: false,
        showPrevButton: false,
        showThumbnailsButton: false,
        showDownloadButton: false,
        showFullscreenButton: false,
    },
    thumbnails: {
        showThumbnails: false,
    },
    progressBar:{},    
  }

  
const renderField = ({input, iconSrc, disabled, placeholder, type, meta: {touched, error, warning}, mask, highlight}) => (
    <div style={{width: '100%'}}>        
        <nobr>{iconSrc && <img style={{height:"30px", width: "30px"}} src={iconSrc}/>}
            {!mask && <input style={{backgroundColor: highlight?'lightyellow':'white',fontWeight:highlight?'bold':'normal'}} disabled={disabled} {...input} placeholder={placeholder} type={type}/>}
            {mask && <MaskedInput style={{backgroundColor: highlight?'lightyellow':'white',fontWeight:highlight?'bold':'normal'}} disabled={disabled} {...input} placeholder={placeholder} type={type} mask={mask}/>}
        </nobr>
        <div className="input-error-container">
            {touched && ((error && <span className="error-message">{i18n.t(`common:errors.${error}`)}</span>) || (warning &&
                <span className="error-message">{warning}</span>))}
        </div>
    </div>
);



const QrGenerationForm = (props) => {
    QrGenerationForm.propTypes = {
        t: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired
    };


    const {
        t,
        handleSubmit,
        onSubmit,
        editable,
        submittable,
        hideTitle,
        establishmentTemplates,
        selectedEstablishment,
        getLotsInGlobalEvents,
        globalEventsLots,
        getAnimalsHistoryByLot,
        animalsHistoryByLot,
        getEstablishmentTemplates
    } = props;
    const [modal, setModal] = useState(false)
    const [fullImage,setFullImage] = useState('')
    const [validLots, setValidLots] = useState([]);

    const [selectedLot, setSelectedLot] = useState(null);
    const [animalsInfo,setAnimalsInfo]=useState(null)

    const [saveDisable, setSaveDisable] = useState(false);
    const [activeTemplates,setActiveTemplates]=useState([])
    const [readyToInitialize, setReadyToInitialize] = useState(false);

    const [relatedImages,setRelatedImages] =useState([])
    const [selectedImages,setSelectedImages] = useState([])

    useEffect(() => {
        getEstablishmentTemplates(props.facilityId)
        //props.changeFieldValue('slaughterDate',null)

        if (selectedEstablishment.type === ESTABLISHMENT_TYPES.slaughterhouse) {getLotsInGlobalEvents(selectedEstablishment._id,'Faena')}
        if (selectedEstablishment.type === ESTABLISHMENT_TYPES.commercial)     {getLotsInGlobalEvents(selectedEstablishment._id,'FaenaEnTerceros')}        
    }, []);
    
    
    
    useEffect(() => {
        if (globalEventsLots!== undefined){  
            setValidLots (globalEventsLots)
        }
    }, [globalEventsLots]);
    
    useEffect(() => {
        if(props.lotRelatedImages.relatedImages!==undefined){
            let relatedImages = {...props.lotRelatedImages.relatedImages}

            if (props.initialValues.selectedImages!== undefined && props.initialValues.selectedImages!==null && props.initialValues.selectedImages!==[]){
                console.log("DBG Initial",props.initialValues.selectedImages)
                console.log('DBG Imágenes lot',props.lotRelatedImages.relatedImages.facilities)
                for (let qrImage of props.initialValues.selectedImages){
                    let isIncluded = false
                    for (let lotImage of props.lotRelatedImages.relatedImages.facilities){
                        if (qrImage.image===lotImage.facilityProductiveImage){
                            isIncluded=true
                            break    
                        }
                    }
                    if (!isIncluded && qrImage.type==='facility'){
                        relatedImages.facilities.push({facilityName:qrImage.from+' (previa)',
                                                        facilityProductiveImage:qrImage.image,
                                                        })
                    }
                }
            }
            setRelatedImages(relatedImages)
        }
    }, [props.lotRelatedImages]);

    useEffect(() => {
        if (establishmentTemplates!== undefined){  
            setActiveTemplates (establishmentTemplates)
        }
    }, [establishmentTemplates]);

    useEffect(() => {
        if (selectedLot!==null){
            getAnimalsHistoryByLot(selectedLot,selectedEstablishment._id)
        }
    }, [selectedLot]);
    
    useEffect(() => {
        if (animalsHistoryByLot!==null){
            setAnimalsInfo(animalsHistoryByLot)  
        }
    }, [animalsHistoryByLot]);
    
    useEffect(() => {
        if (props.initialValues.selectedImages!== undefined && props.initialValues.selectedImages!==null && props.initialValues.selectedImages!==[]){
            if (props.initialValues.lotId!==null)
            {
            props.getRelatedImagesByLot(props.initialValues.lotId.value)
            setSelectedLot(props.initialValues.lotId.value)
            setSelectedImages(props.initialValues.selectedImages)
            }
        }
    }, [props.initialValues.selectedImages]);
    
    
    const parseFacilities = (facilities) =>{
        let parsed=''
        facilities = [...facilities.reverse()]
        for (let f=0; f<facilities.length; f++){
            if (f==0){
                parsed = facilities[f].name
            } else {
                parsed = parsed +  ' ⟶ '  + facilities[f].name
            }
        }
        return parsed

    }
            
    const showErrors = (invalid) => {
        if(invalid) ToastsStore.error(t('common.general_invalid_form'), 10000)
    }
            
            
    const submitForm = (values) => {
        values.facilityId = props.selectedEstablishment._id;
        onSubmit({...values});
    };

    const getSelectedLot = (e) => {
        setSelectedImages([])
        setSelectedLot(e.value)
        props.getRelatedImagesByLot(e.value)
        props.changeFieldValue('referenceAnimalId',null)
        let lot_aux = validLots.filter((lot) => lot.foreignKey=== e.value)
        if(lot_aux.length===1){
            props.changeFieldValue('slaughterDate',lot_aux[0].eventDate)
            }

    }

    const getSelectedTemplate = (e) => {
        // setSelectedTemplate(e.value)
    }
    const getSelectedAnimal = (e) => {
        // setSelectedAnimal(e.value)
    }
        
    const manageImages = (checked,entity,type) => {
        let temp = [...selectedImages]
        let item = {'type': type,
                    'image': type==='animal' ? entity.animalImage : entity.facilityProductiveImage,
                    'from':  type==='animal' ? entity.earId : entity.facilityName}
        if (checked){
            temp.push(item)
        }else{
            temp = temp.filter(function(value, index, arr){ return value.image !== item.image; });
        }
        setSelectedImages(temp)
    }        
        
    useEffect(()=>{
        //console.log('DBG selectedImages',selectedImages)
        props.changeFieldValue('selectedImages',selectedImages)
    },[selectedImages])

    
    const refreshLabelsLightbox = () => {
        var divs = document.getElementsByClassName( "SRLCloseButton" );
        for (var a in divs) {
            try {
                divs[a].setAttribute('title', 'CERRAR');
            } catch (error) {

            }            
        }        
        var divsDownload = document.getElementsByClassName( "SRLDownloadButton" );
        for (var a in divs) {
            try {
                divsDownload[a].setAttribute('title', 'DESCARGAR');
            } catch (error) {

            }
        }        
    }

    return (
        <Container style={{maxWidth:'100%'}}>
        <Row>
            <Col md={12} lg={12}>
           <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
   
           
            <Card >
                <CardBody>
                    <div className="card__title">
                        {!hideTitle && <h5 className="bold-text">{t('cv-forms.qr.newTitle')}</h5>}
                    </div>
                    <form className="form" style={{display:'flex', flexDirection:'column'}} onSubmit={handleSubmit(submitForm)}>
                    <div className="edit-buttons" >
                        {!submittable && 
                        <label><h4>Aguarde...</h4></label>
                    }
                        {submittable && 
                        <ButtonToolbar className="form__button-toolbar" >
                                
                                { !editable && <Button 
                                    color="primary" 
                                    outline
                                    type="button" 
                                    onClick={() => props.setEditable(!editable)}>
                                    {t('common.edit')}</Button>
                                }
                                { editable && <CVButton
                                    outline 
                                    color="success"
                                    type="submit"
                                    onClick={() => showErrors(props.invalid)}
                                    pending={props.pending}
                                    disabled={saveDisable}
                                    >{t('common.save')}</CVButton>
                                }
                                { editable && <Button 
                                    type="button"
                                    outline
                                    color="danger"
                                    size="sm"
                                    onClick={() => {
                                        if(props.isNew){
                                            props.history.push('/qrGeneration/table');
                                        }else{
                                        props.setEditable(!editable)
                                        }
                                        }
                                        }>
                                    {t('common.cancel')}</Button>
                                }
                                { !editable && <Button 
                                    color="primary" 
                                    outline={true} 
                                    type="button" 
                                    onClick={() => props.history.goBack()}>
                                    {t('common.back')}</Button>
                                }
                        </ButtonToolbar>
                    }
                    </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.qr.qrName')}*</span>
                            <Helpi 
                            title="" 
                            size="20"
                            iconColor="lightgrey"
                            isHtml
                            fontColor="white"/>
                            <div className="form__form-group-field">
                                    <Field
                                        name="nameQr"
                                        component={renderField}
                                        disabled={!editable}
                                        validate={formValidators.validateRequired}
                                       // type="text"
                                        placeholder={t('cv-forms.qr.qrNamePlaceholder')}
                                    />

                            </div>


                        </div>
                        { activeTemplates!== undefined &&
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.qr.templates')}*</span>
                            <Helpi 
                                        title="" 
                                        size="20"
                                        iconColor="lightgrey"
                                        fontColor="white"/>
                            <div className="form__form-group-field">
                                <Field
                                    name="templateId"
                                    component={renderSelectField}
                                    disabled={!editable}
                                    validate={formValidators.validateRequired}
                                    type="text"
                                    placeholder={t('cv-forms.qr.templatesPlaceholder')}
                                    options={
                                        [
                                          ...activeTemplates.map(template => ({
                                                value: template._id,
                                                label: template.templateName + " ("+template.templateLang+")"
                                            }))
                                        ]
                                    }
                                />
                            </div>
                        </div>
                      }
                      { validLots!== undefined &&
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.qr.slaughterLot')}</span>
                            <div className="form__form-group-field">
                                {validLots.length===0 ? t('cv-forms.qr.noSlaughteredLot') : 
                                <Field
                                    name="lotId"
                                    component={renderSelectField}
                                    type="text"
                                    placeholder={t('cv-forms.qr.slaughterLotPlaceholder')}
                                    disabled={!editable}
                                    onChange={getSelectedLot}
                                    options={
                                        [   {value:'',label:'',disabled:true},
                                            ...validLots.map(lot => ({
                                                value: lot.foreignKey,
                                                label: lot.lotName + ' ('+lot.eventDate.slice(0,10)+')'  
                                            }))
                                        ]
                                    }
                                />
                            }
                            </div>
                        </div>
                        } 
                        <div className="form__form-group" style={{display:'none'}}>
                                <div className="form__form-group-field">
                                    <Field
                                        name="slaughterDate"
                                        component={renderField}
                                        disabled={!editable}
                                        type="text"
                                        
                                    />
                                </div>
                            </div>
                        <div className="form__form-group" style={{display:'none'}}>
                            <div className="form__form-group-field">
                                <Field
                                    name="selectedImages"
                                    component={renderField}
                                    disabled={!editable}
                                    type="text"
                                />
                            </div>
                        </div>
                      { animalsInfo!== undefined && animalsInfo!== null &&
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.qr.referenceAnimal')}</span>
                            <div className="form__form-group-field">
                                
                                <Field
                                    name="referenceAnimalId"
                                    component={renderSelectField}
                                    type="text"
                                    placeholder={selectedLot ? t('cv-forms.qr.referenceAnimalPlaceholder'):t('cv-forms.qr.chooseSlaughteredLotFirst')}
                                    disabled={!editable }
                                    validate={selectedLot ? formValidators.validateRequired:''}
                                    onChange={getSelectedAnimal}
                                    options={ 
                                        [
                                            ...animalsInfo.map(animal => ({
                                                value: animal._id,
                                                label: animal.earId + ': ' + parseFacilities(animal.facilityHistory)
                                            }))
                                        ]
                                    }
                                />
                            </div>
                        </div>
                        } 
                        {relatedImages!==[] && selectedLot!==null &&
                            <div>
                                {relatedImages.facilities!==undefined &&
                                      <div className="form__form-group" >
                                        <span className="form__form-group-label">{'Imágenes del Sistema Productivo de los Establecimientos'}</span>
                                        <div className="form__form-group-field" >
                                             
                                            {   relatedImages.facilities.length === 0 ?
                                                <div>Los animales no han pasado por establecimientos que contengan fotos de sus sistemas productivos</div> //TODO pasar a i18
                                                :

                                                relatedImages.facilities.map((facility) => 
                                                    {return(
                                                            <FormGroup check style={{ paddingLeft:'2rem',paddingRight:'2rem',paddingTop:'2rem'}}>
                                                                <Input type='checkbox' 
                                                                    defaultChecked={ selectedImages.some( i => i.image === facility.facilityProductiveImage)} 
                                                                    style={{height:'1rem',width:'1rem'}} 
                                                                    onChange={(value)=> manageImages(value.currentTarget.checked,facility,'facility')} 
                                                                    disabled={!editable}/> 
                                                                <SRLWrapper options={optionsLightbox}>                                                        
                                                                    <img onClick={()=>{refreshLabelsLightbox()}} style={{maxHeight:"8rem", width:"auto", cursor:"pointer", border:'1px solid gray'}} src={facility.facilityProductiveImage}/>                                                        
                                                                </SRLWrapper>
                                                                Fuente: Establecimiento {facility.facilityName}
                                                            </FormGroup>
                                                          )
                                                    })

                                            }
                                   

                                        </div>   
                                    </div>
                                }
                            </div>
                        }
                        {relatedImages!==[] && selectedLot!==null &&
                            <div>
                                {relatedImages.animals!==undefined &&
                                      <div className="form__form-group" >
                                        <span className="form__form-group-label">{t('cv-forms.qr.AnimalImages')}</span>
                                        <div className="form__form-group-field" >
                                             
                                            {   relatedImages.animals.length === 0 ?
                                                <div>Los animales faenados no contenían imágenes</div> //TODO pasar a i18
                                                :
                                                
                                                relatedImages.animals.map((animal) => 
                                                    {return(
                                                            <FormGroup check style={{ paddingLeft:'2rem',paddingRight:'2rem',paddingTop:'2rem'}}>
                                                                <Input type='checkbox' 
                                                                    defaultChecked={ selectedImages.some( i => i.image === animal.animalImage)}
                                                                    style={{height:'1rem',width:'1rem'}} 
                                                                    onChange={(value)=> manageImages(value.currentTarget.checked,animal,'animal')} 
                                                                    disabled={!editable}/> 
                                                                <SRLWrapper options={optionsLightbox}>                                                        
                                                                    <img onClick={()=>{refreshLabelsLightbox()}} style={{maxHeight:"8rem", width:"auto", cursor:"pointer", border:'1px solid gray'}} src={animal.animalImage}/>                                                        
                                                                </SRLWrapper>
                                                                Fuente: Animal {animal.earId}
                                                            </FormGroup>
                                                          )
                                                    })

                                            }
                                   

                                        </div>   
                                    </div>
                                }
                            </div>
                        }
                        
                        <div className="edit-buttons">
                            {!submittable && 
                            <label><h4>Aguarde...</h4></label>
                            }
                            {submittable &&
                            <ButtonToolbar className="form__button-toolbar" >
                                    { !editable && <Button 
                                        color="primary" 
                                        outline
                                        type="button" 
                                        onClick={() => props.setEditable(!editable)}>
                                        {t('common.edit')}</Button>
                                    }
                                    { editable && <CVButton
                                        outline 
                                        color="success"
                                        type="submit"
                                        disabled={saveDisable}
                                        onClick={() => showErrors(props.invalid)}
                                        pending={props.pending}
                                        >{t('common.save')}</CVButton>
                                    }
                                    { editable && <Button 
                                        type="button"
                                        outline
                                        color="danger"
                                        size="sm"
                                        onClick={() => {
                                            if(props.isNew){
                                                props.history.push('/qrGeneration/table');
                                            }else{
                                            props.setEditable(!editable)
                                            }
                                            }
                                            }>
                                        {t('common.cancel')}</Button>
                                    }
                                    { !editable && <Button 
                                        color="primary" 
                                        outline={true} 
                                        type="button" 
                                        onClick={() => props.history.goBack()}>
                                        {t('common.back')}</Button>
                                    }
                            </ButtonToolbar>
                            }
                        </div>

                    </form>
                </CardBody>
            </Card>
        </Col>
        </Row>
    </Container>
    )
};



const selector = formValueSelector('qrGeneration-form');

const mapStateToProps = (state, props) => ({
    initialValues: props.initialValues || {},
    selectedEstablishment: state.user.selectedEstablishment,
    establishmentTemplates: state.qrGeneration.QR === undefined ? [] : state.qrGeneration.QR.templates,
    establishmentDetails: state.user.establishmentDetails,
    //establishmentLots: state.user.establishmentDetails.animalLots.lots,
    allEstablishments: state.user.allEstablishments,
    globalEventsLots: state.qrGeneration.QR === undefined ? [] : state.qrGeneration.QR.lots,
    animalsHistoryByLot: state.animal.animals.animalsHistoryByLot===undefined ? [] : state.animal.animals.animalsHistoryByLot,
    lotRelatedImages: state.qrGeneration.relatedImages === undefined ? [] : state.qrGeneration.relatedImages,
});

const mapDispatchToProps = (dispatch) => ({
    changeFieldValue: (field, value) => dispatch(change('qrGeneration-form', field, value)),
    getUserEstablishmentById: (currentEstablishment, callback) =>
        dispatch(actions.user.getEstablishmentById(currentEstablishment, callback)),
    getEstablishmentTemplates: (currentEstablishment) => dispatch(qrGenerationActions.getQrsTemplates(currentEstablishment,'QR')), 
    getLotsInGlobalEvents: (currentEstablishment,globalEventName) => dispatch(qrGenerationActions.getLotsInGlobalEvents(currentEstablishment,globalEventName,'QR')),
    getAnimalsHistoryByLot: (lotId,facilityId) => dispatch(animalActions.getAnimalsHistoryByLot(lotId,facilityId)),
    getRelatedImagesByLot:(lotId) => dispatch(qrGenerationActions.getRelatedImagesByLot(lotId))
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'qrGeneration-form',
    touchOnChange: true // this is set because if not the 'touched' props on DateInputField was always false
})(withTranslation('common')(withRouter(QrGenerationForm))));
