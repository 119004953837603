export const CREATE_WALLET = 'CREATE_WALLET';
export const CREATE_WALLET_RESPONSE = 'CREATE_WALLET_RESPONSE';
export const CREATE_WALLET_ERROR = 'CREATE_WALLET_ERROR';
export const GET_LOCAL_WALLET_RESPONSE = 'GET_LOCAL_WALLET_RESPONSE';
export const GET_LOCAL_WALLET_ERROR = 'GET_LOCAL_WALLET_ERROR';
export const GET_REMOTE_WALLET_RESPONSE = 'GET_REMOTE_WALLET_RESPONSE';
export const GET_REMOTE_WALLET_ERROR = 'GET_REMOTE_WALLET_ERROR';
export const GET_WALLET = 'GET_WALLET';
export const GET_WALLET_ERROR = 'GET_WALLET_ERROR';
export const SAVE_WALLET = 'SAVE_WALLET';
export const SAVE_WALLET_RESPONSE = 'SAVE_WALLET_RESPONSE';
export const SAVE_WALLET_ERROR = 'SAVE_WALLET_ERROR';
export const RECREATE_REMOTE_WALLET = 'RECREATE_REMOTE_WALLET';
export const RECREATE_REMOTE_WALLET_ERROR = 'RECREATE_REMOTE_WALLET_ERROR';


const wallet = {
    createWallet(mnemonic, secretWord, pin, callback){
        return {
            type: CREATE_WALLET,
            mnemonic,
            secretWord,
            pin,
            callback
        }
    },
    createWalletResponse(response){
        return {
            type: CREATE_WALLET_RESPONSE,
            response
        }
    },
    createWalletError(message){
        return {
            type: CREATE_WALLET_ERROR,
            message
        }
    },
    getLocalWalletResponse(response){
        return {
            type: GET_LOCAL_WALLET_RESPONSE,
            response
        }
    },
    getLocalWalletError(message){
        return {
            type: GET_LOCAL_WALLET_ERROR,
            message
        }
    },
    getRemoteWalletResponse(response){
        return {
            type: GET_REMOTE_WALLET_RESPONSE,
            response
        }
    },
    getRemoteWalletError(message){
        return {
            type: GET_REMOTE_WALLET_ERROR,
            message
        }
    },
    recreateRemoteWallet(password, pin){
        return {
            type: RECREATE_REMOTE_WALLET,
            password,
            pin
        }
    },
    recreateRemoteWalletError(message){
        return {
            type: RECREATE_REMOTE_WALLET_ERROR,
            message
        }
    },
    getWallet(callback){
        return {
            type: GET_WALLET,
            callback
        }
    },
    getWalletError(message){
        return {
            type: GET_WALLET_ERROR,
            message
        }
    },
    saveWallet(wallet, pin){
        return {
            type: SAVE_WALLET,
            wallet,
            pin
        }
    },
    saveWalletResponse(response){
        return {
            type: SAVE_WALLET_RESPONSE,
            response
        }
    },
    saveWalletError(message){
        return {
            type: SAVE_WALLET_ERROR,
            message
        }
    }
};

export default wallet;