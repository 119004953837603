export const GET_QRS_GENERATED_BY_FACILITY_PAGE = 'GET_QRS_GENERATED_BY_FACILITY_PAGE';
export const GET_QRS_GENERATED_BY_FACILITY_PAGE_RESPONSE = 'GET_QRS_GENERATED_BY_FACILITY_PAGE_RESPONSE';
export const GET_QRS_GENERATED_BY_FACILITY_PAGE_ERROR = 'GET_QRS_GENERATED_BY_FACILITY_PAGE_ERROR';
export const GET_QRS_TEMPLATES = 'GET_QRS_TEMPLATES';
export const GET_QRS_TEMPLATES_RESPONSE = 'GET_QRS_TEMPLATES_RESPONSE';
export const GET_QRS_TEMPLATES_ERROR = 'GET_QRS_TEMPLATES_ERROR';
export const SUBMIT_QR_GENERATION = 'SUBMIT_QR_GENERATION';
export const SUBMIT_QR_GENERATION_RESPONSE = 'SUBMIT_QR_GENERATION_RESPONSE';
export const SUBMIT_QR_GENERATION_ERROR = 'SUBMIT_QR_GENERATION_ERROR';
export const EDIT_QR_GENERATION = 'EDIT_QR_GENERATION';
export const EDIT_QR_GENERATION_RESPONSE = 'EDIT_QR_GENERATION_RESPONSE';
export const EDIT_QR_GENERATION_ERROR = 'EDIT_QR_GENERATION_ERROR';
export const GET_QRS_GENERATED_BY_ID = 'GET_QRS_GENERATED_BY_ID';
export const GET_QRS_GENERATED_BY_ID_RESPONSE = 'GET_QRS_GENERATED_BY_ID_RESPONSE';
export const GET_QRS_GENERATED_BY_ID_ERROR = 'GET_QRS_GENERATED_BY_ID_ERROR';
export const GET_LOTS_IN_GLOBAL_EVENTS = 'GET_LOTS_IN_GLOBAL_EVENTS';
export const GET_LOTS_IN_GLOBAL_EVENTS_RESPONSE = 'GET_LOTS_IN_GLOBAL_EVENTS_RESPONSE';
export const GET_LOTS_IN_GLOBAL_EVENTS_ERROR = 'GET_LOTS_IN_GLOBAL_EVENTS_ERROR';
export const GET_RELATED_IMAGES_BY_LOT = 'GET_RELATED_IMAGES_BY_LOT';
export const GET_RELATED_IMAGES_BY_LOT_RESPONSE = 'GET_RELATED_IMAGES_BY_LOT_RESPONSE';
export const GET_RELATED_IMAGES_BY_LOT_ERROR = 'GET_RELATED_IMAGES_BY_LOT_ERROR';

const qrGenerationActions = {
    getQrsGeneratedById(establishmentId,eventType){
        return {
            type: GET_QRS_GENERATED_BY_ID,
            establishmentId,
            eventType
        }
    },
    getQrsGeneratedByIdResponse(response,eventType){
        return {
            type: GET_QRS_GENERATED_BY_ID_RESPONSE,
            response,
            eventType
        }
    },
    getQrsGeneratedByIdError(message){
        return {
            type: GET_QRS_GENERATED_BY_ID_ERROR,
            message
        }
    },

    getQrsGeneratedByFacilityPage(establishmentId, pageNumber, pageSize,eventType){
        return {
            type: GET_QRS_GENERATED_BY_FACILITY_PAGE,
            establishmentId,
            pageNumber,
            pageSize,
            eventType
        }
    },
    getQrsGeneratedByFacilityPageResponse(response,eventType){
        return {
            type: GET_QRS_GENERATED_BY_FACILITY_PAGE_RESPONSE,
            response,
            eventType
        }
    },
    getQrsGeneratedByFacilityPageError(message){
        return {
            type: GET_QRS_GENERATED_BY_FACILITY_PAGE_ERROR,
            message
        }
    },
    getQrsTemplates(establishmentId,eventType){
        return {
            type: GET_QRS_TEMPLATES,
            establishmentId,
            eventType
        }
    },
    getQrsTemplatesResponse(response,eventType){
        return {
            type: GET_QRS_TEMPLATES_RESPONSE,
            response,
            eventType
        }
    },
    getQrsTemplatesError(message){
        return {
            type: GET_QRS_TEMPLATES_ERROR,
            message
        }
    },

    getLotsInGlobalEvents(establishmentId,globalEventName,eventType){
        return {
            type: GET_LOTS_IN_GLOBAL_EVENTS,
            establishmentId,
            globalEventName,
            eventType
        }
    },
    getLotsInGlobalEventsResponse(response,eventType){
        return {
            type: GET_LOTS_IN_GLOBAL_EVENTS_RESPONSE,
            response,
            eventType
        }
    },
    getLotsInGlobalEventsError(message){
        return {
            type: GET_LOTS_IN_GLOBAL_EVENTS_ERROR,
            message
        }
    },
    submitQrGeneration(values, facilityId, successCallback, errorCallback) {
        return {
            type: SUBMIT_QR_GENERATION,
            values,
            facilityId,
            successCallback,
            errorCallback
        }
    },
    submitQrGenerationResponse(response) {
        return {
            type: SUBMIT_QR_GENERATION_RESPONSE,
            response
        }
    },
    submitQrGenerationError(message) {
        return {
            type: SUBMIT_QR_GENERATION_ERROR,
            message
        }
    },
    editQrGeneration(qrId,values,successCallback, errorCallback) { 
        return {
            type: EDIT_QR_GENERATION,
            qrId,
            values,
            successCallback,
            errorCallback
        }
    },
    editQrGenerationResponse(response) {
        return {
            type: EDIT_QR_GENERATION_RESPONSE,
            response
        }
    },
    editQrGenerationError(message) {
        return {
            type: EDIT_QR_GENERATION_ERROR,
            message
        }
    },
    getRelatedImagesByLot(lotId) {
        return {
            type: GET_RELATED_IMAGES_BY_LOT,
            lotId,
        }
    },
    getRelatedImagesByLotResponse(response) {
        return {
            type: GET_RELATED_IMAGES_BY_LOT_RESPONSE,
            response
        }
    },
    getRelatedImagesByLotError(message) {
        return {
            type: GET_RELATED_IMAGES_BY_LOT_ERROR,
            message
        }
    },
};

export default qrGenerationActions;