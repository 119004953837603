import React, {useState, useEffect, useCallback} from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import actions from "../../redux/actions";
import { withRouter } from "react-router";


const SRAConnectionStatus = (props)=> {
  
  const {seconds, t, getSRAConnectionStatus, SRAConnectionStatus} = props
  const {status, error} = SRAConnectionStatus
  

  useEffect( () => {
    
    const instance = (seconds > 10 ) && demonSRAConnectionStatus()

    return () => { clearInterval(instance) }
          
  }, [status]);
        
        
        
  const demonSRAConnectionStatus =  ()=> (setInterval (async () => {
      const data = { mode:"hba", hba: 811196, category: { value:"Ternera", label:"Ternera" }, breed:{ value:"ANGUS", label:"ANGUS" } }

      try {

        getSRAConnectionStatus(data, null, null)

      } catch (err) { 
          return err 
        }
    }, seconds * 1000))
  

    return ( <></>
      // error ? <div style={{color: "red"}}>{`Error en el servicio: ${error}`}</div> : (!status && <div style={{color: "red"}}>{`El servicio no se encuentra disponible`}</div>) 
    )
}




const mapStateToProps = (state) => ({
  SRAConnectionStatus : state.servicesStatus.SRAConnectionStatus
})


const mapDispatchToProps = (dispatch) => ({
    getSRAConnectionStatus: (data, successCallback, errorCallback) => dispatch(actions.servicesStatus.getSRAConnectionStatus(data, successCallback, errorCallback)),

});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(SRAConnectionStatus)));


