import React, {useEffect, useState} from 'react'
import MagnifyIcon from "mdi-react/MagnifyIcon";
import CVTableEvents from "../../../Tables/CVTable/CVTableEvents";
import { withTranslation } from "react-i18next";
import eventActions from '../../../../redux/actions/eventActions';
import { connect } from 'react-redux';
import FileUploadOutlineIcon from "mdi-react/FileUploadOutlineIcon";
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import FileTableOutlineIcon from "mdi-react/FileTableOutlineIcon";
import FileUploadModal from "../../../../shared/components/cv/FileUploadModal";
import { withRouter } from 'react-router-dom';
import { EVENT_TYPES } from '../../../../constants/index';
import {parse_UTC_date_to_DD_MM_YYYY} from "../../../../util";
import {Tooltip} from "react-tippy";
import FileDownloadOutlineIcon from "mdi-react/FileDownloadOutlineIcon";
import settings from "../../../../util/settings";
import { ErrorNoEstablishment } from "../EventErrors";
import {useAlert} from "react-alert";

const DentitionTable = (props) => {
    const { t, events, total, excelBuffer, getExcel, page, getPage, totalPages, pageSize, establishmentId,establishmentSelected } = props;
    const [fileUploadModalIsOpen, setFileUploadModalOpen] = useState(false);
    const [excelDownload, setExcelDownload] = useState(false);
    const templatesUrl = settings.templatesUrl;
    const [establishmentName, setEstablishmentName] = useState("");

    const alert = useAlert();

    useEffect(() => {
        getPage(establishmentId, 1, 10)
    }, []);

    useEffect(() => {    
        getPage(establishmentId, 1, 10)
  }, [establishmentId]);

  useEffect(() => {
    if (excelDownload===true && excelBuffer!=null && excelBuffer!=undefined)
    {   var blob=new Blob([new Uint8Array(excelBuffer.data)], {type: 'application/octet-stream' });
        var link=document.createElement('a');
        link.href=window.URL.createObjectURL(blob);
        link.download="eventos.xlsx";
        link.click();
        setExcelDownload(false);
    }
}, [excelBuffer]);


  useEffect (() => {
    console.log("DGB est",establishmentSelected)
    if (props.establishmentSelected!==undefined){
      setEstablishmentName(" del establecimiento "+props.establishmentSelected.name)
    }else{
      setEstablishmentName("")
    }
  },[props.establishmentSelected])

    const renderValueDefault = ({value}) => {
        return (
          <div style={{display: 'flex', justifyContent: 'center'}}>
              {value}
          </div>
        )
    };

    const renderDate = ({value}) => {
        return (
          <div style={{display: 'flex', justifyContent: 'center'}}>
              {value ?
                parse_UTC_date_to_DD_MM_YYYY(value):
                "-"
              }
          </div>
        )
    };

    const renderButtons = ({ dependentValues }) => {
        return (
          <div style={{display: 'flex', justifyContent: 'center'}}>
              <MagnifyIcon className={"button"} style={{marginLeft: "10px"}}
                           onClick={() => props.history.push('/events/dentition/details', {event: dependentValues})}/>
          </div>
        )
    };

    const renderIconsForNewEvent = () => (
      <div>
           <Tooltip
              title="Descargar lista de eventos"
              position="top"
              trigger="mouseenter"
              animation="scale"
              duration="600"
              size="small"
              theme="transparent"
              distance="0" >
                <FileTableOutlineIcon onClick={() => {if(events) {setExcelDownload(true);
                                                                    getExcel(establishmentId);}}}/>
            </Tooltip>
          <Tooltip
            title="Descargar plantilla"
            position="top"
            trigger="mouseenter"
            animation="scale"
            duration="600"
            size="small"
            theme="transparent"
            distance="0"
          >
              <a href={templatesUrl + "modelo evento denticion.xls"} download><FileDownloadOutlineIcon/></a>
          </Tooltip>
          <FileUploadOutlineIcon onClick={() => {props.resetMassiveLoadEvents(); setFileUploadModalOpen(true)} }/>
          <PlusCircleOutlineIcon onClick={() => props.history.push('/events/dentition/new_event')}/>
      </div>
    );

    const heads = [
        {
            key: 'earId',
            name: t('events.dentition.table.headers.caravan_id'),
            percentage: 50,
            minWidth: 150,
            formatter: renderValueDefault
        },
        {
            key: 'date',
            name: t('events.dentition.table.headers.date'),
            percentage: 25,
            minWidth: 210,
            formatter: renderDate
        },
        {
            key: 'value',
            name: 'Dentición',
            percentage: 25,
            minWidth: 210,
            formatter: renderValueDefault
        },        
        {
          key: 'facilityPushed',
          name: t('events.dentition.table.headers.facilityPushed'),
          percentage: 25,
          minWidth: 210,
          formatter: renderValueDefault
      },
        {
            key: 'actions',
            name: t('events.table.headers.actions'),
            percentage: 25,
            minWidth: 135,
            formatter: renderButtons,
            getRowMetaData: (row) => row
        }
    ];

    return(
      (establishmentId) ? 
      <div>
          <CVTableEvents
            getPage={getPage}
            data={events}
            total={total}
            currentPage={page}
            totalPages={totalPages}
            pageSize={pageSize}
            heads={heads}
            hideFilters
            title={t('events.dentition.table.title')+" del establecimiento "+establishmentName}
            SubtitleComponent={renderIconsForNewEvent}
            noDataLabel={t('events.dentition.table.no_data_label')}
            establishmentId={establishmentId}
            />
          <FileUploadModal title={t('events.modals.massive_upload.title')} isOpen={fileUploadModalIsOpen}
                           toggleModal={() => setFileUploadModalOpen(!fileUploadModalIsOpen)} acceptedFormats={".xls,.xlsx"}
                           onSubmit={(file) => {
                              props.uploadFileForMassiveLoad(
                                file.files, 
                                props.establishmentId, 
                                props.history.push('/events/dentition/massive_load'),
                                ()=>{ props.history.push('/events/dentition/table'); alert.error(t('events.modals.massive_upload.search_file_error')) }
                              );
                            }}/>
      </div>
      : <ErrorNoEstablishment />

    );
};

const mapStateToProps = (state) => ({
    events: state.event[`${EVENT_TYPES.dentition}`] ?
      state.event[`${EVENT_TYPES.dentition}`].events : [],
    total: state.event[`${EVENT_TYPES.dentition}`] ?
      state.event[`${EVENT_TYPES.dentition}`].total : 0,
    page: state.event[`${EVENT_TYPES.dentition}`] ?
      state.event[`${EVENT_TYPES.dentition}`].page : 1,
    totalPages: state.event[`${EVENT_TYPES.dentition}`] ?
      state.event[`${EVENT_TYPES.dentition}`].totalPages : 0 ,
    pageSize: state.event[`${EVENT_TYPES.dentition}`] ?
      state.event[`${EVENT_TYPES.dentition}`].pageSize : 0,
    establishmentId: state.user.selectedEstablishment != undefined ? state.user.selectedEstablishment._id : null,
    excelBuffer: state.event[`${EVENT_TYPES.dentition}`] ? state.event.excelBuffer : null,
    establishmentSelected: state.user.selectedEstablishment,
});

const mapDispatchToProps = (dispatch) => ({
    getPage: (establishmentId, page, pageSize) => dispatch(eventActions.getEventsPage(establishmentId, page, pageSize, EVENT_TYPES.dentition)),
    uploadFileForMassiveLoad: (file, establishmentId, successCallback, errorCallback) =>
      dispatch(eventActions.uploadFileForMassiveEventLoad(file, establishmentId, EVENT_TYPES.dentition, successCallback, errorCallback)),
    getExcel: (establishmentId) => dispatch(eventActions.getEventsExcel(establishmentId,  EVENT_TYPES.dentition)),
    resetMassiveLoadEvents: () => dispatch(eventActions.resetMassiveLoadEvents()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(DentitionTable)));