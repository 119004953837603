import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import notificationActions from '../../redux/actions/notificationActions'
import NotificationTable from "./components/NotificationTable";
import CVModal from "../UI/Modals/components/CVModal";
import { withTranslation } from 'react-i18next';

const Notifications = (props) => {

    const [notifications, setNotifications] = useState();
    const [acceptModalIsOpen, setAcceptModalOpen] = useState(false);
    const [rejectModalIsOpen, setRejectModalOpen] = useState(false);
    const [detailsModalIsOpen, setDetailsModalOpen] = useState(false);
    const [selectedNotification, selectNotification] = useState();

    const { totalPages, pageSize, total, page } = props;

    useEffect(() => {
        props.getPage(1, 10);
    }, []);

    useEffect(() => {
        setNotifications(props.notifications);
    }, [props.notifications]);

    const findNotificationById = (id) => new Promise((resolve, reject) => {
        notifications.forEach(notification => {
            if(notification.id === id) resolve(notification)
        });
        reject();
    });

    const searchNotification = (word) => {
        props.search(word)
    };

    const filterNotification = (filters) => {
        props.filter(filters)
    };

    const { t } = props;

    const renderAcceptBody = () => (
        selectedNotification ?
            <div>
                {t('notifications.modals.accept.body', { sender: selectedNotification.sender,
                    role: t(`notifications.table.roles.${selectedNotification.role}`)})}
            </div>:
            null
    );

    const renderRejectBody = () => (
        selectedNotification ?
            <div>
                {t('notifications.modals.reject.body', { sender: selectedNotification.sender,
                    role: t(`notifications.table.roles.${selectedNotification.role}`)})}
            </div>:
            null
    );

    const renderDetailsBody = () => {
        let keysAndValues;
        if (selectedNotification) keysAndValues = Object.entries(selectedNotification.details);

        return (
            selectedNotification ?
                keysAndValues.map((pair) => {
                    return <div className={'notification-details-row'} key={pair[0]}>
                        <h4 className={"bold-text label"}>{t(`notifications.modals.details.keys.${pair[0]}`)}:</h4>
                        <h4 className={"value"}>{pair[1]}</h4>
                    </div>
                })
                :
                null
        )
    };

    const toggleAcceptModal = (id) => {
        setAcceptModalOpen(!acceptModalIsOpen);
        findNotificationById(id).then((notification) => selectNotification(notification))
    };

    const toggleRejectModal = (id) => {
        setRejectModalOpen(!rejectModalIsOpen);
        findNotificationById(id).then((notification) => selectNotification(notification))
    };

    const toggleDetailsModal = (id) => {
        setDetailsModalOpen(!detailsModalIsOpen);
        findNotificationById(id).then((notification) => selectNotification(notification))
    };

    const acceptModal = () => (
        <CVModal
            title={t('notifications.modals.accept.title')}
            body={() => renderAcceptBody()}
            color="success"
            header
            isOpen={acceptModalIsOpen}
            toggle={() => setAcceptModalOpen(!acceptModalIsOpen)}
            acceptFunc={() => {
                props.acceptRol(selectedNotification.id);
                setAcceptModalOpen(!acceptModalIsOpen)
            }}
        />
    );

    const rejectModal = () => (
        <CVModal
            title={t('notifications.modals.reject.title')}
            body={() => renderRejectBody()}
            color="danger"
            header
            isOpen={rejectModalIsOpen}
            toggle={() => setRejectModalOpen(!rejectModalIsOpen)}
            acceptFunc={() => {
                props.rejectRol(selectedNotification.id);
                setRejectModalOpen(!rejectModalIsOpen);
            }}
        />
    );

    const detailsModal = () => (
        <CVModal
            title={t('notifications.modals.details.title')}
            body={() => renderDetailsBody()}
            color="primary"
            header
            isOpen={detailsModalIsOpen}
            toggle={() => setDetailsModalOpen(!detailsModalIsOpen)}
            acceptFunc={() => setDetailsModalOpen(!detailsModalIsOpen)}
            hideButtons={true}
        />
    );

    return (
        <div>
            <NotificationTable
                notifications={notifications} total={total} currentPage={page} getPage={props.getPage}
                totalPages={totalPages} pageSize={pageSize}
                toggleAcceptModal={(id) => toggleAcceptModal(id)}
                toggleRejectModal={(id) => toggleRejectModal(id)}
                toggleDetailsModal={(id) => toggleDetailsModal(id)}
                search={searchNotification}
                filter={filterNotification}
            />
            {acceptModal()}
            {rejectModal()}
            {detailsModal()}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        notifications: state.notification.notifications,
        total: state.notification.total,
        page: state.notification.page,
        totalPages: state.notification.totalPages,
        pageSize: state.notification.pageSize
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPage: (page, pageSize) => dispatch(notificationActions.getPage(page, pageSize)),
        acceptRol: (notificationId) => dispatch(notificationActions.acceptRol(notificationId)),
        rejectRol: (notificationId) => dispatch(notificationActions.rejectRol(notificationId)),
        search: (word) => dispatch(notificationActions.search(word)),
        filter: (filters) => dispatch(notificationActions.filter(filters))
    }
};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Notifications));
