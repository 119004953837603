import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import actions from '../../../redux/actions/index';
import DownIcon from "mdi-react/ChevronDownIcon";
import {Collapse} from "reactstrap";
import {ButtonBase} from "@material-ui/core";
import {ROLES} from "../../../constants";

const TopbarCompany = (props) => {

    // if the user has a profesional role, it doesn't have companies
    if(props.roles.some(rol => (rol.name) === ROLES.AGRICULTURAL_ENGINEER )) return null;
    if(props.roles.some(rol => (rol.name) === ROLES.VETERINARY )) return null;

    const [collapse, setCollapse] = useState();
    const [selectedCompany, selectCompany] = useState();
    const [companies, setCompanies] = useState([]);

    const { allCompanies } = props;

    useEffect(() => {
        if(props.roles.some(rol => (rol.name) === ROLES.TITULAR )) props.getAllCompanies();
    }, []);

    useEffect(() => {
        if(props.roles.some(rol => (rol.name) === ROLES.SURROGATE )) props.getAssignedCompanies(props.userId);
    }, []);


    useEffect(() => {
        //  If there is at least one company set it on top bar.
        if(allCompanies && allCompanies.length > 0) {
            setCompanies(allCompanies);

            //  If there is a previous selected company, don't change it.
            if(props.selectedCompany) {
                selectCompany(props.selectedCompany);
                props.selectCompany(props.selectedCompany);
                props.getEstablishmentsByCompanyId(props.selectedCompany._id);
                //props.resetSelectedEstablishment();
            }
            //  If there is not a previous selected company, select the first one.
            else {
                selectCompany(allCompanies[0]);
                props.selectCompany(allCompanies[0]);
                props.getEstablishmentsByCompanyId(allCompanies[0]._id);
                //props.resetSelectedEstablishment();
            }
        }
        // refresh, this is need when the last company is deleted.
        if(allCompanies && allCompanies.length === 0) {
            setCompanies(null);
            selectCompany(null);
        }
    }, [allCompanies]);

    const toggle = () => setCollapse(!collapse);

    const changeCompany = (company) => {
        props.selectCompany(company);
        props.getEstablishmentsByCompanyId(company._id);
        //props.resetSelectedEstablishment();
        selectCompany(company);
        toggle();
    };

    if(companies && companies.length > 0) {
        return (
            <div className="topbar__profile">
                <button className="topbar__avatar" type="button" onClick={toggle}>
                    <span className={"topbar__link-icon lnr lnr-store"}/>
                    <p className="topbar__avatar-name">{selectedCompany.name}</p>
                    <DownIcon className="topbar__icon"/>
                </button>
                {
                    companies.length > 1 ?
                        <div>
                            {collapse && <button className="topbar__back" type="button" onClick={toggle}/>}
                            <Collapse isOpen={collapse} className="topbar__menu-wrap">
                                <div className="topbar__menu left-align">
                                    {/* todo uncomment this when 'all' companies is supported */}
                                    {/*<ButtonBase
                                        className="topbar__link d-flex justify-content-start"
                                        type="button"
                                        onClick={() => changeCompany({all: true, name: t('common.all')})}
                                    >
                                        <span className={`topbar__link-icon lnr lnr-store`}/>
                                        <p className="topbar__link-title">{t('common.all')}</p>
                                    </ButtonBase>*/}
                                    {
                                        companies.map((company) => (
                                            <ButtonBase
                                                className="topbar__link d-flex justify-content-start"
                                                type="button"
                                                onClick={() => changeCompany(company)}
                                                key={company._id}
                                            >
                                                <span className={`topbar__link-icon lnr lnr-store`}/>
                                                <p className="topbar__link-title">{company.name}</p>
                                            </ButtonBase>
                                        ))
                                    }
                                </div>
                            </Collapse>
                        </div> :
                        null
                }
            </div>
        );
    }

    return null
};

const mapStateToProps = (state) => ({
    allCompanies: state.user.allCompanies,
    selectedCompany: state.user.selectedCompany,
    roles: state.user.roles,
    userId: state.user.id

});
const mapDispatchToProps = (dispatch) => ({
    getAllCompanies: () => dispatch(actions.user.getAllUserCompanies()),
    getAssignedCompanies: (userId) => dispatch(actions.user.getAssignedCompanies(userId)),
    selectCompany: (company) => dispatch(actions.user.selectCompany(company)),
    getEstablishmentsByCompanyId: (companyId) => dispatch(actions.user.getUserEstablishmentsByCompany(companyId)),
    resetSelectedEstablishment: () => dispatch(actions.user.resetSelectedEstablishment())
});

export default connect(mapStateToProps, mapDispatchToProps)(TopbarCompany);