import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { Field, reduxForm, change, formValueSelector } from 'redux-form'
import {withTranslation} from "react-i18next";
import {Button, ButtonToolbar} from "reactstrap";
import { renderCVPhotoInputField } from '../../../../shared/components/cv/CVFileUpload';
import renderSelectField from "../../../../shared/components/form/Select";
import AddressInput from "../../../../shared/components/cv/AddressInput";
import { formValidators } from "../../../../util";
import { connect } from "react-redux";
import CVButton from '../../../../shared/components/cv/CVButton';
import RucaInput from "./RucaInput";
import renderField from '../../../../shared/components/cv/Field';
import CoordinatesInput from "../../../../shared/components/cv/CoordinatesInput";
import {CheckBoxField} from "../../../../shared/components/form/CheckBox";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import {SRLWrapper} from "simple-react-lightbox";

import {ERRORS} from "../../../../constants";


const optionsLightbox = {
    settings: {},
    caption: {
        showCaption: false,
    },
    buttons: {
        showAutoplayButton: false,
        showNextButton: false,
        showPrevButton: false,
        showThumbnailsButton: false,
        showDownloadButton: false,
        showFullscreenButton: false,
    },
    thumbnails: {
        showThumbnails: false,
    },
    progressBar:{},    
  }

const SlaughterEstablishmentForm = (props) => {
    SlaughterEstablishmentForm.propTypes = {
        t: PropTypes.func.isRequired,
        submitEstablishmentForm: PropTypes.func.isRequired,
        previousPage: PropTypes.func.isRequired,
        pending: PropTypes.bool.isRequired
    };

    const { submitEstablishmentForm, handleSubmit, previousPage, t,
        editable, showBackButton, submitButtonText, showCompanySelection,
        showImages, establishmentType, initialValues } = props;

    const availableApts = ["none", "hilton", "china", "others"];

    const [addressesToAutocomplete, setAddressesToAutocomplete] = useState({
        names: []
    });

    const [aptSelected, setAptSelected] = useState({
        none: false,
        hilton: false,
        china: false,
        others: false,
    });

    useEffect(() => {
        if(props.initialValues.apts && !editable) {
            setAptSelected(props.initialValues.apts)
        }
    }, [props.initialValues]);

    const showErrors = (invalid) => {
        if(invalid) ToastsStore.error(t('common.general_invalid_form'), 10000)
    }

    const selectApt = (apt) => {
        const newValue = {
            ...aptSelected
        };

        newValue[`${apt}`] = !newValue[`${apt}`];
        if(apt==="none"){
          newValue.china = false;
          newValue.hilton = false;
          newValue.others = false;
        }
        else{
          newValue.none = false
        }
        setAptSelected(newValue);
    };

    const noneAptsSelected = (apts) => {
        if(apts.none) return false;
        if(apts.hilton) return false;
        if(apts.china) return false;
        if(apts.others) return false;
        return true;
    };

    const [addressNameToCopyFrom, setAddressNameToCopyFrom] = useState();

    useEffect(() => {
    }, [props.initialValues]);

    useEffect(() => {
        if(props.addressesToAutocomplete){
            setAddressesToAutocomplete(props.addressesToAutocomplete);
        }
    }, [props.addressesToAutocomplete]);

    useEffect(() => {
        //  If the select of company is shown, select the first available company by default.
        if (showCompanySelection) {
            // Set the commercial and legal address of the selected company as possible addresses to autocomplete with.
            setAddressesToAutocomplete({
                names: ['commercial', 'personal'],
                commercial: props.selectedCompany.addresses.commercial,
                personal: props.personalAddress
            });
        }
    }, [props.selectedCompany]);

    const submitForm = (values) => {
        if(noneAptsSelected(aptSelected)) return;
        submitEstablishmentForm({...values, apts: aptSelected});
    };

    // This method fill the establishment address with an address given on 'addressesToAutocomplete' prop.
    const fillEstablishmentAddressWith = (addressName) => {
        setAddressNameToCopyFrom(
            {
                value: addressName,
                label: t(`cv-forms.productive_establishment.autocomplete_address.${addressName}`)
            }
        );
        let addressToAutocomplete = addressesToAutocomplete[`${addressName}`];
        //  Fill the establishment address.
        props.changeFieldValue(`establishmentAddress.streetName`, addressToAutocomplete.streetName);
        props.changeFieldValue(`establishmentAddress.streetNumber`, addressToAutocomplete.streetNumber);
        props.changeFieldValue(`establishmentAddress.apartment`, addressToAutocomplete.apartment);
        props.changeFieldValue(`establishmentAddress.postalCode`, addressToAutocomplete.postalCode);
        props.changeFieldValue(`establishmentAddress.city`, addressToAutocomplete.city);
        props.changeFieldValue(`establishmentAddress.state`, addressToAutocomplete.state);
        props.changeFieldValue(`establishmentAddress.country`, addressToAutocomplete.country);
    };

    
    const renderImageRuca = (imageName) => {
        // An image was selected and preview is needed
        if(typeof (props.formImages[`${imageName}`]) === 'object' && !Array.isArray(props.formImages[`${imageName}`])) {
            return <SRLWrapper options={optionsLightbox}><img style={{cursor:"pointer"}} src={URL.createObjectURL(props.formImages[`${imageName}`].files[0])}/></SRLWrapper>;
        }
        // An image (or images) was previously upload and need to be shown
        else if((typeof (props.formImages[`${imageName}`]) === 'string') ||
            (Array.isArray(props.formImages[`${imageName}`]) && props.formImages[`${imageName}`].length > 0)) {
            return <SRLWrapper options={optionsLightbox}><img style={{cursor:"pointer"}} src={props.formImages[`${imageName}`]}/></SRLWrapper>;
        }
        // No image was selected and no image was upload
        else {
            return <label>{t('cv-forms.productive_establishment.no_image')}</label>;
        }
    };
    
    const renderImage = (imageName) => {
        // An image was selected and preview is needed
        if(typeof (props.formImages[`${imageName}`]) === 'object' && !Array.isArray(props.formImages[`${imageName}`]) && props.formImages[`${imageName}`]!=null) {            
            return (
            <div>
                <SRLWrapper options={optionsLightbox}>                                                        
                <img style={{cursor:"pointer"}} src={URL.createObjectURL(props.formImages[`${imageName}`].files[0])}/>
                </SRLWrapper>
                <br/>
                {
                    (editable) && (
                    <a onClick={()=>{deleteFile(`${imageName}`)}}><button>Eliminar</button></a>
                    )
                }
                
            </div>
            );
        }
        // An image (or images) was previously upload and need to be shown
        else if((typeof (initialValues[`${imageName}`]) === 'string' && initialValues[`${imageName}`] != '') ||
            (Array.isArray(initialValues[`${imageName}`]) && initialValues[`${imageName}`].length > 0)) {            
            return (
            <div>
                <SRLWrapper options={optionsLightbox}>                                                        
                <img style={{cursor:"pointer"}} src={initialValues[`${imageName}`]}/>
                </SRLWrapper>
                <br/>
                {
                    (editable) && (
                    <a onClick={()=>{deleteFile(`${imageName}`)}}><button>Eliminar</button></a>
                    )
                }                
            </div>                                       
            );
        }
        // No image was selected and no image was upload
        else {
            return <label>{t('cv-forms.productive_establishment.no_image')}</label>;
        }
    };

    const deleteFile = (field) => {        
        console.log("debug",initialValues);
        props.dispatch(change('slaughter_establishment_form',field,null))                                                
        delete props.initialValues[`${field}`];        
        //props.formImages[`${field}`]=null;
        delete initialValues[`${field}`];
    }


    const validateEmail = value => !value || (value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) ? undefined : ERRORS.invalidEmail

    return (
        <form className="form" onSubmit={handleSubmit(submitForm)}>
          <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>

          <div className="edit-buttons" >
                <ButtonToolbar className="form__button-toolbar" >
                        { !editable && <Button 
                            color="primary" 
                            outline
                            type="button" 
                            onClick={() => props.setEditable(!editable)}>
                            {t('common.edit')}</Button>
                        }
                        { editable && <CVButton
                            outline 
                            color="success"
                            type="submit"
                            onClick={() => showErrors(props.invalid)}
                            pending={props.pending}
                            >{t('common.save')}</CVButton>
                        }
                        { editable && <Button 
                            type="button"
                            outline
                            color="danger"
                            size="sm"
                            onClick={() => {
                                if(props.isNew){
                                    props.history.push('/manage_establishments/table');
                                }else{
                                props.setEditable(!editable)
                                }
                                }
                                }>
                        {t('common.cancel')}</Button>
                 
                        }
                        { !editable && <Button 
                            color="primary" 
                            outline={true} 
                            type="button" 
                            onClick={() => props.history.goBack()}>
                            {t('common.back')}</Button>
                        }
                </ButtonToolbar>
            </div>

            {showCompanySelection &&
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.company_to_add_establishment')}</span>
                <div className="form__form-group-field">
                    <Field
                        name="company_to_add_establishment"
                        component={renderSelectField}
                        type="text"
                        placeholder={t('cv-forms.productive_establishment.company_to_add_establishment')}
                        validate={formValidators.validateRequired}
                        disabled={true}
                        onChange={() => {}}
                        options={[]}
                    />
                </div>
            </div>
            }

            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.establishment_name')}</span>
                <div className="form__form-group-field">
                    <Field
                        name="establishmentName"
                        component={renderField}
                        type="text"
                        validate={formValidators.validateRequired}
                        disabled={!editable}
                        placeholder={t('cv-forms.productive_establishment.establishment_name')}
                    />
                </div>
            </div>
            <RucaInput 
              editable={editable}
              renderImage={renderImageRuca}
              classname={'ruca'}
              showImages={showImages}
              establishmentType = {establishmentType}
            />
            <div className="form__form-group">
            <span className="form__form-group-label">{t('cv-forms.productive_establishment.email')}</span>
            <div className="form__form-group-field">
              <Field
                name="email"
                normalize={(value) => value.toLowerCase()}
                normalize={(value) => value.toLowerCase()}
                component={renderField}
                validate={validateEmail}
                type="email"
                placeholder={t('cv-forms.productive_establishment.email')}
              />
            </div>
          </div>
          <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.linkedin')}</span>
                <div className="form__form-group-field">
                    <Field
                        name="linkedin"
                        component={renderField}
                        type="text"
                        disabled={!editable}
                        placeholder={t('cv-forms.productive_establishment.linkedin')}
                    />
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.webSite')}</span>
                <div className="form__form-group-field">
                    <Field
                        name="webSite"
                        component={renderField}
                        type="text"
                        disabled={!editable}
                        placeholder={t('cv-forms.productive_establishment.webSite')}
                    />
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.logotype')}</span>
                <div className="form__form-group-field with_image">
                    {
                        (!showImages || editable) &&
                        <Field
                            name="logotypePhoto"
                            component={renderCVPhotoInputField}
                            disabled={!editable}
                            hideFileName={true}
                        />
                    }
                    {renderImage('logotypePhoto')}
                </div>
            </div>
            <AddressInput title={t('cv-forms.productive_establishment.establishment_address')}
                          addressName={'establishmentAddress'} renderInput={renderField} editable={editable}
                          showAutoComplete={true} onAutocompleteChange={(address) => fillEstablishmentAddressWith(address.value)}
                          autocompleteValue={addressNameToCopyFrom} addressesToAutocomplete={addressesToAutocomplete}
            />
            <CoordinatesInput editable={editable}/>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.productive_establishment.video_link_optional')}</span>
                <div className="form__form-group-field">
                    <Field
                        name="videoLink"
                        component={renderField}
                        type="text"
                        validate={formValidators.validateUrl}
                        disabled={!editable}
                        placeholder={t('cv-forms.productive_establishment.video_link_placeholder')}
                    />
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">{t('cv-forms.apts')}</span>
                <div className="form__form-group-field">
                    {
                        availableApts.map((apt, index) => (
                          <div style={{marginLeft: index === 0 ? '0px' : '20px'}}>
                              <CheckBoxField
                                onChange={() => selectApt(apt)}
                                value={aptSelected[`${apt}`]}
                                name={`apts.${apt}`}
                                label={t(`cv-forms.apt_options.${apt}`)}
                                disabled={!editable}
                              />
                          </div>
                        ))
                    }
                </div>
                {
                    noneAptsSelected(aptSelected) &&
                    <div className="input-error-container">
                                        <span className="error-message">
                                            {props.t(`errors.REQUIRED`)}
                                        </span>
                    </div>
                }
            </div>

            <div className="edit-buttons" >
                <ButtonToolbar className="form__button-toolbar" >
                        { !editable && <Button 
                            color="primary" 
                            outline
                            type="button" 
                            onClick={() => props.setEditable(!editable)}>
                            {t('common.edit')}</Button>
                        }
                        { editable && <CVButton
                            outline 
                            color="success"
                            type="submit"
                            onClick={() => showErrors(props.invalid)}
                            pending={props.pending}
                            >{t('common.save')}</CVButton>
                        }
                        { editable && <Button 
                            type="button"
                            outline
                            color="danger"
                            size="sm"
                            onClick={() => {
                                if(props.isNew){
                                    props.history.push('/manage_establishments/table');
                                }else{
                                props.setEditable(!editable)
                                }
                                }
                                }>
                        {t('common.cancel')}</Button>
                    }
                        { !editable && <Button 
                            color="primary" 
                            outline={true} 
                            type="button" 
                            onClick={() => props.history.goBack()}>
                            {t('common.back')}</Button>
                        }
                </ButtonToolbar>
            </div>
        </form>
    )
};

const selector = formValueSelector('slaughter_establishment_form');

// This is hard-code because the default value of the select is not working properly.
const mapStateToProps = (state, props) => ({
    initialValues: props.initialValues ||
        {
            establishmentAddress: {
                country:{
                    value: 'ARGENTINA',
                    label: "Argentina"
                }
            },
            apts: {
                hilton: false,
                china: false,
                others: true,
            },
            company_to_add_establishment: {
                value: props.selectedCompany ? props.selectedCompany._id : "",
                label: props.selectedCompany ? props.selectedCompany.name : ""
            }
        },
    personalAddress: state.user.profile.homeAddress,
    formImages: {
        enrollmentImage: selector(state, 'enrollmentImage'),
        rucaImage: selector(state, 'ruca.image'),
        logotypePhoto: selector(state, 'logotypePhoto')
    }
});

const mapDispatchToProps = (dispatch) => ({
    changeFieldValue: (field, value) => dispatch(change('slaughter_establishment_form', field, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'slaughter_establishment_form'
})(withTranslation('common')(SlaughterEstablishmentForm)))