export const GET_ANIMAL_PAGE = 'GET_ANIMAL_PAGE';
export const GET_ANIMAL_PAGE_FOR_DOWNLOAD = 'GET_ANIMAL_PAGE_FOR_DOWLOAD';
export const GET_ANIMAL_PAGE_FOR_DOWNLOAD_RESPONSE = 'GET_ANIMAL_PAGE_FOR_DOWNLOAD_RESPONSE';
export const GET_ANIMAL_PAGE_FOR_DOWNLOAD_ERROR = 'GET_ANIMAL_PAGE_FOR_DOWNLOAD_ERROR';
export const GET_ANIMAL_PAGE_RESPONSE = 'GET_ANIMAL_PAGE_RESPONSE';
export const GET_ANIMAL_PAGE_ERROR = 'GET_ANIMAL_PAGE_ERROR';
export const SEARCH_ANIMALS = 'SEARCH_ANIMALS';
export const SEARCH_ANIMALS_RESPONSE = 'SEARCH_ANIMALS_RESPONSE';
export const SEARCH_ANIMALS_ERROR = 'SEARCH_ANIMALS_ERROR';
export const FILTER_ANIMALS = 'FILTER_ANIMALS';
export const FILTER_ANIMALS_RESPONSE = 'FILTER_ANIMALS_RESPONSE';
export const FILTER_ANIMALS_ERROR = 'FILTER_ANIMALS_ERROR';
export const UPLOAD_FILE_FOR_MASSIVE_ANIMALS_LOAD = 'UPLOAD_FILE_FOR_MASSIVE_ANIMALS_LOAD';
export const UPLOAD_FILE_FOR_MASSIVE_ANIMALS_LOAD_RESPONSE = 'UPLOAD_FILE_FOR_MASSIVE_ANIMALS_LOAD_RESPONSE';
export const UPLOAD_FILE_FOR_MASSIVE_ANIMALS_LOAD_ERROR = 'UPLOAD_FILE_FOR_MASSIVE_ANIMALS_LOAD_ERROR';
export const ACCEPT_MASSIVE_ANIMALS_UPLOAD = 'ACCEPT_MASSIVE_ANIMALS_UPLOAD';
export const ACCEPT_MASSIVE_ANIMALS_UPLOAD_RESPONSE = 'ACCEPT_MASSIVE_ANIMALS_UPLOAD_RESPONSE';
export const ACCEPT_MASSIVE_ANIMALS_UPLOAD_ERROR = 'ACCEPT_MASSIVE_ANIMALS_UPLOAD_ERROR';
export const ADD_COW = 'ADD_COW';
export const ADD_COW_RESPONSE = 'ADD_COW_RESPONSE';
export const ADD_COW_ERROR = 'ADD_COW_ERROR';
export const GET_USER_ANIMAL_BY_ID = 'GET_USER_ANIMAL_BY_ID';
export const GET_USER_ANIMAL_BY_ID_RESPONSE = 'GET_USER_ANIMAL_BY_ID_RESPONSE';
export const GET_USER_ANIMAL_BY_ID_ERROR = 'GET_USER_ANIMAL_BY_ID_ERROR';
export const RESET_ANIMAL_DETAILS = 'RESET_ANIMAL_DETAILS';
export const RESET_MASSIVE_LOAD_ANIMALS = 'RESET_MASSIVE_LOAD_ANIMALS';
export const UPLOAD_COW_FILES = 'UPLOAD_COW_FILES';
export const EDIT_COW = 'EDIT_COW';
export const EDIT_COW_RESPONSE = 'EDIT_COW_RESPONSE';
export const EDIT_COW_ERROR = 'EDIT_COW_ERROR';
export const GET_ANIMAL_PAGE_BY_ESTABLISHMENT = 'GET_ANIMAL_PAGE_BY_ESTABLISHMENT';
export const GET_ANIMAL_PAGE_BY_ESTABLISHMENT_RESPONSE = 'GET_ANIMAL_PAGE_BY_ESTABLISHMENT_RESPONSE';
export const GET_ANIMAL_PAGE_BY_ESTABLISHMENT_ERROR = 'GET_ANIMAL_PAGE_BY_ESTABLISHMENT_ERROR';
export const GET_FACILITY_ANIMAL_TO_EXCEL = 'GET_FACILITY_ANIMAL_TO_EXCEL';
export const GET_FACILITY_ANIMAL_TO_EXCEL_RESPONSE = 'GET_FACILITY_ANIMAL_TO_EXCEL_RESPONSE';
export const GET_FACILITY_ANIMAL_TO_EXCEL_ERROR = 'GET_FACILITY_ANIMAL_TO_EXCEL_ERROR';
export const GET_GENEALOGY = 'GET_GENEALOGY';
export const GET_GENEALOGY_RESPONSE = 'GET_GENEALOGY_RESPONSE';
export const GET_GENEALOGY_ERROR = 'GET_GENEALOGY_ERROR';
export const GET_ANIMALS_HISTORY_BY_LOT = 'GET_ANIMALS_HISTORY_BY_LOT';
export const GET_ANIMALS_HISTORY_BY_LOT_RESPONSE = 'GET_ANIMALS_HISTORY_BY_LOT_RESPONSE';
export const GET_ANIMALS_HISTORY_BY_LOT_ERROR = 'GET_ANIMALS_HISTORY_BY_LOT_ERROR';
export const GET_VALIDATE_STOCK_PRODUCTIVE_SYSTEM_TYPE = 'GET_VALIDATE_STOCK_PRODUCTIVE_SYSTEM_TYPE';
export const GET_VALIDATE_STOCK_PRODUCTIVE_SYSTEM_TYPE_RESPONSE = 'GET_VALIDATE_STOCK_PRODUCTIVE_SYSTEM_TYPE_RESPONSE';
export const GET_VALIDATE_STOCK_PRODUCTIVE_SYSTEM_TYPE_ERROR = 'GET_VALIDATE_STOCK_PRODUCTIVE_SYSTEM_TYPE_ERROR';

const animalActions = {
    getPage(page, pageSize){
        return {
            type: GET_ANIMAL_PAGE,
            page,
            pageSize
        }
    },
    getPageResponse(response){
        return {
            type: GET_ANIMAL_PAGE_RESPONSE,
            response
        }
    },
    getPageError(message){
        return {
            type: GET_ANIMAL_PAGE_ERROR,
            message
        }
    },
    search(word){
        return {
            type: SEARCH_ANIMALS,
            word
        }
    },
    searchResponse(response){
        return {
            type: SEARCH_ANIMALS_RESPONSE,
            response
        }
    },
    searchError(message){
        return {
            type: SEARCH_ANIMALS_ERROR,
            message
        }
    },
    filter(filters){
        return {
            type: FILTER_ANIMALS,
            filters
        }
    },
    filterResponse(response){
        return {
            type: FILTER_ANIMALS_RESPONSE,
            response
        }
    },
    filterError(message){
        return {
            type: FILTER_ANIMALS_ERROR,
            message
        }
    },
    uploadFileForBulkLoad(file, establishmentId, successCallback, errorCallback){
        return {
            type: UPLOAD_FILE_FOR_MASSIVE_ANIMALS_LOAD,
            file,
            establishmentId,
            successCallback,
            errorCallback
        }
    },
    uploadFileForBulkLoadResponse(response){
        return {
            type: UPLOAD_FILE_FOR_MASSIVE_ANIMALS_LOAD_RESPONSE,
            response
        }
    },
    uploadFileForBulkLoadError(message){
        return {
            type: UPLOAD_FILE_FOR_MASSIVE_ANIMALS_LOAD_ERROR,
            message
        }
    },
    acceptMassiveAnimalsUpload(excelId, successCallback, errorCallback){
        return {
            type: ACCEPT_MASSIVE_ANIMALS_UPLOAD,
            excelId,
            successCallback,
            errorCallback
        }
    },
    acceptMassiveAnimalsUploadResponse() {
        return {
            type: ACCEPT_MASSIVE_ANIMALS_UPLOAD_RESPONSE,
        }
    },
    acceptMassiveAnimalsUploadError(message){
        return {
            type: ACCEPT_MASSIVE_ANIMALS_UPLOAD_ERROR,
            message
        }
    },
    addCow(data, successCallback, errorCallback) {
        return {
            type: ADD_COW,
            data,
            successCallback,
            errorCallback
        }
    },
    addCowResponse(response) {
        return {
            type: ADD_COW_RESPONSE,
            response
        }
    },
    addCowError(message) {
        return {
            type: ADD_COW_ERROR,
            message
        }
    },
    getById(animalId) {
        return {
            type: GET_USER_ANIMAL_BY_ID,
            animalId
        }
    },
    getByIdResponse(response) {
        return {
            type: GET_USER_ANIMAL_BY_ID_RESPONSE,
            response
        }
    },
    getByIdError(message) {
        return {
            type: GET_USER_ANIMAL_BY_ID_ERROR,
            message
        }
    },
    resetDetails() {
        return {
            type: RESET_ANIMAL_DETAILS
        }
    },
    resetMassiveLoadAnimals() {
        return {
            type: RESET_MASSIVE_LOAD_ANIMALS
        }
    },
    uploadCowFiles(files, callback) {
        return {
            type: UPLOAD_COW_FILES,
            files,
            callback
        }
    },
    editCow(animalId, data, successCallback, errorCallback) {
        return {
            type: EDIT_COW,
            animalId,
            data,
            successCallback,
            errorCallback
        }
    },
    editCowResponse(response) {
        return {
            type: EDIT_COW_RESPONSE,
            response
        }
    },
    editCowError(message) {
        return {
            type: EDIT_COW_ERROR,
            message
        }
    },
    getPageByEstablishment(establishmentId, lotId, page, pageSize) {
        return {
            type: GET_ANIMAL_PAGE_BY_ESTABLISHMENT,
            establishmentId,
            lotId,
            page,
            pageSize
        }
    },
    getPageByEstablishmentResponse(response) {
        return {
            type: GET_ANIMAL_PAGE_BY_ESTABLISHMENT_RESPONSE,
            response
        }
    },
    getPageByEstablishmentError(message) {
        return {
            type: GET_ANIMAL_PAGE_BY_ESTABLISHMENT_ERROR,
            message
        }
    },
    getPageForDownload(establishmentId, lotId, page, pageSize) {
        return {
            type: GET_ANIMAL_PAGE_FOR_DOWNLOAD,
            establishmentId,
            lotId,
            page,
            pageSize
        }
    },
    getAnimalPageForDownloadResponse(response) {
        return {
            type: GET_ANIMAL_PAGE_FOR_DOWNLOAD_RESPONSE,
            response
        }
    },
    getAnimalPageForDownloadError(message) {
        return {
            type: GET_ANIMAL_PAGE_FOR_DOWNLOAD_ERROR,
            message
        }
    },
    getFacilityAnimalsToExcel(establishmentId, lotId) {
        return {
            type: GET_FACILITY_ANIMAL_TO_EXCEL,
            establishmentId,lotId
        }
    },
    getFacilityAnimalsToExcelResponse(response) {
        return {
            type: GET_FACILITY_ANIMAL_TO_EXCEL_RESPONSE,
            response
        }
    },
    getFacilityAnimalsToExcelError(message) {
        return {
            type: GET_FACILITY_ANIMAL_TO_EXCEL_ERROR,
            message
        }
    },    

    getGenealogy(data,successCallback, errorCallback){
        return {
            type: GET_GENEALOGY,
            data,successCallback, errorCallback
        }
    },
    getGenealogyResponse(response){
        return {
            type: GET_GENEALOGY_RESPONSE,
            response
        }
    },
    getGenealogyError(message){
        return {
            type: GET_GENEALOGY_ERROR,
            message
        }
    },
    getAnimalsHistoryByLot(lotId,facilityId) {
        return {
            type: GET_ANIMALS_HISTORY_BY_LOT,
            lotId,
            facilityId
        }
    },
    getAnimalsHistoryByLotResponse(response) {
        return {
            type: GET_ANIMALS_HISTORY_BY_LOT_RESPONSE,
            response
        }
    },
    getAnimalsHistoryByLotError(message) {
        return {
            type: GET_ANIMALS_HISTORY_BY_LOT_ERROR,
            message
        }
    },

    getValidateStockProductiveSystemType(data,successCallback, errorCallback){
        return {
            type: GET_VALIDATE_STOCK_PRODUCTIVE_SYSTEM_TYPE,
            data,successCallback, errorCallback
        }
    },
    getValidateStockProductiveSystemTypeResponse(response){
        return {
            type: GET_VALIDATE_STOCK_PRODUCTIVE_SYSTEM_TYPE_RESPONSE,
            response
        }
    },
    getValidateStockProductiveSystemTypeError(message){
        return {
            type: GET_VALIDATE_STOCK_PRODUCTIVE_SYSTEM_TYPE_ERROR,
            message
        }
    },

    


};
export default animalActions;