import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import PropTypes from 'prop-types';

const es = `${process.env.PUBLIC_URL}/img/language/es-circle.png`;
const en = `${process.env.PUBLIC_URL}/img/language/en-circle.png`;

const EsLng = () => (
    <span className="topbar__language-btn-title">
    <img src={es} alt="es" />
    <span>ES</span>
  </span>
);

const EnLng = () => (
    <span className="topbar__language-btn-title">
    <img src={en} alt="en" />
    <span>EN</span>
  </span>
);

class TopbarLanguage extends PureComponent {
  static propTypes = {
    i18n: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
      mainButtonContent: <EsLng/>,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  changeLanguage = (lng) => {
    const { i18n } = this.props;
    i18n.changeLanguage(lng);
    switch (lng) {
      case 'es':
        this.setState({mainButtonContent: <EsLng/> });
        break;
      case 'en':
        this.setState({ mainButtonContent: <EnLng /> });
        break;
      default:
        this.setState({ mainButtonContent: <EsLng /> });
        break;
    }
  };

  render() {
    const { mainButtonContent, collapse } = this.state;

    return (
      <div className="topbar__collapse topbar__collapse--language">
        <button className="topbar__btn" type="button" onClick={this.toggle}>
          {mainButtonContent}
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
        <Collapse
          isOpen={collapse}
          className="topbar__collapse-content topbar__collapse-content--language"
        >
          <button
              className="topbar__language-btn"
              type="button"
              onClick={() => this.changeLanguage('es')}
          >
            <EsLng />
          </button>
          {/*<button
            className="topbar__language-btn"
            type="button"
            onClick={() => this.changeLanguage('en')}
          >
            <EnLng />
          </button>*/}
        </Collapse>
      </div>
    );
  }
}

export default withTranslation('common')(TopbarLanguage);
