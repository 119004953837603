import React, { useState }  from 'react';
import { withTranslation } from 'react-i18next';
import {Field, reduxForm} from "redux-form";
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import {Button} from "reactstrap";

const styles = {
    formContainer: {
        marginLeft: '15px',
        marginRight: '15px'
    },
    filterTypeLabel: {
        marginBottom: '10px'
    },
    filterButton: {
        marginLeft: '15px',
        marginRight: '15px'
    }
};

const Filter = (props) => {

    /*  'filter's props example */
    /*
    * [
    *   {
    *       filterId: 'byRole',
    *       label: 'Role', //Could be i18n id
    *       values: [
    *           {
    *               id: 'VETERINARY',
    *               label: 'Vet' //Could be i18n id
    *           },
    *           {
    *               id: 'TITULAR',
    *               label: 'Titular' //Could be i18n id
    *           }
    *       ]
    *   }
    * ]
    * */

    const { t, handleSubmit } = props;

    return (
        <div>
            {
                props.filters.length !== 0 &&
                <form className="form form--preview table__filter" onSubmit={handleSubmit}>
                    {
                        props.filters.map(filter => {
                            return (
                                <div style={styles.formContainer}>
                                    <h4 style={styles.filterTypeLabel}>{t(`${filter.group}.filters.labels.${filter.label}`)}</h4>
                                    {
                                        filter.values.map(value => {
                                            return (
                                                <div className="form__form-group">
                                                    <div className="form__form-group-field">
                                                        <Field
                                                            name={value.id}
                                                            component={renderCheckBoxField}
                                                            label={t(`${filter.group}.filters.labels.${value.label}`)}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                    <Button style={styles.filterButton} outline type="submit">{t('cv-table.filter-button-text')}</Button>
                </form>
            }
        </div>
    )
};
export default reduxForm({
    form: 'filter-form',
})(withTranslation('common')(Filter));