import React, { PureComponent } from 'react';
import PropTypes  from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import { RTLProps } from '../../prop-types/ReducerProps';
import FileUploadDefault from './FileUploadDefault';
import CloseIcon from "mdi-react/CloseIcon"

class FileUploadModal extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        rtl: RTLProps.isRequired,
        onSubmit: PropTypes.func,
        isOpen: PropTypes.bool,
        toggleModal: PropTypes.func,
        acceptedFormats: PropTypes.string
    };

    static defaultProps = {
        title: ''
    };

    render() {
        const { title, rtl, onSubmit, isOpen, toggleModal, acceptedFormats } = this.props;

        if(!isOpen) return null;

        return (
            <Modal
                isOpen={isOpen}
                toggle={toggleModal}
                modalClassName={`${rtl.direction}-support`}
                className={`modal-dialog`}
            >
                <div className="modal__header">
                    <h4 className="text-modal  modal__title">{title}</h4>
                </div>
                <CloseIcon
                    className={"lnr lnr-cross modal__close-btn"}
                    onClick={() => toggleModal(false)}
                  />
                <div className="modal__body">
                    <FileUploadDefault onSubmit={onSubmit} acceptedFormats={acceptedFormats}/>
                </div>
            </Modal>
        );
    }
}

export default connect(state => ({
    rtl: state.rtl,
}))(FileUploadModal);
