/*
*   The animals mapper is use for mapping either the body or the response on a animal request. It try to decouple the
*   animals middleware.
* */

import i18n from "../../../translations/config/i18n";

const mapper =  {    
    response: {
        getBI: (response) => ({
            animalsByUserCount: response.response.animalsByUserCount,
            animalsDeletionByUserCount: response.response.animalsDeletionByUserCount,            
            animalsForBreedByUserCount: mapKeyValueAggregation(response.response.animalsForBreedByUserCount,{keyFrom:'_id',valueFrom:'count'},{keyTo:'name',valueTo:'value'}),
            animalsForCategoryByUserCount: mapKeyValueAggregation(response.response.animalsForCategoryByUserCount,{keyFrom:'_id',valueFrom:'count'},{keyTo:'name',valueTo:'value'}),
            animalsForFacilityByUserCount: mapKeyValueAggregation(response.response.animalsForFacilityByUserCount,{keyFrom:'_id',valueFrom:'count'},{keyTo:'name',valueTo:'value'}),
            animalsForWeightRangeyByUserCount: mapKeyValueAggregation(response.response.animalsForWeightRangeyByUserCount,{keyFrom:'_id',valueFrom:'count'},{keyTo:'name',valueTo:'value'}),
            animalsTransferByUserCount: response.response.animalsTransferByUserCount,
            animalsWeightAverageForCategoryByUserCount: mapKeyValueAggregation(response.response.animalsWeightAverageForCategoryByUserCount,{keyFrom:'_id',valueFrom:'avgWeight'},{keyTo:'name',valueTo:'value'}),
            animalsWeightAverageForProductionSystemByUserCount: mapKeyValueAggregation(response.response.animalsWeightAverageForProductionSystemByUserCount,{keyFrom:'_id',valueFrom:'avgWeight'},{keyTo:'name',valueTo:'value'}),
            companiesAsDelegateByUserCount: response.response.companiesAsDelegateByUserCount,
            companyDelegateByUserCount: response.response.companyDelegateByUserCount,
            facilityDelegateByUserCount: response.response.facilityDelegateByUserCount,
            faciltiesByUserCount: response.response.faciltiesByUserCount,
            companiesByUserCount: response.response.companiesByUserCount,
            animalsCreatedByUserCount: response.response.animalsCreatedByUserCount,
            animalsActiveByUserCount: response.response.animalsActiveByUserCount,
        }),        
    },    
};

const mapKeyValueAggregation = (data, from,to) => {
    let results = [];
    data.forEach(row => {
        let rowReturn = {};
        rowReturn[to.keyTo]=row[from.keyFrom];
        rowReturn[to.valueTo]=row[from.valueFrom];
        results.push(rowReturn);                    
    });    
    return results;
}

export default mapper;