import React, {PureComponent} from 'react';
import {withTranslation} from 'react-i18next';
import {Field, reduxForm} from 'redux-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';
import { formValidators } from '../../../../util/index';

class ForgotPasswordForm extends PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };

    renderField = ({input, placeholder, type, meta: {touched, error, warning}}) => (
        <div style={{width: '100%'}}>
            <input style={{backgroundColor: 'white'}} {...input} placeholder={placeholder} type={type}/>
            <div className="input-error-container">
                {touched && ((error && <span className="error-message">{this.props.t(`errors.${error}`)}</span>) || (warning &&
                    <span className="error-message">{warning}</span>))}
            </div>
        </div>
    );

    render() {
        const {handleSubmit, t} = this.props;

        return (
            <form className="form" onSubmit={handleSubmit}>
                <div className="form__form-group">
                    <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                            <AccountOutlineIcon/>
                        </div>
                        <Field
                            name="email"
                            component={this.renderField}
                            validate={[ formValidators.validateRequired, formValidators.validateEmail ]}
                            type="text"
                            normalize={(value) => value.toLowerCase()}
                            placeholder={t('login.email')}
                        />
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'flex-end', flex: 1}}>
                    <Button style={{width: 100}} className="btn btn-primary account__btn" type="submit">{t('common.send')}</Button>
                </div>
            </form>
        );
    }
}

export default withTranslation('common')(reduxForm({
    form: 'forgot_password_form', // a unique identifier for this form
})(ForgotPasswordForm));
