import {ERRORS} from "../constants";

export const validateEmail = value => value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
export const validateRENSPA = value => value && /^[a-zA-Z0-9]{2}.[a-zA-Z0-9]{3}.[a-zA-Z0-9].[a-zA-Z0-9]{5}\/[a-zA-Z0-9]{2}$/.test(value);
export const validateCaravanId = value => value && /^[a-zA-Z]{2}[0-9]{3}-[a-zA-Z][a-zA-Z0-9]{2}[0-9]$/.test(value);
export const validateCaravanIdR = value => value && /^[a-zA-Z]{1}[0-9]{8}$/.test(value);
export const validateCaravanEId = value => value && /^03201[0-4]{1}[0-9]{9}$/.test(value);

export const formValidators = {
    validateRequired2: (value) => {alert(value); return value ? undefined : ERRORS.required},
    validateRequired: (value) => value ? undefined : ERRORS.required,
    validateEmail: (value) => validateEmail(value) ? undefined : ERRORS.invalidEmail,
    validateRENSPA: (value) => validateRENSPA(value) ? undefined : ERRORS.invalidRENSPA,
    minLength: min => value => value && value.length < min ? ERRORS.shortPassword : undefined,
    validateCowWeight: (value) => (value >= 15 && value <= 1500) ? undefined : ERRORS.invalidCowWeight,
    validateCowWeightNotRequired: (value) => !value || (value >= 15 && value <= 1500) ? undefined : ERRORS.invalidCowWeight,
    validateLatCoordinate: (value) => ((!isNaN(value)) && (value > -91) && (value < 91)) ? undefined : ERRORS.invalidLatCoordinate,
    validateLonCoordinate: (value) => ((!isNaN(value)) && (value > -181) && (value < 181)) ? undefined : ERRORS.invalidLonCoordinate,
    validateDateBetweenNotRequired: (minDate, maxDate) => value => (!value || (dateStringIsBetweenRange(value, minDate, maxDate))) ? undefined : ERRORS.invalidDate,
    validateDateBetweenRequired: (minDate, maxDate) => value => (dateStringIsBetweenRange(value, minDate, maxDate)) ? undefined : ERRORS.invalidDate,
    validateNumberBetweenNotRequired: (minNumber, maxNumber) => value => (!value || (value >= minNumber && value <= maxNumber)) ? undefined : ERRORS.invalidMonthAge,
    
    validateCaravanIdRequired: (value) => validateCaravanId(value) ? undefined : ERRORS.invalidCaravanId,
    validateCaravanIdRRequired: (value) => validateCaravanIdR(value) ? undefined : ERRORS.invalidCaravanId,
    validateCaravanEIdRequired: (value) => validateCaravanEId(value) ? undefined : ERRORS.invalidCaravanId,
    
    validateCaravanIdNotRequired: (value) => (!value || validateCaravanId(value)) ? undefined : ERRORS.invalidCaravanId,
    validateCaravanIdRNotRequired: (value) => (!value || validateCaravanIdR(value)) ? undefined : ERRORS.invalidCaravanId,
    validateCaravanEIdNotRequired: (value) => (!value || validateCaravanEId(value)) ? undefined : ERRORS.invalidCaravanId,
       
    validateUrl: (value) =>  {
        if (value!== undefined){
            var regex = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi);
            if (!value.match(regex)){
                return ERRORS.invalidURL
            }else{
               return undefined
            }
        }
    }
};


export const formMasks = {
    RENSPAMask: [/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, '.', /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, '.', /[a-zA-Z0-9]/, '.',
        /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, '/', /[a-zA-Z0-9]/, /[a-zA-Z0-9]/],
    caravanIdMask: [/[a-zA-Z]/, /[a-zA-Z0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[a-zA-Z]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[0-9]/],
    caravanIdMaskR: [/[a-zA-Z]/, /[a-zA-Z0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/],
    cuilOrCuitMask: [/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/],
    caravanEIdMask: [/[0]/, /[3]/, /[2]/, /[0]/, /[1]/, /[0-4]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
};

const appendZeros = (n) => {
    if(n < 10) return '0' + n;
    return n;
};

export const parse_UTC_date_to_YYYY_MM_DD = (stringDate) => {
    let date_aux = new Date(stringDate.split('Z')[0]);
    return `${date_aux.getFullYear()}-${appendZeros(date_aux.getMonth()+1)}-${appendZeros(date_aux.getDate())}`
};

export const parse_UTC_date_to_DD_MM_YYYY = (stringDate) => {
    let date_aux = new Date(stringDate.split('Z')[0]);
    return `${appendZeros(date_aux.getDate())}/${appendZeros(date_aux.getMonth()+1)}/${date_aux.getFullYear()}`
};

export const parse_js_date_to_YYYY_MM_DD = (date) => {
    return `${date.getFullYear()}-${appendZeros(date.getMonth()+1)}-${appendZeros(date.getDate())}`
};

export const subtract_x_years_to_date = (date, xYears) => {
  date.setFullYear(date.getFullYear() - xYears);
  return date;
};

const dateStringIsBetweenRange = (dateString, minDate, maxDate) => {
    let date = new Date(dateString);
    return date > minDate && date < maxDate;
};