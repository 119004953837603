import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Card, CardHeader, CardBody, CardFooter, Button, ButtonToolbar} from 'reactstrap';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Field, reduxForm, change, formValueSelector} from 'redux-form';
import {connect} from "react-redux";
import actions from "../../../../redux/actions";
import MaskedInput from "react-text-mask";
import i18n from '../../../../translations/config/i18n';
import {useAlert} from "react-alert";
import {SRLWrapper} from "simple-react-lightbox";
import CVButton from "../../../../shared/components/cv/CVButton";
import {formValidators, subtract_x_years_to_date} from '../../../../util/index';
import { withRouter } from 'react-router-dom';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import {renderDateInputField} from "../../../../shared/components/cv/CVDatePicker";
import {renderCVFileInputField, renderCVPhotoInputField} from '../../../../shared/components/cv/CVFileUpload';
import renderSelectField from "../../../../shared/components/form/Select";
import {ROLES} from "../../../../constants";
import Helpi from '../../../Helpi';

const renderField = ({input, disabled, placeholder, type, meta: {touched, error, warning}, mask}) => (
  <div style={{width: '100%'}}>
    {!mask && <input style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type} />}
    {mask && <MaskedInput style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type} mask={mask}/>}
    <div className="input-error-container">
      {touched && ((error && <span className="error-message">{i18n.t(`common:errors.${error}`)}</span>) || (warning &&
        <span className="error-message">{warning}</span>))}
    </div>
  </div>
);

const availableSlaugtherRite = ['Sin informar', 'Kosher', 'Halal'];
const files =['file1','file2','file3','file4','file5'];

// Solo válido para el caso: showImages = true
const optionsLightbox = {
  settings: {},
  caption: {
      showCaption: false,
  },
  buttons: {
      showAutoplayButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showDownloadButton: false,
      showFullscreenButton: false,
  },
  thumbnails: {
      showThumbnails: false,
  },
  progressBar:{},    
}

// Solo válido para el caso: showImages = true
const refreshLabelsLightbox = () => {
  var divs = document.getElementsByClassName( "SRLCloseButton" );
  for (var a in divs) {
      try {
          divs[a].setAttribute('title', 'CERRAR');
      } catch (error) {

      }            
  }        
  var divsDownload = document.getElementsByClassName( "SRLDownloadButton" );
  for (var a in divs) {
      try {
          divsDownload[a].setAttribute('title', 'DESCARGAR');
      } catch (error) {

      }
  }        
}

const validateDateRequired = formValidators.validateDateBetweenRequired(
  subtract_x_years_to_date(new Date(), 100), // 100 years ago date
  new Date(), // today date
);

const ThirdPartySlaughterEventForm = (props) => {
  
  ThirdPartySlaughterEventForm.propTypes = {
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pending: PropTypes.bool.isRequired
  };

  const {
    t,
    userId,
    handleSubmit,
    onSubmit,
    editable,
    isSlaughter,
    initialValues,    
    showImages,
    allCompanies,
    allEstablishments,
    allEstablishmentLots,
    hideTitle,
    establishmentDetails,
    currentCompany,
    currentEstablishment,
    averageWeight
  } = props;

  const [selectedCompany, selectCompany] = useState([])
  const [selectedEstablishment, selectEstablishment] = useState([])
  const [selectedLot, selectLot] = useState([])
  const [facilityType, setFacilityType] = useState([])
  const [facilityIsFrigo, setFacilityIsFrigo] = useState(false)
  const [globalEventName, setGlobalEventName] = useState();
  const [globalEventI18next, setGlobalEventI18next] = useState();
  const alert = useAlert();

  const deleteFile = (initialValuesName) => {                
    props.dispatch(change('thirdPartySlaughterForm',initialValuesName,null))
    props.dispatch(change('thirdPartySlaughterForm',initialValuesName+'_checksumFile',null)) 
    props.dispatch(change('thirdPartySlaughterForm',initialValuesName+'_description',null))                                      
    props.initialValues[initialValuesName]='';
    props.initialValues[initialValuesName+'_checksumFile']='';
    props.initialValues[initialValuesName+'_description']='';
  }

  useEffect(() => {
    if(props.roles.some(rol => (rol.name) === ROLES.TITULAR )) { props.getAllCompanies(props.userId) }
    if(props.roles.some(rol => (rol.name) === ROLES.SURROGATE )) { props.getAssignedCompanies(props.userId) }
    if(!initialValues.companyId) initialValues.companyId = currentCompany._id
    if(!initialValues.facilityId) initialValues.facilityId = currentEstablishment._id
    if(currentEstablishment) {
      if (currentEstablishment.type == "slaughterhouse") {
          setFacilityType('slaughterhouse')
          setGlobalEventName('Faena')
          setGlobalEventI18next('slaughter_event')
      }
      if (currentEstablishment.type == "commercial") {
          setFacilityType('commercial')
          setGlobalEventName('FaenaEnTerceros')
          setGlobalEventI18next('third_party_slaughter_event')
      }
    }
    return () => { props.resetSelectedEvent(); props.resetSelectedLot() }
  }, []);

  useEffect(() => {
      if (establishmentDetails.id == selectedEstablishment._id && establishmentDetails.type =='slaughterhouse') {
        props.dispatch(change('thirdPartySlaughterForm', 'fridgeName', establishmentDetails.establishmentName)) 
        props.dispatch(change('thirdPartySlaughterForm', 'fridgeRuca', establishmentDetails.ruca.id))
        props.dispatch(change('thirdPartySlaughterForm', 'rucaImage', establishmentDetails.ruca.image))
        props.dispatch(change('thirdPartySlaughterForm', 'logotypeImage', establishmentDetails.logotypePhoto))
        props.dispatch(change('thirdPartySlaughterForm', 'latitude', establishmentDetails.coordinates.lat)) 
        props.dispatch(change('thirdPartySlaughterForm', 'longitude', establishmentDetails.coordinates.long))
        props.initialValues.rucaImage = establishmentDetails.ruca.image
        props.initialValues.logotypeImage = establishmentDetails.logotypePhoto
        
        setFacilityIsFrigo(true)
      }
  }, [establishmentDetails, selectedEstablishment])

useEffect(() => { 
    if(initialValues.companyId && allCompanies.length > 0) {
      allCompanies.map((company) => ((company._id == initialValues.companyId)? selectCompany(company) : null));
      props.getAllEstablishments(initialValues.companyId);
      props.getAllValidLots('');
      selectEstablishment('');
      selectLot('');
    }
  }, [allCompanies]);

  useEffect(() => {
    if(initialValues.facilityId && allEstablishments.length > 0) {
      allEstablishments.map((establishment) => ((establishment._id == initialValues.facilityId)? selectEstablishment(establishment) : null));
      props.getAllValidLots(initialValues.facilityId, initialValues.viewOnlyActiveLots);
      selectLot('');
    }
  }, [allEstablishments]);

  useEffect(() => {
    if(initialValues.lotId && allEstablishmentLots.length > 0) {
      allEstablishmentLots.map((lot) => ((lot._id == initialValues.lotId)? selectLot(lot) : null));                 
      props.getLotAverageWeight(initialValues.lotId);
    } 
  }, [allEstablishmentLots]);

  useEffect(() => {
    if (!isSlaughter) props.dispatch(change('thirdPartySlaughterForm', 'lotAverageWeight', Math.round(averageWeight.average))) 
  }, [averageWeight]);


  const changeCompany = (event) => {  
    let company = event.target.value;
    selectCompany(company);
  
    props.getAllEstablishments(company._id);
    selectEstablishment('');
    selectLot('');
  };

  const changeEstablishment = (event) => {      
      let establishment = event.target.value;        
      selectEstablishment(establishment);        
      props.getAllValidLots(establishment._id, initialValues.viewOnlyActiveLots);
      selectLot('');
      if (globalEventName == 'Faena') props.getUserEstablishmentById(establishment._id)
  };

  const changeLot = (event) => { 
      let lot = event.target.value;
      if (lot.animalsCount == 0 ) {
        alert.error(t('events.third_party_slaughter.alerts.lotWithoutAnimals'), {timeout: 4000} )
        selectLot('');
      } else if (lot.animalsCount > 40 ) {
        alert.error(t('events.third_party_slaughter.alerts.lotWithMoreAnimals'), {timeout: 4000} )
        selectLot('');
      } else selectLot(lot);      
      props.getLotAverageWeight(lot._id)
  };

  const showErrors = (invalid) => {
    if(invalid) ToastsStore.error(t('common.general_invalid_form'), 10000)
  }

  const submitForm = (values) => {
    values.userId = userId;
    values.companyId = selectedCompany._id;
    values.facilityId = selectedEstablishment._id;
    values.lotId = selectedLot._id;
    values.globalEventName = globalEventName;
    values.entityType = initialValues.entityType;
    values.file1_checksumFile = initialValues.file1_checksumFile;
    values.file2_checksumFile = initialValues.file2_checksumFile;
    values.file3_checksumFile = initialValues.file3_checksumFile;
    values.file4_checksumFile = initialValues.file4_checksumFile;
    values.file5_checksumFile = initialValues.file5_checksumFile;
    onSubmit({...values});
  };

  return (
    <Container>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
      <form className="form" onSubmit={handleSubmit(submitForm)}>
        <Card>
          <CardHeader>
            <div className="card__title">
              {!hideTitle && <h5 className="bold-text">{t('cv-forms.events.' + globalEventI18next + '.title')}</h5>}
            </div>

            {/* ================ SELECTS - Company / Establishment / Lot (Button Back) ============ */}
            <Row>
              <Col xl={3} md={6}>
                  <InputLabel id="company-select-label" style={{marginBottom:"0px", marginTop:"5px", fontSize:"14px"}}>Empresa</InputLabel><br/>
                  <FormControl>                                             
                      <Select
                        labelId="company-select-label"
                        name="companyId"
                        id="company-select"
                        onChange={changeCompany}
                        value={selectedCompany}
                        displayEmpty style={{fontSize:"12px"}}
                        disabled={!editable || isSlaughter}
                      >
                        <MenuItem style={{fontSize:"12px"}} value='' disabled>Seleccione una Empresa</MenuItem>                        
                        { allCompanies.map((company) => (
                        <MenuItem style={{fontSize:"12px"}} value={company}>{company.name}</MenuItem>                        
                        ))}
                      </Select>
                  </FormControl>
              </Col>
              <Col xl={3} md={6}>
                  <InputLabel id="establishment-select-label" style={{marginBottom:"0px", marginTop:"5px", fontSize:"14px"}}>Establecimiento</InputLabel><br/>
                  <FormControl>     
                      <Select
                        labelId="establishment-select-label"
                        name="facilityId"
                        id="establishment-select"
                        onChange={changeEstablishment}
                        value={selectedEstablishment}
                        displayEmpty style={{fontSize:"12px"}}
                        disabled={!editable || isSlaughter}
                      >
                        <MenuItem style={{fontSize:"12px"}} value='' disabled>Seleccione un Establecimiento</MenuItem>                        
                        { allEstablishments.map((establishment) => {
                          if ( establishment.type === facilityType ){
                                return  <MenuItem style={{fontSize:"12px"}} value={establishment}>{establishment.name}</MenuItem>                       
                          } else { return "" }
                        })}
                      </Select>
                  </FormControl>
              </Col>
              <Col xl={3} md={6}>
                  <InputLabel id="lots-select-label" style={{marginBottom:"0px", marginTop:"5px", fontSize:"14px"}}>Lotes</InputLabel><br/>
                  <FormControl>                         
                      <Select
                        labelId="lots-select-label"
                        id="lots-select"
                        onChange={changeLot}
                        value={selectedLot}
                        displayEmpty style={{fontSize:"12px"}}
                        disabled={!editable || isSlaughter}
                      >
                        <MenuItem style={{fontSize:"12px"}} value='' disabled>Seleccione un Lote</MenuItem>                        
                        { allEstablishmentLots.map((lot) => (
                        <MenuItem style={{fontSize:"12px"}} value={lot}>{lot.name}</MenuItem>                        
                        ))}
                      </Select>
                  </FormControl>
              </Col>
              <Col xl={3} md={6}>
                  <div className="edit-buttons" >
                      <ButtonToolbar className="form__button-toolbar" >
                          { !editable && <Button 
                              color="primary" 
                              outline
                              type="button" 
                              onClick={() => props.setEditable(!editable)}>
                              {t('common.edit')}</Button>
                          }
                          <Button 
                              color="primary" 
                              outline={true} 
                              type="button" 
                              onClick={() => props.history.goBack()}>
                              {t('common.back')}
                          </Button>
                      </ButtonToolbar>
                  </div>
              </Col>
            </Row>
            {/* ================================= End SELECTS ============================ */}
          </CardHeader>
                          
          <CardBody>
            {/* =============================== Datos FRIGO ============================== */}
            <Row>
              <Col xl={6} md={6}>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('cv-forms.events.' + globalEventI18next + '.fridgeName')}</span>
                  <Field
                      name="fridgeName"
                      component={renderField}     
                      type="text"
                      placeholder={t('cv-forms.events.' + globalEventI18next + '.fridgeName')}
                      disabled={!editable || facilityIsFrigo}
                  />
                </div>
              </Col>
              <Col xl={3} md={3}>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('cv-forms.events.' + globalEventI18next + '.date')}</span>
                  <Field
                      name="eventDate"
                      component={renderDateInputField}
                      validate={[validateDateRequired]}
                      disabled={!editable || isSlaughter}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={3} md={3}>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('cv-forms.events.' + globalEventI18next + '.fridgeRuca')}</span>
                  <Field
                      name="fridgeRuca"
                      component={renderField}     
                      type="text"
                      placeholder={t('cv-forms.events.' + globalEventI18next + '.fridgeRuca')}
                      disabled={!editable || facilityIsFrigo}
                  />
                </div>
              </Col>
              <Col xl={3} md={3}>
                <span className="form__form-group-label">{t('cv-forms.events.' + globalEventI18next + '.imageRuca')}</span><br></br>
                { (facilityIsFrigo) ? 
                    <a href={establishmentDetails.ruca.image}>
                      {t('cv-forms.events.' + globalEventI18next + '.downloadImageRuca')}
                    </a>
                  : <div>
                      {
                        (!showImages || editable) &&
                          <Field
                            name="rucaImage"
                            component={renderCVFileInputField}
                            disabled={!editable}
                            acceptedFormats={'.pdf, .docx, .doc, .xlsx, .xls, .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .png, .gif, .tiff, .tif, .bmp, .dib'}
                            hideFileName={false}
                          />                                            
                      }
                      { 
                        (showImages) && props.initialValues.rucaImage ? 
                        <div>
                            {
                                typeof (props.rucaImage) !== 'object' || props.rucaImage == null ?
                                <a href={props.initialValues.rucaImage}>
                                  {t('cv-forms.events.' + globalEventI18next + '.downloadImageRuca')}
                                </a>
                                : null
                            }
                            {
                                (editable) && <a onClick={()=>{deleteFile('rucaImage')}}><button>Eliminar</button></a>
                            }
                        </div>
                        : typeof (props.rucaImage) !== 'object' || props.rucaImage == null? 
                            <label>{t('cv-forms.events.' + globalEventI18next + '.noImageRuca')}</label>:
                            <a onClick={()=>{deleteFile('rucaImage')}}><button>Eliminar</button></a>

                      }
                    </div>
                 }
              </Col>
              <Col xl={3} md={3}>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('cv-forms.events.' + globalEventI18next + '.numSenasa')}</span>
                  <Field
                      name="numSenasa"
                      component={renderField}     
                      type="text"
                      placeholder={t('cv-forms.events.' + globalEventI18next + '.numSenasa')}
                      disabled={!editable}
                    />
                </div>              
              </Col>
              <Col xl={3} md={3}>
                <span className="form__form-group-label">{t('cv-forms.events.' + globalEventI18next + '.fridgeLogotype')}</span>
                { (facilityIsFrigo) &&<Helpi 
                            title={t('cv-forms.events.' + globalEventI18next + '.noFridgeLogoHelpi')}
                            size="20"
                            iconColor="lightgrey"
                            fontColor="white"/>
                }
                { (facilityIsFrigo) ? 
                      (establishmentDetails.logotypePhoto) ?
                        <div>
                          <a href={establishmentDetails.logotypePhoto}>
                            {t('cv-forms.events.' + globalEventI18next + '.downloadFridgeLogotype')}
                          </a>
                        </div>
                      : <div>
                          <label>{t('cv-forms.events.' + globalEventI18next + '.noFridgeLogotype')}</label>

                        </div>
                  : <div>
                      {
                        (!showImages || editable) &&
                          <Field
                            name="logotypeImage"
                            component={renderCVFileInputField}
                            disabled={!editable}
                            acceptedFormats={'.pdf, .docx, .doc, .xlsx, .xls, .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .png, .gif, .tiff, .tif, .bmp, .dib'}
                            hideFileName={false}
                          />                                            
                      }
                      { 
                        (showImages) && props.initialValues.logotypeImage ? 
                        <div>

                            {
                                typeof (props.logotypeImage) !== 'object' || props.logotypeImage == null ?
                                <a href={props.initialValues.logotypeImage}>
                                  {t('cv-forms.events.' + globalEventI18next + '.downloadFridgeLogotype')}
                                </a>
                                : null
                            }
                            {
                                (editable) && <a onClick={()=>{deleteFile('logotypeImage')}}><button>Eliminar</button></a>
                            }
                        </div>
                        : typeof (props.logotypeImage) !== 'object' || props.logotypeImage == null? 
                            <label>{t('cv-forms.events.' + globalEventI18next + '.noFridgeLogotype')}</label>:
                            <a onClick={()=>{deleteFile('logotypeImage')}}><button>Eliminar</button></a>

                      }
                    </div>
                 }
              </Col>
            </Row>
            <Row>
              <Col xl={6} md={6}>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('cv-forms.events.' + globalEventI18next + '.latitude')}</span>
                  <Field
                      name="latitude"
                      component={renderField}     
                      type="text"
                      placeholder={t('cv-forms.events.' + globalEventI18next + '.latitude')}
                      disabled={!editable || facilityIsFrigo}
                  />
                </div>
              </Col>
              <Col xl={6} md={6}>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('cv-forms.events.' + globalEventI18next + '.longitude')}</span>
                  <Field
                      name="longitude"
                      component={renderField}     
                      type="text"
                      placeholder={t('cv-forms.events.' + globalEventI18next + '.longitude')}
                      disabled={!editable || facilityIsFrigo}
                    />
                </div>              
              </Col>
            </Row>
            <Row>
              <Col xl={6} md={6}>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('cv-forms.events.' + globalEventI18next + '.slaughterRite')}</span>
                  <Field
                      name="slaughterRite"
                      defaultValue={{"value": initialValues.slaughterRite, "label": initialValues.slaughterRite}}
                      component={renderSelectField}     
                      type="text"
                      placeholder={t('cv-forms.events.' + globalEventI18next + '.slaughterRite')}
                      disabled={!editable}
                      options={[ ...availableSlaugtherRite.map(category => ({ value: category, label: category })) ]
                    }
                  />
                </div>
              </Col>
              <Col xl={6} md={6}>
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('cv-forms.events.' + globalEventI18next + '.lotAverageWeight')}</span>
                  <Field
                      name="lotAverageWeight"
                      component={renderField}     
                      type="text"
                      placeholder={t('cv-forms.events.' + globalEventI18next + '.lotAverageWeight')}
                      disabled={!editable}
                    />
                </div>              
              </Col>
            </Row>

            {/* ========================== Image Inputs ============================ */}
            <Row>
            { files.map((file) => (
               <Col md={2} lg={2}>
                  <div>
                      {/* <Helpi title="" size="20" iconColor="lightgrey" fontColor="white"/> */}
                      <span className="form__form-group-label">{t('cv-forms.events.' + globalEventI18next + '.' + file)}</span>
                      {
                      (props.initialValues[file]) ?
                          <div>
                            {
                                typeof (props[file]) !== 'object' || props[file] == null ?
                                  <a href={props.initialValues[file]}>{t('cv-forms.cow.download_file')}</a>
                                : null
                            }
                                <Field
                                  name={file + "_description"}
                                  component={renderField}     
                                  type="text"
                                  placeholder={"Descripcion del documento"}
                                  disabled={!editable}
                                />
                            {    
                                (editable) ? <a onClick={()=>{deleteFile(file)}}><button>Eliminar</button></a> : null
                            }
                          </div>
                          :
                          <div>
                                <Field
                                  name={file}
                                  component={renderCVFileInputField}
                                  disabled={!editable}
                                  acceptedFormats={'.pdf, .docx, .doc, .xlsx, .xls, .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .png, .gif, .tiff, .tif, .bmp, .dib'}
                                  hideFileName={false}
                                />
                                {
                                 (typeof (props[file]) == 'object' && props[file] != null) ?
                                  <div>
                                    <Field
                                      name={file + "_description"}
                                      component={renderField}     
                                      type="text"
                                      placeholder={t('cv-forms.events.' + globalEventI18next + '.fileDescription')}
                                      disabled={!editable}
                                    />
                                    <a onClick={()=>{deleteFile(file)}}><button>Eliminar</button></a>
                                  </div>
                                  : <label>{t('cv-forms.events.' + globalEventI18next + '.noFile')}</label>
                                }
                          </div>
                      }
                  </div>
               </Col>
            ))}
            </Row>
            {/* ========================== End Image Inputs ============================ */}           
          </CardBody>

          <CardFooter>
            <ButtonToolbar className="form__button-toolbar">
                {
                    editable &&
                    <CVButton
                    color="primary"
                    type="submit"
                    onClick={()=> showErrors(props.invalid)}
                    pending={props.pending}>
                    {t('common.accept')}
                    </CVButton>
                }
                {
                    editable &&
                    <Button type="button" onClick={() => props.history.goBack()}>
                    {t('common.cancel')}
                    </Button>
                 }
            </ButtonToolbar>
          </CardFooter>
        </Card>
      </form>
    </Container>
  )
};

const selector = formValueSelector('thirdPartySlaughterForm');

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues || {},
  isSlaughter: props.initialValues.isSlaughter,
  roles: state.user.roles,
  userId: state.user.id,
  allCompanies: state.thirdPartySlaughter.allCompanies,
  allEstablishments: state.thirdPartySlaughter.allEstablishments,
  allEstablishmentLots: state.globalEvent.allLotsWhithAnimals,
  currentCompany: state.user.selectedCompany,
  currentEstablishment: state.user.selectedEstablishment,
  establishmentDetails: state.user.establishmentDetails,
  averageWeight: state.thirdPartySlaughter.averageWeight,

  rucaImage: selector(state, 'rucaImage'),
  logotypeImage: selector(state, 'logotypeImage'),
  file1: selector(state, 'file1'),
  file2: selector(state, 'file2'),
  file3: selector(state, 'file3'),
  file4: selector(state, 'file4'),
  file5: selector(state, 'file5'),
});

const mapDispatchToProps = (dispatch) => ({
  getAllCompanies: (userId) => dispatch(actions.thirdPartySlaughter.getAllUserCompanies(userId)),
  getAllEstablishments: (companyId) => dispatch(actions.thirdPartySlaughter.getUserEstablishmentsByCompany(companyId)), 
  getAllValidLots: (establishmentId, viewAllLots) => dispatch(actions.globalEvent.getLotsWithAnimalsByFacilityId(establishmentId, viewAllLots)),
  selectEstablishment: (establishmentId) => dispatch(actions.thirdPartySlaughter.selectEstablishment(establishmentId)),
  getAssignedCompanies: (userId) => dispatch(actions.thirdPartySlaughter.getAssignedCompanies(userId)),    
  resetSelectedEvent: () => dispatch(actions.thirdPartySlaughter.resetSelectedEvent()),
  // TODO reset glovalEvent completo. Antes hay que pasar de thirdPartySlaughter a globalEvent
  resetSelectedLot: () => dispatch(actions.globalEvent.resetSelectedEvent()),
  getUserEstablishmentById: (establishmentId) => dispatch(actions.user.getEstablishmentById(establishmentId)),
  resetEstablishmentDetails: (establishmentId) => dispatch(actions.user.resetEstablishmentDetails(establishmentId)),
  getLotAverageWeight: (lotId) => dispatch(actions.thirdPartySlaughter.getLotAverageWeight(lotId))
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'thirdPartySlaughterForm',
  touchOnChange: true // this is set because if not the 'touched' props on DateInputField was always false
})(withTranslation('common')(withRouter(ThirdPartySlaughterEventForm))));