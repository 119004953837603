import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';

import HomeIcon from 'mdi-react/HomeIcon';
import CowIcon from 'mdi-react/CowIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import TransferRightIcon from 'mdi-react/TransferRightIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

class SimpleTable extends React.Component {
    
    constructor(props) {
      super(props);
          
    }
        
    render() {
      const {data}  = this.props;
      return (          
          <Col md={12} xl={this.props.col} lg={this.props.col} xs={12}>
          <Card style={{paddingBottom:'20px'}}>
            <CardBody style={{margin:'0px 0px 0px 0px',padding:'5px'}}>            
              <Table responsive striped>
                <thead>
                  <tr>
                    <th colspan="2" style={{padding:'0px 5px 5px 5px'}}><h5 className="dashboard__booking-total-description" style={{color:this.props.color}}>
                      {(this.props.icon=='Home') && (
                        <HomeIcon style={{cursor:"default"}}/>
                      )}
                      {(this.props.icon=='MapMarker') && (
                        <MapMarkerIcon style={{cursor:"default"}}/>
                      )}
                      {(this.props.icon=='TransferRight') && (
                        <TransferRightIcon style={{cursor:"default"}}/>
                      )}
                      {(this.props.icon=='Cow') && (
                        <CowIcon style={{cursor:"default"}}/>
                      )}
                      &nbsp;{this.props.title}</h5></th>
                  </tr>
                  
                </thead>  
                <tbody>                
                {
                  data.map(el => (
                    <tr style={{verticalAlign:'middle'}}><td style={{padding:'8px'}}>
                        {el.name}{
                            (el.link) && (
                            <a href={el.link} style={{marginLeft:"5px"}}><OpenInNewIcon color="black" size="15"/></a>
                            )
                            }
                        </td>
                        <td style={{fontSize:'14px',fontWeight:'bold',color:'blue', padding:'8px'}}>
                        {el.value}                        
                        </td></tr>
                  ))
                }
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
            
        )
  }
}

SimpleTable.defaultProps = {
  data: []
};

export default withTranslation('common')(SimpleTable);
