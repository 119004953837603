import React from 'react';
import { render } from 'react-dom';
import App from './containers/App/App';
import { Provider as AlertProvider } from 'react-alert'
import { CVAlertTemplate, options} from "./shared/components/cv/CVAlert";
import SimpleReactLightbox from 'simple-react-lightbox'


render(
    <AlertProvider template={CVAlertTemplate} {...options}>
        <SimpleReactLightbox>
          <App />
        </SimpleReactLightbox>
    </AlertProvider>,
  document.getElementById('root'),
);
