import React, {useState, useEffect} from 'react';
import {Col, Card, CardBody} from 'reactstrap';
import {withTranslation} from "react-i18next";
import CompanyFormPage from "./CompanyFormPage";
import ProductiveEstablishmentForm from "../../../ManageEstablishments/components/establishments/ProductiveEstablishmentForm";
import SlaughterEstablishmentForm from "../../../ManageEstablishments/components/establishments/SlaughterEstablishmentForm";
import {connect} from "react-redux";
import actions from '../../../../redux/actions/index';
import EstablishmentTypeSelection from "../../../ManageEstablishments/components/AddEstablishmentWizard/EstablishmentTypeSelection";
import {ESTABLISHMENT_TYPES} from '../../../../constants/index';
import {useAlert} from "react-alert";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

const TitularWizardForm = (props) => {
    const [page, setPage] = useState(1);
    const [companyId, setCompanyId] = useState();
    const [commercialAddress, setCommercialAddress] = useState();
    const [establishmentTypeSelected, setEstablishmentTypeSelected] = useState();

    const {t} = props;

    const alert = useAlert();
    

    useEffect(() => {
        props.getAvailableEntityTypes();
        props.getAvailableProductiveSystems();
        props.getAvailableGroundConditions();
        props.getFacilitiesProductiveTypes();
    }, []);

    const companyCreatedCallback = (companyId) => {
        setCompanyId(companyId);
        nextPage();
    };

    const nextPage = () => {
        setPage(page + 1);
    };

    const previousPage = () => {
        setPage(page - 1);
    };

    const submitCompanyForm = (values) => {
        setCommercialAddress(values.companyCommercialAddress);
        props.submitCompanyForm(values, function(companyId){
            companyCreatedCallback(companyId)
        }, function (errorMessage) {
                ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);}
        );
    };

    // const establishmentAddedSuccessfully = () => {
    //     props.history.push('/manage_establishments/table');
    //     alert.success(t('add_new_account_wizard.establishment_and_account_added_successfully_message'));
    // };

    // const submitEstablishmentForm = (values) => {
    //     props.submitEstablishmentForm(values, companyId, establishmentTypeSelected, establishmentAddedSuccessfully);
    // };

    const submitEstablishmentForm = (values) => {
        props.submitEstablishmentForm(values, companyId, establishmentTypeSelected, function() {
            props.history.push('/manage_establishments/table');
            alert.success(t('add_new_establishment.establishment_added_successfully_message'));
        }, function(errorMessage) {
            ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
        });
    };

    const selectEstablishmentType = (establishmentType) => {
        setEstablishmentTypeSelected(establishmentType);
        nextPage();
    };

    const getEstablishmentFormByType = (establishmentType) => {
        switch (establishmentType) {
            case ESTABLISHMENT_TYPES.productive:
                return (
                  <ProductiveEstablishmentForm
                    previousPage={previousPage}
                    submitEstablishmentForm={submitEstablishmentForm}
                    availableProductiveSystems={
                      props.availableProductiveSystems
                    }
                    availableGroundConditions={props.availableGroundConditions}
                    editable={true}
                    facilitiesTypes={props.facilitiesTypes}
                    showBackButton={true}
                    submitButtonText={t("common.finalize")}
                    addressesToAutocomplete={{
                      names: ["commercial", "personal"],
                      commercial: { ...commercialAddress },
                      personal: { ...props.personalAddress },
                    }}
                    pending={props.addEstablishmentToCompanyPending}
                  />
                );
            case ESTABLISHMENT_TYPES.slaughterhouse:
            case ESTABLISHMENT_TYPES.commercial:
            case ESTABLISHMENT_TYPES.importer:
                return (
                  <SlaughterEstablishmentForm
                    previousPage={previousPage}
                    submitEstablishmentForm={submitEstablishmentForm}
                    editable={true}
                    showBackButton={true}
                    submitButtonText={t("common.finalize")}
                    addressesToAutocomplete={{
                      names: ["commercial", "personal"],
                      commercial: { ...commercialAddress },
                      personal: { ...props.personalAddress },
                    }}
                    pending={props.addEstablishmentToCompanyPending}
                  />
                );
            default:
                return <div>No implementado todavía</div>;

        }
    };

    const getEstablishmentTitleByType = (establishmentType) => {
        switch (establishmentType) {
            case ESTABLISHMENT_TYPES.productive:
                return t('cv-forms.productive_establishment.title');
            case ESTABLISHMENT_TYPES.slaughterhouse:
                return t('cv-forms.slaughter_establishment.title');
            case ESTABLISHMENT_TYPES.commercial:
                return t('cv-forms.commercial.title');
            case ESTABLISHMENT_TYPES.importer:
                return t('cv-forms.importer.title');
            default:
                return 'No implementado todavía';
        }
    };

    const getStep = () => {
        switch (page) {
            case 1:
                return (
                  <div>
                  <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
                  <CompanyFormPage
                    previousPage={previousPage}
                    handleSubmit={submitCompanyForm}
                    availableEntityTypes={props.availableEntityTypes}
                    pending={props.addUserCompanyPending}
                    history={props.history}
                  />
                  </div>
                );
            case 2:
                return <EstablishmentTypeSelection selectEstablishmentType={selectEstablishmentType}/>;
            case 3:
                return (
                    <Col md={12} lg={12}>
                        <Card>
                            <CardBody>
                                <div className="card__title">
                                    <h5 className="bold-text">{getEstablishmentTitleByType(establishmentTypeSelected)}</h5>
                                </div>
                                {getEstablishmentFormByType(establishmentTypeSelected)}
                            </CardBody>
                        </Card>
                    </Col>
                );
            default:
                break;
        }
    };

    return (
        <Col md={12} lg={12}>
            <Card>
                <CardBody className="wizard">
                    <div className="wizard__steps">
                        <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}>
                            <p>{t('common.step')} 1</p></div>
                        <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}>
                            <p>{t('common.step')} 2</p></div>
                        <div className={`wizard__step${page === 3 ? ' wizard__step--active' : ''}`}>
                            <p>{t('common.step')} 3</p></div>
                    </div>
                    <div className="wizard__form-wrapper">
                        {getStep()}
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

const mapStateToProps = (state) => ({
    userId: state.user.id,
    accounts: state.user.accounts,
    availableEntityTypes: state.constant.availableEntityTypes,
    availableProductiveSystems: state.constant.availableProductiveSystems,
    availableGroundConditions: state.constant.availableGroundConditions,
    personalAddress: state.user.profile.homeAddress,
    commercialAddress: state.commercialAddress,
    addUserCompanyPending: state.ui.addUserCompanyPending,
    addEstablishmentToCompanyPending: state.ui.addEstablishmentToCompanyPending,
    facilitiesTypes: state.constant.facilitiesTypes
});

const mapDispatchToProps = (dispatch) => ({
    submitCompanyForm: (info, successCallback, errorCallBack) =>
        dispatch(actions.user.addCompany(info, successCallback, errorCallBack)),
    submitEstablishmentForm: (info, companyId, establishmentType, successCallback, errorCallBack) =>
        dispatch(actions.user.addEstablishmentToCompany(info, companyId, establishmentType, successCallback, errorCallBack)),
    getAvailableEntityTypes: () => dispatch(actions.constant.getAvailableEntityTypes()),
    getAvailableProductiveSystems: () => dispatch(actions.constant.getAvailableProductiveSystems()),
    getAvailableGroundConditions: () => dispatch(actions.constant.getAvailableGroundConditions()),
    getFacilitiesProductiveTypes: () => dispatch(actions.constant.getFacilitiesProductiveTypes())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(TitularWizardForm))