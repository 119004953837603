import React, { useEffect } from 'react';
import {Col, Card, CardBody, Button, ButtonToolbar} from 'reactstrap';
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Field, reduxForm} from 'redux-form';
import {connect} from "react-redux";
import MaskedInput from "react-text-mask";
import i18n from '../../../../src/translations/config/i18n';
import CVButton from "../../../shared/components/cv/CVButton";
import {formValidators} from '../../../util/index';
import { withRouter } from 'react-router-dom';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import actions from '../../../redux/actions/userActions';

const renderField = ({input, disabled, placeholder, type, meta: {touched, error, warning}, mask}) => (
    <div style={{width: '100%'}}>
        {!mask && <input style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type}/>}
        {mask && <MaskedInput style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type} mask={mask}/>}
        <div className="input-error-container">
            {touched && ((error && <span className="error-message">{i18n.t(`common:errors.${error}`)}</span>) || (warning &&
                <span className="error-message">{warning}</span>))}
        </div>
    </div>
);

const EstablishmentLotForm = (props) => {
    EstablishmentLotForm.propTypes = {
        t: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired
    };


    const {t, selectedEstablishment} = props;

    const showErrors = (invalid) => {
        if(invalid) ToastsStore.error(t('common.general_invalid_form'), 10000)
    }

    const submitForm = (e) => {
        e.preventDefault()
        let values = props.currentState.form['establishment-lot-form'].values;
        values.establishmentId = selectedEstablishment._id;
        props.submitEstablishmentLot(values, ()=> {
            props.history.push('/manage_establishments/establishment_lots_table');
        }, (errorMessage)=> {
            ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 6000);
        });
    };

    return (
        <Col md={12} lg={12}>
            <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
            <Card>
                <CardBody>
                    <div className="card__title">
                        {<h5 className="bold-text">{'Nuevo Lote'}</h5>}
                    </div>
                    <form className="form" onSubmit={submitForm}>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('establishments.lots.lot_name')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="name"
                                    component={renderField}
                                    validate={[formValidators.validateRequired]}
                                    type="text"
                                    placeholder={t('establishments.lots.lot_name')}
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('establishments.lots.lot_details')}</span>
                            <div className="form__form-group-field">
                                <Field
                                  name="details"
                                  component={renderField}
                                />
                            </div>
                        </div>
                        <ButtonToolbar className="form__button-toolbar">
                                <CVButton
                                  color="primary"
                                  type="submit"
                                  pending={props.addEstablishmentLotPending}
                                  onClick={()=> showErrors(props.invalid)}
                                >
                                {t('common.accept')}
                                </CVButton>
                                <Button type="button"
                                  onClick={() => props.history.push('/manage_establishments/establishment_lots_table')}
                                >
                                {t('common.cancel')}
                                </Button>
                        </ButtonToolbar>
                    </form>
                </CardBody>
            </Card>
        </Col>)
};

const mapStateToProps = (state) => ({
    initialValues: {},
    selectedEstablishment: state.user.selectedEstablishment,
    addEstablishmentLotPending: state.ui.addEstablishmentLotPending,
    currentState: state
});

const mapDispatchToProps = (dispatch) => ({
    submitEstablishmentLot: (values, successCallback, errorCallback) =>
    dispatch(actions.submitEstablishmentLot(values, successCallback, errorCallback))
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'establishment-lot-form',
    touchOnChange: true // this is set because if not the 'touched' props on DateInputField was always false
})(withTranslation('common')(withRouter(EstablishmentLotForm))));