import api from '../../api/index';
import {    
    GET_ALL_USER_COMPANIES_THIRDPARTYSLAUGHTER,
    GET_ASSIGNED_COMPANIES_THIRDPARTYSLAUGHTER,  
    GET_USER_ESTABLISHMENTS_BY_COMPANY_THIRDPARTYSLAUGHTER,    
    GET_LOTS_BY_ESTABLISHMENT_THIRDPARTYSLAUGHTER,
    SUBMIT_THIRDPARTYSLAUGHTER_EVENT,
    GET_GLOBAL_EVENT_THIRDPARTYSLAUGHTER,
    GET_GLOBAL_EVENT_BY_ID_THIRDPARTYSLAUGHTER,
    GET_LOT_AVERAGE_WEIGHT_GLOBALEVENT,
    UPLOAD_GLOBAL_EVENT_FILES
} from '../actions/thirdPartySlaughterActions';

import actions from '../actions';
import mapper from './mappers/userMapper';
import eventMapper from './mappers/eventMapper';

const thirdPartySlaughter = ({ dispatch, getState }) => next => action => {
    next(action);
    switch (action.type) {
        
        case GET_LOTS_BY_ESTABLISHMENT_THIRDPARTYSLAUGHTER:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getLotsByEstablishmentId(jwt, action.establishmentId, action.viewOnlyActiveLots)
                    .then(res => {
                        dispatch(actions.thirdPartySlaughter.getLotsByEstablishmentIdResponse(
                            eventMapper.response.getLotsByEstablishmentId(res, action.viewOnlyActiveLots)))
                        if (action.callback) action.callback();
                    })
                    .catch(err => {
                        dispatch(actions.thirdPartySlaughter.getLotsByEstablishmentIdError(err))
                    })
            }));
            break;
        
        case GET_ALL_USER_COMPANIES_THIRDPARTYSLAUGHTER:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAllUserCompanies(jwt)
                    .then(res => {
                        dispatch(actions.thirdPartySlaughter.getAllUserCompaniesResponse(mapper.response.getAllUserCompanies(res)))
                    })
                    .catch(err => {
                        dispatch(actions.thirdPartySlaughter.getAllUserCompaniesError(err))
                    })
            }));
            break;
        
        case GET_USER_ESTABLISHMENTS_BY_COMPANY_THIRDPARTYSLAUGHTER:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getUserEstablishmentsByCompany(jwt, action.companyId)
                    .then(res => {
                        dispatch(actions.thirdPartySlaughter.getUserEstablishmentsByCompanyResponse(
                            mapper.response.getUserEstablishmentsByCompany(res)))
                    })
                    .catch(err => {
                        dispatch(actions.thirdPartySlaughter.getUserEstablishmentsByCompanyError(err))
                    })
            }));
            break;

        case GET_ASSIGNED_COMPANIES_THIRDPARTYSLAUGHTER:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAssignedCompanies(jwt, action.userId)
                    .then(res => {
                        dispatch(actions.thirdPartySlaughter.getAssignedCompaniesResponse(mapper.response.getAssignedCompanies(res)))
                    })
                    .catch(err => {
                        dispatch(actions.thirdPartySlaughter.getAssignedCompaniesError(err))
                    })
            }))
            break;

        case SUBMIT_THIRDPARTYSLAUGHTER_EVENT:

            dispatch(actions.auth.authenticatedRequest(jwt => {
                //  Map all the files to be uploaded
                const files = eventMapper.data.uploadGlobalEventFiles(action.values);
                //  Upload all the files
                if(files.length > 0) {
                    dispatch(actions.thirdPartySlaughter.uploadGlobalEventFiles(files, (uploadedFilesAndUrls) => {
                        api.addThirdPartySlaughterEvent(jwt, eventMapper.body.addThirdPartySlaughterEvent(action.values, uploadedFilesAndUrls), 'thirdPartySlaughterEvent')
                            .then(res => {
                                dispatch(actions.thirdPartySlaughter.submitThirdPartySlaughterEventResponse(res));
                                if(action.successCallback) action.successCallback();
                            })
                            .catch(err => {
                                dispatch(actions.thirdPartySlaughter.submitThirdPartySlaughterEventError(err))
                                if(action.errorCallback) action.errorCallback(err);
                            })
                    }))
                }
                else {

                dispatch(actions.auth.authenticatedRequest(jwt => {
                    api.addThirdPartySlaughterEvent(jwt, eventMapper.body.addThirdPartySlaughterEvent(action.values), 'thirdPartySlaughterEvent')
                        .then(res => {
                            dispatch(actions.thirdPartySlaughter.submitThirdPartySlaughterEventResponse(res));
                            if(action.successCallback) action.successCallback();
                        })
                        .catch(err => {
                            dispatch(actions.thirdPartySlaughter.submitThirdPartySlaughterEventError(err))
                            if(action.errorCallback) action.errorCallback((err));
                        });
                    }))
                }
            }))
            break;

        case GET_GLOBAL_EVENT_THIRDPARTYSLAUGHTER:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAllGlobalEvent(jwt, action.userId, action.globalEventName, action.facilityId)
                    .then(res => {
                        dispatch(actions.thirdPartySlaughter.getAllGlobalEventResponse(eventMapper.response.getAllGlobalEvent(res)))
                    })
                    .catch(err => {
                        dispatch(actions.thirdPartySlaughter.getAllGlobalEventError(err))
                    })
            }));
            break;
        
        case GET_GLOBAL_EVENT_BY_ID_THIRDPARTYSLAUGHTER:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getGlobalEventByIdThirdPartySlaughter(jwt, action.globalEventId)
                    .then(res => {
                        dispatch(actions.thirdPartySlaughter.getGlobalEventByIdThirdPartySlaughterResponse(eventMapper.response.getAllGlobalEvent(res)))
                    })
                    .catch(err => {
                        dispatch(actions.thirdPartySlaughter.getGlobalEventByIdThirdPartySlaughterError(err))
                    })
            }));
            break;

        case GET_LOT_AVERAGE_WEIGHT_GLOBALEVENT:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getLotAverageWeight(jwt, action.lotId)
                    .then(res => {
                        dispatch(actions.thirdPartySlaughter.getLotAverageWeightResponse(res))
                    })
                    .catch(err => {
                        dispatch(actions.thirdPartySlaughter.getLotAverageWeightError(err))
                    })
            }));
            break;
        
        case UPLOAD_GLOBAL_EVENT_FILES:
                dispatch(actions.auth.authenticatedRequest(jwt => {
                    //  All files to upload
                    let filesToUpload = action.files;
                    //  All urls promises
                    let urlsPromises = [];
                    //  All files ans it associated ids
                    let filesAndAssociatedUrls = {};
    
                    filesToUpload.forEach(fileObj => {
                        let urlPromise = api.uploadImage(jwt, fileObj.file, fileObj.id);
                        urlsPromises.push(urlPromise);
                    });
    
                    Promise.all(urlsPromises)
                        .then(response => {
                            response.forEach(element => {
                                let fileName = element.additionalData.name;
                                let fileUrl = element.images.cvImage;
                                filesAndAssociatedUrls[`${fileName}`] = {fileUrl:fileUrl,md5Hash:element.md5Hash};
                            });
                            if(action.callback) action.callback(filesAndAssociatedUrls)
                        })
                        .catch(err => {
                            console.log("Error when uploading files ", err);
                        });
                }));
            break;

    }
}
export default thirdPartySlaughter;
