import React from 'react';
import {Container, Row} from 'reactstrap';
import DentitionEventForm from "../NewEventForms/DentitionEventForm"

const DentitionEventDetailsContainer = (props) => {
  const event = props.location.state.event;

  const mapInitialValues = (event) => {
    if (event)
      return {
        caravan_id: event.earId,
        date: event.date,
        value: event.value,
        comments: event.comments,
      };
    return {}
  };

  return (
    <Container>
      <Row>
        {
          event &&
          <DentitionEventForm onSubmit={() => {}} pending={props.addCowPending}
                              editable={false} initialValues={mapInitialValues(event)}/>
        }
      </Row>
    </Container>
  )
};

export default DentitionEventDetailsContainer;