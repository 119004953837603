/*
*   The animals mapper is use for mapping either the body or the response on a animal request. It try to decouple the
*   animals middleware.
* */

import i18n from '../../../translations/config/i18n';



const mapper =  {
    body: {
        addCertificate: (data, uploadedFilesAndUrls = {}) => {
            let result = {
                type: data.type.value,              
                foreignKey: data.foreignKey,
                entityType: data.entityType,
                file: data.file,
                checksumFile: data.checksumFile,
                toDate: data.toDate,
                fromDate: data.fromDate,
                detail: data.detail,
                _id: data._id,
            };
            
            if(uploadedFilesAndUrls.file) result['file'] = uploadedFilesAndUrls.file.fileUrl;            
            if(uploadedFilesAndUrls.file) result['checksumFile'] = uploadedFilesAndUrls.file.md5Hash;            
            return result;
        },
        editCertificate: (data, uploadedFilesAndUrls = {}) => {
            return mapper.body.addCertificate(data, uploadedFilesAndUrls)
        }
    },
    response: {  
        /*getFacilityCertificates: function(response) {            
            return {results:response};
        }*/
        getFacilityCertificates: (response) => ({
            results: response,            
        }),
        getCertificateById: (response) => {            
            let res = {...response};            
            res.type = {value:response.type,label:i18n.t(`common:certificates.${response.type}`)};                        
            return {
                results: res,            
            }
        },    
    },
    data: {        
        editCertificateFormFiles: (info) => {
            let files = [];

            // Check is need on file file because is not required.
            if(info.file) {
                // If the dna analysis is an string these means that the file didn't change ant it is already upload.
                if(typeof info.file !== 'string') {
                    files.push({
                        id: 'file',
                        file: info.file.files[0]
                    });
                }
            }            
            return files;
        }
    }
};

export default mapper;