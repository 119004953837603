import React, {useEffect, useState} from 'react';
import {Col, Card, CardBody, Button, ButtonToolbar} from 'reactstrap';
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Field, reduxForm} from 'redux-form';
import {connect} from "react-redux";
import actions from "../../../../redux/actions";
import MaskedInput from "react-text-mask";
import {CheckBoxField} from '../../../../shared/components/form/CheckBox';
import i18n from '../../../../translations/config/i18n';
import {useAlert} from "react-alert";
import CVButton from "../../../../shared/components/cv/CVButton";
import {formValidators, formMasks, subtract_x_years_to_date} from '../../../../util/index';
import { withRouter } from 'react-router-dom';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import {renderDateInputField} from "../../../../shared/components/cv/CVDatePicker";
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';
import "../../../../scss/component/Select2.css"
import caravanIdLogo from '../../../../shared/img/icon/caravanId.png';
import caravanIdRLogo from '../../../../shared/img/icon/caravanIdR.png';
import caravanEIdLogo from '../../../../shared/img/icon/caravanEId.png';
import caravanIncompleteLogo from '../../../../shared/img/icon/incomplete.png';

import Helpi from '../../../Helpi'

const renderField = ({input, disabled, placeholder, type, meta: {touched, error, warning}, mask}) => (
    <div style={{width: '100%'}}>
        {!mask && <input style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type}/>}
        {mask && <MaskedInput style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type} mask={mask}/>}
        <div className="input-error-container">
            {touched && ((error && <span className="error-message">{i18n.t(`common:errors.${error}`)}</span>) || (warning &&
                <span className="error-message">{warning}</span>))}
        </div>
    </div>
);

const validateDateRequired = formValidators.validateDateBetweenRequired(
    subtract_x_years_to_date(new Date(), 100), // 100 years ago date
    new Date(), // today date
);

const ChangeProductiveSystemEventForm = (props) => {
    ChangeProductiveSystemEventForm.propTypes = {
        t: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired
    };

    useEffect(() => {
        props.getAvailableProductiveSystems()
    }, []);

    const {t, reset, handleSubmit, onSubmit, establishmentProductiveSystems, editable,
        hideTitle, allAvailableProductiveSystems} = props;

    const [productiveSystemSelected, setProductiveSystemSelected] = useState({});

    const alert = useAlert();

    const [earIdValidatorMode, setEarIdValidatorMode] = useState(0);

    useEffect(() => {        
        initEarIdValidator(props.initialValues.caravan_id);        
    }, [props.initialValues]);

    const getValidatorsEarId = () => {                
        if (earIdValidatorMode==0) {            
            return [formValidators.validateRequired, formValidators.validateCaravanEIdRequired];
        } else if (earIdValidatorMode==1) {                        
            return [formValidators.validateRequired, formValidators.validateCaravanEIdRequired];
        } else if (earIdValidatorMode==2) {
            return [formValidators.validateRequired, formValidators.validateCaravanIdRequired];
        } else if (earIdValidatorMode==3) {
            return [formValidators.validateRequired, formValidators.validateCaravanIdRRequired];
        }                        
    }

    const initEarIdValidator = (earId) => {
        if (earId!=undefined) {
            let isnum = /^\d+$/.test(earId[1]);
            if (earId[0]=='0') {                        
                setEarIdValidatorMode(1);
            } else if (isnum) {
                setEarIdValidatorMode(3);
            } else {
                setEarIdValidatorMode(2);
            }
        }
    }

    const updateEarId = (e) => {        
        //e.preventDefault();
        
        if (e.currentTarget.value.length==0) {            
            setEarIdValidatorMode(0);
        } else if (e.currentTarget.value[0]=='0') {                        
            setEarIdValidatorMode(1);
        } else if (e.currentTarget.value[0]!='0') {
            setEarIdValidatorMode(2);
        }
        
        if (e.currentTarget.value.length>1 && e.currentTarget.value[0]!='0') {            
            let isnum = /^\d+$/.test(e.currentTarget.value[1]);
            if (isnum) {
                setEarIdValidatorMode(3);
            }
        }
        
        if (e.currentTarget.value.length>2) return;
        setTimeout(function(){
            if (e.currentTarget.value.length>1) return;
            var elem = document.getElementsByName("caravan_id")[0];
            let caretPos = 1;
            if(elem != null) {
                if(elem.createTextRange) {
                    var range = elem.createTextRange();
                    range.move('character', caretPos);
                    range.select();
                }
                else {
                    if(elem.selectionStart) {
                        elem.focus();
                        elem.setSelectionRange(caretPos, caretPos);
                    }
                    else
                        elem.focus();
                }
            }            
        },200);
        
        
    }

    const showErrors = (invalid) => {
        if(invalid) ToastsStore.error(t('common.general_invalid_form'), 10000)
    }

    const naturalFood = ["Falaris", "Festuca", "Cebadilla Criolla", "Agropiro", "Lotus", "Pastizal Natural", "Campo Natural", "Rastrojo de Girasol", "Rastrojo de Maiz", "Sales Minerales", "Campo Organico Patagonico"]
    const silvoPastoralFood = ["Raigras Inglesa", "Festuca", "Trébol Subterráneo"]
    const pastoralFood = ["Alfalfa", "Raigras Inglesa", "Raigras Perenne", "Raigras Binuales","Bromo" , "Falaris", "Festuca", "Pasto Ovillo", "Sorgo", "Trébol Alejandrino", "Trébol Blanco", "Pradera Consociada", "Verdeo Avena", "Verdeo de Centeno", "Ver. Avena / Ras. Maiz", "Trébol Melilotus", "Verdeo de Mijo", "Rollo de Pastura", "Rollo de Alfalfa", "Eragrostis Cúrvula (P. Llorón)", "Gatton Panic"]
    const creepFeedingFood=["Alfalfa", "Avena", "Raigras Inglesa", "Raigras Perenne", "Raigras Binuales","Bromo" , "Falaris", "Festuca", "Pasto Ovillo", "Trigo", "Cebada", "Centeno", "Maíz (Suplem. NO mayor a 1kg)", "Sorgo", "Trébol Rojo", "Cebadilla Criolla", "Cebadilla", "Agropiro", "Lotus", "Expeller de Girasol", "Verdeo Avena"]
    const strategicFeedingFood=["Avena", "Festuca", "Trigo", "Cebada", "Centeno", "Maíz (Suplem. NO mayor a 1kg)", "Sorgo", "Expeller de Girasol", "Verdeo Avena", "Silo de Pastura", "Silo de Maiz", "Silo de Sorgo", "Maiz Picado"]
    const feedlotFood=["Avena", "Trigo", "Cebada", "Centeno", "Maíz (Suplem. NO mayor a 1kg)", "Sorgo", "Expeller de Girasol", "Núcleo Vitamínico", "Silo de Pastura", "Silo de Maiz", "Silo de Sorgo", "Rollo de Pastura", "Rollo de Alfalfa", "Rollo de Mijo", "Maiz Picado", "Suplement. Feed Lot"]
    let [selectedData, setSelectedData] = useState();
    let [defaultSelectData, setDefaultSelectData] = useState();
    let selectedFoods = []

    function changeSelectedFood(e){
        selectedFoods = []
        for (var i = 0; i < e.currentTarget.length; i++) {
            if(e.currentTarget.options[i].selected){selectedFoods.push(e.currentTarget.options[i].value)}
        }
    }

    function searchTrueInProductiveSystem(object){
        let values = Object.values(object)
        let keys = Object.keys(object)
        let index = values.indexOf(true)
        select2Data(keys[index])
    }

    function select2Data(text){
        let res;
        if(text == "natural"){res =  naturalFood}
        if(text == "silvoPastoral"){res = silvoPastoralFood}
        if(text == "pastoral"){res = pastoralFood}
        if(text == "creepFeeding"){res = creepFeedingFood}
        if(text == "strategicFeeding"){res = strategicFeedingFood}
        if(text == "feedlot"){res = feedlotFood}
        if(text == "noneSystem"){res = []}

        setSelectedData(res)
        selectedFoods = []
    }

    let renderSelect2 = () => (
      <div style={{width: '100%'}}>
          <Select2
            className={"customSelect2"}
            multiple
            data={selectedData}
            onChange={changeSelectedFood}
            defaultValue={defaultSelectData}
            disabled={!editable ? true : false}
            options={
                {
                    placeholder: 'Alimentos',
                }
            }
          />
      </div>
    );

    // On cow details, sets the productive system selected.
    useEffect(() => {
        if(props.initialValues.productive_system) {
            setProductiveSystemSelected(props.initialValues.productive_system)
        }
        if(props.initialValues.feeding){
            searchTrueInProductiveSystem(props.initialValues.productive_system)
            setDefaultSelectData(props.initialValues.feeding)
        }

    }, [props.initialValues]);

    useEffect(() => {
        // Only if the form is on edit mode
        if(editable) {
            if(props.selectedEstablishment) {
                // Set the available productive systems depending on the establishment selected.
                if(props.selectedEstablishment.productiveSystems) {
                    let productiveSystems = {};
                    props.selectedEstablishment.productiveSystems.forEach((productiveSystem, index) => {
                        // Select the first value by default.
                        productiveSystems[`${productiveSystem}`] = index === 0;
                        if(productiveSystems[`${productiveSystem}`]){
                            select2Data(productiveSystem)
                        }
                    });
                    setProductiveSystemSelected(productiveSystems)
                  }
            }
            else alert.info(t('cv-forms.cow.you_need_to_select_an_establishment'), {timeout: 0})
        }
    }, [props.selectedEstablishment]);

    const selectProductiveSystem = (productiveSystem) => {
        let newValue = {
            ...productiveSystemSelected
        };

        // set all productive systems to false
        Object.keys(newValue).forEach(key => {
            newValue[`${key}`] = false
        });

        newValue[`${productiveSystem}`] = true;
        searchTrueInProductiveSystem(newValue)
        setProductiveSystemSelected(newValue);
    };

    const noneProductiveSystemsSelected = (productiveSystems) => {
        let result = true;
        
        establishmentProductiveSystems.forEach(productiveSystem => {
            if(productiveSystems[`${productiveSystem}`]) {
                return result = false;
            }
        });
        return result;
    };

    const submitForm = (values) => {
        values.establishment = props.selectedEstablishment;
        if(noneProductiveSystemsSelected(productiveSystemSelected)) return;
        onSubmit({...values, productiveSystems: productiveSystemSelected, selectedFoods: selectedFoods});
    };

    return (
        <Col md={12} lg={12}>
            <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
            <Card>
                <CardBody>
                    <div className="card__title">
                        {!hideTitle && <h5 className="bold-text">{t('cv-forms.events.change_productive_system.title')}</h5>}
                    </div>
                    <form className="form" onSubmit={handleSubmit(submitForm)}>
                    <div className="edit-buttons" >
                          <ButtonToolbar className="form__button-toolbar" >
                                  { !editable && <Button 
                                      color="primary" 
                                      outline={true} 
                                      type="button" 
                                      onClick={() => props.history.goBack()}>
                                      {t('common.back')}</Button>
                                  }
                          </ButtonToolbar>
                    </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.events.change_productive_system.caravan_id')}</span>
                            <div className="form__form-group-field">
                                {
                                    (earIdValidatorMode==0) &&
                                    <img style={{width:"30px",height:"30px"}} src={caravanIncompleteLogo}/>
                                }
                                {
                                    (earIdValidatorMode==1) &&
                                    <img style={{width:"30px",height:"30px"}} src={caravanEIdLogo}/>
                                }
                                {
                                    (earIdValidatorMode==2) &&
                                    <img style={{width:"30px",height:"30px"}} src={caravanIdLogo}/>
                                }
                                {
                                    (earIdValidatorMode==3) &&
                                    <img style={{width:"30px",height:"30px"}} src={caravanIdRLogo}/>
                                }                                
                                &nbsp;
                                <Field
                                    name="caravan_id"
                                    component={renderField}
                                    validate={getValidatorsEarId()}                                    
                                    type="text"
                                    placeholder={t('cv-forms.events.change_productive_system.caravan_id')}
                                    mask={(earIdValidatorMode==1)?formMasks.caravanEIdMask:(earIdValidatorMode==2)?formMasks.caravanIdMask:(earIdValidatorMode==3)?formMasks.caravanIdMaskR:null}
                                    onChange={updateEarId}
                                    normalize={(value) => value.toUpperCase()}
                                    disabled={!editable}
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.events.change_productive_system.date')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="date"
                                    component={renderDateInputField}
                                    validate={[validateDateRequired]}
                                    disabled={!editable}
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span
                                className="form__form-group-label">{t('cv-forms.events.change_productive_system.new_productive_system')}</span>
                            <div className="form__form-group-field">
                                {
                                    editable &&
                                    establishmentProductiveSystems.map((productiveSystem, index) => (
                                        <div style={{marginLeft: index === 0 ? '0px' : '20px',display:"flex"}}>
                                            <CheckBoxField
                                                onChange={() => selectProductiveSystem(productiveSystem)}
                                                value={productiveSystemSelected[`${productiveSystem}`]}
                                                disabled={!editable}
                                                name={`productive_system.${productiveSystem}`}
                                                label={
                                                    <div>
                                                    {t(`cv-forms.productive_establishment.productive_system_options.${productiveSystem}`)}
                                                    &nbsp;                                                    
                                                    </div>
                                                }
                                            />
                                            <Helpi 
                                                        title={t(`cv-forms.productive_establishment.productive_system_options_helpis.${productiveSystem}`)} 
                                                        size="20"
                                                        iconColor="lightgrey"
                                                        fontColor="white"/> 
                                        </div>
                                    ))
                                }
                                {
                                    !editable &&
                                    establishmentProductiveSystems.map((productiveSystem, index) => (
                                        <div style={{marginLeft: index === 0 ? '0px' : '20px'}}>
                                            <CheckBoxField
                                                onChange={() => selectProductiveSystem(productiveSystem)}
                                                value={props.initialValues.productive_system[`${productiveSystem}`]}
                                                disabled={!editable}
                                                name={`productive_system.${productiveSystem}`}
                                                label={t(`cv-forms.productive_establishment.productive_system_options.${productiveSystem}`)}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                editable && noneProductiveSystemsSelected(productiveSystemSelected) &&
                                <div className="input-error-container">
                                        <span className="error-message">
                                            {t(`errors.REQUIRED`)}
                                        </span>
                                </div>
                            }
                        </div>
                        {
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.events.change_productive_system.feeding')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="feeding"
                                    component={renderSelect2}
                                    type="text"
                                    placeholder={t('cv-forms.events.change_productive_system.feeding')}
                                    disabled={!editable}
                                    /*value={props.initialValues.feeding}*/
                                />
                            </div>
                        </div>
                        }

                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.events.common_fields.comments')}</span>
                            <div className="form__form-group-field">
                                <Field
                                  name="comments"
                                  component={renderField}
                                  disabled={!editable}
                                />
                            </div>
                        </div>
                        <ButtonToolbar className="form__button-toolbar">
                            {
                                editable &&
                                <CVButton
                                    color="primary"
                                    type="submit"
                                    onClick={()=> showErrors(props.invalid)}
                                    pending={props.pending}>
                                        {t('common.accept')}
                                </CVButton>
                            }
                            {
                                editable &&
                                <Button type="button" onClick={() => props.history.push('/animals/table')}>
                                        {t('common.cancel')}
                                </Button>
                            }

                        </ButtonToolbar>
                    </form>
                </CardBody>
            </Card>
        </Col>)
};

const mapStateToProps = (state, props) => ({
    initialValues: props.initialValues || {},
    establishmentProductiveSystems: state.user.selectedEstablishment ?
        state.user.selectedEstablishment.productiveSystems ?
            state.user.selectedEstablishment.productiveSystems:
            []:
        [],
    selectedEstablishment: state.user.selectedEstablishment,
    allAvailableProductiveSystems: state.constant.availableProductiveSystems
});

const mapDispatchToProps = (dispatch) => ({
    getAvailableProductiveSystems: () => dispatch(actions.constant.getAvailableProductiveSystems())
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'change-productive-system-event-form',
    touchOnChange: true // this is set because if not the 'touched' props on DateInputField was always false
})(withTranslation('common')(withRouter(ChangeProductiveSystemEventForm))));