import {
    ACCEPT_ROL_RESPONSE, FILTER_NOTIFICATIONS_RESPONSE,
    GET_NOTIFICATION_PAGE_RESPONSE, REJECT_ROL_RESPONSE, SEARCH_NOTIFICATIONS_RESPONSE
} from "../actions/notificationActions";

const initialState = {
    notifications: [],
    page: 1,
    totalPages: 0,
    total: 0,
    pageSize: 0,
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTIFICATION_PAGE_RESPONSE:
        case SEARCH_NOTIFICATIONS_RESPONSE:
        case FILTER_NOTIFICATIONS_RESPONSE:
            return {
                ...state,
                ...action.response
            };
        case ACCEPT_ROL_RESPONSE:
        case REJECT_ROL_RESPONSE:
            return {
                ...state,
                notifications: state.notifications.filter((notification) => notification.id !== action.response.notificationId)
            };
        default:
            return state
    }
};
export default notificationReducer;