import React, {useEffect, useState} from 'react';
import { withTranslation } from "react-i18next";
import CVTable from "../../Tables/CVTable/CVTable";
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import { connect } from "react-redux";
import actions from '../../../redux/actions/index'
import { withRouter } from 'react-router-dom';
import MagnifyIcon from "mdi-react/MagnifyIcon";
import InfoIcon from "mdi-react/InfoCircleOutlineIcon"
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import AccountPlusOutlineIcon from 'mdi-react/AccountPlusOutlineIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import CVModal from "../../UI/Modals/components/CVModal";
import {useAlert} from "react-alert";
import {ROLES} from "../../../constants";
import { Tooltip } from 'react-tippy';
import {CVBasicModalWithoutButtons} from "../../../shared/components/cv/CVBasicModal";
import SurrogateList from "./SurrogateList";
import AddNewSurrogate from "./AddNewSurrogate";
import CVButton from "../../../shared/components/cv/CVButton";
import './accountTable.css'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import setting from '../../../util/settings';


import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TooltipM from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const AccountTable = (props) => {
    const { t, accounts, widthPercentage, currentPage, total, totalPages, pageSize, hideAddNewCompanyButton} = props;
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [accountToDelete, setAccountToDelete] = useState();
    const [showAddUserToAccountModal, setShowAddUserToAccountModal] = useState(false);
    const [accountSelected, setAccountSelected] = useState();

    const alert = useAlert();

    useEffect(()=> {
        console.log("debug",accounts);
    },[accounts]);

    useEffect(() => {
        if(props.userRoles.some((rol) => rol.name === ROLES.TITULAR)) props.getAccounts(1, 10)
    }, []);

    useEffect(() => {
        if(props.userRoles.some((rol) => rol.name === ROLES.SURROGATE)) props.getSurrogateAccounts(1, 10)
    }, []);

    const renderValueDefault = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value}
            </div>
        )
    };

    const renderTierValue = ({value}) => {
        
        let parsedValue = value.split("|");
        let name = "-";
        let max = "-";
        if (parsedValue.length==2) {
            name=parsedValue[0];
            max=parsedValue[1];
        }
    
       
        const HtmlTooltip = withStyles((theme) => ({
            tooltip: {
              backgroundColor: '#f5f5f9',
              color: 'rgba(0, 0, 0, 0.87)',
              maxWidth: 450,
              fontSize: theme.typography.pxToRem(12),
              border: '1px solid #dadde9',
            },
          }))(TooltipM);

        const handleLink = () => {
            const newWindow = window.open(setting.urlPricing, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }

        return (
            
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>                
                {name}&nbsp;    
                <HtmlTooltip
                    interactive                    
                    
                    title={
                    <React.Fragment>
                        Actualmente tu empresa está trabajando<br></br> con el plan <b>{name}</b>,
                        con el cual tenés permitido<br></br> operar hasta con <b>{max}</b> animales activos.<br></br><br></br>
                        Si te interesa conocer más sobre nuestros planes<br></br> podés hacer click <a style={{cursor:"pointer", color:"black;", fontWeight:"weight"}} onClick={handleLink}><b>AQUI</b></a>                         
                    </React.Fragment>
                    }
                >
                    <p><InfoIcon/></p>
                </HtmlTooltip>
                
            </div>
            
        )
    };

    const isTitularRole = () => {
        return props.userRoles.some((rol) => rol.name === ROLES.TITULAR)
    };

    const renderDate = ({value}) => {
        const date = new Date(value);
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {`${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`}
            </div>
        )
    };

    const renderAddSurrogateToCompanyBody = () => (
        <div>
            <SurrogateList companyId={accountSelected}/>
            <AddNewSurrogate companyId={accountSelected}/>
            <br/>
            <CVButton 
                                  color="primary"
                                  type="submit"
                                  onClick={()=> {setShowAddUserToAccountModal(false)}}
                                  >Cerrar
                                </CVButton>
        </div>
    );

    const addSurrogateToCompanyModal = () => (
        <CVBasicModalWithoutButtons
            title={t('accounts.modals.add_surrogate_to_company.title')}
            body={renderAddSurrogateToCompanyBody}
            isOpen={showAddUserToAccountModal}
            toggleModal={() => {
                if(showAddUserToAccountModal) props.resetSurrogateUserSearch();
                setShowAddUserToAccountModal(!showAddUserToAccountModal)
            }}
        />
    );

    const renderButtons = ({ dependentValues }) => {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <MagnifyIcon
            className={"button"}
            style={{ marginLeft: "10px" }}
            onClick={() =>
              props.history.push("/manage_accounts/account_details", {
                companyId: dependentValues._id,
                userRoles: props.userRoles
              })
            }
          />
          {isTitularRole() ? (
            <Tooltip
              title="Agregar delegados"
              position="top"
              trigger="mouseenter"
              animation="scale"
              duration="600"
              size="small"
              theme="transparent"
              distance="0"
            >
              <AccountPlusOutlineIcon
                className={"button"}
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  setAccountSelected(dependentValues._id);
                  setShowAddUserToAccountModal(true);
                }}
              />
            </Tooltip>
          ) : null}
          {isTitularRole() ? (
          <CloseCircleIcon
            className={"button"}
            style={{ marginLeft: "10px" }}
            color={"red"}
            onClick={() => {
              setAccountToDelete({ name: dependentValues.legalName, _id: dependentValues._id});
              setShowDeleteModal(!showDeleteModal);
            }}
          />
          ) : null}
        </div>
      );
    };

    const renderIconsForAddingARole = () => {
        let rolesWithPermissionToAddCompanies = props.userRoles.filter((rol) => (rol.name === ROLES.TITULAR));
        if(rolesWithPermissionToAddCompanies.length > 0) {
            return (
                <div>
                    <PlusCircleOutlineIcon onClick={() => props.history.push('/manage_accounts/new_account')}/>
                </div>
            )
        }
        else return null;
    };

    const heads = [
        {
            key: 'legalName',
            name: t('accounts.table.headers.account_name'),
            percentage: 25,
            minWidth: 160,
            formatter: renderValueDefault
        },
        {
            key: 'tierName',
            name: t('accounts.table.headers.tier'),
            percentage: 25,
            minWidth: 180,
            formatter: renderTierValue
        },
        {
            key: 'createdAt',
            name: t('accounts.table.headers.creation_date'),
            percentage: 25,
            minWidth: 155,
            formatter: renderDate
        },        
        {
            key: 'actions',
            name: t('accounts.table.headers.actions'),
            percentage: 25,
            minWidth: 135,
            formatter: renderButtons,
            getRowMetaData: (row) => row
        }
    ];

    const renderDeleteAccountBody = () => (
        accountToDelete ?
            <div>
                {t('accounts.modals.delete_account.body', { account_name: accountToDelete.name })}
            </div>:
            null
    );

    const renderDeleteAccountModal = () => (
        <CVModal
            title={t('accounts.modals.delete_account.title')}
            body={() => renderDeleteAccountBody()}
            color="danger"
            header
            isOpen={showDeleteModal}
            toggle={() => setShowDeleteModal(!showDeleteModal)}
            acceptFunc={() => {
                props.deleteUserCompanyById(accountToDelete._id,
                //callback
                () => {
                    setShowDeleteModal(false);
                    alert.success(t('accounts.alerts.account_delete_successfully'),{timeout: 3000});
                },
                //errorCallback
                ()=>{
                    setShowDeleteModal(false);
                    alert.error(t('accounts.alerts.account_deleted_not_empty'),{timeout: 0});
                })
            }}
        />
    );

    const getPage = (page, pageSize) => {
        props.getAccounts(page, pageSize)
    };

    return(
        <div className={"accountTable"}>
            <CVTable
                getPage={getPage}
                data={accounts}
                total={total}
                totalPages={totalPages}
                currentPage={currentPage ? currentPage : 1}
                pageSize={pageSize}
                heads={heads}
                title={t('accounts.table.title')}
                hideFilters
                SubtitleComponent={renderIconsForAddingARole}
                widthPercentage={widthPercentage}
                noDataLabel={t('accounts.table.no_data_label')}
                pending={props.getUserCompaniesPending}
                userRole={props.userRoles}
            />
            {renderDeleteAccountModal()}
            {addSurrogateToCompanyModal()}
        </div>
    )

};

const mapStateToProps = (state) => ({
    accounts: state.user.accounts.results,
    currentPage: state.user.accounts.page,
    total: state.user.accounts.total,
    totalPages: state.user.accounts.totalPages,
    pageSize: state.user.accounts.pageSize,
    getUserCompaniesPending: state.ui.getUserCompaniesPending,
    getUserCompaniesError: state.ui.getUserCompaniesError,
    userRoles: state.user.roles
});

const mapDispatchToProps = (dispatch) => ({
    getAccounts: (page, pageSize) => dispatch(actions.user.getAccounts(page, pageSize)),
    getSurrogateAccounts: (page, pageSize) => dispatch(actions.user.getSurrogateAccounts(page, pageSize)),
    deleteUserCompanyById: (companyId, callback, errorCallback) => dispatch(actions.user.deleteUserCompanyById(companyId, callback, errorCallback)),
    resetSurrogateUserSearch: () => dispatch(actions.user.resetSurrogateUserSearch())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(AccountTable)));