import {nanoid} from 'nanoid'
import { POST, PUT, GET, DELETE } from "./methods";
import { GENERIC_ERROR } from "./errors";
import { sessionErrors } from "./errors";
import { animalErrors } from "./errors";
import { eventErrors } from "./errors";
import { getAnimalsByLot } from "../../api/api.mixed";

class RouteError {
    constructor(message, cause){
        this.message = message;
        this.cause = cause;
    };

    static generic(cause) {
        return new RouteError(GENERIC_ERROR, cause)
    }
}

class RouteErrorMapper {
    constructor(statusCode, errorCode, message) {
        this.statusCode = statusCode;
        this.errorCode = errorCode;
        this.message = message;
    }
}

class Route {
    constructor(method, url, errors = []) {
        this.method = method;
        this.url = url;
        this.errors = errors;
    }

    parseError(error) {
        const sameStatusCode = this.errors.filter(e => e.statusCode === error.statusCode);
        if(sameStatusCode.length === 0) {
            return RouteError.generic(error);
        }
        else {
            const sameErrorCode = sameStatusCode.filter(e => (e.errorCode === error.errorCode));
            return new RouteError(sameErrorCode[0].message, error)
        }
    }
}

//  Session routes
const login = new Route(POST, '/users/login', [
    new RouteErrorMapper(404, "USR004", sessionErrors.USER_NOT_FOUND_IN_THE_SYSTEM),
    new RouteErrorMapper(401, "USR007", sessionErrors.WRONG_CREDENTIALS),
    new RouteErrorMapper(500, "USR006", sessionErrors.EMAIL_NOT_VERIFIED),
    new RouteErrorMapper(403, "USR006", sessionErrors.EMAIL_NOT_VERIFIED),
]);

const refresh = new Route(POST, '/users/refresh', [
    new RouteErrorMapper(404, "USR004", sessionErrors.USER_NOT_FOUND_IN_THE_SYSTEM),
    new RouteErrorMapper(401, "USR007", sessionErrors.WRONG_CREDENTIALS),
    new RouteErrorMapper(500, "USR006", sessionErrors.EMAIL_NOT_VERIFIED)
]);

const basicUserRegister = new Route(POST, '/users', [
    new RouteErrorMapper(409, "USR006", sessionErrors.USER_ALREADY_REGISTER),
    new RouteErrorMapper(500, "USR008", sessionErrors.USER_ALREADY_REGISTER_EMAIL_NOT_VERIFIED),
    
]);

const emailConfirmation = (token) => new Route(POST, `/users/confirmation/${token}`, []);
const resendEmailConfirmation = (email) => new Route(POST, `/users/confirmationResend/${email}`, []);
const passwordRecovery = (email) => new Route(POST, `/users/recover/${email}`, []);
const resetPassword = (userId, token) => new Route(PUT, `/users/resetPassword/${userId}/${token}`, []);
const sendEmailTier = () => new Route(POST, `/users/sendEmailTier`, []);


//router.post('/api/users/recover/:userEmail', controller.sendPasswordRecovery);



//  User routes
const getUser = () => new Route(GET, '/users/profile', []);
const editUserData = (userId) => new Route(PUT, `/users/${userId}`, []);
const addRole = (userId, role) => new Route(PUT, `/users/${userId}/role/${role}`, []);
const getProfessionalUserByDni = (dni) => new Route(GET, `/users/professionals/${dni}`, []);
const getAssignedEstablishments = (userId) => new Route(GET, `/users/${userId}/facilities?page=1&limit=1000`, []);
const getAssignedCompanies = (userId) => new Route(GET, `/users/${userId}/companies`, []);
const getSurrogateUserByDni = (dni) => new Route(GET, `/users/surrogates/${dni}`, []);
const getCountUserFacilitiesByType = (userId) => new Route(GET, `/users/countFacilitiesByType/${userId}`, []);
const getUserNotifications = () => new Route(GET, `/users/notifications/`, []);

// Constant routes
const getAvailableRoles = () => new Route(GET, '/constants/users/roles', []);
const getAvailableEntityTypes = () => new Route(GET, '/constants/companies/entity/types', []);
const getAvailableProductiveSystems = () => new Route(GET, '/constants/facilities/productive/productiveSystems', []);
const getFacilitiesProductiveTypes = () => new Route(GET, '/constants/facilities/productive/types', []);
const getAvailableProductiveSystemsFacilityType = (facilityType) => new Route(GET, `/constants/facilities/productive/productiveSystems/${facilityType}`, []);
const getAvailableGroundConditions = () => new Route(GET, '/constants/facilities/productive/groundConditions', []);
const getAvailableEstablishmentTypes = () => new Route(GET, '/constants/facilities/types', []);
const getAvailableRucaActivities = () => new Route(GET, '/constants/rucaActivities', []);
const getDentitionValues = () => new Route(GET, '/constants/dentition', []);
const getDeletionReasonValues = () => new Route(GET, '/constants/deletionReason', []);
const getAvailableStates = () => new Route(GET, '/constants/statesValues', []);


//  Companies routes
const getUserCompaniesPaginated = (page, limit) => new Route(GET, `/companies?page=${page}&limit=${limit}&byRole=true`, []);
const getSurrogateUserCompaniesPaginated = (page, limit) => new Route(GET, `/companies/surrogateUser?page=${page}&limit=${limit}&byRole=true`, []);
const addUserCompany = () => new Route(POST, `/companies`, []);
const getUserCompanyById = (companyId) => new Route(GET, `/companies/${companyId}`, []);
const editCompanyById = (companyId) => new Route(PUT, `/companies/${companyId}`, []);

// todo delete this hard-code route when the route for getting all companies's name and ids is finish.
const getAllUserCompanies = () => new Route(GET, `/companies?page=1&limit=all&byRole=true`, []);
const deleteUserCompanyById = (companyId) => new Route(DELETE, `/companies/${companyId}`, []);
const getUserEstablishmentsByCompany = (companyId) => new Route(GET, `/companies/${companyId}/facilities?limit=all`, []);
const addUserToCompany = (companyId, userId) => new Route(POST, `/companies/${companyId}/user/${userId}`, []);
const deleteUserFromCompany = (companyId, userId) => new Route(DELETE, `/companies/${companyId}/user/${userId}`, []);

//  Establishment routes
const addEstablishmentToCompany = (establishmentType) => new Route(POST, `/facilities/${establishmentType}`, []);
const addEstablishmentLot = (establishmentId) => new Route(POST, `/facilities/${establishmentId}/animalLot`, []);
const editEstablishmentLot = (establishmentId) => new Route(PUT, `/facilities/${establishmentId}/animalLot`, [])
const getUserEstablishmentsPaginated = (page, limit) => new Route(GET, `/facilities?page=${page}&limit=${limit}&byRole=true`, []);

const getUserEstablishmentsByCompaniesPaginated = (page, limit) => new Route(GET, `/facilities?page=${page}&limit=${limit}&byCompanies=true`, []);

const getSurrogateUserEstablishmentsPaginated = (page, limit) => new Route(GET, `/facilities/surrogateUser?page=${page}&limit=${limit}&byRole=true`, []);
const getCompanyScopeAnimalsCount = (facilityId) => new Route(GET, `/facilities/${facilityId}/companyScopeAnimalsCount`, []);

const getHasSlaughterhouseOrCommercial = (userId, role) => new Route(GET, `/facilities/hasSlaughterhouseOrCommercial/role/${role}`, []);

// todo delete this hard-code route when the route for getting all establishment's name and ids is finish.
const getAllUserEstablishments = () => new Route(GET, `/facilities?page=1&limit=all&byRole=true`, []);
const getAllEstablishments = () => new Route(GET, `/facilities?page=1&limit=all`, []);
const getUserEstablishmentById = (establishmentId) => new Route(GET, `/facilities/${establishmentId}`, []);
const getEstablishmentByCode = (code) => new Route(GET, `/facility/byCode/${code}`, []);
const getLotsByEstablishmentId = (establishmentId) => new Route(GET, `/facilities/${establishmentId}/animalLots`, []);
const editUserEstablishmentById = (establishmentId) => new Route(PUT, `/facilities/${establishmentId}`, []);
const deleteUserEstablishmentById = (establishmentId) => new Route(DELETE, `/facilities/${establishmentId}`, []);
const deleteUserEstablishmentLotById = (establishmentLotId) => new Route(DELETE, `/facilities/animalLot/${establishmentLotId}`, []);
const getEstablishmentAnimals = (establishmentId, lotId, page, limit) => new Route(GET, `/facilities/${establishmentId}/${lotId}/animals?page=${page}&limit=${limit}`, []);
const getEstablishmentAnimalsForExport = (establishmentId, lotId, page, limit) => new Route(GET, `/facilities/${establishmentId}/${lotId}/animalsForExport?page=${page}&limit=${limit}`, []);
const addProfessionalToEstablishment = (establishmentId, userId, role) => new Route(POST, `/facilities/${establishmentId}/user/${userId}/role/${role}`, []);
const deleteProfessionalFromEstablishment = (establishmentId, userId, role) => new Route(DELETE, `/facilities/${establishmentId}/user/${userId}/role/${role}`, []);
const sendMailToEstablishment = (establishmentId, userId) => new Route(POST, `/facilities/${establishmentId}/user/${userId}`, []);
const sendMailToUnregistered = (establishmentId, email) => new Route(POST, `/facilities/${establishmentId}/unregistered/${email}`, []);
const sendMailToUnregisteredSurrogate = (companyId, email) => new Route(POST, `/companies/${companyId}/unregistered/${email}`, []);

// Animal routes
const addAnimal = (animalType) => new Route(POST, `/animals/${animalType}`, []);
const getUserAnimalsPaginated = (page, limit) => new Route(GET, `/animals/?page=${page}&limit=${limit}&byRole=true`, []);
const getUserAnimalById = (animalId) => new Route(GET, `/animals/${animalId}`, []);
const massiveAnimalUpload = (establishmentId, animalType) =>
    new Route(POST, `/facilities/${establishmentId}/animals/excel/${animalType}`, []);
const acceptMassiveAnimalUpload = (excelId) => new Route(POST, `/animals/fromExcel/${excelId}`, [
    new RouteErrorMapper(409, "XSL003", animalErrors.EXCEL_HAS_NO_VALID_ROWS)
]);
const editAnimalById = (animalId) => new Route(PUT, `/animals/${animalId}`, []);
const getFacilityAnimalsToExcel = (establishmentId,lotId) => new Route(GET, `/facilities/${establishmentId}/${lotId}/animalsToExcel/?limit=all`, []);
const getGenealogy = () => new Route(POST, `/animal/genealogy`, []);
const getAnimalsHistoryByLot = (lotId,facilityId) => new Route(GET, `/facilities/animalLot/${lotId}/facility/${facilityId}`, []);
const getValidateStockProductiveSystemType = () => new Route(POST, `/facilities/validateStockProductiveSystemType`, []);

// //  Qr Generation
const getQrsGeneratedByFacilityPage = (establishmentId, page, limit) => new Route(GET, `/qrGeneration/${establishmentId}?page=${page}&limit=${limit}`, []);
const getQrsTemplates = (establishmentId) => new Route(GET, `/qrGeneration/templates/${establishmentId}`, []);
const addQrGeneration = () => new Route(POST, `/qrGeneration/addQr`, []);
const editQrGeneration = (qrId) => new Route(POST, `/qrGeneration/editQr/${qrId}`, []);
const getQrsGeneratedById = (qrId) => new Route(GET, `/qrGeneration/qr/${qrId}`, []);
const getRelatedImagesByLot = (lotId) => new Route(GET,`/facilities/animalLot/${lotId}`, []);



// Image routes
const uploadImage = () => new Route(PUT, `/images`, []);

// Event routes
// todo confirm this route.
const addAnimalEvent = (eventType) => new Route(POST, `/animals/event/${eventType}`, []);
const getEventsPaginated = (establishmentId, page, limit, eventType) => new Route(GET, `/facilities/${establishmentId}/events/${eventType}/?page=${page}&limit=${limit}`, []);
const getAllGlobalTransferEvents = (establishmentId) => new Route(GET,`/getGlobalTransfersByFacilityId/${establishmentId}`, [])
const getEventsExcel = (establishmentId,eventType) => new Route(GET, `/facilities/${establishmentId}/eventsToExcel/${eventType}/`, []);
const massiveEventUpload = (establishmentId, eventType) => new Route(POST, `/facilities/${establishmentId}/events/excel/${eventType}`, []);
const acceptMassiveEventUpload = (excelId, eventType) => new  Route(POST, `/animals/events/fromExcel/${excelId}/${eventType}`,[
    new RouteErrorMapper(409, "XSL003", eventErrors.EXCEL_HAS_NO_VALID_ROWS)
]);

// Global Event Routes
// const addThirdPartySlaughterEvent = (globalEventType) => new Route(POST, `/GlobalEvents/${globalEventType}`, []);
const addThirdPartySlaughterEvent = () => new Route(POST, `/GlobalEvents`, []);
const getAllGlobalEvent= (userId, globalEventName, facilityId) => 
        new Route(GET, `/allGlobalEvents/user/${userId}/globalEventName/${globalEventName}/facility/${facilityId}`, []);
const getLotsWithAnimalsByFacilityId = (facilityId) => new Route(GET, `/facilities/${facilityId}/animalLotsWithAnimalsCount`, []);
const getGlobalEventByIdThirdPartySlaughter = (globalEventId) => new Route(GET, `/globalEventById/${globalEventId}`, []);
const getLotsInGlobalEvents = (facilityId,globalEventName) => new Route(GET, `/getLotsInGlobalEvents/facility/${facilityId}/globalEventName/${globalEventName}`)
const getLotAverageWeight = (lotId) => new Route(GET, `/calculateLotAverageWeight/${lotId}`, []);

// BI routes
const getBI = () => new Route(POST, `/bi/getDashboard`, []);

// CERTIFICATE Routes
const addCertificate = () => new Route(POST, `/certificates`, []);
const getFacilityCertificates = (facility) => new Route(GET, `/certificates/facility/${facility}`, []);
const getCertificateById = (id) => new Route(GET, `/certificates/${id}`, []);


// CONNECTION STATUS routes
const getConnectionStatus = ()=> new Route( GET, `/checkConnection/${nanoid(12)}`, [])
// External services routes
const getSRAConnectionStatus = () => new Route(POST, `/sraService/connectionStatus/${nanoid(12)}`, []);



/*
Todo (note):
    + Now the 'fullRegister' and the 'editUserData' are the same PUT request, in the future this may change.
*/
export const sessionRoutes = {
    login,
    refresh,
    basicUserRegister,
    fullRegister: (userId) => editUserData(userId),
    emailConfirmation: (token) => emailConfirmation(token),
    passwordRecovery: (email) => passwordRecovery(email),
    resetPassword: (userId, token) => resetPassword(userId, token),
    resendEmailConfirmation: (email) => resendEmailConfirmation(email),
    sendEmailTier
};

export const userRoutes = {
    editUserData: (userId) => editUserData(userId),
    getUser,
    addRole: (userId, role) => addRole(userId, role),
    getProfessionalUserByDni: (dni) => getProfessionalUserByDni(dni),
    getAssignedEstablishments: (userId) => getAssignedEstablishments(userId),
    getAssignedCompanies: (userId) => getAssignedCompanies(userId),
    getSurrogateUserByDni: (dni) => getSurrogateUserByDni(dni),
    getCountUserFacilitiesByType: (userId) => getCountUserFacilitiesByType(userId), 
    getUserNotifications: () => getUserNotifications()
};

export const constantRoutes = {
    getAvailableRoles,
    getAvailableEntityTypes,
    getAvailableProductiveSystems,
    getFacilitiesProductiveTypes,
    getAvailableProductiveSystemsFacilityType,
    getAvailableEstablishmentTypes,
    getAvailableGroundConditions,
    getAvailableRucaActivities,
    getDentitionValues,
    getDeletionReasonValues,
    getAvailableStates
};

export const companyRoutes = {
    getUserCompaniesPaginated: (page, limit) => getUserCompaniesPaginated(page, limit),
    getSurrogateUserCompaniesPaginated: (page, limit) => getSurrogateUserCompaniesPaginated(page, limit),
    addUserCompany,
    getUserCompanyById: (companyId) => getUserCompanyById(companyId),
    getCompanyScopeAnimalsCount: (facilityId) => getCompanyScopeAnimalsCount(facilityId),
    editUserCompanyById: (companyId) => editCompanyById(companyId),
    getAllUserCompanies,
    deleteUserCompanyById: (companyId) => deleteUserCompanyById(companyId),
    getUserEstablishmentsByCompany: (companyId) => getUserEstablishmentsByCompany(companyId),
    addUserToCompany: (companyId, userId) => addUserToCompany(companyId, userId),
    deleteUserFromCompany: (companyId, userId) => deleteUserFromCompany(companyId, userId),
    sendMailToUnregisteredSurrogate: (companyId, email) => sendMailToUnregisteredSurrogate(companyId, email)
};

export const establishmentRoutes = {
    addEstablishmentToCompany: (establishmentType) => addEstablishmentToCompany(establishmentType),
    addEstablishmentLot: (establishmentId) => addEstablishmentLot(establishmentId),
    editEstablishmentLot: (establishmentId) => editEstablishmentLot(establishmentId),
    getUserEstablishmentsPaginated: (page, limit) => getUserEstablishmentsPaginated(page, limit),
    getUserEstablishmentsByCompaniesPaginated: (page, limit) => getUserEstablishmentsByCompaniesPaginated(page, limit),
    getSurrogateUserEstablishmentsPaginated: (page, limit) => getSurrogateUserEstablishmentsPaginated(page, limit),
    getEstablishmentByCode: (code) => getEstablishmentByCode(code),
    getLotsByEstablishmentId: (establishmentId) => getLotsByEstablishmentId(establishmentId),
    getAllUserEstablishments,
    getAllEstablishments,
    getUserEstablishmentById: (establishmentId) => getUserEstablishmentById(establishmentId),
    getHasSlaughterhouseOrCommercial: (userId, role) => getHasSlaughterhouseOrCommercial(userId, role),
    editUserEstablishmentById: (establishmentId) => editUserEstablishmentById(establishmentId),
    deleteUserEstablishmentById: (establishmentId) => deleteUserEstablishmentById(establishmentId),
    deleteUserEstablishmentLotById: (establishmentLotId) => deleteUserEstablishmentLotById(establishmentLotId),
    getEstablishmentAnimals: (establishmentId, lotId, page, limit) => getEstablishmentAnimals(establishmentId, lotId, page, limit),
    getEstablishmentAnimalsForExport: (establishmentId, lotId, page, limit) => getEstablishmentAnimalsForExport(establishmentId, lotId, page, limit),
    addProfessionalToEstablishment: (establishmentId, userId, rol) => addProfessionalToEstablishment(establishmentId, userId, rol),
    deleteProfessionalFromEstablishment: (establishmentId, userId, role) => deleteProfessionalFromEstablishment(establishmentId, userId, role),
    sendMailToEstablishment: (establishmentId, userId) => sendMailToEstablishment(establishmentId, userId),
    sendMailToUnregistered: (establishmentId, email) => sendMailToUnregistered(establishmentId, email)
};

export const animalRoutes = {
    addAnimal: (animalType) => addAnimal(animalType),
    getUserAnimalsPaginated: (page, limit) => getUserAnimalsPaginated(page,limit),
    getUserAnimalById: (animalId) => getUserAnimalById(animalId),
    massiveAnimalUpload: (establishmentId, animalType) => massiveAnimalUpload(establishmentId, animalType),
    acceptMassiveAnimalUpload: (excelId) => acceptMassiveAnimalUpload(excelId),
    editAnimalById: (animalId) => editAnimalById(animalId),
    getFacilityAnimalsToExcel: (establishmentId,lotId)=> getFacilityAnimalsToExcel(establishmentId,lotId),
    getGenealogy: () => getGenealogy(),
    getAnimalsHistoryByLot: (lotId,facilityId) => getAnimalsHistoryByLot(lotId,facilityId),
    getValidateStockProductiveSystemType: () => getValidateStockProductiveSystemType()
};

export const imageRoutes = {
    uploadImage: () => uploadImage()
};

export const eventRoutes = {
    addAnimalEvent: (eventType) => addAnimalEvent(eventType),
    getAllGlobalTransferEvents: (establishmentId, eventType) => getAllGlobalTransferEvents(establishmentId),
    getEventsPaginated: (establishmentId, eventType, page, limit) => getEventsPaginated(establishmentId, eventType, page, limit),
    getEventsExcel:(establishmentId, eventType) => getEventsExcel(establishmentId, eventType),
    massiveEventUpload: (establishmentId, eventType) => massiveEventUpload(establishmentId, eventType),
    acceptMassiveEventUpload: (excelId, eventType) => acceptMassiveEventUpload(excelId, eventType),
};

export const globalEventRoutes = {
    addThirdPartySlaughterEvent,
    getAllGlobalEvent: (userId, globalEventName, facilityId) => getAllGlobalEvent(userId, globalEventName, facilityId),
    getLotsWithAnimalsByFacilityId: (facilityId) => getLotsWithAnimalsByFacilityId(facilityId),
    getGlobalEventByIdThirdPartySlaughter: (globalEventId) => getGlobalEventByIdThirdPartySlaughter(globalEventId),
    getLotsInGlobalEvents: (facilityId,globalEventName) => getLotsInGlobalEvents(facilityId,globalEventName),
    getLotAverageWeight: (lotId) => getLotAverageWeight(lotId),
};

export const biRoutes = {
    getBI,    
};

export const certificateRoutes = {
    addCertificate,
    getFacilityCertificates: (facility) => getFacilityCertificates(facility),   
    getCertificateById: (id) => getCertificateById(id),   
};

export const qrGenerationRoutes = {
    getQrsGeneratedByFacilityPage: (establishmentId,page,limit) => getQrsGeneratedByFacilityPage(establishmentId,page,limit),
    getQrsTemplates: (establishmentId) => getQrsTemplates(establishmentId),
    addQrGeneration: () => addQrGeneration(),
    editQrGeneration: (qrId) => editQrGeneration(qrId),
    getQrsGeneratedById: (qrId) => getQrsGeneratedById(qrId),
    getRelatedImagesByLot: (lotId) => getRelatedImagesByLot(lotId)
}
export const connectionStatusRoutes = {
     getConnectionStatus,
};

export const externalServicesRoutes = {
    getSRAConnectionStatus: () => getSRAConnectionStatus()
      
};
