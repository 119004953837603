import React, { PureComponent, useEffect, useState } from 'react';
import PropTypes  from 'prop-types';
import { connect } from 'react-redux';
import {Button, ButtonToolbar, Modal} from 'reactstrap';
import { RTLProps } from '../../prop-types/ReducerProps';
import { withTranslation } from "react-i18next";
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import { validate } from 'react-email-validator';

class CVEmailTierModal extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        body: PropTypes.func.isRequired,
        rtl: RTLProps,
        isOpen: PropTypes.bool.isRequired,
        toggleModal: PropTypes.func.isRequired,
        cancel: PropTypes.func.isRequired,
        accept: PropTypes.func.isRequired,
        cancelText: PropTypes.string,
        acceptText: PropTypes.string
    };

    static defaultProps = {
        title: '',
        rtl: {
            direction: "rtl"
        }
    };
    
    constructor(props) {
        super(props);
        //console.log("props_b",props);

        const br = '\n';
        
        const textareaDefault = 
        'Solicito información para actualizar el Plan correspondiente a:' +
        br + br +
        'Empresa: '+props.animalsCountSelectedCompanyScope.context.company.legalName +
        br +
        'CUIT: '+props.animalsCountSelectedCompanyScope.context.company.tin +
        br + 
        'Teléfono: '+props.mobilePhoneNumber +
        br + 
        
        'Plan actual: '+props.animalsCountSelectedCompanyScope.context.activeTier.name +
        br

        const subjectDefault = 'Solicito Información para actualizar mi Plan en Carnes Validadas';


        this.state = {
          msgError: '',
          
          emailSubject:subjectDefault,
          emailBody:textareaDefault,
          textareaDefault: textareaDefault,
          subjectDefault: subjectDefault,
          userEmail: props.userEmail
        };
      }
          
    
    render() {

                

        const { title, rtl, isOpen, toggleModal, body, cancel, accept, t, cancelText, acceptText, hideButtons, hideCancelButton, backdrop, keyboard, animalsCountSelectedCompanyScope, userEmail, msgError, buttonDisabled } = this.props;
        
        let context = {
            userEmail:'',
            emailSubject:'',
            emailBody:'',            
        }

        const acceptWrapper = () => {
            
            let flagError = false;
            this.setState({msgError:''});
            
            if (validate(this.state.userEmail)==false) {
                flagError = true;
                this.setState({msgError:'La dirección de email no es válida'});
                //msgError='La dirección de email no es válida';
                return;
            }
            accept(this.state);
        }
        
        const onChangeEmail = (event) => {            
            //context.userEmail = event.target.value;                           
            this.setState({userEmail:event.target.value});
        }

        const onChangeSubject = (event) => {            
            this.setState({emailSubject:event.target.value});
            //context.emailSubject = event.target.value;
            //console.log(context)
            //context.toggleMailModal(false);
        }

        const onChangeBody = (event) => {            
            this.setState({emailBody:event.target.value});
            //context.emailBody = event.target.value;
            //console.log(context)
            //context.toggleMailModal(false);
        }

        

        
        
        
        /*if (context.userEmail=='') {
            
            context.emailSubject = subjectDefault;
            context.emailBody = textareaDefault;
            context.userEmail = userEmail;
        }*/

        const styles = {
            style: {
                fontSize:"14px",
                backgroundColor:"#f5f5f5",
                padding:"4px"
            }
          }

        if(!isOpen) return null;

        return (
            <Modal
                isOpen={isOpen}
                toggle={toggleModal}
                backdrop={backdrop}
                keyboard={keyboard}
                fade={false}
                modalClassName={`${rtl.direction}-support`}                
                className={`modal-dialog`}
                style={{padding:"10px"}}
            >
                <div className="modal__header" style={{padding:"10px"}}>
                    {!hideCancelButton &&
                    <button className="lnr lnr-cross modal__close-btn" type="button" onClick={() => toggleModal(false)} />
                    }
                    <h4 className="text-modal  modal__title">{title}</h4>
                </div>
                <div className="modal__body" style={{padding:"10px"}}>

                    <Row>
                            <Col md={12} xl={12} lg={12} xs={12}>
                            
                            <div className="form__form-group">                    
                                <div className="form__form-group-field">                       
                                <TextField fullWidth                      
                                    label="Email"                   
                                    InputProps={styles}
                                    defaultValue={this.state.userEmail}
                                    onChange={onChangeEmail}
                                    />
                                </div>
                            </div>
                            <div className="form__form-group">                    
                                <div className="form__form-group-field">                                              
                                <TextField fullWidth                       
                                    label="Asunto"     
                                    multiline                        
                                    rows="2"
                                    InputProps={styles}                   
                                    defaultValue={this.state.subjectDefault}
                                    onChange={onChangeSubject}
                                    />
                                </div>
                            </div>                
                            <div className="form__form-group">                    
                                <div className="form__form-group-field">                       
                                <TextField fullWidth                       
                                    label="Mensaje"
                                    InputProps={styles}
                                    multiline                        
                                    rows="7"
                                    defaultValue={this.state.textareaDefault}
                                    onChange={onChangeBody}
                                    />
                                </div>
                            </div>
                            <p>
                            Nos contactaremos con vos para la realización del pago vía transferencia y confirmar la actualización.<br/>
                            Querés agregar un comentario? Podés hacerlo modificando el mensaje.
                            </p>
                            
                            

                        </Col>
                    </Row>



                    {
                        !hideButtons &&
                        <ButtonToolbar className="form__button-toolbar center">                            
                            <Button disabled={buttonDisabled} color="primary" onClick={()=>{acceptWrapper(context)}}>{acceptText || t('common.accept')}</Button>
                            {!hideCancelButton &&
                            <Button type="reset" onClick={cancel}>{cancelText || t('common.cancel')}</Button>
                            }
                        </ButtonToolbar>
                    }

                            <span style={{fontWeight:"bold",color:"orange"}}>
                            {this.state.msgError}
                            {(msgError!='') &&
                            <div>
                                <br/>
                                {msgError}
                            </div>
                            }
                            </span>
                </div>
            </Modal>
        );
    }
}

export class CVEmailTierModalWithoutButtons extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        body: PropTypes.func.isRequired,
        isOpen: PropTypes.bool.isRequired,
        toggleModal: PropTypes.func.isRequired
    };

    render() {
        return (
            <CVEmailTierModal {...this.props} hideButtons cancel={() => {}} accept={() => {}}/>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({    
   
});

const mapStateToProps = (state) => ({   
    animalsCountSelectedCompanyScope: state.user.animalsCountSelectedCompanyScope,    
    userEmail: state.user.email,    
    mobilePhoneNumber: state.user.profile.cellular,    
});

export default connect(state => ({
    rtl: state.rtl,
}))(withTranslation('common')((connect(mapStateToProps, mapDispatchToProps)(CVEmailTierModal))));