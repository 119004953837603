import api from '../../api/index';
import {    
    UPLOAD_CERTIFICATE_FILES,
    EDIT_CERTIFICATE,
    GET_FACILITY_CERTIFICATES,    
    GET_CERTIFICATE_BY_ID,
    NEW_CERTIFICATE,
} from '../actions/certificateActions';
import actions from '../actions';
import mapper from './mappers/certificatesMapper';

const certificate = ({dispatch}) => next => action => {
    
    next(action);    
    switch (action.type) {        
        case NEW_CERTIFICATE:            
        dispatch(actions.certificate.newCertificateResponse(action.initValues));
            action.successCallback(action.initValues);
            break;
        case UPLOAD_CERTIFICATE_FILES:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                //  All files to upload
                let filesToUpload = action.files;
                //  All urls promises
                let urlsPromises = [];
                //  All files ans it associated ids
                let filesAndAssociatedUrls = {};

                filesToUpload.forEach(fileObj => {
                    let urlPromise = api.uploadImage(jwt, fileObj.file, fileObj.id);
                    urlsPromises.push(urlPromise);
                });

                Promise.all(urlsPromises)
                    .then(response => {
                        response.forEach(element => {
                            let fileName = element.additionalData.name;
                            let fileUrl = element.images.cvImage;
                            filesAndAssociatedUrls[`${fileName}`] = {fileUrl:fileUrl,md5Hash:element.md5Hash};
                        });
                        if(action.callback) action.callback(filesAndAssociatedUrls)
                    })
                    .catch(err => {
                        console.log("Error when uploading files ", err);
                    });
            }));
        break;
        case EDIT_CERTIFICATE:
            // todo finish when the cv-api support this method
            
            dispatch(actions.auth.authenticatedRequest(jwt => {

                //  Map all the files that has change
                const files = mapper.data.editCertificateFormFiles(action.data);

                if(files.length > 0) {
                    //  Upload the files that change
                    dispatch(actions.certificate.uploadCertificateFiles(files, (uploadedFilesAndUrls) => {
                        let filesUrl = {
                            file: action.data.file,                            
                            ...uploadedFilesAndUrls
                        };
                        api.addCertificate(jwt, mapper.body.editCertificate(action.data, filesUrl))
                            .then(res => {
                                dispatch(actions.certificate.editCertificateResponse(res));                                
                                if(action.successCallback) {                                    
                                    action.successCallback();
                                }
                            })
                            .catch(err => {                                
                                dispatch(actions.certificate.editCertificateError(err));                                                                
                                if(action.errorCallback) {                                    
                                    action.errorCallback(err);
                                }
                            })
                    }))
                }
                else {
                    
                    api.addCertificate(jwt, mapper.body.addCertificate(action.data))
                    .then(res => {
                        dispatch(actions.certificate.editCertificateResponse(res));                        
                        if(action.successCallback) action.successCallback();
                    })
                    .catch(err => {
                        dispatch(actions.certificate.editCertificateError(err));                        
                        if(action.errorCallback) action.errorCallback(err);
                    })

                    /*api.editAnimalById(jwt, action.animalId, mapper.body.addCertificate(action.data))
                        .then(res => {
                            dispatch(actions.certificate.editCertificateResponse(res));
                            if(action.successCallback) action.successCallback();
                        })
                        .catch(err => {
                            dispatch(actions.certificate.editCertificateError(err));
                            if(action.errorCallback) action.errorCallback(err);
                        })*/
                }
            }));
        break;
        case GET_FACILITY_CERTIFICATES:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getFacilityCertificates(jwt, action.facility)
                    .then(res => {
                        dispatch(actions.certificate.getFacilityCertificatesResponse(mapper.response.getFacilityCertificates(res)))
                        if(action.successCallback) action.successCallback(mapper.response.getFacilityCertificates(res));
                    })
                    .catch(err => {
                        console.log(err)
                        dispatch(actions.certificate.getFacilityCertificatesError(err))
                        if(action.errorCallback) action.errorCallback(err);
                    })
            }))
            break;        
        case GET_CERTIFICATE_BY_ID:
                dispatch(actions.auth.authenticatedRequest(jwt => {
                    api.getCertificateById(jwt, action.id)
                        .then(res => {
                            dispatch(actions.certificate.getCertificateByIdResponse(mapper.response.getCertificateById(res)))
                            if(action.successCallback) action.successCallback(mapper.response.getCertificateById(res));
                        })
                        .catch(err => {
                            console.log(err)
                            dispatch(actions.certificate.getCertificateByIdError(err))
                            if(action.errorCallback) action.errorCallback(err);
                        })
                }))
                break;        
    }
};

export default certificate;