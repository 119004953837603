/* eslint-disable default-case */
import api from '../../api/index';
import {

    GET_SRA_CONNECTION_STATUS

} from '../actions/servicesStatusActions';
import actions from '../actions';
import mapper from './mappers/servicesStatusMapper';

const servicesStatus = ({dispatch}) => next => action => {
    next(action);
    switch (action.type) {
        

        case GET_SRA_CONNECTION_STATUS:
            dispatch(actions.auth.authenticatedRequest(jwt => {
            
            api.getSRAConnectionStatus(jwt, action.data)
            .then(res => {
                  
                    const response = mapper.response.SRAConnectionStatus(res)
                    dispatch(actions.servicesStatus.getSRAConnectionStatusResponse(response));
                    // if(action.successCallback) action.successCallback(mapper.response.SRAConnectionStatus(response));
                })
                .catch(err => {
                    const e = mapper.error.SRAConnectionStatus(err)
                    dispatch(actions.servicesStatus.getSRAConnectionStatusError(e))
                    // if(action.errorCallback) action.errorCallback(err);
                })

            }));
        break;
        
    }
};

export default servicesStatus;