import React from 'react';
import cowTransparent from "../../img/cow-transparent.gif";
import {Button} from "reactstrap";

const CVButton = (props) => {

    const { type, disabled, pending, color, outline, onClick, style } = props;

    return (
        <Button
        className={"btn fixed_size"}
        color={color} type={type}
        style={style}
        outline={outline}
        disabled={disabled || pending}
        onClick={onClick}>
            {
                pending ?
                    <img className="cow__button_loader" alt="cow" src={cowTransparent}/> :
                    <span>{props.children}</span>
            }
        </Button>
    )
};

export default CVButton;