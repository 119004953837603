import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
import { RTLProps } from '../../../../shared/prop-types/ReducerProps';
import { withTranslation } from 'react-i18next';

class CVModal extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        body: PropTypes.func.isRequired,
        color: PropTypes.string.isRequired,
        colored: PropTypes.bool,
        header: PropTypes.bool,
        isOpen: PropTypes.bool.isRequired,
        toggle: PropTypes.func.isRequired,
        acceptFunc: PropTypes.func,
        acceptText: PropTypes.string,
        hideButtons: PropTypes.bool,
        showAcceptOnly: PropTypes.bool,
        hideCloseButton: PropTypes.bool,
        rtl: RTLProps.isRequired,
    };

    static defaultProps = {
        title: '',
        message: '',
        colored: false,
        header: false,
    };

    render() {
        const {
            color, title, body, colored, header, isOpen, acceptFunc,rtl, toggle, t, hideCloseButton
        } = this.props;
        let Icon;

        switch (color) {
            case 'primary':
                Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
                break;
            case 'success':
                Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
                break;
            case 'warning':
                Icon = <span className="lnr lnr-flag modal__title-icon" />;
                break;
            case 'danger':
                Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
                break;
            default:
                break;
        }
        const modalClass = classNames({
            'modal-dialog--colored': colored,
            'modal-dialog--header': header,
        });

        return (
            <div>
                <Modal
                    isOpen={isOpen}
                    toggle={toggle}
                    modalClassName={`${rtl.direction}-support`}
                    className={`modal-dialog--${color} ${modalClass}`}
                >
                    <div className="modal__header">
                        {!hideCloseButton && <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggle} />}
                        {header ? '' : Icon}
                        <h4 className="text-modal  modal__title">{title}</h4>
                    </div>
                    <div className="modal__body">
                        {body()}
                    </div>
                    {
                        !this.props.hideButtons &&
                            <ButtonToolbar className="modal__footer">
                                {
                                    this.props.showAcceptOnly ?
                                        <div>
                                            <Button className="modal_ok" outline={colored} color={color} onClick={acceptFunc}>{t('common.accept')}</Button>
                                        </div> :
                                        <div>
                                            <Button className="modal_cancel" onClick={toggle}>{t('common.cancel')}</Button>{' '}
                                            <Button className="modal_ok" outline={colored} color={color} onClick={acceptFunc}>{this.props.acceptText ? this.props.acceptText : t('common.accept')}</Button>
                                        </div>

                                }
                            </ButtonToolbar>
                    }
                </Modal>
            </div>
        );
    }
}

export default withTranslation('common')(connect(state => ({
    rtl: state.rtl,
}))(CVModal));
