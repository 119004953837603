import api from '../../api/index';
import {    
    GET_USER_ESTABLISHMENT_BY_ID_MICROSITE,    
    GET_ALL_USER_COMPANIES_MICROSITE,    
    GET_USER_ESTABLISHMENTS_BY_COMPANY_MICROSITE,    
    GET_ASSIGNED_COMPANIES_MICROSITE,    
} from '../actions/templateActions';

import actions from '../actions';
import mapper from './mappers/userMapper';

const template = ({ dispatch, getState }) => next => action => {
    next(action);
    switch (action.type) {
        
        case GET_USER_ESTABLISHMENT_BY_ID_MICROSITE:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getUserEstablishmentById(jwt, action.establishmentId)
                    .then(res => {
                        dispatch(actions.template.getEstablishmentByIdResponse(mapper.response.getUserEstablishmentById(res)))
                        if (action.callback) action.callback();
                    })
                    .catch(err => {
                        dispatch(actions.template.getEstablishmentByIdError(err))
                    })
            }));
            break;
        
        case GET_ALL_USER_COMPANIES_MICROSITE:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAllUserCompanies(jwt)
                    .then(res => {
                        dispatch(actions.template.getAllUserCompaniesResponse(mapper.response.getAllUserCompanies(res)))
                    })
                    .catch(err => {
                        dispatch(actions.template.getAllUserCompaniesError(err))
                    })
            }));
            break;
        
        case GET_USER_ESTABLISHMENTS_BY_COMPANY_MICROSITE:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getUserEstablishmentsByCompany(jwt, action.companyId)
                    .then(res => {
                        dispatch(actions.template.getUserEstablishmentsByCompanyResponse(
                            mapper.response.getUserEstablishmentsByCompany(res)))
                    })
                    .catch(err => {
                        dispatch(actions.template.getUserEstablishmentsByCompanyError(err))
                    })
            }));
            break;
        
        case GET_ASSIGNED_COMPANIES_MICROSITE:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAssignedCompanies(jwt, action.userId)
                    .then(res => {
                        dispatch(actions.template.getAssignedCompaniesResponse(mapper.response.getAssignedCompanies(res)))
                    })
                    .catch(err => {
                        dispatch(actions.template.getAssignedCompaniesError(err))
                    })
            }))
            break;
        
    }
}
export default template;
