export const GET_ALL_USER_COMPANIES_MICROSITE = 'GET_ALL_USER_COMPANIES_MICROSITE';
export const GET_ALL_USER_COMPANIES_MICROSITE_RESPONSE = 'GET_ALL_USER_COMPANIES_MICROSITE_RESPONSE';
export const GET_ALL_USER_COMPANIES_MICROSITE_ERROR = 'GET_ALL_USER_COMPANIES_MICROSITE_ERROR';
export const SELECT_COMPANY_MICROSITE = 'SELECT_COMPANY_MICROSITE';
export const SELECT_ESTABLISHMENT_MICROSITE = 'SELECT_ESTABLISHMENT_MICROSITE';
export const GET_ASSIGNED_COMPANIES_MICROSITE = "GET_ASSIGNED_COMPANIES_MICROSITE";
export const GET_ASSIGNED_COMPANIES_MICROSITE_RESPONSE = "GET_ASSIGNED_COMPANIES_MICROSITE_RESPONSE";
export const GET_ASSIGNED_COMPANIES_MICROSITE_ERROR = "GET_ASSIGNED_COMPANIES_MICROSITE_ERROR";
export const GET_USER_ESTABLISHMENTS_BY_COMPANY_MICROSITE = "GET_USER_ESTABLISHMENTS_BY_COMPANY_MICROSITE";
export const GET_USER_ESTABLISHMENTS_BY_COMPANY_MICROSITE_RESPONSE = "GET_USER_ESTABLISHMENTS_BY_COMPANY_MICROSITE_RESPONSE";
export const GET_USER_ESTABLISHMENTS_BY_COMPANY_MICROSITE_ERROR = "GET_USER_ESTABLISHMENTS_BY_COMPANY_MICROSITE_ERROR";
export const GET_USER_ESTABLISHMENT_BY_ID_MICROSITE = 'GET_USER_ESTABLISHMENT_BY_ID_MICROSITE';
export const GET_USER_ESTABLISHMENT_BY_ID_MICROSITE_RESPONSE = 'GET_USER_ESTABLISHMENT_BY_ID_MICROSITE_RESPONSE';
export const GET_USER_ESTABLISHMENT_BY_ID_MICROSITE_ERROR = 'GET_USER_ESTABLISHMENT_BY_ID_MICROSITE_ERROR';

const templateActions = {
    getAllUserCompanies() {
        return {
            type: GET_ALL_USER_COMPANIES_MICROSITE
        }
    },
    getAllUserCompaniesResponse(response) {
        return {
            type: GET_ALL_USER_COMPANIES_MICROSITE_RESPONSE,
            response
        }
    },
    getAllUserCompaniesError(message) {
        return {
            type: GET_ALL_USER_COMPANIES_MICROSITE_ERROR,
            message
        }
    },
    selectCompany(company) {
        return {
            type: SELECT_COMPANY_MICROSITE,
            company
        }
    },
    selectEstablishment(establishment) {
        return {
            type: SELECT_ESTABLISHMENT_MICROSITE,
            establishment
        }
    },
    getAssignedCompanies(userId) {
        return {
            type: GET_ASSIGNED_COMPANIES_MICROSITE,
            userId
        }
    },
    getAssignedCompaniesResponse(response) {
        return {
            type: GET_ASSIGNED_COMPANIES_MICROSITE_RESPONSE,
            response
        }
    },
    getAssignedCompaniesError(message) {
        return {
            type: GET_ASSIGNED_COMPANIES_MICROSITE_ERROR,
            message
        }
    },
    getUserEstablishmentsByCompany(companyId) {
        return {
            type: GET_USER_ESTABLISHMENTS_BY_COMPANY_MICROSITE,
            companyId
        }
    },
    getUserEstablishmentsByCompanyResponse(response) {
        return {
            type: GET_USER_ESTABLISHMENTS_BY_COMPANY_MICROSITE_RESPONSE,
            response
        }
    },
    getUserEstablishmentsByCompanyError(message) {
        return {
            type: GET_USER_ESTABLISHMENTS_BY_COMPANY_MICROSITE_ERROR,
            message
        }
    },
    getEstablishmentById(establishmentId, callback) {
        return {
            type: GET_USER_ESTABLISHMENT_BY_ID_MICROSITE,
            establishmentId,
            callback
        }
    },
    getEstablishmentByIdResponse(response) {
        return {
            type: GET_USER_ESTABLISHMENT_BY_ID_MICROSITE_RESPONSE,
            response
        }
    },
    getEstablishmentByIdError(message) {
        return {
            type: GET_USER_ESTABLISHMENT_BY_ID_MICROSITE_ERROR,
            message
        }
    },
};
export default templateActions;