import React , {useEffect, useState} from 'react';
import { Button, ButtonToolbar, Row, Col, FormGroup, Container } from 'reactstrap';
import { Field, FormSection, reduxForm, change } from 'redux-form';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next';
import renderDropZoneField from '../../components/form/DropZoneTransfer';
import FileExcelIcon from 'mdi-react/FileExcelIcon';
import SearchInput from "../../../shared/components/cv/SearchInput";
import actions from "../../../redux/actions/index";
import _ from "lodash";
import {renderCVFileInputField} from './CVFileUpload';
import cowTransparent from "../../../shared/img/cow-transparent.gif";

    const UploadDoc = props => {
    const { component, label, disabled, acceptedFormats, error, id, resetInputFile} = props

    const [showDeleteButton, setShowDeleteButton] = useState(false);

    const handleResetFile = (e, id) => {
        e.preventDefault()
        resetInputFile('file_upload_default', `${id}.doc` ,"")
        setShowDeleteButton(false)                      
    }


    return (
        
    <Container>
        <Row  style={{alignItems: "center", justifyContent:"center"}}>
            
           { showDeleteButton && (<div><a><button style={{margin:"0px 5px 10px 0px"}} onClick={(e)=>{handleResetFile(e, id)}}>x</button></a></div>)}
            <Col  xl={5} style={{padding: "0px"}}>
                <span>{disabled ? <strong>{label}</strong> : label}</span> 
                <Field
                    name={"doc"}
                    component={component || "input"}
                    disabled={false}
                    acceptedFormats={acceptedFormats || '.pdf, .docx, .doc, .xlsx, .xls, .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .png, .gif, .tiff, .tif, .bmp, .dib'}
                    onChange={()=> setShowDeleteButton(true)}
                   />
            </Col>
           
            <Col xl={6} >
                <Field  
                    name={"docTitle"} 
                    disabled={disabled} 
                    component="input"
                    type= {"text"}
                    placeholder = {!disabled && "Ingrese un título para el documento"}
                    style={{
                        marginBottom:"10px", 
                        borderRadius: "5px", 
                        fontStyle: disabled && "italic", border: !disabled ? (!error ? "2px solid black" : "2px solid red") : "none", 
                        backgroundColor: disabled && "#eff1f5" 
                        }}

            />
            </Col>
        </Row>
    </Container>
       
    )

    }


    const FileUploadExtended = props => {
    const { handleSubmit, reset, t, acceptedFormats, searchPlaceholder, facilitySearch, initialValues, uploadedFilesError, pending } = props;

    const search = value => {
        props.getFacilityByCode(value);
    };
    
    
    useEffect(() => {
        
        props.resetFacilitySearch();
    }, []);


    const formSectionErrorStyle = () => ({ border: "1px solid red", margin: "0px 0px 15px 0px", borderRadius:"5px" })

    const UploadedFilesErrorMessage = (message) => (
        <div style={{color: "red", padding: "0px 0px 0px 5px"}}>{message}</div>
    )
    
  
    return (
        <>
        <div className="add_new_professional">
            
        <Container style={{maxWidth: "90%",  paddingTop: "10px",marginTop:'20px'}}>
            <Row>
                <Col>           
            
                    <p style={{display:'flex', fontSize:'0.9rem', fontWeight:'600', marginBottom:'1rem'}}> Indica el destinatario de la transferencia</p> 
                    <SearchInput
                        placeholder={searchPlaceholder}
                        search={search}
                    />
                    {facilitySearch.fetched && !_.isEmpty(facilitySearch.res) && (
                        <div className="">
                            <br/>
                            <div className="add_new_professional-result-name text-center">
                                <label style={{width: "100%"}}>
                                    Los animales serán transferidos a: <br/>{facilitySearch.res.name}
                                </label>
                            </div>
                        </div>
                    )}
                    {facilitySearch.fetched && _.isEmpty(facilitySearch.res) && (
                        <div className="error_container">
                            <label>
                                {t(
                                    "events.modals.massive_upload.search_error"
                                )}
                            </label>
                        </div>
                    )}
            </Col>
            </Row>
        </Container>
        {(
        <form className="form form--justify" onSubmit={handleSubmit}>
            <Container style={{maxWidth: "90%", borderTop: "2px solid lightgray", paddingTop: "20px",marginTop:'20px'}}>
                <Row>
                    <Col>
                    <p style={{display:'flex', fontSize:'0.9rem', fontWeight:'600', marginBottom:'1rem'}}> Subí la planilla de transferencias</p> 
                        <Field
                        name="files"
                        acceptedFormats={acceptedFormats}
                        dropZoneDefaultImage={FileExcelIcon}
                        dropZonePlaceHolder={t('cv-inputs.file_upload.drop_zone_placeholder')}
                        component={renderDropZoneField}
                        transf={true}
                        />                
                    </Col>
                </Row>
            </Container>

            <Container style={{maxWidth: "90%", borderTop: "2px solid lightgray", paddingTop: "20px",marginTop:'20px'}}>
                
                <Row>
                    <Col style={{borderRight: '2px solid lightgray'}} >
                    <p style={{display:'flex', fontSize:'0.9rem', fontWeight:'600', marginBottom:'1rem'}}> Cargá documentos predefinidos de la transferencia (Opcional)</p> 
                       
                   { 
                       ['doc1', 'doc2', 'doc3', 'doc4', 'doc5'].map((doc, index) => {
                        
                            const labels = [ "DTE", "DUT", "Guía de traslado", "Factura", "Certificado de garantía"]

                            return (   
                                    <FormSection name={doc} style={ uploadedFilesError.hasOwnProperty(doc) ? formSectionErrorStyle() : {}}>
                                        { uploadedFilesError.hasOwnProperty(doc) && UploadedFilesErrorMessage(uploadedFilesError[doc]) }
                                        <UploadDoc
                                            component={renderCVFileInputField}
                                            label={t(`${labels[index]}`)}
                                            disabled={true}
                                            id={doc}
                                            resetInputFile={props.resetInputFile}
                                        />
                                    </FormSection>
                                
                            )
                        })
                    }
                    
                    </Col>
          
                    <Col>
                    <p style={{display:'flex', fontSize:'0.9rem', fontWeight:'600', marginBottom:'1rem'}}> Cargá documentos adicionales asociados a la transferencia (Opcional)</p> 
                   {
                        ['doc6', 'doc7', 'doc8', 'doc9'].map((doc, index) => {
                            const labels = [ "Documento opcional 1", "Documento opcional 2", "Documento opcional 3", "Documento opcional 4"]
                            return (   
                                    <FormSection name={doc} style={ uploadedFilesError.hasOwnProperty(doc) ? formSectionErrorStyle() : {}}>
                                        { uploadedFilesError.hasOwnProperty(doc) && UploadedFilesErrorMessage(uploadedFilesError[doc]) }
                                        <UploadDoc
                                            component={renderCVFileInputField}
                                            label={t(`${labels[index]}`)}
                                            disabled={false}
                                            id={doc}
                                            resetInputFile={props.resetInputFile}
                                        />
                                    </FormSection>
                                
                            )
                        })
                    }
                    </Col>
                </Row>
            </Container>
            <Col md={12} style={{margin: "20px 0px 30px 0px"}}>

            <ButtonToolbar className="form__button-toolbar center">
              <Button disabled={!facilitySearch.fetched || _.isEmpty(facilitySearch.res) } color="primary" type="submit">
                    {pending?  
                            <img alt="cow"  style={{height:'1.5rem',width:'auto'}} src={cowTransparent}/>
                        :
                        <>{t('common.upload')}</>
                    }
                  </Button>
              <Button type="reset" onClick={reset}>{t('common.delete')}</Button>
            </ButtonToolbar>
            </Col>
          </form>
           
          )}
        
       

          {facilitySearch.fetched && _.isEmpty(facilitySearch.res) && (
              <div className="error_container">
                    <label>
                        {t(
                            "events.modals.massive_upload.search_error"
                            )}
                    </label>
                </div>
            )}
        </div>
        </>
    );
};

FileUploadExtended.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    initialValues: {
        doc1: { docTitle: "DTE" },
        doc2: { docTitle: "DUT" },
        doc3: { docTitle: "Guía de traslado" },
        doc4: { docTitle: "Factura" },
        doc5: { docTitle: "Certificado de garantía" },
    },
    facilitySearch: state.generalData.facilitySearch,
});

const mapDispatchToProps = dispatch => ({
    getFacilityByCode: code =>
        dispatch(actions.generalData.getFacilityByCode(code)),
    resetFacilitySearch: () => dispatch(actions.generalData.resetFacilitySearch()),
    resetInputFile: (form, field, newValue) => dispatch(change(form, field, newValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'file_upload_default',
    enableReinitialize : true
    // a unique identifier for this form
})(withTranslation('common')(FileUploadExtended)));

