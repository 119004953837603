/*
*   The admin mapper is use for mapping either the body or the response on a user request. It try to decouple the
*   admin middleware.
* */


const mapper =  {
    body: {
    },
    response: {
        getUsers: (response) => ({
            users: response.results,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        getRoles: (response) => ({
            roles: response.results,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        getGeneralDataTitlesPage: (response) => ({
            titles: response.results,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        addGeneralData: (response) => ({
            titles: response.results,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        })
    }
};

export default mapper;