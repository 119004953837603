import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import newIconSrc from '../../../shared/img/icon/new-icon-text.png';
import './SidebarLink.css';

const SidebarLink = ({
  title, icon: Icon, newLink, route, onClick, newIcon, isBeta
}) => (
  <NavLink
    to={route}
    onClick={onClick}
    activeClassName="sidebar__link-active"    
  >
    <li className="sidebar__link">
      {Icon && <Icon className="sidebar__link-icon"/>}
      <p className="sidebar__link-title">
        {title}
        {newLink ? <Badge className="sidebar__link-badge"><span>New</span></Badge> : ''}
        {isBeta ? <Badge color='primary' style={{marginLeft:'5px'}}><span>Beta</span></Badge> : ''}
        {newIcon ? <div>&nbsp;<img src={newIconSrc} style={{width:"36px"}}/></div> : ''}
      </p>      
    </li>
  </NavLink>
);

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarLink.defaultProps = {
  newLink: false,
  route: '/',
  onClick: () => {},
};

export default SidebarLink;
