import React, { useEffect } from 'react';
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import {Button} from "reactstrap";
import {withTranslation} from "react-i18next";

const EstablishmentTypeSelection = (props) => {
    useEffect(() => {
        props.getAvailableEstablishmentTypes()
    }, []);

    const { t, selectEstablishmentType } = props;

    const availableEstablishmentTypes = ["productive",  "slaughterhouse", "commercial", "importer"]

    return (
        <div className="establishment_type_selection">
            <h5 className="bold-text title">{t('add_new_account_wizard.establishment_type_selection.title')}</h5>
            {
                availableEstablishmentTypes.map(establishmentType => (
                    <Button color="primary" className="establishment_type_button" type="button"
                            onClick={() => selectEstablishmentType(establishmentType)} key={establishmentType}
                            disabled={!props.selectedCompany}>
                        {t(`cv-forms.productive_establishment.establishment_types.${establishmentType}`)}
                    </Button>
                ))
            }
        </div>
    )

};

const mapStateToProps = (state) => ({
    availableEstablishmentTypes: state.constant.availableEstablishmentTypes,
    selectedCompany: state.user.selectedCompany
});

const mapDispatchToProps = (dispatch) => ({
    getAvailableEstablishmentTypes: () => dispatch(actions.constant.getAvailableEstablishmentTypes())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(EstablishmentTypeSelection));