import React from 'react';
import { Container, Row } from 'reactstrap';
import SlaughterEventForm from "../NewEventForms/SlaughterEventForm"
import { connect } from 'react-redux';
import actions from '../../../../redux/actions';
import {useAlert} from "react-alert";
import { withTranslation } from "react-i18next";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

const NewSlaughterEventContainer = (props) => {
    const alert = useAlert();

    const addSlaughterEvent = (values) => {
        props.submitSlaughterEvent(values, props.facilityId, () => {
            alert.success(props.t('events.alerts.event_added'));
            props.history.push('/events/slaughter/table');
        }, (errorMessage) => {
            ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
        });
    };

    return (
        <Container>
         <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
            <Row>
                <SlaughterEventForm
                  onSubmit={addSlaughterEvent}
                  pending={props.addCowPending}
                  editable/>
            </Row>
        </Container>
    )
};

const mapStateToProps = (state) => ({
    addCowPending: state.ui.addCowPending, // todo change this!
    facilityId: state.user.selectedEstablishment._id
});

const mapDispatchToProps = (dispatch) => ({
    submitSlaughterEvent: (values, facilityId, successCallback, errorCallback) =>
        dispatch(actions.event.submitSlaughterEvent(values, facilityId, successCallback, errorCallback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(NewSlaughterEventContainer));