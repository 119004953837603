export const GET_ALL_USER_COMPANIES_THIRDPARTYSLAUGHTER = 'GET_ALL_USER_COMPANIES_THIRDPARTYSLAUGHTER';
export const GET_ALL_USER_COMPANIES_THIRDPARTYSLAUGHTER_RESPONSE = 'GET_ALL_USER_COMPANIES_THIRDPARTYSLAUGHTER_RESPONSE';
export const GET_ALL_USER_COMPANIES_THIRDPARTYSLAUGHTER_ERROR = 'GET_ALL_USER_COMPANIES_THIRDPARTYSLAUGHTER_ERROR';
export const SELECT_COMPANY_THIRDPARTYSLAUGHTER = 'SELECT_COMPANY_THIRDPARTYSLAUGHTER';
export const SELECT_ESTABLISHMENT_THIRDPARTYSLAUGHTER = 'SELECT_ESTABLISHMENT_THIRDPARTYSLAUGHTER';
export const SELECT_LOT_THIRDPARTYSLAUGHTER = 'SELECT_LOT_THIRDPARTYSLAUGHTER';
export const GET_ASSIGNED_COMPANIES_THIRDPARTYSLAUGHTER = "GET_ASSIGNED_COMPANIES_THIRDPARTYSLAUGHTER";
export const GET_ASSIGNED_COMPANIES_THIRDPARTYSLAUGHTER_RESPONSE = "GET_ASSIGNED_COMPANIES_THIRDPARTYSLAUGHTER_RESPONSE";
export const GET_ASSIGNED_COMPANIES_THIRDPARTYSLAUGHTER_ERROR = "GET_ASSIGNED_COMPANIES_THIRDPARTYSLAUGHTER_ERROR";
export const GET_USER_ESTABLISHMENTS_BY_COMPANY_THIRDPARTYSLAUGHTER = "GET_USER_ESTABLISHMENTS_BY_COMPANY_THIRDPARTYSLAUGHTER";
export const GET_USER_ESTABLISHMENTS_BY_COMPANY_THIRDPARTYSLAUGHTER_RESPONSE = "GET_USER_ESTABLISHMENTS_BY_COMPANY_THIRDPARTYSLAUGHTER_RESPONSE";
export const GET_USER_ESTABLISHMENTS_BY_COMPANY_THIRDPARTYSLAUGHTER_ERROR = "GET_USER_ESTABLISHMENTS_BY_COMPANY_THIRDPARTYSLAUGHTER_ERROR";
export const GET_LOTS_BY_ESTABLISHMENT_THIRDPARTYSLAUGHTER = 'GET_LOTS_BY_ESTABLISHMENT_THIRDPARTYSLAUGHTER';
export const GET_LOTS_BY_ESTABLISHMENT_THIRDPARTYSLAUGHTER_RESPONSE = 'GET_LOTS_BY_ESTABLISHMENT_THIRDPARTYSLAUGHTER_RESPONSE';
export const GET_LOTS_BY_ESTABLISHMENT_THIRDPARTYSLAUGHTER_ERROR = 'GET_LOTS_BY_ESTABLISHMENT_THIRDPARTYSLAUGHTER_ERROR';
export const SUBMIT_THIRDPARTYSLAUGHTER_EVENT = 'SUBMIT_THIRDPARTYSLAUGHTER_EVENT';
export const SUBMIT_THIRDPARTYSLAUGHTER_EVENT_RESPONSE = 'SUBMIT_THIRDPARTYSLAUGHTER_EVENT_RESPONSE';
export const SUBMIT_THIRDPARTYSLAUGHTER_EVENT_ERROR = 'SUBMIT_THIRDPARTYSLAUGHTER_EVENT_ERROR';
export const RESET_SELECTED_EVENT ='RESET_SELECTED_EVENT'
export const SET_ESTABLISHMENTS_DETAIL_FORM ='SET_ESTABLISHMENTS_DETAIL_FORM'

// Global Events - TODO sacarlos a su propio redux completo
export const GET_GLOBAL_EVENT_THIRDPARTYSLAUGHTER = 'GET_GLOBAL_EVENT_THIRDPARTYSLAUGHTER';
export const GET_GLOBAL_EVENT_THIRDPARTYSLAUGHTER_RESPONSE  = 'GET_GLOBAL_EVENT_THIRDPARTYSLAUGHTER_RESPONSE';
export const GET_GLOBAL_EVENT_THIRDPARTYSLAUGHTER_ERROR = 'GET_GLOBAL_EVENT_THIRDPARTYSLAUGHTER_ERROR';
export const GET_GLOBAL_EVENT_BY_ID_THIRDPARTYSLAUGHTER = 'GET_GLOBAL_EVENT_BY_ID_THIRDPARTYSLAUGHTER';
export const GET_GLOBAL_EVENT_BY_ID_THIRDPARTYSLAUGHTER_RESPONSE  = 'GET_GLOBAL_EVENT_BY_ID_THIRDPARTYSLAUGHTER_RESPONSE';
export const GET_GLOBAL_EVENT_BY_ID_THIRDPARTYSLAUGHTER_ERROR = 'GET_GLOBAL_EVENT_BY_ID_THIRDPARTYSLAUGHTER_ERROR';
export const GET_LOT_AVERAGE_WEIGHT_GLOBALEVENT = 'GET_LOT_AVERAGE_WEIGHT_GLOBALEVENT';
export const GET_LOT_AVERAGE_WEIGHT_GLOBALEVENT_RESPONSE = 'GET_LOT_AVERAGE_WEIGHT_GLOBALEVENT_RESPONSE';
export const GET_LOT_AVERAGE_WEIGHT_GLOBALEVENT_ERROR = 'GET_LOT_AVERAGE_WEIGHT_GLOBALEVENT_ERROR';
export const UPLOAD_GLOBAL_EVENT_FILES = 'UPLOAD_GLOBAL_EVENT_FILES';


const thirdPartySlaughterActions = {
    getAllUserCompanies() {
        return {
            type: GET_ALL_USER_COMPANIES_THIRDPARTYSLAUGHTER
        }
    },
    getAllUserCompaniesResponse(response) {
        return {
            type: GET_ALL_USER_COMPANIES_THIRDPARTYSLAUGHTER_RESPONSE,
            response
        }
    },
    getAllUserCompaniesError(message) {
        return {
            type: GET_ALL_USER_COMPANIES_THIRDPARTYSLAUGHTER_ERROR,
            message
        }
    },
    selectCompany(company) {
        return {
            type: SELECT_COMPANY_THIRDPARTYSLAUGHTER,
            company
        }
    },
    selectEstablishment(establishment) {
        return {
            type: SELECT_ESTABLISHMENT_THIRDPARTYSLAUGHTER,
            establishment
        }
    },
    selectLots(lot) {
        return {
            type: SELECT_LOT_THIRDPARTYSLAUGHTER,
            lot
        }
    },
    getAssignedCompanies(userId) {
        return {
            type: GET_ASSIGNED_COMPANIES_THIRDPARTYSLAUGHTER,
            userId
        }
    },
    getAssignedCompaniesResponse(response) {
        return {
            type: GET_ASSIGNED_COMPANIES_THIRDPARTYSLAUGHTER_RESPONSE,
            response
        }
    },
    getAssignedCompaniesError(message) {
        return {
            type: GET_ASSIGNED_COMPANIES_THIRDPARTYSLAUGHTER_ERROR,
            message
        }
    },
    getUserEstablishmentsByCompany(companyId) {
        return {
            type: GET_USER_ESTABLISHMENTS_BY_COMPANY_THIRDPARTYSLAUGHTER,
            companyId
        }
    },
    getUserEstablishmentsByCompanyResponse(response) {
        return {
            type: GET_USER_ESTABLISHMENTS_BY_COMPANY_THIRDPARTYSLAUGHTER_RESPONSE,
            response
        }
    },
    getUserEstablishmentsByCompanyError(message) {
        return {
            type: GET_USER_ESTABLISHMENTS_BY_COMPANY_THIRDPARTYSLAUGHTER_ERROR,
            message
        }
    },
    getLotsByEstablishmentId(establishmentId, viewOnlyActiveLots) {
        return {
            type: GET_LOTS_BY_ESTABLISHMENT_THIRDPARTYSLAUGHTER,
            establishmentId,
            viewOnlyActiveLots
        }
    },
    getLotsByEstablishmentIdResponse(response) {
        return {
            type: GET_LOTS_BY_ESTABLISHMENT_THIRDPARTYSLAUGHTER_RESPONSE,
            response
        }
    },
    getLotsByEstablishmentIdError(message) {
        return {
            type: GET_LOTS_BY_ESTABLISHMENT_THIRDPARTYSLAUGHTER_ERROR,
            message
        }
    },
    submitThirdPartySlaughterEvent(values, facilityId, successCallback, errorCallback) {
        return {
            type: SUBMIT_THIRDPARTYSLAUGHTER_EVENT,
            values,
            facilityId,
            successCallback,
            errorCallback
        }
    },
    submitThirdPartySlaughterEventResponse(response) {
        return {
            type: SUBMIT_THIRDPARTYSLAUGHTER_EVENT_RESPONSE,
            response
        }
    },
    submitThirdPartySlaughterEventError(message) {
        return {
            type: SUBMIT_THIRDPARTYSLAUGHTER_EVENT_ERROR,
            message
        }
    },
    resetSelectedEvent() {
        return {
            type: RESET_SELECTED_EVENT,
        }
    },
    getAllGlobalEvent(userId, globalEventName, facilityId) {
        return {
            type: GET_GLOBAL_EVENT_THIRDPARTYSLAUGHTER,
            userId,
            globalEventName,
            facilityId
        }
    },
    getAllGlobalEventResponse(response) {
        return {
            type: GET_GLOBAL_EVENT_THIRDPARTYSLAUGHTER_RESPONSE,
            response
        }
    },
    getAllGlobalEventError(message) {
        return {
            type: GET_GLOBAL_EVENT_THIRDPARTYSLAUGHTER_ERROR,
            message
        }
    },
    getGlobalEventByIdThirdPartySlaughter(globalEventId) {
        return {
            type: GET_GLOBAL_EVENT_BY_ID_THIRDPARTYSLAUGHTER,
            globalEventId
        }
    },
    getGlobalEventByIdThirdPartySlaughterResponse(response) {
        return {
            type: GET_GLOBAL_EVENT_BY_ID_THIRDPARTYSLAUGHTER_RESPONSE,
            response
        }
    },
    getGlobalEventByIdThirdPartySlaughterError(message) {
        return {
            type: GET_GLOBAL_EVENT_BY_ID_THIRDPARTYSLAUGHTER_ERROR,
            message
        }
    },
    getLotAverageWeight(lotId) {
        return {
            type: GET_LOT_AVERAGE_WEIGHT_GLOBALEVENT,
            lotId
        }
    },
    getLotAverageWeightResponse(response) {
        return {
            type: GET_LOT_AVERAGE_WEIGHT_GLOBALEVENT_RESPONSE,
            response
        }
    },
    getLotAverageWeightError(message) {
        return {
            type: GET_LOT_AVERAGE_WEIGHT_GLOBALEVENT_ERROR,
            message
        }
    },
    uploadGlobalEventFiles(files, callback) {
        return {
            type: UPLOAD_GLOBAL_EVENT_FILES,
            files,
            callback
        }
    },
};

export default thirdPartySlaughterActions;