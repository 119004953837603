import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import  OfflineModal  from '../../App/ConnectionStatus/Components/OfflineModal';

const ConnectionStatus = (props) => {
    ConnectionStatus.propTypes = {
        seconds: PropTypes.number.isRequired,
        fetchStatus: PropTypes.func.isRequired
    }

    const {seconds, fetchStatus}= props
    const [apiStatus, setApiStatus] = useState(true)
    const [isRetry, setIsRetry] = useState(false)

    useEffect( () => {
        const instance = !isRetry  &&  demonConnectionStatus()
        
        return () => {
            clearInterval(instance)
        }

        }, [apiStatus, isRetry]);


    const demonConnectionStatus =  useCallback(()=> (setInterval (async () => {
        try {
            const response = await fetchStatus()
            setApiStatus(response.status)
            
        } catch (err) {
            setApiStatus(false)
        }
    }, seconds * 1000)), [seconds])


    const getSingleConnectionStatus =  useCallback(()=> (
        (setTimeout ( async () => {
            try {
           
                const response = await fetchStatus()
                setIsRetry(false)
                setApiStatus(response.status)
                 
            } catch (err) {
                setApiStatus(false)
                setIsRetry(false)
                
        }}, 2000))
    ), [isRetry])


    const handleRetry = useCallback((async ()=> {
        setIsRetry(true)
        getSingleConnectionStatus()
 
    }), [getSingleConnectionStatus] )


 
    return (
        <div>
            {
                !apiStatus && <OfflineModal 
                                    counterSeconds={seconds}
                                    restartCounter={false}
                                    handleRetry= {handleRetry}
                                    isOnline = {apiStatus}
                                    isRetry = {isRetry} 
                                />
            }
        </div>
    )

}

export default React.memo(ConnectionStatus)