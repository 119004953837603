import React from 'react';
import { withTranslation } from 'react-i18next';
import CVTable from '../../Tables/CVTable/CVTable';
import MagnifyIcon from "mdi-react/MagnifyIcon";
import LeadPencilIcon from 'mdi-react/LeadPencilIcon';

const RolesTable = (props) => {
    const { t, roles, currentPage, totalPages, totalRoles, pageSize, getPage } = props;

    const renderValueDefault = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value}
            </div>
        )
    };

    const renderButtons = ({ dependentValues }) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <MagnifyIcon className={"button"} style={{marginLeft: "10px"}}/>
                <LeadPencilIcon className={"button"} style={{marginLeft: "10px"}}/>
            </div>
        )
    };

    const heads = [
        {
            key: 'role_name',
            name: t('roles.table.headers.name'),
            percentage: 30,
            minWidth: 100,
            formatter: ({value}) => renderValueDefault({value: t(`common.roles.${value.toLowerCase()}`)})
        },
        {
            key: 'created_by',
            name: t('roles.table.headers.created_by'),
            percentage: 40,
            minWidth: 100,
            formatter: renderValueDefault
        },
        {
            key: 'actions',
            name: t('roles.table.headers.actions'),
            percentage: 30,
            minWidth: 100,
            formatter: renderButtons,
            getRowMetaData: (row) => row
        }
    ];

    return (
        <CVTable
            getPage={getPage}
            data={roles}
            total={totalRoles}
            totalPages={totalPages}
            currentPage={currentPage}
            pageSize={pageSize}
            heads={heads}
            title={t('roles.table.title')}
            hideFilters
            noDataLabel={t('roles.table.no_data_label')}
        />
    )
};

export default withTranslation('common')(RolesTable)
