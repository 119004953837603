import React, {useEffect, useLayoutEffect, useState} from 'react'
import CVTableEvents from "../../../Tables/CVTable/CVTableEvents";
import { withTranslation } from "react-i18next";
import eventActions from '../../../../redux/actions/eventActions';
import { connect } from 'react-redux';
import FileUploadOutlineIcon from "mdi-react/FileUploadOutlineIcon";
import FileTableOutlineIcon from "mdi-react/FileTableOutlineIcon";
import FileUploadExtendedModal from "../../../../shared/components/cv/FileUploadExtendedModal";
import { withRouter } from 'react-router-dom';
import { EVENT_TYPES } from '../../../../constants/index';
import {parse_UTC_date_to_DD_MM_YYYY} from "../../../../util";
import GeneralDataActions from "../../../../redux/actions/generalDataActions";
import {Tooltip} from "react-tippy";
import FileDownloadOutlineIcon from "mdi-react/FileDownloadOutlineIcon";
import settings from "../../../../util/settings";
import { ErrorNoEstablishment } from "../EventErrors";
import {useAlert} from "react-alert";
import { TabContent, TabPane, Nav, NavItem, NavLink, CardTitle, CardText } from 'reactstrap';
import classnames from 'classnames';
import CVTableEventsTransferGroups from '../../../Tables/CVTable/CVTableEventsTransferGroups';


const TransferEventTable = (props) => {
  const { t, events, total, excelBuffer, getExcel, page, getPage, totalPages, pageSize, establishmentId, getAvailableFacilities, establishmentSelected,
        getAllGlobalTransferEvents,globalTransferEvents} = props;
  const [fileUploadModalIsOpen, setFileUploadModalOpen] = useState(false);
  const [excelDownload, setExcelDownload] = useState(false);
  const templatesUrl = settings.templatesUrl;
  const [establishmentName, setEstablishmentName] = useState("");
  const [uploadedFilesError, setUploadedFilesError] = useState(false);
  const [globalEvents,setGlobalEvents] = useState([])
  const [pending,setPending]= useState(false)
  const alert = useAlert();
  const [activeTab, setActiveTab] = useState('1');
  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
}



  useEffect(() => {
    getAvailableFacilities();
    getPage(establishmentId, 1, 10)
    getAllGlobalTransferEvents(establishmentId)
  }, []);

    useEffect(() => {    
      getAvailableFacilities();
      getPage(establishmentId, 1, 10)
      getAllGlobalTransferEvents(establishmentId)
  }, [establishmentId]);

  useEffect( ()=> {
    if (globalTransferEvents!==null) {
      setGlobalEvents(globalTransferEvents.globalEvents)
    }
  },[globalTransferEvents])

  useEffect (() => {
    if (props.establishmentSelected!==undefined){
      setEstablishmentName(" del establecimiento "+props.establishmentSelected.name)
    }else{
      setEstablishmentName("")
    }
  },[props.establishmentSelected])

useEffect(() => {
  if (excelDownload===true && excelBuffer!=null && excelBuffer!=undefined)
  {   var blob=new Blob([new Uint8Array(excelBuffer.data)], {type: 'application/octet-stream' });
      var link=document.createElement('a');
      link.href=window.URL.createObjectURL(blob);
      link.download="eventos.xlsx";
      link.click();
      setExcelDownload(false);
  }
}, [excelBuffer]);

  const renderValueDefault = ({value}) => {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        {value}
      </div>
    )
  };

  const renderValueEstablishment = ({value}) => {
    let facility = props.facilities.find(function (facility) {
      return facility._id === value;
    });

    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        {facility ? facility.name : '-'}
      </div>
    )
  };

  const renderDate = ({value}) => {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        {value ?
          parse_UTC_date_to_DD_MM_YYYY(value):
          "-"
        }
      </div>
    )
  };

  const renderIconsForNewEvent = (downloadExcel=true) => (
    <div>
        {downloadExcel && <Tooltip
          title="Descargar lista de eventos"
          position="top"
          trigger="mouseenter"
          animation="scale"
          duration="600"
          size="small"
          theme="transparent"
          distance="0" >
            <FileTableOutlineIcon onClick={() => {if(events) {setExcelDownload(true);
                                                                getExcel(establishmentId);}}}/>
        </Tooltip>
        }
        <Tooltip
        title="Descargar plantilla"
        position="top"
        trigger="mouseenter"
        animation="scale"
        duration="600"
        size="small"
        theme="transparent"
        distance="0"
      >
      </Tooltip>
      <Tooltip
        title="Descargar plantilla"
        position="top"
        trigger="mouseenter"
        animation="scale"
        duration="600"
        size="small"
        theme="transparent"
        distance="0"
      >
        <a href={templatesUrl + "modelo evento transferencia.xls"} download><FileDownloadOutlineIcon/></a>
      </Tooltip>
      <FileUploadOutlineIcon onClick={() => {props.resetMassiveLoadEvents(); setFileUploadModalOpen(true)} }/>
    </div>
  );

  const heads = [
    {
      key: 'earId',
      name: t('events.transfer.table.headers.caravan_id'),
      percentage: 15,
      minWidth: 150,
      formatter: renderValueDefault
    },
    {
      key: 'date',
      name: t('events.transfer.table.headers.date'),
      percentage: 15,
      minWidth: 210,
      formatter: renderDate
    },
    {
      key: 'oldFacilityId',
      name: t('events.transfer.table.headers.old_establishment_id'),
      percentage: 35,
      minWidth: 210,
      formatter: renderValueEstablishment
    },
    {
      key: 'newFacilityId',
      name: t('events.transfer.table.headers.new_establishment_id'),
      percentage: 35,
      minWidth: 210,
      formatter: renderValueEstablishment
    }
  ];

  const checkFilesUploaded = (files) => {
    let c = {}
    
    for ( let d in files) {     
      files[d].hasOwnProperty('doc') && !files[d].hasOwnProperty('docTitle') && (c[d]= "Debe ingresar un título para el documento" ) 
    }

    if(Object.keys(c).length){ 
      setUploadedFilesError(c);
      return false
    }

    return true
  }

  return(
    (establishmentId) ? 
    <div>


<Nav tabs style={{marginTop:"5px"}}>
                    <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1'); }}
                    >
                        VISTA POR EVENTO
                    </NavLink>
                    </NavItem>
                    <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { toggle('2'); }}
                    >
                        VISTA POR ANIMAL
                    </NavLink>
                    </NavItem>
                    
                </Nav>
                
                <TabContent activeTab={activeTab} style={{width:"100%"}}>
                    <TabPane tabId="1" style={{width:"100%"}}>
                    <CVTableEventsTransferGroups
                          getPage={getPage}
                          data={globalEvents}
                          total={total}
                          currentPage={page}
                          totalPages={totalPages}
                          pageSize={pageSize}
                          heads={heads}
                          hideFilters
                          title={t('events.transfer.table.title')+establishmentName}
                          SubtitleComponent={()=>renderIconsForNewEvent(false)}
                          noDataLabel={t('events.transfer.table.no_data_label')}
                          establishmentId={establishmentId}
                         />
                    </TabPane>
                    <TabPane tabId="2" style={{width:"100%"}}>
                      <CVTableEvents
                          getPage={getPage}
                          data={events}
                          total={total}
                          currentPage={page}
                          totalPages={totalPages}
                          pageSize={pageSize}
                          heads={heads}
                          hideFilters
                          title={t('events.transfer.table.title')+establishmentName}
                          SubtitleComponent={renderIconsForNewEvent}
                          noDataLabel={t('events.transfer.table.no_data_label')}
                          establishmentId={establishmentId}
                         />



                    </TabPane>

                </TabContent>








      <FileUploadExtendedModal title='TRANFERENCIA' isOpen={fileUploadModalIsOpen}
                               searchPlaceholder={t('events.modals.massive_upload.search_placeholder')}
                               toggleModal={() => {if(fileUploadModalIsOpen) props.resetFacilitySearch(); setFileUploadModalOpen(!fileUploadModalIsOpen) }} 
                               acceptedFormats={".xls,.xlsx"}
                               availableFacilities={props.facilities}
                               uploadedFilesError={uploadedFilesError}
                               pending = {pending}
                               onSubmit={(file) => {
                                  
                                  const isValid = checkFilesUploaded(file)

                                  if(isValid) {
                                    setPending(true)
                                    props.uploadFileForMassiveLoad(props.facilitySearch._id, file, props.establishmentId, ()=>{
                                    props.history.push('/events/transfer/massive_load');
                                    });
                                  }
                                 
                               }}

                               />
    </div>
    : <ErrorNoEstablishment />

    
  );
};

const mapStateToProps = (state) => ({
  events: state.event[`${EVENT_TYPES.transfer}`] ?
    state.event[`${EVENT_TYPES.transfer}`].events : [],
  total: state.event[`${EVENT_TYPES.transfer}`] ?
    state.event[`${EVENT_TYPES.transfer}`].total : 0,
  page: state.event[`${EVENT_TYPES.transfer}`] ?
    state.event[`${EVENT_TYPES.transfer}`].page : 1,
  totalPages: state.event[`${EVENT_TYPES.transfer}`] ?
    state.event[`${EVENT_TYPES.transfer}`].totalPages : 0 ,
  pageSize: state.event[`${EVENT_TYPES.transfer}`] ?
    state.event[`${EVENT_TYPES.transfer}`].pageSize : 0,
  globalTransferEvents: state.event.globalTransferEvent?state.event.globalTransferEvent:null,
  establishmentId: state.user.selectedEstablishment != undefined ? state.user.selectedEstablishment._id : null,
  facilitySearch: state.generalData.facilitySearch ? state.generalData.facilitySearch.res : undefined,
  facilities: state.generalData.availableFacilities,
  excelBuffer: state.event[`${EVENT_TYPES.transfer}`] ? state.event.excelBuffer : null,
  establishmentSelected: state.user.selectedEstablishment,

});

const mapDispatchToProps = (dispatch) => ({
  resetFacilitySearch: () => dispatch(GeneralDataActions.resetFacilitySearch()),
  getAvailableFacilities: () => dispatch(GeneralDataActions.getAvailableFacilities()),
  getPage: (establishmentId, page, pageSize) => dispatch(eventActions.getEventsPage(establishmentId, page, pageSize, EVENT_TYPES.transfer)),
  getAllGlobalTransferEvents: (establishmentId) => {dispatch(eventActions.getAllGlobalTransferEvents(establishmentId, EVENT_TYPES.globalTransfer)) },
  uploadFileForMassiveLoad: (newFacility, file, establishmentId, successCallback, errorCallback) =>
    dispatch(eventActions.uploadFileForTransferEventMassiveLoad(newFacility, file, establishmentId, EVENT_TYPES.transfer, successCallback, errorCallback)),
  getExcel: (establishmentId) => dispatch(eventActions.getEventsExcel(establishmentId,  EVENT_TYPES.transfer)),
  resetMassiveLoadEvents: () => dispatch(eventActions.resetMassiveLoadEvents())
  });

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(TransferEventTable)));