import React from 'react';
import { Container, Row } from 'reactstrap';
import DeletionEventForm from "../NewEventForms/DeletionEventForm"
import { connect } from 'react-redux';
import actions from '../../../../redux/actions';
import {useAlert} from "react-alert";
import { withTranslation } from "react-i18next";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

const NewDeletionEventContainer = (props) => {
    const alert = useAlert();

    const addDeletionEvent = (values) => {        
        props.submitDeletionEvent(values, props.facilityId, () => {
            alert.success(props.t('events.alerts.event_added'));
            props.history.push('/events/deletion/table');
        }, (errorMessage) => {
            ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
        });
    };

    return (
        <Container>
         <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
            <Row>
                <DeletionEventForm
                  onSubmit={addDeletionEvent}
                  pending={props.addCowPending}
                  editable/>
            </Row>
        </Container>
    )
};

const mapStateToProps = (state) => ({
    addCowPending: state.ui.addCowPending, // todo change this!
    facilityId: state.user.selectedEstablishment._id
});

const mapDispatchToProps = (dispatch) => ({
    submitDeletionEvent: (values, facilityId, successCallback, errorCallback) =>
        dispatch(actions.event.submitDeletionEvent(values, facilityId, successCallback, errorCallback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(NewDeletionEventContainer));