import React, {useEffect, useState} from 'react';
import { withTranslation } from "react-i18next";
import CVTable from "../../../Tables/CVTable/CVTable";
import { connect } from "react-redux";
import actions from '../../../../redux/actions/index'
import { withRouter } from 'react-router-dom';
import MagnifyIcon from "mdi-react/MagnifyIcon";
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import 'react-tippy/dist/tippy.css'
import { ErrorNoEstablishment, ErrorSlaughterNotValid } from "../EventErrors";


const ThirdPartySlaughterTable = (props) => {
    const {
        t,
        userId,
        thirdPartySlaughterEvents,
        widthPercentage,
        currentCompany,
        currentEstablishment,
        establishmentId,
        defaultEstablishment,
        globalEventDetails
    } = props;

    const [allGlobalEvents, setAllGlobalEvents] = useState();
    const [selectedGlobalEvent, setSelectedGlobalEvent] = useState(false);
    const [globalEventName, setGlobalEventName] = useState();
    const [globalEventI18next, setGlobalEventI18next] = useState();
    const [menuGlobalEventName, setMenuGlobalEventName] = useState(props.menuGlobalEventName);

    

    useEffect(() => {
        if (!menuGlobalEventName) {
            if (currentEstablishment.type == "slaughterhouse") { 
                setMenuGlobalEventName('Faena')
                setGlobalEventName('Faena')
                setGlobalEventI18next('slaughter_event')
            } else if (currentEstablishment.type == "commercial") {
                setMenuGlobalEventName('FaenaEnTerceros')
                setGlobalEventName('FaenaEnTerceros')
                setGlobalEventI18next('third_party_slaughter_event')
            }
        }
    }, []);

    useEffect(() => {
        if(currentEstablishment) {
            props.getUserEstablishmentById(currentEstablishment._id, ()=> {});
            if (currentEstablishment.type == "slaughterhouse") { 
                setGlobalEventName('Faena')
                setGlobalEventI18next('slaughter_event')
            } else if (currentEstablishment.type == "commercial") {
                setGlobalEventName('FaenaEnTerceros')
                setGlobalEventI18next('third_party_slaughter_event')
            } else {
                setGlobalEventName('noFaena')
                setGlobalEventI18next('no_slaughter_event')
            }
            props.getAllGlobalEvent(userId, globalEventName, currentEstablishment._id)

        }else if(defaultEstablishment) {
            props.getUserEstablishmentById(defaultEstablishment._id, ()=> {});
            if (defaultEstablishment.type == "slaughterhouse") { 
                setGlobalEventName('Faena')
                setGlobalEventI18next('slaughter_event')
            }
            if (defaultEstablishment.type == "commercial") {
                setGlobalEventName('FaenaEnTerceros')
                setGlobalEventI18next('third_party_slaughter_event')
            } else {
                setGlobalEventName('noFaena')
                setGlobalEventI18next('no_slaughter_event')
            }
            props.getAllGlobalEvent(userId, globalEventName, defaultEstablishment._id)
        }
       
    }, [currentEstablishment, globalEventName]);

    useEffect(() => {
        //console.log("Debug [menuGlEvName]", userId, globalEventName, currentEstablishment._id)
        if (props.menuGlobalEventName == "Faena") {
            setMenuGlobalEventName('Faena')
            setGlobalEventI18next('slaughter_event')
        }
        if (props.menuGlobalEventName == 'FaenaEnTerceros') {
            setMenuGlobalEventName('FaenaEnTerceros')
            setGlobalEventI18next('third_party_slaughter_event')
        }
    }, [menuGlobalEventName, props.menuGlobalEventName]);

    useEffect(() => {
        //console.log("Debug [thirdPartySlaughter]", userId, globalEventName, currentEstablishment._id)
        setAllGlobalEvents(thirdPartySlaughterEvents);
    }, [thirdPartySlaughterEvents]);

    useEffect(() => {
        if (selectedGlobalEvent) props.history.push({
            pathname: '/events/third_party_slaughter/details',
            globalEventName: globalEventName,
            globalEventI18next: globalEventI18next
        })
    }, [globalEventDetails]);

    const renderValueDefault = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value}
            </div>
        )
    };

    const renderDate = ({value}) => {
        const date = new Date(value);
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {`${date.toLocaleDateString()}`}
            </div>
        )
    };

    const renderButtons = ({dependentValues}) => {
        return (
          <div style={{display: "flex", justifyContent: "center"}}>
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <MagnifyIcon
                className={"button"}
                style={{marginLeft: "10px"}}
                onClick={() => {
                    setSelectedGlobalEvent(true);
                    props.selectGlobalEventToView(dependentValues._id)
                }}
              />
          </div>
        );
    };

    const heads = [
        {
            key: 'globalEventName',
            name: t('events.third_party_slaughter.table.headers.event'),
            minWidth: 150,
            formatter: renderValueDefault
        },
        {
            key: 'companyName',
            name: t('events.third_party_slaughter.table.headers.companyName'),
            minWidth: 150,
            formatter: renderValueDefault
        },
        {
            key: 'facilityName',
            name: t('events.third_party_slaughter.table.headers.facilityName'),
            minWidth: 150,
            formatter: renderValueDefault
        },
        {
            key: 'lotName',
            name: t('events.third_party_slaughter.table.headers.lotName'),
            minWidth: 150,
            formatter: renderValueDefault
        },
        {
            key: 'eventDate',
            name: t('events.third_party_slaughter.table.headers.date'),
            minWidth: 150,
            formatter: renderDate
        },
        {
            key: 'animalsAffected',
            name: t('events.third_party_slaughter.table.headers.animalsAffected'),
            minWidth: 150,
            formatter: renderValueDefault
        },
        {
            key: 'actions',
            name: t('establishments.table.headers.actions'),
            minWidth: 150,
            formatter: renderButtons,
            getRowMetaData: (row) => row
        }
    ];

    const renderIconForAddingLots = () => (
        (currentEstablishment || defaultEstablishment) ?
        <div>
            <PlusCircleOutlineIcon
                onClick={() => props.history.push({
                    pathname: '/events/third_party_slaughter/new_event',
                    companyId: currentCompany._id,
                    facility: currentEstablishment._id,
                    foreignKey: '',
                })}
            />
        </div>
        : null
    );


    return(
        (establishmentId) ?
            (globalEventName == "Faena" || globalEventName == "FaenaEnTerceros") ? 
                <div>
                    { /* Cambiar CVTable por CVTableEventLots cuando esté creada */ }
                    <CVTable
                        getPage={props.getAllGlobalEvent}
                        data={allGlobalEvents}
                        heads={heads}
                        title={t('cv-forms.events.' + globalEventI18next + '.title')}
                        hideFilters
                        hidePagination
                        SubtitleComponent={renderIconForAddingLots}
                        widthPercentage={widthPercentage}
                        noDataLabel={t('cv-forms.events.' + globalEventI18next + '.noSlaughterLot')}
                        pending={props.getAllGlobalEventPending}
                    />
                </div>
            :<ErrorSlaughterNotValid />
        : <ErrorNoEstablishment />
    )
};

const mapStateToProps = (state) => ({
    userId: state.user.id,
    defaultEstablishment: state.user.allEstablishmentsByCompany[0],
    currentCompany: state.user.selectedCompany,
    currentEstablishment: state.user.selectedEstablishment,
    establishmentId: state.user.selectedEstablishment != undefined ? state.user.selectedEstablishment._id : null,
    establishments: state.user.establishments.results,
    currentPage: state.user.establishments.page,
    total: state.user.establishments.total,
    totalPages: state.user.establishments.totalPages,
    pageSize: state.user.establishments.pageSize,
    getAllGlobalEventPending: state.ui.getAllGlobalEventPending,
    menuGlobalEventName: state.router.location.menuGlobalEventName,
    thirdPartySlaughterEvents: state.thirdPartySlaughter.allGlobalEvents?
        state.thirdPartySlaughter.allGlobalEvents.globalEvents : [],
    globalEventDetails: state.thirdPartySlaughter.globalEventDetails?
        state.thirdPartySlaughter.globalEventDetails.globalEvents : [],
});

const mapDispatchToProps = (dispatch) => ({
    getUserEstablishmentById: (currentEstablishment) => dispatch(actions.user.getEstablishmentById(currentEstablishment)),
    getAllGlobalEvent: (userId, globalEventName, facilityId) => dispatch(actions.thirdPartySlaughter.getAllGlobalEvent(userId, globalEventName, facilityId)),
    selectGlobalEventToView: (globalEventId) => dispatch(actions.thirdPartySlaughter.getGlobalEventByIdThirdPartySlaughter(globalEventId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(ThirdPartySlaughterTable)));