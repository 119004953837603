import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {Button, Col, Container, Row} from 'reactstrap';
import ProfileMain from './components/ProfileMain';
import ProfileTabs from './components/ProfileTabs';
import userActions from '../../../redux/actions/userActions'
import {ROLES} from "../../../constants";
import CVModal from "../../UI/Modals/components/CVModal";
import {withTranslation} from "react-i18next";
import actions from "../../../redux/actions";
import {useAlert} from "react-alert";

const Calendar = (props) => {
    const [user, setUser] = useState();
    const [enableEditing, setEnableEditing] = useState(false);
    const [professionalLicense, setProfessionalLicense] = useState();

    const alert = useAlert();

    //Todo this must be uncommented. Now there is a problem with the user roles, when ask for the user's roles it returns
    // an empty array, because the mock api can not return the current role.
    /*useEffect(() => {
        props.getUser();
    }, []);*/

    useEffect(() => {
        setUser(props.user)
    }, props.user);

    const userIsLoaded = () => user !== undefined && user.profile !== undefined && user.id !== undefined;
    const userRolesAreLoaded = () => user !== undefined && user.roles !== undefined;

    const changeEditingMode = () => setEnableEditing(!enableEditing);

    const editUser = (values) => {
        props.editUser(values,
            () => changeEditingMode());
    };

    const selectProfessionalRol = (rol) => {                                  
            props.addRoleToUser(user.id, rol, professionalLicense, () => {
                alert.success(props.t('full_register.successful_role_selection_message',
                    {role: props.t(`common.roles.${rol}`)}), {timeout: 0});
            })                    
    };
    const selectProfessionalRolWithoutLicense = (rol) => {                        
            props.addRoleToUserWithoutLicense(user.id, rol, professionalLicense, () => {
                alert.success(props.t('full_register.successful_role_selection_message',
                    {role: props.t(`common.roles.${rol}`)}), {timeout: 0});
            })              
    };

    const renderRoleSelectionModalBody = () => (
        <div className="role__selection__modal">
            {(props.location.state.rolSelected==ROLES.AGRICULTURAL_ENGINEER) && ( 
            <h5 style={{marginBottom:"25px"}}>{props.t('full_register.modals.role_selection.enter_license_optative')}</h5>
            )}
            {(props.location.state.rolSelected==ROLES.VETERINARY) && ( 
            <h5 style={{marginBottom:"25px"}}>{props.t('full_register.modals.role_selection.enter_license_mandatory')}</h5>
            )}
            <div className="license_input">                                
                <input style={{flex:"1 1"}} type="text" name="license" placeholder={props.t('full_register.modals.role_selection.enter_license')}
                       onChange={(e) => setProfessionalLicense(e.target.value)}/>                
                <Button style={{padding:"5px 5px"}} color="primary" type="button" onClick={() => selectProfessionalRol(props.location.state.rolSelected)}
                        disabled={(!professionalLicense || professionalLicense === "")}>Guardar</Button>                
                {(props.location.state.rolSelected==ROLES.AGRICULTURAL_ENGINEER) && ( 
                <Button style={{padding:"5px 5px"}} color="danger" type="button" onClick={() => selectProfessionalRolWithoutLicense(props.location.state.rolSelected)}
                        disabled={(!professionalLicense || professionalLicense === "") && props.location.state.rolSelected!=ROLES.AGRICULTURAL_ENGINEER}>Omitir</Button>
                )
                }
            </div>
            <div className="license_input">                                
            
            </div>
        </div>
    );

    const licenseNumberModal = () => {        
        if(!props.user) return null;
        else if(props.user.roles[0].name === ROLES.WITH_OUT_ROLE){            
            return (
                <CVModal
                    title={props.t('profile.modals.attention.title')}
                    body={() => renderRoleSelectionModalBody()}
                    color="primary"
                    header
                    isOpen={true}
                    toggle={() => {
                    }}
                    hideButtons={true}
                    hideCloseButton={true}
                />
            );
        }
        else return null;
    };

    return (
        <Container>
            <div className="profile">
                <Row>
                    <Col md={12} lg={12} xl={4}>
                        <Row>
                            {
                                !userIsLoaded() ?
                                    null:
                                    <ProfileMain user={user}
                                                 enableEditing={enableEditing}
                                                 changeEditingMode={changeEditingMode}
                                                 editUser={editUser}
                                                 editUserPending={props.editUserPending}
                                                 editUserAvatar={props.editUserAvatar}/>
                            }
                        </Row>
                    </Col>
                    {
                        !userRolesAreLoaded() ?
                            null:
                            <ProfileTabs roles={user.roles}/>
                    }
                </Row>
            </div>
            {licenseNumberModal()}
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        editUserPending: state.ui.editUserPending
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: () => dispatch(userActions.getUser()),
        editUser: (data, callback) => dispatch(userActions.editUserData(data, callback)),
        editUserAvatar: (image, callback) => dispatch(userActions.editUserAvatar(image, callback)),
        addRoleToUser: (userId, rol, license, callback) => dispatch(actions.user.addRoleToUser(userId, rol, license, callback)),
        addRoleToUserWithoutLicense: (userId, rol, license, callback) => dispatch(actions.user.addRoleToUserWithoutLicense(userId, rol, license, callback)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Calendar));
