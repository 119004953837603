import React, {useState, useEffect} from 'react';
import {Col, Container, Row, Card, CardBody, Button} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SecretWordAndPinForm from "./component/SecretWordAndPinForm";

const GenerateSecretWordAndPin = ({ t, location }) => {
    const [mnemonic, setMnemonic] = useState([]);

    useEffect(() => {
        if(location.state.mnemonic) setMnemonic(location.state.mnemonic)
    }, [location.state]);

    return (
        <Container className="verify__mnemonic">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">{t('wallet.generate_secret_and_pin.title')}</h3>
                    <h3 className="page-subhead subhead">{t('wallet.generate_secret_and_pin.subtitle')}</h3>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={{size: 8, offset: 2}}>
                    <Card>
                        <CardBody>
                            <SecretWordAndPinForm mnemonic={mnemonic}/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
};

GenerateSecretWordAndPin.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(GenerateSecretWordAndPin);
