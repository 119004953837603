import React from 'react';
import { Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import actions from '../../../../redux/actions';
import { useAlert } from "react-alert";
import { withTranslation } from "react-i18next";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import ThirdPartySlaughterEventForm from "../NewEventForms/ThirdPartySlaughterEventForm"

const NewThirdPartySlaughterEventContainer = (props) => {
  const alert = useAlert();

  const addThirdPartySlaughterEvent = (values) => {
    props.submitThirdPartySlaughterEvent(values, props.facilityId, () => {
        alert.success(props.t('events.alerts.event_added'));
        props.history.push('/events/third_party_slaughter/table');
    }, (errorMessage) => {
        ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
    });
  };

  let event = null;
  if (props.location != null){
    event = props.location;
  }

  const mapInitialValues = (event) => {
    if (event)
    return {
      // Valores para mostrar desdeo el Camión (Lote)
      companyId: event.companyId,
      facilityId: event.facility,
      lotId: event.foreignKey,
      entityType: "lot",
      viewOnlyActiveLots: true,
      isSlaughter: false
    }
    // Valores para un evento nuevo (Faena)
    return {
        entityType: "lot",
        viewOnlyActiveLots: true,
        isSlaughter: false
      };
  };

  return (
    <Container>
      <Row>
        <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
        <ThirdPartySlaughterEventForm
          onSubmit={addThirdPartySlaughterEvent}
          pending={props.addGlobalEventPending}
          editable={true}
          isSlaughter={false}
          initialValues={mapInitialValues(event)}
          />
      </Row>
    </Container>
  )
};

const mapStateToProps = (state) => ({
  addGlobalEventPending: state.ui.addGlobalEventPending, // todo change this!
});

const mapDispatchToProps = (dispatch) => ({
  submitThirdPartySlaughterEvent: (values, facilityId, successCallback, errorCallback) =>
    dispatch(actions.thirdPartySlaughter.submitThirdPartySlaughterEvent(values, facilityId, successCallback, errorCallback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(NewThirdPartySlaughterEventContainer));