import {createBrowserHistory} from 'history'
import {compose, combineReducers, applyMiddleware, createStore} from 'redux';
import {persistStore, persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage/session'
import {connectRouter, routerMiddleware} from 'connected-react-router'
import {reducer as reduxFormReducer} from 'redux-form';
import logger from 'redux-logger';


import {
    cryptoTableReducer,
    newOrderTableReducer,
    sidebarReducer,
    themeReducer,
    customizerReducer,
    todoReducer,
    rtlReducer,
    userReducer,
    uiReducer,
    notificationReducer,
    animalReducer,
    sessionReducer,
    adminReducer,
    eventReducer,
    generalDataReducer,
    walletReducer,
    constantReducer,
    biReducer,
    templateReducer,
    certificateReducer,
    reportReducer,
    globalEventReducer,
    thirdPartySlaughterReducer,
    qrGenerationReducer,
    servicesStatusReducer
} from '../../redux/reducers/index';

import {
    user,
    session,
    notification,
    animal,
    admin,
    event,
    generalData,
    wallet,
    auth,
    constant,
    image,
    bi,
    template,
    certificate,
    report,
    globalEvent,
    thirdPartySlaughter,
    qrGeneration,
    servicesStatus
} from '../../redux/middlewares/index'

export const history = createBrowserHistory();

const reducer = combineReducers({
    router: connectRouter(history),
    form: reduxFormReducer, // mounted under "form",
    theme: themeReducer,
    rtl: rtlReducer,
    sidebar: sidebarReducer,
    cryptoTable: cryptoTableReducer,
    newOrder: newOrderTableReducer,
    customizer: customizerReducer,
    todos: todoReducer,
    user: userReducer,
    ui: uiReducer,
    notification: notificationReducer,
    animal: animalReducer,
    session: sessionReducer,
    admin: adminReducer,
    event: eventReducer,
    generalData: generalDataReducer,
    wallet: walletReducer,
    constant: constantReducer,
    bi: biReducer,
    template: templateReducer,
    certificate: certificateReducer,
    report: reportReducer,
    globalEvent: globalEventReducer,
    thirdPartySlaughter: thirdPartySlaughterReducer,
    qrGeneration:qrGenerationReducer,
    servicesStatus: servicesStatusReducer
});

const middleware = applyMiddleware(
    routerMiddleware(history) ,
    // logger,
    user,
    session,
    notification,
    animal,
    admin,
    event,
    generalData,
    wallet,
    auth,
    constant,
    image,
    bi,
    template,
    certificate,
    report,
    globalEvent,
    thirdPartySlaughter,
    qrGeneration,
    servicesStatus
);

const persistConf = {
    key: 'root',
    storage: storage,
    whitelist: ['session', 'user']
};

const pReducer = persistReducer(persistConf, reducer);

const store = createStore(pReducer, middleware);
export const persistor = persistStore(store);

export default store;
