/*
*   The session mapper is use for mapping either the body or the response on a session request. It try to decouple the
*   session middleware.
* */

const mapper = {
    body: {
        fullRegister: (data) => {
            let result = {
                name: data.firstName,
                lastName: data.lastName,
                birthday: data.birthDate,
                document: data.dni,
                mobilePhoneNumber: data.cellphone,
                linkedin: data.linkedin,
                web: data.web,
                address: {
                    ...data.personalAddress,
                    country: data.personalAddress.country.value,
                    state: data.personalAddress.state.value,
                },
                fullRegister: true
            };

            // Optionals
            if(data.phone){
                result['phoneNumber'] = data.phone;
            }
            return result;
        }
    },
    response: {
        basicRegister: (response) => ({
            id: response._id,
            country: response.country,
            createdAt: response.createdAt,
            updatedAt: response.updatedAt,
            email: response.email
        })
    }
};

export default mapper;