import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import CVTable from '../../Tables/CVTable/CVTable';
import ArrowRightCircleOutlineIcon from 'mdi-react/ArrowRightCircleOutlineIcon';
import { Redirect } from 'react-router-dom';
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import AddDataModal from "./AddDataModal";

const GeneralDataList = (props) => {
    const { t, data, currentPage, total, totalPages, pageSize, getPage } = props;
    const [redirectRoute, serRedirectRoute] = useState();
    const [addGeneralDataModalOpen, setAddGeneralDataModalOpen] = useState();

    const renderValueDefault = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value}
            </div>
        )
    };

    const renderButtons = ({ dependentValues }) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <ArrowRightCircleOutlineIcon className={"button"} style={{marginLeft: "10px"}}
                                             onClick={() => serRedirectRoute(`/generalData/${dependentValues.data_id}`)}/>
            </div>
        )
    };

    const renderIconForAddingElement = () => (
        <div>
            <PlusCircleOutlineIcon onClick={() => setAddGeneralDataModalOpen(true)}/>
        </div>
    );

    const addGeneralDataModal = () => {
        if(addGeneralDataModalOpen) return (
            <AddDataModal open={addGeneralDataModalOpen} toggle={() => setAddGeneralDataModalOpen(!addGeneralDataModalOpen)}
                          acceptFunc={(value) => {
                              props.addGeneralData(value);
                              setAddGeneralDataModalOpen(!addGeneralDataModalOpen)
                          }}/>
        );
        return null
    };

    const heads = [
        {
            key: 'data_name',
            name: t('roles.table.headers.name'),
            percentage: 60,
            formatter: renderValueDefault
        },
        {
            key: 'actions',
            name: t('roles.table.headers.actions'),
            percentage: 40,
            formatter: renderButtons,
            getRowMetaData: (row) => row
        }
    ];

    return (
        <div>
            <CVTable
                getPage={getPage}
                data={data}
                total={total}
                totalPages={totalPages}
                pageSize={pageSize}
                currentPage={currentPage}
                heads={heads}
                title={t('general_data.table.title')}
                hideFilters
                SubtitleComponent={renderIconForAddingElement}
                noDataLabel={t('general_data.table.no_data_label')}
            />
            {redirectRoute && <Redirect to={redirectRoute}/>}
            {addGeneralDataModal()}
        </div>

    )
};

export default withTranslation('common')(GeneralDataList)
