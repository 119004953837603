import _ from 'lodash';
import {EVENT_TYPES} from "../constants";
import { ROLES } from "../constants";

const requestTimeout = 500;
const okResponse = { result: "ok"};

/* ~~~~~~~~~~~~~~~~~~ DUMMY DATA ~~~~~~~~~~~~~~~~~~ */
const countriesList = [
    {
        value: 'ARGENTINA'
    }/*,
    {
        value: 'URUGUAY'
    },
    {
        value: 'BRASIL'
    },
    {
        value: 'PARAGUAY'
    }*/
];
const address1 = {
    streetName: "Av. Rivadavia",
    streetNumber: "1245",
    apartment: "2B",
    postalCode: "1033",
    city: "Monserrat",
    state: "Buenos Aires",
    country: {value: 'ARGENTINA'}
};
const address2 = {
    streetName: "Ruta 8",
    streetNumber: "3420",
    apartment: "",
    postalCode: "S2600",
    city: "Venado Tuerto",
    state: "Santa Fe",
    country: {value: 'ARGENTINA'}
};
const adminProfile = {
    document: '30546192',
    taxId: '12341234',
    name: 'Juan',
    lastName: 'Lupanni',
    birthday: new Date("December 1, 1995 03:24:00"),
    deviceId: '12341234',
    avatar: 'https://picsum.photos/200',
    linkedin: 'juanLupanni@gmail.com',
    web: 'www.juanLupanni.com.ar',
    bio: '',
    homeAddress: address1,
    commercialAddress: address2,
    cellular: '01130406012',
    telephone: '0230453863',
};
const titularProfile = {
    document: '30546192',
    taxId: '12341234',
    name: 'Pedro',
    lastName: 'Rodriguez',
    birthday: new Date("December 17, 1995 03:24:00"),
    deviceId: '12341234',
    avatar: 'https://picsum.photos/200',
    linkedin: 'pedrorodriguez@gmail.com',
    web: 'www.pedrorodriguez.com.ar',
    bio: '',
    homeAddress: address1,
    commercialAddress: address2,
    cellular: '01130406098',
    telephone: '0230453890'
};
const roles = {
    titular: {
        name: ROLES.TITULAR,
        defaultPanel: '',
        actions: []
    },
    admin: {
        name: ROLES.ADMIN,
        defaultPanel: '',
        actions: []
    },
    with_out_role: {
        name: ROLES.WITH_OUT_ROLE,
        defaultPanel: '',
        actions: []
    }
};
const adminUser = {
    id: '1',
    createdAt: Date.now(),
    updatedAt: Date.now(),
    appId: {
        id: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        name: undefined,
        apiKey: undefined
    },
    email: 'admin@mail.com',
    password: 'admin',
    profile: adminProfile,
    roles: [roles.admin],
    accounts: [],
    name: 'Juan',
    surname: 'Lupanni',
    cuit: 2234367324,
};
const titularUser = {
    id: '2',
    createdAt: Date.now(),
    updatedAt: Date.now(),
    appId: {
        id: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        name: undefined,
        apiKey: undefined
    },
    email: 'titular@mail.com',
    password: 'titular',
    profile: titularProfile,
    roles: [roles.titular],
    accounts: [{id: 1, legalName: "Frigorificos Roberto", createdAt: new Date().toDateString()}],
    name: 'Pedro',
    surname: 'Rodriguez',
    cuit: 2234367234,
};

const cowBreedsConst = {
    ANGUS: 'ANGUS',
    HEREFORD: 'HEREFORD'
};

const generalDataMockInfo = [
    {
        data_id: 0,
        data_name: 'Especies',
        values: [
            {
                name: "Bos taurus",
                creation_date: new Date(2018, 1, 1).toLocaleString(),
                last_updated_date: new Date(2018, 12, 20).toLocaleString()
            }
        ]
    },
    {
        data_id: 1,
        data_name: 'Razas',
        values: [
            {
                name: cowBreedsConst.ANGUS,
                creation_date: new Date(2018, 1, 1).toLocaleString(),
                last_updated_date: new Date(2018, 12, 20).toLocaleString()
            },
            {
                name: cowBreedsConst.HEREFORD,
                creation_date: new Date(2018, 1, 1).toLocaleString(),
                last_updated_date: new Date(2018, 12, 20).toLocaleString()
            }
        ]
    }
];
const productiveSystemsConst = {
    PASTURELAND: 'PASTURELAND',
    FEEDLOT: 'FEEDLOT',
    MIXED: 'MIXED'
};
const allEstablishments = [
    {
        id: 1,
        name: 'Establecimiento 1',
        userId: 1
    },
    {
        id: 2,
        name: 'Establecimiento 2',
        userId: 1
    },
    {
        id: 3,
        name: 'Establecimiento 3',
        userId: 2
    },
    {
        id: 4,
        name: 'Establecimiento 4',
        userId: 2
    },
    {
        id: 5,
        name: 'Establecimiento 5',
        userId: 2
    }
];

const cowBreedsList = [
    {
        value: cowBreedsConst.ANGUS
    },
    {
        value: cowBreedsConst.HEREFORD
    }
];

const createUserWallet = (address, encryptedMnemonic) => (
    {
        id: _.uniqueId('wallet_'),
        createdAt: Date.now(),
        updatedAt: Date.now(),
        address: address,
        encryptedMnemonic: encryptedMnemonic,
        active: true
    }
);

const allUserWallets = [];

/* ~~~~~~~~~~~~~~~~~~ USEFUL FUNCTIONS ~~~~~~~~~~~~~~~~~~ */
const createUser = (id, email, password, country) => (
    {
        id: id.toString(),
        createdAt: Date.now().toLocaleString(),
        updatedAt: Date.now().toLocaleString(),
        appId: {
            id: undefined,
            createdAt: undefined,
            updatedAt: undefined,
            name: undefined,
            apiKey: undefined
        },
        email: email,
        password: password,
        profile: undefined,
        roles: [roles.with_out_role],
        accounts: [],
        name: undefined,
        surname: undefined,
        cuit: undefined,
    }
);

const getRandomDate = (startDate, endDate) => {
    const date = new Date(+startDate + (Math.random() * (endDate - startDate)));
    return `${date.getUTCFullYear()}/${date.getUTCDay()}/${date.getUTCMonth()}`
};

const getRandomDateAndTime = (startDate, endDate) => {
    const date = new Date(+startDate + Math.random() * (endDate - startDate));
    const hour = startDate.getHours() + Math.random() * (endDate.getHours() - startDate.getHours()) | 0;
    const minutes = startDate.getMinutes() + Math.random() * (endDate.getMinutes() - startDate.getMinutes()) | 0;
    date.setHours(hour, minutes);
    return `${date.getUTCFullYear()}/${date.getUTCDay() + 1}/${date.getUTCMonth()} ${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}`
};

const getRandomDNI = () => (Math.floor(10000000 + Math.random() * 90000000)).toString();

const getRandomCellular = () => "11" + (Math.floor(10000000 + Math.random() * 90000000)).toString();

const getRandomTelephone = () => "4" + (Math.floor(1000000 + Math.random() * 9000000)).toString();

const generateRandomNotifications = (numberOfNotifications) => {
    const notifications = [];
    for (let i = 1; i < numberOfNotifications + 1; i += 1) {
        const nameAndSurname = ['Maria de lo Angeles', 'Federico Lagomarsino', 'Juan Pedro', 'Romina Villegas'][Math.floor((Math.random() * 4))];

        notifications.push({
            id: i,
            sender: nameAndSurname,
            role: ['VETERINARY', 'ESTABLISHMENT'][Math.floor((Math.random() * 2))],
            date: getRandomDateAndTime(new Date(2019, 3, 1, 0,0),
                new Date(2019, 9, 1, 23,59)).toLocaleString(),
            details: {
                nameAndSurname: nameAndSurname,
                dateOfBirth: getRandomDate(new Date(1980, 1, 1),
                    new Date(1995, 1, 1)),
                dni: getRandomDNI(),
                cellular: getRandomCellular(),
                telephone: getRandomTelephone(),
                address: {
                    streetName: "Zapiola",
                    number: "4248",
                    apartment: "2B",
                    postalCode: "8174",
                    city: "Saavedra",
                    state: "Buenos Aires",
                    country: {
                        name: "Argentina"
                    }
                }
            }
        });
    }
    return notifications;
};

const generateRandomAnimals = (numberOfAnimals, idStartIndex) => {
    const animals = [];
    for (let i = 1; i < numberOfAnimals + 1; i += 1) {
        animals.push({
            id: idStartIndex + i,
            caravan_id: Math.round(Math.random() * 1000),
            breed: [cowBreedsConst.ANGUS, cowBreedsConst.HEREFORD][Math.floor((Math.random() * 2))],
            species: ['BT', 'BT'][Math.floor((Math.random() * 2))],
            sex: ['male', 'female'][Math.floor((Math.random() * 2))],
            category: ['VACA', 'VAQUILLONA', 'NOVILLO', 'TERNERO'][Math.floor((Math.random() * 4))],
            birth_date: getRandomDateAndTime(new Date(2019, 3, 1, 0,0),
                new Date(2019, 9, 1, 23,59)).toLocaleString(),
            born_weight: [20, 25, 30, 35][Math.floor((Math.random() * 4))],
            age_in_months: [10, 30, 50, 60][Math.floor((Math.random() * 4))],
            father_id: ['VV101', 'VV132', 'VV142', 'VV173'][Math.floor((Math.random() * 4))],
            mother_id: ['VV201', 'VV232', 'VV242', 'VV293'][Math.floor((Math.random() * 4))],
        });
    }
    return animals;
};

const getRandomAccount = () => {
    return [
        {id: 2, legalName: 'Carnes Pacheco', createdAt: new Date().toDateString()},
        {id: 3, legalName: 'Frigorifico Mato', createdAt: new Date().toDateString()},
        {id: 4, legalName: 'Carnes del Sur', createdAt: new Date().toDateString()},
        {id: 5, legalName: 'Frigorifico Ruben', createdAt: new Date().toDateString()}][Math.floor((Math.random() * 4))];
};

const generateRandomUsers = (numberOfUsers) => {
    const users = [];
    for (let i = 3; i <= numberOfUsers + 2; i += 1) {
        const name = ['Maria', 'Federico', 'Juan', 'Romina'][Math.floor((Math.random() * 4))];
        const surname = ['Lagomarsino', 'Pedro', 'Villegas', 'Rodriguez'][Math.floor((Math.random() * 4))];
        const cuit = ['203456761', '223436751', '216236351', '176096151'][Math.floor((Math.random() * 4))];
        const account1 = getRandomAccount();
        let account2 = getRandomAccount();

        while(account2 === account1) account2 = getRandomAccount();

        users.push({
            id: i,
            name: name,
            surname: surname,
            cuit: cuit,
            accounts: [account1, account2],
            roles: [roles.titular]
        })
    }
    return users;
};

const getAllTheRoles = () => {
    return [
        {
            id: 0,
            role_name: ROLES.ADMIN,
            created_by: 'Carnes Validadas'
        },
        {
            id: 1,
            role_name: ROLES.TITULAR,
            created_by: 'Martina Gonzales'
        },
        {
            id: 2,
            role_name: ROLES.VETERINARY,
            created_by: 'Jose Carlos'
        }
    ];
};

const generateGeneralDataTitles = (numberOfGeneralDataTitles) => {
    let generalDataTitles = [
        {
            data_name: 'Especies',
            data_id: 0,
        },
        {
            data_name: 'Razas',
            data_id: 1,
        }
    ];
    for(let i = 2; i <= numberOfGeneralDataTitles; i++){
        generalDataTitles.push({
            data_name: `Dato General ${i}`,
            data_id: i
        })
    }
    return generalDataTitles;
};

const getEventData = (eventName) => {
    switch (eventName) {
        case EVENT_TYPES.weight:
            return {
                weight_kg: Math.floor((Math.random() * 750)) + 35
            };
        case EVENT_TYPES.dentition:
            return {
                teeth_table_value: 5
            };
        case EVENT_TYPES.change_of_productive_system:
            return {
                productive_system: [productiveSystemsConst.PASTURELAND, productiveSystemsConst.FEEDLOT,
                    productiveSystemsConst.MIXED][Math.floor((Math.random() * 3))],
                feeding: ['Alimentación 1', 'Alimentación 2',
                    'Alimentación 3'][Math.floor((Math.random() * 3))]
            };
        case EVENT_TYPES.caravan_re_identification:
            return {
                new_caravan: Math.floor((Math.random() * 10000))
            };
        case EVENT_TYPES.change_of_category:
            return {
                new_category: ['Categoría 1', 'Categoría 2',
                    'Categoría 3'][Math.floor((Math.random() * 3))]
            };
        default:
            return {};
    }
};

const generateRandomEvents = (numberOfEvents, startIndex) => {
    let events = [];
    for (let i = 1; i < numberOfEvents; i ++) {
        const name = [EVENT_TYPES.weight, EVENT_TYPES.dentition, EVENT_TYPES.castration,
            EVENT_TYPES.change_of_productive_system, EVENT_TYPES.caravan_re_identification,
            EVENT_TYPES.change_of_category, EVENT_TYPES.fain, EVENT_TYPES.vaccination][Math.floor((Math.random() * 8))];
        const date = getRandomDateAndTime(new Date(2019, 3, 1, 0,0),
            new Date(2019, 9, 1, 23,59)).toLocaleString();

        events.push({
            id: startIndex + i,
            name: name,
            date: date,
            ...getEventData(name)
        })
    }
    return events;
};

const searchUserById = (id) => {
    return allUsers.filter(user => user.id === id)[0]
};

const searchUserByEmail = (email) => {
    return allUsers.filter(user => user.email === email)[0]
};

const modifyUserById = (userId, modifyUser) => {
    for(let i = 0; i < allUsers.length; i++){
        if(allUsers[i].id === userId){
            allUsers[i] = modifyUser;
            return allUsers[i];
        }
    }
};

const getUserEstablishments = (userId) => {
    return allEstablishments.filter((establishment) => (establishment.userId.toString() === userId));
};

const checkCredentials = (email, password) => {
    return searchUserByEmail(email).password === password
};

const addRoleToUserService = (userId, role) => {
    let user = searchUserById(userId);
    let userRolesModify = user.roles.filter((userRol) => userRol.name !== roles.with_out_role.name);
    userRolesModify.push({
        name: role,
        defaultPanel: '',
        actions: []
    });
    user.roles = userRolesModify;
    modifyUserById(userId, user);

    return user.roles;
};

const generateUserWallet = (userId, address, encryptedMnemonic) => {
    const walletCreated = createUserWallet(address, encryptedMnemonic);
    allUserWallets.push({userId, wallet: walletCreated});
    return walletCreated;
};

const getUserWallet = (userId) => {
    return allUserWallets.filter(wallet => wallet.userId === userId)[0]
};

/* ~~~~~~~~~~~~~~~~~~ VARIABLES FOR PAGINATED REQUEST ~~~~~~~~~~~~~~~~~~ */

let allUsers = [adminUser, titularUser, ...generateRandomUsers(100)];

let allNotifications = generateRandomNotifications(300);

let allRoles = getAllTheRoles();

let allAnimals = generateRandomAnimals(300, 0);

let allGeneralDataTitles = generateGeneralDataTitles(50);

let allEvents = generateRandomEvents(100, 0);

const getPage = (allResults, pageNumber, pageSize) => {
    const from = pageSize * (pageNumber - 1);
    const to = from + pageSize;
    return allResults.slice(from, to);
};

const createPaginatedResultsResponseBody = (allResults, pageNumber, pageSize, page) => {
    return {
        page: pageNumber,
        pages: allResults.length % pageSize === 0 ?
            Math.floor(allResults.length / pageSize) :
            Math.floor(allResults.length / pageSize) + 1,
        total: allResults.length,
        limit: pageSize,
        results: page
    }
};

const searchInNotifications = (word) => {
    allNotifications = allNotifications.filter((notification) => {
        return notification.sender.toUpperCase().includes(word.toUpperCase()) ||
            notification.role.toUpperCase().includes(word.toUpperCase()) ||
            notification.date.includes(word)
    });
    return allNotifications
};

const filterInNotifications = (filters) => {
    const filterArray = Object.entries(filters);
    let filteredNotifications = [];

    filterArray.forEach(filter => {
        //  If the filter is 'activated' (true)
        if (filter[1]) {
            filteredNotifications = filteredNotifications.concat(allNotifications.filter(notification => {
                return notification.role === filter[0];
            }));
        }
    });
    allNotifications = filteredNotifications;
    return allNotifications;
};

const searchInAnimals = (word) => {
    allAnimals = allAnimals.filter((animal) => {
        return animal.sender.toUpperCase().includes(word.toUpperCase()) ||
            animal.role.toUpperCase().includes(word.toUpperCase()) ||
            animal.date.includes(word)
    });
    return allAnimals
};

const filterInAnimals = (filters) => {
    const filterArray = Object.entries(filters);
    let filteredAnimals = [];

    filterArray.forEach(filter => {
        //  If the filter is 'activated' (true)
        if (filter[1]) {
            filteredAnimals = filteredAnimals.concat(allAnimals.filter(animal => {
                return (animal.breed === filter[0] || animal.species === filter[0] || animal.sex === filter[0] || animal.category === filter[0]);
            }));
        }
    });
    allAnimals = filteredAnimals;
    return allAnimals;
};

const searchInEvents = (word) => {
    allEvents = allEvents.filter((event) => {
        return event.name.toUpperCase().includes(word.toUpperCase()) ||
            event.date.includes(word)
    });
    return allEvents;
};

const filterInEvents = (filters) => {
    const filterArray = Object.entries(filters);
    let filteredEvents = [];

    filterArray.forEach(filter => {
        //  If the filter is 'activated' (true)
        if (filter[1]) {
            filteredEvents = filteredEvents.concat(allEvents.filter(event => {
                return event.name === filter[0];
            }));
        }
    });
    allEvents = filteredEvents;
    return allEvents;
};

const setUserAsBlocked = (userId) => {
    for (let i = 0 ; i < allUsers.length; i++){
        if(allUsers[i].id === userId) {
            allUsers[i].block = true;
            break;
        }
    }
};

const getUserAccountsService = (userId) => {
    let user = searchUserById(userId);
    if(user) return user.accounts;
    return []
};

const addCompanyToUserService = (userId, company) => {
    let userModify;
    allUsers.map(user => {
        if(user.id.toString() === userId) {
            let id = user.accounts.length === 0 ? 1 : user.accounts[user.accounts.length -1].id + 1;
            user.accounts.push({id: id, ...company});
            userModify = user;
        }
        return user;
    });
    return userModify.accounts;
};

const addEstablishmentToCompanyService = (userId, accountId, establishment) => {
    let userModify;
    allUsers.map(user => {
        if(user.id.toString() === userId) {
            user.accounts.map(account => {
                if(account.id === accountId) {
                    if(account.establishments) account.establishments.push(establishment);
                    else account[establishment] = [establishment];
                    userModify = user;
                }
                return account;
            });
        }
        return user;
    });
    return userModify.accounts;
};

/* ~~~~~~~~~~~~~~~~~~ MOCK API FUNCTIONS ~~~~~~~~~~~~~~~~~~ */

export function login(email, password) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const response = {
                jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVkY2RhODZlN2I2N2E2NDBmNjk0MjlhMiIsImV4cGlyZXMiOjE1Nzg2MTYyNTMxNDIsInJlZnJlc2giOnRydWUsImlhdCI6MTU3Mzc3Nzg1M30.1Qr6ZHxbf4Io3qbbhi5J8iSD9Nb4RtSAB2Z7K89rvwk",
                jwtExpiration: Date.now() + (8.64e+7),
                refreshToken: "eyJhbGciOiJIUzI1NiJ9.YjZkNzU2MmUzZGI3ZjFlMDM5YTJhYmNiYjllZWQxMWUwZjRkYzlhOTQwYzk2NjI1MjVjYTYyOTNlMmQzN2MwMzYzZjNkMTEyN2QzNTgyYWU0MGU5ZjQ1YTdiYjhlYTc5TFdiWlZpTDhHRE02Umx2MDFKNmNwL1ZGRGczVnhYT2JhNGdHRm5QY1kxRlJjK29kREI1b3NtVUV5RTB2UklaNytJa1VBb21YK1hKTTdGci9ZNkF6UEU3QlE2S1JHQnF1eGpEeFB3cjU1UTg9.4iqhMeANsc8vzFWXnHOtCQstG79JuE0c6lJWjQKMjPw",
                refreshExpiration: Date.now() + (2 * (8.64e+7))
            };
            let user = searchUserByEmail(email);
            if(user){
                if(user.password === password){
                    response.userId = user.id;
                    resolve(response);
                }
                else reject({error: "WRONG_CREDENTIAL", code: 'LG001'});
            }
            else reject({error: "USER_NOT_FOUND", code: 'LG001'});
        }, requestTimeout)
    });
}

export function basicRegister(email, password, country) {
    return new Promise((resolve) => {
        setTimeout(() => {
            const newUser = createUser(allUsers[allUsers.length-1].id + 1, email, password, country);
            allUsers.push(newUser);
            resolve({user: newUser})
        }, requestTimeout)
    })
}

export function fullRegister(userId, info) {
    let userUpdated = {};
    return new Promise((resolve) => {
        setTimeout(() => {
            allUsers.map((user) => {
                if (user.id === userId) {
                    user.name = info.name;
                    user.surname = info.surname;
                    user.profile = {
                        birthday: info.birthday,
                        document: info.dni,
                        cellular: info.cellular,
                        telephone: info.telephone,
                        homeAddress: {
                            streetName: info.address.streetName,
                            streetNumber: info.address.streetNumber,
                            apartment: info.address.apartment,
                            postalCode: info.address.postalCode,
                            city: info.address.city,
                            state: info.address.state,
                            country: info.address.country
                        },
                        avatar: 'https://picsum.photos/200'
                    };
                    userUpdated = user;
                }
                return user;
            });
            resolve({user: userUpdated})
        }, requestTimeout)
    })
}

export function getAvailableCountries() {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                countries: countriesList
            })
        }, requestTimeout)
    })
}

export function getUserProfile(userId) {
    return new Promise(resolve => {
        setTimeout(() => {
            const userFound = searchUserById(userId);
            resolve({
                profile: userFound.profile
            })
        }, requestTimeout)
    })
}

export function getUserRoles() {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({
                roles: roles
            })
        }, requestTimeout)
    })
}

export function editUserData(values) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve(values)
        }, requestTimeout)
    })
}

export function editUserAvatar(image) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve(image)
        }, requestTimeout)
    })
}

export function getNotifications(pageNumber, pageSize) {
    return new Promise(resolve => {
        setTimeout(() => {
            const pageNotification = getPage(allNotifications, pageNumber, pageSize);
            resolve(createPaginatedResultsResponseBody(allNotifications, pageNumber, pageSize, pageNotification))
        }, requestTimeout)
    })
}

export function acceptNotificationRol(notificationId) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({...okResponse, notificationId});
        }, requestTimeout)
    })
}

export function rejectNotificationRol(notificationId) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({...okResponse, notificationId});
        }, requestTimeout)
    })
}

export function searchNotifications(word) {
    return new Promise(resolve => {
        setTimeout(() => {
            const pageFilterNotification = getPage(searchInNotifications(word), 1, 10);
            resolve(createPaginatedResultsResponseBody(allNotifications, 1, 10, pageFilterNotification))
        }, requestTimeout)
    })
}

export function filterNotifications(filters) {
    return new Promise(resolve => {
        setTimeout(() => {
            const pageFilterNotification = getPage(filterInNotifications(filters), 1, 10);
            resolve(createPaginatedResultsResponseBody(allNotifications, 1, 10, pageFilterNotification))
        }, requestTimeout)
    })
}

export function getAnimals(pageNumber, pageSize) {
    return new Promise(resolve => {
        setTimeout(() => {
            const pageAnimal = getPage(allAnimals, pageNumber, pageSize);
            resolve(createPaginatedResultsResponseBody(allAnimals,pageNumber, pageSize, pageAnimal))
        }, requestTimeout)
    })
}

export function searchAnimals(word) {
    return new Promise(resolve => {
        setTimeout(() => {
            const pageFilterAnimal = getPage(searchInAnimals(word), 1, 10);
            resolve(createPaginatedResultsResponseBody(allAnimals,1, 10, pageFilterAnimal));
        }, requestTimeout)
    })
}

export function filterAnimals(filters) {
    return new Promise(resolve => {
        setTimeout(() => {
            const pageFilterAnimal = getPage(filterInAnimals(filters), 1, 10);
            resolve(createPaginatedResultsResponseBody(allAnimals,1, 10, pageFilterAnimal));
        }, requestTimeout)
    })
}

export function uploadFileForMassiveAnimalsLoad(file) {
    return new Promise(resolve => {
        setTimeout(() => {
            const response = generateRandomAnimals(50, allAnimals.length);
            resolve({...okResponse, result: response});
        }, requestTimeout*10)
    })
}

export function massiveAnimalsLoad(animals) {
    return new Promise(resolve => {
        setTimeout(() => {
            allAnimals = [...animals, ...allAnimals];
            const pageAnimal = getPage(allAnimals, 1, 10);
            resolve(createPaginatedResultsResponseBody(allAnimals,1,10, pageAnimal));
        }, requestTimeout*5)
    })
}

export function getUsers(pageNumber, pageSize) {
    return new Promise(resolve => {
        setTimeout(() => {
            const userPage = getPage(allUsers.slice(1, allUsers.length), pageNumber, pageSize);
            resolve(createPaginatedResultsResponseBody(allUsers.slice(1, allUsers.length), pageNumber, pageSize, userPage));
        }, requestTimeout)
    })
}

export function getRoles(pageNumber, pageSize) {
    return new Promise(resolve => {
        setTimeout(() => {
            const rolesPage = getPage(allRoles, pageNumber, pageSize);
            resolve(createPaginatedResultsResponseBody(allRoles, pageNumber, pageSize, rolesPage))
        }, requestTimeout)
    })
}

export function getGeneralDataTitlesPage(pageNumber, pageSize) {
    return new Promise(resolve => {
        setTimeout(() => {
            const generalDataTitlesPage = getPage(allGeneralDataTitles, pageNumber, pageSize);
            resolve(createPaginatedResultsResponseBody(allGeneralDataTitles, pageNumber, pageSize, generalDataTitlesPage))
        }, requestTimeout)
    })
}

export function getGeneralDataInfo(id) {
    return new Promise(resolve => {
        setTimeout(() => {
            let response = generalDataMockInfo.filter(generalData => generalData.data_id.toString() === id)[0];
            if(!response){
                response = {
                    data_id: id,
                    data_name: `Dato General ${id}`,
                    values: [
                        {
                            name: "Valor 1",
                            creation_date: new Date(2018, 1, 1).toLocaleString(),
                            last_updated_date: new Date(2018, 12, 20).toLocaleString()
                        },
                        {
                            name: "Valor 2",
                            creation_date: new Date(2018, 1, 1).toLocaleString(),
                            last_updated_date: new Date(2018, 12, 20).toLocaleString()
                        },
                        {
                            name: "Valor 3",
                            creation_date: new Date(2018, 1, 1).toLocaleString(),
                            last_updated_date: new Date(2018, 12, 20).toLocaleString()
                        },
                        {
                            name: "Valor 4",
                            creation_date: new Date(2018, 1, 1).toLocaleString(),
                            last_updated_date: new Date(2018, 12, 20).toLocaleString()
                        }
                    ]
                };
                generalDataMockInfo.push(response);
            }
            resolve(response);
        }, requestTimeout)
    })
}

export function editGeneralDataInfo(id, prevValue, newValue) {
    return new Promise(resolve => {
        setTimeout(() => {
            generalDataMockInfo.map(generalData => {
                if(generalData.data_id.toString() === id){
                    generalData.values.map( value => {
                        if(value.name === prevValue) {
                            value.name = newValue;
                            value.last_updated_date = (new Date()).toLocaleString();
                        }
                        return value;
                    })
                }
                return generalData;
            });
            let response = generalDataMockInfo.filter(generalData => generalData.data_id.toString() === id)[0];
            resolve(response)
        }, requestTimeout)
    })
}

export function addGeneralDataInfo(id, value) {
    return new Promise(resolve => {
        setTimeout(() => {
            generalDataMockInfo.map(generalData => {
                if(generalData.data_id === id){
                    return generalData.values.push({
                        name: value,
                        creation_date: new Date().toLocaleString(),
                        last_updated_date: new Date().toLocaleString(),
                    });
                }
                return generalData;
            });
            let response = generalDataMockInfo.filter(generalData => generalData.data_id === id)[0];
            resolve(response)
        }, requestTimeout)
    })
}

export function getEvents(pageNumber, pageSize) {
    return new Promise(resolve => {
        setTimeout(() => {
            const eventPage = getPage(allEvents.slice().reverse(), pageNumber, pageSize);
            resolve(createPaginatedResultsResponseBody(allEvents, pageNumber, pageSize, eventPage));
        }, requestTimeout)
    })
}

export function searchEvents(word) {
    return new Promise(resolve => {
        setTimeout(() => {
            const pageFilterEvent = getPage(searchInEvents(word), 1, 10);
            resolve(createPaginatedResultsResponseBody(allEvents,1, 10, pageFilterEvent));
        }, requestTimeout)
    })
}

export function filterEvents(filters) {
    return new Promise(resolve => {
        setTimeout(() => {
            const pageFilterEvent = getPage(filterInEvents(filters), 1, 10);
            resolve(createPaginatedResultsResponseBody(allEvents,1, 10, pageFilterEvent));
        }, requestTimeout)
    })
}

export function blockUser(userId) {
    return new Promise(resolve => {
        setTimeout(() => {
            setUserAsBlocked(userId);
            resolve(okResponse);
        }, requestTimeout)
    })
}

export function getUser(userId) {
    return new Promise(resolve => {
        setTimeout(() => {
            const user = searchUserById(userId);
            resolve({...okResponse, result: user});
        }, requestTimeout)
    })
}

export function getUserById(userId) {
    return new Promise(resolve => {
        setTimeout(() => {
            const user = searchUserById(userId);
            resolve({...okResponse, result: user});
        }, requestTimeout)
    })
}

export function editUserById(userId, modifyUser) {
    return new Promise(resolve => {
        setTimeout(() => {
            const userModify = modifyUserById(userId, modifyUser);
            resolve({...okResponse});
        }, requestTimeout)
    })
}

export function getEstablishments(userId) {
    return new Promise(resolve => {
        setTimeout(() => {
            const userEstablishments = getUserEstablishments(userId);
            resolve(userEstablishments);
        }, requestTimeout)
    })
}

export function addTeethingEvent(teething, date) {
    return new Promise(resolve => {
        setTimeout(() => {
            const newId = allEvents[allEvents.length-1].id + 1;
            allEvents.push({
                id: newId,
                name: EVENT_TYPES.dentition,
                date: date ? new Date(date).toLocaleString() : new Date().toLocaleString(),
                teeth_table_value: teething
            });
            resolve({...okResponse})
        }, requestTimeout)
    })
}

export function addCastrationEvent(date) {
    return new Promise(resolve => {
        setTimeout(() => {
            const newId = allEvents[allEvents.length-1].id + 1;
            allEvents.push({
                id: newId,
                name: EVENT_TYPES.castration,
                date: date ? new Date(date).toLocaleString() : new Date().toLocaleString(),
            });
            resolve({...okResponse})
        }, requestTimeout)
    })
}

export function addProductiveSystemChangeEvent(newProductiveSystem, feeding, date, id) {
    return new Promise(resolve => {
        setTimeout(() => {
            const newId = allEvents[allEvents.length-1].id + 1;
            allEvents.push({
                id: newId,
                name: EVENT_TYPES.change_of_productive_system,
                productive_system: newProductiveSystem,
                feeding: feeding,
                date: date ? new Date(date).toLocaleString() : new Date().toLocaleString(),
            });
            resolve({...okResponse})
        }, requestTimeout)
    })
}

export function addCaravanReIdentificationEvent(newIdentification, date) {
    return new Promise(resolve => {
        setTimeout(() => {
            const newId = allEvents[allEvents.length-1].id + 1;
            allEvents.push({
                id: newId,
                name: EVENT_TYPES.caravan_re_identification,
                new_caravan: newIdentification,
                date: date ? new Date(date).toLocaleString() : new Date().toLocaleString(),
            });
            resolve({...okResponse})
        }, requestTimeout)
    })
}

export function addCategoryChangeEvent(id, newCategory, date) {
    return new Promise(resolve => {
        setTimeout(() => {
            const newId = allEvents[allEvents.length-1].id + 1;
            allEvents.push({
                id: newId,
                name: EVENT_TYPES.change_of_category,
                new_category: newCategory,
                date: date ? new Date(date).toLocaleString() : new Date().toLocaleString(),
            });
            resolve({...okResponse})
        }, requestTimeout)
    })
}

// export function addSlaughterEvent(id, date) {
//     return new Promise(resolve => {
//         setTimeout(() => {
//             const newId = allEvents[allEvents.length-1].id + 1;
//             allEvents.push({
//                 id: newId,
//                 name: EVENT_TYPES.fain,
//                 date: date ? new Date(date).toLocaleString() : new Date().toLocaleString(),
//             });
//             resolve({...okResponse})
//         }, requestTimeout)
//     })
// }

export function addVaccinationEvent(id, date, vaccine) {
    return new Promise(resolve => {
        setTimeout(() => {
            const newId = allEvents[allEvents.length-1].id + 1;
            allEvents.push({
                id: newId,
                name: EVENT_TYPES.vaccination,
                date: date ? new Date(date).toLocaleString() : new Date().toLocaleString(),
            });
            resolve({...okResponse})
        }, requestTimeout)
    })
}

export function uploadFileForMassiveEventsLoad(file) {
    return new Promise(resolve => {
        setTimeout(() => {
            const response = generateRandomEvents(50, allEvents.length);
            resolve({...okResponse, result: response});
        }, requestTimeout*10)
    })
}

export function massiveEventsLoad(events) {
    return new Promise(resolve => {
        setTimeout(() => {
            allEvents = [...allEvents, ...events];
            resolve(getEvents(1,10));
        }, requestTimeout*5)
    })
}

export function getAvailableCowBreeds() {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                breeds: cowBreedsList
            })
        }, requestTimeout)
    })
}

export function getUserAccounts(userId) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({accounts: getUserAccountsService(userId)});
        }, requestTimeout)
    })
}

export function addUserCompany(userId, company) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({accounts: addCompanyToUserService(userId, company)});
        }, requestTimeout)
    })
}

export function addEstablishmentToCompany(userId, accountId, info) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({accounts: addEstablishmentToCompanyService(userId, accountId, info)})
        }, requestTimeout)
    })
}

export function addRoleToUser(userId, role) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({roles: addRoleToUserService(userId, role)})
        }, requestTimeout)
    })
}

export function createWallet(userId, address, encryptedMnemonic){
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({wallet: generateUserWallet(userId, address, encryptedMnemonic)})
        }, requestTimeout)
    })
}

export function getWallet(userId){
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({wallet: getUserWallet(userId)})
        }, requestTimeout)
    })
}