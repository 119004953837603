import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';
import SimpleNumberValue from './components/SimpleNumberValue'
import SimpleTable from './components/SimpleTable';
import SimplePie from './components/SimplePie';
import SimplePie2 from './components/SimplePie2';
import SimpleHorizontalBarsPerc from './components/SimpleHorizontalBarsPerc';
import actions from '../../redux/actions/index';
import {ROLES} from "../../constants";
import CVModal from "../UI/Modals/components/CVModal";
import './bi.css';


const BI = (props) => {
    const {  t, BI, reduxState, user } = props;
    
    useEffect(() => {        
        props.getAvailableBIInit();
        props.getAvailableBI();        
        
        
        props.getUserNotifications((resArray)=>{
            
            if (resArray.notifications.length>0) {
                setNotificationsModalIsOpen(true);
                setNotifications(resArray.notifications);
            }
        },(error)=>{
            
        });

    }, []);

    const [notificationsModalIsOpen, setNotificationsModalIsOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);

    const presetColors = [
        '#908bd6',
        '#ff9e9e',
        '#aee6b5',
        '#e2ace3',
        '#abdfe0',
        '#eaedb7',
        '#cd8282',
    ];

    const getPresetColor = function(index) {
        if (presetColors[index]) {
            return presetColors[index];
        } else {
            return '#'+Math.floor(Math.random()*16777215).toString(16);
        }
    }

    const generateFillRandom = function(data) {                
        let results = [];
        data.forEach((row,index) => {            
            row['fill']=getPresetColor(index);
            results.push(row);
        });
        return results;        
    }

    const renderNotificationModalBody = () => (
        <div>
            {notifications.map((notification, index) => (
                <div>
                    <h4 style={{marginBottom: 10}}>{t(notification.msgTitle)}</h4>                    
                    <div dangerouslySetInnerHTML={{__html:notification.msgBody}} />                    
                </div>
            ))
        }
            
        </div>
    );

    const notificationsModal = () => (
        <CVModal
            title={t('users.notifications.modal.title')}
            body={() => renderNotificationModalBody()}
            color="primary"
            header
            isOpen={notificationsModalIsOpen}
            toggle={() => setNotificationsModalIsOpen(!notificationsModalIsOpen)}            
            acceptFunc={() => setNotificationsModalIsOpen(!notificationsModalIsOpen)}         
            showAcceptOnly={true}
        />
    );

    //console.log("reduxState",reduxState.session.jwt);

    /*const jwt = actions.auth.authenticatedRequest(jwt => {
        console.log("JWT DEBUG", jwt);
    });*/
    //console.log("DEBUG 3",getState().session.jwt);
    
    
    const animalsForCategoryByUserCount = generateFillRandom(BI.animalsForCategoryByUserCount);
    const animalsForFacilityByUserCount = generateFillRandom(BI.animalsForFacilityByUserCount);
    const animalsForWeightRangeyByUserCount = generateFillRandom(BI.animalsForWeightRangeyByUserCount);
    const animalsForBreedByUserCount = generateFillRandom(BI.animalsForBreedByUserCount);
    var companyData = [
        {name:"Empresas como Titular",value:BI.companiesByUserCount.count, link:'/manage_accounts/table'},
        {name:"Empresas como Delegado",value:BI.companiesAsDelegateByUserCount.count, link:'/manage_accounts/table'},        
    ];
    var facilityData = [
        {name:"Establecimientos como Titular",value:BI.faciltiesByUserCount.count, link:'/manage_establishments/table'},        
    ];
    var delegateData = [
        {name:"Delegados de Empresas",value:BI.companyDelegateByUserCount.count, link:'/manage_accounts/table'},        
        {name:"Profesionales / Operadores de Establecimientos",value:BI.facilityDelegateByUserCount.count, link:'/manage_establishments/table'},        
    ];
    

    return (
        <Container className="dashboard" style={{marginTop:"-15px"}}>                                                    
                    {notificationsModal()}
                    {(user.roles[0].name == ROLES.TITULAR) &&                    
                    <Row>                        
                        <SimpleTable col="4" color="blue" icon="Home" data={companyData} title="Empresas"/>
                        <SimpleTable col="4" color="green" icon="MapMarker" data={facilityData} title="Establecimientos"/>
                        <SimpleTable col="4" color="red" icon="TransferRight" data={delegateData} title="Delegaciones"/>
                    </Row>
                    }
                    <Row>
                        
                        <SimpleNumberValue icon="Cow" title="Animales Activos" value={BI.animalsActiveByUserCount.count}/>
                        <SimpleNumberValue icon="Cow" title="Animales creados" value={BI.animalsCreatedByUserCount.count}/>                        
                        <SimpleNumberValue icon="Transfer" title="Animales Transferidos" value={BI.animalsTransferByUserCount.count}/>
                        <SimpleNumberValue icon="ArrowBottomBold" title="Animales dados de Baja" value={BI.animalsDeletionByUserCount.count}/>                                                
                    </Row>
                    <Row>                        
                        <SimplePie nodataMode='icon' title="Animales - Cantidad por Categoría" data={animalsForCategoryByUserCount}/>
                        <SimplePie nodataMode='video' title="Animales - Cantidad por Establecimiento" data={animalsForFacilityByUserCount}/>
                        <SimplePie nodataMode='icon' title="Animales - Cantidad por Raza" data={animalsForBreedByUserCount}/>                        
                    </Row>                    
                    {(false) &&
                    <Row>
                        <SimpleTable col="6" color="blue" icon="Cow" data={BI.animalsWeightAverageForCategoryByUserCount} title="Peso Promedio por Categoría"/>
                        <SimpleTable col="6" color="green" icon="Cow" data={BI.animalsWeightAverageForProductionSystemByUserCount} title="Peso Promedio por Sistema Productivo"/>                        
                    </Row>
                    }                    
        </Container>
    )
};

const mapDispatchToProps = (dispatch) => ({
    getAvailableBI: () => dispatch(actions.bi.getAvailableBI()),
    getAvailableBIInit: () => dispatch(actions.bi.getAvailableBIInit()),
    getUserNotifications: (successCallback, errorCallback) => dispatch(actions.user.getUserNotifications(successCallback, errorCallback))
});

const mapStateToProps = (state) => ({
    BI: state.bi.availableBI,
    reduxState: state,
    user: state.user
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(BI));
