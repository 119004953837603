export const UPLOAD_CERTIFICATE_FILES = 'UPLOAD_CERTIFICATE_FILES';
export const EDIT_CERTIFICATE = 'EDIT_CERTIFICATE';
export const EDIT_CERTIFICATE_RESPONSE = 'EDIT_CERTIFICATE_RESPONSE';
export const EDIT_CERTIFICATE_ERROR = 'EDIT_CERTIFICATE_ERROR';
export const GET_FACILITY_CERTIFICATES = 'GET_FACILITY_CERTIFICATES';
export const GET_FACILITY_CERTIFICATES_RESPONSE = 'GET_FACILITY_CERTIFICATES_RESPONSE';
export const GET_FACILITY_CERTIFICATES_ERROR = 'GET_FACILITY_CERTIFICATES_ERROR';

export const GET_CERTIFICATE_BY_ID = 'GET_CERTIFICATE_BY_ID';
export const GET_CERTIFICATE_BY_ID_RESPONSE = 'GET_CERTIFICATE_BY_ID_RESPONSE';
export const GET_CERTIFICATE_BY_ID_ERROR = 'GET_CERTIFICATE_BY_ID_ERROR';

export const NEW_CERTIFICATE = 'NEW_CERTIFICATE';
export const NEW_CERTIFICATE_RESPONSE = 'NEW_CERTIFICATE_RESPONSE';


const certificateActions = {
    
    uploadCertificateFiles(files, callback) {
        return {
            type: UPLOAD_CERTIFICATE_FILES,
            files,
            callback
        }
    },
    editCertificate(data, successCallback, errorCallback) {        
        
        return {
            type: EDIT_CERTIFICATE,            
            data,
            successCallback,
            errorCallback
        }
    },    
    editCertificateResponse(response) {
        return {
            type: EDIT_CERTIFICATE_RESPONSE,
            response
        }
    },
    editCertificateError(message) {
        return {
            type: EDIT_CERTIFICATE_ERROR,
            message
        }
    },
    
    getFacilityCertificates(facility, successCallback, errorCallback) {                                
        return {
            type: GET_FACILITY_CERTIFICATES,            
            facility,
            successCallback,
            errorCallback
        }
    },
    getFacilityCertificatesResponse(response) {        
        return {
            type: GET_FACILITY_CERTIFICATES_RESPONSE,
            response
        }
    },
    getFacilityCertificatesError(message) {
        return {
            type: GET_FACILITY_CERTIFICATES_ERROR,
            message
        }
    },

    getCertificateById(id, successCallback, errorCallback) {                                
        return {
            type: GET_CERTIFICATE_BY_ID,            
            id,
            successCallback,
            errorCallback
        }
    },
    getCertificateByIdResponse(response) {        
        return {
            type: GET_CERTIFICATE_BY_ID_RESPONSE,
            response
        }
    },
    getCertificateByIdError(message) {
        return {
            type: GET_CERTIFICATE_BY_ID_ERROR,
            message
        }
    },
    newCertificate(initValues, successCallback, errorCallback) {
        return {
            type: NEW_CERTIFICATE,
            initValues,
            successCallback,
            errorCallback
        }
    },
    newCertificateResponse(response) {        
        return {
            type: NEW_CERTIFICATE_RESPONSE,
            response
        }
    },
    
};
export default certificateActions;