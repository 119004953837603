
export const GET_SRA_CONNECTION_STATUS = 'GET_SRA_CONNECTION_STATUS'
export const GET_SRA_CONNECTION_STATUS_RESPONSE = 'GET_SRA_CONNECTION_STATUS_RESPONSE'
export const GET_SRA_CONNECTION_STATUS_ERROR = 'GET_SRA_CONNECTION_STATUS_ERROR'

const ServicesStatusActions = {

    getSRAConnectionStatus(data,successCallback, errorCallback){
        return {
            type: GET_SRA_CONNECTION_STATUS,
            data, 
            successCallback, 
            errorCallback
        }
    },

     getSRAConnectionStatusResponse(response){
        return {
            type: GET_SRA_CONNECTION_STATUS_RESPONSE,
            response
        }
    },
    getSRAConnectionStatusError(message){
        return {
            type: GET_SRA_CONNECTION_STATUS_ERROR,
            message
        }
    },
    

};
export default ServicesStatusActions;