import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col} from "reactstrap";
import {withTranslation} from "react-i18next";
import actions from '../../../redux/actions/index';
import { connect } from 'react-redux';
import LeadPencilIcon from "mdi-react/LeadPencilIcon";
import CowForm from './CowForm';
import { reset } from 'redux-form'
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

const AnimalsDetails = (props) => {

    const [editable, setEditable] = useState(false);
    const [submittable, setSubmittable] = useState(true);

    if(!props.location || !props.location.state || !props.location.state.animalId ) return null;
    const animalId = props.location.state.animalId;

    useEffect(() => {
        // resets the account details to avoid wrong information render.
        props.resetAnimalDetails();
        props.getAvailableEstablishments();
    }, []);

    useEffect(() => {
        if(animalId) {
            props.getAnimalById(animalId);
        }
    }, [animalId]);

    useEffect(() => {
        if(!editable) props.resetAnimalForm()
    }, [editable]);


    const editAnimal = (values) => {
        setSubmittable(false);
        props.editCow(animalId, values, () => {
            props.history.push('/animals/table');
        }, (errorMessage) => {
            ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
            setSubmittable(true);
        });
    };

    return (
        <Col md={12} lg={12}>
            <Card>
                <CardBody>
                <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
                    <div className="card__title">
                        <div style={{display: 'flex', flexDirection: 'row',justifyContent: 'space-between'}}>
                            <h5 className="bold-text">{props.t('animal_details.title')}</h5>
                        </div>
                    </div>
                    {/* only show the details when the animal info arrive */}
                    {
                        props.animalDetails &&
                        <CowForm
                            onSubmit={editAnimal}
                            availableEstablishments={props.availableEstablishments}
                            hideTitle
                            initialValues={props.animalDetails}
                            editable={editable}
                            setEditable={setEditable}
                            showImages
                            submittable={submittable}
                            pending={props.editCowPending}
                        />
                    }
                </CardBody>
            </Card>
        </Col>
    )
};

const mapStateToProps = (state) => ({
    animalDetails: state.animal.animalDetails,
    availableEstablishments: state.user.allEstablishments,
    editCowPending: state.ui.editCowPending
});

const mapDispatchToProps = (dispatch) => ({
    getAnimalById: (animalId) => dispatch(actions.animal.getById(animalId)),
    getAvailableEstablishments: () => dispatch(actions.user.getAllUserEstablishments()),
    resetAnimalForm: () => dispatch(reset('cow-form')),
    resetAnimalDetails: () => dispatch(actions.animal.resetDetails()),
    editCow: (animalId, data, successCallback, errorCallback) => dispatch(actions.animal.editCow(animalId, data, successCallback, errorCallback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(AnimalsDetails))