/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { withTranslation } from 'react-i18next';
import CVTable from '../../Tables/CVTable/CVTable';
import FileUploadOutlineIcon from "mdi-react/FileUploadOutlineIcon";
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import ExportIcon from 'mdi-react/ExportIcon';
import QrcodeIcon from "mdi-react/QrcodeIcon";
import { withRouter } from 'react-router-dom';
import {parse_UTC_date_to_DD_MM_YYYY} from '../../../util/index';
import {useAlert} from "react-alert";
import FileDownloadOutlineIcon from "mdi-react/FileDownloadOutlineIcon";
import {Tooltip} from "react-tippy";
import settings from "../../../util/settings";
import LotDropdown from '../../Layout/dropdown/lotsDropdown';
import { saveAs } from 'file-saver';
import { connect } from 'react-redux';
import FileTableOutlineIcon from "mdi-react/FileTableOutlineIcon";
import animalsActions from '../../../redux/actions/animalActions';
import { getCompanyScopeAnimalsCount } from '../../../api/api.mixed';
import actions from "../../../redux/actions";
import CVBasicModal from "../../../shared/components/cv/CVBasicModal";
import CVEmailTierModal from "../../../shared/components/cv/CVEmailTierModal";
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

const AnimalsTable = (props) =>  {
    const [animals, setAnimals] = useState();
    const templatesUrl = settings.templatesUrl;
    const [excelDownload, setExcelDownload] = useState(false);
    const [establishmentName, setEstablishmentName] = useState("");

    const alert = useAlert();

    useEffect(() => {
        if(props.animals !== undefined) setAnimals(props.animals);
        props.getPageForDownload(props.currentPage);
    }, [props.animals]);

    useEffect(() => {       
    },[props.animalsCountSelectedCompanyScope])

    useEffect(() => {
        if (props.selectedFacility!=undefined) {
            props.getCompanyScopeAnimalsCount(selectedFacility._id);        
        }
     },[props.selectedFacility])

     useEffect (() => {
         if (props.establishmentSelected!==undefined){
            setEstablishmentName(" del establecimiento "+props.establishmentSelected.name)
         }else{
            setEstablishmentName("")
         }
     },[props.establishmentSelected])


    const { total,
        totalPages,
        currentPage,
        pageSize,
        pending,
        openQrModal,
        establishmentSelected,
        animalsForDownload,
        selectedEstablishmentLotMode,
        
        selectedFacility,
        t, excelBuffer, getExcel,
        establishmentId,lotIds } = props;

    const renderValueDefault = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value}
            </div>
        )
    };

    useEffect(() => {
        if (excelDownload===true && excelBuffer!=null && excelBuffer!=undefined)
        {   var blob=new Blob([new Uint8Array(excelBuffer.data)], {type: 'application/octet-stream' });
            var link=document.createElement('a');
            link.href=window.URL.createObjectURL(blob);
            link.download="animales.xlsx";
            link.click();
            setExcelDownload(false);
        }
    }, [excelBuffer]);


    const [mailModalIsOpen, toggleMailModal] = useState(false);
    const [msgErrorMailModal, setMsgErrorMailModal] = useState('');
    const [buttonDisabledMailModal, setButtonDisabledMailModal] = useState(false);
    
    const mailModal = () => (
        <CVEmailTierModal            
            title={'Info'}            
            acceptText="Enviar"
            msgError={msgErrorMailModal}
            backdrop={"static"}
            keyboard={false}
            isOpen={mailModalIsOpen}
            toggleModal={() => toggleMailModal(!mailModalIsOpen)}
            hideCancelButton={false}
            cancel={() => toggleMailModal(false)}
            accept={acceptEmail}
            buttonDisabled={buttonDisabledMailModal}
            //acceptText={t('animals.modals.qr.download')}
        />
    );

    const acceptEmail = (context) => {
        setButtonDisabledMailModal(true);
        setMsgErrorMailModal("Aguarde un momento.");
        props.sendMailTierRequest(context,()=>{            
            toggleMailModal(false);
            toggleInfoModal(false);
            setButtonDisabledMailModal(false);
            ToastsStore.success(t('Solicitud Enviada'), 10000)
        },(error)=>{            
            setMsgErrorMailModal("No se ha podido enviar la solicitud, intente nuevamente.");
            setButtonDisabledMailModal(false);
        })
        
    }
    

    const [infoModalIsOpen, toggleInfoModal] = useState(false);
    const infoModal = () => (
        <CVBasicModal
            title={'Info'}
            body={renderInfoBody}
            backdrop={"static"}
            keyboard={false}
            isOpen={infoModalIsOpen}
            toggleModal={() => toggleInfoModal(!infoModalIsOpen)}
            hideCancelButton={true}
            //cancel={() => toggleQrModal(false)}
            accept={()=>toggleInfoModal(false)}
            //acceptText={t('animals.modals.qr.download')}
        />
    );
    const renderInfoBody = () => (
        <div>
            <span>
            Tu empresa ha llegado al límite de animales activos ({props.animalsCountSelectedCompanyScope.max} animales) para el plan vigente.<br/><br/>

            Para actualizar tu plan podés contactarnos haciendo click <a style={{cursor:"pointer", color:"black;", fontWeight:"weight"}} onClick={handleLinkEmail}><b><u>AQUI</u></b></a> <br/>o podés contactarnos en forma directa vía mail a <a target="_BLANK" style={{cursor:"pointer", color:"black;", fontWeight:"weight"}} href={'mailto:'+settings.emailPricing}><b><u>{settings.emailPricing}</u></b></a>
            &nbsp;o a whatsapp/telegram al {settings.telPricing}<br/><br/>

            Si te interesa conocer más sobre nuestros planes<br></br> podés hacer click <a style={{cursor:"pointer", color:"black;", fontWeight:"weight"}} onClick={handleLink}><b><u>AQUI</u></b></a>
            </span>
        </div>
    );

    
    
    const handleLinkEmail = () => {
        /*const newWindow = window.open("mailto:"+settings.emailPricing+"?subject=Actualizar mi Plan en Carnes Validadas", '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null*/
        setButtonDisabledMailModal(false);
        setMsgErrorMailModal("");
        toggleMailModal(true);
    }

    const handleLink = () => {
        const newWindow = window.open(settings.urlPricing, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const renderButtons = ({dependentValues}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <MagnifyIcon className={"button"} style={{marginLeft: "10px"}} onClick={() => {
                    props.history.push('/animals/animal_detail', {animalId: dependentValues._id})
                }}/>
                <QrcodeIcon className={"button"} style={{marginLeft: "10px"}} 
                    onClick={() => openQrModal(dependentValues._id, dependentValues.earId)}/>
            </div>
        )
    };

    const renderBreed = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {/* commented because no translation is supported yet */}
                {/*{t(`animals.table.breed.${value}`)}*/}
                {value}
            </div>
        )
    };

    const renderSpecies = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {/* commented because no translation is supported yet */}
                {/*{t(`animals.table.species.${value}`)}*/}
                {value}
            </div>
        )
    };

    const renderSex = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {/*{t(`animals.table.sex.${value}`)}*/}
                {value}
            </div>
        )
    };

    const renderCategory = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {/* commented because no translation is supported yet */}
                {/*{t(`animals_categories.cow.${value}`)}*/}
                {value}
            </div>
        )
    };


    const renderBirthDate = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value ?
                    parse_UTC_date_to_DD_MM_YYYY(value):
                    "-"
                }
            </div>
        )
    };



    const renderLotsSelect = () => (
      <div>
        <LotDropdown/>
      </div>
    );
    
    const renderIconsForAddingAnimals = () => (
        <div>
            {infoModal()}
            {mailModal()}
            <div>
                <Tooltip
                title="Descargar listado de animales"
                position="top"
                trigger="mouseenter"
                animation="scale"
                duration="600"
                size="small"
                theme="transparent"
                distance="0" >
                    <FileTableOutlineIcon onClick={() => {if(animals) {setExcelDownload(true);
                                                                        getExcel(props.establishmentSelected._id,lotIds);}}}/>
                </Tooltip>
                <Tooltip
                    title="Descargar plantilla"
                    position="top"
                    trigger="mouseenter"
                    animation="scale"
                    duration="600"
                    size="small"
                    theme="transparent"
                    distance="0"
                >
                <a href={templatesUrl + "modelo animales.xls"} download><FileDownloadOutlineIcon/></a>
                </Tooltip>
                <Tooltip
                    title="Cargar datos"
                    position="top"
                    trigger="mouseenter"
                    animation="scale"
                    duration="600"
                    size="small"
                    theme="transparent"
                    distance="0"
                >
                <FileUploadOutlineIcon onClick={() => {
                    if (props.animalsCountSelectedCompanyScope.count>=props.animalsCountSelectedCompanyScope.max) {
                        toggleInfoModal(true);
                        return;
                    }
                    if(!establishmentSelected) {
                        alert.error(t('animals.table.to_add_cows_you_need_to_select_an_establishment'));
                    } else { 
                        props.resetMassiveLoadAnimals(); props.toggleFileUploadModal(true)
                    }
                }}/>
                </Tooltip>
                <PlusCircleOutlineIcon onClick={() => {
                    if(!establishmentSelected) alert.error(t('animals.table.to_add_cows_you_need_to_select_an_establishment'));
                    else props.redirectTo('/animals/singleLoad')
                }}/>
                <div>
                 {/* <LotDropdown /> */}
               </div>
            </div>
        </div>
    );

    const heads = [
        {
            key: 'earId',
            name: t('animals.table.headers.caravan_id'),
            percentage: 14,
            minWidth: 140,
            formatter: renderValueDefault
        },
        {
            key: 'breed',
            name: t('animals.table.headers.breed'),
            percentage: 12,
            minWidth: 130,
            formatter: renderBreed
        },
        {
            key: 'specie',
            name: t('animals.table.headers.species'),
            percentage: 12,
            minWidth: 100,
            formatter: renderSpecies
        },
        {
            key: 'gender',
            name: t('animals.table.headers.sex'),
            percentage: 14,
            minWidth: 120,
            formatter: renderSex
        },
        {
            key: 'category',
            name: t('animals.table.headers.category'),
            percentage: 14,
            minWidth: 80,
            formatter: renderCategory
        },
        {
            key: 'birthDate',
            name: t('animals.table.headers.birth_date'),
            percentage: 20,
            minWidth: 135,
            formatter: renderValueDefault
        },
        {
            key: 'actions',
            name: t('animals.table.headers.actions'),
            percentage: 14,
            minWidth: 75,
            formatter: renderButtons,
            getRowMetaData: (row) => row
        }
    ];

    const filters = [
        {
            group: 'animals',
            filterId: 'byBreed',
            label: 'breed',
            values: [
                {
                    id: 'ANGUS',
                    label: 'ANGUS'
                },
                {
                    id: 'HEREFORD',
                    label: 'HEREFORD'
                }
            ]
        },
        {
            group: 'animals',
            filterId: 'bySpecies',
            label: 'species',
            values: [
                {
                    id: 'BT',
                    label: 'BT'
                }
            ]
        },
        {
            group: 'animals',
            filterId: 'bySex',
            label: 'sex',
            values: [
                {
                    id: 'male',
                    label: 'male'
                },
                {
                    id: 'female',
                    label: 'female'
                }
            ]
        },
        {
            group: 'animals',
            filterId: 'byCategory',
            label: 'category',
            values: [
                {
                    id: 'VACA',
                    label: 'VACA'
                },
                {
                    id: 'VAQUILLONA',
                    label: 'VAQUILLONA'
                },
                {
                    id: 'NOVILLO',
                    label: 'NOVILLO'
                },
                {
                    id: 'TERNERO',
                    label: 'TERNERO'
                }
            ]
        },
    ];

    return (
        <div>
        <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
        <CVTable
            getPage={props.getPage}
            data={animals}
            total={total ? total : 0}
            totalPages={totalPages}
            currentPage={currentPage ? currentPage : 1}
            pageSize={pageSize}
            heads={heads}
            title={props.title ? props.title+ establishmentName: t('animals.table.title')+ establishmentName}
            SubtitleComponent={renderIconsForAddingAnimals}
            CVSelect={renderLotsSelect}
            showSearchInput={false}
            showColumnFilter={true}
            //onFilter={props.filter}
            //filters={filters}
            hideFilters
            noDataLabel={t('animals.table.no_data_label')}
            pending={pending}
        />
        </div>
    );
};

const mapStateToProps = (state) => ({
    excelBuffer: state.animal ? state.animal.excelBuffer : null,
    animalsCountSelectedCompanyScope: state.user.animalsCountSelectedCompanyScope,    
    userEmail: state.user.email,    
    selectedFacility: state.user.selectedEstablishment
});

const mapDispatchToProps = (dispatch) => ({
    sendMailTierRequest: (data, successCallback, errorCallback) => dispatch(actions.session.sendMailTierRequest(data, successCallback, errorCallback)),
    getCompanyScopeAnimalsCount: (facilityId) => dispatch(actions.user.getCompanyScopeAnimalsCount(facilityId)),
    getExcel: (establishmentId,lotIds) => dispatch(animalsActions.getFacilityAnimalsToExcel(establishmentId,lotIds)),
    resetMassiveLoadAnimals: () => dispatch(animalsActions.resetMassiveLoadAnimals())
    });

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(AnimalsTable)));
