import {    
    GET_AVAILABLE_BI_RESPONSE,
    GET_AVAILABLE_BI_ERROR,
    GET_AVAILABLE_BI_INIT,
} from "../actions/biActions";

const initialState = {    
    availableBI: {
        animalsByUserCount: {count:'-'},
        animalsDeletionByUserCount: {count:'-'},        
        animalsForBreedByUserCount: [],
        animalsForCategoryByUserCount: [],
        animalsForWeightRangeyByUserCount: [],
        animalsTransferByUserCount: {count:'-'},
        animalsWeightAverageForCategoryByUserCount: [],
        animalsWeightAverageForProductionSystemByUserCount: [],
        animalsForFacilityByUserCount: [],
        companiesAsDelegateByUserCount: {count:'-'},
        companyDelegateByUserCount: {count:'-'},
        facilityDelegateByUserCount: {count:'-'},
        faciltiesByUserCount: {count:'-'},
        companiesByUserCount: {count:'-'},
        animalsCreatedByUserCount: {count:'-'},
        animalsActiveByUserCount: {count:'-'},
    }
};

const biReducer = (state = initialState, action) => {
    switch (action.type) {        
        case GET_AVAILABLE_BI_RESPONSE:
            return {
                ...state,
                availableBI: action.response
            };
        case GET_AVAILABLE_BI_ERROR:
            return {
                ...state,
                availableBI: initialState.availableBI
            };
        case GET_AVAILABLE_BI_INIT:
                return {
                    ...state,
                    availableBI: initialState.availableBI
                };
        default:
            return state
    }
};

export default biReducer;