
const mapper =  {
     
    response: {

        SRAConnectionStatus: (response) => { return (response.length ? true : false) },
       
        },
    error: {
        SRAConnectionStatus: (error) => { return error.message } 
    }

    }  

export default mapper;