/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import {
  PieChart, Pie, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Progress, Row,
} from 'reactstrap';
import SimpleVideoEmbed from './SimpleVideoEmbed';
import nodataIcon from '../../../shared/img/icon/nodata.png';
import CowIcon from 'mdi-react/CowIcon';
import HelpCircleIcon from 'mdi-react/HelpCircleIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

const style = (dir) => {
  const left = dir === 'ltr' ? { left: 0 } : { right: 0 };
  return ({
    ...left,    
    lineHeight: '12px',
    position: 'absolute',
  });
};


const renderLegend = ({ payload }) => (
  <ul className="dashboard__chart-legend" style={{marginBottom:"0px",textAlign:'center',fontSize:'12px',display:"flex",flexWrap:"wrap",alignItems:"center",justifyContent:"center"}}>    
    {      
        payload.map((entry, index) => (                    
        <a style={{lineHeight:'20px',whiteSpace:"nowrap"}} key={`item-${index}`}><span style={{ backgroundColor: entry.color, marginRight:'2px' }} />{entry.value}: <b>{entry.payload.value}</b><span style={{}} /></a>
        ))
    }    
  </ul>
);

/*renderLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
};*/

class SimplePie extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    dir: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      x: 0,
      y: 0,
    };
  }


  onMouseMove = (e) => {
    const { dir } = this.props;
    if (e.tooltipPosition) {
      this.setState({ x: dir === 'ltr' ? e.tooltipPosition.x : e.tooltipPosition.x / 10, y: e.tooltipPosition.y });
    }
  };

  render() {
    const { t, dir, title, data, nodataMode } = this.props;
    const { x, y } = this.state;

    return (
      <Col md={12} xl={4} lg={4} xs={12}>
        <Card style={{paddingBottom:'5px'}}>
          <CardBody style={{padding:'10px'}}>
            <h5 className="dashboard__booking-total-description" style={{color:"black"}}><CowIcon style={{cursor:"default"}}/> {title}</h5>  
            { (data.length>0) && (
            <div dir={dir}>
              <ResponsiveContainer className="dashboard__chart-pie dashboard__chart-pie--crypto">
                <PieChart className="dashboard__chart-pie-container">
                  <Tooltip formatter={value => (`${value}`)} position={{ x, y }} />
                  <Pie
                    data={data}                    
                    outerRadius={80}                                        
                    onMouseMove={this.onMouseMove}
                  />
                  <Legend layout="horizontal" verticalAlign="bottom" wrapperStyle={style(dir)} content={renderLegend} />
                </PieChart>                
              </ResponsiveContainer>              
            </div>
            )}
            { (data.length==0) && (
              <div style={{display:'table',height:'250px',overflow:'hidden',width:'100%'}}>
                <div style={{display:'table-cell',verticalAlign:'middle',width:'100%'}}>
                  <div style={{textAlign:'center',width:'100%'}}>                    
                    {
                      (nodataMode=='icon') && (
                        <div>
                          <h4 style={{marginBottom:"10px"}}>SIN DATOS...</h4>
                          <img style={{height:"150px",width:"130px"}} src={nodataIcon}/>
                        </div>
                      )
                    }
                    {
                      (nodataMode=='video') && (
                        <div>                        
                        <SimpleVideoEmbed path='https://www.youtube.com/embed/CzY3OAB4-jI'/>
                        <h5>TUTORIAL | CARGA DE ANIMALES <a href="https://www.youtube.com/embed/CzY3OAB4-jI" target="_BLANK" title="Ver en Youtube"><OpenInNewIcon color="black" size="15"/></a></h5>
                        </div>
                      )
                    }                    
                  </div>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
      
    );
  }
}

export default withTranslation('common')(SimplePie);
