import api from '../../api/index';
import {
    GET_AVAILABLE_COUNTRIES,
    GET_AVAILABLE_COW_BREEDS,
    GET_AVAILABLE_FACILITIES,
    GET_FACILITY_BY_CODE
} from "../actions/generalDataActions";
import actions from '../actions';
import userMapper from "./mappers/userMapper";

const generalData = ({dispatch}) => next => action => {
    next(action);
    switch (action.type) {
        case GET_AVAILABLE_COUNTRIES:
            api.getAvailableCountries()
                .then(res => {
                    dispatch(actions.generalData.getAvailableCountriesResponse(res));
                })
                .catch(err => {
                    dispatch(actions.generalData.getAvailableCountriesError(err))
                });
            break;
        case GET_AVAILABLE_COW_BREEDS:
            api.getAvailableCowBreeds()
                .then(res => {
                    dispatch(actions.generalData.getAvailableCowBreedsResponse(res))
                })
                .catch(err => {
                    dispatch(actions.generalData.getAvailableCowBreedsError(err))
                });
            break;
        case GET_AVAILABLE_FACILITIES:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAvailableFacilities(jwt)
                  .then(res => {
                      dispatch(actions.generalData.getAvailableFacilitiesResponse(userMapper.response.getAllEstablishments(res)))
                  })
                  .catch(err => {
                      dispatch(actions.generalData.getAvailableFacilitiesError(err))
                  });
            }));
            break;
        case GET_FACILITY_BY_CODE:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getFacilityByCode(jwt, action.code)
                  .then(res => {
                      dispatch(actions.generalData.getFacilityByCodeResponse({fetched: true, res: res}))
                      if(action.callback) action.callback();
                  })
                  .catch(err => {
                      dispatch(actions.generalData.getFacilityByCodeError(err))
                  });
            }));
            break;
    }
};

export default generalData;
