/* eslint-disable default-case */
import api from '../../api/index';
import { GET_AVAILABLE_ROLES, GET_AVAILABLE_ENTITY_TYPES, GET_AVAILABLE_PRODUCTIVE_SYSTEMS, GET_FACILITIES_PRODUCTIVE_TYPES,
    GET_AVAILABLE_PRODUCTIVE_SYSTEMS_FACILITY_TYPE,GET_AVAILABLE_ESTABLISHMENT_TYPES, GET_AVAILABLE_GROUND_CONDITIONS, GET_AVAILABLE_RUCA_ACTIVITIES, GET_DENTITION_VALUES, GET_DENTITION_VALUES_RESPONSE, GET_DENTITION_VALUES_ERROR, GET_DELETION_REASON_VALUES, GET_DELETION_REASON_VALUES_RESPONSE, GET_DELETION_REASON_VALUES_ERROR
    ,GET_AVAILABLE_STATES
} from '../actions/constantActions';
import actions from '../actions';
import mapper from './mappers/constantMapper';

const constant = ({dispatch, getState}) => next => action => {
    next(action);
    switch (action.type) {
        case GET_AVAILABLE_ROLES:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAvailableRoles(jwt)
                    .then(res => {
                        dispatch(actions.constant.getAvailableRolesResponse(
                            mapper.response.getAvailableRoles(res)))
                    })
                    .catch(err => {
                        dispatch(actions.constant.getAvailableRolesError(err))
                    });
            }));
            break;
        case GET_AVAILABLE_ENTITY_TYPES:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAvailableEntityTypes(jwt)
                    .then(res => {
                        dispatch(actions.constant.getAvailableEntityTypesResponse(
                            mapper.response.getAvailableEntityTypes(res)))
                    })
                    .catch(err => {
                        dispatch(actions.constant.getAvailableEntityTypesError(err))
                    })
            }));
            break;

        case GET_DENTITION_VALUES:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getDentitionValues(jwt)
                    .then(res => {
                        dispatch(actions.constant.getDentitionValuesResponse(
                            mapper.response.getDentitionValues(res)))
                    })
                    .catch(err => {
                        dispatch(actions.constant.getDentitionValuesError(err))
                    })
            }));
            break;
        case GET_DELETION_REASON_VALUES:
                dispatch(actions.auth.authenticatedRequest(jwt => {
                    api.getDeletionReasonValues(jwt)
                        .then(res => {
                            dispatch(actions.constant.getDeletionReasonValuesResponse(
                                mapper.response.getDeletionReasonValues(res)))
                        })
                        .catch(err => {
                            dispatch(actions.constant.getDeletionReasonValuesError(err))
                        })
                }));
                break;
        case GET_AVAILABLE_PRODUCTIVE_SYSTEMS:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAvailableProductiveSystemsFacilityType(jwt,'productive')
                    .then(res => {
                        dispatch(actions.constant.getAvailableProductiveSystemsResponse(
                            mapper.response.getAvailableProductiveSystems(res)))
                    })
                    .catch(err => {
                        dispatch(actions.constant.getAvailableProductiveSystemsError(err))
                    })
            }));
            break;
        case GET_FACILITIES_PRODUCTIVE_TYPES:
                dispatch(actions.auth.authenticatedRequest(jwt => {
                    api.getFacilitiesProductiveTypes(jwt)
                        .then(res => {
                            dispatch(actions.constant.getFacilitiesProductiveTypesResponse(
                                mapper.response.getFacilitiesProductiveTypes(res)))
                        })
                        .catch(err => {
                            dispatch(actions.constant.getFacilitiesProductiveTypesError(err))
                        })
                }));
                break;
        case GET_AVAILABLE_PRODUCTIVE_SYSTEMS_FACILITY_TYPE:
                    dispatch(actions.auth.authenticatedRequest(jwt => { 
                        api.getAvailableProductiveSystemsFacilityType(jwt,action.data)
                            .then(res => {
                                dispatch(actions.constant.getAvailableProductiveSystemsFacilityTypeResponse(
                                    mapper.response.getAvailableProductiveSystemsFacilityType(res)))
                            })
                            .catch(err => {
                                dispatch(actions.constant.getAvailableProductiveSystemsFacilityTypeError(err))
                            })
                    }));
                    break;
        case GET_AVAILABLE_ESTABLISHMENT_TYPES:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAvailableEstablishmentTypes(jwt)
                    .then(res => {
                        dispatch(actions.constant.getAvailableEstablishmentTypesResponse(
                            mapper.response.getAvailableEstablishmentTypes(res)))
                    })
                    .catch(err => {
                        dispatch(actions.constant.getAvailableEstablishmentTypesError(err))
                    })
            }));
            break;
        case GET_AVAILABLE_GROUND_CONDITIONS:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAvailableGroundConditions(jwt)
                    .then(res => {
                        dispatch(actions.constant.getAvailableGroundConditionsResponse(
                            mapper.response.getAvailableGroundConditions(res)))
                    })
                    .catch(err => {
                        dispatch(actions.constant.getAvailableGroundConditionsError(err))
                    })
            }));
            break;
        case GET_AVAILABLE_RUCA_ACTIVITIES:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAvailableRucaActivities(jwt)
                    .then(res => {
                        dispatch(actions.constant.getAvailableRucaActivitiesResponse(
                            mapper.response.getAvailableRucaActivities(res)))
                    })
                    .catch(err => {
                        dispatch(actions.constant.getAvailableRucaActivitiesError(err))
                    })
            }));
            break;
        case GET_AVAILABLE_STATES:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAvailableStates(jwt)
                    .then(res => {
                        dispatch(actions.constant.getAvailableStatesResponse(
                            mapper.response.getAvailableStates(res)))
                    })
                    .catch(err => {
                        dispatch(actions.constant.getAvailableStatesError(err))
                    })
            }));
            break;
    }
};

export default constant;