import api from '../../api/index';
import {
    GET_QRS_GENERATED_BY_FACILITY_PAGE, 
    GET_QRS_TEMPLATES, 
    SUBMIT_QR_GENERATION, 
    EDIT_QR_GENERATION, 
    GET_QRS_GENERATED_BY_ID, 
    GET_LOTS_IN_GLOBAL_EVENTS,
    GET_RELATED_IMAGES_BY_LOT,
  } from '../actions/qrGenerationActions';

import actions from '../actions';
import mapperQr from './mappers/qrGenerationMapper';


const event = ({dispatch}) => next => action => {
    next(action);
    switch (action.type) {
        case GET_QRS_GENERATED_BY_FACILITY_PAGE:            
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getQrsGeneratedByFacilityPage(jwt, action.establishmentId, action.pageNumber, action.pageSize)
                .then(res => {
                  let map = mapperQr.response.getQrsGeneratedByFacility(res)
                  let resp = actions.qrGeneration.getQrsGeneratedByFacilityPageResponse(map, action.eventType)
                  dispatch(resp)
                })
                .catch(err => {
                  dispatch(actions.qrGeneration.getQrsGeneratedByFacilityPageError(err))
                });
            }));
            break;
        case GET_QRS_TEMPLATES:                        
                dispatch(actions.auth.authenticatedRequest(jwt => {
                    api.getQrsTemplates(jwt, action.establishmentId)
                    .then(res => {
                      let map = mapperQr.response.getQrsTemplates(res)
                      let resp = actions.qrGeneration.getQrsTemplatesResponse(map, action.eventType)
                      dispatch(resp)
                    })
                    .catch(err => {
                      dispatch(actions.qrGeneration.getQrsTemplatesError(err))
                    });
                }));
                break;
        case GET_QRS_GENERATED_BY_ID:                        
                dispatch(actions.auth.authenticatedRequest(jwt => {
                    api.getQrsGeneratedById(jwt, action.establishmentId)
                    .then(res => {
                      let map = mapperQr.response.getQrsGeneratedById(res)
                      let resp = actions.qrGeneration.getQrsGeneratedByIdResponse(map, action.eventType)
                      dispatch(resp)
                    })
                    .catch(err => {
                      dispatch(actions.qrGeneration.getQrsGeneratedByIdError(err))
                    });
                }));
                break;
        case GET_LOTS_IN_GLOBAL_EVENTS:                        
                dispatch(actions.auth.authenticatedRequest(jwt => {
                    api.getLotsInGlobalEvents(jwt, action.establishmentId,action.globalEventName)
                    .then(res => {
                      let map = mapperQr.response.getLotsInGlobalEvents(res)
                      let resp = actions.qrGeneration.getLotsInGlobalEventsResponse(map, action.eventType)
                      dispatch(resp)
                    })
                    .catch(err => {
                      dispatch(actions.qrGeneration.getLotsInGlobalEventsError(err))
                    });
                }));
                break;
        case SUBMIT_QR_GENERATION:  
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.addQrGeneration(jwt, mapperQr.body.addQrGeneration(action.values), 'QR')
                    .then(res => {
                        dispatch(actions.qrGeneration.submitQrGenerationResponse(res));
                        if(action.successCallback) action.successCallback();
                    })
                    .catch(err => {
                        dispatch(actions.qrGeneration.submitQrGenerationError(err))
                        if(action.errorCallback) action.errorCallback((err));
                    });
            }));
            break;
        case EDIT_QR_GENERATION:  
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.editQrGeneration(jwt, action.qrId,mapperQr.body.editQrGeneration(action.values), 'QR')
                    .then(res => {
                        dispatch(actions.qrGeneration.editQrGenerationResponse(res));
                        if(action.successCallback) action.successCallback();
                    })
                    .catch(err => {
                        dispatch(actions.qrGeneration.editQrGenerationError(err))
                        if(action.errorCallback) action.errorCallback((err));
                    });
            }));
            break;
        case GET_RELATED_IMAGES_BY_LOT:       
                            
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getRelatedImagesByLot(jwt, action.lotId)
                .then(res => {
                  let map = mapperQr.response.getRelatedImagesByLot(res)
                  let resp = actions.qrGeneration.getRelatedImagesByLotResponse(map)
                  dispatch(resp)
                })
                .catch(err => {
                  dispatch(actions.qrGeneration.getLotsInGlobalEventsError(err))
                });
            }));
            break;

    }
};

export default event;