import api from '../../api/index';
import {
    GET_USER,
    GET_USER_PROFILE,
    GET_USER_ROLES,
    EDIT_USER_DATA,
    EDIT_USER_AVATAR,
    GET_USER_ACCOUNTS,
    GET_SURROGATE_USER_ACCOUNTS,
    GET_SURROGATE_USER_ESTABLISHMENTS,
    ADD_USER_COMPANY,
    ADD_ESTABLISHMENT_TO_COMPANY,
    ADD_ROLE_TO_USER,
    UPLOAD_ESTABLISHMENT_IMAGES,
    UPLOAD_COMPANY_IMAGES,
    GET_USER_COMPANY,
    EDIT_USER_COMPANY,
    EDIT_ESTABLISHMENT_LOT,
    GET_USER_ESTABLISHMENTS,
    GET_USER_ESTABLISHMENTS_BY_COMPANIES_PAGINATED,
    GET_ALL_USER_ESTABLISHMENTS,
    GET_USER_ESTABLISHMENT_BY_ID,
    EDIT_USER_ESTABLISHMENT_BY_ID,
    GET_ALL_USER_COMPANIES,
    DELETE_USER_COMPANY,
    DELETE_USER_ESTABLISHMENT,
    DELETE_USER_ESTABLISHMENT_LOT,
    GET_USER_ESTABLISHMENTS_BY_COMPANY,
    GET_PROFESSIONAL_USER_BY_DNI,
    GET_PROFESSIONALS_BY_ESTABLISHMENT,
    ADD_PROFESSIONAL_TO_ESTABLISHMENT,
    GET_SURROGATE_USER_BY_DNI,
    GET_SURROGATES_BY_COMPANY,
    GET_HAS_SLAUGHTERHOUSE_OR_COMMERCIAL,
    ADD_SURROGATE_TO_COMPANY,
    GET_ASSIGNED_ESTABLISHMENTS,
    GET_ASSIGNED_COMPANIES,
    DELETE_USER_FROM_COMPANY,
    DELETE_PROFESSIONAL_FROM_ESTABLISHMENT,
    SEND_EMAIL_TO_ESTABLISHMENT,
    SEND_EMAIL_TO_UNREGISTERED,
    SEND_EMAIL_TO_UNREGISTERED_SURROGATE,
    SUBMIT_ESTABLISHMENT_LOT,
    ADD_ROLE_TO_USER_WITHOUT_LICENSE,
    GET_COMPANY_SCOPE_ANIMALS_COUNT,
    GET_COUNT_FACILITIES_BY_TYPE,
    GET_USER_NOTIFICATIONS
} from '../actions/userActions';

import actions from '../actions';
import mapper from './mappers/userMapper';

const user = ({ dispatch, getState }) => next => action => {
    next(action);
    //console.log(action.type);
    // eslint-disable-next-line default-case
    switch (action.type) {
        case GET_USER_PROFILE:
            api.getUserProfile(action.userId)
                .then(res => {
                    dispatch(actions.user.getUserProfileResponse(res.profile))
                })
                .catch(err => {
                    dispatch(actions.user.getUserProfileError(err))
                });
            break;
        case GET_USER_ROLES:
            api.getUserRoles()
                .then(res => {
                    dispatch(actions.user.getUserRolesResponse(res.roles))
                })
                .catch(err => {
                    dispatch(actions.user.getUserRolesError(err))
                });
            break;
        case GET_USER:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getUser(jwt)
                    .then(res => {
                        dispatch(actions.user.getUserResponse(mapper.response.getUser(res)));
                        if (action.callback) action.callback(res);
                    })
                    .catch(err => {
                        //  todo check the error format and map it if necessary.
                        dispatch(actions.user.getUserError(err))
                    })
            }));
            break;
        case EDIT_USER_DATA:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.editUserData(jwt, getState().user.id, mapper.body.editUserData(action.data))
                    .then(res => {
                        dispatch(actions.user.editUserDataResponse(mapper.response.editUserData(res)));
                        if (action.callback) action.callback();
                        window.location.reload() //TODO remove when data reloading is fixed on server
                    })
                    .catch(err => {
                        dispatch(actions.user.editUserDataError(err))
                    });
            }));
            break;
        case EDIT_USER_AVATAR:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                dispatch(actions.image.uploadImage(action.image, (element) => {
                    api.editUserData(jwt, getState().user.id, mapper.body.editUserAvatar(element))
                        .then(res => {
                            dispatch(actions.user.editUserDataResponse(mapper.response.editUserData(res)));
                            if (action.callback) action.callback();
                        })
                        .catch(err => {
                            dispatch(actions.user.editUserDataError(err))
                        });
                }));
            }));
            break;
        case GET_USER_ACCOUNTS:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getUserAccounts(jwt, action.page, action.pageSize)
                    .then(res => {
                        dispatch(actions.user.getAccountsResponse(mapper.response.getUserAccounts(res)));
                        if (action.callback) action.callback();
                    })
                    .catch(err => {
                        dispatch(actions.user.getAccountsError(err))
                    });
            }));
            break;
        case GET_SURROGATE_USER_ACCOUNTS:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getSurrogateUserAccounts(jwt, action.page, action.pageSize)
                    .then(res => {
                        dispatch(actions.user.getSurrogateAccountsResponse(mapper.response.getSurrogateUserAccounts(res)));
                        if (action.callback) action.callback();
                    })
                    .catch(err => {
                        dispatch(actions.user.getSurrogateAccountsError(err))
                    });
            }));
            break;
        case GET_SURROGATE_USER_ESTABLISHMENTS:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getSurrogateUserEstablishments(jwt, action.page, action.pageSize)
                    .then(res => {
                        dispatch(actions.user.getSurrogateEstablishmentsResponse(mapper.response.getSurrogateUserEstablishments(res)));
                        if (action.callback) action.callback();
                    })
                    .catch(err => {
                        dispatch(actions.user.getSurrogateUserEstablishmentsError(err))
                    });
            }));
            break;
        case ADD_USER_COMPANY:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                const images = mapper.data.uploadCompanyImages(action.info);
                dispatch(actions.user.uploadCompanyImages(images, (uploadedImagesAndUrls) => {
                    api.addUserCompany(jwt, mapper.body.addUserCompany(action.info, uploadedImagesAndUrls))
                        .then(res => {
                        console.log("🚀 ~ ACTION.INFO 3 file: userMiddleware.js ~ line 157 ~ dispatch ~ res", res)
                            
                            dispatch(actions.user.addCompanyResponse(mapper.response.addUserCompany(res)))
                       
                            dispatch(actions.user.getAllUserCompanies());
                            //  The res._id is the id of the recent created company.
                            if (action.successCallback) action.successCallback(res._id);
                        })
                        .catch(err => {
                            dispatch(actions.user.addCompanyError(err));
                            if (action.errorCallback) action.errorCallback((err));

                        });
                }))
            }));
            break;
        case SUBMIT_ESTABLISHMENT_LOT:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.addEstablishmentLot(jwt,action.values.establishmentId, action.values)
                    .then(res => {
                        dispatch(actions.user.submitEstablishmentLotResponse(res));
                        dispatch(actions.user.getEstablishmentById(action.values.establishmentId))
                        dispatch(actions.user.getUserEstablishmentsByCompany(res.company));
                        if (action.successCallback) action.successCallback();
                    })
                    .catch(err => {
                        dispatch(actions.user.submitEstablishmentLotError(err));
                        if (action.errorCallback) action.errorCallback((err));
                    });
            }));
            break;
        case ADD_ESTABLISHMENT_TO_COMPANY:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                //  Map all the images to be upload.
                const images = mapper.data.uploadEstablishmentImages(action.info, action.establishmentType);
                //  Upload all images.
                dispatch(actions.user.uploadEstablishmentImages(images, (uploadedImagesAndUrls) => {                    
                    api.addEstablishmentToCompany(jwt, action.establishmentType,
                        //  Map the request body                        
                        mapper.body.addEstablishmentToCompany(action.info, action.companyId, action.establishmentType, uploadedImagesAndUrls))
                        .then(res => {
                            dispatch(actions.user.getAllUserCompanies());
                            dispatch(actions.user.getUserEstablishmentsByCompany(action.companyId));
                            dispatch(actions.user.addEstablishmentToCompanyResponse(res));
                            if (action.successCallback) action.successCallback();
                        })
                        .catch(err => {
                            dispatch(actions.user.addEstablishmentToCompanyError(err));
                            if (action.errorCallback) action.errorCallback((err));
                        });
                }))
            }));
            break;
        case ADD_ROLE_TO_USER:            
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.addRoleToUser(jwt, action.userId, action.role, mapper.body.addRoleToUser(action.role, action.license))
                    .then(res => {
                        dispatch(actions.user.addRoleToUserResponse(mapper.response.addRole(res)));
                        if (action.callback) action.callback();
                    })
                    .catch(err => {
                        dispatch(actions.user.addRoleToUserError(err))
                    });
            }));
            break;
        case ADD_ROLE_TO_USER_WITHOUT_LICENSE:            
            dispatch(actions.auth.authenticatedRequest(jwt => {
                    api.addRoleToUser(jwt, action.userId, action.role, mapper.body.addRoleToUser(action.role, null))
                        .then(res => {
                            dispatch(actions.user.addRoleToUserWithoutLicenseResponse(mapper.response.addRole(res)));
                            if (action.callback) action.callback();
                        })
                        .catch(err => {
                            dispatch(actions.user.addRoleToUserWithoutLicenseError(err))
                        });
                }));
                break;
        case UPLOAD_ESTABLISHMENT_IMAGES:
            dispatch(actions.auth.authenticatedRequest((jwt) => {
                //  All the images to be upload.
                let imagesToUpload = action.images;
                //  All the urls request promises
                let urlsPromises = [];
                //  Map the image id with the url.
                let imageIdsAndAssociatedUrl = {};

                //  Upload all images
                imagesToUpload.forEach((imageObj) => {
                    let urlPromise = api.uploadImage(jwt, imageObj.image, imageObj.id);
                    urlsPromises.push(urlPromise);
                });

                Promise.all(urlsPromises)
                    .then((response) => {
                        response.forEach(element => {
                            let imageName = element.additionalData.name;
                            let imageUrl = element.images.cvImage;
                            imageIdsAndAssociatedUrl[`${imageName}`] = imageUrl
                        });

                        if (action.callback) action.callback(imageIdsAndAssociatedUrl)
                    })
                    .catch(err => {
                        //console.log("Error when uploading images ", err);
                    });
            }));
            break;
        case UPLOAD_COMPANY_IMAGES:
            dispatch(actions.auth.authenticatedRequest((jwt) => {
                //  All the images to be upload.
                let imagesToUpload = action.images;
                //  All the urls request promises
                let urlsPromises = [];
                //  Map the image id with the url.
                let imageIdsAndAssociatedUrl = {};

                //  Upload all images
                imagesToUpload.forEach((imageObj) => {
                    let urlPromise = api.uploadImage(jwt, imageObj.image, imageObj.id);
                    urlsPromises.push(urlPromise);
                });

                Promise.all(urlsPromises)
                    .then((response) => {
                        response.forEach(element => {
                            let imageName = element.additionalData.name;
                            let imageUrl = element.images.cvImage;
                            imageIdsAndAssociatedUrl[`${imageName}`] = imageUrl
                        });

                        if (action.callback) action.callback(imageIdsAndAssociatedUrl)
                    })
                    .catch(err => {
                        //console.log("Error when uploading images ", err);
                    });
            }));
            break;
        case GET_USER_COMPANY:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getUserCompanyById(jwt, action.companyId)
                    .then(res => {
                        dispatch(actions.user.getCompanyResponse(mapper.response.getUserCompany(res)));
                    })
                    .catch(err => {
                        dispatch(actions.user.getCompanyError(err))
                    });
            }));
            break;
        case GET_COMPANY_SCOPE_ANIMALS_COUNT:
                dispatch(actions.auth.authenticatedRequest(jwt => {
                    api.getCompanyScopeAnimalsCount(jwt, action.facilityId)
                        .then(res => {
                            dispatch(actions.user.getCompanyScopeAnimalsCountResponse(mapper.response.getCompanyScopeAnimalsCount(res)));
                        })
                        .catch(err => {
                            dispatch(actions.user.getCompanyScopeAnimalsCountError(err))
                        });
                }));
                break;
        case GET_USER_NOTIFICATIONS:
                    dispatch(actions.auth.authenticatedRequest(jwt => {
                        api.getUserNotifications(jwt, action.facilityId)
                            .then(res => {
                                dispatch(actions.user.getUserNotificationsResponse(mapper.response.getUserNotifications(res)));
                                if (action.successCallback) action.successCallback(res);
                            })
                            .catch(err => {
                                dispatch(actions.user.getUserNotificationsError(err))                                
                                if (action.errorCallback) action.errorCallback((err));
                            });
                    }));
                    break;
        case GET_USER_ESTABLISHMENTS:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getUserEstablishments(jwt, action.page, action.pageSize)
                    .then(res => {
                        dispatch(actions.user.getEstablishmentsResponse(mapper.response.getUserEstablishments(res)));
                        // todo separate this when the 'get all establishment' and the 'get establishment paginated'
                        //  are different request
                        dispatch(actions.user.getAllUserEstablishmentsResponse(mapper.response.getAllUserEstablishments(res)))

                    })
                    .catch(err => {
                        dispatch(actions.user.getEstablishmentsError(err))
                    })
            }));
            break;
        case GET_USER_ESTABLISHMENTS_BY_COMPANIES_PAGINATED:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getUserEstablishmentsByCompaniesPaginated(jwt, action.page, action.pageSize)
                    .then(res => {
                        dispatch(actions.user.getUserEstablishmentsByCompaniesPaginatedResponse(mapper.response.getUserEstablishmentsByCompaniesPaginated(res)));
                        if (action.callback) action.callback();
                    })
                    .catch(err => {
                        dispatch(actions.user.getUserEstablishmentsByCompaniesPaginatedError(err))
                    })
            }));
            break;
        case GET_ALL_USER_ESTABLISHMENTS:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAllUserEstablishments(jwt)
                    .then(res => {
                        dispatch(actions.user.getAllUserEstablishmentsResponse(mapper.response.getAllUserEstablishments(res)))
                    })
                    .catch(err => {
                        dispatch(actions.user.getAllUserEstablishmentsError(err))
                    })
            }));
            break;
        case GET_USER_ESTABLISHMENT_BY_ID:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getUserEstablishmentById(jwt, action.establishmentId)
                    .then(res => {
                        dispatch(actions.user.getEstablishmentByIdResponse(mapper.response.getUserEstablishmentById(res)))
                        if (action.callback) action.callback();
                    })
                    .catch(err => {
                        dispatch(actions.user.getEstablishmentByIdError(err))
                    })
            }));
            break;
        // eslint-disable-next-line no-duplicate-case
        // case ADD_USER_COMPANY:
        //     dispatch(actions.auth.authenticatedRequest(jwt => {
        //         const images = mapper.data.uploadCompanyImages(action.info);
        //         dispatch(actions.user.uploadCompanyImages(images, (uploadedImagesAndUrls) => {
        //             api.addUserCompany(jwt, mapper.body.addUserCompany(action.info, uploadedImagesAndUrls))
        //                 .then(res => {
        //                     dispatch(actions.user.addCompanyResponse(mapper.response.addUserCompany(res)));
        //                     dispatch(actions.user.getAllUserCompanies());
        //                     //  The res._id is the id of the recent created company.
        //                     if (action.successCallback) action.successCallback(res._id);
        //                 })
        //                 .catch(err => {
        //                     dispatch(actions.user.addCompanyError(err));
        //                     if (action.errorCallback) action.errorCallback((err));
        //                 });
        //         }))
        //     }));
        //     break;
        case EDIT_USER_COMPANY:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                const images = mapper.data.uploadCompanyImages(action.data);
                dispatch(actions.user.uploadCompanyImages(images, (uploadedImagesAndUrls) => {
                    api.editUserCompanyById(jwt, action.companyId, mapper.body.editUserCompanyById(action.data, uploadedImagesAndUrls))
                        .then(res => {
                            
                            dispatch(actions.user.getAccounts(1, 10, () => {
                                dispatch(actions.user.editCompanyResponse(mapper.response.editUserCompanyById(res)));
                                if (action.successCallback) action.successCallback();
                            }));
                        })
                        .catch(err => {
                            dispatch(actions.user.editCompanyError(err));
                            if(action.errorCallback) action.errorCallback((err));
                        })
                }))
            }));
            break;

        case EDIT_USER_ESTABLISHMENT_BY_ID:            
            dispatch(actions.auth.authenticatedRequest(jwt => {
                //  Map all the images that has been change
                const images = mapper.data.uploadEstablishmentImages(action.data, action.establishmentType);
                // Upload all the images that has been change
                if (images.length > 0) {
                    dispatch(actions.user.uploadEstablishmentImages(images, (uploadedImagesAndUrls) => {
                        api.editUserEstablishmentById(jwt, action.establishmentId,
                            mapper.body.editUserEstablishmentById(action.data, action.establishmentType, uploadedImagesAndUrls))
                            .then(res => {
                                dispatch(actions.user.getUserEstablishmentsByCompany(res.company));
                                dispatch(actions.user.editEstablishmentByIdResponse(res));
                                if (action.successCallback) action.successCallback();
                            })
                            .catch(err => {
                                dispatch(actions.user.editEstablishmentByIdError(err));
                                if (action.errorCallback) action.errorCallback((err));
                            })
                    }))
                } else {
                    api.editUserEstablishmentById(jwt, action.establishmentId,
                        mapper.body.editUserEstablishmentById(action.data, action.establishmentType))
                        .then(res => {
                            dispatch(actions.user.getUserEstablishmentsByCompany(res.company));
                            dispatch(actions.user.editEstablishmentByIdResponse(res));
                            if (action.successCallback) action.successCallback();
                        })
                        .catch(err => {
                            dispatch(actions.user.editEstablishmentByIdError(err));
                            if (action.errorCallback) action.errorCallback((err));
                        })
                }
            }));
            break;
        case GET_ALL_USER_COMPANIES:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAllUserCompanies(jwt)
                    .then(res => {
                        dispatch(actions.user.getAllUserCompaniesResponse(mapper.response.getAllUserCompanies(res)))
                    })
                    .catch(err => {
                        dispatch(actions.user.getAllUserCompaniesError(err))
                    })
            }));
            break;
        case DELETE_USER_COMPANY:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.deleteUserCompanyById(jwt, action.companyId)
                    .then(() => {
                        dispatch(actions.user.getAccounts(1, 10));
                        dispatch(actions.user.getAllUserCompanies());

                        // If the company deleted is the one that was selected, delete on reducer.
                        let currentCompanySelected = getState().user.selectedCompany;
                        if (currentCompanySelected._id === action.companyId) {
                            dispatch(actions.user.resetSelectedCompany())
                        }
                        if (action.callback) action.callback();
                    })
                    .catch(err => {
                        dispatch(actions.user.deleteUserCompanyByIdError(err))
                        if (action.errorCallback) action.errorCallback();
                    })
            }));
            break;
        case DELETE_USER_ESTABLISHMENT:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.deleteUserEstablishmentById(jwt, action.establishmentId)
                    .then(() => {
                        dispatch(actions.user.getUserEstablishmentsByCompaniesPaginated(1, 10));
                        if (action.callback) action.callback();
                    })
                    .catch(err => {
                        dispatch(actions.user.deleteUserEstablishmentByIdError(err))
                        if (action.errorCallback) action.errorCallback();
                    })

            }));
            break;
        case DELETE_USER_ESTABLISHMENT_LOT:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.deleteUserEstablishmentLotById(jwt, action.establishmentLotId)
                    .then(() => {
                        dispatch(actions.user.getEstablishments(1, 10));
                        if (action.callback) action.callback();
                    })
                    .catch(err => {
                        dispatch(actions.user.deleteUserEstablishmentLotByIdError(err))
                    })
            }));
            break;
        case EDIT_ESTABLISHMENT_LOT:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.editEstablishmentLot(jwt, action.data.establishmentId, action.data)
                .then(res => {
                        if (action.callback) action.successCallback();
                    })
                    .catch(err => {
                        dispatch(actions.user.editEstablishmentLotError(err));
                        if (action.errorCallback) action.errorCallback();
                    })
            }));
            break;

       

        case GET_USER_ESTABLISHMENTS_BY_COMPANY:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getUserEstablishmentsByCompany(jwt, action.companyId)
                    .then(res => {
                        dispatch(actions.user.getUserEstablishmentsByCompanyResponse(
                            mapper.response.getUserEstablishmentsByCompany(res)))
                    })
                    .catch(err => {
                        dispatch(actions.user.getUserEstablishmentsByCompanyError(err))
                    })
            }));
            break;
        case GET_PROFESSIONAL_USER_BY_DNI:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getProfessionalUserByDni(jwt, action.dni)
                    .then(res => {
                        let currentProfessionals = getState().user.establishmentProfessionals;
                        dispatch(actions.user.getProfessionalUserByDniResponse(
                            mapper.response.getProfessionalUserByDni(res, currentProfessionals)))
                    })
                    .catch(err => {
                        dispatch(actions.user.getProfessionalUserByDniError(err))
                    })
            }));
            break;
        case GET_PROFESSIONALS_BY_ESTABLISHMENT:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                // the professionals of the establishment are on the establishment details
                api.getUserEstablishmentById(jwt, action.establishmentId)
                    .then(res => {
                        dispatch(actions.user.getProfessionalsByEstablishmentResponse(
                            mapper.response.getProfessionalsByEstablishment(res)))
                    })
                    .catch(err => {
                        dispatch(actions.user.getProfessionalsByEstablishmentError(err))
                    })
            }));
            break;
        case ADD_PROFESSIONAL_TO_ESTABLISHMENT:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.addProfessionalToEstablishment(jwt, action.establishmentId, action.userId, action.role)
                    .then(res => {
                        dispatch(actions.user.resetProfessionalUserSearch());
                        dispatch(actions.user.addProfessionalToEstablishmentResponse(
                            mapper.response.getProfessionalsByEstablishment(res)))
                    })
                    .catch(err => {
                        dispatch(actions.user.addProfessionalToEstablishmentError(err))
                    })
            }));
            break;
        case DELETE_PROFESSIONAL_FROM_ESTABLISHMENT:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.deleteProfessionalFromEstablishment(jwt, action.establishmentId, action.userId, action.role)
                    .then(res => {
                        dispatch(actions.user.deleteProfessionalFromEstablishmentResponse(
                            mapper.response.getProfessionalsByEstablishment(res)))
                    })
                    .catch(err => {
                        dispatch(actions.user.deleteProfessionalFromEstablishmentError(err))
                    })
            }));
            break;
        case GET_ASSIGNED_ESTABLISHMENTS:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAssignedEstablishments(jwt, action.userId)
                    .then(res => {
                        dispatch(actions.user.getAssignedEstablishmentsResponse(res))
                    })
                    .catch(err => {
                        dispatch(actions.user.getAssignedEstablishmentsError(err))
                    })
            }))
            break;
        case GET_ASSIGNED_COMPANIES:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getAssignedCompanies(jwt, action.userId)
                    .then(res => {
                        dispatch(actions.user.getAssignedCompaniesResponse(mapper.response.getAssignedCompanies(res)))
                    })
                    .catch(err => {
                        dispatch(actions.user.getAssignedCompaniesError(err))
                    })
            }))
            break;
        case GET_SURROGATE_USER_BY_DNI:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getSurrogateUserByDni(jwt, action.dni)
                    .then(res => {
                        dispatch(actions.user.resetProfessionalUserSearch());
                        let currentSurrogates = getState().user.companySurrogates;
                        dispatch(actions.user.getSurrogateUserByDniResponse(
                            mapper.response.getSurrogateUserByDni(res, currentSurrogates)))
                    })
                    .catch(err => {
                        dispatch(actions.user.getSurrogateUserByDniError(err))
                    })
            }));
            break;
        case GET_SURROGATES_BY_COMPANY:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getUserCompanyById(jwt, action.companyId)
                    .then(res => {
                        dispatch(actions.user.getSurrogatesByCompanyResponse(
                            mapper.response.getSurrogatesByCompany(res)))
                    })
                    .catch(err => {
                        dispatch(actions.user.getSurrogatesByCompanyError(err))
                    })
            }));
            break;
        case GET_HAS_SLAUGHTERHOUSE_OR_COMMERCIAL:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.getHasSlaughterhouseOrCommercial(jwt, action.userId, action.role)
                    .then(res => {
                        dispatch(actions.user.getHasSlaughterhouseOrCommercialResponse(
                            mapper.response.getHasSlaughterhouseOrCommercial(res)))
                    })
                    .catch(err => {
                        dispatch(actions.user.getHasSlaughterhouseOrCommercialError(err))
                    })
            }));
            break;
        case ADD_SURROGATE_TO_COMPANY:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.addUserToCompany(jwt, action.companyId, action.userId, action.role)
                    .then(res => {
                        dispatch(actions.user.resetSurrogateUserSearch());
                        dispatch(actions.user.addUserToCompanyResponse(
                            mapper.response.getSurrogatesByCompany(res)))
                    })
                    .catch(err => {
                        dispatch(actions.user.addUserToCompanyError(err))
                    })
            }));
            break;
        case DELETE_USER_FROM_COMPANY:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.deleteUserFromCompany(jwt, action.companyId, action.userId)
                    .then(res => {
                        dispatch(actions.user.deleteUserFromCompanyResponse(
                            mapper.response.getSurrogatesByCompany(res)))
                    })
                    .catch(err => {
                        dispatch(actions.user.deleteUserFromCompanyError(err))
                    })
            }));
            break;
        case SEND_EMAIL_TO_ESTABLISHMENT:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.sendMailToEstablishment(jwt, action.establishmentId, action.userId)
                    .then(res => {
                        dispatch(actions.user.sendMailToEstablishmentResponse(res))
                    })
                    .catch(err => {
                        dispatch(actions.user.sendMailToEstablishmentError(err))
                    });
            }))
            break;
        case SEND_EMAIL_TO_UNREGISTERED:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.sendMailToUnregistered(jwt, action.establishmentId, action.email)
                    .then(res => {
                        dispatch(actions.user.sendMailToUnregisteredResponse(res))
                    })
                    .catch(err => {
                        dispatch(actions.user.sendMailToUnregisteredResponse(err))
                    })
            }))
            break;
        case SEND_EMAIL_TO_UNREGISTERED_SURROGATE:
            dispatch(actions.auth.authenticatedRequest(jwt => {
                api.sendMailToUnregisteredSurrogate(jwt, action.companyId, action.email)
                    .then(res => {
                        dispatch(actions.user.sendMailToUnregisteredSurrogateResponse(res))
                    })
                    .catch(err => {
                        dispatch(actions.user.sendMailToUnregisteredSurrogateError(err))
                    })
            }))
            break;
            case GET_COUNT_FACILITIES_BY_TYPE:
                dispatch(actions.auth.authenticatedRequest(jwt => {
                    api.getCountUserFacilitiesByType(jwt, getState().user.id)
                        .then(res => {
                            dispatch(actions.user.getCountUserFacilitiesByTypeResponse(res))
                        })
                        .catch(err => {
                            dispatch(actions.user.getCountUserFacilitiesByTypeError(err))
                        })
                }))
                break;    
    }
}
export default user;
