import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarLinkExternalToBlank from './SidebarLinkExternalToBlank';
import SidebarCategory from './SidebarCategory';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import sessionActions from '../../../redux/actions/sessionActions';
import actions from "../../../redux/actions";
import {SIDEBAR_LINKS} from "../../../constants";
import { ROLES } from '../../../constants';
import { withTranslation } from "react-i18next";
import BarnIcon from 'mdi-react/BarnIcon';
import CowIcon from 'mdi-react/CowIcon';
import CalendarIcon from 'mdi-react/CalendarIcon';
import CalendarPlusIcon from "mdi-react/CalendarPlusIcon";
import BellIcon from 'mdi-react/BellIcon';
import AccountGroupIcon from 'mdi-react/AccountGroupIcon';
import AccountKeyIcon from 'mdi-react/AccountKeyIcon';
import DramaMasksIcon from 'mdi-react/DramaMasksIcon';
import ExitToAppIcon from 'mdi-react/ExitToAppIcon';
import FileDocumentEditIcon from 'mdi-react/FileDocumentEditIcon';
import DatabaseIcon from 'mdi-react/DatabaseIcon';
import HelpIcon from 'mdi-react/HelpIcon';
import FilePlusIcon from 'mdi-react/FilePlusIcon'
import ChartFinanceIcon from 'mdi-react/ChartFinanceIcon'
import FootPrintIcon from 'mdi-react/FootPrintIcon'
import setting from '../../../util/settings';

const ADMIN_SIDEBAR_LINKS = [
  SIDEBAR_LINKS.bi,    
  SIDEBAR_LINKS.accounts,
  SIDEBAR_LINKS.establishments,
  SIDEBAR_LINKS.establishmentLots,  
  SIDEBAR_LINKS.users,
  SIDEBAR_LINKS.roles,
  SIDEBAR_LINKS.permissions,
  SIDEBAR_LINKS.animals,
  SIDEBAR_LINKS.events,

  SIDEBAR_LINKS.weight,
  SIDEBAR_LINKS.productive_system_change,
  SIDEBAR_LINKS.dentition,
  SIDEBAR_LINKS.reidentification,
  SIDEBAR_LINKS.castration,
  SIDEBAR_LINKS.recategorization,
  SIDEBAR_LINKS.slaughter,
  SIDEBAR_LINKS.third_party_slaughter,
  SIDEBAR_LINKS.deletion,
  SIDEBAR_LINKS.lot,

  SIDEBAR_LINKS.certificates,  
  SIDEBAR_LINKS.transfer,  
  SIDEBAR_LINKS.generalData,
  SIDEBAR_LINKS.notifications,
  SIDEBAR_LINKS.microsite,
  SIDEBAR_LINKS.qrGeneration,  
  SIDEBAR_LINKS.asekuro,

  SIDEBAR_LINKS.tools,
  SIDEBAR_LINKS.reports,
  SIDEBAR_LINKS.karbono,
  SIDEBAR_LINKS.gdp
];

const TITULAR_SIDEBAR_LINKS = [
  SIDEBAR_LINKS.bi,  
  SIDEBAR_LINKS.accounts,
  SIDEBAR_LINKS.establishments,  
  SIDEBAR_LINKS.establishmentLots,  
  SIDEBAR_LINKS.animals,
  SIDEBAR_LINKS.events,
  SIDEBAR_LINKS.weight,
  SIDEBAR_LINKS.productive_system_change,
  SIDEBAR_LINKS.dentition,
  SIDEBAR_LINKS.reidentification,
  SIDEBAR_LINKS.castration,
  SIDEBAR_LINKS.recategorization,
  SIDEBAR_LINKS.slaughter,
  SIDEBAR_LINKS.third_party_slaughter,
  SIDEBAR_LINKS.deletion,
  SIDEBAR_LINKS.lot,
  SIDEBAR_LINKS.certificates,  
  SIDEBAR_LINKS.transfer,    
  SIDEBAR_LINKS.microsite,  
  SIDEBAR_LINKS.qrGeneration,  
  SIDEBAR_LINKS.asekuro,  
  
  SIDEBAR_LINKS.tools,
  SIDEBAR_LINKS.reports,
  SIDEBAR_LINKS.karbono,
  SIDEBAR_LINKS.gdp
];

const SURROGATE_SIDEBAR_LINKS = [  
  SIDEBAR_LINKS.bi,  
  SIDEBAR_LINKS.accounts,
  SIDEBAR_LINKS.establishments,
  SIDEBAR_LINKS.establishmentLots,
  SIDEBAR_LINKS.animals,
  SIDEBAR_LINKS.events,
  SIDEBAR_LINKS.weight,
  SIDEBAR_LINKS.productive_system_change,
  SIDEBAR_LINKS.dentition,
  SIDEBAR_LINKS.reidentification,
  SIDEBAR_LINKS.castration,
  SIDEBAR_LINKS.recategorization,
  SIDEBAR_LINKS.slaughter,
  SIDEBAR_LINKS.third_party_slaughter,
  SIDEBAR_LINKS.deletion,
  SIDEBAR_LINKS.lot,
  SIDEBAR_LINKS.transfer,    
  SIDEBAR_LINKS.certificates,  
  SIDEBAR_LINKS.microsite,  
  SIDEBAR_LINKS.qrGeneration,  
  SIDEBAR_LINKS.asekuro,

  SIDEBAR_LINKS.tools,
  SIDEBAR_LINKS.reports,
  SIDEBAR_LINKS.karbono,
  SIDEBAR_LINKS.gdp
];

const PROFESSIONAL_SIDEBAR_LINKS = [
  SIDEBAR_LINKS.bi,  
  SIDEBAR_LINKS.animals,
  SIDEBAR_LINKS.events,
  SIDEBAR_LINKS.weight,
  SIDEBAR_LINKS.productive_system_change,
  SIDEBAR_LINKS.dentition,
  SIDEBAR_LINKS.reidentification,
  SIDEBAR_LINKS.castration,
  SIDEBAR_LINKS.recategorization,
  SIDEBAR_LINKS.slaughter,
  SIDEBAR_LINKS.third_party_slaughter,
  SIDEBAR_LINKS.deletion,
  SIDEBAR_LINKS.lot
];

class SidebarContent extends Component {
  componentDidMount() {
    if(!this.props.facilitiesByType) this.props.countFacilitiesByType()
  }

  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  //TODO missing events are commented
  getSidebarLink = (name) => {
    switch (name) {

      case SIDEBAR_LINKS.establishments:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={BarnIcon} title={this.props.t('cv-sidebar.linksNames.establishments')} route="/manage_establishments/table"/>);
      case SIDEBAR_LINKS.establishmentLots:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={CalendarPlusIcon} title={this.props.t('cv-sidebar.linksNames.establishmentLots')} route="/manage_establishments/establishment_lots_table" />);
      case SIDEBAR_LINKS.animals:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={CowIcon} title={this.props.t('cv-sidebar.linksNames.animals')} route="/animals/table"/>);
      case SIDEBAR_LINKS.events:
        return (
            <SidebarCategory key={'sidebar-category-events'} icon={CalendarIcon} title={this.props.t('cv-sidebar.linksNames.events')}>

              <SidebarLink key={`${name.toLowerCase()}.sidebarLink`} title={this.props.t('cv-sidebar.linksNames.eventTypes.weight')} 
                route="/events/weight/table" />
              <SidebarLink key={`${name.toLowerCase()}.sidebarLink`} title={this.props.t('cv-sidebar.linksNames.eventTypes.productive_system_change')} 
                route="/events/change_productive_system/table" />
              <SidebarLink key={`${name.toLowerCase()}.sidebarLink`} title={this.props.t('cv-sidebar.linksNames.eventTypes.dentition')} 
                route="/events/dentition/table" />
              <SidebarLink key={`${name.toLowerCase()}.sidebarLink`} title={this.props.t('cv-sidebar.linksNames.eventTypes.reidentification')} 
                route="/events/reidentification/table" />
              <SidebarLink key={`${name.toLowerCase()}.sidebarLink`} title={this.props.t('cv-sidebar.linksNames.eventTypes.castration')} 
                route="/events/castration/table" />
              <SidebarLink key={`${name.toLowerCase()}.sidebarLink`} title={this.props.t('cv-sidebar.linksNames.eventTypes.recategorization')} 
                route="/events/recategorization/table" />

              {(this.isSlaughterEstablishment() && setting.isTrupoEnabled)? 
              <SidebarLink key={'slaughter'} title={this.props.t('cv-sidebar.linksNames.eventTypes.slaughter')} 
                route="/events/third_party_slaughter/table"/>
              :null}

              {(this.isCommercialEstablishment() && setting.isTrupoEnabled)? 
              <SidebarLink key={'third_party_slaughter'} title={this.props.t('cv-sidebar.linksNames.eventTypes.third_party_slaughter')} 
                route="/events/third_party_slaughter/table"/>
              :null}

              {(this.props.userRoles[0].name == ROLES.TITULAR || this.props.userRoles[0].name == ROLES.SURROGATE)?
              <SidebarLink key={'deletion'} title={this.props.t('cv-sidebar.linksNames.eventTypes.deletion')} 
                route="/events/deletion/table"/>
              :null}

              <SidebarLink key={`${name.toLowerCase()}.sidebarLink`} title={this.props.t('cv-sidebar.linksNames.eventTypes.lot')} 
                route="/events/lot/table" />

            </SidebarCategory>
        );

      case SIDEBAR_LINKS.transfer:
        return(<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={CalendarIcon} title={this.props.t('cv-sidebar.linksNames.transfer')} route="/events/transfer/table" />)
      case SIDEBAR_LINKS.notifications:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={BellIcon} title={this.props.t('cv-sidebar.linksNames.notifications')} route="/notifications" />);
      case SIDEBAR_LINKS.users:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={AccountGroupIcon} title={this.props.t('cv-sidebar.linksNames.users')} route="/users/table"/>);
      case SIDEBAR_LINKS.roles:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={DramaMasksIcon} title={this.props.t('cv-sidebar.linksNames.roles')} route="/roles/table"/>);
      case SIDEBAR_LINKS.permissions:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={AccountKeyIcon} title={this.props.t('cv-sidebar.linksNames.permissions')} />);
      case SIDEBAR_LINKS.accounts:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={FileDocumentEditIcon} title={this.props.t('cv-sidebar.linksNames.companies')} route="/manage_accounts/table"/>);
      case SIDEBAR_LINKS.generalData:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={DatabaseIcon} title={this.props.t('cv-sidebar.linksNames.general_data')} route="/generalData/table"/>);
      case SIDEBAR_LINKS.bi:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={DatabaseIcon} title={this.props.t('cv-sidebar.linksNames.home')} route="/bi/dashboard"/>);
      case SIDEBAR_LINKS.microsite:
          return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={FileDocumentEditIcon} title={this.props.t('cv-sidebar.linksNames.microsite')} route="/templates/microsite"/>);        
      case SIDEBAR_LINKS.qrGeneration:
          if (setting.isTrupoEnabled){ 
           return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={FileDocumentEditIcon} title={this.props.t('cv-sidebar.linksNames.qrGeneration')} route="/qrGeneration/table" newIcon={true}/>);             
          }
      case SIDEBAR_LINKS.certificates:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={FileDocumentEditIcon} title={this.props.t('cv-sidebar.linksNames.certificates')} route="/certificates/table"/>);        
      case SIDEBAR_LINKS.asekuro:
        return (<SidebarLink key={`${name.toLowerCase()}.sidebarLink`} icon={FilePlusIcon} title={this.props.t('cv-sidebar.linksNames.asekuro')} route="/asekuro" newIcon={true}/>);        
      case SIDEBAR_LINKS.tools:
        return (
            <SidebarCategory key={'sidebar-category-tools'} icon={ChartFinanceIcon} title={this.props.t('cv-sidebar.linksNames.tools')}>
                <SidebarLink key={`${name.toLowerCase()}.sidebarLink`} title={this.props.t('cv-sidebar.linksNames.reports')} route="/reports" newIcon={true}/>    
                <SidebarLink key={`${name.toLowerCase()}.sidebarLink`} title={this.props.t('cv-sidebar.linksNames.karbono')} route="/karbono" isBeta={true}/>
                <SidebarLink key={`${name.toLowerCase()}.sidebarLink`} title={this.props.t('cv-sidebar.linksNames.gdp')} route="/GDP" newIcon={true}/> 
            </SidebarCategory>
        );  
      }
  };

  getLinksToShow = (roles) => {
    let results = [];
    roles.forEach(rol => {
      switch (rol.name) {
        case ROLES.ADMIN:
          results.push(...ADMIN_SIDEBAR_LINKS);
          break;
        case ROLES.TITULAR:
          results.push(...TITULAR_SIDEBAR_LINKS);
          break;
        case ROLES.AGRICULTURAL_ENGINEER:
          results.push(...PROFESSIONAL_SIDEBAR_LINKS);
          break;
        case ROLES.VETERINARY:
          results.push(...PROFESSIONAL_SIDEBAR_LINKS);
          break;
        case ROLES.SURROGATE:
          results.push(...SURROGATE_SIDEBAR_LINKS);
          break;
      }
    });
    return [...new Set(results)];
  };

  isSlaughterEstablishment = () => {
    if (this.props.selectedFacilityType == "slaughterhouse") return true
    else return false
  };

  isCommercialEstablishment = () => {
    if (this.props.selectedFacilityType == "commercial") return true
    else return false
  };

  userDoesNotHaveARol = () => {
    return (this.props.userRoles[0].name === ROLES.WITH_OUT_ROLE)
  };

  render() {
    const { changeToLight, changeToDark, logout, userRoles} = this.props;
    const sidebarLinks = this.getLinksToShow(userRoles);
    
    return (
      <div className="sidebar__content">
        {/* todo uncomment when dashboards are supported */}
        {/*<ul className="sidebar__block">
          {
            this.userDoesNotHaveARol() ?
                this.getSidebarLink(SIDEBAR_LINKS.accounts):
                <CVDashboards/>
          }
        </ul>*/}        
        {
          (sidebarLinks.length !== 0) &&
          <ul className="sidebar__block">
            {sidebarLinks.map(linkName => this.getSidebarLink(linkName))}
          </ul>
        }        
        <ul className="sidebar__block">
          <SidebarLinkExternalToBlank icon={HelpIcon} title={this.props.t('cv-sidebar.linksNames.help')} route="https://www.carnesvalidadas.com/helpi/"/>
          <SidebarLink title="Log Out" icon={ExitToAppIcon} route="/log_in" onClick={() => logout()}/>
        </ul>        
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userRoles: state.user.roles,
  userId: state.user.id,
  facilitiesByType: state.user.countFacilitiesByType,
  selectedFacilityType: !state.user.selectedEstablishment ? null : state.user.selectedEstablishment.type
  //selectedFacilityType: state.user.selectedEstablishment.type
  //countFrigo: state.user.facilitiesByType.commercial,
  //countProductive: state.user.facilitiesByType.productive,
  //countCommercial: state.user.facilitiesByType.commercial,
  
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(sessionActions.logout()),
    countFacilitiesByType: (userId) => dispatch(actions.user.getCountUserFacilitiesByType(userId))
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(withRouter(SidebarContent)));
