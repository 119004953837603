import React from 'react';
import { Route, Switch } from 'react-router-dom';
import QrGenerationNew from '../../../QrGeneration/QrGenerationNew';
import QrGenerationTable from '../../../QrGeneration/QrGenerationTable';
import QrGenerationDetails from '../../../QrGeneration/QrGenerationDetails';

export default () => (
    <Switch>
        <Route path="/qrGeneration/table" component={QrGenerationTable} />       
        <Route path="/qrGeneration/qrGenerationNew" component={QrGenerationNew} />       
        <Route path="/qrGeneration/qrGenerationDetails" component={QrGenerationDetails} />       

    </Switch>
);