export const GET_NOTIFICATION_PAGE = 'GET_NOTIFICATION_PAGE';
export const GET_NOTIFICATION_PAGE_RESPONSE = 'GET_NOTIFICATION_PAGE_RESPONSE';
export const GET_NOTIFICATION_PAGE_ERROR = 'GET_NOTIFICATION_PAGE_ERROR';
export const ACCEPT_ROL = 'ACCEPT_ROL';
export const ACCEPT_ROL_RESPONSE = 'ACCEPT_ROL_RESPONSE';
export const ACCEPT_ROL_ERROR = 'ACCEPT_ROL_ERROR';
export const REJECT_ROL = 'REJECT_ROL';
export const REJECT_ROL_RESPONSE = 'REJECT_ROL_RESPONSE';
export const REJECT_ROL_ERROR = 'REJECT_ROL_ERROR';
export const SEARCH_NOTIFICATIONS = 'SEARCH_NOTIFICATIONS';
export const SEARCH_NOTIFICATIONS_RESPONSE = 'SEARCH_NOTIFICATIONS_RESPONSE';
export const SEARCH_NOTIFICATIONS_ERROR = 'SEARCH_NOTIFICATIONS_ERROR';
export const FILTER_NOTIFICATIONS = 'FILTER_NOTIFICATIONS';
export const FILTER_NOTIFICATIONS_RESPONSE = 'FILTER_NOTIFICATIONS_RESPONSE';
export const FILTER_NOTIFICATIONS_ERROR = 'FILTER_NOTIFICATIONS_ERROR';

const notificationActions = {
    getPage(page, pageSize){
        return {
            type: GET_NOTIFICATION_PAGE,
            page,
            pageSize
        }
    },
    getPageResponse(response){
        return {
            type: GET_NOTIFICATION_PAGE_RESPONSE,
            response
        }
    },
    getPageError(message){
        return {
            type: GET_NOTIFICATION_PAGE_ERROR,
            message
        }
    },
    acceptRol(notificationId){
        return {
            type: ACCEPT_ROL,
            notificationId
        }
    },
    acceptRolResponse(response){
        return {
            type: ACCEPT_ROL_RESPONSE,
            response
        }
    },
    acceptRolError(message){
        return {
            type: ACCEPT_ROL_ERROR,
            message
        }
    },
    rejectRol(notificationId){
        return {
            type: REJECT_ROL,
            notificationId
        }
    },
    rejectRolResponse(response){
        return {
            type: REJECT_ROL_RESPONSE,
            response
        }
    },
    rejectRolError(message){
        return {
            type: REJECT_ROL_ERROR,
            message
        }
    },
    search(word){
        return {
            type: SEARCH_NOTIFICATIONS,
            word
        }
    },
    searchResponse(response){
        return {
            type: SEARCH_NOTIFICATIONS_RESPONSE,
            response
        }
    },
    searchError(message){
        return {
            type: SEARCH_NOTIFICATIONS_ERROR,
            message
        }
    },
    filter(filters){
        return {
            type: FILTER_NOTIFICATIONS,
            filters
        }
    },
    filterResponse(response){
        return {
            type: FILTER_NOTIFICATIONS_RESPONSE,
            response
        }
    },
    filterError(message){
        return {
            type: FILTER_NOTIFICATIONS_ERROR,
            message
        }
    }
};
export default notificationActions;