/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import CVPagination from '../../../shared/components/pagination/CVPagination';
import { withTranslation } from 'react-i18next';
import Search from "./components/Search";
import Filter from "./components/Filter";
import ColumnFilter from "./components/ColumnFilter";
import PropTypes from 'prop-types';
import DataTable from '../../../shared/components/table/DataTable';
import AlertIcon from 'mdi-react/AlertIcon';
import cowTransparent from "../../../shared/img/cow-transparent.gif";
//import CVSelect from "./components/MultiSelect"
import { DataGrid ,GridToolbarDensitySelector, getGridStringOperators,
    GridToolbarFilterButton } from '@mui/x-data-grid';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import MagnifyIcon from "mdi-react/MagnifyIcon";
import FileDownloadOutlineIcon from "mdi-react/FileDownloadOutlineIcon";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { color } from 'highcharts';
import CVModal from "../../UI/Modals/components/CVModal";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import IconButton from '@material-ui/icons/Filter';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import Box from '@material-ui/core/Box';
import {parse_UTC_date_to_DD_MM_YYYY} from "../../../../src/util";
import {parse_UTC_date_to_YYYY_MM_DD} from "../../../../src/util";

const styles = {
    filterContainer: {
        flex: 2,
        marginRight:'15px',
        marginTop: '40px',
        backgroundColor: 'rgb(250,251,254)',
        borderRadius: 10,
        flexDirection: 'row'
    },
    tableContainer: {
        flex: 10,
        flexDirection:'row',
        overflowX:'auto'
    }
};



const CVTableEventsTransferGroups = (props) =>  {
    const [data, setData] = useState([]);
    const [heads, setHeads] = useState();

    const [docs, setDocs] = useState([])
    const [openModalDocs, setOpenModalDocs] = useState(false);
    const toggleModalDocs = () => setOpenModalDocs(!openModalDocs)

    const [animals, setAnimals] = useState([])
    const [openModalAnimals, setOpenModalAnimals] = useState(false);
    const toggleModalAnimals = () => setOpenModalAnimals(!openModalAnimals)

    
    const [sortModel, setSortModel] = React.useState([
        {
          field: 'executionDate',
          sort: 'desc',
        },
      ]);

    const columns1 = [
    
        { field: 'origen', headerName: 'Origen', width: 300 },
        { field: 'destino', headerName: 'Destino', width: 300 },
        { field: 'fecha',   headerName: 'Fecha de transferencia del animal', type: 'string', width: 260,
         valueFormatter: (v)=>{ return v.row.fecha ? parse_UTC_date_to_DD_MM_YYYY(v.row.fecha) : '--' }
       },
        { field: 'executionDate', options: { sortDirection: 'desc' }, headerName: 'Fecha de transferencia del token-vaca', type: 'date', width: 290, 
         valueFormatter: (v)=>{ return v.row.executionDate ? parse_UTC_date_to_DD_MM_YYYY(v.row.executionDate) : '--' }
    },
        { field: 'animalsAffected', headerName: 'Cantidad Animales', width: 170 },
        { field: 'documents',
          headerName: 'Acciones',
          filterable: false,
          sortable: false,
          width: 100,
          renderCell: (cellValues) =>{
              return(
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <MagnifyIcon className={"button"} style={{marginLeft: "10px"}}
                             onClick={() =>  {setAnimals(cellValues.row.animals)
                                              toggleModalAnimals() }}/>
                    <DescriptionOutlinedIcon className={"button"} style={{marginLeft: "10px"}}
                             onClick={() => { setDocs(cellValues.row.documents)
                                            toggleModalDocs() }}/>
                </div>
              )
          }
        },
      ];
      
    const muiTheme = createTheme({
        palette: {
            type: 'light'
          },
          typography: {
            fontSize: 12
          },
    });
    

    const parseEndpointData = () =>{
        let newValues = []
        let data_sort = props.data.sort( function (a,b){ 
            return a.executionDate-b.executionDate}).reverse()
        for (let d of data_sort){
            newValues.push({id: d._id,
                            animalsAffected:d.animalsAffected,
                            origen: d.fromFacilityName,
                            destino: d.toFacilityName,
                            fecha: d.transferDate ,
                            documents: d.attachedFiles,
                            animals: d.animals,
                            executionDate: d.executionDate 
                        })
        
        }
        setData(newValues)
    }

    useEffect(() => {
        if(props.data) {
            parseEndpointData()
        };
    }, [props.data]);


    const renderModalDocBody = () =>  (
        <>
            { docs.length===0 ? 
                <div>La transferencia no tiene documentos cargados</div>:
                <div style={{maxHeight:'35rem',overflowY:'auto'}}>
                    {   
                        docs.map( (doc) =>(
                            <div>
                                <a style={{ color:'gray', padding:'0.5rem'}} href={doc.storage.fileUrl}>
                                    <FileDownloadOutlineIcon/> {doc.docTitle}
                                </a>
                                <hr/>
                            </div>
                        ))

                    }
                </div>
            }
            <div style={{display:'flex', marginTop:'2rem', justifyContent:'flex-end'}}>
                <Button color="primary" size='sm' outline onClick={toggleModalDocs}>Volver</Button>
            </div>
            </>
);



const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const exportToCSV = () => {
    let  animals_parsed = animals.map((animal)=> { return ( {'ID PRIMARIO CARAVANA': animal.earId} ) })
    const ws = XLSX.utils.json_to_sheet(animals_parsed);
    const wb = { Sheets: { 'animales': ws }, SheetNames: ['animales'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataxls = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(dataxls, "animales_transferidos.xlsx");
}

const renderModalAnimalBody = () => (
        <>
            { animals.length===0 ? 
                <div>La transferencia no tiene animales incluídos</div>:
                <>
                <h5>Listado de animales incluídos en la transferencia</h5>
                <div  style={{maxHeight:'35rem', borderBottom: '1px solid #ccc', overflowY:'auto'}}>
                    {   
                        animals.map( (animal) =>(
                            <div style={{ display:'inline-block', borderRadius: '1rem', backgroundColor:'lightgray', margin: '0.5rem', padding:'0.5rem'}}>
                            {animal.earId}
                            </div>
                    ))

                    }
                </div>
            
                <div style={{display:'flex', marginTop:'2rem',marginBottom:'3rem', justifyContent:'center'}}>
                    <Button color="primary" size='sm' onClick={(e) => exportToCSV()}>Exportar caravanas</Button>
                </div>
            </>   
            }
            <div style={{display:'flex', marginTop:'2rem', justifyContent:'flex-end'}}>
                <Button color="primary" size='sm' outline onClick={toggleModalAnimals}>Volver</Button>
            </div>
            </>
);


const [searchText, setSearchText] = React.useState('');

const requestSearch = (searchValue) => {
    parseEndpointData()
    setSearchText(searchValue);
    if (searchValue){
        
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = data.filter((row) => {

            let value = false
            for(let animal of row.animals){
                if (searchRegex.test(animal.earId)){
                    value=true
                    break
                }
            }
            return value
        });
        setData(filteredRows);
    }
  };

  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }



    useEffect(() => {
        let headsAux = [];
        props.heads.forEach(head => {
            headsAux.push({...head, visible: true})
        });
        setHeads(headsAux);
    }, [props.heads]);

    const setColumnVisibility = (columnId, visible) => {
        let headsAux = [...heads];
        headsAux.forEach(head => {
            if(head.key === columnId) head.visible = visible
        });
        setHeads(headsAux);
    };

    const setAllColumnVisibility = (visibility) => {
        let columnsVisibility = {};
        if(heads) {
            heads.forEach(head => {
                columnsVisibility[`${head.key}`] = visibility
            });
        }
        return columnsVisibility;
    };



    const { CVSelect, SubtitleComponent, hideFilters, hidePagination } = props;

    return (
        <>
        <Col md={12} lg={12}>
            <Card>
                <CardBody className="cv__table">

                {   props.title ?
            <div>
                <div className="card__title">
                    <div className="cv-table-title">
                        <h5 className="bold-text">{props.title}</h5>
                        <div style={{display: 'flex'}}>
                            {props.showSearchInput && <Search search={props.onSearch}/>}
                            {
                                props.showColumnFilter && heads &&
                                <ColumnFilter columns={heads} setColumnVisibility={setColumnVisibility}
                                              initialValues={setAllColumnVisibility(true)}
                                />
                            }
                        </div>
                    </div>
                </div>
                {
                    SubtitleComponent ?
                        <div className="subtitle__wrapper" style={{ marginTop:"-30px"}}>
                          <div className="subtitle__left">
                            {CVSelect? <div><CVSelect /></div>: null}
                          </div>
                          <div className="subtitle__right">
                            <SubtitleComponent/>
                          </div>
                        </div>
                        : null
                }
            </div>:
            null
        }
                    {
                        props.pending ?
                            <div className="loading">
                                <img alt="cow" src={cowTransparent}/>
                            </div>:
                                <div>
                                    <div style={{display:'flex', flexDirection:'row', alignItems:'flex-start'}}>
                                    <div style={{margin:'30px', marginTop:"-30px", width:'100%', height:'500px', fontSize:'12px'}}>
                                            <MuiThemeProvider theme={muiTheme}>
                                                <DataGrid
                                                    //sortModel={sortModel}
                                                    //onSortModelChange={(model) => setSortModel(model)}                                                    
                                                    sortingOrder={['desc', 'asc']}
                                                    localeText = {{  noRowsLabel: 'Sin datos',  }}
                                                    components={{ Toolbar: QuickSearchToolbar }}
                                                    rows={data}
                                                    columns={columns1}
                                                    pageSize={10}
                                                    rowsPerPageOptions={[5]}
                                                    rowHeight={32}
                                                    checkboxSelection={false}
                                                    disableSelectionOnClick
                                                    disableColumnMenu={true}
                                                    style={{border:'none', color:'#646777', fontSize:'13px', fontFamily:'Roboto' }}
                                                    componentsProps={{
                                                        toolbar: {
                                                          value: searchText,
                                                          onChange: (event) => requestSearch(event.target.value),
                                                          clearSearch: () => {parseEndpointData();setSearchText('')},
                                                        },
                                                      }}
                                                />
                                            </MuiThemeProvider>
                                        </div>
                                    </div>
                                </div>
                    }
                </CardBody>
            </Card>
        </Col>
        

        <CVModal
            title={'Documentación de la transferencia'}
            body={() => renderModalDocBody()}
            color="primary"
            header
            hideButtons={true}
            isOpen={openModalDocs}
            toggle={toggleModalDocs}

        />

        <CVModal
            title={'Animales incluídos en  la transferencia'}
            body={() => renderModalAnimalBody()}
            color="primary"
            header
            hideButtons={true}
            isOpen={openModalAnimals}
            toggle={toggleModalAnimals}
        />


        </> 
    );
};

CVTableEventsTransferGroups.propTypes = {
    data: PropTypes.array,
    //  Required for pagination
    getPage: PropTypes.func,
    total: PropTypes.number, // Total items
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    pageSize: PropTypes.number,
    heads: PropTypes.array.isRequired,
    title: PropTypes.string,
    SubtitleComponent: PropTypes.func,
    CVSelect: PropTypes.func,
    hideFilters: PropTypes.bool,
    hidePagination: PropTypes.bool,
    showSearchInput: PropTypes.bool,
    onSearch: PropTypes.func,
    showColumnFilter: PropTypes.bool,
    onFilter: PropTypes.func,
    filters: PropTypes.array,
    widthPercentage: PropTypes.number,
    noDataLabel: PropTypes.string.isRequired,
    pending: PropTypes.bool
};


function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
  
  function QuickSearchToolbar(props) {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
        }}
      >
        <div>
           {/* <GridToolbarFilterButton /> */}
          {/*<GridToolbarDensitySelector /> */}
        </div>
        <TextField
          variant="outlined"
          value={props.value}
          onChange={props.onChange}
          placeholder="Buscar caravana…"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" style={{marginRight:'10px'}}/>,
            endAdornment: (
              <ClearIcon 
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
              />
            ),
          }}
          sx={{
            width: {
              xs: 1,
              sm: 'auto',
            },
            m: (theme) => theme.spacing(1, 0.5, 1.5),
            '& .MuiSvgIcon-root': {
              mr: 0.5,
            },
            '& .MuiInput-underline:before': {
              borderBottom: 1,
              borderColor: 'divider',
            },
          }}
        />
      </Box>
    );
  }
  
  QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  };


export default withTranslation('common')(CVTableEventsTransferGroups)
