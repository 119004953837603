import React from 'react';
import {Field} from "redux-form";
import renderField from "./Field";
import {formValidators} from "../../../util";
import {withTranslation} from "react-i18next";

const CoordinatesInput = (props) => {
    const { t, editable, name } = props;

    return (
        <div className="form__form-group">
            <span className="form__form-group-label">{t('cv-forms.productive_establishment.establishment_coordinates')}</span>
            <div className="form__form-group-field row_direction">
                <div>
                    <span className="secondary__label" style={{marginLeft: '0'}}>{t('cv-forms.productive_establishment.latitude')}</span>
                    <Field
                        name={name ? `${name}.lat` : "coordinates.lat"}
                        component={renderField}
                        validate={[formValidators.validateRequired, formValidators.validateLatCoordinate]}
                        disabled={!editable}
                        placeholder={t('cv-forms.productive_establishment.latitudePlaceholder')}
                    />
                </div>
                <div style={{marginLeft: '15px'}}>
                    <span className="secondary__label" style={{marginLeft: '0'}}>{t('cv-forms.productive_establishment.longitude')}</span>
                    <Field
                        name={name ? `${name}.long` : "coordinates.long"}
                        component={renderField}
                        validate={[formValidators.validateRequired, formValidators.validateLonCoordinate]}
                        disabled={!editable}
                        placeholder={t('cv-forms.productive_establishment.longitudePlaceholder')}
                    />
                </div>
            </div>
        </div>
    )
};

export default withTranslation('common')(CoordinatesInput);