import React, {useEffect, useState} from 'react'
import MagnifyIcon from "mdi-react/MagnifyIcon";
import CVTableEvents from "../Tables/CVTable/CVTableEvents";
import { withTranslation } from "react-i18next";
import { Card, CardBody } from 'reactstrap';
import qrGenerationActions from '../../redux/actions/qrGenerationActions'
import eventActions from '../../redux/actions/eventActions';
import { connect } from 'react-redux';
import FileUploadOutlineIcon from "mdi-react/FileUploadOutlineIcon";
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import FileTableOutlineIcon from "mdi-react/FileTableOutlineIcon";
import FileDownloadOutlineIcon from "mdi-react/FileDownloadOutlineIcon";
import FileUploadModal from "../../shared/components/cv/FileUploadModal";
import { withRouter } from 'react-router-dom';
import { EVENT_TYPES } from '../../constants/index';
import {parse_UTC_date_to_DD_MM_YYYY} from "../../util";
import settings from '../../util/settings';
import {Tooltip} from "react-tippy";
import QrcodeIcon from "mdi-react/QrcodeIcon";
import CVBasicModal from "../../shared/components/cv/CVBasicModal";
import '../../scss/component/qrcode.scss';
import setting from '../../util/settings';
import {ESTABLISHMENT_TYPES }from '../../constants';
import {Link} from 'react-router-dom';

const QrGenerationTable = (props) => {
    const { t, events, total, excelBuffer, getExcel, page, getPage, totalPages, pageSize, establishmentId,establishmentType } = props;
    const [fileUploadModalIsOpen, setFileUploadModalOpen] = useState(false);
    const [excelDownload, setExcelDownload] = useState(false);
    const templatesUrl = settings.templatesUrl;


    useEffect(() => {
        getEstablishmentPage(establishmentId,1, 10)
    }, []);

    useEffect(() => {    
        getPage(establishmentId, 1, 10)
    }, [establishmentId]);


    const getEstablishmentPage = (establishmentId, page, pageSize) => {
        getPage(establishmentId, page, pageSize)
    };

    const renderValueDefault = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value ? value:'-'}
            </div>
        )
    };

    const renderDate = ({value}) => {
        return (
          <div style={{display: 'flex', justifyContent: 'center'}}>
              {value ?
                parse_UTC_date_to_DD_MM_YYYY(value):
                "-"
              }
          </div>
        )
    };



    const renderIconsForNewEvent = () => (
        <div>

            <PlusCircleOutlineIcon onClick={() => props.history.push('/qrGeneration/qrGenerationNew')}/>
        </div>
    );

    //  QR ------------------------------------------------------
    const QRCode = require('qrcode.react')
    const [qrModalIsOpen, toggleQrModal] = useState(false);
    const [qrId, setQrId] = useState();
    const [qrUrl,setQrUrl]= useState(null);

    const openQrModal=(qrId) => {
        setQrId(qrId);
        let qr =    events.filter( e=>  e._id.toString() === qrId.toString() )
        if (qr.length>0){
         setQrUrl(qr[0].shortUrl)
         //console.log("DBG qr",qr)
        }
        toggleQrModal(!qrModalIsOpen)
    }

    const downloadQR = () => {
        let canvas = document.getElementById('generated_qr');
        let pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        let qr =    events.filter( e=>  e._id.toString() === qrId.toString() )
        if (qr.length>0){
            // let fechaStr = new Date().toLocaleString().replaceAll('/','-').replaceAll(':','-').replace(' ','_')
            // downloadLink.download = `QR_${qr[0].name}_${fechaStr}.png`;
            downloadLink.download = `${qr[0].name}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
        toggleQrModal(false);
    };

    const qrModal = () => (
        <CVBasicModal
            title={t('animals.modals.qr.title')}
            body={renderQRBody}
            isOpen={qrModalIsOpen}
            toggleModal={() => toggleQrModal(!qrModalIsOpen)}
            cancel={() => toggleQrModal(false)}
            accept={downloadQR}
            acceptText={t('animals.modals.qr.download')}
        />
    );

    const renderQRBody = () => (
        <div>
            <QRCode value={qrUrl} size={500} includeMargin id={"generated_qr"}
                    imageSettings={{src: require(`../../shared/img/carnes-validadas-logo-qr-v2.png`), height: 84, width: 120}}
                    level={'H'}
            />            
            <br/>
            <a target='_BLANK' style={{fontSize:"16px",fontWeight:"bold"}} 
            href={qrUrl}>VER</a>            
            <br/>
            <br/>
        </div>

    );

    //  ------------------------------------------------------

    const renderButtons = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <MagnifyIcon className={"button"} style={{marginLeft: "10px"}} onClick={() => {
                    props.history.push('/qrGeneration/qrGenerationDetails', {qrId: value})
                }}/>
                <QrcodeIcon className={"button"} style={{marginLeft: "10px"}} onClick={() => openQrModal(value)}/>
            </div>
        )
    };

    const heads = [
        {
            key: 'name',
            name: t('qrGeneration.name'),
            percentage: 20,
            minWidth: 150,
            formatter: renderValueDefault
        },
        {
            key: 'templateName',
            name: t('qrGeneration.templateName'),
            percentage: 20,
            minWidth: 210,
            formatter: renderValueDefault
        },
        {
            key: 'lotName',
            name: t('qrGeneration.lot'),
            percentage: 20,
            minWidth: 210,
            formatter: renderValueDefault
        },
        {
            key: 'slaughterDate',
            name: t('qrGeneration.slaughterDate'),
            percentage: 20,
            minWidth: 210,
            formatter: renderDate
        },
        // {
        //     key: 'shortUrl',
        //     name: t('qrGeneration.shortLink'),
        //     percentage: 20,
        //     minWidth: 210,
        //     formatter: renderValueDefault
        // },
        {
            key: '_id',
            name: t('qrGeneration.actions'),
            percentage: 14,
            minWidth: 75,
            formatter: renderButtons,
            getRowMetaData: (row) => row
        }
 
    ];


    

    return(
        <div>
        { ( (establishmentType === ESTABLISHMENT_TYPES.commercial) || (establishmentType === ESTABLISHMENT_TYPES.slaughterhouse)) && establishmentType!==null ? 
         <div>
                {qrModal()}
            <CVTableEvents
                getPage={getEstablishmentPage}
                data={events}
                total={total}
                currentPage={page}
                totalPages={totalPages}
                pageSize={pageSize}
                heads={heads}
                hideFilters
                title={t('qrGeneration.title')}
                SubtitleComponent={renderIconsForNewEvent}
                noDataLabel={t('cv-forms.qr.no_data_label')}
                establishmentId={establishmentId}
            />
            <FileUploadModal title={t('events.modals.massive_upload.title')} isOpen={fileUploadModalIsOpen}
                             toggleModal={() => setFileUploadModalOpen(!fileUploadModalIsOpen)} acceptedFormats={".xls,.xlsx"}
                             onSubmit={(file) => {
                                 props.uploadFileForMassiveLoad(file.files, props.establishmentId, undefined);
                                 props.history.push('/qrGeneration/qrGenerationNew');
                             }}/>
        </div>
        :
        <Card>
                <CardBody className="cv__table">
                <div>
                        <div className="card__title">
                            <div className="cv-table-title">
                                <h5 className="bold-text">{t('qrGeneration.title')}</h5>
                            </div>
                            <div style={{display: 'flex', flexDirection:'column', paddingTop:'2rem'}}>
                                <h5>Lo sentimos, esta sección no está disponible para es tipo de establecimiento.</h5>
                                <p> Esta sección permite crear un código Qr y asociarlo a un lote faenado. De esa manera al cliente final le llega una porción de carne con toda la info de trazabilidad asociada a las vacas que formaban ese lote. Copado, no?</p> 
                                <p> El último paso para poder lograr esto es asociar el Qr a un lote faenado, y en este tipo de establecimiento no tiene permitido la faena. Igual podés crear y ajustar Qrs Identeco. Lo podés hacer <Link to="/templates/microsite">acá</Link></p> 
                            </div>

                            </div>
                        </div>
 
                    
                </CardBody>
        </Card>
        }
        </div>

    );
};

const mapStateToProps = (state) => ({
    events: state.qrGeneration.QR ? state.qrGeneration.QR.qrsGenerated : [],
    total: state.qrGeneration.QR ?
        state.qrGeneration.QR.total : 0,
    page: state.qrGeneration.QR ?
        state.qrGeneration.QR.page : 1,
    totalPages: state.qrGeneration.QR ?
        state.qrGeneration.QR.totalPages : 0 ,
    pageSize: state.qrGeneration.QR ?
        state.qrGeneration.QR.pageSize : 0,
    establishmentId: state.user.selectedEstablishment != undefined ? state.user.selectedEstablishment._id : null,
    establishmentType: state.user.selectedEstablishment != undefined ? state.user.selectedEstablishment.type : null,
});

const mapDispatchToProps = (dispatch) => ({
    getPage: (establishmentId, page, pageSize) => dispatch(qrGenerationActions.getQrsGeneratedByFacilityPage(establishmentId, page, pageSize,'QR')), 

});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(QrGenerationTable)))