import React, { useEffect } from 'react';
import {Field} from "redux-form";
import {withTranslation} from "react-i18next";
import renderSelectField, {SelectField} from "../form/Select";
import { connect } from "react-redux";
import generalDataActions from '../../../redux/actions/generalDataActions';
import constantActions from '../../../redux/actions/constantActions';
import { formValidators } from "../../../util";



const AddressInput = (props) => {
    const { t, addressName, vertical, renderInput, showLabels, availableCountries, editable, classname, title,
        showAutoComplete, onAutocompleteChange, autocompleteValue, addressesToAutocomplete, country, availableStates } = props;

    useEffect(() => {
        props.getAvailableCountries();
        props.getAvailableStates();
    }, []);

    return (
        <div>
            {title &&  <span className="form__form-group-label">{title}</span>}
            {/* Address Autocomplete select */}
            {
                (showAutoComplete && editable) &&
                <div style={{marginBottom: '40px'}}>
                    <SelectField
                        onChange={onAutocompleteChange}
                        value={autocompleteValue}
                        placeholder={t('cv-forms.productive_establishment.use_the_same_address_as')}
                        name={'copy_same_address'}
                        options={[
                            ...addressesToAutocomplete.names.map(addressName => ({
                                value: addressName,
                                label: t(`cv-forms.productive_establishment.autocomplete_address.${addressName}`)
                            }))
                        ]}
                    />
                </div>
            }
            <div style={{display: 'flex', flex: 1, flexWrap: 'wrap'}}>
                <div className={`form__form-group ${vertical ? 'address_input vertical' :'address_input horizontal'} ${classname ? classname : ''}`} style={{flexDirection:'column'}}>
                    {showLabels &&  <span className="form__form-group-label">{t('cv-inputs.address.streetName')}</span>}
                    <div className="form__form-group-field">
                        <Field
                            name={`${addressName}.streetName`}
                            component={renderInput ? renderInput : "input"}
                            type="text"
                            disabled={!editable}
                            validate={formValidators.validateRequired}
                            placeholder={t('cv-inputs.address.streetName')}
                        />
                    </div>
                </div>
                <div className={`form__form-group ${vertical ? 'address_input vertical' :'address_input horizontal'} ${classname ? classname : ''}`} style={{flexDirection:'column'}}>
                    {showLabels &&  <span className="form__form-group-label">{t('cv-inputs.address.streetNumber')}</span>}
                    <div className="form__form-group-field">
                        <Field
                            name={`${addressName}.streetNumber`}
                            component={renderInput ? renderInput : "input"}
                            type="number"
                            disabled={!editable}
                            validate={formValidators.validateRequired}
                            placeholder={t('cv-inputs.address.streetNumber')}
                        />
                    </div>
                </div>
                <div className={`form__form-group ${vertical ? 'address_input vertical' :'address_input horizontal'} ${classname ? classname : ''}`} style={{flexDirection:'column'}}>
                    {showLabels &&  <span className="form__form-group-label">{t('cv-inputs.address.apartment')}</span>}
                    <div className="form__form-group-field">
                        <Field
                            name={`${addressName}.apartment`}
                            component={renderInput ? renderInput : "input"}
                            type="text"
                            disabled={!editable}
                            //validate={formValidators.validateRequired}
                            placeholder={t('cv-inputs.address.apartment')}
                        />
                    </div>
                </div>
                <div className={`form__form-group ${vertical ? 'address_input vertical' :'address_input horizontal'} ${classname ? classname : ''}`} style={{flexDirection:'column'}}>
                    {showLabels &&  <span className="form__form-group-label">{t('cv-inputs.address.postalCode')}</span>}
                    <div className="form__form-group-field">
                        <Field
                            name={`${addressName}.postalCode`}
                            component={renderInput ? renderInput : "input"}
                            type="text"
                            disabled={!editable}
                            validate={formValidators.validateRequired}
                            placeholder={t('cv-inputs.address.postalCode')}
                        />
                    </div>
                </div>
                <div className={`form__form-group ${vertical ? 'address_input vertical' :'address_input horizontal'} ${classname ? classname : ''}`} style={{flexDirection:'column'}}>
                    {showLabels &&  <span className="form__form-group-label">{t('cv-inputs.address.city')}</span>}
                    <div className="form__form-group-field">
                        <Field
                            name={`${addressName}.city`}
                            component={renderInput ? renderInput : "input"}
                            type="text"
                            disabled={!editable}
                            validate={formValidators.validateRequired}
                            placeholder={t('cv-inputs.address.city')}
                        />
                    </div>
                </div>
                <div className={`form__form-group ${vertical ? 'address_input vertical' :'address_input horizontal'} ${classname ? classname : ''}`} style={{flexDirection:'column'}}>
                    {showLabels &&  <span className="form__form-group-label">{t('cv-inputs.address.state')}</span>}
                    <div className="form__form-group-field">
                                     
                    <Field
                            name={`${addressName}.state`}
                            disabled={!editable}
                            component={renderSelectField}
                            validate={formValidators.validateRequired}
                            type="text"
                            options={
                               availableStates.states.map(s => ({
                                            value: s,
                                            label: t(`states.${s}`)
                                }))
                            }
                            placeholder={t('cv-inputs.address.state')}
                    />
                    </div>
                </div>
                <div className={`form__form-group ${vertical ? 'address_input vertical' :'address_input horizontal'} ${classname ? classname : ''}`} style={{flexDirection:'column'}}>
                    {showLabels &&  <span className="form__form-group-label">{t('cv-inputs.address.country')}</span>}
                    <div className="form__form-group-field">
                        <Field
                          name={`${addressName}.country`}
                          component={renderSelectField}
                          type="text"
                          placeholder={t('cv-inputs.address.country')}
                          disabled={!editable}
                          options={
                              availableCountries.map(country => ({
                                  value: country.value,
                                  label: t(`countries.${country.value}`)
                              }))
                          }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

 
const mapStateToProps = (state) => ({
    availableCountries: state.generalData.availableCountries,
    availableStates : state.constant.availableStates
});

const mapDispatchToProps = (dispatch) => ({
    getAvailableCountries: () => (dispatch(generalDataActions.getAvailableCountries())),
    getAvailableStates: () =>  (dispatch(constantActions.getAvailableStates()))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(AddressInput))