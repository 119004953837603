import React, { useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import QrGenerationForm from "./QrGenerationForm"
import { connect } from 'react-redux';
import actions from '../../redux/actions';
import {useAlert} from "react-alert";
import { withTranslation } from "react-i18next";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import {useState} from 'react';

const QrGenerationNew = (props) => {
    const alert = useAlert();
    const [submittable, setSubmittable] = useState(true);
    const [pending, setPending] = useState(false)
    const addQrGeneration = (values) => {
        setPending(true)
        props.submitQrGeneration(values, props.facilityId, (retValue) => {
            console.log("DBG retValue",retValue)
            alert.success(props.t('events.qr.qrAdded'));
            props.history.push('/qrGeneration/table');
        }, (errorMessage) => {
            ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
            setPending(false)
        });
    };


    return (
        <Container>
         <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
            <Row>
                { props.facilityId!== undefined &&
                <QrGenerationForm
                  onSubmit={addQrGeneration}
                  facilityId={props.facilityId}
                  isNew={true}
                  pending={pending}
                  submittable={submittable}
                  editable/>
                }
            </Row>
        </Container>
    )
};

const mapStateToProps = (state) => ({
    addCowPending: state.ui.addCowPending, // todo change this!
    facilityId: state.user.selectedEstablishment._id
});

const mapDispatchToProps = (dispatch) => ({
    submitQrGeneration: (values, facilityId, successCallback, errorCallback) =>
        dispatch(actions.qrGeneration.submitQrGeneration(values, facilityId, successCallback, errorCallback)) 
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(QrGenerationNew));
