import React, {useEffect, useState} from 'react';
import {Col, Card, CardBody, Button, ButtonToolbar} from 'reactstrap';
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Field, reduxForm} from 'redux-form';
import {connect} from "react-redux";
import actions from "../../../../redux/actions";
import MaskedInput from "react-text-mask";
import {CheckBoxField} from '../../../../shared/components/form/CheckBox';
import i18n from '../../../../translations/config/i18n';
import {useAlert} from "react-alert";
import CVButton from "../../../../shared/components/cv/CVButton";
import {formValidators, formMasks, subtract_x_years_to_date} from '../../../../util/index';
import { withRouter } from 'react-router-dom';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import {renderDateInputField} from "../../../../shared/components/cv/CVDatePicker";

const renderField = ({input, disabled, placeholder, type, meta: {touched, error, warning}, mask}) => (
  <div style={{width: '100%'}}>
    {!mask && <input style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type}/>}
    {mask && <MaskedInput style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type} mask={mask}/>}
    <div className="input-error-container">
      {touched && ((error && <span className="error-message">{i18n.t(`common:errors.${error}`)}</span>) || (warning &&
        <span className="error-message">{warning}</span>))}
    </div>
  </div>
);

const validateDateRequired = formValidators.validateDateBetweenRequired(
  subtract_x_years_to_date(new Date(), 100), // 100 years ago date
  new Date(), // today date
);

const SlaughterEventForm = (props) => {
  SlaughterEventForm.propTypes = {
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  const {t, reset, handleSubmit, onSubmit, establishmentProductiveSystems, editable,
    hideTitle, allAvailableProductiveSystems} = props;

  // const [productiveSystemSelected, setProductiveSystemSelected] = useState({});

  const alert = useAlert();

  const showErrors = (invalid) => {
    if(invalid) ToastsStore.error(t('common.general_invalid_form'), 10000)
  }

  const submitForm = (values) => {
    values.facilityId = props.selectedEstablishment._id;
    onSubmit({...values});
  };

  return (
    <Col md={12} lg={12}>
      <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
      <Card>
        <CardBody>
          <div className="card__title">
            {!hideTitle && <h5 className="bold-text">{'Evento de Faena'}</h5>}
          </div>
          <form className="form" onSubmit={handleSubmit(submitForm)}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('cv-forms.events.slaughter_event.caravan_id')}</span>
              <div className="form__form-group-field">
                <Field
                  name="caravan_id"
                  component={renderField}
                  validate={[formValidators.validateRequired, formValidators.validateCaravanIdRequired]}
                  type="text"
                  placeholder={t('cv-forms.events.slaughter_event.caravan_id')}
                  mask={formMasks.caravanIdMask}
                  normalize={(value) => value.toUpperCase()}
                  disabled={!editable}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('cv-forms.events.slaughter_event.date')}</span>
              <div className="form__form-group-field">
                <Field
                  name="date"
                  component={renderDateInputField}
                  validate={[validateDateRequired]}
                  disabled={!editable}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('cv-forms.events.common_fields.comments')}</span>
              <div className="form__form-group-field">
                <Field
                  name="comments"
                  component={renderField}
                  disabled={!editable}
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              {
                editable &&
                <CVButton
                  color="primary"
                  type="submit"
                  onClick={()=> showErrors(props.invalid)}
                  pending={props.pending}>
                  {t('common.accept')}
                </CVButton>
              }
              {
                editable &&
                <Button type="button" onClick={() => props.history.push('/animals/table')}>
                  {t('common.cancel')}
                </Button>
              }

            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>)
};

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues || {},
  selectedEstablishment: state.user.selectedEstablishment,
});

const mapDispatchToProps = (dispatch) => ({
  getAvailableProductiveSystems: () => dispatch(actions.constant.getAvailableProductiveSystems())
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'slaughter-event-form',
  touchOnChange: true // this is set because if not the 'touched' props on DateInputField was always false
})(withTranslation('common')(withRouter(SlaughterEventForm))));