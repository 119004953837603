import CloseCircleOutlineIcon from "mdi-react/CloseCircleOutlineIcon";
import React from "react";
import { transitions, positions } from "react-alert";

const defaultAlertStyle = {
    color: 'white',
    padding: '10px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center'
};

const closeIconStyle = {
    marginRight: '10px',
    marginLeft: '10px',
    color: 'white'
};

//TODO set the offset dynamically. It show be adobe the top bar.
export const options = {
    timeout: 5000,
    transition: transitions.FADE,
    offset: '70px',
    position: positions.TOP_CENTER,
    containerStyle: {
        marginLeft: '120px' // because the width of the side bar is 240 px
    }
};

export const CVAlertTemplate = ({ message, options, style, close }) => {
    switch (options.type) {
        case 'success':
            defaultAlertStyle.backgroundColor = 'rgb(67, 222, 168)';
            break;
        case 'error':
            defaultAlertStyle.backgroundColor = 'rgb(251, 44, 79)';
            break;
        case 'info':
            defaultAlertStyle.backgroundColor = 'rgb(95, 171, 252)';
            break;
        default:
            defaultAlertStyle.backgroundColor = 'rgb(112, 187, 253)';
            break;
    }

    return (
        <div style={{...defaultAlertStyle, ...style}}>
            <div>{message}</div>
            <div style={closeIconStyle}>
                <CloseCircleOutlineIcon color='white' onClick={close}/>
            </div>
        </div>
    )
};