import React, {useEffect} from 'react';
import {Field} from "redux-form";
import {formValidators} from "../../../../util";
import { withTranslation } from "react-i18next";
import renderField from '../../../../shared/components/cv/Field';
import renderSelectField from "../../../../shared/components/form/Select";
import {renderCVPhotoInputField} from "../../../../shared/components/cv/CVFileUpload";
import { connect } from "react-redux";
import actions from '../../../../redux/actions/index'

const RucaInput = (props) => {

    const { classname, t, showImages, editable, renderImage, rucaActivities, establishmentType } = props;

    useEffect(() => {
        props.getAvailableRucaActivities();
    }, []);

    const establishmentTypeTitle = () => {
        if(establishmentType != "importer") return t('cv-inputs.ruca.title')
        return ''
    }

    const establishmentTypeEnrollment = () => {
        if(establishmentType != "importer") return t('cv-inputs.ruca.enrollement')
        return t('cv-inputs.fiscal_id.enrollement')
    }

    const establishmentTypeImage= () => {
        if(establishmentType != "importer") return t('cv-inputs.ruca.image')
        return t('cv-inputs.fiscal_id.image')
    }

    return (
        <div style={{width: '100%'}}>
            <h5 className="bold-text">{establishmentTypeTitle()}</h5>
            <div className={`form__form-group ${classname ? classname : ''}`}>
                <span className="form__form-group-label">{establishmentTypeEnrollment()}</span>
                <div className="form__form-group-field">
                    <Field
                        name="ruca.id"
                        component={renderField}
                        type="text"
                        disabled={!editable}
                        validate={formValidators.validateRequired}
                        placeholder={establishmentTypeEnrollment()}
                    />
                </div>
            </div>
            <div className={`form__form-group ${classname ? classname : ''}`}>
                <span className="form__form-group-label">{establishmentTypeImage()}</span>
                <div className="form__form-group-field with_image">
                    {
                        (!showImages || editable) &&
                        <Field
                            name="ruca.image"
                            component={renderCVPhotoInputField}
                            validate={formValidators.validateRequired}
                            disabled={!editable}
                            hideFileName={true}
                        />
                    }
                    {renderImage('rucaImage')}
                </div>
            </div>
            {/* todo this select must be with multiple options */}
            <div className={`form__form-group ${classname ? classname : ''}`}>
                <span className="form__form-group-label">{t('cv-inputs.ruca.activities')}</span>
                <div className="form__form-group-field">
                    {/* todo change the activities options */}
                    <Field
                        name="ruca.activities"
                        component={renderSelectField}
                        type="text"
                        placeholder={t('cv-inputs.ruca.activities')}
                        validate={formValidators.validateRequired}
                        disabled={!editable}
                        options={[
                            ...rucaActivities.map(activity => ({
                                value: activity.code.toString(),
                                label: activity.activity
                            }))
                        ]}
                        multiple
                        closeMenuOnSelect={false}
                    />
                </div>
            </div>
        </div>
    )
};

const mapDispatchToProps = (dispatch) => ({
    getAvailableRucaActivities: () => dispatch(actions.constant.getAvailableRucaActivities())
});

const mapStateToProps = (state) => ({
    rucaActivities: state.constant.availableRucaActivities
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(RucaInput));