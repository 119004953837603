import React, { useState, useEffect } from 'react';
import RolesTable from './components/RolesTable';
import { connect } from "react-redux";
import actions from '../../redux/actions/index';

const Roles = (props) => {
    const [roles, setRoles] = useState();

    const { currentPage, totalPages, total, pageSize } = props;

    useEffect(() => {
        props.getPage(1,10)
    }, []);

    useEffect(() => {
        setRoles(props.roles)
    }, [props.roles]);

    return (
        <div>
            <RolesTable roles={roles} currentPage={currentPage} totalPages={totalPages} totalRoles={total}
                        pageSize={pageSize} getPage={props.getPage}/>
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        roles: state.admin.roles.roles,
        currentPage: state.admin.roles.page,
        total: state.admin.roles.total,
        totalPages: state.admin.roles.totalPages,
        pageSize: state.admin.roles.pageSize
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPage: (pageNumber, pageSize) => dispatch(actions.admin.getRolesPage(pageNumber, pageSize))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);