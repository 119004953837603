import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux';
import GeneralDataList from './components/GeneralDataList'
import adminActions from '../../redux/actions/adminActions';

const GeneralData = (props) => {
    const [titles, setTitles] = useState();

    const { currentPage, total, totalPages, pageSize } = props;

    useEffect(() => {
        props.getPage(1, 10)
    }, []);

    useEffect(() => {
        setTitles(props.generalDataTitles)
    }, [props.generalDataTitles]);

    return(
        <div>
            <GeneralDataList data={titles} currentPage={currentPage} total={total} totalPages={totalPages}
                             pageSize={pageSize} getPage={props.getPage} addGeneralData={props.addGeneralData}
            />
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        generalDataTitles: state.admin.generalData.titles.titles,
        currentPage: state.admin.generalData.titles.page,
        total: state.admin.generalData.titles.total,
        totalPages: state.admin.generalData.titles.totalPages,
        pageSize: state.admin.generalData.titles.pageSize
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPage: (pageNumber, pageSize) => dispatch(adminActions.getGeneralDataTitlesPage(pageNumber, pageSize)),
        addGeneralData: (generalData) => dispatch(adminActions.addGeneralData(generalData))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralData)