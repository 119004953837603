import React, {useEffect, useState} from 'react';
import {Col, Row, Card, CardBody, Button, ButtonToolbar} from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, CardTitle, CardText } from 'reactstrap';
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {change, Field, reduxForm, formValueSelector} from 'redux-form';
import {renderCVFileInputField, renderCVPhotoInputField} from '../../../shared/components/cv/CVFileUpload';
import renderSelectField from "../../../shared/components/form/Select";
import {connect} from "react-redux";
import GeneralDataActions from '../../../redux/actions/generalDataActions';
import actions from "../../../redux/actions";
import MaskedInput from "react-text-mask";
import {CheckBoxField} from '../../../shared/components/form/CheckBox';
import i18n from '../../../translations/config/i18n';
import {useAlert} from "react-alert";
import CVButton from "../../../shared/components/cv/CVButton";
import { formValidators, subtract_x_years_to_date, formMasks } from '../../../util/index';
import { renderDateInputField } from '../../../shared/components/cv/CVDatePicker';
import { withRouter } from 'react-router-dom';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import {SRLWrapper} from "simple-react-lightbox";
import caravanIdLogo from '../../../shared/img/icon/caravanId.png';
import caravanIdRLogo from '../../../shared/img/icon/caravanIdR.png';
import caravanEIdLogo from '../../../shared/img/icon/caravanEId.png';
import caravanIncompleteLogo from '../../../shared/img/icon/incomplete.png';
import CVBasicModal from "../../../shared/components/cv/CVBasicModal";
import {Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import CVEmailTierModal from "../../../shared/components/cv/CVEmailTierModal";
import setting from '../../../util/settings';

//import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';
import "../../../scss/component/Select2.css"
import '../../../scss/component/cvRadioBtn.scss';
import '../../../scss/component/cvCowForm.scss';

import classnames from 'classnames';

import * as constants from '../../../constants'
import Helpi from '../../Helpi'
import SRAConnectionStatus from '../../ServicesStatus/SRAConnectionStatus';

const optionsLightbox = {
    settings: {},
    caption: {
        showCaption: false,
    },
    buttons: {
        showAutoplayButton: false,
        showNextButton: false,
        showPrevButton: false,
        showThumbnailsButton: false,
        showDownloadButton: false,
        showFullscreenButton: false,
    },
    thumbnails: {
        showThumbnails: false,
    },
    progressBar:{},    
  }

// todo delete this hard-code data when the api is ready for returning this values
const cowAvailableBreeds = [
    'ANGUS','ANGUS NEGRO','ANGUS COLORADO', 'HEREFORD', 'POLLED HERFORD', 'BRANGUS', 'BRAFORD',
    'CRUZA', 'NEGRO CARA BLANCA', 'LIMANGUS', 'HOLSTEIN', 'CHAROLAIS', 'JERSEY',
    'LIMANGUS COLORADO', 'BRANGUS NEGRO', 'BRANGUS COLORADO', 'SHORTHORN', 'NELORE',
    'BRAHMAN','CARA BLANCA', 'HOLANDO ARGENTINO','PARDO SUIZO',
    'FRISSONA','AYRSHIRE','BLACK/RED SIMMENTAL',"BLONDE D'AQUITAINE",
    'BONSMARA','CHARBRAY','CHIANINA','CRIOLLO','GALLOWAY',
    'INDUSIN','JAFARABADI','LIMOUSIN','MARCHIGIANA',
    'MEDITERRANEA','MURRAH','NORMANDO','PIEMONTESE','RETINTA','ROMAGNOLA',
    'SALERS','SANTA GERTRUDIS','SIMBRAH','SIMENTAL','TABAPUA',
    'TULI','WEST-HIGHLAND','SANTA CRUZ','GUERNSEY','BERRENDA',
    'SIMBRAFORD','SIMANGUS','SIMFORD','BELMONT RED','RAGEMAKER','RED POLL',
    'SENEPOL','STABILIZER','CORRIEDALE','COLORADA CARA BLANCA',
    'CUARTINO','FLECKVIEH','CRIBU','ÑATA',
    'CRUZA HOLANDO','CRUZA INDICA','CRUZA CONTINENTAL',
    'CRUZA BRITANICA','LIMANGUS NEGRO','MEDIA SANGRE LIMOUSIN',
    'GELBVIEH','CANCHIM','GIR','SIMBRASIL','GIROLANDO','ANELORADO','WAGYU'
];
const cowAvailableSpecies = ['Bovina'];
const cowAvailableCategories = ['Ternera', 'Vaquillona', 'Vaca', 'Vaca madre', 'Ternero', 'Novillo', 'Toro'];
const cowAvailableFireMarkPlace = [
    {
        value: 'anca',
        label: i18n.t('common:cv-forms.cow.fire_mark_place_options.anca')
    },
    {
        value: 'carretilla',
        label: i18n.t('common:cv-forms.cow.fire_mark_place_options.carretilla')
    }
];

const renderField = ({input, iconSrc, disabled, placeholder, type, meta: {touched, error, warning}, mask, highlight}) => (
    <div style={{width: '100%'}}>        
        <nobr>{iconSrc && <img style={{height:"30px", width: "30px"}} src={iconSrc}/>}
            {!mask && <input style={{backgroundColor: highlight?'lightyellow':'white',fontWeight:highlight?'bold':'normal'}} disabled={disabled} {...input} placeholder={placeholder} type={type}/>}
            {mask && <MaskedInput style={{backgroundColor: highlight?'lightyellow':'white',fontWeight:highlight?'bold':'normal'}} disabled={disabled} {...input} placeholder={placeholder} type={type} mask={mask}/>}
        </nobr>
        <div className="input-error-container">
            {touched && ((error && <span className="error-message">{i18n.t(`common:errors.${error}`)}</span>) || (warning &&
                <span className="error-message">{warning}</span>))}
        </div>
    </div>
);

const renderFieldWizard = ({input, iconSrc, disabled, placeholder, type, meta: {touched, error, warning}, mask}) => (
    <div style={{width: '100%'}}>        
        <nobr>{iconSrc && <img style={{height:"30px", width: "30px"}} src={iconSrc}/>}
            {!mask && <input style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type}/>}
            {mask && <MaskedInput style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type} mask={mask}/>}
        </nobr>        
    </div>
);

const detailFileFieldNoImage = (t,editable,showImages,props,fileFieldName,modelName,deleteFile) => (
    showImages && props.initialValues[modelName] ?
    <div>
        {
            typeof (props[fileFieldName]) !== 'object' || props[fileFieldName] == null ?
            <a href={props.initialValues[modelName]}>{t('cv-forms.cow.download_file')}</a>:
            null
        }

        {
            (editable) &&
            <a onClick={()=>{deleteFile(modelName)}}><button>Eliminar</button></a>
        }
    </div>:
    typeof (props[fileFieldName]) !== 'object' || props[fileFieldName] == null?
        <label>{t('cv-forms.cow.no_certificate_file')}</label>:
        <a onClick={()=>{deleteFile(modelName)}}><button>Eliminar</button></a>
);
 
// Validate the cow birthday date, the date must be between 7 years ago and now.
const validateCowBirthDate = formValidators.validateDateBetweenNotRequired(subtract_x_years_to_date(new Date(), 20), new Date());

// Validate the cow age in months, the number must be between 0 and 84 (7 years)
const validateCowAgeInMonths = formValidators.validateNumberBetweenNotRequired(0, 360);

const CowForm = (props) => {
    CowForm.propTypes = {
        t: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        SRAConnectionStatus: PropTypes.object
    };

    const naturalFood = ["Falaris", "Festuca", "Cebadilla Criolla", "Agropiro", "Lotus", "Pastizal Natural", "Campo Natural", "Rastrojo de Girasol", "Rastrojo de Maiz", "Sales Minerales", "Campo Organico Patagonico"]
    const silvoPastoralFood = ["Raigras Inglesa", "Festuca", "Trébol Subterráneo"]
    const pastoralFood = ["Alfalfa", "Raigras Inglesa", "Raigras Perenne", "Raigras Bianuales","Bromo" , "Falaris", "Festuca", "Pasto Ovillo", "Sorgo", "Trébol Alejandrino", "Trébol Blanco", "Pradera Consociada", "Verdeo Avena", "Verdeo de Centeno", "Ver. Avena / Ras. Maiz", "Trébol Melilotus", "Verdeo de Mijo", "Rollo de Pastura", "Rollo de Alfalfa", "Eragrostis Cúrvula (P. Llorón)", "Gatton Panic"]
    const creepFeedingFood=["Alfalfa", "Avena", "Raigras Inglesa", "Raigras Perenne", "Raigras Bianuales","Bromo" , "Falaris", "Festuca", "Pasto Ovillo", "Trigo", "Cebada", "Centeno", "Maíz (Suplem. NO mayor a 1kg)", "Sorgo", "Trébol Rojo", "Cebadilla Criolla", "Cebadilla", "Agropiro", "Lotus", "Expeller de Girasol", "Verdeo Avena"]
    const strategicFeedingFood=["Avena", "Festuca", "Trigo", "Cebada", "Centeno", "Maíz (Suplem. NO mayor a 1kg)", "Sorgo", "Expeller de Girasol", "Verdeo Avena", "Silo de Pastura", "Silo de Maiz", "Silo de Sorgo", "Maiz Picado"]
    const feedlotFood=["Avena", "Trigo", "Cebada", "Centeno", "Maíz (Suplem. NO mayor a 1kg)", "Sorgo", "Expeller de Girasol", "Núcleo Vitamínico", "Silo de Pastura", "Silo de Maiz", "Silo de Sorgo", "Rollo de Pastura", "Rollo de Alfalfa", "Rollo de Mijo", "Maiz Picado", "Suplement. Feed Lot"]
    const noneSystemFood=[]

    let [selectedData, setSelectedData] = useState();    
    let [selectedFoodsUE, setSelectedFoodsUE] = useState();

    const [validLots, setValidLots] = useState([]);
    const [activeLots, setActiveLots] = useState();
    const [selectedLot, selectLot] = useState();
    const [activeTab, setActiveTab] = useState('1');
    const [earIdValidatorMode, setEarIdValidatorMode] = useState(0);
    const [motherEarIdValidatorMode, setMotherEarIdValidatorMode] = useState(0);
    const [fatherEarIdValidatorMode, setFatherEarIdValidatorMode] = useState(0);
    const [tierModalIsOpen, toggleTierModal] = useState(false);
    const [genealogyWizardModalIsOpen, toggleGenealogyWizardModal] = useState(false);
    const [productiveSystemsEnabled, setProductiveSystemsEnabled] = useState([]);
    const [wizardButtonPending, setWizardButtonPending] = useState(false);
    
    const [genealogyHBAHighlight,setGenealogyHBAHighlight] = useState(false);
    const [genealogyCCHighlight,setGenealogyCCHighlight] = useState(false);
    const [genealogyRPHighlight,setGenealogyRPHighlight] = useState(false);
    const [genealogyNameHighlight,setGenealogyNameHighlight] = useState(false);
    const [genealogyFatherHBAHighlight,setGenealogyFatherHBAHighlight] = useState(false);
    const [genealogyFatherCCHighlight,setGenealogyFatherCCHighlight] = useState(false);
    const [genealogyFatherRPHighlight,setGenealogyFatherRPHighlight] = useState(false);
    const [genealogyFatherNameHighlight,setGenealogyFatherNameHighlight] = useState(false);
    const [genealogyMotherHBAHighlight,setGenealogyMotherHBAHighlight] = useState(false);
    const [genealogyMotherCCHighlight,setGenealogyMotherCCHighlight] = useState(false);
    const [genealogyMotherRPHighlight,setGenealogyMotherRPHighlight] = useState(false);
    const [genealogyMotherNameHighlight,setGenealogyMotherNameHighlight] = useState(false);

    const [genealogyWizardResponse, setGenealogyWizardResponse] = useState([]);
    
    const [saveDisable, setSaveDisable] = useState(false);

    const {
        t,
        handleSubmit,
        onSubmit,
        availableProductiveSystems,
        editable,
        submittable,
        hideTitle,
        showImages,
        allAvailableProductiveSystems,
        selectedEstablishment,
        establishmentLots,
        selectedEstablishmentLotMode,
        allEstablishments,
        initialValues
    } = props;


    useEffect(() => {
        props.getAvailableCowBreeds();
        props.getAvailableProductiveSystems();
        props.getDentitionValues();  
    }, []);

    useEffect(() => {
        props.getUserEstablishmentById(selectedEstablishment._id, ()=> {});
    }, [selectedEstablishment]);


    useEffect(() => {
       setValidLots(getActiveLots());
    }, [selectedEstablishment]);



    useEffect(() => {
        // remapeo de sistemas productivos: si no existe el sistema del animal en la lista de alternativas posibles, lo agrego
        let selected = null;
        let productiveSystemsEnabledTemp = [...selectedEstablishment.productiveSystems];

        if (props.initialValues.productive_system!=undefined) {
            for (var key of Object.keys(props.initialValues.productive_system)) {
                if (props.initialValues.productive_system[key]) {
                    selected = key;
                }
            }

            if (selectedEstablishment.productiveSystems.includes(selected)==false) {
                productiveSystemsEnabledTemp.push(selected)
            }
        }

        setProductiveSystemsEnabled(productiveSystemsEnabledTemp)

     }, [selectedEstablishment]);




    const getActiveLots = () => {
        let lots = []
        const defaultOptions = [
          { "_id": "newLot", "name": "Crear nuevo Lote" },
          { "_id": constants.NOT_ASSIGNED, "name": "Sin Lote" }
        ]

        if(selectedEstablishmentLotMode !== "lotModeDisabled") lots = [...defaultOptions]
        for(let lot of establishmentLots) {
            if (lot.isActive === true && lot._id != 0 && lot._id !== constants.NOT_ASSIGNED) lots.push(lot)
        }
        return lots
    }

    const renderInfoBody = () => (
        <div>
            <span>
            Tu empresa ha llegado al límite de animales activos ({props.animalsCountSelectedCompanyScope.max} animales) para el plan vigente.<br/><br/>

            Para actualizar tu plan podés contactarnos haciendo click <a style={{cursor:"pointer", color:"black;", fontWeight:"weight"}} onClick={handleLinkEmail}><b><u>AQUI</u></b></a> <br/>o podés contactarnos en forma directa vía mail a <a target="_BLANK" style={{cursor:"pointer", color:"black;", fontWeight:"weight"}} href={'mailto:'+setting.emailPricing}><b><u>{setting.emailPricing}</u></b></a>
            &nbsp;o a whatsapp/telegram al {setting.telPricing}<br/><br/>

            Si te interesa conocer más sobre nuestros planes<br></br> podés hacer click <a style={{cursor:"pointer", color:"black;", fontWeight:"weight"}} onClick={handleLink}><b><u>AQUI</u></b></a>
            </span>
        </div>
    );

    const genealogyWizardModal = () => (
        <div>        
        <Modal style={{maxWidth: '900px', width: '80%'}} size="lg" isOpen={genealogyWizardModalIsOpen} toggle={() => toggleGenealogyWizardModal(!genealogyWizardModalIsOpen)}>
            <ModalHeader toggle={() => toggleGenealogyWizardModal(!genealogyWizardModalIsOpen)}>
                Resultado de la búsqueda con el criterio seleccionado en la base de <b>Sociedad Rural Argentina</b>
            </ModalHeader>
            <ModalBody>
          <div style={{
                maxHeight: '250px',
                overflowY: 'auto'
            }}>
            <Table>
            <thead>
              <tr>                                
                <th>Nombre</th>
                <th>Sexo</th>
                <th>HBA</th>
                <th>Fecha de Nacimiento</th>
                <th></th>
              </tr>
            </thead>
            <tbody>              
          {
            genealogyWizardResponse.map(option => (
                <tr>                
                    <td>{option.Nombre}</td>
                    <td>{option.Sexo}</td>
                    <td>{option.HBA}</td>                    
                    <td>{option.fechaNacimiento}</td>                    
                    <td><Button color="primary" style={{marginBottom:"5px",marginTop:"5px"}} size="sm" onClick={() => {bindWizardGenealogyResponse(option); toggleGenealogyWizardModal(!genealogyWizardModalIsOpen)}}>Seleccionar</Button>{' '}</td>
                </tr>                
            ))
            }
            </tbody>
            </Table>        
            </div>     
            </ModalBody>
            <ModalFooter>            
            <Button color="secondary" size="sm" onClick={() => toggleGenealogyWizardModal(!genealogyWizardModalIsOpen)}>Cancelar</Button>
            </ModalFooter>
        </Modal>
        </div>
    )       

    const handleLink = () => {
        const newWindow = window.open(setting.urlPricing, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const handleLinkEmail = () => {
        //const newWindow = window.open("mailto:"+setting.emailPricing+"?subject=Actualizar mi Plan en Carnes Validadas", '_blank', 'noopener,noreferrer')
        //if (newWindow) newWindow.opener = null
        toggleMailModal(true);
    }

    const [mailModalIsOpen, toggleMailModal] = useState(false);
    const [msgErrorMailModal, setMsgErrorMailModal] = useState('');
    const [buttonDisabledMailModal, setButtonDisabledMailModal] = useState(false);
    
    const mailModal = () => (
        <CVEmailTierModal            
            title={'Info'}            
            acceptText="Enviar"
            msgError={msgErrorMailModal}
            backdrop={"static"}
            keyboard={false}
            isOpen={mailModalIsOpen}
            toggleModal={() => toggleMailModal(!mailModalIsOpen)}
            hideCancelButton={false}
            cancel={() => toggleMailModal(false)}
            accept={acceptEmail}
            buttonDisabled={buttonDisabledMailModal}
            //acceptText={t('animals.modals.qr.download')}
        />
    );

    const acceptEmail = (context) => {
        setButtonDisabledMailModal(true);
        setMsgErrorMailModal("Aguarde un momento.");
        props.sendMailTierRequest(context,()=>{            
            toggleMailModal(false);
            toggleTierModal(false);
            setButtonDisabledMailModal(false);
            ToastsStore.success(t('Solicitud Enviada'), 10000)
            goBackList()
        },(error)=>{            
            setMsgErrorMailModal("No se ha podido enviar la solicitud, intente nuevamente.");
            setButtonDisabledMailModal(false);
        })
    }

    const tierModal = () => (
        <CVBasicModal
            title={'Tipo de Servicio'}
            body={renderInfoBody}
            isOpen={tierModalIsOpen}
            backdrop={"static"}
            keyboard={false}
            toggleModal={() => toggleTierModal(!tierModalIsOpen)}
            hideCancelButton={true}
            //cancel={() => toggleQrModal(false)}
            accept={goBackList}
            //acceptText={t('animals.modals.qr.download')}
        />
    );

    
    function goBackList() {
        props.history.push('/animals/table');
    }

    function changeSelectedFood(e){        
        let selectedFoodsTemp = []
        for (var i = 0; i < e.currentTarget.length; i++) {
            if(e.currentTarget.options[i].selected){selectedFoodsTemp.push(e.currentTarget.options[i].value)}
        }
        setSelectedFoodsUE(selectedFoodsTemp);
        setDefaultValueFood(selectedFoodsTemp);
    }

    const getSelectedLot = (e) => {
        selectLot(e.value)
    }

    const changeEstablishmentValue = (e) => {
        console.log(e)
        props.changeFieldValue('establishment', {
            value: e.value,
            label: e.label
        });
    }

    function searchTrueInProductiveSystem(object){        
        let values = Object.values(object)
        let keys = Object.keys(object)
        let index = values.indexOf(true)
        select2Data(keys[index])
    }

    function select2Data(text){
        let res;
        if(text === "natural"){res =  naturalFood}
        if(text === "silvoPastoral"){res = silvoPastoralFood}
        if(text === "pastoral"){res = pastoralFood}
        if(text === "creepFeeding"){res = creepFeedingFood}
        if(text === "strategicFeeding"){res = strategicFeedingFood}
        if(text === "feedlot"){res = feedlotFood}
        if(text === "noneSystem"){res =noneSystemFood}
        setSelectedData(res)
        
    }
    let renderSelect2 = () => (
      <div style={{width: '100%'}}>
          <Select2
            className={"customSelect2"}
            multiple
            data={selectedData}
            defaultValue={defaultValueFood}
            disabled={!editable}
            onChange={changeSelectedFood}
            options={
                {
                    placeholder: 'Alimentos',
                }
            }
          />
      </div>
    );

    const [productiveSystemSelected, setProductiveSystemSelected] = useState({});

    let [defaultValueFood, setDefaultValueFood] = useState();

    const alert = useAlert();

    const showErrors = (invalid) => {
        if(invalid) ToastsStore.error(t('common.general_invalid_form'), 10000)
    }

    // On cow details, sets the productive system selected.
    useEffect(() => {
        let selectedFoods = []
        initialValues.genealogywizard = {mode:"hba"}

        if(props.initialValues.productive_system) {
            setProductiveSystemSelected(props.initialValues.productive_system)            
        }
        if(props.initialValues.feeding){
            searchTrueInProductiveSystem(props.initialValues.productive_system)            
            setDefaultValueFood(props.initialValues.feeding)

            
            for (var i = 0; i < props.initialValues.feeding.length; i++) {
                selectedFoods.push(props.initialValues.feeding[i]);
            }
            
            
        } else {
            if (props.initialValues.productive_system) {
                searchTrueInProductiveSystem(props.initialValues.productive_system)
            }
            
        }
        setSelectedFoodsUE(selectedFoods);


        initEarIdValidator(props.initialValues.caravan_id);
        if (props.initialValues.birth != undefined) initFatherEarIdValidator(props.initialValues.birth.father_id);
        if (props.initialValues.birth != undefined) initMotherEarIdValidator(props.initialValues.birth.mother_id);
    }, [props.initialValues]);

    useEffect(() => {
        // Only if the form is not in 'details' mode
        if(!props.initialValues.establishment) {
            if(props.selectedEstablishment) {
                props.changeFieldValue('establishment', {
                    value: props.selectedEstablishment._id,
                    label: props.selectedEstablishment.name
                });
                // Set the available productive systems depending on the establishment selected.
                if(props.selectedEstablishment.productiveSystems) {
                    let productiveSystems = {};
                    
                    props.animalsCountSelectedCompanyScope.max=0;
                    props.animalsCountSelectedCompanyScope.count=-1;
                    setProductiveSystemSelected(productiveSystems)
                }
                props.getCompanyScopeAnimalsCount(selectedEstablishment._id);
                //toggleTierModal(true);
            }
            else {
                alert.info(t('cv-forms.cow.you_need_to_select_an_establishment'), {timeout: 0})
            }
        }
    }, [props.selectedEstablishment]);

    useEffect(()=>{                
        //console.log("debug",props.animalsMaxSelectedCompanyScope);        
        if (props.animalsCountSelectedCompanyScope.count>=props.animalsCountSelectedCompanyScope.max && props.animalsCountSelectedCompanyScope.max!=-1) {            
            toggleTierModal(true);
        }
        
    },[props.animalsCountSelectedCompanyScope])

    useEffect(() => {        
        if (selectedEstablishment.productiveSystems.length==1){
            selectProductiveSystem(selectedEstablishment.productiveSystems[0])
        }        
    }, [selectedEstablishment]);

    useEffect(() => {
        
    }, [selectedEstablishment]);


    const selectProductiveSystem = (productiveSystem) => {
        let newValue = {
            ...productiveSystemSelected
        };

        // set all productive systems to false
        Object.keys(newValue).forEach(key => {
            newValue[`${key}`] = false
        });
        newValue[`${productiveSystem}`] = true;
        searchTrueInProductiveSystem(newValue)
        setProductiveSystemSelected(newValue);
        
        setDefaultValueFood(['']);
        setSelectedFoodsUE(['']);
        
    };

    const noneProductiveSystemsSelected = (productiveSystems) => {
        
        // agrego sistema productivo original del animal a sistemas productivos posibles
        
        let availableProductiveSystemsTemp = [...availableProductiveSystems];
        if (props.initialValues.productive_system!=undefined) {
            for (var key of Object.keys(props.initialValues.productive_system)) {
                if (props.initialValues.productive_system[key]) {
                    availableProductiveSystemsTemp.push(key);
                }
            }
        }
        
        let result = true;
        availableProductiveSystemsTemp.forEach(productiveSystem => {
            if (productiveSystems[`${productiveSystem}`]) {
                return result = false
            }
        })
        return result
    }

    const showLotField = () => {
        if(selectedEstablishmentLotMode === "lotModeFlexible") return true;
        const validMode = selectedEstablishmentLotMode != "lotModeDisabled";
        const lotsToShow = establishmentLots && establishmentLots.length > 0;
        return lotsToShow && validMode;
    }    

    const submitForm = (values) => {        
        if(noneProductiveSystemsSelected(productiveSystemSelected)) return;
        // fix to override with no foods selected
        if (selectedFoodsUE.length==0) {selectedFoodsUE=[""]}
        // console.log("debug----->",selectedFoodsUE)        
        
        setSaveDisable(true);        
        setTimeout(function(){
            onSubmit({...values, productiveSystems: productiveSystemSelected, foods: selectedFoodsUE});
            setSaveDisable(false);
        },1000);
        
    };
    
    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    const deleteFile = (initialValuesName) => {           
        props.dispatch(change('cow-form',initialValuesName,null))
        props.dispatch(change('cow-form',initialValuesName+'_checksumFile',null))
        props.initialValues[initialValuesName]='';                                     
        props.initialValues[initialValuesName+'_checksumFile']='';
    }

    const updateEarId = (e) => {                
        if (e.currentTarget.value.length==0) {            
            setEarIdValidatorMode(0);
        } else if (e.currentTarget.value[0]=='0') {                        
            setEarIdValidatorMode(1);
        } else if (e.currentTarget.value[0]!='0') {
            setEarIdValidatorMode(2);
        }        
        if (e.currentTarget.value.length>1 && e.currentTarget.value[0]!='0') {            
            let isnum = /^\d+$/.test(e.currentTarget.value[1]);
            if (isnum) {
                setEarIdValidatorMode(3);
            }
        }        
        if (e.currentTarget.value.length>2) return;
        setTimeout(function(){
            if (e.currentTarget.value.length>1) return;
            var elem = document.getElementsByName("caravan_id")[0];
            let caretPos = 1;
            if(elem != null) {
                if(elem.createTextRange) {
                    var range = elem.createTextRange();
                    range.move('character', caretPos);
                    range.select();
                }
                else {
                    if(elem.selectionStart) {
                        elem.focus();
                        elem.setSelectionRange(caretPos, caretPos);
                    }
                    else
                        elem.focus();
                }
            }            
        },200);        
    }

    const updateFatherEarId = (e) => {                
        if (e.currentTarget.value.length==0) {            
            setFatherEarIdValidatorMode(0);
        } else if (e.currentTarget.value[0]=='0') {                                    
            setFatherEarIdValidatorMode(1);
        } else if (e.currentTarget.value[0]!='0') {            
            setFatherEarIdValidatorMode(2);
        }        
        if (e.currentTarget.value.length>1 && e.currentTarget.value[0]!='0') {            
            let isnum = /^\d+$/.test(e.currentTarget.value[1]);
            if (isnum) {
                setFatherEarIdValidatorMode(3);                
            }
        }        
        if (e.currentTarget.value.length>2) return;
        setTimeout(function(){
            if (e.currentTarget.value.length>1) return;
            var elem = document.getElementsByName("birth.father_id")[0];
            let caretPos = 1;
            if(elem != null) {
                if(elem.createTextRange) {
                    var range = elem.createTextRange();
                    range.move('character', caretPos);
                    range.select();
                }
                else {
                    if(elem.selectionStart) {
                        elem.focus();
                        elem.setSelectionRange(caretPos, caretPos);
                    }
                    else
                        elem.focus();
                }
            }            
        },200);        
    }

    const updateMotherEarId = (e) => {                
        if (e.currentTarget.value.length==0) {            
            setMotherEarIdValidatorMode(0);
        } else if (e.currentTarget.value[0]=='0') {                        
            setMotherEarIdValidatorMode(1);
        } else if (e.currentTarget.value[0]!='0') {
            setMotherEarIdValidatorMode(2);
        }        
        if (e.currentTarget.value.length>1 && e.currentTarget.value[0]!='0') {            
            let isnum = /^\d+$/.test(e.currentTarget.value[1]);
            if (isnum) {
                setMotherEarIdValidatorMode(3);
            }
        }        
        if (e.currentTarget.value.length>2) return;
        setTimeout(function(){
            if (e.currentTarget.value.length>1) return;
            var elem = document.getElementsByName("birth.mother_id")[0];
            let caretPos = 1;
            if(elem != null) {
                if(elem.createTextRange) {
                    var range = elem.createTextRange();
                    range.move('character', caretPos);
                    range.select();
                }
                else {
                    if(elem.selectionStart) {
                        elem.focus();
                        elem.setSelectionRange(caretPos, caretPos);
                    }
                    else
                        elem.focus();
                }
            }            
        },200);        
    }
    
    const refreshLabelsLightbox = () => {
        var divs = document.getElementsByClassName( "SRLCloseButton" );
        for (var a in divs) {
            try {
                divs[a].setAttribute('title', 'CERRAR');
            } catch (error) {

            }            
        }        
        var divsDownload = document.getElementsByClassName( "SRLDownloadButton" );
        for (var a in divs) {
            try {
                divsDownload[a].setAttribute('title', 'DESCARGAR');
            } catch (error) {

            }
        }        
    }

    const getValidatorsEarId = () => {                
        if (earIdValidatorMode==0) {            
            return [formValidators.validateRequired, formValidators.validateCaravanEIdRequired];
        } else if (earIdValidatorMode==1) {                        
            return [formValidators.validateRequired, formValidators.validateCaravanEIdRequired];
        } else if (earIdValidatorMode==2) {
            return [formValidators.validateRequired, formValidators.validateCaravanIdRequired];
        } else if (earIdValidatorMode==3) {
            return [formValidators.validateRequired, formValidators.validateCaravanIdRRequired];
        }                        
    }

    const getValidatorsFatherEarId = () => {                
        if (fatherEarIdValidatorMode==0) {            
            return [formValidators.validateCaravanEIdNotRequired];
        } else if (fatherEarIdValidatorMode==1) {                        
            return [formValidators.validateCaravanEIdNotRequired];
        } else if (fatherEarIdValidatorMode==2) {
            return [formValidators.validateCaravanIdNotRequired];
        } else if (fatherEarIdValidatorMode==3) {
            return [formValidators.validateCaravanIdRNotRequired];
        }                        
    }

    const getValidatorsMotherEarId = () => {                
        if (motherEarIdValidatorMode==0) {            
            return [formValidators.validateCaravanEIdNotRequired];
        } else if (motherEarIdValidatorMode==1) {                        
            return [formValidators.validateCaravanEIdNotRequired];
        } else if (motherEarIdValidatorMode==2) {
            return [formValidators.validateCaravanIdNotRequired];
        } else if (motherEarIdValidatorMode==3) {
            return [formValidators.validateCaravanIdRNotRequired];
        }                        
    }

    const initEarIdValidator = (earId) => {
        if (earId!=undefined) {
            let isnum = /^\d+$/.test(earId[1]);
            if (earId[0]=='0') {                        
                setEarIdValidatorMode(1);
            } else if (isnum) {
                setEarIdValidatorMode(3);
            } else {
                setEarIdValidatorMode(2);
            }
        }
    }

    const initFatherEarIdValidator = (earId) => {
        if (earId!=undefined) {
            let isnum = /^\d+$/.test(earId[1]);
            if (earId[0]=='0') {                        
                setFatherEarIdValidatorMode(1);
            } else if (isnum) {
                setFatherEarIdValidatorMode(3);
            } else {
                setFatherEarIdValidatorMode(2);
            }
        }
    }

    const initMotherEarIdValidator = (earId) => {
        if (earId!=undefined) {
            let isnum = /^\d+$/.test(earId[1]);
            if (earId[0]=='0') {                        
                setMotherEarIdValidatorMode(1);
            } else if (isnum) {
                setMotherEarIdValidatorMode(3);
            } else {
                setMotherEarIdValidatorMode(2);
            }
        }
    }

    const bindWizardGenealogyResponse = (response) => {
        if (response.Nombre !=undefined) {    
            props.dispatch(change('cow-form','birth.genealogyName',response.Nombre));
            setGenealogyNameHighlight(true);
        }
        if (response.Criador !=undefined) {    
            props.dispatch(change('cow-form','birth.genealogyCC',response.Criador));
            setGenealogyCCHighlight(true);
        }
        if (response.RP !=undefined) {    
            props.dispatch(change('cow-form','birth.genealogyRP',response.RP));
            setGenealogyRPHighlight(true);
        }
        if (response.HBA !=undefined) {    
            props.dispatch(change('cow-form','birth.genealogyHBA',response.HBA));
            setGenealogyHBAHighlight(true);
        }
        if (response.HBAMadre !=undefined) {    
            props.dispatch(change('cow-form','birth.genealogyMotherHBA',response.HBAMadre));
            setGenealogyMotherHBAHighlight(true);
        }
        if (response.HBAPadre !=undefined) {    
            props.dispatch(change('cow-form','birth.genealogyFatherHBA',response.HBAPadre));
            setGenealogyFatherHBAHighlight(true);
        }
        if (response.NombreMadre !=undefined) {    
            props.dispatch(change('cow-form','birth.genealogyMotherName',response.NombreMadre));
            setGenealogyMotherNameHighlight(true);
        }
        if (response.NombrePadre !=undefined) {    
            props.dispatch(change('cow-form','birth.genealogyFatherName',response.NombrePadre));
            setGenealogyFatherNameHighlight(true);
        }
    }

    const genealogywizardSearch = (event) => {                
        event.preventDefault();
                
        props.dispatch(change('cow-form','birth.genealogyName',""));        
        props.dispatch(change('cow-form','birth.genealogyCC',""));
        props.dispatch(change('cow-form','birth.genealogyRP',""));                 
        props.dispatch(change('cow-form','birth.genealogyHBA',""));
        props.dispatch(change('cow-form','birth.genealogyMotherHBA',""));        
        props.dispatch(change('cow-form','birth.genealogyFatherHBA',""));        
        props.dispatch(change('cow-form','birth.genealogyMotherName',""));        
        props.dispatch(change('cow-form','birth.genealogyFatherName',""));
        
        setGenealogyNameHighlight(false);
        setGenealogyCCHighlight(false);
        setGenealogyRPHighlight(false);
        setGenealogyHBAHighlight(false);
        setGenealogyMotherHBAHighlight(false);
        setGenealogyFatherHBAHighlight(false);
        setGenealogyMotherNameHighlight(false);
        setGenealogyFatherNameHighlight(false);
        
        
        if (props.genealogywizardMode=='hba' && props.genealogywizardHBA==undefined) {
            
            ToastsStore.error(t('Error: Tenés que ingresar un número de HBA en el formulario de Búsqueda'), 5000)                        
            return;
        }
        if (props.genealogywizardMode=='rp' && (props.genealogywizardCC==undefined || props.genealogywizardRP==undefined)) {
            ToastsStore.error(t('Error: Tenés que ingresar el código de criador y el RP en el formulario de Búsqueda'), 5000)                        
            return;
        }
        if (props.category==undefined) {
            ToastsStore.error(t('Error: Para poder realizar esta consulta tenés que seleccionar una categoría.'), 5000)                        
            return;
        }
        if (props.breed==undefined) {
            ToastsStore.error(t('Error: Para poder realizar esta consulta tenés que seleccionar una raza.'), 5000)                        
            return;
        }
        setWizardButtonPending(true);
        ToastsStore.success(t('Enviando Consulta a Sociedad Rural Argentina'), 4000)

        setTimeout(function(){
            props.getGenealogy(
            {
                mode:props.genealogywizardMode,
                rp:props.genealogywizardRP,
                cc:props.genealogywizardCC,
                hba:props.genealogywizardHBA,
                category: props.category,
                breed: props.breed,

            },(resArray)=>{                        
                if (resArray.length==-1) { // deprecado
                    let response = resArray[0];
                    bindWizardGenealogyResponse(response);
                    ToastsStore.success(t('Datos Encontrados!'), 5000)
                    setWizardButtonPending(false);
                }
                if (resArray.length==0) {
                    ToastsStore.error(t('No se han encontrado datos genealógicos para el criterio seleccionado.'), 5000)            
                    setWizardButtonPending(false);
                }
                if (resArray.length>0) {                    
                    toggleGenealogyWizardModal(true);
                    setGenealogyWizardResponse(resArray);
                    setWizardButtonPending(false);
                }
            },(error)=>{            
                ToastsStore.error(t('No se han encontrado datos genealógicos para el criterio seleccionado.'), 5000)            
                setWizardButtonPending(false);
        })}, 2000);
    }

    const {status : SRAServiceStatus, error: SRAServiceError} = props.SRAConnectionStatus

    return (
        
        
        <Col md={12} lg={12}>
        <SRAConnectionStatus seconds={setting.SRAConnectionStatusSeconds}/> 
            <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
            <Card>
                <CardBody>
                    <div className="card__title">
                        {!hideTitle && <h5 className="bold-text">{t('cv-forms.cow.title')}</h5>}
                    </div>
                    <form className="form" onSubmit={handleSubmit(submitForm)}>
                    <div className="edit-buttons" >
                        {!submittable && 
                        <label><h4>Aguarde...</h4></label>
                        }
                        {submittable && 
                        <ButtonToolbar className="form__button-toolbar" >
                                
                                { !editable && <Button 
                                    color="primary" 
                                    outline
                                    type="button" 
                                    onClick={() => props.setEditable(!editable)}>
                                    {t('common.edit')}</Button>
                                }
                                { editable && <CVButton
                                    outline 
                                    color="success"
                                    type="submit"
                                    onClick={() => showErrors(props.invalid)}
                                    pending={props.pending}
                                    disabled={saveDisable}
                                    >{t('common.save')}</CVButton>
                                }
                                { editable && <Button 
                                    type="button"
                                    outline
                                    color="danger"
                                    size="sm"
                                    onClick={() => {
                                        if(props.isNew){
                                            props.history.push('/animals/table');
                                        }else{
                                        props.setEditable(!editable)
                                        }
                                        }
                                        }>
                                    {t('common.cancel')}</Button>
                                }
                                { !editable && <Button 
                                    color="primary" 
                                    outline={true} 
                                    type="button" 
                                    onClick={() => props.history.goBack()}>
                                    {t('common.back')}</Button>
                                }
                        </ButtonToolbar>
                    }
                    </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.caravan_id')}</span>
                            
                            <Helpi 
                            title="X" 
                            size="20"
                            iconColor="lightgrey"
                            fontColor="white"/>
                            <div className="form__form-group-field">                                                                
                                {
                                    (earIdValidatorMode==0) &&
                                    <img style={{width:"30px",height:"30px"}} src={caravanIncompleteLogo}/>
                                }
                                {
                                    (earIdValidatorMode==1) &&
                                    <img style={{width:"30px",height:"30px"}} src={caravanEIdLogo}/>
                                }
                                {
                                    (earIdValidatorMode==2) &&
                                    <img style={{width:"30px",height:"30px"}} src={caravanIdLogo}/>
                                }
                                {
                                    (earIdValidatorMode==3) &&
                                    <img style={{width:"30px",height:"30px"}} src={caravanIdRLogo}/>
                                }                                
                                &nbsp;
                                <Field                                    
                                    name="caravan_id"
                                    component={renderField}
                                    validate={getValidatorsEarId()}
                                    type="text"                                                                        
                                    placeholder={t('cv-forms.cow.caravan_id')}
                                    mask={(earIdValidatorMode==1)?formMasks.caravanEIdMask:(earIdValidatorMode==2)?formMasks.caravanIdMask:(earIdValidatorMode==3)?formMasks.caravanIdMaskR:null}
                                    normalize={(value) => value.toUpperCase()}
                                    onChange={updateEarId}
                                    disabled={!editable}
                                />                                
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.fire_mark_place')}</span>
                          <Helpi 
                            title="" 
                            size="20"
                            iconColor="lightgrey"
                            isHtml
                            fontColor="white"/>
                            <div className="form__form-group-field">
                                <Field
                                    name="fire_mark_place"
                                    component={renderSelectField}
                                    disabled={!editable}
                                    type="text"
                                    placeholder={t('cv-forms.cow.fire_mark_place')}
                                    options={cowAvailableFireMarkPlace}
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.secondary_id.title')}</span>
                            <Helpi 
                            title="" 
                            size="20"
                            iconColor="lightgrey"
                            isHtml
                            fontColor="white"/>
                            <div className="form__form-group-field row_direction">
                                <div>
                                    <span className="secondary__label">{t('cv-forms.cow.secondary_id.electronic_id')}</span>
                                    <Field
                                        name="secondary_id.chip"
                                        component={renderField}
                                        disabled={!editable}
                                        type="text"
                                        placeholder={t('cv-forms.cow.secondary_id.electronic_id')}
                                    />
                                </div>
                                <div>
                                    <span className="secondary__label">{t('cv-forms.cow.secondary_id.tattoo')}</span>
                                    <Field
                                        name="secondary_id.button"
                                        component={renderField}
                                        disabled={!editable}
                                        type="text"
                                        placeholder={t('cv-forms.cow.secondary_id.tattoo')}
                                    />
                                </div>
                                <div>
                                    <span className="secondary__label">{t('cv-forms.cow.secondary_id.internal_caravan')}</span>
                                    <Helpi 
                            title="" 
                            size="20"
                            iconColor="lightgrey"
                            isHtml
                            fontColor="white"/>
                                    <Field
                                        name="secondary_id.internal_caravan"
                                        component={renderField}
                                        disabled={!editable}
                                        type="text"
                                        placeholder={t('cv-forms.cow.secondary_id.internal_caravan')}
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div className="form__form-group">
                            
                            <Row>
                                <Col md={3} lg={3}>
                                <div>
                                    <span className="secondary__label">{t('cv-forms.cow.animal_image')}</span>
                                    <Helpi 
                                    title="" 
                                    size="20"
                                    iconColor="lightgrey"
                                    fontColor="white"/>
                                    
                                    <div>
                                        {
                                            (!showImages || editable) &&
                                            <Field
                                                name="animal_image"
                                                component={renderCVPhotoInputField}
                                                disabled={!editable}
                                                hideFileName={true}
                                            />                                            

                                        }
                                        {
                                            // Image preview if one is selected
                                            typeof (props.animalImage) === 'object' && props.animalImage!=null ?
                                                <div>
                                                    <SRLWrapper options={optionsLightbox}>                                                        
                                                        <img onClick={()=>{refreshLabelsLightbox()}} style={{maxHeight:"100px", width:"auto", cursor:"pointer"}} src={URL.createObjectURL(props.animalImage.files[0])}/>                                                        
                                                    </SRLWrapper>
                                                    {
                                                    (editable) &&
                                                        <div><a onClick={()=>{deleteFile('animal_image')}}><button style={{marginTop:"4px"}}>Eliminar</button></a></div>
                                                    }
                                                </div>:
                                                // Show image uploaded if there is one
                                                showImages && initialValues.animal_image && initialValues.animal_image != '' ?
                                                    <div>
                                                        <SRLWrapper options={optionsLightbox}>                                                                                                                        
                                                            <img onClick={()=>{refreshLabelsLightbox()}} style={{maxHeight:"100px", width:"auto", cursor:"pointer"}} src={initialValues.animal_image}/>                                                                                                                                                                                
                                                        </SRLWrapper>
                                                        {
                                                        (editable) &&
                                                            <div><a onClick={()=>{deleteFile('animal_image')}}><button style={{marginTop:"4px"}}>Eliminar</button></a></div>
                                                        }
                                                    </div>:
                                                    <label>{t('cv-forms.cow.no_animal_image')}</label>                                                    
                                        }
                                        
                                        
                                    </div>
                                </div>
                                </Col>
                                <Col md={3} lg={3}>
                                <div>
                                    <span className="secondary__label">{t('cv-forms.cow.signal_on_ear')}</span>
                                    <Helpi 
                                        title="" 
                                        size="20"
                                        iconColor="lightgrey"
                                        fontColor="white"/>
                                    <div>
                                        {
                                            (!showImages || editable) &&
                                            <Field
                                                name="signal_on_ear"
                                                component={renderCVPhotoInputField}
                                                disabled={!editable}
                                                hideFileName={true}
                                            />

                                        }
                                        {
                                            // Image preview if one is selected
                                            typeof (props.signalOnEarImage) === 'object' && props.signalOnEarImage!=null ?
                                                <div>
                                                    <SRLWrapper options={optionsLightbox}>                                                        
                                                    <img onClick={()=>{refreshLabelsLightbox()}} style={{maxHeight:"100px", width:"auto", cursor:"pointer"}} src={URL.createObjectURL(props.signalOnEarImage.files[0])}/>
                                                    </SRLWrapper>
                                                    {
                                                    (editable) &&
                                                        <div><a onClick={()=>{deleteFile('signal_on_ear')}}><button style={{marginTop:"4px"}}>Eliminar</button></a></div>
                                                    }
                                                </div>:
                                                // Show image uploaded if there is one
                                                showImages && initialValues.signal_on_ear && initialValues.signal_on_ear != '' ?
                                                    <div>
                                                        <SRLWrapper options={optionsLightbox}>                                                        
                                                        <img onClick={()=>{refreshLabelsLightbox()}} style={{maxHeight:"100px", width:"auto", cursor:"pointer"}} src={initialValues.signal_on_ear}/>                                                        
                                                        </SRLWrapper>
                                                        {
                                                        (editable) &&
                                                            <div><a onClick={()=>{deleteFile('signal_on_ear')}}><button style={{marginTop:"4px"}}>Eliminar</button></a></div>
                                                        }
                                                    </div>:
                                                    <label>{t('cv-forms.cow.no_animal_image')}</label>                                                    
                                        }                                        
                                    </div>
                                </div>
                                </Col>
                                <Col md={3} lg={3}>
                                <div>
                                    <span className="secondary__label">{t('cv-forms.cow.animal_video_url')}</span>
                                    <Helpi 
                                        title="" 
                                        size="20"
                                        iconColor="lightgrey"
                                        fontColor="white"/>
                                    <Field
                                        name="animal_video_url"
                                        component={renderField}
                                        disabled={!editable}
                                        type="text"
                                        placeholder={t('cv-forms.cow.animal_video_url')}
                                    />
                                </div>
                                </Col>
                            
                            </Row>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.breed')}</span>
                            <Helpi 
                                        title="" 
                                        size="20"
                                        iconColor="lightgrey"
                                        fontColor="white"/>
                            <div className="form__form-group-field">
                                <Field                                    
                                    name="breed"
                                    component={renderSelectField}
                                    disabled={!editable}
                                    validate={formValidators.validateRequired}
                                    type="text"
                                    placeholder={t('cv-forms.cow.breed')}
                                    options={                                        
                                        [                                            
                                            {isDisabled: true},                                            
                                            ...cowAvailableBreeds.map(breed => ({
                                                value: breed,
                                                label: breed                                                
                                            }))
                                        ]
                                    }
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.species')}</span>
                            <Helpi 
                                        title="" 
                                        size="20"
                                        iconColor="lightgrey"
                                        fontColor="white"/>
                            <div className="form__form-group-field">
                                <Field
                                    name="specie"
                                    component={renderSelectField}
                                    validate={formValidators.validateRequired}
                                    disabled={!editable}
                                    type="text"
                                    placeholder={t('cv-forms.cow.species')}
                                    options={
                                        [
                                            ...cowAvailableSpecies.map(specie => ({
                                                value: specie,
                                                label: specie
                                            }))
                                        ]
                                    }
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.category')}</span>
                            <Helpi 
                                        title="" 
                                        size="20"
                                        iconColor="lightgrey"
                                        fontColor="white"/>
                            <div className="form__form-group-field">
                                <Field
                                    name="category"
                                    component={renderSelectField}
                                    validate={formValidators.validateRequired}
                                    disabled={!editable}
                                    type="text"
                                    placeholder={t('cv-forms.cow.category')}
                                    options={
                                        [
                                            ...cowAvailableCategories.map(category => ({
                                                value: category,
                                                label: category
                                            }))
                                        ]
                                    }
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.dentition')}</span>
                            <Helpi 
                                        title="" 
                                        size="20"
                                        iconColor="lightgrey"
                                        fontColor="white"/>
                            <div className="form__form-group-field">
                                <Field
                                  name="dentition"
                                  component={renderSelectField}
                                  validate={formValidators.validateRequired}
                                  disabled={!editable}
                                  type="text"
                                  placeholder={t('cv-forms.cow.dentition')}
                                  options={
                                      [
                                          ...props.dentitionValues.map(dentition => ({
                                              value: dentition,
                                              label: dentition
                                          }))
                                      ]
                                  }
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.age_in_months')}</span>
                            <Helpi 
                                        title="" 
                                        size="20"
                                        iconColor="lightgrey"
                                        fontColor="white"/>
                            <div className="form__form-group-field">
                                <Field
                                    name="age_in_months"
                                    component={renderField}
                                    disabled={!editable}
                                    validate={validateCowAgeInMonths}
                                    type="number"
                                    placeholder={t('cv-forms.cow.age_in_months')}
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.weight_at_discharge')}</span>
                            <Helpi 
                                        title="" 
                                        size="20"
                                        iconColor="lightgrey"
                                        fontColor="white"/>
                            <div className="form__form-group-field">
                                <Field
                                    name="weight_at_discharge"
                                    component={renderField}
                                    validate={[formValidators.validateRequired, formValidators.validateCowWeight]}
                                    disabled={!editable}
                                    type="number"
                                    placeholder={t('cv-forms.cow.weight_at_discharge')}
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span
                                className="form__form-group-label">{t('cv-forms.productive_establishment.productive_system')}</span>
                                <Helpi 
                                        title="" 
                                        size="20"
                                        iconColor="lightgrey"
                                        fontColor="white"/>
                            <div className="form__form-group-field">
                                
                                
                                {
                                    editable && productiveSystemsEnabled.length!=1 &&
                                    productiveSystemsEnabled.map((productiveSystem, index) => (
                                        <div style={{marginLeft: index === 0 ? '0px' : '20px', display:'flex'}}>
                                            
                                            <CheckBoxField
                                                onChange={() => selectProductiveSystem(productiveSystem)}
                                                value={productiveSystemSelected[`${productiveSystem}`]}
                                                disabled={!editable}
                                                name={`productive_system.${productiveSystem}`}
                                                label={
                                                    <div>
                                                    {t(`cv-forms.productive_establishment.productive_system_options.${productiveSystem}`)}
                                                    &nbsp;                                                    
                                                    </div>
                                                }
                                            />
                                            <Helpi 
                                                        title={t(`cv-forms.productive_establishment.productive_system_options_helpis.${productiveSystem}`)} 
                                                        size="20"
                                                        iconColor="lightgrey"
                                                        fontColor="white"/> 
                                            
                                            
                                        </div>
                                    ))
                                }
                                {
                                    editable && productiveSystemsEnabled.length==1 &&
                                    productiveSystemsEnabled.map((productiveSystem, index) => (
                                        <div style={{marginLeft: index === 0 ? '0px' : '20px'}}>
                                            
                                            <CheckBoxField
                                                //onChange={() => selectProductiveSystem(productiveSystem)}
                                                value={true}
                                                defaultChecked={true}
                                                disabled={true}
                                                name={`productive_system.${productiveSystem}`}
                                                label={t(`cv-forms.productive_establishment.productive_system_options.${productiveSystem}`)}
                                            />
                                        </div>
                                    ))
                                }
                                {
                                    !editable &&
                                    productiveSystemsEnabled.map((productiveSystem, index) => (
                                        <div style={{marginLeft: index === 0 ? '0px' : '20px'}}>
                                            
                                            <CheckBoxField
                                                onChange={() => selectProductiveSystem(productiveSystem)}
                                                value={productiveSystemSelected[`${productiveSystem}`]}
                                                disabled={!editable}
                                                name={`productive_system.${productiveSystem}`}
                                                label={t(`cv-forms.productive_establishment.productive_system_options.${productiveSystem}`)}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                editable && noneProductiveSystemsSelected(productiveSystemSelected) &&
                                <div className="input-error-container">
                                        <span className="error-message">
                                            {t(`errors.REQUIRED`)}
                                        </span>
                                </div>
                            }
                        </div>

                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.productive_establishment.feeding')}</span>
                            <Helpi 
                                        title="" 
                                        size="20"
                                        iconColor="lightgrey"
                                        fontColor="white"/>
                            <div className="form__form-group-field">
                                <Field
                                  name="food"
                                  component={renderSelect2}
                                  type="text"
                                  value={selectedFoodsUE}
                                />
                            </div>
                        </div>

                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.birth_date')}</span>
                            <Helpi 
                                        title="" 
                                        size="20"
                                        iconColor="lightgrey"
                                        fontColor="white"/>
                            <div className="form__form-group-field">
                                <Field
                                    name="birth.date"
                                    component={renderDateInputField}
                                    validate={validateCowBirthDate}
                                    disabled={!editable}
                                />
                            </div>
                        </div>
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.born_weight')}</span>
                            <Helpi 
                                        title="" 
                                        size="20"
                                        iconColor="lightgrey"
                                        fontColor="white"/>
                            <div className="form__form-group-field">
                                <Field
                                    name="birth.weight"
                                    component={renderField}
                                    validate={formValidators.validateCowWeightNotRequired}
                                    disabled={!editable}
                                    type="number"
                                    placeholder={t('cv-forms.cow.born_weight')}
                                />
                            </div>
                        </div>
                        
                        <Row style={{width:"100%", marginBottom:"20px"}}> 
                            <Col md={12} lg={12}>               
                                <div className="card__subtitle">
                                    <h5 className="bold-text">{t('cv-forms.cow.genealogy')}</h5>
                                    <Helpi 
                                        title="" 
                                        size="20"
                                        iconColor="lightgrey"
                                        fontColor="white"/>
                                </div>
                            </Col>
                                <Row style={{width:"80%", padding:"5px", marginBottom:"20px", marginLeft:"20px", "pointer-events": (!SRAServiceStatus) && "none", borderStyle:"dotted", borderWidth:"1px", backgroundColor:"#f7f7f7"}}>
                                    <Col md={12} lg={12}>
                                        <p style={{marginBottom:"10px"}}>Podés completar tus datos genealógicos consultando la base de datos de la <b>Sociedad Rural Argentina</b></p>
                                    </Col>
                                    <Col md={3} lg={3}>                                               
                                    <div className="form__form-group" style={{marginBottom:"5px"}}>                                    
                                        <span style={{marginLeft:"20px"}} className="form__form-group-label">{t('cv-forms.cow.genealogywizard.hba')}</span>
                                        <div className="form__form-group-field">                                        
                                        <Field className="cv-radio-btn" name="genealogywizard.mode" component="input" type="radio" value="hba"/>
                                            <Field
                                                name="genealogywizard.hba"
                                                component={renderFieldWizard}                                        
                                                disabled={!editable}                                            
                                            />
                                        </div>
                                    
                                    </div>
                                    </Col>
                                    <Col md={6} lg={6}>                       
                                        
                                        <Row>
                                            <Col md={6} lg={6}>                                                       
                                            <div className="form__form-group" style={{marginBottom:"5px"}}>
                                                <span style={{marginLeft:"20px"}} className="form__form-group-label">{t('cv-forms.cow.genealogywizard.cc')}</span>
                                                <div className="form__form-group-field">                                                
                                                    <Field className="cv-radio-btn"  name="genealogywizard.mode" component="input" type="radio" value="rp" />                            
                                                    <Field
                                                        name="genealogywizard.cc"
                                                        component={renderFieldWizard}                                        
                                                        disabled={!editable}
                                                    />
                                                </div>
                                            </div>
                                            </Col>
                                            <Col md={6} lg={6}>               
                                            <div className="form__form-group" style={{marginBottom:"5px"}}>
                                                <span className="form__form-group-label">{t('cv-forms.cow.genealogywizard.rp')}</span>
                                                <div className="form__form-group-field">
                                                    <Field
                                                        name="genealogywizard.rp"
                                                        component={renderFieldWizard}                                        
                                                        disabled={!editable}
                                                    />
                                                </div>
                                            </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={2} lg={2}>                       
                                        <CVButton pending={wizardButtonPending} onClick={genealogywizardSearch} style={{height:"40px", marginTop:"20px"}}>Buscar</CVButton>
                                    </Col>
                                    <Col  md={12} lg={12}>                       
                                        {(!SRAServiceStatus) && (<div><strong>{  t('cv-forms.cow.genealogywizard.no_conn')}</strong></div>)}
                                    </Col>   
                                </Row>
                            
                            
                            
                            <Col md={12} lg={12}>               
                            <Nav tabs style={{marginTop:"5px"}}>
                                <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => { toggle('1'); }}
                                >
                                    Genealogía del Animal                                    
                                </NavLink>
                                </NavItem>
                                <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => { toggle('2'); }}
                                >
                                    Genealogía del Padre
                                </NavLink>
                                </NavItem>
                                <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '3' })}
                                    onClick={() => { toggle('3'); }}
                                >
                                    Genealogía de la Madre
                                </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab} style={{width:"100%"}}>
                                <TabPane tabId="1" style={{width:"100%",borderStyle:"dotted", borderWidth:"2px", padding:"5px"}}>
                                <div className="form__form-group" style={{width:"100%"}}>                                    
                                    <div className="form__form-group-field row_direction">
                                                                                
                                        <div>
                                        <span className="secondary__label">{t('cv-forms.cow.genealogy_hba')}</span>
                                        <Field
                                            name="birth.genealogyHBA"
                                            component={renderField}
                                            disabled={!editable}
                                            type="text"
                                            highlight={genealogyHBAHighlight}
                                            placeholder={t('cv-forms.cow.genealogy_hba')}
                                            
                                            normalize={(value) => value.toUpperCase()}                                    
                                        />
                                        </div>
                                        <div style={{marginLeft:"10px"}}>
                                        <span className="secondary__label">{t('cv-forms.cow.genealogy_rp')}</span>
                                        <Field
                                            name="birth.genealogyRP"
                                            component={renderField}
                                            disabled={!editable}
                                            type="text"
                                            highlight={genealogyRPHighlight}
                                            placeholder={t('cv-forms.cow.genealogy_rp')}
                                            
                                            normalize={(value) => value.toUpperCase()}                                    
                                        />
                                        </div>
                                        <div style={{marginLeft:"10px"}}>
                                        <span className="secondary__label">{t('cv-forms.cow.genealogy_cc')}</span>
                                        <Field
                                            name="birth.genealogyCC"
                                            component={renderField}
                                            disabled={!editable}
                                            highlight={genealogyCCHighlight}
                                            type="text"
                                            placeholder={t('cv-forms.cow.genealogy_cc')}
                                            
                                            normalize={(value) => value.toUpperCase()}                                    
                                        />
                                        </div>
                                        <div style={{marginLeft:"10px"}}>
                                        <span className="secondary__label">{t('cv-forms.cow.genealogy_name')}</span>
                                        <Field
                                            name="birth.genealogyName"                                     
                                            component={renderField}
                                            disabled={!editable}
                                            highlight={genealogyNameHighlight}
                                            type="text"
                                            placeholder={t('cv-forms.cow.genealogy_name')}
                                            
                                            normalize={(value) => value.toUpperCase()}                                    
                                        />
                                        </div>
                                    </div>
                                    <div className="form__form-group-field row_direction">
                                                                        
                                        <div>
                                        <span className="secondary__label">{t('cv-forms.cow.genealogy_certificate_identifier')}</span>
                                        <Field
                                            name="birth.genealogyCertificateIdentifier"
                                            component={renderField}
                                            disabled={!editable}
                                            type="text"
                                            placeholder={t('cv-forms.cow.genealogy_certificate_identifier')}
                                            
                                            normalize={(value) => value.toUpperCase()}                                    
                                        />
                                        </div>
                                        <div style={{marginLeft:"10px"}}>
                                        <span className="form__form-group-label">{t('cv-forms.cow.genealogy_certificate')}</span>
                                        {
                                            (!showImages || editable) &&
                                            <Field
                                                name="genealogyCertificate"
                                                component={renderCVFileInputField}
                                                disabled={!editable}
                                                acceptedFormats={'.pdf, .docx, .doc, .xlsx, .xls, .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .png, .gif, .tiff, .tif, .bmp, .dib'}
                                            />
                                        }
                                        
                                        {detailFileFieldNoImage(t,editable,showImages,props,'genealogyCertificateFile','genealogyCertificate',deleteFile)}
                                        
                                        
                                        </div>                           
                                        
                                        <div style={{marginLeft:"10px"}}>
                                            <span className="form__form-group-label">{t('cv-forms.cow.DNA_analysis')}</span>
                                            
                                                {
                                                    (!showImages || editable) &&
                                                    <Field
                                                        name="DNA_analysis"
                                                        component={renderCVFileInputField}
                                                        disabled={!editable}
                                                        acceptedFormats={'.pdf, .docx, .doc, .xlsx, .xls, .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .png, .gif, .tiff, .tif, .bmp, .dib'}
                                                    />
                                                }                                                
                                                {detailFileFieldNoImage(t,editable,showImages,props,'dnaAnalysisFile','DNA_analysis',deleteFile)}                                            
                                        </div>

                                        <div style={{marginLeft:"10px"}}>
                                            <span className="form__form-group-label">{t('cv-forms.cow.genealogy_DEPS')}</span>
                                            
                                                {
                                                    (!showImages || editable) &&
                                                    <Field
                                                        name="genealogy_DEPS"
                                                        component={renderCVFileInputField}
                                                        disabled={!editable}
                                                        acceptedFormats={'.jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .png, .gif, .tiff, .tif, .bmp, .dib'}
                                                    />
                                                }                                            
                                                {detailFileFieldNoImage(t,editable,showImages,props,'genealogyDEPSImage','genealogy_DEPS',deleteFile)}                                            
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                </TabPane>                                
                                <TabPane tabId="2" style={{padding:"5px", width:"100%",borderStyle:"dotted", borderWidth:"2px"}}>
                                <div className="form__form-group">                                    
                                    <div className="form__form-group-field row_direction">
                                        
                                        <div>                                        
                                        <span className="secondary__label">{t('cv-forms.cow.father_id')}</span>
                                        <Field
                                            name="birth.father_id"
                                            component={renderField}
                                            disabled={!editable}
                                            iconSrc={(fatherEarIdValidatorMode==0)?caravanIncompleteLogo:(fatherEarIdValidatorMode==1)?caravanEIdLogo:(fatherEarIdValidatorMode==2)?caravanIdLogo:(fatherEarIdValidatorMode==3)?caravanIdRLogo:null}
                                            type="text"
                                            placeholder={t('cv-forms.cow.father_id')}
                                            mask={(fatherEarIdValidatorMode==1)?formMasks.caravanEIdMask:(fatherEarIdValidatorMode==2)?formMasks.caravanIdMask:(fatherEarIdValidatorMode==3)?formMasks.caravanIdMaskR:null}
                                            normalize={(value) => value.toUpperCase()}
                                            validate={getValidatorsFatherEarId()}
                                            onChange={updateFatherEarId}
                                        />
                                        </div>
                                        <div style={{marginLeft:"10px"}}>
                                        <span className="secondary__label">{t('cv-forms.cow.father_genealogy_hba')}</span>
                                        <Field
                                            name="birth.genealogyFatherHBA"
                                            component={renderField}
                                            disabled={!editable}
                                            type="text"
                                            highlight={genealogyFatherHBAHighlight}
                                            placeholder={t('cv-forms.cow.father_genealogy_hba')}
                                            
                                            normalize={(value) => value.toUpperCase()}                                    
                                        />
                                        </div>
                                        <div style={{marginLeft:"10px"}}>
                                        <span className="secondary__label">{t('cv-forms.cow.father_genealogy_rp')}</span>
                                        <Field
                                            name="birth.genealogyFatherRP"
                                            component={renderField}
                                            disabled={!editable}
                                            type="text"
                                            highlight={genealogyFatherRPHighlight}
                                            placeholder={t('cv-forms.cow.father_genealogy_rp')}
                                            
                                            normalize={(value) => value.toUpperCase()}                                    
                                        />
                                        </div>
                                        <div style={{marginLeft:"10px"}}>
                                        <span className="secondary__label">{t('cv-forms.cow.father_genealogy_cc')}</span>
                                        <Field
                                            name="birth.genealogyFatherCC"
                                            component={renderField}
                                            disabled={!editable}
                                            type="text"
                                            highlight={genealogyFatherCCHighlight}
                                            placeholder={t('cv-forms.cow.father_genealogy_cc')}
                                            
                                            normalize={(value) => value.toUpperCase()}                                    
                                        />
                                        </div>
                                        <div style={{marginLeft:"10px"}}>
                                        <span className="secondary__label">{t('cv-forms.cow.father_genealogy_name')}</span>
                                        <Field
                                            name="birth.genealogyFatherName"
                                            component={renderField}
                                            disabled={!editable}
                                            type="text"
                                            placeholder={t('cv-forms.cow.father_genealogy_name')}
                                            highlight={genealogyFatherNameHighlight}
                                            normalize={(value) => value.toUpperCase()}                                    
                                        />
                                        </div>
                                    </div>
                                    <div className="form__form-group-field row_direction">
                                                                        
                                        <div>
                                        <span className="secondary__label">{t('cv-forms.cow.genealogy_father_certificate_identifier')}</span>
                                        <Field
                                            name="birth.genealogyFatherCertificateIdentifier"
                                            component={renderField}
                                            disabled={!editable}
                                            type="text"
                                            placeholder={t('cv-forms.cow.genealogy_father_certificate_identifier')}
                                            
                                            normalize={(value) => value.toUpperCase()}                                    
                                        />
                                        </div>
                                        <div style={{marginLeft:"10px"}}>
                                        <span className="secondary__label">{t('cv-forms.cow.genealogy_father_certificate')}</span>
                                        {
                                            (!showImages || editable) &&
                                            <Field
                                                name="genealogyFatherCertificate"
                                                component={renderCVFileInputField}
                                                disabled={!editable}
                                                acceptedFormats={'.pdf, .docx, .doc, .xlsx, .xls, .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .png, .gif, .tiff, .tif, .bmp, .dib'}
                                            />
                                        }
                                        
                                        {detailFileFieldNoImage(t,editable,showImages,props,'genealogyFatherCertificateFile','genealogyFatherCertificate',deleteFile)}
                                        </div>                           
                                        
                                        <div style={{marginLeft:"10px"}}>
                                            <span className="form__form-group-label">{t('cv-forms.cow.DNA_analysis_father')}</span>
                                            
                                                {
                                                    (!showImages || editable) &&
                                                    <Field
                                                        name="DNA_analysis_father"
                                                        component={renderCVFileInputField}
                                                        disabled={!editable}
                                                        acceptedFormats={'.pdf, .docx, .doc, .xlsx, .xls, .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .png, .gif, .tiff, .tif, .bmp, .dib'}
                                                    />
                                                }
                                                
                                                {detailFileFieldNoImage(t,editable,showImages,props,'dnaAnalysisFile_father','DNA_analysis_father',deleteFile)}                                            
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                </TabPane>
                                <TabPane tabId="3" style={{width:"100%",borderStyle:"dotted", borderWidth:"2px", padding:"5px"}}>
                                <div className="form__form-group">                                    
                                    <div className="form__form-group-field row_direction">
                                        
                                        <div>
                                        <span className="secondary__label">{t('cv-forms.cow.mother_id')}</span>
                                                                                                                                                     
                                        <Field
                                            name="birth.mother_id"
                                            component={renderField}
                                            iconSrc={(motherEarIdValidatorMode==0)?caravanIncompleteLogo:(motherEarIdValidatorMode==1)?caravanEIdLogo:(motherEarIdValidatorMode==2)?caravanIdLogo:(motherEarIdValidatorMode==3)?caravanIdRLogo:null}
                                            disabled={!editable}                                            
                                            type="text"
                                            placeholder={t('cv-forms.cow.mother_id')}
                                            mask={(motherEarIdValidatorMode==1)?formMasks.caravanEIdMask:(motherEarIdValidatorMode==2)?formMasks.caravanIdMask:(motherEarIdValidatorMode==3)?formMasks.caravanIdMaskR:null}
                                            normalize={(value) => value.toUpperCase()}
                                            validate={getValidatorsMotherEarId()}
                                            onChange={updateMotherEarId}
                                        />
                                        
                                        
                                        </div>
                                        <div style={{marginLeft:"10px"}}>
                                        <span className="secondary__label">{t('cv-forms.cow.mother_genealogy_hba')}</span>
                                        <Field
                                            name="birth.genealogyMotherHBA"
                                            component={renderField}
                                            disabled={!editable}
                                            type="text"
                                            highlight={genealogyMotherHBAHighlight}
                                            placeholder={t('cv-forms.cow.mother_genealogy_hba')}
                                            
                                            normalize={(value) => value.toUpperCase()}                                    
                                        />
                                        </div>
                                        <div style={{marginLeft:"10px"}}>
                                        <span className="secondary__label">{t('cv-forms.cow.mother_genealogy_rp')}</span>
                                        <Field
                                            name="birth.genealogyMotherRP"
                                            component={renderField}
                                            disabled={!editable}
                                            type="text"
                                            placeholder={t('cv-forms.cow.mother_genealogy_rp')}
                                            highlight={genealogyMotherRPHighlight}
                                            normalize={(value) => value.toUpperCase()}                                    
                                        />
                                        </div>
                                        <div style={{marginLeft:"10px"}}>
                                        <span className="secondary__label">{t('cv-forms.cow.mother_genealogy_cc')}</span>
                                        <Field
                                            name="birth.genealogyMotherCC"
                                            component={renderField}
                                            disabled={!editable}
                                            type="text"
                                            placeholder={t('cv-forms.cow.mother_genealogy_cc')}
                                            highlight={genealogyMotherCCHighlight}
                                            normalize={(value) => value.toUpperCase()}                                    
                                        />
                                        </div>
                                        <div style={{marginLeft:"10px"}}>
                                        <span className="secondary__label">{t('cv-forms.cow.mother_genealogy_name')}</span>
                                        <Field
                                            name="birth.genealogyMotherName"
                                            component={renderField}
                                            disabled={!editable}
                                            type="text"
                                            placeholder={t('cv-forms.cow.mother_genealogy_name')}
                                            highlight={genealogyMotherNameHighlight}
                                            normalize={(value) => value.toUpperCase()}                                    
                                        />
                                        </div>
                                    </div>
                                    <div className="form__form-group-field row_direction">
                                                                        
                                        <div>
                                        <span className="secondary__label">{t('cv-forms.cow.genealogy_mother_certificate_identifier')}</span>
                                        <Field
                                            name="birth.genealogyMotherCertificateIdentifier"
                                            component={renderField}
                                            disabled={!editable}
                                            type="text"
                                            placeholder={t('cv-forms.cow.genealogy_mother_certificate_identifier')}
                                            
                                            normalize={(value) => value.toUpperCase()}                                    
                                        />
                                        </div>
                                        <div style={{marginLeft:"10px"}}>
                                        <span className="secondary__label">{t('cv-forms.cow.genealogy_mother_certificate')}</span>
                                        {
                                            (!showImages || editable) &&
                                            <Field
                                                name="genealogyMotherCertificate"
                                                component={renderCVFileInputField}
                                                disabled={!editable}
                                                acceptedFormats={'.pdf, .docx, .doc, .xlsx, .xls, .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .png, .gif, .tiff, .tif, .bmp, .dib'}
                                            />
                                        }
                                        
                                        {detailFileFieldNoImage(t,editable,showImages,props,'genealogyMotherCertificateFile','genealogyMotherCertificate',deleteFile)}
                                        </div>                           
                                        
                                        <div style={{marginLeft:"10px"}}>
                                            <span className="form__form-group-label">{t('cv-forms.cow.DNA_analysis_mother')}</span>
                                            
                                                {
                                                    (!showImages || editable) &&
                                                    <Field
                                                        name="DNA_analysis_mother"
                                                        component={renderCVFileInputField}
                                                        disabled={!editable}
                                                        acceptedFormats={'.pdf, .docx, .doc, .xlsx, .xls, .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .png, .gif, .tiff, .tif, .bmp, .dib'}
                                                    />
                                                }
                                                
                                                {detailFileFieldNoImage(t,editable,showImages,props,'dnaAnalysisFile_mother','DNA_analysis_mother',deleteFile)}
                                            
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                </TabPane>
                            </TabContent>
                            </Col>
                        </Row>
                                                                        
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.establishment')}</span>
                            <Helpi 
                                        title="" 
                                        size="20"
                                        iconColor="lightgrey"
                                        fontColor="white"/>
                            <div className="form__form-group-field">
                                <Field
                                    name="establishment"
                                    component={renderSelectField}
                                    validate={formValidators.validateRequired}
                                    //disabled={true}
                                    type="text"
                                    placeholder={t('cv-forms.cow.establishment')}
                                    onChange={changeEstablishmentValue}
                                    options={
                                        [
                                            ...allEstablishments.map(est => ({
                                                value: est._id,
                                                label: est.name
                                            }))
                                        ]
                                    }
                                />
                            </div>
                        </div>
                        { showLotField() &&
                        <div className="form__form-group">
                            <span className="form__form-group-label">{t('cv-forms.cow.lot')}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="establishmentLot"
                                    component={renderSelectField}
                                    type="text"
                                    placeholder={t('cv-forms.cow.non-assigned-lot')}
                                    disabled={!editable || selectedEstablishmentLotMode  === "lotModeDisabled"}
                                    onChange={getSelectedLot}
                                    options={
                                        [
                                            ...validLots.map(lot => ({
                                                value: lot._id,
                                                label: lot.name
                                            }))
                                        ]
                                    }
                                />
                            </div>
                        </div>
                        }
                        { selectedLot === "newLot" &&
                            <div className="form__form-group">
                                <span className="form__form-group-label">{t('cv-forms.events.lot_event.new_lot')}</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="new_lot"
                                        component={renderField}
                                        hidden={true}
                                        disabled={selectedLot != "newLot"}
                                    />
                                </div>
                            </div>
                        }
                        <div className="edit-buttons" >
                            {!submittable && 
                            <label><h4>Aguarde...</h4></label>
                            }
                            {submittable &&
                            <ButtonToolbar className="form__button-toolbar" >
                                    { !editable && <Button 
                                        color="primary" 
                                        outline
                                        type="button" 
                                        onClick={() => props.setEditable(!editable)}>
                                        {t('common.edit')}</Button>
                                    }
                                    { editable && <CVButton
                                        outline 
                                        color="success"
                                        type="submit"
                                        disabled={saveDisable}
                                        onClick={() => showErrors(props.invalid)}
                                        pending={props.pending}
                                        >{t('common.save')}</CVButton>
                                    }
                                    { editable && <Button 
                                        type="button"
                                        outline
                                        color="danger"
                                        size="sm"
                                        onClick={() => {
                                            if(props.isNew){
                                                props.history.push('/animals/table');
                                            }else{
                                            props.setEditable(!editable)
                                            }
                                            }
                                            }>
                                        {t('common.cancel')}</Button>
                                    }
                                    { !editable && <Button 
                                        color="primary" 
                                        outline={true} 
                                        type="button" 
                                        onClick={() => props.history.goBack()}>
                                        {t('common.back')}</Button>
                                    }
                            </ButtonToolbar>
                            }
                        </div>
                    </form>
                </CardBody>
            </Card>
            {tierModal()}
            {genealogyWizardModal()}
            {mailModal()}
        </Col>
        )
};

const selector = formValueSelector('cow-form');

const mapStateToProps = (state, props) => ({
    initialValues: props.initialValues || {},
    availableCowBreeds: state.generalData.availableCowBreeds,
    availableProductiveSystems: state.user.selectedEstablishment ?
        state.user.selectedEstablishment.productiveSystems ?
            state.user.selectedEstablishment.productiveSystems:
            []:
        [],
    selectedEstablishment: state.user.selectedEstablishment,
    allAvailableProductiveSystems: state.constant.availableProductiveSystems,
    earId: selector(state, 'caravan_id'),
    
    genealogywizardMode: selector(state, 'genealogywizard.mode'),
    genealogywizardRP: selector(state,'genealogywizard.rp'),
    genealogywizardCC: selector(state,'genealogywizard.cc'),
    genealogywizardHBA: selector(state,'genealogywizard.hba'),
    genealogyMotherHBA: selector(state,'birth.genealogyMotherHBA'),
    genealogyFatherHBA: selector(state,'birth.genealogyFatherHBA'),
    genealogyMotherName: selector(state,'birth.genealogyMotherName'),
    genealogyFatherName: selector(state,'birth.genealogyFatherName'),
    genealogyHBA: selector(state,'birth.genealogyHBA'),
    genealogyCC: selector(state,'birth.genealogyCC'),
    genealogyRP: selector(state,'birth.genealogyRP'),
    genealogyName: selector(state,'birth.genealogyName'),
    category: selector(state, 'category'),
    breed: selector(state,'breed'),
    
    signalOnEarImage: selector(state, 'signal_on_ear'),
    animalImage: selector(state, 'animal_image'),
    genealogyDEPSImage: selector(state,'genealogy_DEPS'),
    dnaAnalysisFile: selector(state, 'DNA_analysis'),
    dnaAnalysisFile_mother: selector(state, 'DNA_analysis_mother'),
    dnaAnalysisFile_father: selector(state, 'DNA_analysis_father'),
    genealogyCertificateFile: selector(state, 'genealogyCertificate'),
    genealogyMotherCertificateFile: selector(state, 'genealogyMotherCertificate'),
    genealogyFatherCertificateFile: selector(state, 'genealogyFatherCertificate'),
    dentitionValues: state.constant.dentitionValues,
    establishmentDetails: state.user.establishmentDetails,
    establishmentLots: state.user.establishmentDetails.animalLots.lots,
    selectedEstablishmentLotMode:  state.user.establishmentDetails.animalLots.mode?
        state.user.establishmentDetails.animalLots.mode: "lotModeDisabled",
    allEstablishments: state.user.allEstablishments,
    animalsCountSelectedCompanyScope: state.user.animalsCountSelectedCompanyScope,    
    SRAConnectionStatus : state.servicesStatus.SRAConnectionStatus
});

const mapDispatchToProps = (dispatch) => ({
    sendMailTierRequest: (data, successCallback, errorCallback) => dispatch(actions.session.sendMailTierRequest(data, successCallback, errorCallback)),
    getCompanyScopeAnimalsCount: (facilityId) => dispatch(actions.user.getCompanyScopeAnimalsCount(facilityId)),
    getAvailableCowBreeds: () => dispatch(GeneralDataActions.getAvailableCowBreeds()),
    getAvailableProductiveSystems: () => dispatch(actions.constant.getAvailableProductiveSystems()),
    getDentitionValues: () => dispatch(actions.constant.getDentitionValues()),
    changeFieldValue: (field, value) => dispatch(change('cow-form', field, value)),
    getUserEstablishmentById: (currentEstablishment, callback) =>
        dispatch(actions.user.getEstablishmentById(currentEstablishment, callback)),
    getGenealogy: (data,successCallback, errorCallback) => dispatch(actions.animal.getGenealogy(data, successCallback, errorCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'cow-form',
    touchOnChange: true // this is set because if not the 'touched' props on DateInputField was always false
})(withTranslation('common')(withRouter(CowForm))));
