import {

    // GET_SRA_CONNECTION_STATUS,
    GET_SRA_CONNECTION_STATUS_RESPONSE,
    GET_SRA_CONNECTION_STATUS_ERROR
} from "../actions/servicesStatusActions";


const initialState = {
    
    SRAConnectionStatus: {
        status: true,
        error : undefined
    }
    
    
    
};

const servicesStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case GET_SRA_CONNECTION_STATUS_RESPONSE:
            return {
                ...state,
                SRAConnectionStatus: {
                    status: action.response
                }
            };

        case GET_SRA_CONNECTION_STATUS_ERROR:
            return {
                ...state,
                SRAConnectionStatus: {
                    status: false,
                    error: action.message
                }
            };
        default:
            return state
    }
};
export default servicesStatusReducer;