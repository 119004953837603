import React from 'react';
import { Container, Row } from 'reactstrap';
import ChangeProductiveSystemEventForm from "../NewEventForms/ChangeProductiveSystemEventForm"
import { connect } from 'react-redux';
import actions from '../../../../redux/actions';
import {useAlert} from "react-alert";
import { withTranslation } from "react-i18next";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

const NewChangeProductiveSystemEventContainer = (props) => {
    const alert = useAlert();

    const addChangeProductiveSystemEvent = (values) => {
        props.submitProductiveSystemChangeEvent(values, props.facilityId, () => {
            alert.success(props.t('events.change_productive_system.alerts.event_added'));
            props.history.push('/events/change_productive_system/table');
        }, (errorMessage) => {
            ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
        });
    };

    return (
        <Container>
          <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
            <Row>
                <ChangeProductiveSystemEventForm
                  onSubmit={addChangeProductiveSystemEvent}
                  pending={props.addCowPending}
                  editable/>
            </Row>
        </Container>
    )
};

const mapStateToProps = (state) => ({
    addCowPending: state.ui.addCowPending, // todo change this!
    facilityId: state.user.selectedEstablishment._id
});

const mapDispatchToProps = (dispatch) => ({
    submitProductiveSystemChangeEvent: (values, facilityId, successCallback, errorCallback) =>
        dispatch(actions.event.submitProductiveSystemChangeEvent(values, facilityId, successCallback, errorCallback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(NewChangeProductiveSystemEventContainer));