import React, {useState} from 'react';
import SearchIcon from "mdi-react/SearchIcon";
import { ClipLoader } from "react-spinners";

const SearchInput = (props) => {
    let [inputValue, setInputValue] = useState('');

    const search = () => {
        props.search(inputValue);
        //setInputValue('')
    };

    const { placeholder, loading } = props;

    const handleSubmit = (event) => {
        search(inputValue);
        event.preventDefault();
    };

    return (
        <form className="search_input" onSubmit={handleSubmit}>
            <input placeholder={placeholder}
                   value={inputValue} onChange={(event) => setInputValue(event.target.value)}/>
            <button type="submit" disabled={!inputValue || (inputValue === "")}>
                {
                    loading ?
                        <ClipLoader size={15} loading={true} color={"#123abc"}/>:
                        <SearchIcon/>
                }
            </button>
        </form>
    )
};

export default SearchInput