import React from 'react';
import {withTranslation} from 'react-i18next';
import FullRegisterForm from './components/FullRegisterForm';
import {connect} from "react-redux";
import cvLogo from '../../../shared/img/logo/cv-logo-long.png';
import sessionActions from "../../../redux/actions/sessionActions";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

const Register = (props) => {

    const _handleFullRegisterRequest = (values) => {
        props.fullRegisterRequest(props.userId, values, () =>
            props.history.push('/', {fullRegisterSuccessful: true}), (errorMessage) => {
                ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
            });
    };

    return (
        <div className="account">
            <div className="account__wrapper">
              <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
                <div className="account__card full_register_card">
                    <div className="account__head">
                        <img src={cvLogo} alt="cv-logo"/>
                    </div>
                    <FullRegisterForm
                        onSubmit={(values) => _handleFullRegisterRequest(values)}
                        registerPending={props.registerPending}
                        registerError={props.registerError}
                        registerErrorMessage={props.registerErrorMessage}
                    />
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        registerPending: state.ui.registerPending,
        registerError: state.ui.registerError,
        registerErrorMessage: state.ui.registerErrorMessage,
        userId: state.user.id
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fullRegisterRequest: (userId, info, successCallback, errorCallback) =>
        dispatch(sessionActions.fullRegisterRequest(userId, info, successCallback, errorCallback))
    }
};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Register));
