import api from '../../api/index';
import {
    GET_USERS_PAGE, GET_ROLES_PAGE, GET_GENERAL_DATA_TITLES_PAGE,
    GET_GENERAL_DATA_INFO, EDIT_GENERAL_DATA_INFO, ADD_GENERAL_DATA_INFO,
    BLOCK_USER, GET_USER_BY_ID, EDIT_USER_BY_ID, ADD_GENERAL_DATA
} from '../actions/adminActions';
import actions from '../actions';
import mapper from './mappers/adminMapper';

const admin = ({dispatch}) => next => action => {
    next(action);
    switch (action.type) {
        case GET_USERS_PAGE:
            api.getUsers(action.pageNumber, action.pageSize)
                .then(res => {
                    dispatch(actions.admin.getUsersPageResponse(mapper.response.getUsers(res)))
                })
                .catch(err => {
                    dispatch(actions.admin.getUsersPageError(err))
                });
            break;
        case GET_ROLES_PAGE:
            api.getRoles(action.pageNumber, action.pageSize)
                .then(res => {
                    dispatch(actions.admin.getRolesPageResponse(mapper.response.getRoles(res)))
                })
                .catch(err => {
                    dispatch(actions.admin.getRolesPageError(err))
                });
            break;
        case GET_GENERAL_DATA_TITLES_PAGE:
            api.getGeneralDataTitlesPage(action.pageNumber, action.pageSize)
                .then(res => {
                    dispatch(actions.admin.getGeneralDataTitlesPageResponse(mapper.response.getGeneralDataTitlesPage(res)))
                })
                .catch(err => {
                    dispatch(actions.admin.getGeneralDataTitlesPageError(err))
                });
            break;
        case GET_GENERAL_DATA_INFO:
            api.getGeneralDataInfo(action.id)
                .then(res => {
                    dispatch(actions.admin.getGeneralDataInfoResponse(res))
                })
                .catch(err => {
                    dispatch(actions.admin.getGeneralDataInfoError(err))
                });
            break;
        case EDIT_GENERAL_DATA_INFO:
            api.editGeneralDataInfo(action.id, action.prevValue, action.newValue, action.filter)
                .then(res => {
                    dispatch(actions.admin.editGeneralDataInfoResponse(res));
                })
                .catch(err => {
                    dispatch(actions.admin.editGeneralDataInfoError(err))
                });
            break;
        case ADD_GENERAL_DATA_INFO:
            api.addGeneralDataInfo(action.id, action.value, action.filter)
                .then(res => {
                    dispatch(actions.admin.addGeneralDataInfoResponse(res))
                })
                .catch(err => {
                    dispatch(actions.admin.addGeneralDataInfoError(err))
                });
            break;
        case ADD_GENERAL_DATA:
            api.addGeneralData(action.generalData)
                .then(res => {
                    dispatch(actions.admin.addGeneralDataResponse(mapper.response.addGeneralData(res)))
                })
                .catch(err => {
                    dispatch(actions.admin.addGeneralDataError(err))
                });
            break;
        case BLOCK_USER:
            api.blockUser(action.userId)
                .then(res => {
                    dispatch(actions.admin.blockUserResponse(res));
                    if(action.callback) action.callback()
                })
                .catch(err => {
                    dispatch(actions.admin.blockUserError(err))
                });
            break;
        case GET_USER_BY_ID:
            api.getUserById(action.userId)
                .then(res => {
                    dispatch(actions.admin.getUserByIdResponse(res))
                })
                .catch(err => {
                    dispatch(actions.admin.getUserByIdError(err))
                });
            break;
        case EDIT_USER_BY_ID:
            api.editUserById(action.userId, action.userModify)
                .then(res => {
                    dispatch(actions.admin.editUserByIdResponse(res));
                    if(action.callback) action.callback();
                })
                .catch(err => {
                    dispatch(actions.admin.editUserByIdError(err))
                })
    }
};

export default admin;