import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, CardTitle, CardText } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';
import actions from '../../redux/actions/index';
import {ROLES} from "../../constants";
import {Collapse} from "reactstrap";
import {ButtonBase} from "@material-ui/core";
import DownIcon from "mdi-react/ChevronDownIcon";
import cowTransparent from "../../shared/img/cow-transparent.gif";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import setting from '../../util/settings';
import Tooltip from 'react-power-tooltip';
import MatTable from './components/MatTable';
import classnames from 'classnames';
import InfoIcon from 'mdi-react/InfoCircleIcon';




const BI = (props) => {
    const {  t, BI, reduxState, allCompanies, establishments, establishmentLots, templates, certificate_types } = props;    
    const [editTemplateMode, setEditTemplateMode] = useState(false);
    const [selectedTemplate, selectTemplate] = useState([]);
    const [selectedCompany, selectCompany] = useState([]);
    const [companies, setCompanies] = useState([]);    
    const [allTemplates, setAllTemplates] = useState([]);    
    const [allEstablishmentLots, setAllEstablishmentLots] = useState([]);    
    const [allEstablishment, setAllEstablishment] = useState([]);    
    const [selectedEstablishment, selectEstablishment] = useState([]);
    const [selectedLot, selectLot] = useState([]);    
    const [visibilitySaveButton, setVisibilitySaveButton] = useState(false);
    const [templateUrl, setTemplateUrl] = useState([]);
    const [iframeTemplate, setIframeTemplate] = useState([]);
    const [iframeDisable, setIframeDisable] = useState(true);    
    const [visibilityEditButton, setVisibilityEditButton] = useState(false);
    const [disableSaveButton, setDisableSaveButton] = useState(false);
    const [showCertificates, setShowCertificates] = useState(false);
    const [showTooltipAdm, setShowTooltipAdm] = useState(false);
    
    
    const [tooltipEditTemplate, setTooltipEditTemplate] = useState(false);    

    const [activeTab, setActiveTab] = useState('1');
    const [tableActive, setTableActive] = useState(true);

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }
    
    
    useEffect(() => {

        const handler = event => {

            const data = JSON.parse(event.data)   
            if (data) {                               
                if (data.message=='OK') {
                    //props.history.push('/templates/microsite')            

                    /*if(allCompanies && allCompanies.length > 0) {            
                        setAllTemplates(templates);    
                        selectTemplate('');
                        setCompanies(allCompanies);
                        setAllEstablishmentLots(establishmentLots);
                        selectCompany('');
                        selectEstablishment('');
                        selectLot('');
                        setAllEstablishment([]);
                        setAllEstablishmentLots([]);            
                        setTemplateUrl(false);
                        setIframeDisable(true);
                    }*/

                }            
                if (data.message=='setSaveButton') {
                    //setVisibilitySaveButton(true);
                    setVisibilityEditButton(true);
                }
                if (data.message=='setDisableSaveButton') {                
                    setDisableSaveButton(true);
                }
                if (data.message=='unsetDisableSaveButton') {               
                    setDisableSaveButton(false);
                }            
                if (data.message=='setIframeEnable') {
                    setIframeDisable(false);
                }
            }
            
            window.addEventListener("message", handler)        
        }
        
    }, [allCompanies]);

    
    useEffect(() => {
        if(props.roles.some(rol => (rol.name) === ROLES.TITULAR )) props.getAllCompanies();
    }, []);

    useEffect(() => {
        if(props.roles.some(rol => (rol.name) === ROLES.SURROGATE )) props.getAssignedCompanies(props.userId);
    }, []);


    useEffect(() => {
        setAllEstablishmentLots(establishmentLots);
    }, [establishmentLots]);

    useEffect(() => {                
                
        if(allCompanies && allCompanies.length > 0) {            
            setAllTemplates(templates);    
            setCompanies(allCompanies);
            setAllEstablishmentLots(establishmentLots);
            selectCompany('');
            selectEstablishment('');
            selectLot('');
            setAllEstablishment([]);
            setAllEstablishmentLots([]);            
        }
    }, [allCompanies]);
   
    
    const changeTemplate = (event) => {        
        let template = event.target.value;
        selectTemplate(template);        
        
        setAllEstablishment([]);
        setAllEstablishmentLots([]);
        
        selectCompany('');
        selectEstablishment('');
        selectLot('');

        validateEditButtom('','','');
        setTooltipEditTemplate(template.tooltipEditTemplate);
        setDisableSaveButton(false);
    };

    const changeCompany = (event) => {        
        let company = event.target.value;
        selectCompany(company);
                
        props.getEstablishmentsByCompanyId(company._id);                
        setAllEstablishmentLots([]);

        selectEstablishment('');
        selectLot('');

        validateEditButtom(company,'','');
        setDisableSaveButton(false);
        setShowCertificates(false);
    };

    const changeEstablishment = (event) => {        
        let establishment = event.target.value;        
        selectEstablishment(establishment);                

        props.getUserEstablishmentById(establishment._id, ()=> {});
        
        selectLot('');

        validateEditButtom(selectedCompany,establishment,'');
        setDisableSaveButton(false);
        setShowCertificates(true);

        setTableActive(false);
        props.getFacilityCertificates(establishment._id,(response) => {             
            setTableActive(true);
        }, (errorMessage) => {                     
            //ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
        });
    };

    const changeLot = (event) => {        
        let lot = event.target.value;
        selectLot(lot);                

        validateEditButtom(selectedCompany,selectedEstablishment,lot);
        setDisableSaveButton(false);
    };

    const validateEditButtom = (company,establishment,lot) => {
        let validate = false;
        if (selectedTemplate.scope==1) {
            if (company!='') {
                validate = true;
            }
            setTemplateUrl(selectedTemplate.url+"/"+company._id)                        
        }
        if (selectedTemplate.scope==2) {                        
            if (company!='' && establishment!='') {
                validate = true;
            }            
            setTemplateUrl(selectedTemplate.url+"/"+establishment._id)
        }
        if (selectedTemplate.scope==3) {
            if (company!='' && establishment!='' && lot!='') {
                validate = true;
                setTemplateUrl(selectedTemplate.url+"/"+lot._id)
            }
        }
        setEditTemplateMode(validate);        
        setIframeDisable(true);        
        setVisibilityEditButton(false);        
    }

    const setRef = (frame) => {
        console.log("frame",frame);
        setIframeTemplate(frame);        
    }
    
    const saveTemplate = (save) => {                
        iframeTemplate.contentWindow.postMessage(
        JSON.stringify({
            error: false,
            message: "saveTemplate"
        }),"*");        
    }

    const CancelTemplate = () => {                
        if(allCompanies && allCompanies.length > 0) {            
            setAllTemplates(templates);    
            selectTemplate('');
            setCompanies(allCompanies);
            setAllEstablishmentLots(establishmentLots);
            selectCompany('');
            selectEstablishment('');
            selectLot('');
            setAllEstablishment([]);
            setAllEstablishmentLots([]);            
            setTemplateUrl(false);
            setIframeDisable(true);
        }  
    }

    useEffect(() => {        
        
      }, [props.certificates]);


    return (
        <Container style={{height:"80vh",marginTop:"-15px"}}>                                                                                                        
            
            <Row>
                
            </Row>            
            <Row>
                { (false) &&    
                <Col md={8} xl={3} lg={3} xs={8}>
                    <InputLabel id="template-select-label" style={{marginBottom:"0px", marginTop:"5px", fontSize:"14px"}}>Plantilla</InputLabel><br/>
                    <FormControl>                        
                        
                        <Select
                        labelId="template-select-label"
                        id="template-select"
                        onChange={changeTemplate}
                        value={selectedTemplate}
                        displayEmpty style={{fontSize:"12px"}}
                        >
                        <MenuItem style={{fontSize:"12px"}} value='' disabled>Seleccione una Plantilla</MenuItem>                        
                        { allTemplates.map((template) => (
                        <MenuItem style={{fontSize:"12px"}} disabled={(template.type!=1 && template.type!=4)} value={template}>{template.name}</MenuItem>                        
                        ))
                        }
                        </Select>
                    </FormControl>
                </Col>
                }
                <Col md={8} xl={3} lg={3} xs={8}>
                    <h4 style={{fontSize:"14px"}} onMouseOver={() => setShowTooltipAdm(true)} onMouseLeave={() => setShowTooltipAdm(false)}>Administración de Certificados <InfoIcon size="22px"/></h4>
                    <Tooltip
                        show={showTooltipAdm}
                        position="bottom left"
                        moveDown="-25px"                                                
                        moveRight="10px"
                        fontSize="12px"                        
                        textBoxWidth="600px"                        
                        padding="10px"
                        static>
                            <span>Los certificados a cargar son los pertenecientes a un Establecimiento y serán parte del token mientras permanezca en ese establecimiento. Evite cargar certificados de animales en particular y/o tratamientos</span>
                    </Tooltip>
                    </Col>
                { ( true ) && 
                    
                    <Col md={8} xl={2} lg={2} xs={8}>
                        <InputLabel id="company-select-label" style={{marginBottom:"0px", marginTop:"5px", fontSize:"14px"}}>Empresa</InputLabel><br/>
                        <FormControl>                        
                            
                            <Select
                            labelId="company-select-label"
                            id="company-select"
                            onChange={changeCompany}
                            value={selectedCompany}
                            displayEmpty style={{fontSize:"12px"}}
                            >
                            <MenuItem style={{fontSize:"12px"}} value='' disabled>Seleccione una Empresa</MenuItem>                        
                            { companies.map((company) => (
                            <MenuItem style={{fontSize:"12px"}} value={company}>{company.name}</MenuItem>                        
                            ))
                            }
                            </Select>
                        </FormControl>
                    </Col>
                }
                { ( true ) && 
                    <Col md={8} xl={3} lg={3} xs={8}>
                        <InputLabel id="establishment-select-label" style={{marginBottom:"0px", marginTop:"5px", fontSize:"14px"}}>Establecimiento</InputLabel><br/>
                        <FormControl>
                            
                            <Select
                            labelId="establishment-select-label"
                            id="establishment-select"
                            onChange={changeEstablishment}
                            value={selectedEstablishment}
                            disabled={!tableActive}
                            displayEmpty style={{fontSize:"12px"}}
                            >
                            <MenuItem style={{fontSize:"12px"}} value='' disabled>Seleccione un Establecimiento</MenuItem>                        
                            { establishments.map((establishment) => (
                            <MenuItem style={{fontSize:"12px"}} value={establishment}>{establishment.name}</MenuItem>                        
                            ))
                            }
                            </Select>
                        </FormControl>
                    </Col>
                }
                { ( false ) && 
                    <Col md={8} xl={2} lg={2} xs={8}>
                        <InputLabel id="lots-select-label" style={{marginBottom:"0px", marginTop:"5px", fontSize:"14px"}}>Lotes</InputLabel><br/>
                        <FormControl>                        
                            
                            <Select
                            labelId="lots-select-label"
                            id="lots-select"
                            onChange={changeLot}
                            value={selectedLot}
                            displayEmpty style={{fontSize:"12px"}}
                            >
                            <MenuItem style={{fontSize:"12px"}} value='' disabled>Seleccione un Lote</MenuItem>                        
                            { allEstablishmentLots.map((lot) => (
                            <MenuItem style={{fontSize:"12px"}} value={lot}>{lot.name}</MenuItem>                        
                            ))
                            }
                            </Select>
                        </FormControl>
                    </Col>
                }
                { (false) && 
                    <Col md={8} xl={2} lg={2} xs={8}>                                                
                        <Button style={{marginTop:"18px", fontSize:"12px", padding:"8px"}} variant="contained"  color="primary" onClick={()=> setIframeDisable(false)}>
                        Editar Plantilla                           
                        </Button>       
                        <Tooltip
                        show={true}
                        position="bottom center"
                        moveDown="5px"                        
                        moveLeft="20px"                        
                        fontSize="12px"
                        textBoxWidth="250px"
                        static>
                            <span>{tooltipEditTemplate}</span>
                        </Tooltip>              
                        
                    </Col>
                }
                { (!iframeDisable) &&
                    <Col md={8} xl={3} lg={3} xs={8}>                                                                        
                        
                        <Button disabled={(disableSaveButton)} style={{marginTop:"18px", fontSize:"12px", padding:"8px"}} variant="contained"  color="secondary" onClick={()=> saveTemplate(true)}>
                        Guardar Cambios
                        </Button>                       
                        &nbsp;
                        <Button style={{marginTop:"18px", fontSize:"12px", padding:"8px"}} variant="contained"  color="warning" onClick={()=> CancelTemplate()}>
                        Cerrar
                        </Button>                        
                    </Col>
                }
                
                
            </Row>
            
            


            <hr style={{marginTop:"4px", marginBottom:"4px"}}/>
            {(showCertificates) && 
                <Row>
                
                <Nav tabs style={{marginTop:"5px"}}>
                    <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1'); }}
                    >
                        CERTIFICADOS SANITARIOS
                    </NavLink>
                    </NavItem>
                    <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { toggle('2'); }}
                    >
                        CERTIFICADOS PRODUCTIVOS
                    </NavLink>
                    </NavItem>
                    <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => { toggle('3'); }}
                    >
                        CERTIFICADOS DE GESTION                        
                    </NavLink>
                    
                    </NavItem>
                    
                </Nav>
                
                <TabContent activeTab={activeTab} style={{width:"100%"}}>
                    <TabPane tabId="1" style={{width:"100%"}}>
                        {(tableActive) &&
                            <MatTable title="CERTIFICADOS SANITARIOS" certificates={props.certificates} certificate_types={certificate_types.sanitary} establishment={selectedEstablishment._id}/>
                        }
                        {(!tableActive) &&
                        <div style={{width:"100%","textAlign":"center", marginTop:"50px"}}><img className="cow__button_loader" style={{width:"100px"}} alt="cow" src={cowTransparent}/></div>
                        }
                    </TabPane>
                    <TabPane tabId="2" style={{width:"100%"}}>
                        {(tableActive) &&
                            <MatTable title="CERTIFICADOS PRODUCTIVOS" certificates={props.certificates} certificate_types={certificate_types.productive} establishment={selectedEstablishment._id}/>
                        }
                        {(!tableActive) &&
                        <div style={{width:"100%","textAlign":"center", marginTop:"50px"}}><img className="cow__button_loader" style={{width:"100px"}} alt="cow" src={cowTransparent}/></div>
                        }
                    </TabPane>
                    <TabPane tabId="3" style={{width:"100%"}}>
                        {(tableActive) &&
                            <MatTable title="CERTIFICADOS DE GESTION" certificates={props.certificates} certificate_types={certificate_types.management} establishment={selectedEstablishment._id}/>
                        }
                        {(!tableActive) &&
                        <div style={{width:"100%","textAlign":"center", marginTop:"50px"}}><img className="cow__button_loader" style={{width:"100px"}} alt="cow" src={cowTransparent}/></div>
                        }
                    </TabPane>
                </TabContent>
                </Row>
            }


            {(showCertificates) &&                                                                          
            <Row style={{marginTop:"10px"}}>
                <Col md={12} xl={12} lg={12} xs={12}>                                
                
                </Col>
            </Row>
            }
            {(showCertificates) &&                                                                          
            <Row style={{marginTop:"10px"}}>
                <Col md={12} xl={12} lg={12} xs={12}>                                
                
                </Col>
            </Row>
            }
            {(showCertificates) &&                                                                          
            <Row style={{marginTop:"10px"}}>
                <Col md={12} xl={12} lg={12} xs={12}>                                
                
                </Col>
            </Row>
            }
            

            {(false) &&
            <Row style={{marginTop:"10px", pointerEvents: iframeDisable ? 'none' : 'auto', opacity: iframeDisable ? "0.2" : '1'}}>
                <Col style={{height:"72vh"}} md={12} xl={12} lg={12} xs={12}>
                    <iframe ref={setRef} allowfullscreen="1" style={{border:'3px dashed #616161',padding:"3px"}} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" src={(editTemplateMode) && templateUrl} id="widget2" data-title="Tutorial - Alta de Animales" width="100%" height="100%" frameborder="0"></iframe>
                </Col>                                                                              
            </Row>
            }
        </Container>
    )
};

const mapDispatchToProps = (dispatch) => ({
    getAvailableBI: () => dispatch(actions.bi.getAvailableBI()),
    getAllCompanies: () => dispatch(actions.template.getAllUserCompanies()),    
    selectCompany: (company) => dispatch(actions.template.selectCompany(company)),
    selectEstablishment: (establishment) => dispatch(actions.template.selectEstablishment(establishment)),
    getAssignedCompanies: (userId) => dispatch(actions.template.getAssignedCompanies(userId)),    
    getEstablishmentsByCompanyId: (companyId) => dispatch(actions.template.getUserEstablishmentsByCompany(companyId)),
    getUserEstablishmentById: (currentEstablishment, callback) =>
        dispatch(actions.template.getEstablishmentById(currentEstablishment, callback)),
    getFacilityCertificates: (facility, successCallback, errorCallback) => dispatch(actions.certificate.getFacilityCertificates(facility, successCallback, errorCallback)),
});

const mapStateToProps = (state) => ({
    allCompanies: state.template.allCompanies,
    selectedCompany: state.template.selectedCompany,
    establishments: state.template.allEstablishmentsByCompany,
    BI: state.bi.availableBI,    
    reduxState: state,
    roles: state.user.roles,
    userId: state.user.id,
    establishmentLots: state.template.establishmentDetails.animalLots.lots,
    templates: state.template.templates,
    certificate_types: state.certificate.certificate_types,
    certificates: state.certificate.certificates,
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(BI));
