import React, { PureComponent, useState, useEffect } from 'react';
import { Container, Row, Card, CardBody, Col } from 'reactstrap';
import {change, Field, reduxForm, formValueSelector} from 'redux-form';
import Table from '@material-ui/core/Table';
import cowTransparent from "../../../shared/img/cow-transparent.gif";
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableBody';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import MatTableHead from './MatTableHead';
import MatTableToolbar from './MatTableToolbar';
import { withTranslation } from 'react-i18next';
import { Button, ButtonToolbar, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import renderSelectField from "../../../shared/components/form/Select";
import renderField from "../../../shared/components/cv/Field";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import {renderCVFileInputField, renderCVPhotoInputField} from '../../../shared/components/cv/CVFileUpload';
import { renderDateInputField } from '../../../shared/components/cv/CVDatePicker';
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import CVButton from "../../../shared/components/cv/CVButton";
import { formValidators, subtract_x_years_to_date, formMasks } from '../../../util/index';
import actions from "../../../redux/actions";
import MaskedInput from "react-text-mask";
import i18n from '../../../translations/config/i18n';
import { getFacilityCertificates } from '../../../api/api.mixed';
import CertificateIcon from 'mdi-react/CertificateIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import CheckbookIcon from "mdi-react/CheckbookIcon";
import {initialize} from 'redux-form';

import './MatTable.css';




const CertificatesTable = (props) =>  {
  
  /*const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });*/

  const [debug, setDebug] = useState([]);
  const [fromDateRequired, setFromDateRequired] = useState(false);
  const [toDateRequired, setToDateRequired] = useState(false);
  const [detailRequired, setDetailRequired] = useState(false);
  const [pending, setPending] = useState(false);
  const [active, setActive] = useState(false);
  const [dateLabelFrom, setDateLabelFrom] = useState("");
  const [dateLabelTo, setDateLabelTo] = useState("");

  
 
  const {
    t,
    handleSubmit,
    onSubmit,
    establishment,
    certificates,
    certificate_types,
    initialValues,
    certificateSelected,    
    
    
  } = props



  useEffect(() => {    
    
    /*setActive(false);
    props.getFacilityCertificates(establishment,(response) => {             
      setActive(true);
    }, (errorMessage) => {                     
        //ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
    });*/
    setActive(true);
    

  }, [establishment]);

  useEffect(() => {    
    
  }, [initialValues]);

  useEffect(() => {    
    setToDateRequired(false)
    setFromDateRequired(false)
    setDetailRequired(false)
    setDateLabelFrom("");
    setDateLabelTo("");
    console.log(certificateSelected);
    for (var a in certificate_types) {
      if (certificateSelected) {
        if (certificate_types[a].id==certificateSelected.value) {
          if (certificate_types[a].dateLabel=='validity') {
            setDateLabelFrom("Inicio de Vigencia");
            setDateLabelTo("Fin de Vigencia");
          } else {
            setDateLabelFrom("Fecha de Vacunación");
            setDateLabelTo("Fin de Cobertura de Vacunación");
          }
          if (certificate_types[a].toDateRequired) {
            if (certificate_types[a].toDateRequired==true) {
              setToDateRequired(true)
            }
          }
          if (certificate_types[a].fromDateRequired) {
            if (certificate_types[a].fromDateRequired==true) {
              setFromDateRequired(true)
            }
          }
          if (certificate_types[a].detailRequired) {
            if (certificate_types[a].detailRequired==true) {
              setDetailRequired(true)
            }
          }
        }
      }
    }
  }, [certificateSelected]);

  useEffect(() => {        
    let tempRows = [];        
    
    let certificate_typles_ids = [];
    for (var a in certificate_types) {
      certificate_typles_ids.push(certificate_types[a].id);
    }
    for (var a in certificates) {      
      if (certificate_typles_ids.includes(certificates[a].type)) {
        
        tempRows.push(createData(certificates[a]._id, t(`certificates.${certificates[a].type}`),certificates[a].file,certificates[a].checksumFile,certificates[a].fromDate,certificates[a].toDate, certificates[a].animals, certificates[a].active, certificates[a].detail));
      }
    }            
    if (tempRows.length>0) {      
      setDebug(tempRows);      
    } else {
      setDebug([]);      
    } 
    
  }, [certificates]);
  
  const [modalVisible, setModalVisible] = useState(false);
  const [data, setData] = useState([
    { name: "Jon", job: "Software Dev", age: 29 }
  ]);

  const toggle = () => {
    setModalVisible(false);    
  }
  
  function createData(_id, type, file, checksumFile, fromDate, toDate, animals, active, detail) {
    if (fromDate) {
      let fromDateObj = new Date( Date.parse(fromDate));    
      fromDate = fromDateObj.toLocaleDateString();
    }
    if (toDate) {
      let toDateObj = new Date( Date.parse(toDate));    
      toDate = toDateObj.toLocaleDateString();
    }
    if (detail) {
      if (detail!='') {
        type+=" ("+detail+")"
      }
    }

    let labelActive = <a style={{backgroundColor:"orange", padding:"5px", fontWeight:"bold"}}>Inactivo</a>;
        if (active) {
          labelActive = <a style={{backgroundColor:"lightgreen",padding:"5px", fontWeight:"bold"}}>Activo</a>;
    }
    return { _id, type, file, checksumFile, fromDate, toDate, animals, labelActive};
  }

  const submitForm = (values) => {    
    //onSubmit({...values});
    setPending(true);
    props.editCertificate(null, values, () => {      
      setModalVisible(false);
      ToastsStore.success("Se ha creado/modificado el certificado", 5000);
      props.getFacilityCertificates(establishment,(response) => {             

        props.newCertificate(
        {
          foreignKey: props.establishment,
          entityType: "facility",
          type: null,
          fromDate: null,
          toDate: null,
          detail: null,
          _id: null,
        },(response) => {                       
          
          setPending(false);            
        }, (errorMessage) => {                     
            //ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
            console.log(errorMessage);
            setPending(false);            
        });


      }, (errorMessage) => {                     
          //ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
      });
    }, (errorMessage) => {              
        ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
        console.log("error",errorMessage);
        setPending(false);        
    });
    
  };

  const showErrors = (invalid) => {    
    if(invalid) ToastsStore.error(t('common.general_invalid_form'), 10000)
  }

  const editCertificate = (id) => {    
    props.getCertificateById(id,(response) => {             
      props.dispatch(initialize('certificate-form', response.results));
      setModalVisible(true);            
    }, (errorMessage) => {                     
        //ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
    });
    
  }

  const newCertificate = () => {
    props.newCertificate(
      {
        foreignKey: props.establishment,
        entityType: "facility",
        type: null,
        fromDate: null,
        toDate: null,
        detail: null,
        _id: null,
      },(response) => {                     
        setModalVisible(true);
        props.dispatch(initialize('certificate-form', {
          foreignKey: props.establishment,
          entityType: "facility",
          type: null,
          fromDate: null,
          toDate: null,
          detail: null,
          _id: null,
        }));
      }, (errorMessage) => {                     
          //ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
      });
  }

  /*const renderField = ({input, disabled, placeholder, type, meta: {touched, error, warning}, mask}) => (
      <div style={{width: '100%'}}>
          {!mask && <input style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type}/>}
          {mask && <MaskedInput style={{backgroundColor: 'white'}} disabled={disabled} {...input} placeholder={placeholder} type={type} mask={mask}/>}
          <div className="input-error-container">
              {touched && ((error && <span className="error-message">{i18n.t(`common:errors.${error}`)}</span>) || (warning &&
                  <span className="error-message">{warning}</span>))}
          </div>
      </div>
  );*/
 
  
  return (
    
    <Col md={12} lg={12}>        
        <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
        <Card>
          <CardBody className="cv__table">            
          <div>            
          <div className="card__title">
                <div className="cv-table-title">
                    <h5 className="bold-text"><CheckbookIcon/> {props.title}</h5>
                    {(active && !pending) && (
                    <div style={{display: 'flex'}}>
                      <a onClick={()=>newCertificate()}><PlusCircleOutlineIcon/></a>
                    </div>
                    )}
                </div>
            </div>            
          </div>
            <div style={{height:"300px",overflowY:"auto"}}>
            
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Tipo</TableCell>
                  <TableCell align="left" style={{width:"100px"}}>Archivo</TableCell>                  
                  <TableCell align="left">Inicio de Vigencia</TableCell>
                  <TableCell align="left">Fin de Vigencia</TableCell>
                  <TableCell align="left">Animales Afectados</TableCell>
                  <TableCell align="left">Estado</TableCell>
                </TableRow>
              </TableHead>
              
              <TableBody>
              {(active && !pending) && 
                debug.map((row) => (                  
                  <TableRow key={row._id}>
                    <TableCell component="th" scope="row">
                      <CertificateIcon/> {row.type}
                    </TableCell>
                    <TableCell align="left"><a href={row.file} target="_BLANK">Certificado</a> <a style={{fontSize:"10px", marginRight:"10px"}}>(checksum:{row.checksumFile})</a></TableCell>                    
                    <TableCell align="left">{row.fromDate}</TableCell>
                    <TableCell align="left">{row.toDate}</TableCell>
                    <TableCell align="left">{row.animals}</TableCell>
                    <TableCell align="center">{row.labelActive}</TableCell>
                    <TableCell><a onClick={()=> editCertificate(row._id)}><PencilIcon/></a></TableCell>
                  </TableRow>
                ))
              }
              {(!active || pending) && 
                <TableRow>
                  <TableCell colSpan="7">
                    <div style={{width:"100%","textAlign":"center", marginTop:"50px"}}><img className="cow__button_loader" style={{width:"100px"}} alt="cow" src={cowTransparent}/></div>
                  </TableCell>
                </TableRow>
              }
              </TableBody>
                            
            </Table>
            </div>
            
            
          </CardBody>
          
        </Card>

      <Modal size="lg" style={{maxWidth: '800px', width: '50%'}} isOpen={modalVisible} toggle={toggle}>
        <ModalHeader toggle={toggle}>Alta / Edición de Certificados</ModalHeader>
        <ModalBody>          
                                  <form className="form" onSubmit={handleSubmit(submitForm)}>
                                    
                                  <Container>
                                    
                                    <Row>
                                      <Col style={{textAlign:"left"}}><h5>Tipo de Certificado (obligatorio)</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col style={{textAlign:"left"}}>
                                      <Field
                                        name="type"
                                        component={renderSelectField}
                                        type="text"
                                        placeholder={t('cv-forms.certificate.type')}
                                        disabled={false}
                                        options={[
                                          ...certificate_types.map(certificate => ({
                                            value: certificate.id, label: t(`certificates.${certificate.id}`)
                                          }))
                                        ]}                                        
                                        validate={formValidators.validateRequired}
                                      />                                   
                                      </Col>
                                    </Row>
                                    {(detailRequired) &&
                                    <Row>
                                      <Col style={{textAlign:"left"}}><h5>Detalle (obligatorio)</h5></Col>
                                    </Row>
                                    }
                                    {(detailRequired) &&
                                    <Row>
                                      <Col style={{textAlign:"left"}}>                                      
                                        <Field
                                              name="detail"
                                          component={renderField}
                                          type="text"
                                          placeholder={'Detalle del Certificado'}                                        
                                          validate={formValidators.validateRequired}
                                        />
                                      </Col>
                                    </Row>
                                    }
                                    <Row>
                                      <Col style={{textAlign:"left"}}><h5>Archivo (obligatorio)</h5></Col>
                                    </Row>
                                    <Row>
                                      <Col style={{textAlign:"left"}}>
                                      {
                                          props.initialValues.file ?
                                          <div><CertificateIcon/>&nbsp;
                                          <a href={props.initialValues.file}>{t('cv-forms.certificate.download')}</a></div>:
                                              typeof (props.file) !== 'object'?
                                                  <label>{t('cv-forms.certificate.no_file')}</label>:
                                                  null
                                      }
                                      <Field
                                        name="file"
                                        component={renderCVFileInputField}
                                        disabled={false}
                                        validate={formValidators.validateRequired}
                                        acceptedFormats={'.pdf, .docx, .doc, .xlsx, .xls, .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .png, .gif, .tiff, .tif, .bmp, .dib'}
                                      />                                      
                                      </Col>
                                    </Row>
                                    <Row>
           
                                    </Row>
                                    <Row>
                                      <Col style={{textAlign:"left"}}>
                                      <h5>{dateLabelFrom} {
                                          (fromDateRequired) &&
                                          <a> (obligatorio)</a>
                                        }</h5>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col style={{textAlign:"left"}}>
                                      <Field
                                        name="fromDate"
                                        component={renderDateInputField}
                                        disabled={false}                                        
                                        showFutureDates={true}
                                        validate={(fromDateRequired) && formValidators.validateRequired}
                                        placeholder={t('cv-forms.cow.father_id')}                                                                      
                                    />                               
                                      </Col>
                                    </Row>
                                    <Row style={{marginTop:"20px"}}>
                                      <Col style={{textAlign:"left"}}>
                                      <h5>{dateLabelTo} {
                                        (toDateRequired) &&
                                        <a> (obligatorio)</a>
                                      }</h5>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col style={{textAlign:"left"}}>
                                      <Field
                                        name="toDate"
                                        component={renderDateInputField}
                                        disabled={false}                                        
                                        showFutureDates={true}
                                        validate={(toDateRequired) && formValidators.validateRequired}
                                        placeholder={t('cv-forms.cow.father_id')}                                                                      
                                    />                               
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col style={{textAlign:"left"}}>
                                        <CVButton
                                        color="primary"
                                        type="submit"
                                        onClick={()=> showErrors(props.invalid)}
                                        pending={pending}>{t('common.accept')}</CVButton>   
                                      </Col>
                                    </Row>

                                    
                                  </Container>
                                   
                                  </form>
        </ModalBody>        
      </Modal>
  </Col>
  
  );                      
  
}

const selector = formValueSelector('certificate-form');

const mapStateToProps = (state, props) => ({
  /*initialValues: props.initialValues ||
        {
          //DNA_analysis: "X",
          //foreignKey: "5f78e439da37b12dc7e8ed8b",
          foreignKey: props.establishment,
          entityType: "facility",
          type: "test"
        },*/
  initialValues: state.certificate.certificateDetails, 
  certificates: state.certificate.certificates,
  certificateSelected: selector(state, 'type'),
  file: selector(state, 'file'),
});


const mapDispatchToProps = (dispatch) => ({
  editCertificate: (animalId, data, successCallback, errorCallback) => dispatch(actions.certificate.editCertificate(data, successCallback, errorCallback)),
  getFacilityCertificates: (facility, successCallback, errorCallback) => dispatch(actions.certificate.getFacilityCertificates(facility, successCallback, errorCallback)),
  getCertificateById: (id, successCallback, errorCallback) => dispatch(actions.certificate.getCertificateById(id, successCallback, errorCallback)),
  newCertificate: (initValues, successCallback, errorCallback) => dispatch(actions.certificate.newCertificate(initValues, successCallback, errorCallback))
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'certificate-form',
  touchOnChange: true, // this is set because if not the 'touched' props on DateInputField was always false,
  enableReinitialize: true
})(withTranslation('common')(withRouter(CertificatesTable))));

