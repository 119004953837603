import React from 'react';
import {
  Card, CardBody, Col, Progress, Row
} from 'reactstrap';
import CowIcon from 'mdi-react/CowIcon';
import TransferRightIcon from 'mdi-react/TransferRightIcon';
import ArrowBottomBoldIcon from 'mdi-react/ArrowBottomBoldIcon';


const SimpleNumberValue = (props) => {
  const { value } = props;
  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card style={{paddingBottom:'20px'}}>
        <CardBody style={{padding:'10px'}}>          
        <Row>
        <Col md={3} xl={3} lg={3} xs={3}>
        {(props.icon=='Cow') && (
          <CowIcon style={{cursor:"default"}} size="40"/>
        )}
        {(props.icon=='Transfer') && (
          <TransferRightIcon style={{cursor:"default"}} size="40"/>
        )}
        {(props.icon=='ArrowBottomBold') && (
          <ArrowBottomBoldIcon style={{cursor:"default"}} size="40"/>
        )}          
        </Col>
        <Col md={9} xl={9} lg={9} xs={9}>
          <div className="dashboard__booking-total-container">
            <h5 className="dashboard__booking-total-title dashboard__booking-total-title--blue">
            {props.value}           
            </h5>            
          </div>
          <h5 className="dashboard__booking-total-description">{props.title}</h5>
          <div style={{display:'none'}} className="progress-wrap progress-wrap--small progress-wrap--pink-gradient progress-wrap--rounded">
            <p className="dashboard__booking-card-progress-label progress__label">87%</p>
            <Progress value={87} />
          </div>
        </Col>
        </Row>
        </CardBody>
      </Card>
    </Col>
  )
}

export default SimpleNumberValue;
