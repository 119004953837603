import {
    GET_LOCAL_WALLET_RESPONSE, GET_REMOTE_WALLET_RESPONSE
} from "../actions/walletActions";

const initialState = {
    decryptWallet: {
        keystore: undefined,
        pwDerivedKey: undefined,
        address: undefined,
    },
    encryptWallet: {
        local: undefined,
        remote: undefined
    }
};

const walletReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LOCAL_WALLET_RESPONSE:
            return {
                ...state,
                encryptWallet: {
                    local: action.response.wallet,
                    remote: undefined
                }
            };
        case GET_REMOTE_WALLET_RESPONSE:
            return {
                ...state,
                encryptWallet: {
                    local: undefined,
                    remote: action.response.wallet
                }
            };

        /*case SAVE_WALLET_RESPONSE:
        case GET_LOCAL_WALLET_RESPONSE:
        case GET_REMOTE_WALLET_RESPONSE:
            return  { ...action.response.wallet };
        case GET_WALLET_LOCATION_RESPONSE:
            return {...state, location: action.response};*/
        default:
            return state
    }
};
export default walletReducer;