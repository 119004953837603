import {
    LOG_OUT,
    LOGIN_RESPONSE,
    FULL_REGISTER_RESPONSE,
    SET_TOKENS
} from "../actions/sessionActions"

const initialState = {
    isAuthenticated: false,
    jwt: null,
    jwtExpiration: null,
    refreshToken: null,
    refreshExpiration: null
};

const sessionReducer = (state = initialState, action) => {
    switch(action.type){
        case LOGIN_RESPONSE:
            return { ...state, isAuthenticated: true };
        case SET_TOKENS:
            return { ...state, ...action.tokens };
        case FULL_REGISTER_RESPONSE:
            return state;
        case LOG_OUT:
            return initialState;
        default:
            return state;
    }

};

export default sessionReducer