import React, { PureComponent } from 'react';
import PropTypes  from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import { RTLProps } from '../../prop-types/ReducerProps';
import FileUploadDefault from './FileUploadDefault';
import FileUploadExtended from "./FileUploadExtended";

class FileUploadExtendedModal extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    rtl: RTLProps.isRequired,
    onSubmit: PropTypes.func,
    isOpen: PropTypes.bool,
    toggleModal: PropTypes.func,
    acceptedFormats: PropTypes.string,
    availableFacilities: PropTypes.object
  };

  static defaultProps = {
    title: ''
  };

  render() {
    const { title, rtl, onSubmit, isOpen, toggleModal, acceptedFormats, searchPlaceholder, uploadedFilesError,pending } = this.props;

    if(!isOpen) return null;

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        modalClassName={`${rtl.direction}-support`}
        className={`modal-dialog-tranfer`}
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={() => toggleModal(false)} />
          <h4 className="text-modal  modal__title">{title}</h4>
        </div>
        <div className="modal__body">
          <FileUploadExtended
            onSubmit={onSubmit}
            searchPlaceholder={searchPlaceholder}
            acceptedFormats={acceptedFormats}
            uploadedFilesError={uploadedFilesError}
            pending= {pending}
          />
        </div>
      </Modal>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
}))(FileUploadExtendedModal);
