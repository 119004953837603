import React, {useEffect, useState} from 'react';
import { withTranslation } from "react-i18next";
import CVTable from "../../Tables/CVTable/CVTable";
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon";
import { connect } from "react-redux";
import actions from '../../../redux/actions/index'
import { withRouter } from 'react-router-dom';
import MagnifyIcon from "mdi-react/MagnifyIcon";
import AccountPlusOutlineIcon from 'mdi-react/AccountPlusOutlineIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import {useAlert} from "react-alert";
import CVModal from "../../UI/Modals/components/CVModal";
import {ROLES} from "../../../constants";
import {CVBasicModalWithoutButtons} from "../../../shared/components/cv/CVBasicModal";
import ProfessionalList from "./ProfessionalList";
import AddNewProfessional from "./AddNewProfessional";
import AnimalLotsModal from "./AnimalLotsModal";
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy';
import CowIcon from "mdi-react/CowIcon";
import CalendarPlusIcon from "mdi-react/CalendarPlusIcon";
import { Redirect } from "react-router-dom";
import CVButton from "../../../shared/components/cv/CVButton";

const EstablishmentTable = (props) => {
    const { t, establishments, widthPercentage, currentPage, total, totalPages, pageSize} = props;
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAnimalLotsModal, setShowAnimalLotsModal] = useState(false);
    const [establishmentToDelete, setEstablishmentToDelete] = useState();
    const [showAddUserToEstablishmentModal, setShowAddUserToEstablishmentModal] = useState(false);
    const [establishmentSelected, setEstablishmentSelected] = useState();
    const [redirectRoute, setRedirectRoute] = useState();

    const alert = useAlert();

    useEffect(() => {        
        if(props.userRoles.some((rol) => rol.name === ROLES.TITULAR)) props.getUserEstablishmentsByCompaniesPaginated(1, 1000);        
        if(props.userRoles.some((rol) => rol.name === ROLES.SURROGATE)) props.getSurrogateEstablishments(1, 1000)
        props.countFacilitiesByType(props.UserId)
    }, []);    

    const renderValueDefault = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value}
            </div>
        )
    };

    const editUserEstablishment = () => {
        let values = props.establishmentDetails;
        values.animalLots.mode = props.selectedMode;
        values.animalLots.lots = props.establishmentDetails.animalLots.lots;
        props.resetSelectedLot();
        props.editUserEstablishmentById(values.id, props.establishmentDetails.type, values,
            () => { setShowAnimalLotsModal(!showAnimalLotsModal)}, (errorMessage) => {
                //ToastsStore.error(props.t(`errors.${errorMessage.cause.errorCode}`), 8000);
            });
      };

    const renderDate = ({value}) => {
        const date = new Date(value);
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {`${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`}
            </div>
        )
    };

    const userIsTitular = () => {
        return props.userRoles.some((rol) => rol.name === ROLES.TITULAR)
    };

    const isValidRole = () => {
        if(props.userRoles.some((rol) => rol.name === ROLES.SURROGATE)) return true
        if(props.userRoles.some((rol) => rol.name === ROLES.TITULAR)) return true
        return false
    };

    const renderButtons = ({dependentValues}) => {
        return (
          <div style={{display: "flex", justifyContent: "center"}}>
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Tooltip
                title="Ver animales"
                position="top"
                trigger="mouseenter"
                animation="scale"
                duration="600"
                size="small"
                theme="transparent"
                distance="0"
              >
                  <CowIcon
                    className={"button"}
                    style={{marginLeft: "10px"}}
                    onClick={() =>
                      props.history.push(
                        "/animals/table",
                        {establishmentId: dependentValues._id, establishmentName: dependentValues.name}
                      )
                    }
                  />
              </Tooltip>
              <Tooltip
                title="Gestión de lotes"
                position="top"
                trigger="mouseenter"
                animation="scale"
                duration="600"
                size="small"
                theme="transparent"
                distance="0"
              >
                  <CalendarPlusIcon
                    className={"button"}
                    style={{marginLeft: "10px"}}
                    onClick={() => {
                        setEstablishmentSelected({
                            name: dependentValues.name,
                            _id: dependentValues._id
                        });
                        props.getUserEstablishmentById(dependentValues._id);
                        setShowAnimalLotsModal(true);
                    }}
                  />
              </Tooltip>
              <Tooltip
                title="Edición"
                position="top"
                trigger="mouseenter"
                animation="scale"
                duration="600"
                size="small"
                theme="transparent"
                distance="0"
              >
                <MagnifyIcon
                    className={"button"}
                    style={{marginLeft: "10px"}}
                    onClick={() =>
                    props.history.push(
                        "/manage_establishments/establishment_details",
                        {establishmentId: dependentValues._id}
                    )
                    }
                />
              </Tooltip>
              {
                  isValidRole() ? (
                      <Tooltip
                        title="Agregar profesionales / operadores"
                        position="top"
                        trigger="mouseenter"
                        animation="scale"
                        duration="600"
                        size="small"
                        theme="transparent"
                        distance="0"
                      >
                          <AccountPlusOutlineIcon
                            className={"button"} style={{marginLeft: "10px"}}
                            onClick={() => {
                                setEstablishmentSelected(dependentValues._id);
                                setShowAddUserToEstablishmentModal(true);
                            }}
                          />
                      </Tooltip>
                    )
                    : null
              }
              {
                userIsTitular() ? (
                <CloseCircleIcon
                    className={"button"}
                    style={{marginLeft: "10px"}}
                    color={"red"}
                    onClick={() => {
                        setEstablishmentToDelete({
                            name: dependentValues.name,
                            _id: dependentValues._id
                        });
                        setShowDeleteModal(!showDeleteModal);
                    }}
                />
                )
                : null
             }
          </div>
        );
    };

    const renderCompanyName = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {value ? value.legalName : ''}
            </div>
        )
    };

    const renderIconsForAddingARole = () => (
        <div>
            <PlusCircleOutlineIcon onClick={() => {
                props.history.push('/manage_establishments/new_establishment')
            }}/>
        </div>
    );

    const heads = [
        {
            key: 'name',
            name: t('establishments.table.headers.establishment_name'),
            percentage: 25,
            minWidth: 150,
            formatter: renderValueDefault
        },
        {
            key: 'company',
            name: t('establishments.table.headers.establishment_company'),
            percentage: 25,
            minWidth: 150,
            formatter: renderCompanyName
        },
        {
            key: 'createdAt',
            name: t('establishments.table.headers.creation_date'),
            percentage: 25,
            minWidth: 150,
            formatter: renderDate
        },
        {
            key: 'actions',
            name: t('establishments.table.headers.actions'),
            percentage: 25,
            minWidth: 150,
            formatter: renderButtons,
            getRowMetaData: (row) => row
        }
    ];

    const renderDeleteEstablishmentBody = () => (
        establishmentToDelete ?
            <div>
                {t('establishments.modals.delete_establishment.body', { establishment_name: establishmentToDelete.name })}
            </div>:
            null
    );

    const renderDeleteEstablishmentModal = () => (
        <CVModal
            title={t('establishments.modals.delete_establishment.title')}
            body={() => renderDeleteEstablishmentBody()}
            color="danger"
            header
            isOpen={showDeleteModal}
            toggle={() => setShowDeleteModal(!showDeleteModal)}
            acceptFunc={() => {
                props.deleteEstablishmentById(establishmentToDelete._id, 
                //callback
                () => {
                    setShowDeleteModal(false);
                    alert.success(t('establishments.alerts.establishment_deleted_successfully'),{timeout:3000});
                },
                //errorCallback
                ()=>{
                    setShowDeleteModal(false);
                    alert.error(t('establishments.alerts.establishment_deleted_not_empty'),{timeout:0});
                }
                )
            }}
        />
    );

    const renderAnimalLotsModalBody = () => (
            <div>
                <AnimalLotsModal
                  establishmentId={establishmentSelected}
                />
            </div>
    );

    const submitModal = () => {

    }

    const renderAnimalLotsModal = () => (
        <CVModal
            style={{ width: '95%' }}
            title={t('establishments.modals.animal_lots.title')}
            body={() => renderAnimalLotsModalBody()}
            color="primary"
            header
            isOpen={showAnimalLotsModal}
            toggle={() => setShowAnimalLotsModal(!showAnimalLotsModal)}
            acceptFunc={editUserEstablishment}
            details={props.establishmentDetails}
        />
    );

    const renderAddProfessionalToEstablishmentBody = () => (
        <div>
            <ProfessionalList establishmentId={establishmentSelected}/>
            <AddNewProfessional establishmentId={establishmentSelected}/>
            <br/>
            <CVButton 
                                  color="primary"
                                  type="submit"
                                  onClick={()=> {setShowAddUserToEstablishmentModal(false)}}
                                  >Cerrar
                                </CVButton>
        </div>
    );

    const addProfessionalToEstablishmentModal = () => (
        <CVBasicModalWithoutButtons
            title={t('establishments.modals.add_professional_to_establishment.title')}
            body={renderAddProfessionalToEstablishmentBody}
            isOpen={showAddUserToEstablishmentModal}
            toggleModal={() => {
                if(showAddUserToEstablishmentModal) props.resetProfessionalUserSearch();
                setShowAddUserToEstablishmentModal(!showAddUserToEstablishmentModal)
            }}
        />
    );

    return(
        <div>
            <CVTable
                getPage={
                    (page) => {                       
                        if(props.userRoles.some((rol) => rol.name === ROLES.TITULAR)) props.getUserEstablishmentsByCompaniesPaginated(page, pageSize);        
                        if(props.userRoles.some((rol) => rol.name === ROLES.SURROGATE)) props.getSurrogateEstablishments(page, pageSize)
                    }
                }
                //getPage={establishments}
                data={establishments}
                total={total}
                totalPages={totalPages}
                currentPage={currentPage}
                pageSize={pageSize}
                heads={heads}
                title={t('establishments.table.title')}
                hideFilters
                SubtitleComponent={renderIconsForAddingARole}
                widthPercentage={widthPercentage}
                noDataLabel={t('establishments.table.no_data_label')}
                pending={props.getUserEstablishmentsPending}
            />
            {renderDeleteEstablishmentModal()}
            {addProfessionalToEstablishmentModal()}
            {renderAnimalLotsModal()}
            { redirectRoute && <Redirect to={redirectRoute}/> }
        </div>
    )

};

const mapStateToProps = (state) => ({
    userId: state.user.id,
    establishments: state.user.establishments.results,
    currentPage: state.user.establishments.page,
    total: state.user.establishments.total,
    totalPages: state.user.establishments.totalPages,
    pageSize: state.user.establishments.pageSize,
    getUserEstablishmentsPending: state.ui.getUserEstablishmentsPending,
    getUserEstablishmentsError: state.ui.getUserEstablishmentsError,
    userRoles: state.user.roles,
    establishmentDetails: state.user.establishmentDetails,
    selectedMode: state.user.selectedEstablishmentLotMode
});

const mapDispatchToProps = (dispatch) => ({
    resetSelectedLot: () => dispatch(actions.user.resetSelectedLot()),
    getUserEstablishmentById: (establishmentId) => dispatch(actions.user.getEstablishmentById(establishmentId)),
    editUserEstablishmentById: (establishmentId, establishmentType, data, successCallback, errorCallback) =>
        dispatch(actions.user.editEstablishmentById(establishmentId, establishmentType, data, successCallback, errorCallback)),    
    getUserEstablishmentsByCompaniesPaginated: (page, pageSize) => dispatch(actions.user.getUserEstablishmentsByCompaniesPaginated(page, pageSize)),
    getSurrogateEstablishments: (page, pageSize) => dispatch(actions.user.getSurrogateEstablishments(page, pageSize)),
    deleteEstablishmentById: (establishmentId, callback, errorCallback) =>
        dispatch(actions.user.deleteUserEstablishmentById(establishmentId, callback, errorCallback)),
    resetProfessionalUserSearch: () => dispatch(actions.user.resetProfessionalUserSearch()),
    countFacilitiesByType: (userId) => dispatch(actions.user.getCountUserFacilitiesByType(userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(EstablishmentTable)));