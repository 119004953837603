import api from '../../api/index';
import { GET_AVAILABLE_BI
} from '../actions/biActions';
import actions from '../actions';
import mapper from './mappers/biMapper';

const bi = ({dispatch, getState}) => next => action => {
    next(action);
    switch (action.type) {        
        case GET_AVAILABLE_BI:
            dispatch(actions.auth.authenticatedRequest(jwt => {                
                api.getBI(jwt)
                    .then(res => {                        
                        dispatch(actions.bi.getAvailableBIResponse(mapper.response.getBI(res)))
                    })
                    .catch(err => {
                        dispatch(actions.bi.getAvailableBIError(err))
                    })
            }));
            break;                    
    }
};

export default bi;