
export const GET_AVAILABLE_BI = "GET_AVAILABLE_BI";
export const GET_AVAILABLE_BI_RESPONSE = "GET_AVAILABLE_BI_RESPONSE";
export const GET_AVAILABLE_BI_ERROR = "GET_AVAILABLE_BI_ERROR";
export const GET_AVAILABLE_BI_INIT = "GET_AVAILABLE_BI_INIT";


const biActions = {
    
    getAvailableBI() {
        return {
            type: GET_AVAILABLE_BI
        }
    },
    getAvailableBIResponse(response) {        
        return {
            type: GET_AVAILABLE_BI_RESPONSE,
            response
        }
    },
    getAvailableBIError(message) {    
        return {
            type: GET_AVAILABLE_BI_ERROR,
            message
        }
    },
    getAvailableBIInit() {    
        return {
            type: GET_AVAILABLE_BI_INIT,            
        }
    }
};

export default biActions;