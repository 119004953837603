import React, { useState, useEffect } from 'react';
import {Col, Container, Row, Card, CardBody, Button} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAlert } from "react-alert";
import _ from 'lodash';

const VerifyMnemonic = ({ t, history, location }) => {
    const [mnemonicOrdered, setMnemonicOrdered] = useState([]);
    const [mnemonicDisordered, setMnemonicDisordered] = useState([]);
    const [mnemonicOrderedByUser, setMnemonicOrderedByUser] = useState([]);

    const alert = useAlert();

    useEffect(() => {
        if(location.state.mnemonic) {
            setMnemonicOrdered(parseMnemonicArray(location.state.mnemonic, false));
            setMnemonicDisordered(_.shuffle(parseMnemonicArray(location.state.mnemonic, true)))
        }
    }, [location.state]);

    const parseMnemonicArray = (mnemonicArray, visibility) => {
        return mnemonicArray.map((word, index) => ({
            id: index,
            word: word,
            visible: visibility
        }))
    };

    const translateFromDisorderToResult = (word) => {
        //Show the word on the 'order by user' array
        setMnemonicOrderedByUser([...mnemonicOrderedByUser, {...word, visible: true}]);
        //Hide the word on the disordered array
        setMnemonicDisordered(mnemonicDisordered.map(element => {
            if(element.id === word.id) element['visible'] = false;
            return element;
        }));
    };

    const translateFromResultToDisorder = (word) => {
        //Show the word on the disordered array
        setMnemonicDisordered(mnemonicDisordered.map(element => {
            if(element.id === word.id) element['visible'] = true;
            return element;
        }));
        //Remove the word on the 'order by user' array
        setMnemonicOrderedByUser(mnemonicOrderedByUser.filter(({id}) => id !== word.id));
    };

    const reset = () => {
        setMnemonicOrderedByUser([]);
        setMnemonicDisordered(mnemonicDisordered.map(element => {
            element['visible'] = true;
            return element;
        }));
    };

    const verifyMnemonic = () => {
        return !mnemonicOrderedByUser.some((value, index) => mnemonicOrdered[index].id !== value.id)
    };

    const verify = () => {
        const match = verifyMnemonic();
        if(match) history.push('/wallet/generate_secret', {mnemonic: location.state.mnemonic});
        else alert.error("La frase de recuperación no coincide");
    };

    return (
        <Container className="verify__mnemonic">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">{t('wallet.verifyMnemonic.title')}</h3>
                    <h3 className="page-subhead subhead">{t('wallet.verifyMnemonic.body')}</h3>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={{size: 8, offset: 2}}>
                    <Card>
                        <CardBody>
                            <span className="mnemonic__container__title">{t('wallet.verifyMnemonic.result')}:</span>
                            <div className="mnemonic__container border min_height">
                                {mnemonicOrderedByUser.filter(({visible}) => visible).map((element) =>
                                    (<span className="mnemonic__word"
                                           onClick={() => translateFromResultToDisorder(element)} key={element.id}>
                                        {element.word}
                                    </span>)
                                )}
                            </div>
                            <span className="mnemonic__container__title">{t('wallet.verifyMnemonic.disorder_words')}:</span>
                            <div className="mnemonic__container border min_height">
                                {mnemonicDisordered.filter(({visible}) => visible).map((element) =>
                                    (<span className="mnemonic__word"
                                        onClick={() => translateFromDisorderToResult(element)} key={element.id}>
                                            {element.word}
                                    </span>)
                                )}
                            </div>
                            <div className="button__container">
                                <Button className="btn btn-primary"
                                        disabled={mnemonicDisordered.every((element) => element.visible)}
                                        onClick={reset}>
                                    {t('wallet.verifyMnemonic.reset')}
                                </Button>
                                <Button className="btn btn-primary"
                                        disabled={mnemonicOrderedByUser.length !== mnemonicOrdered.length}
                                        onClick={verify}>
                                    {t('wallet.verifyMnemonic.verify')}
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
};

VerifyMnemonic.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(VerifyMnemonic);
