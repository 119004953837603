/*
*   The animals mapper is use for mapping either the body or the response on a animal request. It try to decouple the
*   animals middleware.
* */

import i18n from "../../../translations/config/i18n";

const mapper =  {
    body: {
        addCow: (data, uploadedFilesAndUrls = {}) => {
             
            let result = {
                earId: data.caravan_id,
                category: data.category.value,
                breed: data.breed.value,
                specie: data.specie.value,
                weight: data.weight_at_discharge,
                facility: data.establishment.value,
                productionSystem: parseProductiveSystemOptionsToArray(data.productiveSystems)[0],
                feeding: data.foods,
                fireMarkPlace: data.fire_mark_place.value,
                dentition: data.dentition.value,
                fireMarkPlace: data.fire_mark_place.value,
                newLot: data.new_lot,
                videoUrl: data.animal_video_url,
            };
            

            // Optionals
            if(data.age_in_months) result['age'] = data.age_in_months;
            if(data.secondary_id){
                if(data.secondary_id.chip) result['chip'] = data.secondary_id.chip;
                if(data.secondary_id.button) result['button'] = data.secondary_id.button;
                if(data.secondary_id.internal_caravan) result['internalEarId'] = data.secondary_id.internal_caravan;
            }
            //if(uploadedFilesAndUrls.DNA_analysis) result['dnaFile'] = uploadedFilesAndUrls.DNA_analysis;
            //if(uploadedFilesAndUrls.signal_on_ear) result['earImage'] = uploadedFilesAndUrls.signal_on_ear;

            

            if(uploadedFilesAndUrls.signal_on_ear) result['earImage'] = uploadedFilesAndUrls.signal_on_ear.fileUrl;
            if(uploadedFilesAndUrls.signal_on_ear) result['earImage_checksumFile'] = uploadedFilesAndUrls.signal_on_ear.md5Hash;         

            if(!uploadedFilesAndUrls.signal_on_ear) {                
                if(data.signal_on_ear) result['earImage'] = data.signal_on_ear;
                if(data.signal_on_ear_checksumFile) result['earImage_checksumFile'] = data.signal_on_ear_checksumFile;
            }

            if(uploadedFilesAndUrls.animal_image) result['animalImage'] = uploadedFilesAndUrls.animal_image.fileUrl;
            if(uploadedFilesAndUrls.animal_image) result['animalImage_checksumFile'] = uploadedFilesAndUrls.animal_image.md5Hash;         

            if(!uploadedFilesAndUrls.animal_image) {                
                if(data.animal_image) result['animalImage'] = data.animal_image;
                if(data.animal_image_checksumFile) result['animalImage_checksumFile'] = data.animal_image_checksumFile;
            }

            
            /// genealogy
            if(data.birth) {
                if(data.birth.father_id) result['fatherId'] = data.birth.father_id;
                if(data.birth.mother_id) result['motherId'] = data.birth.mother_id;
                if(data.birth.date) result['birthDate'] = data.birth.date;
                if(data.birth.weight) result['birthWeight'] = data.birth.weight;

                if (data.birth.genealogyHBA) result['genealogyHBA'] = data.birth.genealogyHBA;
                if (data.birth.genealogyRP) result['genealogyRP'] = data.birth.genealogyRP;
                if (data.birth.genealogyCC) result['genealogyCC'] = data.birth.genealogyCC;
                if (data.birth.genealogyName) result['genealogyName'] = data.birth.genealogyName;
                if (data.birth.genealogyCertificateIdentifier) result['genealogyCertificateIdentifier'] = data.birth.genealogyCertificateIdentifier;

                if (data.birth.genealogyFatherHBA) result['genealogyFatherHBA'] = data.birth.genealogyFatherHBA;
                if (data.birth.genealogyFatherRP) result['genealogyFatherRP'] = data.birth.genealogyFatherRP;
                if (data.birth.genealogyFatherCC) result['genealogyFatherCC'] = data.birth.genealogyFatherCC;
                if (data.birth.genealogyFatherName) result['genealogyFatherName'] = data.birth.genealogyFatherName;
                if (data.birth.genealogyFatherCertificateIdentifier) result['genealogyFatherCertificateIdentifier'] = data.birth.genealogyFatherCertificateIdentifier;

                if (data.birth.genealogyMotherHBA) result['genealogyMotherHBA'] = data.birth.genealogyMotherHBA;
                if (data.birth.genealogyMotherRP) result['genealogyMotherRP'] = data.birth.genealogyMotherRP;
                if (data.birth.genealogyMotherCC) result['genealogyMotherCC'] = data.birth.genealogyMotherCC;
                if (data.birth.genealogyMotherName) result['genealogyMotherName'] = data.birth.genealogyMotherName;
                if (data.birth.genealogyMotherCertificateIdentifier) result['genealogyMotherCertificateIdentifier'] = data.birth.genealogyMotherCertificateIdentifier;
            }

            if(uploadedFilesAndUrls.genealogyCertificate) result['genealogyCertificate'] = uploadedFilesAndUrls.genealogyCertificate.fileUrl;
            if(uploadedFilesAndUrls.genealogyCertificate) result['genealogyCertificate_checksumFile'] = uploadedFilesAndUrls.genealogyCertificate.md5Hash;         

            if(!uploadedFilesAndUrls.genealogyCertificate) {                
                if(data.genealogyCertificate) result['genealogyCertificate'] = data.genealogyCertificate;
                if(data.genealogyCertificate_checksumFile) result['genealogyCertificate_checksumFile'] = data.genealogyCertificate_checksumFile;
            }            

            if(uploadedFilesAndUrls.genealogyFatherCertificate) result['genealogyFatherCertificate'] = uploadedFilesAndUrls.genealogyFatherCertificate.fileUrl;
            if(uploadedFilesAndUrls.genealogyFatherCertificate) result['genealogyFatherCertificate_checksumFile'] = uploadedFilesAndUrls.genealogyFatherCertificate.md5Hash;         

            if(!uploadedFilesAndUrls.genealogyFatherCertificate) {                
                if(data.genealogyFatherCertificate) result['genealogyFatherCertificate'] = data.genealogyFatherCertificate;
                if(data.genealogyFatherCertificate_checksumFile) result['genealogyFatherCertificate_checksumFile'] = data.genealogyFatherCertificate_checksumFile;
            }
            
            if(uploadedFilesAndUrls.genealogyMotherCertificate) result['genealogyMotherCertificate'] = uploadedFilesAndUrls.genealogyMotherCertificate.fileUrl;
            if(uploadedFilesAndUrls.genealogyMotherCertificate) result['genealogyMotherCertificate_checksumFile'] = uploadedFilesAndUrls.genealogyMotherCertificate.md5Hash;         

            if(!uploadedFilesAndUrls.genealogyMotherCertificate) {                
                if(data.genealogyMotherCertificate) result['genealogyMotherCertificate'] = data.genealogyMotherCertificate;
                if(data.genealogyMotherCertificate_checksumFile) result['genealogyMotherCertificate_checksumFile'] = data.genealogyMotherCertificate_checksumFile;
            }

            if(uploadedFilesAndUrls.DNA_analysis) result['dnaFile'] = uploadedFilesAndUrls.DNA_analysis.fileUrl;
            if(uploadedFilesAndUrls.DNA_analysis) result['dnaFile_checksumFile'] = uploadedFilesAndUrls.DNA_analysis.md5Hash; 
            
            if(!uploadedFilesAndUrls.DNA_analysis) {                
                if(data.DNA_analysis) result['dnaFile'] = data.DNA_analysis;
                if(data.DNA_analysis_checksumFile) result['dnaFile_checksumFile'] = data.DNA_analysis_checksumFile;
            }

            if(uploadedFilesAndUrls.genealogy_DEPS) result['genealogyDEPSImage'] = uploadedFilesAndUrls.genealogy_DEPS.fileUrl;
            if(uploadedFilesAndUrls.genealogy_DEPS) result['genealogyDEPSImage_checksumFile'] = uploadedFilesAndUrls.genealogy_DEPS.md5Hash;

            if(!uploadedFilesAndUrls.genealogy_DEPS) {                
                if(data.genealogy_DEPS) result['genealogyDEPSImage'] = data.genealogy_DEPS;
                if(data.genealogy_DEPS_checksumFile) result['genealogyDEPSImage_checksumFile'] = data.genealogy_DEPS_checksumFile;
            }

            if(uploadedFilesAndUrls.DNA_analysis_mother) result['dnaFile_mother'] = uploadedFilesAndUrls.DNA_analysis_mother.fileUrl;
            if(uploadedFilesAndUrls.DNA_analysis_mother) result['dnaFile_mother_checksumFile'] = uploadedFilesAndUrls.DNA_analysis_mother.md5Hash;         

            if(!uploadedFilesAndUrls.DNA_analysis_mother) {                
                if(data.DNA_analysis_mother) result['dnaFile_mother'] = data.DNA_analysis_mother;
                if(data.DNA_analysis_mother_checksumFile) result['dnaFile_mother_checksumFile'] = data.DNA_analysis_mother_checksumFile;
            }

            if(uploadedFilesAndUrls.DNA_analysis_father) result['dnaFile_father'] = uploadedFilesAndUrls.DNA_analysis_father.fileUrl;
            if(uploadedFilesAndUrls.DNA_analysis_father) result['dnaFile_father_checksumFile'] = uploadedFilesAndUrls.DNA_analysis_father.md5Hash;         

            if(!uploadedFilesAndUrls.DNA_analysis_father) {                
                if(data.DNA_analysis_father) result['dnaFile_father'] = data.DNA_analysis_father;
                if(data.DNA_analysis_father_checksumFile) result['dnaFile_father_checksumFile'] = data.DNA_analysis_father_checksumFile;
            }
            /// genealogy: end

            
            if(data.establishment) {
                data.facility = data.establishment.value;
            }
            if(data.establishmentLot && data.establishmentLot.value){
                result['tags'] = [{
                    _id: data.establishmentLot.value,
                    type: 'animalLot'
                }]
                result['animalLot'] = {
                    _id: data.establishmentLot.value,
                    name : data.new_lot,
                    type: 'animalLot'
                }
            }
            return result;
        },
        editCow: (data, uploadedFilesAndUrls = {}) => {
            return mapper.body.addCow(data, uploadedFilesAndUrls)
        }
    },
    response: {
        getAnimals: (response) => ({
            results: response.docs,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        getAnimalsHistoryByLot: (response) => ({
            animalsHistoryByLot: response,
        }),
        getFacilityAnimalsToExcel: (response) => ({
            excelBuffer: response.excelBuffer,
        }),
        getAnimalsForDownload: (response) => ({
            results: parseAnimalsForDownload(response.docs),
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        searchAnimals: (response) => ({
            animals: response.results,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        filterAnimals: (response) => ({
            animals: response.results,
            page: response.page,
            totalPages: response.pages,
            total: response.total,
            pageSize: response.limit
        }),
        getUserAnimalById: (response) => {            
            let result = {
                caravan_id: response.earId,
                breed: {
                    value: response.breed,
                    label: response.breed
                },
                specie: {
                    value: response.specie,
                    label: response.specie
                },
                dentition: {
                    value: response.dentition,
                    label: response.dentition,
                },
                sex: {
                    value: response.gender,
                    label: i18n.t(`common:cv-forms.cow.sex.${response.gender}`)
                },
                weight_at_discharge: response.weight,
                category: {
                    value: response.category,
                    label: response.category
                },
                establishment: {
                    value: response.facility._id,
                    label: response.facility.name
                },
                productive_system: parseProductiveSystemOptionsToJson([response.productionSystem]),
                feeding: formatFeeding(response.feeding),
                fire_mark_place: {
                    value: response.fireMarkPlace,
                    label: i18n.t(`common:cv-forms.cow.fire_mark_place_options.${response.fireMarkPlace}`)
                }
            };

            // optionals
            if(response.age) {
                result['age_in_months'] = response.age
            }
            if(response.videoUrl) result['animal_video_url'] = response.videoUrl;

            let secondary_id = {};
            if(response.chip) secondary_id['chip'] = response.chip;
            if(response.button) secondary_id['button'] = response.button;
            if(response.internalEarId) secondary_id['internal_caravan'] = response.internalEarId;

            if (Object.entries(secondary_id).length !== 0) result['secondary_id'] = secondary_id;

            //
            if(response.dnaFile) result['DNA_analysis'] = response.dnaFile;
            if(response.genealogyDEPSImage) result['genealogy_DEPS'] = response.genealogyDEPSImage;
            if(response.dnaFile_mother) result['DNA_analysis_mother'] = response.dnaFile_mother;
            if(response.dnaFile_father) result['DNA_analysis_father'] = response.dnaFile_father;

            if(response.genealogyCertificate) result['genealogyCertificate'] = response.genealogyCertificate;
            if(response.genealogyMotherCertificate) result['genealogyMotherCertificate'] = response.genealogyMotherCertificate;
            if(response.genealogyFatherCertificate) result['genealogyFatherCertificate'] = response.genealogyFatherCertificate;

            if(response.earImage) result['signal_on_ear'] = response.earImage;
            if(response.animalImage) result['animal_image'] = response.animalImage;


            if(response.dnaFile_checksumFile) result['DNA_analysis_checksumFile'] = response.dnaFile_checksumFile;
            if(response.genealogyDEPSImage_checksumFile) result['genealogy_DEPS_checksumFile'] = response.genealogyDEPSImage_checksumFile;
            if(response.dnaFile_mother_checksumFile) result['DNA_analysis_mother_checksumFile'] = response.dnaFile_mother_checksumFile;
            if(response.dnaFile_father_checksumFile) result['DNA_analysis_father_checksumFile'] = response.dnaFile_father_checksumFile;

            if(response.genealogyCertificate_checksumFile) result['genealogyCertificate_checksumFile'] = response.genealogyCertificate_checksumFile;
            if(response.genealogyMotherCertificate_checksumFile) result['genealogyMotherCertificate_checksumFile'] = response.genealogyMotherCertificate_checksumFile;
            if(response.genealogyFatherCertificate_checksumFile) result['genealogyFatherCertificate_checksumFile'] = response.genealogyFatherCertificate_checksumFile;

            if(response.earImage_checksumFile) result['signal_on_ear_checksumFile'] = response.earImage_checksumFile;
            if(response.animalImage_checksumFile) result['animal_image_checksumFile'] = response.animalImage_checksumFile;
            //

            if(response.fatherId) result['birth'] = {...result.birth, father_id: response.fatherId};
            if(response.motherId) result['birth'] = {...result.birth, mother_id: response.motherId};
            if(response.birthDate) result['birth'] = {...result.birth, date: response.birthDate};
            if(response.birthWeight) result['birth'] = {...result.birth, weight: response.birthWeight};

            if(response.genealogyHBA) result['birth'] = {...result.birth, genealogyHBA: response.genealogyHBA};
            if(response.genealogyRP) result['birth'] = {...result.birth, genealogyRP: response.genealogyRP};
            if(response.genealogyCC) result['birth'] = {...result.birth, genealogyCC: response.genealogyCC};
            if(response.genealogyName) result['birth'] = {...result.birth, genealogyName: response.genealogyName};
            if(response.genealogyCertificateIdentifier) result['birth'] = {...result.birth, genealogyCertificateIdentifier: response.genealogyCertificateIdentifier};

            if(response.genealogyFatherHBA) result['birth'] = {...result.birth, genealogyFatherHBA: response.genealogyFatherHBA};
            if(response.genealogyFatherRP) result['birth'] = {...result.birth, genealogyFatherRP: response.genealogyFatherRP};
            if(response.genealogyFatherCC) result['birth'] = {...result.birth, genealogyFatherCC: response.genealogyFatherCC};
            if(response.genealogyFatherName) result['birth'] = {...result.birth, genealogyFatherName: response.genealogyFatherName};
            if(response.genealogyFatherCertificateIdentifier) result['birth'] = {...result.birth, genealogyFatherCertificateIdentifier: response.genealogyFatherCertificateIdentifier};

            if(response.genealogyMotherHBA) result['birth'] = {...result.birth, genealogyMotherHBA: response.genealogyMotherHBA};
            if(response.genealogyMotherRP) result['birth'] = {...result.birth, genealogyMotherRP: response.genealogyMotherRP};
            if(response.genealogyMotherCC) result['birth'] = {...result.birth, genealogyMotherCC: response.genealogyMotherCC};
            if(response.genealogyMotherName) result['birth'] = {...result.birth, genealogyMotherName: response.genealogyMotherName};
            if(response.genealogyMotherCertificateIdentifier) result['birth'] = {...result.birth, genealogyMotherCertificateIdentifier: response.genealogyMotherCertificateIdentifier};

            
            if(response.establishmentLot ) result['establishmentLot'] = {
                _id: response.establishmentLot._id,
                label : response.establishmentLot.name
            }
            return result;
        },
        massiveAnimalUpload: (response) => ({
            _id: response._id,
            hash: response.hash,
            status: response.status,
            animals: parseValidatedRows(response.validatedRows)
        })
    },
    data: {
        uploadCowFormFiles: (info) => {
            let files = [];

            // Check is need on DNA_analysis file because is not required.
            if(info.DNA_analysis) {
                files.push({
                    id: 'DNA_analysis',
                    file: info.DNA_analysis.files[0]
                });
            }

            // Check is need on genealogy_DEPS file because is not required.
            if(info.genealogy_DEPS) {
                files.push({
                    id: 'genealogy_DEPS',
                    file: info.genealogy_DEPS.files[0]
                });
            }

            // Check is need on signal_on_ear file because is not required.
            if(info.signal_on_ear) {
                files.push({
                    id: 'signal_on_ear',
                    file: info.signal_on_ear.files[0]
                });
            }

            // Check is need on signal_on_ear file because is not required.
            if(info.animal_image) {
                files.push({
                    id: 'animal_image',
                    file: info.animal_image.files[0]
                });
            }

            // Check is need on DNA_analysis file because is not required.
            if(info.DNA_analysis_mother) {
                files.push({
                    id: 'DNA_analysis_mother',
                    file: info.DNA_analysis_mother.files[0]
                });
            }

            // Check is need on DNA_analysis file because is not required.
            if(info.DNA_analysis_father) {
                files.push({
                    id: 'DNA_analysis_father',
                    file: info.DNA_analysis_father.files[0]
                });
            }

            // Check is need on DNA_analysis file because is not required.
            if(info.genealogyCertificate) {
                files.push({
                    id: 'genealogyCertificate',
                    file: info.genealogyCertificate.files[0]
                });
            }

            // Check is need on DNA_analysis file because is not required.
            if(info.genealogyFatherCertificate) {
                files.push({
                    id: 'genealogyFatherCertificate',
                    file: info.genealogyFatherCertificate.files[0]
                });
            }

            // Check is need on DNA_analysis file because is not required.
            if(info.genealogyMotherCertificate) {
                files.push({
                    id: 'genealogyMotherCertificate',
                    file: info.genealogyMotherCertificate.files[0]
                });
            }

            return files;
        },
        editCowFormFiles: (info) => {
            let files = [];

            // Check is need on DNA_analysis file because is not required.
            if(info.genealogyCertificate) {
                // If the dna analysis is an string these means that the file didn't change ant it is already upload.
                if(typeof info.genealogyCertificate !== 'string') {
                    files.push({
                        id: 'genealogyCertificate',
                        file: info.genealogyCertificate.files[0]
                    });
                }
            }

            // Check is need on DNA_analysis file because is not required.
            if(info.genealogyFatherCertificate) {
                // If the dna analysis is an string these means that the file didn't change ant it is already upload.
                if(typeof info.genealogyFatherCertificate !== 'string') {
                    files.push({
                        id: 'genealogyFatherCertificate',
                        file: info.genealogyFatherCertificate.files[0]
                    });
                }
            }

            // Check is need on DNA_analysis file because is not required.
            if(info.genealogyMotherCertificate) {
                // If the dna analysis is an string these means that the file didn't change ant it is already upload.
                if(typeof info.genealogyMotherCertificate !== 'string') {
                    files.push({
                        id: 'genealogyMotherCertificate',
                        file: info.genealogyMotherCertificate.files[0]
                    });
                }
            }
             
            // Check is need on DNA_analysis file because is not required.
            if(info.DNA_analysis) {
                // If the dna analysis is an string these means that the file didn't change ant it is already upload.
                if(typeof info.DNA_analysis !== 'string') {
                    files.push({
                        id: 'DNA_analysis',
                        file: info.DNA_analysis.files[0]
                    });
                }
            }

            // Check is need on genealogy_DEPS file because is not required.
            if(info.genealogy_DEPS) {
                // If the genealogy_DEPS is an string these means that the file didn't change ant it is already upload.
                if(typeof info.genealogy_DEPS !== 'string') {
                    files.push({
                        id: 'genealogy_DEPS',
                        file: info.genealogy_DEPS.files[0]
                    });
                }
            }

            // Check is need on DNA_analysis file because is not required.
            if(info.DNA_analysis_father) {
                // If the dna analysis is an string these means that the file didn't change ant it is already upload.
                if(typeof info.DNA_analysis_father !== 'string') {
                    files.push({
                        id: 'DNA_analysis_father',
                        file: info.DNA_analysis_father.files[0]
                    });
                }
            }

            // Check is need on DNA_analysis file because is not required.
            if(info.DNA_analysis_mother) {
                // If the dna analysis is an string these means that the file didn't change ant it is already upload.
                if(typeof info.DNA_analysis_mother !== 'string') {
                    files.push({
                        id: 'DNA_analysis_mother',
                        file: info.DNA_analysis_mother.files[0]
                    });
                }
            }

            // Check is need on signal_on_ear file because is not required.
            if(info.signal_on_ear) {
                // If the signal on ear is an string these means that the file didn't change ant it is already upload.
                if(typeof info.signal_on_ear !== 'string') {
                    files.push({
                        id: 'signal_on_ear',
                        file: info.signal_on_ear.files[0]
                    });
                }
            }

            if(info.animal_image) {
                // If the signal on ear is an string these means that the file didn't change ant it is already upload.
                if(typeof info.animal_image !== 'string') {
                    files.push({
                        id: 'animal_image',
                        file: info.animal_image.files[0]
                    });
                }
            }
            return files;
        }
    }
};

const parseProductiveSystemOptionsToArray = (productiveSystems) => {
    let result = [];
    if(productiveSystems.natural) result.push('natural');
    if(productiveSystems.silvoPastoral) result.push('silvoPastoral');
    if(productiveSystems.pastoral) result.push('pastoral');
    if(productiveSystems.creepFeeding) result.push('creepFeeding');
    if(productiveSystems.strategicFeeding) result.push('strategicFeeding');
    if(productiveSystems.feedlot) result.push('feedlot');
    if(productiveSystems.noneSystem) result.push('noneSystem');
    

    return result;
};

const parseAnimalsForDownload = (data) => {    
    let results = []
    
    let maxFeedingValues = 0;
    for (let row of data) {
        if (row.feeding.length>maxFeedingValues) maxFeedingValues = row.feeding.length;
    }

    for (let row of data) {
        let parsedData = {}
        try {
            parsedData[i18n.t('common:excelHeaders.earId')] = row.earId;
            parsedData[i18n.t('common:excelHeaders.specie')] = row.specie;
            parsedData[i18n.t('common:excelHeaders.fireMarkPlace')]  = row.fireMarkPlace;
            parsedData[i18n.t('common:excelHeaders.chip')] = row.chip;
            parsedData[i18n.t('common:excelHeaders.breed')]  = row.breed;
            parsedData[i18n.t('common:excelHeaders.weight')] = row.weight;
            parsedData[i18n.t('common:excelHeaders.category')]  = row.category;
            parsedData[i18n.t('common:excelHeaders.dentition')] = row.dentition;
            parsedData[i18n.t('common:excelHeaders.gender')] = row.gender;
            parsedData[i18n.t('common:excelHeaders.productionSystem')] = row.productionSystem;
            parsedData[i18n.t('common:excelHeaders.lotsDetail')] = row.lotsDetail;
            
            if(row.feeding.length > 0) addFeedingData(parsedData, row.feeding, maxFeedingValues)            

            let dateTemp = new Date(row.createdAt);
            parsedData[i18n.t('common:excelHeaders.createdAt')] = dateTemp.toLocaleDateString();

            results.push(parsedData);            
            
        } catch (error) {
            console.log(error)
        }
    }    

    return results;
}

const addFeedingData = (parsedData, values, totalValues) => {
    let i = 1;
    for(let value of values) {
        parsedData[i18n.t('common:excelHeaders.feeding')+i] = value;
        i++;
    }
    for (let j=i; j<=totalValues; j++) {
        parsedData[i18n.t('common:excelHeaders.feeding')+j] = "";
    }
}

const formatFeeding = (values)=> {
    if(values.length > 0 && values[0] != "") {
        // fix food inconsistent intp multiselect (cowform)
        //return formatValues(values);
        return values;
    }
}

const formatValues = (arr) => arr.map(value =>
    value.split(' ').map(word =>
		word[0].toUpperCase() + word.slice(1).toLowerCase()
	).join(' ')
);

const parseProductiveSystemOptionsToJson = (productiveSystemsArray) => {
    let result = {
        natural: false,
        silvoPastoral: false,
        pastoral: false,
        creepFeeding: false,
        strategicFeeding: false,
        feedlot: false,
    };
    productiveSystemsArray.forEach(productiveSystem => {
        result[`${productiveSystem}`] = true;
    });
    return result;
};

const parseValidatedRows = (validatedRows) => {
    let result = [];
    validatedRows.forEach(row => {
        result.push({
            ...row.data,
            rowId: row.rowId + 1,
            status: row.status,
            errors: row.errors
        })
    });
    return result;
};

export default mapper;