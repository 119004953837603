/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import { withRouter } from 'react-router-dom';
import CVTable from '../../Tables/CVTable/CVTableAnimalsPreview';
import {Button} from "reactstrap";
import cowTransparent from "../../../shared/img/cow-transparent.gif";
import actions from '../../../redux/actions/index';
import {Redirect} from "react-router-dom";
import CheckOutlineIcon from 'mdi-react/CheckOutlineIcon';
import CloseOutlineIcon from 'mdi-react/CloseOutlineIcon';
import {useAlert} from "react-alert";
import {GENERIC_ERROR} from '../../../util/routes/errors';
import CVButton from "../../../shared/components/cv/CVButton";
import CVBasicModal from "../../../shared/components/cv/CVBasicModal";
import CVEmailTierModal from "../../../shared/components/cv/CVEmailTierModal";
import setting from '../../../util/settings';
import { ajaxSettings } from 'jquery';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

const AnimalsMassiveLoad = (props) => {
    const [animals, setAnimals] = useState();
    const [animalsToShow, setAnimalsToShow]=useState()
    const [animalsCorrect, setAnimalsCorrect]=useState(0)
    const [animalsIncorrect, setAnimalsIncorrect]=useState(0)
    
    const [total, setTotal] = useState(0);
    const [redirectRoute, setRedirectRoute] = useState();
    const [page,setPage] = useState(1)
    const [totalPages,setTotalPages] = useState(1)
    const [animalsAll,setAnimalsAll] = useState([])
    const pageSize = 10
    const alert = useAlert();

    useEffect(() => {       
        if (props.animals !== undefined) {            
            let ok=0
            let no_ok=0
            props.animals.forEach(element => {
                if(element.status==="ok"){
                    ok++;
                } else if(element.status==="error"){
                    no_ok++;
                }});
            setAnimalsCorrect(ok)
            setAnimalsIncorrect(no_ok)
            
            let animalsOnlyErrors = props.animals.filter(animal => animal.status === 'error');
            animalsOnlyErrors = animalsOnlyErrors.map(function callback(currentValue, index, array) {
                return {...currentValue,rowNumber: currentValue.rowId}
            })            
            setAnimalsAll(animalsOnlyErrors);
        }
    }, [props.animals]);

    useEffect(() => {       
        if (animalsAll !== undefined) {            
            setAnimals(animalsAll);
            setTotal(animalsAll.length)
            setTotalPages(Math.ceil( animalsAll.length/pageSize))
            setAnimalsToShow(animalsAll.slice( ((page-1) *pageSize),(page*pageSize)))            
        }
    }, [animalsAll]);

    const [animalsCountSelectedCompanyScope, setAnimalsCountSelectedCompanyScope] = useState({
        max:0, count:-1
    })
    const [countOK, setCountOK] = useState(0);
    const [isTierAnimalsCountInRange, setIsTierAnimalsCountInRange] = useState(false);
    const [tierModalIsOpen, toggleTierModal] = useState(false);
    


    function evaluateIsTierAnimalsCountInRange() {        
        if (animalsCountSelectedCompanyScope.max==-1) {
            setIsTierAnimalsCountInRange(true);
            return;
        }
        if (animalsCountSelectedCompanyScope.max==0) {
            setIsTierAnimalsCountInRange(false);            
            return;
        }
        if (countOK==0) {
            setIsTierAnimalsCountInRange(false);            
            return;
        }
        if (animalsCountSelectedCompanyScope.max>=(countOK+animalsCountSelectedCompanyScope.count)) {
            setIsTierAnimalsCountInRange(true);
            return;
        } else {
            setIsTierAnimalsCountInRange(false);
            toggleTierModal(true);
            return;
        }
    }

    const renderTierBody = () => (
        <div>
            <div>
            Tu empresa ha llegado al límite de animales activos ({props.animalsCountSelectedCompanyScope.max} animales) para el plan vigente, y no podrás realizar la operación actual.<br></br>
            Actualmente tu empresa tiene {props.animalsCountSelectedCompanyScope.count} animales, y estás intentando ingresar {countOK} adicionales, sobrepasando el limite permitido.<br></br><br/>

            
            Para actualizar tu plan podés contactarnos haciendo click <a style={{cursor:"pointer", color:"black;", fontWeight:"weight"}} onClick={handleLinkEmail}><b><u>AQUI</u></b></a> <br/>o podés contactarnos en forma directa vía mail a <a target="_BLANK" style={{cursor:"pointer", color:"black;", fontWeight:"weight"}} href={'mailto:'+setting.emailPricing}><b><u>{setting.emailPricing}</u></b></a>
            &nbsp;o a whatsapp/telegram al {setting.telPricing}<br/><br/>
            
            
            Si te interesa conocer más sobre nuestros planes<br></br> podés hacer click <a style={{cursor:"pointer", color:"black;", fontWeight:"weight"}} onClick={handleLink}><b><u>AQUI</u></b></a>
            </div>
        </div>
    );

    const handleLink = () => {
        const newWindow = window.open(setting.urlPricing, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const handleLinkEmail = () => {
        //const newWindow = window.open("mailto:"+setting.emailPricing+"?subject=Actualizar mi Plan en Carnes Validadas", '_blank', 'noopener,noreferrer')
        //if (newWindow) newWindow.opener = null
        toggleMailModal(true);
    }


    const [mailModalIsOpen, toggleMailModal] = useState(false);
    const [msgErrorMailModal, setMsgErrorMailModal] = useState('');
    const [buttonDisabledMailModal, setButtonDisabledMailModal] = useState(false);
    
    const mailModal = () => (
        <CVEmailTierModal            
            title={'Info'}            
            acceptText="Enviar"
            msgError={msgErrorMailModal}
            backdrop={"static"}
            keyboard={false}
            isOpen={mailModalIsOpen}
            toggleModal={() => toggleMailModal(!mailModalIsOpen)}
            hideCancelButton={false}
            cancel={() => toggleMailModal(false)}
            accept={acceptEmail}
            buttonDisabled={buttonDisabledMailModal}
            //acceptText={t('animals.modals.qr.download')}
        />
    );

    const acceptEmail = (context) => {
        setButtonDisabledMailModal(true);
        setMsgErrorMailModal("Aguarde un momento.");
        props.sendMailTierRequest(context,()=>{            
            toggleMailModal(false);
            toggleTierModal(false);
            setButtonDisabledMailModal(false);
            ToastsStore.success(t('Solicitud Enviada'), 10000)
            goBackList()
        },(error)=>{            
            setMsgErrorMailModal("No se ha podido enviar la solicitud, intente nuevamente.");
            setButtonDisabledMailModal(false);
        })
    }

    function goBackList() {
        props.history.push('/animals/table');
    }

    const tierModal = () => (
        <CVBasicModal
            title={'Plan'}
            body={renderTierBody}
            isOpen={tierModalIsOpen}
            backdrop={"static"}
            keyboard={false}
            toggleModal={() => toggleTierModal(!tierModalIsOpen)}
            hideCancelButton={true}
            //cancel={() => toggleQrModal(false)}
            accept={goBackList}
            //acceptText={t('animals.modals.qr.download')}
        />
    );

    useEffect(() => {        
        if (props.animals !== undefined) {
            setAnimals(props.animals);
            setTotal(props.animals.length)
            
            // analizo cantidad de registros OK
            let countOK = 0;            
            for (let animalTemp of props.animals) {
                if (animalTemp.status=='ok') {
                    countOK++;
                }
            }            
            setCountOK(countOK);
            
            
        }
    }, [props.animals]);

    useEffect(() => {        
                if (animalsAll !== undefined) {
                    setAnimalsToShow(animalsAll.slice( ((page-1) *pageSize),(page*pageSize)));
                }
            }, [page]);


    useEffect(() => {        
        console.log("debug01");
        evaluateIsTierAnimalsCountInRange();
    }, [countOK, animalsCountSelectedCompanyScope]);

    useEffect(()=>{        
        setCountOK(0);
        props.getCompanyScopeAnimalsCount(props.selectedEstablishment._id);
    },[])

    useEffect(()=>{        
        setAnimalsCountSelectedCompanyScope(props.animalsCountSelectedCompanyScope);
        
    },[props.animalsCountSelectedCompanyScope])

    const {t, isLoading, thereIsAnError, acceptMassiveAnimalUpload} = props;

    const renderValueDefault = ({value}) => {        
        return (
            <div style={{display: 'flex'}}>
                {value}
            </div>
        )
    };

    const renderBreed = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {/*{t(`animals.table.breed.${value}`)}*/}
                {value}
            </div>
        )
    };

    const renderSpecies = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {/*{t(`animals.table.species.${value}`)}*/}
                {value}
            </div>
        )
    };

    const renderSex = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {/*{t(`animals.table.headers.sex.${value}`)}*/}
                {value}
            </div>
        )
    };

    const renderCategory = ({value}) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {/*{t(`animals_categories.cow.${value}`)}*/}
                {value}
            </div>
        )
    };

    const renderStatus = ({value}) => {
        let Icon;

        switch (value) {
            case 'error':
                Icon = <CloseOutlineIcon color={'red'}/>;
                break;
            default:
                Icon = <CheckOutlineIcon color={'green'}/>;
                break;

        }

        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {Icon}
            </div>
        )
    };

    const renderErrorMessage = ({value}) => {
        return (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            {
              value.length > 0 ? t(`errors.${value[0]}`) : '-'
            }
          </div>
        )
    };

    const heads = [
        {
            key: 'rowNumber',
            name: 'Nro. de Fila',
            percentage: 7.6,
            minWidth: 80,
            formatter: renderValueDefault
        },
        {
            key: 'status',
            name: t('animals.table.headers.status'),
            percentage: 7.6,
            minWidth: 80,
            formatter: renderStatus
        },        
        {
            key: 'errors',
            name: t('animals.table.headers.error'),
            percentage: 15,
            minWidth: 250,
            formatter: renderErrorMessage
        },
        {
            key: 'earId',
            name: t('animals.table.headers.caravan_id'),
            percentage: 5,
            minWidth: 140,
            formatter: renderValueDefault
        },
        {
            key: 'fatherId',
            name: t('animals.table.headers.fatherId'),
            percentage: 5,
            minWidth: 100,
            formatter: renderValueDefault
        },
        {
            key: 'motherId',
            name: t('animals.table.headers.motherId'),
            percentage: 5,
            minWidth: 100,
            formatter: renderValueDefault
        },
        {
            key: 'breed',
            name: t('animals.table.headers.breed'),
            percentage: 5,
            minWidth: 100,
            formatter: renderBreed
        },
        {
            key: 'specie',
            name: t('animals.table.headers.species'),
            percentage: 7.6,
            minWidth: 100,
            formatter: renderSpecies
        },
        {
            key: 'category',
            name: t('animals.table.headers.category'),
            percentage: 7.6,
            minWidth: 100,
            formatter: renderCategory
        },
        {
            key: 'dentition',
            name: t('animals.table.headers.dentition'),
            percentage: 7.6,
            minWidth: 100,
            formatter: renderValueDefault
        },
        {
            key: 'birthDate',
            name: t('animals.table.headers.birth_date'),
            percentage: 7.6,
            minWidth: 110,
            formatter: renderValueDefault
        },
        {
            key: 'weight',
            name: t('animals.table.headers.weight'),
            percentage: 7.6,
            minWidth: 80,
            formatter: renderValueDefault
        },
        {
            key: 'productionSystem',
            name: t('animals.table.headers.production_system'),
            percentage: 11,
            minWidth: 150,
            formatter: renderValueDefault
        },
    ];

    const submit = () => {
        acceptMassiveAnimalUpload(props.excelId, () => {
            setRedirectRoute('/animals/table');
            alert.success(t('animals.alerts.massive_load_successfully'));
        }, (errorMessage) => {
            if(errorMessage === GENERIC_ERROR)
                alert.error(t('animals.alerts.massive_load_error'));
            else
                alert.error(t(`errors.${errorMessage}`));
        });
    };

    const cancel = () => {
        setRedirectRoute('/animals/table')
    };

    return (
        <div className="animal-massive-load">
        <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} lightBackground store={ToastsStore}/>
            {
                isLoading &&
                <div className="loading">
                    <img alt="cow" src={cowTransparent}/>
                    <span>{t('animals.massive_load_table.we_are_processing_the_file')}</span>
                </div>
            }
            {
                !isLoading &&
                <div>
                    <CVTable
                        getPage={(est,page,limit) => {  setPage(page)  }}
                        data={animalsToShow}
                        total={total}
                        currentPage={1}
                        heads={heads}
                        hideFilters
                        currentPage={page}
                        totalPages={totalPages}
                        pageSize={pageSize}
                        //hidePagination
                        recognizedAnimals={ {correct:animalsCorrect,incorrect:animalsIncorrect}}
                        title={t('animals.massive_load_table.title')}
                        noDataLabel={t('animals.massive_load_table.no_data_label')}
                    />
                    <div className="button-container">
                        {isTierAnimalsCountInRange &&
                        <CVButton color="primary" style={{width:"300px"}} disabled={animalsIncorrect>0} pending={props.acceptLoading} onClick={submit}>{t('common.importContinue')}</CVButton>
                        }
                        <Button className="btn btn-secondary" onClick={cancel}>{t('common.cancel')}</Button>
                        
                    </div>
                </div>
            }
            {redirectRoute && <Redirect to={redirectRoute}/>}
            {tierModal()}
            {mailModal()}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        animals: state.animal.massiveLoad.animals,
        isLoading: state.ui.uploadFileForMassiveAnimalsLoadPending,
        thereIsAnError: state.ui.uploadFileForMassiveAnimalsLoadError,
        excelId: state.animal.massiveLoad._id,
        acceptLoading: state.ui.acceptMassiveAnimalsLoadPending,
        acceptError: state.ui.acceptMassiveAnimalsLoadError,
        animalsCountSelectedCompanyScope: state.user.animalsCountSelectedCompanyScope,    
        selectedEstablishment: state.user.selectedEstablishment
    };
};

const mapDispatchToProps = (dispatch) => ({
    sendMailTierRequest: (data, successCallback, errorCallback) => dispatch(actions.session.sendMailTierRequest(data, successCallback, errorCallback)),
    getCompanyScopeAnimalsCount: (facilityId) => dispatch(actions.user.getCompanyScopeAnimalsCount(facilityId)),
    acceptMassiveAnimalUpload: (excelId, successCallback, errorCallback) =>
        dispatch(actions.animal.acceptMassiveAnimalsUpload(excelId, successCallback, errorCallback))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(withRouter(AnimalsMassiveLoad)))
