export const GET_USER = 'GET_USER';
export const GET_USER_RESPONSE = 'GET_USER_RESPONSE';
export const GET_USER_ERROR = 'GET_USER_RESPONSE_ERROR';
export const GET_USER_ROLES = 'GET_USER_ROLES';
export const GET_USER_ROLES_RESPONSE = 'GET_USER_ROLES_RESPONSE';
export const GET_USER_ROLES_ERROR = 'GET_USER_ROLES_ERROR';
export const GET_USER_APPID = 'GET_USER_APPID';
export const GET_USER_APPID_RESPONSE = 'GET_USER_APPID_RESPONSE';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_RESPONSE = 'GET_USER_PROFILE_RESPONSE';
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR';
export const EDIT_USER_DATA = 'EDIT_USER_DATA';
export const EDIT_USER_DATA_RESPONSE = 'EDIT_USER_DATA_RESPONSE';
export const EDIT_USER_DATA_ERROR = 'EDIT_USER_DATA_ERROR';
export const EDIT_USER_AVATAR = 'EDIT_USER_AVATAR';
export const EDIT_USER_AVATAR_RESPONSE = 'EDIT_USER_AVATAR_RESPONSE';
export const EDIT_USER_AVATAR_ERROR = 'EDIT_USER_AVATAR_ERROR';
export const SELECT_ESTABLISHMENT = 'SELECT_ESTABLISHMENT';
export const SELECT_ESTABLISHMENT_LOT_MODE = 'SELECT_ESTABLISHMENT_LOT_MODE';
export const SELECT_ESTABLISHMENT_LOT_TO_EDIT = 'SELECT_ESTABLISHMENT_LOT_TO_EDIT';
export const SELECT_COMPANY = 'SELECT_COMPANY';
export const SELECT_LOT = 'SELECT_LOT';
export const GET_USER_ACCOUNTS = 'GET_USER_ACCOUNTS';
export const GET_USER_ACCOUNTS_RESPONSE = 'GET_USER_ACCOUNTS_RESPONSE';
export const GET_USER_ACCOUNTS_ERROR = 'GET_USER_ACCOUNTS_ERROR';
export const GET_SURROGATE_USER_ACCOUNTS = 'GET_SURROGATE_USER_ACCOUNTS';
export const GET_SURROGATE_USER_ACCOUNTS_RESPONSE = 'GET_SURROGATE_USER_ACCOUNTS_RESPONSE';
export const GET_SURROGATE_USER_ACCOUNTS_ERROR = 'GET_SURROGATE_USER_ACCOUNTS_ERROR';
export const GET_SURROGATE_USER_ESTABLISHMENTS = 'GET_SURROGATE_USER_ESTABLISHMENTS';
export const GET_SURROGATE_USER_ESTABLISHMENTS_RESPONSE = 'GET_SURROGATE_USER_ESTABLISHMENTS_RESPONSE';
export const GET_SURROGATE_USER_ESTABLISHMENTS_ERROR = 'GET_SURROGATE_USER_ESTABLISHMENTS_ERROR';
export const ADD_USER_COMPANY = 'ADD_USER_COMPANY';
export const ADD_USER_COMPANY_RESPONSE = 'ADD_USER_COMPANY_RESPONSE';
export const ADD_USER_COMPANY_ERROR = 'ADD_USER_COMPANY_ERROR';
export const ADD_ESTABLISHMENT_TO_COMPANY = 'ADD_ESTABLISHMENT_TO_COMPANY';
export const ADD_ESTABLISHMENT_TO_COMPANY_RESPONSE = 'ADD_ESTABLISHMENT_TO_COMPANY_RESPONSE';
export const ADD_ESTABLISHMENT_TO_COMPANY_ERROR = 'ADD_ESTABLISHMENT_TO_COMPANY_ERROR';
export const ADD_ROLE_TO_USER = 'ADD_ROLE_TO_USER';
export const ADD_ROLE_TO_USER_RESPONSE = 'ADD_ROLE_TO_USER_RESPONSE';
export const ADD_ROLE_TO_USER_ERROR = 'ADD_ROLE_TO_USER_ERROR';
export const ADD_ROLE_TO_USER_WITHOUT_LICENSE = 'ADD_ROLE_TO_USER_WITHOUT_LICENSE';
export const ADD_ROLE_TO_USER_WITHOUT_LICENSE_RESPONSE = 'ADD_ROLE_TO_USER_WITHOUT_LICENSE_RESPONSE';
export const ADD_ROLE_TO_USER_WITHOUT_LICENSE_ERROR = 'ADD_ROLE_TO_USER_WITHOUT_LICENSE_ERROR';
export const UPLOAD_ESTABLISHMENT_IMAGES = 'UPLOAD_ESTABLISHMENT_IMAGES';
export const UPLOAD_COMPANY_IMAGES = 'UPLOAD_COMPANY_IMAGES';
export const GET_USER_COMPANY = 'GET_USER_COMPANY';
export const GET_USER_COMPANY_RESPONSE = 'GET_USER_COMPANY_RESPONSE';
export const GET_USER_COMPANY_ERROR = 'GET_USER_COMPANY_ERROR';
export const EDIT_USER_COMPANY = 'EDIT_USER_COMPANY';
export const EDIT_USER_COMPANY_RESPONSE = 'EDIT_USER_COMPANY_RESPONSE';
export const EDIT_USER_COMPANY_ERROR = 'EDIT_USER_COMPANY_ERROR';
export const GET_USER_ESTABLISHMENTS = 'GET_USER_ESTABLISHMENTS';
export const GET_USER_ESTABLISHMENTS_RESPONSE = 'GET_USER_ESTABLISHMENTS_RESPONSE';
export const GET_USER_ESTABLISHMENTS_ERROR = 'GET_USER_ESTABLISHMENTS_ERROR';
export const GET_USER_ESTABLISHMENTS_BY_COMPANIES_PAGINATED = 'GET_USER_ESTABLISHMENTS_BY_COMPANIES_PAGINATED';
export const GET_USER_ESTABLISHMENTS_BY_COMPANIES_PAGINATED_RESPONSE = 'GET_USER_ESTABLISHMENTS_BY_COMPANIES_PAGINATED_RESPONSE';
export const GET_USER_ESTABLISHMENTS_BY_COMPANIES_PAGINATED_ERROR = 'GET_USER_ESTABLISHMENTS_BY_COMPANIES_PAGINATED_ERROR';
export const GET_ALL_USER_ESTABLISHMENTS = 'GET_ALL_USER_ESTABLISHMENTS';
export const GET_ALL_USER_ESTABLISHMENTS_RESPONSE = 'GET_ALL_USER_ESTABLISHMENTS_RESPONSE';
export const GET_ALL_USER_ESTABLISHMENTS_ERROR = 'GET_ALL_USER_ESTABLISHMENTS_ERROR';
export const GET_USER_ESTABLISHMENT_BY_ID = 'GET_USER_ESTABLISHMENT_BY_ID';
export const GET_USER_ESTABLISHMENT_BY_ID_RESPONSE = 'GET_USER_ESTABLISHMENT_BY_ID_RESPONSE';
export const GET_USER_ESTABLISHMENT_BY_ID_ERROR = 'GET_USER_ESTABLISHMENT_BY_ID_ERROR';
export const EDIT_USER_ESTABLISHMENT_BY_ID = 'EDIT_USER_ESTABLISHMENT_BY_ID';
export const EDIT_USER_ESTABLISHMENT_BY_ID_RESPONSE = 'EDIT_USER_ESTABLISHMENT_BY_ID_RESPONSE';
export const EDIT_USER_ESTABLISHMENT_BY_ID_ERROR = 'EDIT_USER_ESTABLISHMENT_BY_ID_ERROR';
export const GET_ALL_USER_COMPANIES = 'GET_ALL_USER_COMPANIES';
export const GET_ALL_USER_COMPANIES_RESPONSE = 'GET_ALL_USER_COMPANIES_RESPONSE';
export const GET_ALL_USER_COMPANIES_ERROR = 'GET_ALL_USER_COMPANIES_ERROR';
export const DELETE_USER_COMPANY = 'DELETE_USER_COMPANY';
export const DELETE_USER_COMPANY_ERROR = 'DELETE_USER_COMPANY_ERROR';
export const DELETE_USER_ESTABLISHMENT = 'DELETE_USER_ESTABLISHMENT';
export const DELETE_USER_ESTABLISHMENT_ERROR = 'DELETE_USER_ESTABLISHMENT_ERROR';
export const DELETE_USER_ESTABLISHMENT_LOT = 'DELETE_USER_ESTABLISHMENT_LOT';
export const DELETE_USER_ESTABLISHMENT_LOT_ERROR = 'DELETE_USER_ESTABLISHMENT_LOT_ERROR';
export const RESET_ACCOUNT_DETAILS = 'RESET_ACCOUNT_DETAILS';
export const RESET_SELECTED_LOT = 'RESET_SELECTED_LOT';
export const RESET_ESTABLISHMENT_DETAILS = 'RESET_ESTABLISHMENT_DETAILS';
export const GET_USER_ESTABLISHMENTS_BY_COMPANY = "GET_USER_ESTABLISHMENTS_BY_COMPANY";
export const GET_USER_ESTABLISHMENTS_BY_COMPANY_RESPONSE = "GET_USER_ESTABLISHMENTS_BY_COMPANY_RESPONSE";
export const GET_USER_ESTABLISHMENTS_BY_COMPANY_ERROR = "GET_USER_ESTABLISHMENTS_BY_COMPANY_ERROR";
export const RESET_SELECTED_ESTABLISHMENT = "RESET_SELECTED_ESTABLISHMENT";
export const RESET_SELECTED_COMPANY = "RESET_SELECTED_COMPANY";
export const GET_PROFESSIONAL_USER_BY_DNI = "GET_PROFESSIONAL_USER_BY_DNI";
export const GET_PROFESSIONAL_USER_BY_DNI_RESPONSE = "GET_PROFESSIONAL_USER_BY_DNI_RESPONSE";
export const GET_PROFESSIONAL_USER_BY_DNI_ERROR = "GET_PROFESSIONAL_USER_BY_DNI_ERROR";
export const RESET_PROFESSIONAL_USER_SEARCH = "RESET_PROFESSIONAL_USER_SEARCH";
export const GET_PROFESSIONALS_BY_ESTABLISHMENT = "GET_PROFESSIONALS_BY_ESTABLISHMENT";
export const GET_PROFESSIONALS_BY_ESTABLISHMENT_RESPONSE = "GET_PROFESSIONALS_BY_ESTABLISHMENT_RESPONSE";
export const GET_PROFESSIONALS_BY_ESTABLISHMENT_ERROR = "GET_PROFESSIONALS_BY_ESTABLISHMENT_ERROR";
export const ADD_PROFESSIONAL_TO_ESTABLISHMENT = "ADD_PROFESSIONAL_TO_ESTABLISHMENT";
export const ADD_PROFESSIONAL_TO_ESTABLISHMENT_RESPONSE = "ADD_PROFESSIONAL_TO_ESTABLISHMENT_RESPONSE";
export const ADD_PROFESSIONAL_TO_ESTABLISHMENT_ERROR = "ADD_PROFESSIONAL_TO_ESTABLISHMENT_ERROR";
export const DELETE_PROFESSIONAL_FROM_ESTABLISHMENT = "DELETE_PROFESSIONAL_FROM_ESTABLISHMENT";
export const DELETE_PROFESSIONAL_FROM_ESTABLISHMENT_RESPONSE = "DELETE_PROFESSIONAL_FROM_ESTABLISHMENT_RESPONSE";
export const DELETE_PROFESSIONAL_FROM_ESTABLISHMENT_ERROR = "DELETE_PROFESSIONAL_FROM_ESTABLISHMENT_ERROR";
export const GET_ASSIGNED_ESTABLISHMENTS = "GET_ASSIGNED_ESTABLISHMENTS";
export const GET_ASSIGNED_ESTABLISHMENTS_RESPONSE = "GET_ASSIGNED_ESTABLISHMENTS_RESPONSE";
export const GET_ASSIGNED_ESTABLISHMENTS_ERROR = "GET_ASSIGNED_ESTABLISHMENTS_ERROR";
export const GET_ASSIGNED_COMPANIES = "GET_ASSIGNED_COMPANIES";
export const GET_ASSIGNED_COMPANIES_RESPONSE = "GET_ASSIGNED_COMPANIES_RESPONSE";
export const GET_ASSIGNED_COMPANIES_ERROR = "GET_ASSIGNED_COMPANIES_ERROR";
export const GET_SURROGATE_USER_BY_DNI = "GET_SURROGATE_USER_BY_DNI";
export const GET_SURROGATE_USER_BY_DNI_RESPONSE = "GET_SURROGATE_USER_BY_DNI_RESPONSE";
export const GET_SURROGATE_USER_BY_DNI_ERROR = "GET_SURROGATE_USER_BY_DNI_ERROR";
export const RESET_SURROGATE_USER_SEARCH = "RESET_SURROGATE_USER_SEARCH";
export const GET_SURROGATES_BY_COMPANY = "GET_SURROGATES_BY_COMPANY";
export const GET_SURROGATES_BY_COMPANY_RESPONSE = "GET_SURROGATES_BY_COMPANY_RESPONSE";
export const GET_SURROGATES_BY_COMPANY_ERROR = "GET_SURROGATES_BY_COMPANY_ERROR";
export const GET_HAS_SLAUGHTERHOUSE_OR_COMMERCIAL = "GET_HAS_SLAUGHTERHOUSE_OR_COMMERCIAL";
export const GET_HAS_SLAUGHTERHOUSE_OR_COMMERCIAL_RESPONSE = "GET_HAS_SLAUGHTERHOUSE_OR_COMMERCIAL_RESPONSE";
export const GET_HAS_SLAUGHTERHOUSE_OR_COMMERCIAL_ERROR = "GET_HAS_SLAUGHTERHOUSE_OR_COMMERCIAL_ERROR";
export const ADD_SURROGATE_TO_COMPANY = "ADD_SURROGATE_TO_COMPANY";
export const ADD_SURROGATE_TO_COMPANY_RESPONSE = "ADD_SURROGATE_TO_COMPANY_RESPONSE";
export const ADD_SURROGATE_TO_COMPANY_ERROR = "ADD_SURROGATE_TO_COMPANY_ERROR";
export const DELETE_USER_FROM_COMPANY = "DELETE_USER_FROM_COMPANY";
export const DELETE_USER_FROM_COMPANY_RESPONSE = "DELETE_USER_FROM_COMPANY_RESPONSE";
export const DELETE_USER_FROM_COMPANY_ERROR = "DELETE_USER_FROM_COMPANY_ERROR";
export const SEND_EMAIL_TO_ESTABLISHMENT = "SEND_EMAIL_TO_ESTABLISHMENT";
export const SEND_EMAIL_TO_ESTABLISHMENT_RESPONSE = "SEND_EMAIL_TO_ESTABLISHMENT_RESPONSE";
export const SEND_EMAIL_TO_ESTABLISHMENT_ERROR = "SEND_EMAIL_TO_ESTABLISHMENT_ERROR";
export const SEND_EMAIL_TO_UNREGISTERED = "SEND_EMAIL_TO_UNREGISTERED";
export const SEND_EMAIL_TO_UNREGISTERED_RESPONSE = "SEND_EMAIL_TO_UNREGISTERED_RESPONSE";
export const SEND_EMAIL_TO_UNREGISTERED_ERROR = "SEND_EMAIL_TO_UNREGISTERED_ERROR";
export const SEND_EMAIL_TO_UNREGISTERED_SURROGATE = "SEND_EMAIL_TO_UNREGISTERED_SURROGATE";
export const SEND_EMAIL_TO_UNREGISTERED_SURROGATE_RESPONSE = "SEND_EMAIL_TO_UNREGISTERED_SURROGATE_RESPONSE";
export const SEND_EMAIL_TO_UNREGISTERED_SURROGATE_ERROR = "SEND_EMAIL_TO_UNREGISTERED_SURROGATE_ERROR";
export const SUBMIT_ESTABLISHMENT_LOT = 'SUBMIT_ESTABLISHMENT_LOT';
export const SUBMIT_ESTABLISHMENT_LOT_RESPONSE = 'SUBMIT_ESTABLISHMENT_LOT_RESPONSE';
export const SUBMIT_ESTABLISHMENT_LOT_ERROR = 'SUBMIT_ESTABLISHMENT_LOT_ERROR';
export const SET_DEFAULT_STATE = 'SET_DEFAULT_STATE';
export const EDIT_ESTABLISHMENT_LOT = 'EDIT_ESTABLISHMENT_LOT';
export const EDIT_ESTABLISHMENT_LOT_RESPONSE = 'EDIT_ESTABLISHMENT_LOT_RESPONSE';
export const EDIT_ESTABLISHMENT_LOT_ERROR = 'EDIT_ESTABLISHMENT_LOT_ERROR';
export const GET_COMPANY_SCOPE_ANIMALS_COUNT = 'GET_COMPANY_SCOPE_ANIMALS_COUNT';
export const GET_COMPANY_SCOPE_ANIMALS_COUNT_RESPONSE = 'GET_COMPANY_SCOPE_ANIMALS_COUNT_RESPONSE';
export const GET_COMPANY_SCOPE_ANIMALS_COUNT_ERROR = 'GET_COMPANY_SCOPE_ANIMALS_COUNT_ERROR';
export const GET_COUNT_FACILITIES_BY_TYPE = 'GET_COUNT_FACILITIES_BY_TYPE';
export const GET_COUNT_FACILITIES_BY_TYPE_RESPONSE = 'GET_COUNT_FACILITIES_BY_TYPE_RESPONSE';
export const GET_COUNT_FACILITIES_BY_TYPE_ERROR = 'GET_COUNT_FACILITIES_BY_TYPE_ERROR';
export const GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS';
export const GET_USER_NOTIFICATIONS_RESPONSE = 'GET_USER_NOTIFICATIONS_RESPONSE';
export const GET_USER_NOTIFICATIONS_ERROR = 'GET_USER_NOTIFICATIONS_ERROR';

const userActions = {
    getUser(callback){
        return {
            type: GET_USER,
            callback
        }
    },
    getUserResponse(user){
        return {
            type: GET_USER_RESPONSE,
            user
        }
    },
    getUserError(message){
        return {
            type: GET_USER_ERROR,
            message
        }
    },
    getUserRoles(){
        return {
            type: GET_USER_ROLES
        }
    },
    getUserRolesResponse(roles){
        return {
            type: GET_USER_ROLES_RESPONSE,
            roles
        }
    },
    getUserRolesError(message){
        return {
            type: GET_USER_ROLES_ERROR,
            message
        }
    },
    getUserAppId(){
        return {
            type: GET_USER_APPID
        }
    },
    getUserAppIdResponse(appId){
        return {
            type: GET_USER_APPID_RESPONSE,
            appId
        }
    },
    getUserProfile(userId) {
        return {
            type: GET_USER_PROFILE,
            userId
        }
    },
    getUserProfileResponse(profile) {
        return {
            type: GET_USER_PROFILE_RESPONSE,
            profile
        }
    },
    getUserProfileError(message) {
        return {
            type: GET_USER_PROFILE_ERROR,
            message
        }
    },
    editUserData(data, callback) {
        return {
            type: EDIT_USER_DATA,
            data,
            callback
        }
    },
    editUserDataResponse(response) {
        return {
            type: EDIT_USER_DATA_RESPONSE,
            response
        }
    },
    editUserDataError(message) {
        return {
            type: EDIT_USER_DATA_ERROR,
            message
        }
    },
    editUserAvatar(image, callback) {
        return {
            type: EDIT_USER_AVATAR,
            image,
            callback
        }
    },
    editUserAvatarResponse(response) {
        return {
            type: EDIT_USER_AVATAR_RESPONSE,
            response
        }
    },
    editUserAvatarError(message) {
        return {
            type: EDIT_USER_AVATAR_ERROR,
            message
        }
    },
    selectEstablishment(establishment) {
        return {
            type: SELECT_ESTABLISHMENT,
            establishment
        }
    },
    selectEstablishmentLotMode(lotMode) {
        return {
            type: SELECT_ESTABLISHMENT_LOT_MODE,
            lotMode
        }
    },
    selectEstablishmentLotToEdit(establishmentId, lotId, name, details) {
        return {
            type: SELECT_ESTABLISHMENT_LOT_TO_EDIT,
            establishmentId,
            lotId,
            name,
            details
        }
    },
    selectLot(lot) {
        return {
            type: SELECT_LOT,
            lot
        }
    },
    selectCompany(company) {
        return {
            type: SELECT_COMPANY,
            company
        }
    },
    getAccounts(page, pageSize, callback){
        return {
            type: GET_USER_ACCOUNTS,
            page,
            pageSize,
            callback
        }
    },
    getAccountsResponse(response){
        return {
            type: GET_USER_ACCOUNTS_RESPONSE,
            response
        }
    },
    getAccountsError(message){
        return {
            type: GET_USER_ACCOUNTS_ERROR,
            message
        }
    },
    getSurrogateAccounts(page, pageSize, callback){
        return {
            type: GET_SURROGATE_USER_ACCOUNTS,
            page,
            pageSize,
            callback
        }
    },
    getSurrogateAccountsResponse(response){
        return {
            type: GET_SURROGATE_USER_ACCOUNTS_RESPONSE,
            response
        }
    },
    getSurrogateAccountsError(message){
        return {
            type: GET_SURROGATE_USER_ACCOUNTS_ERROR,
            message
        }
    },
    getSurrogateEstablishments(page, pageSize, callback){
        return {
            type: GET_SURROGATE_USER_ESTABLISHMENTS,
            page,
            pageSize,
            callback
        }
    },
    getSurrogateEstablishmentsResponse(response){
        return {
            type: GET_SURROGATE_USER_ESTABLISHMENTS_RESPONSE,
            response
        }
    },
    getSurrogateEstablishmentsError(message){
        return {
            type: GET_SURROGATE_USER_ESTABLISHMENTS_ERROR,
            message
        }
    },
    getCompanyScopeAnimalsCount(facilityId) {
        return {
            type: GET_COMPANY_SCOPE_ANIMALS_COUNT,
            facilityId
        }
    },
    getCompanyScopeAnimalsCountResponse(response) {
        return {
            type: GET_COMPANY_SCOPE_ANIMALS_COUNT_RESPONSE,
            response
        }
    },
    getCompanyScopeAnimalsCountError(message) {
        return {
            type: GET_COMPANY_SCOPE_ANIMALS_COUNT_ERROR,
            message
        }
    },


    addCompany(info, successCallback, errorCallback){
        return {
            type: ADD_USER_COMPANY,
            info,
            successCallback,
            errorCallback
        }
    },
    addCompanyResponse(response){
        return {
            type: ADD_USER_COMPANY_RESPONSE,
            response
        }
    },
    addCompanyError(message){
        return {
            type: ADD_USER_COMPANY_ERROR,
            message
        }
    },
    addEstablishmentToCompany(info, companyId, establishmentType, successCallback, errorCallback){
        return {
            type: ADD_ESTABLISHMENT_TO_COMPANY,
            info,
            companyId,
            establishmentType,
            successCallback,
            errorCallback
        }
    },
    addEstablishmentToCompanyResponse(response){
        return {
            type: ADD_ESTABLISHMENT_TO_COMPANY_RESPONSE,
            response
        }
    },
    addEstablishmentToCompanyError(message){
        return {
            type: ADD_ESTABLISHMENT_TO_COMPANY_ERROR,
            message
        }
    },
    addRoleToUser(userId, role, license, callback){
        return {
            type: ADD_ROLE_TO_USER,
            userId,
            role,
            license,
            callback
        }
    },
    addRoleToUserWithoutLicense(userId, role, license, callback){
        return {
            type: ADD_ROLE_TO_USER_WITHOUT_LICENSE,
            userId,
            role,
            license,
            callback
        }
    },
    addRoleToUserResponse(response){
        return {
            type: ADD_ROLE_TO_USER_RESPONSE,
            response
        }
    },
    addRoleToUserWithoutLicenseResponse(response){
        return {
            type: ADD_ROLE_TO_USER_WITHOUT_LICENSE_RESPONSE,
            response
        }
    },
    addRoleToUserError(message){
        return {
            type: ADD_ROLE_TO_USER_ERROR,
            message
        }
    },
    addRoleToUserWithoutLicenseError(message){
        return {
            type: ADD_ROLE_TO_USER_WITHOUT_LICENSE_ERROR,
            message
        }
    },
    uploadEstablishmentImages(images, callback){
        return {
            type: UPLOAD_ESTABLISHMENT_IMAGES,
            images,
            callback
        }
    },
    uploadCompanyImages(images, callback){
        return {
            type: UPLOAD_COMPANY_IMAGES,
            images,
            callback
        }
    },
    getCompany(companyId) {
        return {
            type: GET_USER_COMPANY,
            companyId
        }
    },
    getCompanyResponse(response) {
        return {
            type: GET_USER_COMPANY_RESPONSE,
            response
        }
    },
    getCompanyError(message) {
        return {
            type: GET_USER_COMPANY_ERROR,
            message
        }
    },
    editCompany(companyId, data, successCallback, errorCallback) {
        return {
            type: EDIT_USER_COMPANY,
            companyId,
            data,
            successCallback,
            errorCallback
        }
    },
    editCompanyResponse(response) {
        return {
            type: EDIT_USER_COMPANY_RESPONSE,
            response
        }
    },
    editCompanyError(message) {
        return {
            type: EDIT_USER_COMPANY_ERROR,
            message
        }
    },
    getUserEstablishmentsByCompaniesPaginated(page, pageSize, callback) {
        return {
            type: GET_USER_ESTABLISHMENTS_BY_COMPANIES_PAGINATED,
            page,
            pageSize,
            callback
        }
    },
    getUserEstablishmentsByCompaniesPaginatedResponse(response) {
        return {
            type: GET_USER_ESTABLISHMENTS_BY_COMPANIES_PAGINATED_RESPONSE,
            response
        }
    },
    getUserEstablishmentsByCompaniesPaginatedError(message) {
        return {
            type: GET_USER_ESTABLISHMENTS_BY_COMPANIES_PAGINATED_ERROR,
            message
        }
    },
    getEstablishments(page, pageSize, callback) {
        return {
            type: GET_USER_ESTABLISHMENTS,
            page,
            pageSize,
            callback
        }
    },
    getEstablishmentsResponse(response) {
        return {
            type: GET_USER_ESTABLISHMENTS_RESPONSE,
            response
        }
    },
    getEstablishmentsError(message) {
        return {
            type: GET_USER_ESTABLISHMENTS_ERROR,
            message
        }
    },
    getAllUserEstablishments() {
        return {
            type: GET_ALL_USER_ESTABLISHMENTS
        }
    },
    getAllUserEstablishmentsResponse(response) {
        return {
            type: GET_ALL_USER_ESTABLISHMENTS_RESPONSE,
            response
        }
    },
    getAllUserEstablishmentsError(message) {
        return {
            type: GET_ALL_USER_ESTABLISHMENTS_ERROR,
            message
        }
    },
    getEstablishmentById(establishmentId, callback) {
        return {
            type: GET_USER_ESTABLISHMENT_BY_ID,
            establishmentId,
            callback
        }
    },
    getEstablishmentByIdResponse(response) {
        return {
            type: GET_USER_ESTABLISHMENT_BY_ID_RESPONSE,
            response
        }
    },
    getEstablishmentByIdError(message) {
        return {
            type: GET_USER_ESTABLISHMENT_BY_ID_ERROR,
            message
        }
    },
    editEstablishmentById(establishmentId, establishmentType, data, successCallback, errorCallback) {
        return {
            type: EDIT_USER_ESTABLISHMENT_BY_ID,
            establishmentId,
            establishmentType,
            data,
            successCallback,
            errorCallback
        }
    },
    editEstablishmentByIdResponse(response) {
        return {
            type: EDIT_USER_ESTABLISHMENT_BY_ID_RESPONSE,
            response
        }
    },
    editEstablishmentByIdError(message) {
        return {
            type: EDIT_USER_ESTABLISHMENT_BY_ID_ERROR,
            message
        }
    },
    getAllUserCompanies() {
        return {
            type: GET_ALL_USER_COMPANIES
        }
    },
    getAllUserCompaniesResponse(response) {
        return {
            type: GET_ALL_USER_COMPANIES_RESPONSE,
            response
        }
    },
    getAllUserCompaniesError(message) {
        return {
            type: GET_ALL_USER_COMPANIES_ERROR,
            message
        }
    },
    deleteUserCompanyById(companyId, callback, errorCallback) {
        return {
            type: DELETE_USER_COMPANY,
            companyId,
            callback,
            errorCallback
        }
    },
    deleteUserCompanyByIdError(message) {
        return {
            type: DELETE_USER_COMPANY_ERROR,
            message
        }
    },
    deleteUserEstablishmentById(establishmentId, callback, errorCallback) {
        return {
            type: DELETE_USER_ESTABLISHMENT,
            establishmentId,
            callback,
            errorCallback
        }
    },
    deleteUserEstablishmentByIdError(message) {
        return {
            type: DELETE_USER_ESTABLISHMENT_ERROR,
            message
        }
    },
    deleteUserEstablishmentLotById(establishmentLotId, callback) {
        return {
            type: DELETE_USER_ESTABLISHMENT_LOT,
            establishmentLotId,
            callback
        }
    },
    deleteUserEstablishmentLotByIdError(message) {
        return {
            type: DELETE_USER_ESTABLISHMENT_LOT_ERROR,
            message
        }
    },
    submitEstablishmentLot(values, successCallback, errorCallback) {
        return {
            type: SUBMIT_ESTABLISHMENT_LOT,
            values,
            successCallback,
            errorCallback
        }
    },
    submitEstablishmentLotResponse(response) {
        return {
            type: SUBMIT_ESTABLISHMENT_LOT_RESPONSE,
            response
        }
    },
    submitEstablishmentLotError(message) {
        return {
            type: SUBMIT_ESTABLISHMENT_LOT_ERROR,
            message
        }
    },
    editEstablishmentLot(data, successCallback, errorCallback) {
        return {
            type: EDIT_ESTABLISHMENT_LOT,
            data,
            successCallback,
            errorCallback
        }
    },
    editEstablishmentLotResponse(response){
        return {
            type: EDIT_ESTABLISHMENT_LOT_RESPONSE,
            response
        }
    },
    editEstablishmentLotError(error){
        return {
            type: EDIT_ESTABLISHMENT_LOT_ERROR,
            error
        }
    },
    resetAccountDetails() {
        return {
            type: RESET_ACCOUNT_DETAILS
        }
    },
    resetEstablishmentDetails() {
        return {
            type: RESET_ESTABLISHMENT_DETAILS
        }
    },
    getUserEstablishmentsByCompany(companyId) {
        return {
            type: GET_USER_ESTABLISHMENTS_BY_COMPANY,
            companyId
        }
    },
    getUserEstablishmentsByCompanyResponse(response) {
        return {
            type: GET_USER_ESTABLISHMENTS_BY_COMPANY_RESPONSE,
            response
        }
    },
    getUserEstablishmentsByCompanyError(message) {
        return {
            type: GET_USER_ESTABLISHMENTS_BY_COMPANY_ERROR,
            message
        }
    },
    resetSelectedEstablishment() {
        return {
            type: RESET_SELECTED_ESTABLISHMENT
        }
    },
    resetSelectedCompany() {
        return {
            type: RESET_SELECTED_COMPANY
        }
    },
    resetSelectedLot() {
        return {
            type: RESET_SELECTED_LOT
        }
    },
    getProfessionalUserByDni(dni) {
        return {
            type: GET_PROFESSIONAL_USER_BY_DNI,
            dni
        }
    },
    getProfessionalUserByDniResponse(response) {
        return {
            type: GET_PROFESSIONAL_USER_BY_DNI_RESPONSE,
            response
        }
    },
    getProfessionalUserByDniError(message) {
        return {
            type: GET_PROFESSIONAL_USER_BY_DNI_ERROR,
            message
        }
    },
    resetProfessionalUserSearch() {
        return {
            type: RESET_PROFESSIONAL_USER_SEARCH
        }
    },
    getProfessionalsByEstablishment(establishmentId) {
        return {
            type: GET_PROFESSIONALS_BY_ESTABLISHMENT,
            establishmentId
        }
    },
    getProfessionalsByEstablishmentResponse(response) {
        return {
            type: GET_PROFESSIONALS_BY_ESTABLISHMENT_RESPONSE,
            response
        }
    },
    getProfessionalsByEstablishmentError(message) {
        return {
            type: GET_PROFESSIONALS_BY_ESTABLISHMENT_ERROR,
            message
        }
    },
    addProfessionalToEstablishment(establishmentId, userId, role) {
        return {
            type: ADD_PROFESSIONAL_TO_ESTABLISHMENT,
            establishmentId,
            userId,
            role
        }
    },
    addProfessionalToEstablishmentResponse(response) {
        return {
            type: ADD_PROFESSIONAL_TO_ESTABLISHMENT_RESPONSE,
            response
        }
    },
    addProfessionalToEstablishmentError(message) {
        return {
            type: ADD_PROFESSIONAL_TO_ESTABLISHMENT_ERROR,
            message
        }
    },
    deleteProfessionalFromEstablishment(establishmentId, userId, role) {
        return {
            type: DELETE_PROFESSIONAL_FROM_ESTABLISHMENT,
            establishmentId,
            userId,
            role
        }
    },
    deleteProfessionalFromEstablishmentResponse(response) {
        return {
            type: DELETE_PROFESSIONAL_FROM_ESTABLISHMENT_RESPONSE,
            response
        }
    },
    deleteProfessionalFromEstablishmentError(message) {
        return {
            type: DELETE_PROFESSIONAL_FROM_ESTABLISHMENT_ERROR,
            message
        }
    },
    getAssignedEstablishments(userId) {
        return {
            type: GET_ASSIGNED_ESTABLISHMENTS,
            userId
        }
    },
    getAssignedEstablishmentsResponse(response) {
        return {
            type: GET_ASSIGNED_ESTABLISHMENTS_RESPONSE,
            response
        }
    },
    getAssignedEstablishmentsError(message) {
        return {
            type: GET_ASSIGNED_ESTABLISHMENTS_ERROR,
            message
        }
    },
    getAssignedCompanies(userId) {
        return {
            type: GET_ASSIGNED_COMPANIES,
            userId
        }
    },
    getAssignedCompaniesResponse(response) {
        return {
            type: GET_ASSIGNED_COMPANIES_RESPONSE,
            response
        }
    },
    getAssignedCompaniesError(message) {
        return {
            type: GET_ASSIGNED_COMPANIES_ERROR,
            message
        }
    },
    getSurrogateUserByDni(dni) {
        return {
            type: GET_SURROGATE_USER_BY_DNI,
            dni
        }
    },
    getSurrogateUserByDniResponse(response) {
        return {
            type: GET_SURROGATE_USER_BY_DNI_RESPONSE,
            response
        }
    },
    getSurrogateUserByDniError(message) {
        return {
            type: GET_SURROGATE_USER_BY_DNI_ERROR,
            message
        }
    },
    resetSurrogateUserSearch() {
        return {
            type: RESET_SURROGATE_USER_SEARCH
        }
    },
    addUserToCompany(companyId, userId, role) {
        return {
            type: ADD_SURROGATE_TO_COMPANY,
            companyId,
            userId,
            role
        }
    },
    addUserToCompanyResponse(response) {
        return {
            type: ADD_SURROGATE_TO_COMPANY_RESPONSE,
            response
        }
    },
    addUserToCompanyError(message) {
        return {
            type: ADD_SURROGATE_TO_COMPANY_ERROR,
            message
        }
    },
    getSurrogatesByCompany(companyId) {
        return {
            type: GET_SURROGATES_BY_COMPANY,
            companyId
        }
    },
    getSurrogatesByCompanyResponse(response) {
        return {
            type: GET_SURROGATES_BY_COMPANY_RESPONSE,
            response
        }
    },
    getSurrogatesByCompanyError(message) {
        return {
            type: GET_SURROGATES_BY_COMPANY_ERROR,
            message
        }
    },
    getHasSlaughterhouseOrCommercial(userId, role) {
        return {
            type: GET_HAS_SLAUGHTERHOUSE_OR_COMMERCIAL,
            userId,
            role
        }
    },
    getHasSlaughterhouseOrCommercialResponse(response) {
        return {
            type: GET_HAS_SLAUGHTERHOUSE_OR_COMMERCIAL_RESPONSE,
            response
        }
    },
    getHasSlaughterhouseOrCommercialError(message) {
        return {
            type: GET_HAS_SLAUGHTERHOUSE_OR_COMMERCIAL_ERROR,
            message
        }
    },
    deleteUserFromCompany(companyId, userId) {
        return {
            type: DELETE_USER_FROM_COMPANY,
            companyId,
            userId
        }
    },
    deleteUserFromCompanyResponse(response) {
        return {
            type: DELETE_USER_FROM_COMPANY_RESPONSE,
            response
        }
    },
    deleteUserFromCompanyError(message) {
        return {
            type: DELETE_USER_FROM_COMPANY_ERROR,
            message
        }
    },
    sendMailToEstablishment(establishmentId, userId) {
        return {
            type: SEND_EMAIL_TO_ESTABLISHMENT,
            establishmentId,
            userId
        }
    },
    sendMailToEstablishmentResponse(response) {
        return {
            type: SEND_EMAIL_TO_ESTABLISHMENT_RESPONSE,
            response
        }
    },
    sendMailToEstablishmentError(message) {
        return {
            type: SEND_EMAIL_TO_ESTABLISHMENT_ERROR,
            message
        }
    },
    sendMailToUnregistered(establishmentId, email) {
        return {
            type: SEND_EMAIL_TO_UNREGISTERED,
            establishmentId,
            email
        }
    },
    sendMailToUnregisteredResponse(response) {
        return {
            type: SEND_EMAIL_TO_UNREGISTERED_RESPONSE,
            response
        }
    },
    sendMailToUnregisteredError(message) {
        return {
            type: SEND_EMAIL_TO_UNREGISTERED_ERROR,
            message
        }
    },
    sendMailToUnregisteredSurrogate(companyId, email) {
        return {
            type: SEND_EMAIL_TO_UNREGISTERED_SURROGATE,
            companyId,
            email
        }
    },
    sendMailToUnregisteredSurrogateResponse(response) {
        return {
            type: SEND_EMAIL_TO_UNREGISTERED_SURROGATE_RESPONSE,
            response
        }
    },
    sendMailToUnregisteredSurrogateError(message) {
        return {
            type: SEND_EMAIL_TO_UNREGISTERED_SURROGATE_ERROR,
            message
        }
    },
    getCountUserFacilitiesByType(userId) {
        return {
            type: GET_COUNT_FACILITIES_BY_TYPE,
            userId
        }
    },
    getCountUserFacilitiesByTypeResponse(response) {
        return {
            type: GET_COUNT_FACILITIES_BY_TYPE_RESPONSE,
            response
        }
    },
    getCountUserFacilitiesByTypeError(message) {
        return {
            type: GET_COUNT_FACILITIES_BY_TYPE_ERROR,
            message
        }
    },
    getUserNotifications(successCallback, errorCallback){
        return {
            type: GET_USER_NOTIFICATIONS,
            successCallback, errorCallback
        }
    },
    getUserNotificationsResponse(response){
        return {
            type: GET_USER_NOTIFICATIONS_RESPONSE,
            response
        }
    },
    getUserNotificationsError(message){
        return {
            type: GET_USER_NOTIFICATIONS_ERROR,
            message
        }
    },
};
export default userActions;