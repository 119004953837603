import settings from '../util/settings';
import { POST, GET, PUT, DELETE } from '../util/routes/methods';
import { TIMEOUT } from "../util/routes/errors";

const baseUrl = settings.baseUrl;
const timeout = settings.timeout;

const performRequest = (route, {body, header} = {}) => {
    let promise;
    switch (route.method) {
        case POST:
            promise = postRequest(baseUrl, route.url, body, header);
            break;
        case GET:
            promise = getRequest(baseUrl, route.url, header);
            break;
        case PUT:
            promise = putRequest(baseUrl, route.url, header);
            break;
        case DELETE:
            promise = deleteRequest(baseUrl, route.url, header);
            break;
        default:
            throw Error(`Invalid method ${route.method}`)
    }

    const withTimeoutPromise = Promise.race([
        promise,
        new Promise((_, reject) =>
            setTimeout(() => reject(new Error(TIMEOUT), timeout))
        )
    ]);

    return withTimeoutPromise
        .then((res) => {
            if(res.ok) return res.json();

            else {
                if(!res.body){
                    let body;
                    try {
                        body = JSON.parse(res['_bodyText']);
                    }
                    catch (e) {
                        body = 'No Json Body';
                    }
                    throw {...res, body};
                } else throw res;
            }
        })
        .catch(e => {
            throw e;
        })
};

const postRequest = (baseUrl, url, body, headers) => {
    //Todo check if the 'Accept' need the quotes.
    const resultHeaders = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...headers,
    };

    const resultBody = resultHeaders['Content-Type'] === 'application/json' ? JSON.stringify(body) : body;

    return fetch(baseUrl + url, {
        method: POST,
        headers: resultHeaders,
        body: resultBody
    });
};

const getRequest = (baseUrl, url, headers) => {
    return fetch(baseUrl + url, {headers})
};

const putRequest = (baseUrl, url, body, headers) => {
    //Todo check if the 'Accept' need the quotes.
    const resultHeaders = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...headers,
    };

    const resultBody = resultHeaders['Content-Type'] === 'application/json' ? JSON.stringify(body) : body;

    return fetch(baseUrl + url, {
        method: PUT,
        headers: resultHeaders,
        body: resultBody
    });
};

const deleteRequest = (baseUrl, url, headers) => {
    return fetch(baseUrl + url, {
        method: DELETE,
        ...headers
    });
};
