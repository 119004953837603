export const GET_ALL_USER_COMPANIES_REPORT = 'GET_ALL_USER_COMPANIES_REPORT';
export const GET_ALL_USER_COMPANIES_REPORT_RESPONSE = 'GET_ALL_USER_COMPANIES_REPORT_RESPONSE';
export const GET_ALL_USER_COMPANIES_REPORT_ERROR = 'GET_ALL_USER_COMPANIES_REPORT_ERROR';
export const SELECT_COMPANY_REPORT = 'SELECT_COMPANY_REPORT';
export const SELECT_ESTABLISHMENT_REPORT = 'SELECT_ESTABLISHMENT_REPORT';
export const GET_ASSIGNED_COMPANIES_REPORT = "GET_ASSIGNED_COMPANIES_REPORT";
export const GET_ASSIGNED_COMPANIES_REPORT_RESPONSE = "GET_ASSIGNED_COMPANIES_REPORT_RESPONSE";
export const GET_ASSIGNED_COMPANIES_REPORT_ERROR = "GET_ASSIGNED_COMPANIES_REPORT_ERROR";
export const GET_USER_ESTABLISHMENTS_BY_COMPANY_REPORT = "GET_USER_ESTABLISHMENTS_BY_COMPANY_REPORT";
export const GET_USER_ESTABLISHMENTS_BY_COMPANY_REPORT_RESPONSE = "GET_USER_ESTABLISHMENTS_BY_COMPANY_REPORT_RESPONSE";
export const GET_USER_ESTABLISHMENTS_BY_COMPANY_REPORT_ERROR = "GET_USER_ESTABLISHMENTS_BY_COMPANY_REPORT_ERROR";
export const GET_USER_ESTABLISHMENT_BY_ID_REPORT = 'GET_USER_ESTABLISHMENT_BY_ID_REPORT';
export const GET_USER_ESTABLISHMENT_BY_ID_REPORT_RESPONSE = 'GET_USER_ESTABLISHMENT_BY_ID_REPORT_RESPONSE';
export const GET_USER_ESTABLISHMENT_BY_ID_REPORT_ERROR = 'GET_USER_ESTABLISHMENT_BY_ID_REPORT_ERROR';

const reportActions = {
    getAllUserCompanies() {
        return {
            type: GET_ALL_USER_COMPANIES_REPORT
        }
    },
    getAllUserCompaniesResponse(response) {
        return {
            type: GET_ALL_USER_COMPANIES_REPORT_RESPONSE,
            response
        }
    },
    getAllUserCompaniesError(message) {
        return {
            type: GET_ALL_USER_COMPANIES_REPORT_ERROR,
            message
        }
    },
    selectCompany(company) {
        return {
            type: SELECT_COMPANY_REPORT,
            company
        }
    },
    selectEstablishment(establishment) {
        return {
            type: SELECT_ESTABLISHMENT_REPORT,
            establishment
        }
    },
    getAssignedCompanies(userId) {
        return {
            type: GET_ASSIGNED_COMPANIES_REPORT,
            userId
        }
    },
    getAssignedCompaniesResponse(response) {
        return {
            type: GET_ASSIGNED_COMPANIES_REPORT_RESPONSE,
            response
        }
    },
    getAssignedCompaniesError(message) {
        return {
            type: GET_ASSIGNED_COMPANIES_REPORT_ERROR,
            message
        }
    },
    getUserEstablishmentsByCompany(companyId) {
        return {
            type: GET_USER_ESTABLISHMENTS_BY_COMPANY_REPORT,
            companyId
        }
    },
    getUserEstablishmentsByCompanyResponse(response) {
        return {
            type: GET_USER_ESTABLISHMENTS_BY_COMPANY_REPORT_RESPONSE,
            response
        }
    },
    getUserEstablishmentsByCompanyError(message) {
        return {
            type: GET_USER_ESTABLISHMENTS_BY_COMPANY_REPORT_ERROR,
            message
        }
    },
    getEstablishmentById(establishmentId, callback) {
        return {
            type: GET_USER_ESTABLISHMENT_BY_ID_REPORT,
            establishmentId,
            callback
        }
    },
    getEstablishmentByIdResponse(response) {
        return {
            type: GET_USER_ESTABLISHMENT_BY_ID_REPORT_RESPONSE,
            response
        }
    },
    getEstablishmentByIdError(message) {
        return {
            type: GET_USER_ESTABLISHMENT_BY_ID_REPORT_ERROR,
            message
        }
    },
};
export default reportActions;