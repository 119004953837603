import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
import { RTLProps } from '../../../../shared/prop-types/ReducerProps';
import ImageUploadDefault from './ImageUploadDefault';

class ImageUploadModal extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        message: PropTypes.string,
        color: PropTypes.string,
        colored: PropTypes.bool,
        header: PropTypes.bool,
        btn: PropTypes.string.isRequired,
        rtl: RTLProps.isRequired,
    };

    static defaultProps = {
        title: '',
        message: '',
        colored: false,
        header: false,
    };

    render() {
        const {
            color, btn, title, message, colored, header, rtl,
        } = this.props;
        let Icon;

        switch (color) {
            case 'primary':
                Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
                break;
            case 'success':
                Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
                break;
            case 'warning':
                Icon = <span className="lnr lnr-flag modal__title-icon" />;
                break;
            case 'danger':
                Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
                break;
            default:
                break;
        }
        const modalClass = classNames({
            'modal-dialog--colored': colored,
            'modal-dialog--header': header,
        });

        const editAvatar = (values) => {
            if (values.files !== undefined) { 
                this.props.editUserAvatar(values.files[0], () => this.props.setOpen())
            }
        };

        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.setOpen}
                modalClassName={`${rtl.direction}-support`}
                className={`modal-dialog--${color} ${modalClass}`}
            >
                <div className="modal__header">
                    <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.props.setOpen} />
                    {header ? '' : Icon}
                    <h4 className="text-modal  modal__title">{title}</h4>
                </div>
                <div className="modal__body">
                    <ImageUploadDefault 
                        setOpen={this.props.setOpen}
                        onSubmit={editAvatar} 
                        acceptedFormats=".jpg,.png"/>
                </div>
            </Modal>
        );
    }
}

export default connect(state => ({
    rtl: state.rtl,
}))(ImageUploadModal);
